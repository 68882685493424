import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell.js';
import ValueWithStateCell from '../../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import LengthCell from '../../../../Common/CustomGridCells/LengthCell/lengthCell.js';
import WeightCell from '../../../../Common/CustomGridCells/WeightCell/weightCell.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../../utils/materialHelper';
import {getAssetEtl} from '../../../../../utils/assetHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import DefrostingLabelCell from '../../../../Common/CustomGridCells/DefrostingLabelCell/defrostingLabelCell';
import LastOperationCell from '../../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {alertStatus,alertType,getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {enumTypes, getEnumValue} from '../../../../../utils/enumHelper';
import {isOutgoingOrIncomingLocation, isTransitArea} from '../../../../../utils/locationHelper';


require('./spoolsGrid.scss');

class SpoolsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'shipmentBusinessId', filterName: 'shipmentBusinessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'material', getOptions: false},
    {fieldName: 'lot', filterName: 'lot', getOptions: false},
    {fieldName: 'defrostingStatus', filterName: 'defrostingStatus', getOptions: true},
    {fieldName: 'lengthLeft', filterName: 'length'},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},
    {fieldName: 'weight', filterName: 'weight'},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.allLabels = createLabelHelper('');
    this.filterDefrostinglabels = createLabelHelper('mat.filter.defrosting.');
    this.etlLabels = createLabelHelper('enum.etl.type.');
    let isTransitAreaLoc = isTransitArea(props.locationDetails);
    this.columnsConfig = isTransitAreaLoc ? this.createShipmentsColumnsConfig() : this.createColumnsConfig();
  }

  createShipmentsColumnsConfig = () => {
    let isShippingOrReceivingLoc = isOutgoingOrIncomingLocation(this.props.locationDetails);
    return [


      {
        fieldName: 'businessId',
        title: this.labels.get('columns.spoolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.SPOOL_BUSINESS_ID,
          filter: this.getRollIdFilterByLocationType(),
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: isShippingOrReceivingLoc ? LabelWithTooltipCell : EntityIdCell,
          valueGetter: (params) => {
            return isShippingOrReceivingLoc ? params.data.businessId :
              {
                id: params.data.id,
                label: params.data.businessId,
                type: navigationStates.SPOOL
              };
          }
        }
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        width: 150,
        columnOptions: {
          valueGetter: (params) => {
            const  status = params.data.assetStatus.toLowerCase();
            return  status.charAt(0).toUpperCase() + status.slice(1);

          },
        }
      },
      {
        fieldName: 'shipmentBusinessId',
        title: this.labels.get('columns.shipmentId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'shipmentBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.shipmentInfo?.businessId
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'material',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [... [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
            ...(isShippingOrReceivingLoc ? [FetchEntitiesFilters.ASSET_IN_PASSPORT_STATUS] : [])],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'lengthLeft',
        title: this.labels.get('columns.length.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: LengthCell,
          valueGetter: (params) => {
            return {
              length: params.data.lengthLeft
            };
          },
        }
      },
      {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      }

    ]
  }

  createColumnsConfig = () => {
    let customColumn = this.getColumnByLocationType();
    let isShippingOrReceivingLoc = isOutgoingOrIncomingLocation(this.props.locationDetails);

    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              defrostingTimeLeft: params.data.defrostingTimeLeft,
              locationName: params.data.locationName,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'material',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [... [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_SPOOL, FetchEntitiesFilters.ON_LOCATION],
                    ...(isShippingOrReceivingLoc ? [FetchEntitiesFilters.ASSET_IN_PASSPORT_STATUS] : [])],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.spoolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.SPOOL_BUSINESS_ID,
          filter: this.getRollIdFilterByLocationType(),
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: isShippingOrReceivingLoc ? LabelWithTooltipCell : EntityIdCell,
          valueGetter: (params) => {
            return isShippingOrReceivingLoc ? params.data.businessId :
              {
                id: params.data.id,
                label: params.data.businessId,
                type: navigationStates.SPOOL
              };
          }
        }
      },
      {...customColumn},
      {
        fieldName: 'lot',
        title: this.labels.get('columns.lot.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'lot',
        fetchConfig: {
          entityType: EntityPropertyTypes.LOT_NAME,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_SPOOL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => ({
            id: params.data.lot?.id,
            label: params.data.lot?.name,
            type: params.data.lot ? navigationStates.LOT : '',
          }),
        },
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'lengthLeft',
        title: this.labels.get('columns.length.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: LengthCell,
          valueGetter: (params) => {
            return {
              length: params.data.lengthLeft
            };
          },
        }
      },
      {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ];
  }

  getRollIdFilterByLocationType = () => {
    let {locationDetails} = this.props;

    if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PASSPORT_STATUS]
    }

    if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS')) {
      return [FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PASSPORT_STATUS]
    }

    if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA')) {
      return [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION]
    }

    return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION]
  }

    getColumnByLocationType = () => {

      let {locationDetails} = this.props;
      if (locationDetails) {
        if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('DEFROSTING_AREA')) {
          return ({
            fieldName: 'defrostingStatus',
            title: this.labels.get('columns.defrosting.title'),
            filterType: filterTypes.MULTI_SECTIONS,
            filterSections: this.getDefrostingColumnFilterSections(),
            filterName: 'defrostingStatus',
            width: 100,
            columnOptions: {
              cellComponent: DefrostingLabelCell,
              valueGetter: (params) => {
                return {
                  status: params.data.assetStatus,
                  statusLabel: params.data.defrostingStatusDisplayKey ? this.allLabels.get(params.data.defrostingStatusDisplayKey) : '',
                  defrostingTimeLeft: params.data.defrostingTimeLeft
                }
              }
            }
          });
        }
        else if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')) {
          return {
            fieldName: 'assetStatus',
            title: this.labels.get('columns.status.title'),
            filterType: filterTypes.MULTI_SELECT,
            filterName: 'assetStatus',
            width: 150,
            columnOptions: {
              valueGetter: (params) => {
                const  status = params.data.assetStatus.toLowerCase();
                return  status.charAt(0).toUpperCase() + status.slice(1);

              },
            }
          }
        }

      }
    return ( {
        fieldName: 'relatedAssetData',
        title: '',
        filterType: filterTypes.NONE,
        width: 70,
        columnOptions: {
          sortable: false,
          resizable: false,
          headerValueGetter: GridHelper.headerValueGetter,
          cellComponent: RelatedAssetsCell,
          valueGetter: (params) => {
            return {
              count: params.data.relatedAssetsCounter,
              cellAction: this.props.cellAction,
              assetId: params.data.id,
              componentAssetsTitle: this.labels.get('columns.componentAssets.cell.title'),
              resultingAssetsTitle: this.labels.get('columns.resultingAssets.cell.title')
            };
          },
          headerComponentParams: {
            headerIcon: 'resulting-assets',
            headerTooltip: this.labels.get('columns.resultingAssets.cell.title'),
          }
        }
      } );

    }

  getDefrostingColumnFilterSections() {
    return [
      {
        properties: [
          {name: 'BEING_DEFROSTED', label: this.filterDefrostinglabels.get('beingDefrosted'), value: false},
          {name: 'DEFROSTED', label:  this.filterDefrostinglabels.get('defrosted'), value: false}
        ]
      },
    ];
  }

  render() {
    return (
      <div className="spools-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_SPOOLS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  gridNameSuffix: this.props.locationDetails?.locationType,
                  getRowHeight: () => 84
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'locationSpoolsGrid'
  }
)(SpoolsGrid);

SpoolsGrid.propTypes = {
  actions: PropTypes.object.isRequired,
  locationDetails: PropTypes.object.isRequired,
  cellAction: PropTypes.func.isRequired,
};
