import { useCallback, useEffect, useSyncExternalStore } from 'react';

export function useSessionStorage(key, initialValue) {
  const getSnapshot = () => getSessionStorageItem(key);

  const store = useSyncExternalStore(useSessionStorageSubscribe, getSnapshot);

  const setState = useCallback(
    (v) => {
      try {
        const nextState = typeof v === 'function' ? v(JSON.parse(store)) : v;

        if (nextState === undefined || nextState === null) {
          removeSessionStorageItem(key);
        } else {
          setSessionStorageItem(key, nextState);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, store]
  );

  useEffect(() => {
    if (getSessionStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setSessionStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [store ? JSON.parse(store) : initialValue, setState];
}

function getSessionStorageItem(key) {
  return window.sessionStorage.getItem(key);
}

function setSessionStorageItem(key, value) {
  const stringifiedValue = JSON.stringify(value);
  window.sessionStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
}

function removeSessionStorageItem(key) {
  window.sessionStorage.removeItem(key);
  dispatchStorageEvent(key, null);
}

function useSessionStorageSubscribe(callback) {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
}

function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}
