import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
  ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_VIEW_SUBMIT_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_VIEW_SUBMIT_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FINISHED: 'ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FINISHED',
  ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FAILED: 'ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FAILED',
  ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_FINISHED: 'ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_FINISHED',
  ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_RESET_DATA: 'ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_RESET_DATA',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  submitInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_VIEW_SUBMIT_IN_PROGRESS};
  },
  submitFinished: function () {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FINISHED};
  },
  submitFailed: function () {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_SUBMIT_FAILED};
  },
  fetchDataInProgress: function () {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_IN_PROGRESS};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_FETCH_DATA_FINISHED, payload: payload};
  },
  resetState: function () {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_ADDITIONAL_FIELDS_RESET_DATA};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.load = function (entityType) {
  return function (dispatch, getState) {
    return api.fetchData(dispatch, getState)(entityType);
  }
};

jsxActions.submit = function (data, entityType) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, entityType);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchData = function (dispatch, getState) {
  return function (entityType) {
    dispatch(actions.fetchDataInProgress());

    entityType = {entityType: _getEntityType(entityType)};
    let promise1 = additionalFieldsService.fetchAdditionalFields(entityType);
    let promise2 = additionalFieldsService.fetchAdditionalFieldsTypes();

    return Promise.all([promise1, promise2]).then((allResults) => {
      if (!Network.isResponseValid(allResults[0] || allResults[1])) {
        console.error("Failed to fetch Additional fields");
        messageDialogApi.responseError(dispatch, getState)(null);
        return {success: false};
      }
      let labels = createLabelHelper('');
      let data = {
        fields: allResults[0].dataList,
        fieldTypes: allResults[1].dataList.map((item) => {
          return {value: item.type, label: labels.get(item.typeDisplayKey), data: item}
        })
      };
      dispatch(actions.fetchDataFinished(data));
      return {success: true};
    });
  }
};

api.submit = function (dispatch, getState) {
  return function (data, entityType) {

    dispatch(actions.submitInProgress());
    let fields = data.additionalFields.map((field) => {
      return {id: field.id, fieldDisplayName: field.displayName, fieldType: field.type.type}
    });

    let query = {
      entityType: _getEntityType(entityType),
      fields: fields
    };
    return additionalFieldsService.setAdditionalFields(query)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Failed to update Additional fields Settings', response);
          dispatch(actions.submitFailed());
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        /* if (messageDialogBuilder) {
           let messageDialogDescriptor = messageDialogBuilder(
             response.data
           );

           messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
         }*/

        dispatch(actions.submitFinished(response.data));

        return api.fetchData(dispatch, getState)(entityType);
      });

  }
};

///////////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
function _getEntityType(entityType) {
  switch (entityType) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
      return entityType;
  }
  console.error('_getEntityType(): unknown entity type ' + entityType);
  return '';
}
