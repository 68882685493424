import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Collapse from 'react-bootstrap/Collapse';
import classNames from 'classnames';

import './helpMenu.scss';

export default class HelpMenu extends React.PureComponent{

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.header.usermenu.');
  }

  renderCopyrights = () => {
    return (
      <div>
        <div className='copyrights'><span className='special-character' >&copy; </span> <span className='info'>{new Date().getFullYear()} {this.labels.get('copyrights')}</span></div>
      </div>
    );
  }

  getHelpMenuElements(){
    return [
      {
        header: this.labels.get('help'),
        action: this.actionHelp,
      },
      {
        header: this.labels.get('userGuide'),
        action: this.actionUserGuide,
      },
      {
        header: this.labels.get('aboutTpo'),
        action: this.actionAboutTpo,
      },
      {
        header: this.renderCopyrights(),
        className : 'copyrights'
      },
      {
        header: this.props.sServerVersion,
        className : 'version'
      }
    ];
  }

  actionHelp=()=>{
    this.props.actions.help();
  }

  actionUserGuide=()=>{
    this.props.actions.showUserGuide();
  }

  actionAboutTpo=()=>{
    this.props.actions.showAboutTpo();
  }

  doAction =(action) => {
    action();
    this.props.actions?.onItemClick?.();
  }

  renderNavigationElements(){
    let elements = [];

    this.getHelpMenuElements().map((element, index)=>{
      if(element) {
         elements.push(
           <div className={classNames('help-menu-item', element.className)}
                key={"help-menu-element-"+index}
                onClick={()=>{this.doAction(element.action || (()=>{}))}}
           >{element.header}</div>);
      }
    });

    return elements;
  }

  render() {
    return (
      <Collapse in={this.props.open} >
      <div className="help-menu panel-body"  >
        {this.renderNavigationElements()}
      </div>
      </Collapse>)
  }
}
