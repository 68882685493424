import PropTypes from 'prop-types';

import SingleLineMultiSelect from 'infrastructure/js/components/controls/SingleLineMultiSelect/singleLineMultiSelect.js';
import withAsyncProps from 'infrastructure/js/components/HOCs/withAsyncProps';
import withEditor from 'infrastructure/js/components/HOCs/withEditor/withEditor.js';

const asyncPropsOptions = [
  {
    propName: 'options',
    config: {
      type: 'api',
      connection: {
        url: 'tags/search',
        method: 'post',
        params: { onlyUnattached: true, maxPageSize: 10 },
        valueKey: 'tid',
        allowEmptyStringValue: false,
      },
      dataTranslator: (response) => {
        if (Array.isArray(response.dataList)) {
          return response.dataList.map(({ id, tagKey }) => ({ value: id, label: tagKey }));
        } else {
          console.error(response);
          return [];
        }
      },
      onLoad: () => {},
      initialValue: [],
    },
  },
];

const Component = withAsyncProps(withEditor(SingleLineMultiSelect), asyncPropsOptions);

const TagsAsyncEditor = ({ asset={}, ...props }) => {
  const { tags: tagsValue } = asset;
  const computedValue = tagsValue?.map(({ id, tagKey }) => ({ value: id, label: tagKey })) || [];

  return <Component {...props} value={computedValue} />;
};

TagsAsyncEditor.propTypes = {
  asset: PropTypes.object,
};

export default TagsAsyncEditor;
