import {useEffect} from 'react';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
import {gridsNames} from '../../../../../enums/gridsNames';
import {List} from 'immutable';
import useRedux from 'infrastructure/js/hooks/useRedux';
import useLabels from 'infrastructure/js/hooks/useLabels';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import SchedulingAutomationViewGrid from './SchedulingAutomationViewGrid/schedulingAutomationViewGrid';
import { jsxActions as settingsPageActions} from '../../../../../actions/Administration/SettingsPage/settingsPageActions';
import { jsxActions as headerSettingsActions} from '../../../../../actions/Header/headerSettingsActions';
import { jsxActions as schedulingAutomationViewActions } from '../../../../../actions/Administration/SettingsPage/schedulingAutomationViewActions';
import { jsxActions as schedulingAutomationViewGridActions } from '../../../../../actions/Administration/SettingsPage/schedulingAutomationViewGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as createSchedulingTriggerDialogActions } from '../../../../../actions/Administration/SettingsPage/Dialogs/createSchedulingTriggerDialogActions';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import CreateSchedulingTriggerDialog from './Dialogs/createSchedulingTriggerDialog';

import'./schedulingAutomationView.scss';


const getState = (state) => {
  let grid = state.grid?.get(gridsNames.ADMINISTRATION_SETTINGS_SCHEDULING_AUTOMATION) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
    sCreateSchedulingTriggerDialogData: state.administration.getIn(['settingsPage', 'createSchedulingTriggerDialog']),
  };
};

const allActions = {
  settingsPageActions,
  headerSettingsActions,
  schedulingAutomationViewActions,
  schedulingAutomationViewGridActions,
  fetchEntitiesActions,
  createSchedulingTriggerDialogActions,
};


export default function SchedulingAutomationView() {
  const labels = useLabels('mat.administration.settings.schedulingAutomation.view.');
  const { state, actions } = useRedux(allActions, getState);
  const {sHasRows = false, sSelectedRowsData, sCreateSchedulingTriggerDialogData } = state;

  useEffect(() => {
    actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_SCHEDULING_AUTOMATION);
  }, []);

  const getHeaderItems = () => {
    return {
      title: labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.schedulingAutomationViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.schedulingAutomationViewActions.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.headerSettingsActions.onCreateSchedulingTrigger
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(sHasRows && sSelectedRowsData.size === 1),
          action: actions.headerSettingsActions.onEditSchedulingTrigger,
          actionData: sSelectedRowsData.get(0)
        },
      ],
      kebab: {
        menuItems: [
          {
            id: 'remove',
            label: headerItemsLabels.REMOVE,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            action: actions.schedulingAutomationViewActions.remove,
            actionData: sSelectedRowsData,
          },
        ]
      },
    };
  }

  const reloadPage = () => {
    actions.schedulingAutomationViewActions.reload();
  }

  return (
    <div className='scheduling-automation-view'>
      <Header {...getHeaderItems()}/>
      <SchedulingAutomationViewGrid
                               actions={{...actions.schedulingAutomationViewGridActions, ...actions.fetchEntitiesActions}}
      />

      <PL_DialogWrapper dialogComponent={CreateSchedulingTriggerDialog}
                        show={sCreateSchedulingTriggerDialogData.get('show')}
                        actions={actions.createSchedulingTriggerDialogActions}
                        sData={sCreateSchedulingTriggerDialogData}
                        reloadParentComponent={reloadPage}
      />
    </div>
  )
}

SchedulingAutomationView.propTypes = {
};
