import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';
import {
  getCompletedQuantityInfo,
  getEventFormattedDates,
  getFormattedDuration
} from '../../../Common/Helpers/SchedulerHelper.js';
import { formatShiftSegment } from '../../../Common/Helpers/ShiftsHelper.js';
import { getPatternStyle } from 'infrastructure/js/components/DynamicIcons/TaskStarted.js';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper.js';
import ToolTypeHelper from '../../../../../js/utils/toolTypeHelper.js';

const pageHelperLabels = createLabelHelper('mat.mobile.taskPage.helper.label.');

/**
 * Returns a list of HTML elements representing the shifts in the given shiftSegments array.
 *
 * @function getTooltipSectionShifts
 * @param {Array<ShiftSegment>} shiftSegments - The array of shift segments.
 * @returns {Array<Element>} A list of HTML elements.
 */
export const getTooltipSectionShifts = (shiftSegments) => {
  // Checks if the shiftSegments array is empty or has no elements.
  if (!shiftSegments || !shiftSegments.length) {
    return '';
  }

  //Creates a list of HTML elements, one for each shift in the shiftSegments array.
  return shiftSegments.map((segment) => <div key={segment.plannedStartDateSegment.epochDateTime}>{formatShiftSegment(segment)}</div>);
};

/**
 * Returns the title of the page (task name), based on the active task.
 *
 * @param {Object} activeTask - The active task.
 * @returns {string} The title of the page.
 */
export const getPageTitle = (activeTask) => {
  //Checks if the activeTask object is undefined or has no `task` property.
  if (!activeTask?.task) {
    return '';
  }

  //Returns the name of the task from the activeTask object.
  return activeTask.task.name;
};

/**
 * Returns a map of formatted components for the given event.
 *
 * @param {Object} event - The event.
 * @returns {Object} A map of formatted components.
 */
export const getEventFormattedComponents = (event) => {
  // This line checks if the event object is undefined or null. Returns an empty object if it does.
  if (!event) {
    return [];
  }

  const labels = createLabelHelper('mat.mobile.taskPage.helper.field.');

  // This line gets the properties from the event object.
  const {
    operationStatus,
    priority,
    splitAssignments,
    task,
    plannedResource,
    plannedStartTime,
    plannedEndTime,
    estimatedEndTime,
    estimatedStartTime,
    endTime,
    startTime,
    plannedTool,
    actualTool,
    groupName,
    vacuumPort,
  } = event;
  // This line gets the task properties from the task object.
  const { kitType, duration, workOrder, toolType, completedQuantity, quantity } = task;
  // This line gets the formatted dates from the getEventFormattedDates function.
  const { plannedStartDisplayDate, plannedEndDisplayDate, estimatedStartDisplayDate, estimatedEndDisplayDate, startDate, endDate } =
    getEventFormattedDates({
      plannedStartDate: plannedStartTime,
      plannedEndDate: plannedEndTime,
      estimatedStartDisplayDate: estimatedStartTime,
      estimatedEndDate: estimatedEndTime,
      startDate: startTime,
      endDate: endTime,
    });
  // Create an array of formatted fields.
  const fields = [
    { fieldName: labels.get('workOrder'), fieldValue: workOrder?.businessId ?? '' },
    { fieldName: labels.get('status'), fieldValue: operationStatus ?? '' },
    { fieldName: labels.get('priority'), fieldValue: priority ?? '' },
    { fieldName: labels.get('shifts'), fieldValue: getTooltipSectionShifts(splitAssignments) },
    { fieldName: labels.get('kitType'), fieldValue: kitType?.businessId ?? '' },
    { fieldName: labels.get('plannedStartTime'), fieldValue: plannedStartDisplayDate },
    { fieldName: labels.get('plannedEndTime'), fieldValue: plannedEndDisplayDate },
    { fieldName: labels.get('plannedDuration'), fieldValue: getFormattedDuration(duration) },
    { fieldName: labels.get('plannedResource'), fieldValue: plannedResource?.businessId ?? '' },
    { fieldName: labels.get('estimatedStartTime'), fieldValue: estimatedStartDisplayDate },
    { fieldName: labels.get('estimatedEndTime'), fieldValue: estimatedEndDisplayDate },
    {
      fieldName: labels.get('requiredToolType'),
      fieldValue: toolType ? ToolTypeHelper.getToolTypeFullLabel(toolType.name, toolType.businessId) : '',
    },
    { fieldName: labels.get('plannedToolId'), fieldValue: plannedTool?.businessId ?? '' },
    { fieldName: labels.get('toolId'), fieldValue: actualTool?.businessId ?? '' },
    { fieldName: labels.get('recipe'), fieldValue: groupName ?? '' },
    { fieldName: labels.get('chain'), fieldValue: vacuumPort ?? '' },
  ];

  //Check if the status is not scheduled and add start time.
  if (operationStatus !== 'SCHEDULED') {
    fields.push({ fieldName: labels.get('startTime'), fieldValue: startDate });
  }

  //Check if the status is completed and add end time.
  if (operationStatus === 'COMPLETED') {
    fields.push({ fieldName: labels.get('endTime'), fieldValue: endDate });
    fields.push({ fieldName: labels.get('completedQuantity'), fieldValue: getCompletedQuantityInfo(completedQuantity, quantity) });
  }

  //return the fields
  return fields;
};

/**
 * Returns an array of button data objects, based on the given status and modal openers.
 *
 * @param {string} status The status of the task.
 * @param {object} modalOpeners An object containing the functions to open the start and complete modals.
 * @returns {array} An array of button data objects.
 */
export const getButtonsDataByStatus = (status, modalOpeners) => {
  // Get the lower case version of the status.
  const lowerStatus = status.toLowerCase();
  // Get the button styles.
  const { start, complete } = getButtonStyles();

  // If the status is scheduled, return an array with two buttons: start and complete.
  if (lowerStatus === 'scheduled') {
    return [
      { id: 'startBTN', label: pageHelperLabels.get('start'), style: start, action: modalOpeners.start },
      { id: 'completeBTN', label: pageHelperLabels.get('complete'), style: complete, action: modalOpeners.complete },
    ];
  }

  // If the status is started, return an array with one button: complete.
  if (lowerStatus === 'started') {
    return [{ id: 'completeBTN', label: pageHelperLabels.get('complete'), style: complete, action: modalOpeners.complete }];
  }

  // If the status is not scheduled or started, return an empty array.
  return [];
};

/**
 * Returns a React element representing the header of the modal, based on the given status.
 *
 * @param {string} status The status of the task.
 * @returns {ReactElement} A React element representing the modal header.
 */
export const getModalHeaderByStatus = (status) => {
  // Get the lower case version of the status.
  const lowerStatus = status.toLowerCase();

  // Get the button styles.
  const { start, complete } = getButtonStyles();

  // If the status is start, return a React element with the start label and start style.
  if (lowerStatus === 'start') {
    return (
      <div className="task-page-modal-header" style={start}>
        {pageHelperLabels.get('start')}
      </div>
    );
  }

  // If the status is complete, return a React element with the complete label and complete style.
  if (lowerStatus === 'complete') {
    return (
      <div className="task-page-modal-header" style={complete}>
        {pageHelperLabels.get('complete')}
      </div>
    );
  }

  if (lowerStatus === 'completeat') {
    return (
      <div className="task-page-modal-header" style={complete}>
        {pageHelperLabels.get('completeAt')}
      </div>
    );
  }
  if (lowerStatus === 'startat') {
    return (
      <div className="task-page-modal-header" style={start}>
        {pageHelperLabels.get('startAt')}
      </div>
    );
  }
  // If the status is not complete or started, return null.
  return null;
};

/**
 * Returns a dictionary of button styles, based on the system colors.
 *
 * @returns {object} A dictionary of button styles.
 */
export const getButtonStyles = () => {
  // Get the system colors.
  const { started, completed, scheduled } = getSystemColors();

  // Get the React pattern style for the start button.
  const start = getPatternStyle(started).react;

  // Create a dictionary of button styles.
  const buttonStyles = {
    start,
    complete: { backgroundColor: completed },
    scheduled: { backgroundColor: scheduled },
  };

  // Return the dictionary of button styles.
  return buttonStyles;
};
