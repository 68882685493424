import React from 'react';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import * as AssetHelper from '../../../../utils/assetHelper';
import {navigationStates} from '../../../../enums/navigationStates';
import Link from '../../../Common/Layout/Link/link.js';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {enumTypes, getEnumValue} from '../../../../utils/enumHelper';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';
import {isLimitedUser, isPresenterUser} from '../../../../utils/userHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';

require('./relatedAssets.scss');


export default class RelatedAssets extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.overview.assets.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
  }

  removeButtonClickHandler = (data) => {
    this.props.actions.assetEditorActions.onRemoveAssetButtonClick(this.getConfirmationMessageDialogBuilder(data), this.getSucceededMessageDialogBuilder(data),data);
  };

  getConfirmationMessageDialogBuilder = (data) => {
    return (fnCloseDialog , callback ) => {
      let type = 'warning';
      let title = this.labels.get('removeRelatedAssetFromAsset.confirmation.title', '', {relatedAssetType: data.relatedAssetObjectType?.toLowerCase()});
      let message = this.labels.get('removeRelatedAssetFromAsset.confirmation.message', '',
        {
          relatedAssetType: data.relatedAssetObjectType?.toLowerCase(),
          relatedAssetId: data.relatedAssetBusinessId,
          parentAssetType: data.parentAssetObjectType?.toLowerCase(),
          parentAssetId: data.parentAssetBusinessId });

      let children =  [<MessageDialog.MessageArea key={'confirmMessageDataRow'} text={message}/>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove',text: this.dialogLabels.get('remove'), action: callback  , bsStyle: 'primary'}
      ];
      let className = 'remove-related-asset-confirm-message';
      return {title, buttons, children, className, type};
    };
  };

  getSucceededMessageDialogBuilder = (data) => {
    return (fnCloseDialog) => {
      let type = 'success';
      let title = this.labels.get('removeRelatedAssetFromAsset.confirmation.success.title', '', {relatedAssetType: data.relatedAssetObjectType?.toLowerCase()});
      let message = this.labels.get('removeRelatedAssetFromAsset.confirmation.success.message', '',
        { relatedAssetType: data.relatedAssetObjectType?.toLowerCase(),
                relatedAssetId: data.relatedAssetBusinessId,
                parentAssetType: data.parentAssetObjectType?.toLowerCase(),
                parentAssetId: data.parentAssetBusinessId
              });

      let children =  [<MessageDialog.MessageArea key={'confirmMessageDataRow'} text={message}/>];
      let buttons = [{id:'close',text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'}];
      let className = 'remove-related-asset-confirm-success-message';
      return {title, buttons, children,  className, type};
    };
  };

  isRemoveButtonAvailable = (relatedAsset, parentAsset) => {
    if (isPresenterUser(this.props.loggedInUser) || isLimitedUser(this.props.loggedInUser)) {
      return false;
    }
    // 'removeRollFromKitEnabled == true' means that the user must be an admin in order to perform the remove action
    if (!PermissionManager.hasRemoveComponentAssetPermissions() && PermissionManager.getOrgPreferences().removeRollFromKitEnabled) {
      return false;
    }

    if (!parentAsset || !AssetHelper.isAssetActivated(parentAsset.assetStatus) || parentAsset.isCured) {
      return false;
    }

    if (!relatedAsset || relatedAsset.isCured) {
      return false;
    }

    // return ((parentAsset.objectType !== getEnumValue(enumTypes.OBJECT_TYPE)('KIT') &&
    //   relatedAsset.objectType !== getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')) ||
    //   (parentAsset.objectType !== getEnumValue(enumTypes.OBJECT_TYPE)('GROUP') &&
    //     relatedAsset.objectType !== getEnumValue(enumTypes.OBJECT_TYPE)('KIT')));

    let parType = parentAsset.objectType;
    let relType = relatedAsset.objectType;

    return (
      parType === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP') ||
      ( parType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT') && relType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')  )
    );
  }

  renderRemoveButton = (relatedAsset, parentAsset) => {
    if (this.isRemoveButtonAvailable(relatedAsset, parentAsset)) {
      let data = {
        parentAssetObjectType: parentAsset.objectType,
        parentAssetId: parentAsset.id,
        parentAssetBusinessId: parentAsset.businessId,
        relatedAssetObjectType: relatedAsset.objectType,
        relatedAssetId: relatedAsset.id,
        relatedAssetBusinessId: relatedAsset.businessId
      };

      return (<Button id="removeAssetBtn" className="remove-asset-button" onClick={() => {
        this.removeButtonClickHandler(data)
      }}>
        <span className="pl pl-removed"/>
      </Button>)
    }
    return null;
  };

  renderAttachedAsset = (assets) => {
    return (
      <Tooltip
        placement="top"
        value={this.labels.get('currently.attached')}
        delayShow={500}>
        <div className="has-attached-asset">
                  <span>
                    <span className={'pl pl-kit2-icon'}/>
                    <span className={'pl pl-update-icon'}/>
                    <span className={'pl pl-tool-full-icon'}/>
                  </span>
        </div>
      </Tooltip>
    )
  };

  renderEquipment = (assets) => {
    if (!assets || assets.length < 1) {
      return null;
    }
    return assets.map((asset, index) => {
      let assetLabel = asset.toolTypeBusinessId || '';
      let hasAttachedAsset =  !!asset.attachedAssetId;

      return (
        <div className="asset-info" key={'asset' + index}>
          {AssetHelper.getAssetIconComponent(asset.objectType)}
          <span className="equipment-asset-id">
            <Link className="asset-id" label={asset.businessId} id={asset.id} type={navigationStates.TOOL}/>
            {hasAttachedAsset && this.renderAttachedAsset()}
          </span>
          <span className="asset-label">{assetLabel}</span>
          <div className='operation-date-wrap'>{asset.relationCreatedDate ? this.getOperationDates([asset.relationCreatedDate], asset.businessId) : null}</div>
        </div>
      );
    });
  };


  getRelatedAssetType(asset){
    if (AssetHelper.isRoll(asset.objectType)) {
      return  navigationStates.ROLL;
    }
    else if (AssetHelper.isSpool(asset.objectType)) {
      return navigationStates.SPOOL;
    }
    else if (AssetHelper.isResin(asset.objectType)) {
      return navigationStates.RESIN;
    }
    else if (AssetHelper.isKit(asset.objectType)) {
      return  navigationStates.KIT;
    }
    else if (AssetHelper.isTool(asset.objectType)) {
      return  navigationStates.TOOL;
    }
    else if (AssetHelper.isGroup(asset.objectType)) {
      return navigationStates.GROUP;
    }
    else {
      console.error('RelatedAssets: Unknown asset type: ' + asset.objectType);
      return ''
    }
  }

  renderAssets = (assets, parentAsset) => {
    if (!assets) {
      return null;
    }
    return assets.map((asset, index) => {
      let type = this.getRelatedAssetType(asset);
      let label = asset.assetDetail || '';
      let businessId = asset.businessId || '';

      return (

        <div className="asset-info" key={'asset' + index}>
          <div className="remove-btn-section">
            {this.renderRemoveButton(asset, parentAsset)}
          </div>

          <div className="info-section">
            {AssetHelper.getAssetIconComponent(asset.objectType)}
            <Link className="asset-id" label={businessId} id={asset.id} type={type}/>
            <span className="asset-label">{label}</span>
            <div className='operation-date-wrap'>{this.getOperationDates(asset.kitActionsCreatedDates, asset.businessId)}</div>
          </div>
        </div>
      );
    });
  };

  renderKitComponentAssets = () => {
    const {componentAssets, parentAsset} = this.props;
    const units = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;

    return (
      <div className='grid-container'>
        <div className="title">{this.labels.get('componentassets')}</div>
        <div className="title grid-column-2">
          <label className="">{this.labels.get('consumedAmount')}</label>
          <label className="units">{`(${UnitHelper.getLabelForUnitType(units)})`}</label>
        </div>
          {componentAssets && componentAssets.map((asset, index) => {
            let type = this.getRelatedAssetType(asset);
            let label = asset.assetDetail || '';
            let businessId = asset.businessId || '';

            return (
              <React.Fragment key={'asset' + index}>
              <div className="asset-info" >
                <div className="remove-btn-section">
                  {this.renderRemoveButton(asset, parentAsset)}
                </div>

                <div className="info-section">
                  {AssetHelper.getAssetIconComponent(asset.objectType)}
                  <Link className="asset-id" label={businessId} id={asset.id} type={type}/>
                  <span className="asset-label">{label}</span>
                  <div className='operation-date-wrap'>{this.getOperationDates(asset.kitActionsCreatedDates, asset.businessId)}</div>
                </div>
              </div>
              <div className='asset-info grid-column-2'>
                {asset.materialUsedAmount}
              </div>
              </React.Fragment>
            )
          })}
        <div className="pl pl-component-assets assets-icon"/>
      </div>
    )
  }

  getOperationDates = (dates, businessId) => {
    if (!dates) {
      return null;
    }
    return dates.map((date, index) => {
      return (
        <span key={'date' + businessId + index}
              className="operation-date">{this.labels.get('date.on')} {DateTimeHelper.DateTimeFormat(date.epochDateTime * 1000)}
        </span>
      );
    });
  };

  render() {
    const isKit = this.props?.parentAsset?.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT');

    const title = AssetHelper.isPart(this.props?.parentAsset?.objectType) ?  this.labels.get('layers') : this.labels.get('componentassets');

    return (
      <div className="related-assets-container">
        <span className="container-title">{this.labels.get('relatedassets')}</span>
        <div className="related-assets">

          <div className={`column ${isKit ? 'grid-wrapper' : ''}`}>
            {isKit ? this.renderKitComponentAssets() :
              <React.Fragment>
                <div className="title">{title}</div>
                <div className="assets-list">
                  {this.renderAssets(this.props.componentAssets, this.props.parentAsset)}
                </div>
                <div className="pl pl-component-assets assets-icon"/>
              </React.Fragment>
            }
          </div>

          { !AssetHelper.isPart(this.props?.parentAsset?.objectType) &&
          <div className="column">
            <div className="title">{this.labels.get('resultingassets')}</div>
            <div className="assets-list">
              {this.renderAssets(this.props.resultingAssets)}
            </div>
            <div className="pl pl-resulting-assets assets-icon"/>
          </div>}

          <div className="column">
            <div className="title">{this.labels.get('equipment')}</div>
            <div className="assets-list">
              {this.renderEquipment(this.props.equipmentAssets)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}



