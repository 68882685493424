import React from 'react';
import PropTypes from 'prop-types';

import {Prompt} from 'react-router-dom';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Header from '../../../../../Common/Header/header';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import CommonPdfLabelSettingsForm from '../CommonPdfLabelSettingsForm/commonPdfLabelSettingsForm';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
require('./commonPdfLabelSettingsView.scss');

 export default class CommonPdfLabelSettingsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.pdfLabelSettings.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }


  componentWillUnmount() {
     this.props.actions.unmount();
  }

  getHeaderItems = () => {
    return {
      title: this.getHeaderTitle(),
    };
  };

  getHeaderTitle = () => {
    let {entityType} = this.props;
    switch (entityType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return this.labels.get('title.roll');
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return this.labels.get('title.spool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
        return this.labels.get('title.resin');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return this.labels.get('title.kit');
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return this.labels.get('title.group');
    }
    console.error('getHeaderTitle(): unknown entity type ' + entityType);
    return '';
  };

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

   renderPrompt = () => {
     return (
       <Prompt
         when={this.props.isDirty}
         message={(location) =>
           this.navigationLabels.get('datanotsaved.text')
         }
       />
     );
   };

  renderForm = () => {
    let {sData, entityType, actions} = this.props;
    if (!sData || !sData.get('formData')) {
      return null;
    }

    return (
            <CommonPdfLabelSettingsForm
              formData={sData.get('formData')}
              entityType={entityType}
              labels={this.labels}
              actions={actions}
            />
    );
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className='common-pdf-label-settings-view'>
        <Header {...headerItems}/>
        {this.renderForm()}

        {this.renderLoadingOverlay()}
        {this.renderPrompt()}
      </div>
    );
  }
}


CommonPdfLabelSettingsView.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  entityType : PropTypes.string.isRequired,
};




