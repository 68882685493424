import { api as gridApi} from './schedulingAutomationViewGridActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import * as schedulerService from '../../../services/Scheduler/schedulerService';
import Network from 'infrastructure/js/modules/network';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS

export const actionTypes = {
  ADMINISTRATION_SETTINGS_SCHEDULING_AUTOMATION_VIEW_FILTER: 'ADMINISTRATION_SETTINGS_SCHEDULING_AUTOMATION_VIEW_FILTER',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

jsxActions.remove = function(data) {
  return function(dispatch, getState) {
    api.remove(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.remove = function(dispatch, getState) {
  return function (data) {
    let ids = data?.map(item => item.id)?.toJS() || [];

    let labels = createLabelHelper('mat.administration.settings.schedulingAutomation.dialog.createSchedulingTrigger.');
    let confirmationConfig = {
      message: labels.get('remove.confirmation.message', '', {count: ids?.length}),
      cancelHandler: messageDialogApi.close(dispatch, getState),
      submitHandler: () => api.doRemove(dispatch, getState)(ids),
    };

    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
  }
};

api.doRemove = function(dispatch, getState) {
  return function(data) {
    messageDialogApi.close(dispatch, getState)();

    schedulerService.removeSchedulingAutomationTrigger(data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to remove scheduling trigger(s)', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      gridApi.reload(dispatch, getState)();
    });
  }
};
