import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import ImportRollsGrid from '../ImportFilesGrids/ImportRollsGrid/ImportRollsGrid';
import ImportWorkOrdersGrid from '../ImportFilesGrids/ImportWorkOrdersGrid/importWorkOrdersGrid';
import ImportKitsGrid from '../ImportFilesGrids/ImportKitsGrid/importKitsGrid';
import ImportGroupsGrid from '../ImportFilesGrids/ImportGroupsGrid/importGroupsGrid';
import ImportToolsGrid from '../ImportFilesGrids/ImportToolsGrid/importToolsGrid';
import ImportTagsGrid from '../ImportFilesGrids/ImportTagsGrid/ImportTagsGrid';
import ImportKitTypesGrid from '../ImportFilesGrids/ImportKitTypesGrid/ImportKitTypesGrid';
import ImportMaterialTypesGrid from '../ImportFilesGrids/ImportMaterialTypesGrid/ImportMaterialTypesGrid';
import ImportToolTypesGrid from '../ImportFilesGrids/ImportToolTypesGrid/ImportToolTypesGrid';
import ImportNestsGrid from '../ImportFilesGrids/ImportNestsGrid/ImportNestsGrid';
import ImportNestsWithRollGrid from '../ImportFilesGrids/ImportNestsWithRollGrid/ImportNestsWithRollGrid';
import ImportNestsMultiRollGrid from '../ImportFilesGrids/ImportNestsMultiRollGrid/ImportNestsMultiRollGrid';
import ImportOperationSequenceGrid from '../ImportFilesGrids/ImportOperationSequenceGrid/ImportOperationSequenceGrid';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import {ImportTypes} from '../../../../enums/importTypes';
import ImportGroupTypesGrid from '../ImportFilesGrids/ImportGroupTypesGrid/ImportGroupTypesGrid';
import ImportSpoolsGrid from '../ImportFilesGrids/ImportSpoolsGrid/ImportSpoolsGrid';
import ImportShipmentDocumentGrid from '../ImportFilesGrids/ImportShipmentDocumentGrid/importShipmentDocumentGrid';
import ImportPartTypesGrid from '../ImportFilesGrids/ImportPartTypesGrid/ImportPartTypesGrid';
import ImportProjectsGrid from '../ImportFilesGrids/ImportProjectsGrid/ImportProjectsGrid';
import ImportAsnShipmentsGrid from '../ImportFilesGrids/ImportAsnShipmentsGrid/importAsnShipmentsGrid';
import ImportResinsGrid from '../ImportFilesGrids/ImportResinsGrid/ImportResinsGrid';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import ImportToolGroupsGrid from '../ImportFilesGrids/ImportToolGroupsGrid/ImportToolGroupsGrid';

import'./importFileStep.scss';

export class ImportFileStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPrintRfidTags: false,
    };

    this.labels = createLabelHelper('mat.wizards.validate.files.');
    this.typeLabels = createLabelHelper('mat.wizards.import.types.');
  }

  componentDidMount() {
    this.props.initialize({
      checkboxRight:false,
      numberOfTags: null,
    })
  }

  onHide = () => {
    if (this.props.loading) {
      return;
    }
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  reloadParentComponent = () => {
    return this.props.parentComponent;
  };

  onSubmit = (formData) => {
    let data;
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS){
      data = {
        userFileName: this.props.sSelectedFile.name,
        rollId: this.props.rollId,
        materialBusinessId: this.props.materialBusinessId,
        nestItems: this.props.nestsItems
      };
    } else {

      if (this.state.isPrintRfidTags && !formData.numberOfTags ) {
        return Validation.required(formData.tagsCount);
      }
      data = {
        userFileName: this.props.sSelectedFile.name,
        token: this.props.sFileToken,
        entityType: this.props.sSelectedAseetType,
        printLabels: formData.checkboxRight ? formData.checkboxRight : false,
        tagsCount: formData.numberOfTags || null,
        shouldIncludeLocation: this.props.shouldIncludeLocation
      };
    }
    this.props.actions.importFile(data,this.getMessageDialogBuilder(), this.reloadParentComponent());
    // this.props.actions.importFileWithProgress(data,this.getMessageDialogBuilder(), this.reloadParentComponent());
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      if (this.props.sSelectedAseetType === 'operationSequence') {
        let title = this.labels.get('dialog.operationSequence.title');
        //Note: last param should be 'kittypes' to display 'kit types' instead of 'operation sequence'
        return dialogHelper.BuildDialogDescriptorForImportFile(response, fnCloseDialog, title, 'kittypes');
      }

      let assetTypeLabel = this.typeLabels.get(this.props.sSelectedAseetType, undefined, {count: response.totalJobItems || 0});
      let title = this.labels.get('dialog.title', undefined,{ assetsCount: response.totalJobItems, assetType: assetTypeLabel});

      if (response?.stepDtoMap?.createMultiRollsRobustCuttingStep) {
        title = this.labels.get('dialog.robustCutting.title');
      }

      return dialogHelper.BuildDialogDescriptorForImportFile(response, fnCloseDialog, title, this.props.sSelectedAseetType);
    };
  };

  goBack = () => {
    let wizardActions = this.props.wizardActions;
    wizardActions.previous();
  };

  isImportButtonDisabled = () => {
    // if(this.props.sValidatedFileData.totalItems === this.props.sValidatedFileData.errorItems) {
    //Note: to avoid Server bug (totalItems are not correct for the duplicated ids SAAS-15200)
    if(this.props.sValidatedFileData?.logContentItems?.length === this.props.sValidatedFileData?.errorItems) {
      return true;
    }
    return !this.props.sPrintLabelsErrors ? this.props.loading : false;
  }

  getDialogButtons() {

    let isButtonDisabled = this.isImportButtonDisabled();

    let buttonOK = {
      id:'submit',
      text: this.labels.get('footer.import.file'),
      bsStyle: "primary",
      loading: this.props.loading,
      disabled: isButtonDisabled,
      action: this.props.handleSubmit(this.onSubmit)
    };

    let leftButtons = [{
      id:'cancel',
      text: this.labels.get('footer.back'),
      disabled:  this.props.loading,
      action: this.goBack
    }];

    let buttonExport = {
      id:'export',
      text: this.labels.get('footer.export'),
      disabled:  this.props.loading,
      action: this.props.actions.exportValidationReport,
    };

    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS ||
        this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS_WITH_ROLL ||
        this.props.sSelectedAseetType === ImportTypes.IMPORT_OPERATION_SEQUENCE ||
        this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS_MULTI_ROLLS ||
        this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS_ANY_ROLLS) {

      leftButtons.push(buttonExport);
    }
    return { left: leftButtons, right: [buttonOK] };
  }

  onPrintRfidTagsChanged = (value) => {
    this.props.change('numberOfTags', value.target.checked ? 1 : null );
    this.setState({isPrintRfidTags: value.target.checked})
  };

  isCheckboxShow = () => {
    return this.props.showPrintLabels;
  };

  getIconPopOver = (data) => {
    if (data) {
      return (<AlertPopover popoverType={data.severity}
                            popoverComponent={<div>{data.message}</div>}
      />);
    }
    return null;
  };

  getCheckbox() {
    if (!this.isCheckboxShow()) {
      return null;
    }

    let iconData = null;

      if(this.props.sPrintLabelsErrors){
        iconData = {
          severity : this.props.sPrintLabelsErrors.statusType,
          icon: 'pl pl-warning-icon',
          message : this.props.sPrintLabelsErrors.message
        }
      }

    let textBefore = this.labels.get('footer.print.labels');
    let textAfter = this.labels.get('footer.printForAssets.labels', '', {assetType:this.props.sSelectedAseetType})

    return (
      <div className="checkbox-right-section">
        {this.getIconPopOver(iconData)}

        <Checkbox name="checkboxRight" id="checkboxRight"
                  disabled={this.props.loading}
                  onChange={this.onPrintRfidTagsChanged}/>

        <label  htmlFor="checkboxRight">{textBefore}</label>

        <TextField  name="numberOfTags" id="numberOfTags"
                    disabled={!this.state.isPrintRfidTags || this.props.loading}
                    validate={this.state.isPrintRfidTags ? Validation.required : []}
                    normalize={Normalize.number(true, 1, 10)}/>

        <label htmlFor="numberOfTags">{textAfter}</label>
      </div>
    )
  }

  getTitleText = () => {
      return `${this.labels.get('validate')} ${this.props.sValidatedFileData.totalItems} ${this.typeLabels.get(this.props.sSelectedAseetType, undefined, {count:this.props.sValidatedFileData.totalItems || 0})}`;
  }

  renderImportInfo = () => {
    return (
      <div className='import-info-selection'>
        <label>{this.labels.get('import.info.title')}</label>

        {this.renderImportInfoItems()}
      </div>
    );
  }

  renderImportInfoItems = () => {
    let errorIcon = 'pl pl-canceled-icon';
    let warningIcon = 'pl pl-warning-icon';
    let successIcon  = 'pl pl-check-circle-icon-blue blue-color';
    let successItems = this.props.sValidatedFileData.successItems;
    let warningItems = this.props.sValidatedFileData.warningItems;
    let errorItems = this.props.sValidatedFileData.errorItems;

     return (
       <div className='import-info-items'>
         {successItems > 0 ?
           <div className='import-info-item'>
             <span className={`import-info-icon ${successIcon}`}/>
             <label className='import-info-msg'>{`${successItems} ${this.getSuccessLabels(successItems)}`}</label>
           </div> : null}
         {warningItems > 0 ?
           <div className='import-info-item'>
             <span className={`import-info-icon ${warningIcon} warning`}/>
             <label className='import-info-msg'>{`${warningItems} ${this.getWarningLabels(warningItems)}`}</label>
           </div> : null}
         {errorItems > 0 ?
           <div className='import-info-item'>
             <span className={`import-info-icon ${errorIcon}`}/>
             <label className='import-info-msg'>{`${errorItems} ${this.getFailedLabels(errorItems)}`}</label>
           </div>: null}
       </div>
     );
  }

  getSuccessLabels(count) {
    let assetType = this.typeLabels.get(this.props.sSelectedAseetType, undefined, {count: count});
    let labels = `${this.typeLabels.get(this.props.sSelectedAseetType, undefined,{count: count})} ${this.labels.get('import.info.will.created.successfully')}`;
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS || this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS_MULTI_ROLLS) {
      labels = this.labels.get('import.info.will.createdupdated.successfully', undefined, {count:count, assetType: assetType});
    }
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_OPERATION_SEQUENCE) {
      labels = this.labels.get('import.info.will.report.successfully', undefined, {count:count, assetType: assetType});
    }
    return labels;
  }
  getWarningLabels(count) {
    let assetType = this.typeLabels.get(this.props.sSelectedAseetType, undefined, {count: count});
    let labels = `${this.typeLabels.get(this.props.sSelectedAseetType, undefined,{count: count})} ${this.labels.get('import.info.will.created.warnings')}`;
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS || this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS_MULTI_ROLLS) {
      labels = this.labels.get('import.info.will.createdupdated.warnings', undefined, {count:count, assetType: assetType});
    }
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_OPERATION_SEQUENCE) {
      labels = this.labels.get('import.info.will.report.warnings', undefined, {count:count, assetType: assetType});
    }
    return labels;
  }
  getFailedLabels(count) {
    let assetType = this.typeLabels.get(this.props.sSelectedAseetType, undefined, {count: count});
    let labels = `${this.typeLabels.get(this.props.sSelectedAseetType, undefined,{count: count})} ${this.labels.get('import.info.will.not.be.created')}`;
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS || this.props.sSelectedAseetType === ImportTypes.IMPORT_NESTS_MULTI_ROLLS) {
      labels = this.labels.get('import.info.will.not.be.createdupdated', undefined, {count:count, assetType: assetType});
    }
    if (this.props.sSelectedAseetType === ImportTypes.IMPORT_OPERATION_SEQUENCE) {
      labels = this.labels.get('import.info.will.not.be.reported', undefined, {count:count, assetType: assetType});
    }
    return labels;
  }

  renderImportGridByAssetType = () => {
    let {sSelectedAseetType, sValidatedFileData} = this.props;
    switch(sSelectedAseetType) {
      case ImportTypes.IMPORT_ROLLS:
         return ( <ImportRollsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_SPOOLS:
         return ( <ImportSpoolsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_RESINS:
         return ( <ImportResinsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_SHIPMENTS:
        return ( <ImportShipmentDocumentGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_ASN_SHIPMENTS:
        return ( <ImportAsnShipmentsGrid rows={sValidatedFileData.logContentItems} />);

      case ImportTypes.IMPORT_WO:
        return ( <ImportWorkOrdersGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_KITS:
        return ( <ImportKitsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_GROUPS:
        return ( <ImportGroupsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_TOOLS:
        return ( <ImportToolsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_TAGS:
        return ( <ImportTagsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_KIT_TYPES:
        return ( <ImportKitTypesGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_MATERIAL_TYPES:
        return ( <ImportMaterialTypesGrid rows={sValidatedFileData.logContentItems} />);
     case ImportTypes.IMPORT_TOOL_TYPES:
        return ( <ImportToolTypesGrid rows={sValidatedFileData.logContentItems} />);
     case ImportTypes.IMPORT_TOOL_GROUPS:
        return ( <ImportToolGroupsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_GROUP_TYPES:
        return ( <ImportGroupTypesGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_PART_TYPES:
        return ( <ImportPartTypesGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_PROJECTS:
        return ( <ImportProjectsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_OPERATION_SEQUENCE:
        return ( <ImportOperationSequenceGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_NESTS:
        return ( <ImportNestsGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_NESTS_WITH_ROLL:
        return ( <ImportNestsWithRollGrid rows={sValidatedFileData.logContentItems} />);
      case ImportTypes.IMPORT_NESTS_MULTI_ROLLS:
        return ( <ImportNestsMultiRollGrid rows={sValidatedFileData.logContentItems} />);
      default:
        console.error('ImportFileStep: Unknown asset type: ' + sSelectedAseetType);
    }
  }

  render() {
    if(!this.props.sValidatedFileData){
      return null;
    }
      return (
        <Dialog
          id="import-files-dialog"
          className="import-files-dialog"
          show={true}
          onHide={this.onHide}
          titleText= {this.getTitleText()}
          moreInfoTextHeader={this.props.currentDate}
          footerButtons={this.getDialogButtons()}
          footerCenterText={this.getCheckbox()}
          handleHashChange={false}
        >

        {this.props.bodyTopSection}

        {this.renderImportInfo()}

        {this.renderImportGridByAssetType()}

        </Dialog>
      );
  }

}

export default reduxForm({
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: false,
  }
)(ImportFileStep);

ImportFileStep.propTypes = {
  currentDate:  PropTypes.string,
  sValidatedFileData: PropTypes.object.isRequired,
  sSelectedAseetType: PropTypes.string.isRequired,
};
