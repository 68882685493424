import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { reduxForm } from 'redux-form';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import useLabels from 'infrastructure/js/hooks/useLabels';
import {gridsNames} from '../../../../../../enums/gridsNames';
import CheckMarkCell from '../../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import {enumTypes, getEnumLabel} from '../../../../../../utils/enumHelper';
import SchedulingPreferencesCell from '../../../../../Common/CustomGridCells/SchedulingPreferencesCell/schedulingPreferencesCell';

require('./schedulingAutomationViewGrid.scss');

const filterConfig = [
  { fieldName: 'active', filterName: 'active', getOptions: false },
  { fieldName: 'name', filterName: 'name', getOptions: true },
  { fieldName: 'triggerType', filterName: 'type', getOptions: true },
];

function SchedulingAutomationViewGrid( {actions}) {
  const labels = useLabels('mat.administration.settings.schedulingAutomation.schedulingTriggers.grid.');

  const columnsConfig = useMemo(() => {
    return [
      {
        fieldName: 'active',
        title: labels.get('columns.active.title'),
        filterName: 'active',
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
        }
      },
      {
        fieldName: 'name',
        title: labels.get('columns.name.title'),
        filterName: 'name',
        filterType: filterTypes.MULTI_SELECT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'triggerType',
        title: labels.get('columns.type.title'),
        filterName: 'type',
        filterType: filterTypes.MULTI_SELECT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.SCHEDULING_TRIGGER_TYPE)(params.data.triggerType);
          }
        },
      },
      {
        fieldName: 'displayName',
        title: labels.get('columns.schedulingPreferences.title'),
        columnOptions: {
          sortable: false,
          cellComponent: SchedulingPreferencesCell,
          valueGetter: (params) => {
            return params.data;
          }
        },
      },
    ];
  }, []);

  const getRowHeight = (params) => {
    return 160;
  }

  return (
    <div className="scheduling-automation-grid">
      <Grid
        gridName={gridsNames.ADMINISTRATION_SETTINGS_SCHEDULING_AUTOMATION}
        columnsConfig={columnsConfig}
        actions={actions}
        filterConfig={filterConfig}
        gridProps={{
          getRowHeight: getRowHeight,
        }}
      />
    </div>
  );
}

export default reduxForm({
  form: 'schedulingAutomationViewGrid',
})(SchedulingAutomationViewGrid);


SchedulingAutomationViewGrid.propTypes = {
  actions: PropTypes.object.isRequired,
};
