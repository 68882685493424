import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize        from 'infrastructure/js/components/controls/controlsNormalizations';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DropDown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import RuleListItem from '../../../../../Common/Controls/AddRemoveList/AddRemoveListItem/RuleListItem/ruleListItem';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import List from 'infrastructure/js/components/List/list';

require('./createAlertDeviceDialog.scss');

class CreateAlertDeviceDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.rfidsettings.dialog.createalertdevice.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    let dialogData = this.props.sData.get('dialogData');
    this.readersOptions = dialogData && dialogData.readers ? dialogData.readers : [];
    this.antennasOptions = dialogData && dialogData.antennas ? dialogData.antennas : [];
    this.businessRules = dialogData && dialogData.businessRules ? dialogData.businessRules : [];

    let namesInUse = [];
    if (this.isEditMode && this.itemToEdit.businessRules) {
      this.itemToEdit.businessRules.map((item) => {
        namesInUse.push(item.propertyName);
      } );
    }

    this.state = { ruleNamesInUse: namesInUse};
  }

  componentDidMount() {

    let initialValues = {
      antennasTypeSelect : {value: 1, label: this.labels.get('antennas')}
    };

    if (this.isEditMode) {
      initialValues.alertDeviceName = this.itemToEdit.name;
      initialValues.alertDeviceIp = this.itemToEdit.host;
      initialValues.reader = this.itemToEdit.reader ? this.readersOptions.find(r => r.value === this.itemToEdit.reader.businessId ) : null;
      initialValues.alertDeviceGpoId = this.itemToEdit.gpoId;
      initialValues.antennasSelect = this.getInitialAntennas(this.itemToEdit.discoAntennas);
      initialValues.businessRulesList = this.getInitialBusinessRules(this.itemToEdit.businessRules);
    }

    this.props.initialize(initialValues);
  }

  getInitialAntennas = (items) => {
    if (items) {
      return items.map((item) => {return {value: item.id, label: item.name, data: item} });
    }
    return [];
  };

  getInitialBusinessRules = (rules) => {
    if (rules) {
      return rules.map((item) => {
        return {
          ruleProperty: {value:item.propertyName, label: item.propertyName, data: {name: item.propertyName, type: item.propertyType}},
          ruleSign: {value: item.ruleOperator, label: item.ruleOperatorDisplayName},
          ruleValue: this.getRuleValue(item)
        };
      });
    }
    return [];
  };

  getRuleValue = (item) => {
    //convert to Number for the 'Integer' type, otherwise - string
    // return item.propertyType === 'Integer' ? +item.value : item.value;
    if (item.propertyType === 'Integer') {
      return +item.value;
    }
    else if (item.propertyType === 'Enum') {
      return {value: item.value, label: item.value}
    }
    return item.value;
  };

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.labels.get('footer.edit') : this.labels.get('footer.create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  getAlertMessageDialogBuilder = (name) => {
    return (fnCloseDialog) => {

      let title = this.labels.get('defaultdevice.alert.message', undefined, {antennaName: name}) ;
      let className = 'oneBackground';
      let type = 'error';

      let messageDialogDescriptor = {title, className, type};
      return messageDialogDescriptor;
    };
  };

  validateBeforeSubmit = (data) => {
    // if it is a default device (with no rules) - validate that all selected antennas
    // have no default devise already set
    let isDefaultDevice = data && (!data.businessRulesList || data.businessRulesList.length === 0);

    if (isDefaultDevice) {
      let antennaWithDefaultDeviceFound = data.antennasSelect.find((an) => {
        return !!an.data.defaultDiscoUsages && !(this.isEditMode && this.itemToEdit.name === an.data.defaultDiscoUsages);
      });

      if (antennaWithDefaultDeviceFound) {
        this.props.actions.showAlert(this.getAlertMessageDialogBuilder(antennaWithDefaultDeviceFound.data.name));
        return false;
      }
    }
    return true;
  };

  onSubmit = (data) => {

    if (!this.validateBeforeSubmit(data)) {
      return;
    }

    let discoAntennas = data && data.antennasSelect ? data.antennasSelect.map((item) => {
      return {businessId: item.data.businessId}
    }) : [];

    let discoBusinessRules = data && data.businessRulesList ? data.businessRulesList.map((item) => {
        let newItem =  {
          propertyName: item.ruleProperty.value,
          propertyType: item.ruleProperty.data.type,
          ruleOperator: item.ruleSign.value,
          value: (item.ruleProperty.data.type === 'Enum' ? item.ruleValue.value : item.ruleValue),
                        };
        if (this.isEditMode) {
          newItem.orgId = this.itemToEdit.orgId;
        }
        return newItem;
      }) : [];

    let newData = {
      deviceAppProfileId: this.props.deviceAppProfileId,
      name: data.alertDeviceName,
      host: data.reader ? data.reader.data.host : null,
      gpoId: data.alertDeviceGpoId,
      discoType: 'SINGLE',
      discoAntennas: discoAntennas,
      discoBusinessRules: discoBusinessRules
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  };



  onChangeCallback = (value, oldValue, index) => {
    let namesInUse = [...this.state.ruleNamesInUse];

    if (oldValue) { //no oldValue on first change
      namesInUse[index] = null;
    }

    if (value) { //no value when clear the selection
      namesInUse[index] = value.data ? value.data.name : null;
    }

    this.setState({ruleNamesInUse: namesInUse});
  };
  onRemoveCallback = (index) => {
    let namesInUse = [...this.state.ruleNamesInUse];

    if (index > -1) {
      namesInUse.splice(index, 1);
    }

    this.setState({ruleNamesInUse: namesInUse});
  };
  onAddCallback = () => {
    let namesInUse = [...this.state.ruleNamesInUse];

    namesInUse.push(null); //hold index in ruleNamesInUse for the new added item

    this.setState({ruleNamesInUse: namesInUse});
  };

  getTypeByIndex = (index) => {
    let namesInUse = [...this.state.ruleNamesInUse];
    if (index > -1 && index < namesInUse.length) {
      return namesInUse[index];
    }
    return null;
  };

  listItemRenderer = (data) => {
    return (
      <RuleListItem
        {...data}
        labels={this.labels}
        options={this.businessRules}
        onChangeCallback={this.onChangeCallback}
        ruleName={this.getTypeByIndex(data.index)}
        {...this.props}
      />
    )
  };

  getMaxGpoByReaderType(type){
    switch (type) {
      case 'IMPINJ':
      case 'ZEBRA':
        return 4;
      default:
        console.error('getMaxGpoByReaderType (): unknown reader type ' + type);
        return 0;
    }
  }

  validateAlertDeviceGpoId = (value, allValues)=>{
    if(!allValues.reader){
      return undefined;
    }

    let maxGpo = this.getMaxGpoByReaderType(allValues.reader.data.readerType);
    if(value > maxGpo){
      return this.labels.get('invalidGpoForReader');
    }

    return undefined;
  };

  renderBusinessRulesList = () => {
    return(
      <div className='rules-wrapper'>
        <div className='rules-header'>
          <label>{this.labels.get('rule.header.property')}</label>
          <label>{this.labels.get('rule.header.sign')}</label>
          <label>{this.labels.get('rule.header.value')}</label>
        </div>

        <List name='businessRulesList'
                       itemRenderer={this.listItemRenderer}
                       preSelectedItems={this.itemToEdit && this.itemToEdit.businessRules ? this.itemToEdit.businessRules : []}
                       addButtonLabel={this.labels.get('rule.add')}
                       onRemoveCallback={this.onRemoveCallback}
                       onAddCallback={this.onAddCallback}
                       maxItemsToOverflow={6}
        />
      </div>
    )
  };

  maxLength30 = Validation.maxLength(30);
  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-alert-device-dialog"
        className="create-alert-device-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}>


        <InputSection label='' className="full no-margin">

          <DropDown
            key={'antennasTypeSelect'}
            id={'antennasTypeSelect'}
            name={'antennasTypeSelect'}
            className='antennas-type-select'
            isDisabled={true}
          />
          <PL_MultiSelectField name='antennasSelect' id='antennasSelect'
                               closeMenuOnSelect={false}
                               validate={Validation.dropdown.required}
                               className='antennas-select'
                               options={this.antennasOptions} />
        </InputSection>

        <div className="device-params-section">

          <InputSection label={this.labels.get('devicename')+'*'} htmlFor="alertDeviceName" className="inline small no-margin">
            <TextField id="alertDeviceName"
                       name="alertDeviceName"
                       className="short-textfield"
                       validate={[Validation.required, this.maxLength30]}/>
          </InputSection>

          <InputSection label={this.labels.get('host')+'*'} htmlFor="reader" className="inline small no-margin">
            <Combobox id="reader"
                      name="reader"
                      options={this.readersOptions}
                      validate={Validation.required}
                      className="short-textfield"/>
          </InputSection>

          <InputSection label={this.labels.get('gpoid')+'*'} htmlFor="alertDeviceGpoId" className="inline small no-margin">
            <TextField id="alertDeviceGpoId"
                       name="alertDeviceGpoId"
                       className="short-textfield"
                       normalize={Normalize.number(true, 1, 4)}
                       validate={[Validation.number.required, this.validateAlertDeviceGpoId ]}
            />
          </InputSection>
        </div>

        {this.renderBusinessRulesList()}


      </Dialog>
    );
  }
}

CreateAlertDeviceDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  deviceAppProfileId: PropTypes.number.isRequired,
};

export default reduxForm({
    form: 'createAlertDeviceDialog',
  }
)(CreateAlertDeviceDialog);



