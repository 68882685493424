// @ts-check

import MapControlSettings from "./MapControlSettings";
import PolyData from "../types/PolyData";
import MapTimers from "../Logic/MapTimers";
import { CAMERA_EASING_MODE } from "@mappedin/mappedin-js";


/**
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").Pl_Map} Pl_Map
 * 
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LayerSettings} PolySetting
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LayerSettingRecord} PolySettingRecord
 * 
 * 
 */



export default class MapControl {


  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static map_OnReady = (refContext) => {
    // console.log('MapControl ===> map_OnReady', );
    MapTimers.map_OnReady(refContext);
    this.initCurrentFloorData(refContext);
  }

  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static map_OnDestroy = (refContext) => {
    // console.log('MapControl ===> map_OnDestroy', );
    MapTimers.map_OnDestroy(refContext);
  }



  /** 
   * When map is initialized, sets its current default floor name to sData.
   * 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   */
  static initCurrentFloorData = (refContext) => {
    
    let currentFloorName = refContext.current.refMapView.current?.currentMap?.shortName;
    if (!currentFloorName) {
      return;
    }
    
    this.setFloorData(refContext, currentFloorName);
  }

  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {string} newFloorName
   */
  static setFloorData = (refContext, newFloorName) => {
    refContext.current.actions.setFloor(newFloorName);
  }

  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {string} newFloorName
   */
  static setFloor = (refContext, newFloorName) => {
    
    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }
    
    let venue = mapView.venue;
    let m = venue.maps.find((map) => {
      return (map.shortName === newFloorName);
    });
    
    if (!m) {
      return;
    }
    
    mapView.setMap(m);
    
  }



  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static init__PolyData = (refContext) => {
    // console.log('init__PL_Polygons ===> ');

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    let venue = mapView.venue;
    if (!venue) {
      return;
    }


    let polyData = new PolyData();

    venue.polygons.forEach((polygon) => {

      let p = polygon;

      let layerName = p.layer;
      if (!layerName) {
        return;
      }

      let layerSetting = MapControlSettings.POLY_SETTINGS.layers[layerName];
      if (!layerSetting) {
        return;
      }

      let normalColor = layerSetting.color;
      let interactive = layerSetting.interactive;

      polyData.addNewPolygon(p, normalColor, interactive);

    });


    refContext.current.refPolyData.current = polyData;

  }



  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static recolorMap__MODE_NORMAL = (refContext) => {
    // console.log('RECOLOR 2 ===> ');

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    let venue = mapView.venue;
    if (!venue) {
      return;
    }

    let polyData = refContext.current.refPolyData.current;
    if (!polyData) {
      return;
    }


    ////////////////////////////////////
    // Color polygons of interest
    //

    venue.polygons.forEach((polygon) => {

      let p = polygon;

      let polyInfo = polyData.getPolygonInfoById(p.id);
      if (!polyInfo) {
        return;
      }

      if (!polyInfo.normalColor) {
        return;
      }

      mapView.setPolygonColor(p, polyInfo.normalColor);
      mapView.setPolygonHoverColor(p, polyInfo.normalColor);


    });


  }



  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static setInteractivity__MODE_NORMAL = (refContext) => {
    // console.log('INTERACTIVITY 2 ===> ');

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    let venue = mapView.venue;
    if (!venue) {
      return;
    }

    let polyData = refContext.current.refPolyData.current;
    if (!polyData) {
      return;
    }


    //////////////////////////////////////////////////////////
    // Add interactivity to polygons of interest
    //
    venue.polygons.forEach((p) => {

      let layer = p.layer;
      if (!layer) {
        return;
      }

      let polyInfo = polyData.getPolygonInfoById(p.id);
      if (!polyInfo) {
        return;
      }

      if (!polyInfo.interactive) {
        return;
      }

      mapView.addInteractivePolygon(p);

    });

  }


  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static setInteractivity__MODE_SEARCH = (refContext) => {
    // console.log('INTERACTIVITY 2 ===> ');

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    let venue = mapView.venue;
    if (!venue) {
      return;
    }

    let polyData = refContext.current.refPolyData.current;
    if (!polyData) {
      return;
    }


    mapView.removeAllInteractivePolygons();

    // //////////////////////////////////////////////////////////
    // // remove interactivity from default polygons
    // //
    // venue.polygons.forEach((p) => {

    //   let layer = p.layer;
    //   if (!layer) {
    //     return;
    //   }

    //   let polyInfo = polyData.getPolygonInfoById(p.id);
    //   if (!polyInfo) {
    //     return;
    //   }

    //   if (!polyInfo.interactive) {
    //     return;
    //   }

    //   mapView.removeInteractivePolygon(p);

    // });

  }



  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static setupCamera__MODE_NORMAL = (refContext) => {

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    /** @type {Pl_Map} */
    let map = mapView.currentMap;

    // console.log('XXXXXXXXXXXXXXXXXXX => map ===> ', map);
    // console.log('XXXXXXXXXXXXXXXXXXX => map.pl_Metadata ===> ', map.pl_Metadata);

    let mapCalculations = map.pl_Metadata?.mapCalculations;
    if (!mapCalculations) {
      console.log(`No PL metadata found for map ${map.name}`);
      return;
    }


    // mapView.venue.maps.forEach((map) => {
    //   console.log('map ===> ', map);

    //   // @ts-ignore
    //   console.log('ZZZZZZZZZZZZZZZ map.pl_Metadata ===> ', map.pl_Metadata);
    // });


    let minZoom = mapCalculations.minZoomLimit;
    mapView.Camera.minZoom = minZoom;

  }


  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static setupCamera__GO_DEFAULT = (refContext) => {

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    /** @type {Pl_Map} */
    let map = mapView.currentMap;

    let mapCalculations = map.pl_Metadata?.mapCalculations;
    // console.log('mapCalculations ===> ', mapCalculations);

    if (!mapCalculations) {
      return;
    }

    mapView.Camera.set(mapCalculations.camera_Default);

  }

  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static setupCamera__GO_DEFAULT__SMOOTH = (refContext) => {

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    /** @type {Pl_Map} */
    let map = mapView.currentMap;

    let mapCalculations = map.pl_Metadata?.mapCalculations;
    // console.log('mapCalculations ===> ', mapCalculations);

    if (!mapCalculations) {
      return;
    }

    mapView.Camera.animate(mapCalculations.camera_Default, {
      duration: 500,
      easing: CAMERA_EASING_MODE.EASE_OUT
    });

  }



  static DOM_repositionAndActivateCurrentMarkers = () => {

    let markers = document.querySelectorAll('.mappedin__smart-collision-engine__container .mappedin-marker');
    if (markers.length === 0) {
      return;
    }
    // console.log('markers ===> ', markers);


    for (let i = 0; i < markers.length; i++) {

      let marker = markers[i];
      if (!(marker instanceof HTMLDivElement)) {
        return;
      }


      let customMarker = marker.querySelector('.custom-marker');
      if (!(customMarker instanceof HTMLDivElement)) {
        return;
      }

      let anchorTop = customMarker.dataset.anchorTop;
      if (!anchorTop) {
        return;
      }

      let anchorLeft = customMarker.dataset.anchorLeft;
      if (!anchorLeft) {
        return;
      }

      let anchorTopVal = parseFloat(anchorTop);
      if (isNaN(anchorTopVal)) {
        return;
      }

      let anchorLeftVal = parseFloat(anchorLeft);
      if (isNaN(anchorLeftVal)) {
        return;
      }


      // console.log(`proxying offsets ===> top: ${anchorTopVal} left${anchorLeftVal}`, marker);

      try {
        this.DOM_forceAnchorOnMappedInMarker(marker, anchorTopVal, anchorLeftVal);
      } catch (error) {
        console.log('Failed to proxy offsets ===> ', marker, error);
      }

      customMarker.style.opacity = '0.9';

      // window.setTimeout(() => {
      //   console.log('window.setTimeout ===> customMarker.style.opacity', customMarker);
      //   // customMarker.style.visibility = 'visible';
      //   customMarker.style.opacity = '0.9';
      // }, 1000);

    }



  }


  /**
   * @param {HTMLDivElement} marker
   * @param {number} forceTop
   * @param {number} forceLeft
   */
  static DOM_forceAnchorOnMappedInMarker = (marker, forceTop, forceLeft) => {

    // attribute name: data-offset-proxy
    let hasProxyAlready = (marker.dataset.offsetProxy === 'true');
    if (hasProxyAlready) {
      return;
    }
    
    // console.log('DOM_forceAnchorOnMappedInMarker ===> ', marker);
    
    
    /**
     * @param {string} value
     * @param {number} force
     * @returns {string}
     */
    function modifyCssValue(value, force) {
      let vTrim = value.replace('px', '');
      let v = parseFloat(vTrim);
      v = v + force;
      let newVal = `${v}px`;
      return newVal;
    }
    
    
    ///////////////////////////////////////////
    // Set the forced values once right now
    //
    
    let top = marker.style.top;
    let left = marker.style.left;
    
    let newTop = modifyCssValue(top, forceTop);
    let newLeft = modifyCssValue(left, forceLeft);
    
    marker.style.top = newTop;
    marker.style.left = newLeft;
    
    


    /////////////////////////////////////
    // Add proxy to style object.
    //

    const originalStyle = marker.style;

    const styleProxy = new Proxy(originalStyle, {

      set(target, property, value) {
        // console.log(`Style changed: ${property} = ${value}`);

        if (property === "top") {
          let newVal = modifyCssValue(value, forceTop);
          target[property] = newVal;
          // console.log(`Style changed: ${property} = ${value} ===> ${newVal}`, marker);
          
          return true;
        }

        if (property === "left") {
          let newVal = modifyCssValue(value, forceLeft);
          target[property] = newVal;
          // console.log(`Style changed: ${property} = ${value} ===> ${newVal}`, marker);

          return true;
        }


        // Default behavior

        // @ts-ignore
        target[property] = value;

        return true;
      }
    });


    Object.defineProperty(marker, 'style', {
      get: () => styleProxy,
      set: (newStyle) => {
        Object.assign(styleProxy, newStyle);
      }
    });


    marker.dataset.offsetProxy = 'true';

  }


  
  
  static DOM_repositionAndActivateCurrentMarkers_with_delays = () => {
    
    // The DOM_repositionAndActivateCurrentMarkers is idempotent, so we can call it multiple times.

    window.setTimeout(() => {
      // console.log('Delayed proxying 1 ===> ', );
      this.DOM_repositionAndActivateCurrentMarkers();
    }, 100);

    window.setTimeout(() => {
      // console.log('Delayed proxying 2 ===> ', );
      this.DOM_repositionAndActivateCurrentMarkers();
    }, 500);

    window.setTimeout(() => {
      // console.log('Delayed proxying 3 ===> ', );
      this.DOM_repositionAndActivateCurrentMarkers();
    }, 1000);

    window.setTimeout(() => {
      // console.log('Delayed proxying 3 ===> ', );
      this.DOM_repositionAndActivateCurrentMarkers();
    }, 5000);

  }
  


}







