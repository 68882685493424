// @ts-check

import { MapView } from "@mappedin/mappedin-js";

import { livemapIcons } from '../../../../../../../../mat/assets/svg/index';
import React from "react";


import './searchResultItem.scss';
import { ObjectType } from "../../../../../../../../mat/js/types/ServerTypes/enums/ObjectType";


/**
 * @typedef {import("mat/js/actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("mat/js/actions/LiveMap/types/liveMapPageTypes").LiveMapPageActions} LiveMapPageActions
 * @typedef {import("mat/js/types/ServerTypes/Models/LiveSearch").SeverityType} SeverityType
 * @typedef {import("mat/js/types/ServerTypes/Models/LiveSearch").LiveSearchResultItem} LiveSearchResultItem
 * 
 */

/**
 * @typedef {{
 *   itemData: LiveSearchResultItem
 *   sData:    PL_LiveMapPageState, 
 *   actions:  LiveMapPageActions
 * }} SearchResultItemProps
 * 
 */



/** 
 * @param {SearchResultItemProps} props
 * 
 */
let SearchResultItem = (props) => {


  // <div className='search-result-block__item'>
  //   <div className="item-title">
  //     <div className="item-icon">
  //       {livemapIcons.liveMapResult_Item__Tool()}
  //     </div>
  //     <div className="item-text">
  //       <span>ZZ-123-BA-gygy-17</span>
  //     </div>
  //   </div>
  //   <div className="item-alert-icon">
  //     {livemapIcons.liveMapResult_Alert__Error()}
  //   </div>
  // </div>


  // console.log('props ===> ', props);


  let item = props.itemData;

  let title = item.displayName;


  // let type = item.type;

  // let locationId = item.location?.locationId;
  // let locationName = item.location?.locationName;



  let block_item_clicked = () => {
    // console.log('block_item_clicked ===> ', item.type);
    props.actions.resultBlockItemClicked(item);
  }



  let assetIcon = getAssetIcon(item);
  let alertIcon = getAlertIcon(item);

  return (
    <>
      <div onClick={block_item_clicked} className='search-result-block__item'>
        <div className="item-title">
          <div className="item-icon">
            {assetIcon}
          </div>
          <div className="item-text">
            <span>{title}</span>
          </div>
        </div>
        {alertIcon}
      </div>

    </>
  );

};

export default SearchResultItem;




let materialTypes = [
  "ROLL",
  "SPOOL",
  "RESIN",
  "KIT",
  "HOOK",
  // "GROUP",
  // "PART"
];

let locationTypes = [
  "LOCATION",
  "STATION",
  "SECTION",
];


/** 
 * @param {LiveSearchResultItem} item
 * 
 */
let getAssetIcon = (item) => {

  let type = item.type;
  // console.log('type ===> ', type);

  if (type === "ROLL") {
    return livemapIcons.liveMapResult_Item__Roll();
  }
  if (type === "TOOL") {
    return livemapIcons.liveMapResult_Item__Tool();
  }
  if (type === "RESIN") {
    return livemapIcons.liveMapResult_Item__Resin();
  }
  if (type === "KIT") {
    return livemapIcons.liveMapResult_Item__Kit();
  }
  if (type === "GROUP") {
    return livemapIcons.liveMapResult_Item__Group();
  }
  if (type === "PART") {
    return (
      <div className="part-icon">
        {livemapIcons.liveMapResult_Item__Part()}
      </div>
    );
  }
  if (type === "SPOOL") {
    return (
      <div className="spool-icon">
        {livemapIcons.liveMapResult_Item__Spool()}
      </div>
    );
  }
  if (materialTypes.includes(type)) {
    return livemapIcons.liveMapResult_Item__Material();
  }


  if (type === "STATION") {
    return livemapIcons.liveMapResult_Item__Station();
  }
  if (type === "SECTION") {
    return livemapIcons.liveMapResult_Item__Section();
  }
  if (locationTypes.includes(type)) {
    return livemapIcons.liveMapResult_Item__Location();
  }


  if (type === "WO") {
    return livemapIcons.liveMapResult_Item__WO();
  }

  return null;
}


/** 
 * @param {LiveSearchResultItem} item
 * 
 */
let getAlertIcon = (item) => {

  let hasError = item.alertSeverity === "ERROR";
  if (hasError) {
    return (
      <div className={`item-alert-icon`}>
        {livemapIcons.liveMapResult_Alert__Error()}
      </div>
    );
  }

  let hasWarning = item.alertSeverity === "WARNING";
  if (hasWarning) {
    return (
      <div className={`item-alert-icon warning`}>
        {livemapIcons.liveMapResult_Alert__Warning()}
      </div>
    );
  }


  return null;
}


