import PropTypes from 'prop-types';
import {gridsNames} from '../../../../enums/gridsNames';
import DashboardGrid from './OrgsDashboardViewGrid/orgsDashboardViewGrid';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import OrgsDashboardHeader from './OrgsDashboardViewHeader/orgsDashboardViewHeader';
import './orgsDashboardView.scss';

export default function OrgsDashboardView({ actions, sHasRows }) {

  const getHeaderItems = () => {

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.orgsDashboardViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.orgsDashboardViewActions.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id: 'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.exportOrgsDashboardGrid,
          actionData: { gridName: gridsNames.ADMINISTRATION_ORGS_DASHBOARD },
        },
      ],
    };
    return items;
  }

  let headerItems = getHeaderItems();

  return (
    <div className="orgs-dashboard-view">
      <Header {...headerItems} />
      <OrgsDashboardHeader actions={actions.orgsDashboardViewActions}/>
      <DashboardGrid actions={{...actions.orgsDashboardViewGridActions, ...actions.fetchEntitiesActions}} />
    </div>
  );
}

OrgsDashboardView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
};
