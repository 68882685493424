import { List, fromJS } from 'immutable';
import { actionTypes as gridActionTypes } from './Utils/gridActionsHelper';
import GridHelper from './Utils/gridHelper';

let defaultState = {
};

let defaultNamedGridState = {
  gridName: null,
  filterConfig: [],
  columnsConfig: [],
  rows: null,
  filteredRowsCount: 0,
  filtersData: null,
  selectedRowsData: List(),
  filterState: List(),
  sortState: null,
  loading: false,
  error: false,
  queryResultInfo: null,
  abortController: new AbortController()
};

export default function(state = fromJS(defaultState), action) {
  let gridName = action.payload ? action.payload.gridName : null;
  if (!gridName) {
    return state;
  }

  // Prepare default named grid state for current action if needed.
  if (!state.get(gridName)) {
    if(action.payload.defaultState){
      defaultNamedGridState = {...defaultNamedGridState, ...action.payload.defaultState};
    }
    state = state.set(gridName, fromJS(defaultNamedGridState));
  }

  switch(action.type) {

    case gridActionTypes.GRID_FETCH_DATA_FINISHED:
      state = state.setIn([gridName, 'selectedRowsData'], List());
      state = state.setIn([gridName, 'filteredRowsCount'], action.payload.filteredRowsCount);
      state = state.setIn([gridName, 'filterState'], List(action.payload.filterModel));
      state = state.setIn([gridName, 'sortState'], action.payload.sortModel);
      state = state.setIn([gridName, 'rows'], List(action.payload.rows));
      state = state.setIn([gridName, 'filtersData'], List(action.payload.filtersData));
      state = state.setIn([gridName, 'queryResultInfo'], action.payload.queryResultInfo);
      state = state.setIn([gridName, 'loading'], false);
      state = state.setIn([gridName, 'abortController'], null);
      return state;

    case gridActionTypes.GRID_SELECTED_ROWS_CHANGED:
      return state.setIn([gridName, 'selectedRowsData'], List(action.payload.selectedRows));

    case gridActionTypes.GRID_FETCH_DATA_IN_PROGRESS:
      state = state.setIn([gridName, 'error'], false);
      state = state.setIn([gridName, 'abortController'], action.payload.abortController);
      return state.setIn([gridName, 'loading'], true);

    case gridActionTypes.GRID_FETCH_DATA_ERROR:
      state = state.setIn([gridName, 'loading'], false);
      state = state.setIn([gridName, 'abortController'], null);
      return state.setIn([gridName, 'error'], true);

    case gridActionTypes.GRID_INIT : {
      const abortController = state.getIn([gridName, 'abortController'])
      state = state.set(gridName, fromJS(defaultNamedGridState));
      let defaultSort = _getDefaultSort(gridName, action.payload.columnsConfig, action.payload.filterConfig);
      if (defaultSort) {
        state = state.setIn([gridName, 'sortState'], defaultSort);
      }

      if (action.payload.rows) {
        state = state.setIn([gridName, 'rows'], List(action.payload.rows));
      }

      return state
          .setIn([gridName, 'gridName'], action.payload.gridName)
          .setIn([gridName, 'gridNameSuffix'], action.payload.gridNameSuffix ?? '')
          .setIn([gridName, 'filterConfig'], action.payload.filterConfig)
          .setIn([gridName, 'columnsConfig'], action.payload.columnsConfig)
          .setIn([gridName, 'abortController'], abortController);
    }

    case gridActionTypes.GRID_CLEAR_FILTERS :
      return state
          .setIn([gridName, 'filterState'], action.payload.filterState);

    case gridActionTypes.GRID_UNMOUNT:
      return state.set(gridName, undefined);

    case gridActionTypes.GRID_UPDATE_ROW_DATA:
      state = state.setIn([gridName, 'rows'],action.payload.rows);
      return state;

    case gridActionTypes.GRID_SET_FILTER_STATE: {
      return state.setIn([gridName, 'filterState'], action.payload.filterState);
    }

    case gridActionTypes.GRID_SET_BUSY: {
      return state.setIn([gridName, 'loading'], action.payload.isBusy);
    }

    default:
      return state;
  }
}

let _getDefaultSort = function (gridName, columnsConfig, filterConfig) {

  //First, look for the sorting settings in the storage (if available)
  let columnsState = GridHelper.getColumnsStateFromStorage(gridName);
  let storedSortingColumnFound = columnsState?.find((column) => {return column.sort });

  if (storedSortingColumnFound) {
      let filterName = filterConfig?.find(item => item.fieldName === storedSortingColumnFound.colId)?.filterName || '';
      if (filterName) {
        return {filterName, direction: storedSortingColumnFound.sort};
      }
  }
  else { //Second, look for the default sorting in the columnsConfig
    let columnFound = columnsConfig?.find(column => column.columnOptions?.sort);
    if (columnFound) {
      let filterName = filterConfig?.find(item => item.fieldName === columnFound.fieldName)?.filterName || '';
      if (filterName) {
        return {filterName, direction: columnFound.columnOptions?.sort};
      }
    }
  }

  return {};
}













