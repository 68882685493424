import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import './carousel.scss';

const Carousel = ({ interval=5,
                    children
                  }) => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, interval);
    return () => clearInterval(intervalId); // Clean up on component unmount
  }, [children?.length, interval]);

  const renderIndicators = () => {
    if (children?.length <= 1) {
      return null;
    }
    return <div className="PL_carousel-indicators">
      {children?.map((item, index) => (
        <span key={index}
              className={`indicator ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
        />
      ))}
    </div>
  }

  return (
    <div className="PL_carousel">
      {children?.length ? children[currentIndex] : null}

      {renderIndicators()}
    </div>
  );
};

Carousel.propTypes = {
  interval: PropTypes.number,
  children: PropTypes.node,
};

export default Carousel;
