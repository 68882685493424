import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import CreateKitTypeDialog from './Dialogs/CreateKitTypeDialog/createKitTypeDialog';
import KitTypesViewGrid from './KitTypesViewGrid/kitTypesViewGrid';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import {MatSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import {isAllItemsActive, isAllItemsDeactive} from '../utils/matSettingsHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {gridsNames} from '../../../../enums/gridsNames.js';
import ImportKitTypesDialog from './Dialogs/ImportKitTypesDialog/importKitTypesDialog';
import {ImportAcceptFileTypes, ImportTypes} from '../../../../enums/importTypes';

export default class KitTypesView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.kittypes.view.');
  }

  componentDidMount() {
    this.props.actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_KIT_TYPES);
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;

    let items =  {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        ...(PermissionManager.hasCreateOrEditKitTypesPermissions() ? [ //Note: show the buttons only for PS users
          {
            id:'create',
            label: headerItemsLabels.CREATE,
            icon: 'pl pl-icon-add',
            action: actions.header.onCreateKitTypeClick,
          },
          {
            id:'edit',
            label: headerItemsLabels.EDIT,
            icon: 'pl pl-edit-icon',
            disabled: !(hasRows && selectedRowsData.size === 1),
            action: actions.header.onEditKitTypeClick,
            actionData: selectedRowsData.get(0)
          },
        ] : []),
      ],
      kebab: {
        menuItems: [
          {
            id:'activate',
            label: headerItemsLabels.ACTIVATE,
            action: actions.header.onActivateKitTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsDeactive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'deactvate',
            label: headerItemsLabels.DEACTIVATE,
            action: actions.header.onDeactivateKitTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size !== 1 || !isAllItemsActive(selectedRowsData)),
            actionData:  selectedRowsData,
          },
          {
            id:'importKitTypes',
            label: headerItemsLabels.IMPORT_KIT_TYPES,
            action: actions.header.importItems,
            disabled: false,
            actionData: {
              importTypes: [ImportTypes.IMPORT_KIT_TYPES],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload
            }
          },
          {
            id:'importKitTypesJson',
            label: headerItemsLabels.IMPORT_KIT_TYPES_JSON,
            action: actions.header.onMenuItemImportKitTypesJsonClick,
            disabled: false,
            actionData: {parentComponent: actions.reload}
          },
          {
            id:'exportKitTypes',
            label: headerItemsLabels.EXPORT_KIT_TYPES,
            action: actions.header.exportItems,
            disabled: !hasRows,
            actionData: {gridName: gridsNames.ADMINISTRATION_MAT_SETTINGS_KIT_TYPES}
          },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.header.onRemoveKitTypesClick,
            disabled: (!selectedRowsData || selectedRowsData.size === 0),
            actionData:  selectedRowsData,
          },
          {
            id:'importOperationSequence',
            label: headerItemsLabels.IMPORT_OPERATION_SEQUENCE,
            action: actions.header.importItems,
            disabled: false,
            actionData: {
              importTypes: [ImportTypes.IMPORT_OPERATION_SEQUENCE],
              acceptFileTypes: [ImportAcceptFileTypes.CSV],
              parentComponent: actions.reload
            }
          },
          {
            id:'exportOperationSequence',
            label: headerItemsLabels.EXPORT_OPERATION_SEQUENCE,
            action: actions.header.exportOperationSequence,
            disabled: !hasRows,
            actionData: {}
          },
          {
            id:'clone',
            label: headerItemsLabels.CLONE,
            action: actions.header.onCloneKitTypeClick,
            disabled:  !(hasRows && selectedRowsData.size === 1),
            actionData:  selectedRowsData.get(0)
          },

        ]
      }
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <Header {...headerItems}/>

        <KitTypesViewGrid actions={{...this.props.actions.kitTypesViewGridActions, ...this.props.actions.fetchEntitiesActions}} />

        <PL_DialogWrapper dialogComponent={CreateKitTypeDialog}
                          show={this.props.sCreateKitTypeDialogData.get('show')}
                          actions={this.props.actions.createKitTypeDialogActions}
                          sData={this.props.sCreateKitTypeDialogData}
                          reloadParentComponent={this.reloadPage}
                          sLoggedInUser={this.props.sLoggedInUser}
        />
        <PL_DialogWrapper dialogComponent={ImportKitTypesDialog}
                          show={this.props.sImportKitTypesDialogData.get('show')}
                          actions={this.props.actions.importKitTypesDialogActions}
                          sData={this.props.sImportKitTypesDialogData}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}



