import React from 'react';
import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../utils/materialHelper';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import LastOperationCell from '../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {navigationStates} from '../../../../enums/navigationStates';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import WeightCell from '../../../Common/CustomGridCells/WeightCell/weightCell';
import LengthCell from '../../../Common/CustomGridCells/LengthCell/lengthCell';

import'./archivedRollsGrid.scss';

class ArchiveRollsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'material', getOptions: false},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetEtl', getOptions: false},
    {fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'lastStatusChangeDate'},
    {fieldName: 'lot', filterName: 'lot'},
    {fieldName: 'weight', filterName: 'weight'},
    {fieldName: 'lengthLeft', filterName: 'length'},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ];

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        filterName: 'assetAlert',
        columnOptions: {
          sort: 'desc',
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              defrostingTimeLeft: params.data.defrostingTimeLeft,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          }
        },
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        valueFormatter: (params) => {
          return (params.data.assetStatusLabel || '');
        },
        columnOptions:{
          valueGetter: (params) => {
              return (params.data.assetStatus || '');
         },
        }
      },
      {
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.archivingDate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return (params.data && params.data.m_archivingDate ? params.data.m_archivingDate : '');
        },
        columnOptions:{
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon()),
          valueGetter: (params) => {
            return params.data.lastStatusChangeDate?.epochDateTime;
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'material',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ASSET_ARCHIVED, FetchEntitiesFilters.ASSET_TYPE_ROLL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.rollId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ARCHIVED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.ROLL
            };
          }
        }
      },
      {
        fieldName: 'lot',
        title: this.labels.get('columns.lot.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'lot',
        fetchConfig: {
          entityType: EntityPropertyTypes.LOT_NAME,
          filter: [FetchEntitiesFilters.ASSET_ARCHIVED, FetchEntitiesFilters.ASSET_TYPE_ROLL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => ({
            id: params.data.lot?.id,
            label: params.data.lot?.name,
            type: params.data.lot ? navigationStates.LOT : '',
          }),
        },
      },
      {...this.getLengthOrWeight()},
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ]
  }

  getLengthOrWeight = () => {
    if(PermissionManager.isWeightSupported()){
      return {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      }
    }

    return {
      fieldName: 'lengthLeft',
      title: this.labels.get('columns.length.title'),
      filterType: filterTypes.NONE,
      width: 110,
      columnOptions: {
        cellComponent: LengthCell,
        valueGetter: (params) => {
          return {
            length: params.data.lengthLeft
          };
        },
      }
    }
  };

  getRowHeight() {
     return 84;
  }

  render() {
    return (
      <div className="archive-rolls-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}

              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  filterPersistence: true,
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'ArchiveRollsGrid'
  }
)(ArchiveRollsGrid);






