import React from 'react';
import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ActivityTypeCell from '../../../Common/CustomGridCells/ActivityTypeCell/activityTypeCell';
import ActivityDetailsCell from '../../../Common/CustomGridCells/ActivityDetailsCell/activityDetailsCell';
import AssetCell from './CustomCells/AssetCell/assetCell.js';
import {navigationStates} from '../../../../enums/navigationStates';
import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow'
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import {enumTypes, getEnumValue} from '../../../../utils/enumHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
require('./activityLogGrid.scss');

class ActivityLogGrid extends React.PureComponent{

  filterConfig = [
      {fieldName: 'time.epochDateTime', filterName: 'fromDate'},
      {fieldName: 'activityType', filterName: 'activityType', getOptions: true},
      {fieldName: 'location', filterName: 'locationName', getOptions: true},
      {fieldName: 'username', filterName: 'reportedBy', getOptions: true},
      {fieldName: '', filterName: 'rollBusinessId'},
      {fieldName: '', filterName: 'kitBusinessId'},
      {fieldName: '', filterName: 'toolBusinessId'},
    ];

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.entityLabels = createLabelHelper('mat.entity.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig = [
      {
        fieldName: 'time.epochDateTime',
        title: this.labels.get('columns.time.title'),
        filterType: filterTypes.NONE,
        width: 165,
        valueFormatter: (params) => {
          return (params.data && params.data.m_Time ? params.data.m_Time : '');
        },
        columnOptions: {
          cellRenderer: 'agGroupCellRenderer',
          sort: 'desc',
          valueGetter: (params) => {
            return params.data?.time?.epochDateTime;
          },
        },
      },
      {
        fieldName: 'activityType',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'activityType',
        width: 220,
        columnOptions: {
          cellComponent: ActivityTypeCell,
          valueGetter: (params) => {
            return { activityType: params.data.activityType, activityTypeDisplayKey: params.data.activityTypeDisplayKey };
          }
        }
      },
      {
        fieldName: 'details',
        title: this.labels.get('columns.details.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ActivityDetailsCell,
          valueGetter: (params) => {
            return this.activityDetailsValueGetter(params);
          },
          sortable: false,
        }
      },
      {
        fieldName: 'assetBusinessId',
        title: this.labels.get('columns.assets.title'),
        filterType: filterTypes.MULTI_PROPERTIES,
        propertyTypes: this.getAssetsColumnFilterProperties(),
        columnOptions: {
          cellComponent: AssetCell,
          valueGetter: (params) => {
            return this.assetsValueGetter(params);
          },
          sortable: false,
        },
        handleSubmit: this.props.handleSubmit,
        change: this.props.change
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'locationName',
        width: 220,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.location ? params.data.location.id : null,
              type: navigationStates.LOCATION,
              label: params.data.location ? params.data.location.name : null
            };
          }
        }
      },
      {
        fieldName: 'username',
        title: this.labels.get('columns.reportedBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'reportedBy',
        filterAlignment: filterAlignmentTypes.RIGHT,
        width: 220,
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.username;
          },
        }
      }
    ];
    return columnsConfig;
  }


  getAssetsColumnFilterProperties() {
    return [
      {value: 'rollBusinessId', label: this.entityLabels.get('roll') || 'Roll'},
      {value: 'kitBusinessId', label: this.entityLabels.get('kit') || 'Kit'},
      {value: 'toolBusinessId', label: this.entityLabels.get('tool') || 'Tool'},
    ];
  }

  activityDetailsValueGetter(params) {
    // AT the moment we are supporting only 1, according to product it may change
    if(!params.data.primaryDetails || !params.data.primaryDetails[0]){
      return { key: 'NOT_FOUND', value: '' };
    }
    let primaryActivity = params.data.primaryDetails[0];
    return { key: primaryActivity.key, value: primaryActivity.value, type: primaryActivity.valuetype , isLocalizedKey: primaryActivity.keyIsReadyForDisplay};
  }

  assetsValueGetter(params) {

    let type = '';
    //possible for type is null
    switch (params.data.assetType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        type = navigationStates.ROLL;
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        type = navigationStates.SPOOL;
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        type = navigationStates.KIT;
        break;
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        type = navigationStates.TOOL;
        break;
    }

    return {
      id: params.data.assetId,
      label: params.data.assetBusinessId,
      type: type,
      assetType: params.data.assetType,
    }
  }

  getRowHeight = (params) => {
    let rowHeight = 64;
    let rowCount = params.data.details ? params.data.details.filter((detail) => !detail.extended).length : 0;
    if (rowCount > 1) {
      rowHeight = ((rowCount-1) * 45) + 64;
    }

    return rowHeight;
  };



  render() {
    return (
      <div className="activity-log-grid">
        <Grid gridName={gridsNames.WO_PAGE_ACTIVITY_LOG}
              columnsConfig={this.columnsConfig}
              filterConfig={this.filterConfig}
              actions={this.props.actions}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  checkboxSelection: false,
                  masterDetailRowComponent: MasterDetailRow,
                  isMasterDetail:true
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'activityLogGrid'
  }
)(ActivityLogGrid);
