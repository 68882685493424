import React from 'react';
import PropTypes from 'prop-types';

import {Prompt} from 'react-router-dom';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Header from '../../../../../Common/Header/header';
import CommonAdditionalFieldsForm from '../CommonAdditionalFieldsForm/commonAdditionalFieldsForm';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
require('./commonAdditionalFieldsView.scss');

export default class CommonAdditionalFieldsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.additionalFields.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  getHeaderItems = () => {
    return {
      title: this.getHeaderTitle(),
    };
  };

  getHeaderTitle = () => {
    let {entityType} = this.props;
    switch (entityType) {
      case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
        return this.labels.get('title.roll');
      case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
        return this.labels.get('title.spool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
        return this.labels.get('title.resin');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
        return this.labels.get('title.kit');
      case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
        return this.labels.get('title.group');
      case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
        return this.labels.get('title.tool');
      case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
        return this.labels.get('title.workOrder');
      case getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
        return this.labels.get('title.kitType');
    }
    console.error('getHeaderTitle(): unknown entity type ' + entityType);
    return '';
  };

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  renderPrompt = () => {
    return (
      <Prompt
        when={this.props.isDirty}
        message={() =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  };

  renderForm = () => {
    let {sData, entityType, actions} = this.props;
      if (!sData || !sData.get('fields')) {
        return null;
      }

      let fields = sData.get('fields');
      let fieldTypes = sData.get('fieldTypes');

    return (
      <CommonAdditionalFieldsForm
        fields={fields}
        fieldTypes={fieldTypes}
        entityType={entityType}
        labels={this.labels}
        actions={actions}
        sLoggedInUser = {this.props.sLoggedInUser}
      />
    );
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className='common-additional-fields-view'>
        <Header {...headerItems}/>
        {this.renderForm()}
        {this.renderLoadingOverlay()}
        {this.renderPrompt()}
      </div>
    );
  }
}


CommonAdditionalFieldsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
};
