import React from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {gridsNames} from '../../../enums/gridsNames';
import ActivitiesGrid    from './ActivitiesGrid/activitiesGrid.js';

import './activitiesView.scss';

const TAB_ID = 'activity';

export default class ActivitiesView extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.activities.');
  }

  componentDidMount() {
    if (this.props.match.params.tabId === TAB_ID) {
      this.reload(false);
    }
  }

  componentDidUpdate(prevProps) {
    let tabId = this.props.match.params.tabId;
    if (tabId === prevProps.match.params.tabId || tabId !== TAB_ID) {
      return;
    }

    this.reload(false);
  }

  reload = (refreshAll = true) => {
    this.props.actions.reload(refreshAll);

    if (refreshAll && this.props.actions.locationPageActions && this.props.actions.locationPageActions.reload) {
      this.props.actions.locationPageActions.reload();
    }
  };

  getHeaderItems() {
    let { sHasRows} = this.props;
    let loading = this.props.sActivitiesGridData ? this.props.sActivitiesGridData.get('loading') : true;

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: this.props.actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
          disabled: loading,
        },
        {
          id: 'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading,
        },
      ],
      kebab: {
        // loading: loading,
        disabled: !sHasRows || loading,
        menuItems: [
          {
            id:'export',
            label: headerItemsLabels.EXPORT_ACTIVITY_LIST,
            action: this.props.actions.exportActions.export,
            actionData: {gridName: gridsNames.LOCATION_ACTIVITIES}
          },
        ],
      },
      title: this.labels.get('header.title'),
    };
  }

  render() {
    if (this.props.match.params.tabId !== TAB_ID ) {
      return null;
    }
    let headerItems = this.getHeaderItems();
    return ( <div className="location-activities-view">
        <Header  {...headerItems}/>
        <ActivitiesGrid actions={this.props.actions.activitiesGridActions}
                        locationDetails={this.props.sLocationDetails}
        />
      </div>
    );
  }
}

ActivitiesView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sLocationDetails: PropTypes.object.isRequired,
};

