import {useState} from 'react';
import PropTypes from 'prop-types';
import Button from 'infrastructure/js/components/controls/Button/button';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';
import {isTouchApp} from 'infrastructure/js/utils/appHelper';

const isTouchScreen = isTouchApp()

export default function ButtonWithTooltip (props)  {

  const [showTooltip, setShowTooltip] = useState(false);

  const {placement='top', tooltip=''} = props;

  const handleTouchStart = (e) => {
    setShowTooltip(true);
  }

  const handleTouchEnd = (e) => {
    setShowTooltip(false);
  }

//Note: The 'Tooltip' component's 'showTooltip' parameter is relevant only for the touch screens
  return (
    <Tooltip placement={placement} value={tooltip}
             showTooltip={isTouchScreen && !!tooltip && showTooltip}
    >
      <Button
        {...props}
        onTouchStart={ isTouchScreen && tooltip ? handleTouchStart : undefined }
        onTouchEnd={ isTouchScreen && tooltip ? handleTouchEnd : undefined }
      />
    </Tooltip>
  )
}

ButtonWithTooltip.propTypes = {
  tooltip: PropTypes.any,
  placement: PropTypes.string,
};
