import PropTypes from 'prop-types';
import getColorSpaces from '../../utils/colorHelper';

export const getPatternStyle = (color) => {
  const {
    HSL: { hue, luminance, saturation },
  } = getColorSpaces(color);
  const darkerColor = `hsl(${hue},${saturation - 46}%,${luminance - 15}%)`;

  const css = `background: repeating-linear-gradient(-45deg, ${color}, ${color} 3px, ${darkerColor} 4px, ${darkerColor} 6px);
  border-color: ${darkerColor};`;
  const react = {
    background: `repeating-linear-gradient(-45deg, ${color}, ${color} 3px, ${darkerColor} 4px, ${darkerColor} 6px)`,
    borderColor: darkerColor,
  };

  return { react, css };
};

const TaskStarted = ({ color='#535353' }) => {
  const {
    HSL: { hue },
  } = getColorSpaces(color);
  const darkerColor = `hsl(${hue},46%,15%)`;

  const groupStyle = { opacity: 0.6, mixBlendMode: 'hard-light', isolation: 'isolate', clipPath: 'url(#tni8wsbgsa)' };
  const darkDiagonalLineStyle = { stroke: darkerColor, strokeWidth: '0.5px', fill: darkerColor };
  const path2Style = { fillRule: 'evenodd', fill: '#fff' };
  const group2Style = { stroke: color, fill: '#fff' };

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
        <defs>
          <clipPath id="tni8wsbgsa">
            <path data-name="Rectangle 1531" style={{ fill: color }} d="M0 0h42v42H0z" />
          </clipPath>
        </defs>
        <g data-name="Group 3534">
          <path data-name="Rectangle 1510" style={{ fill: color }} d="M0 0h42v42H0z" />
          <g data-name="Mask Group 2" style={groupStyle}>
            <g data-name="Group 168">
              <path data-name="Path 2095" d="M0 0h1.312v93.283H0z" transform="rotate(45 55.292 19.35)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2096" d="M0 0h1.312v93.283H0z" transform="rotate(45 53.323 24.103)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2097" d="M0 0h1.312v93.283H0z" transform="rotate(45 51.354 28.856)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2098" d="M0 0h1.312v93.283H0z" transform="rotate(45 49.386 33.609)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2099" d="M0 0h1.312v93.283H0z" transform="rotate(45 47.417 38.362)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2100" d="M0 0h1.312v93.283H0z" transform="rotate(45 45.448 43.115)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2101" d="M0 0h1.312v93.283H0z" transform="rotate(45 43.48 47.868)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2102" d="M0 0h1.312v93.283H0z" transform="rotate(45 41.51 52.62)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2103" d="M0 0h1.312v93.283H0z" transform="rotate(45 39.542 57.374)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2104" d="M0 0h1.312v93.283H0z" transform="rotate(45 37.573 62.127)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2105" d="M0 0h1.312v93.283H0z" transform="rotate(45 35.604 66.88)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2106" d="M0 0h1.312v93.283H0z" transform="rotate(45 33.636 71.633)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2107" d="M0 0h1.312v93.283H0z" transform="rotate(45 31.667 76.386)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2108" d="M0 0h1.312v93.283H0z" transform="rotate(45 29.698 81.139)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2109" d="M0 0h1.312v93.283H0z" transform="rotate(45 27.73 85.892)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2110" d="M0 0h1.312v93.283H0z" transform="rotate(45 25.76 90.645)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2111" d="M0 0h1.312v93.283H0z" transform="rotate(45 23.792 95.398)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2112" d="M0 0h1.312v93.283H0z" transform="rotate(45 21.823 100.15)" style={darkDiagonalLineStyle} />
              <path data-name="Path 2113" d="M0 0h1.312v93.283H0z" transform="rotate(45 19.854 104.904)" style={darkDiagonalLineStyle} />
            </g>
          </g>
          <path
            data-name="Path 2055"
            d="M336.008 515.11c.151-.23.29-.466.419-.711a8.8 8.8 0 0 0 0-8.218 8.716 8.716 0 0 0-.904-1.379 1.648 1.648 0 0 0-.552 2.149 7.158 7.158 0 0 1 0 6.677 1.648 1.648 0 0 0 .552 2.149c.172-.215.335-.437.485-.667zm-1.865 2.046a1.649 1.649 0 0 0-2.149-.551 7.156 7.156 0 0 1-6.676 0 1.651 1.651 0 0 0-2.151.551 8.64 8.64 0 0 0 1.378.905 8.8 8.8 0 0 0 8.22 0c.243-.129.479-.27.709-.419s.454-.313.67-.486zm-12.354-1.379a1.649 1.649 0 0 0 .551-2.149 7.158 7.158 0 0 1 0-6.677 1.649 1.649 0 0 0-.551-2.149q-.259.323-.485.668c-.151.23-.292.466-.42.711a8.8 8.8 0 0 0 0 8.218 8.846 8.846 0 0 0 .905 1.379zm1.379-12.354a1.651 1.651 0 0 0 2.151.551 7.156 7.156 0 0 1 6.676 0 1.649 1.649 0 0 0 2.149-.551 8.82 8.82 0 0 0-1.377-.905 8.8 8.8 0 0 0-8.22 0 8.798 8.798 0 0 0-1.379.905z"
            transform="translate(-307.933 -489.568)"
            style={path2Style}
          />
          <g data-name="Ellipse 312" transform="translate(24 23)" style={group2Style}>
            <circle cx="4" cy="4" r="4" style={{ stroke: 'none' }} />
            <circle cx="4" cy="4" r="3.5" style={{ fill: 'none' }} />
          </g>
        </g>
      </svg>
    </>
  );
};

TaskStarted.propTypes = {
  color: PropTypes.string,
};

export default TaskStarted;
