import * as csvSettingsService from '../../../services/Administration/csvSettingsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {

  ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FINISHED: 'ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FINISHED',
  ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FAILED: 'ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FAILED',

  ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_FINISHED: 'ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_FINISHED',
  ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_RESET_DATA: 'ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_RESET_DATA',


};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  submitInProgress: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_IN_PROGRESS, payload: payload};
  },
  submitFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FINISHED, payload: payload};
  },
  submitFailed: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FAILED, payload: payload};
  },
  fetchDataInProgress: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS, payload: payload};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_FINISHED, payload: payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_RESET_DATA, payload: payload};
  },

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.load = function (entityType) {
  return function (dispatch, getState) {

    return api.fetchData(dispatch, getState)(entityType);
  }
};

jsxActions.submit = function (data, entityType, formType, messageDialogBuilder) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, entityType, formType, messageDialogBuilder);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchData = function (dispatch, getState) {
  return function (entityType) {

    if (entityType === getEnumValue(enumTypes.OBJECT_TYPE)('TASK') || entityType === 'ROLL_USAGE' ) {
      return api.fetchExportData(dispatch, getState)(entityType);
    }
    if (entityType === getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT') || entityType === 'ASN_SHIPMENT' ) {
      return api.fetchImportData(dispatch, getState)(entityType);
    }
    dispatch(actions.fetchDataInProgress());

    let importType = {importType: _getImportType(entityType)};
    let exportType = {exportType: _getExportType(entityType)};

    let promise1 = csvSettingsService.fetchImportPropertiesOptions(importType);
    let promise2 = csvSettingsService.fetchExportPropertiesOptions(exportType);
    let promise3 = csvSettingsService.fetchImportSettings(importType);
    let promise4 = csvSettingsService.fetchExportSettings(exportType);

    return Promise.all( [promise1, promise2, promise3, promise4]).then((allResults) => {
      const invalidResponse = allResults.find(response => {
        return !Network.isResponseValid(response);
      });

      if(invalidResponse){
        messageDialogApi.responseError(dispatch, getState)(invalidResponse);
        dispatch(actions.fetchDataFinished());
        return {success: false};
      }

      let labels = createLabelHelper('');
      let importCsvOptions = allResults[0].dataList.map((item) => {return {value: item.id, label: item.isAdditionalField ? item.name : labels.get(item.labelKey), data: item}});
      let exportCsvOptions = allResults[1].dataList.map((item) => {return {value: item.id, label: item.isAdditionalField ? item.name : labels.get(item.labelKey), data: item}});

      let importCsvData =  {
        settings: _normalize(allResults[2].data.settings),
        startingRowNum: allResults[2].data.startingRowNum,
        mergedPropertiesIndexes: allResults[2].data.mergedPropertiesIndexes,
      };
      let exportCsvData =  {
        settings: _normalize(allResults[3].data.settings),
        startingRowNum: allResults[3].data.startingRowNum
      };
      let data = {
        importData: {
          csvOptions: importCsvOptions,
          csvData: importCsvData,
        },
        exportData: {
          csvOptions: exportCsvOptions,
          csvData: exportCsvData
        }
      };

      dispatch(actions.fetchDataFinished(data));

      return {success: true};
    });

  }
};

api.fetchExportData = function (dispatch, getState) {
  return function (entityType) {
    dispatch(actions.fetchDataInProgress());

    let exportType = {exportType: _getExportType(entityType)};

    let promise1= csvSettingsService.fetchExportPropertiesOptions(exportType);
    let promise2 = csvSettingsService.fetchExportSettings(exportType);

    return Promise.all([promise1, promise2]).then((allResults) => {

      if (!Network.isResponseValid(allResults[0]) ||
          !Network.isResponseValid(allResults[1])
      ) {
        messageDialogApi.responseError(dispatch, getState)(null);
        console.error('Failed to fetch Csv Settings');
        return {success: false};
      }

      let labels = createLabelHelper('');
      let exportCsvOptions = allResults[0].dataList.map((item) => {return {value: item.id, label: item.isAdditionalField ? item.name : labels.get(item.labelKey), data: item}});
      let exportCsvData = {
        settings: _normalize(allResults[1].data.settings),
        startingRowNum: allResults[1].data.startingRowNum
      };

      let data = {
        importData: null,
        exportData: {
          csvOptions: exportCsvOptions,
          csvData: exportCsvData
        }
      };

      dispatch(actions.fetchDataFinished(data));

      return {success: true};
    });

  }
};


api.fetchImportData = function (dispatch, getState) {
  return function (entityType) {
    dispatch(actions.fetchDataInProgress());

    let importType = {importType: _getImportType(entityType)};

    let promise1= csvSettingsService.fetchImportPropertiesOptions(importType);
    let promise2 = csvSettingsService.fetchImportSettings(importType);

    return Promise.all([promise1, promise2]).then((allResults) => {

      if (!Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1])
      ) {
        messageDialogApi.responseError(dispatch, getState)(null);
        console.error('Failed to fetch Csv Settings');
        return {success: false};
      }

      let labels = createLabelHelper('');
      let exportCsvOptions = allResults[0].dataList.map((item) => {return {value: item.id, label: item.isAdditionalField ? item.name : labels.get(item.labelKey), data: item}});
      let exportCsvData = {
        settings: _normalize(allResults[1].data.settings),
        startingRowNum: allResults[1].data.startingRowNum
      };

      let data = {
       exportData: null,
        importData : {
          csvOptions: exportCsvOptions,
          csvData: exportCsvData
        }
      };

      dispatch(actions.fetchDataFinished(data));

      return {success: true};
    });

  }
};

api.submit = function (dispatch, getState) {
  return function (data, entityType, formType, messageDialogBuilder) {

    dispatch(actions.submitInProgress());

    let method = (formType === 'IMPORT') ? csvSettingsService.setImportSettings : csvSettingsService.setExportSettings;

    if (formType === 'IMPORT') {
      data.importType = _getImportType(entityType);
    }
    else {
      data.exportType = _getExportType(entityType);
    }
    return method(data)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Failed to update Csv Settings', response);
          dispatch(actions.submitFailed());
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );

          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }

        dispatch(actions.submitFinished(response.data));

        return api.fetchData(dispatch, getState)(entityType);
      });
  }
};

///////////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
export function _normalize(settings) {
  let res = [];
  let indexDelta = 0;

  settings.forEach((item, index) => {
    let curIndex = index + 1 + indexDelta;

    if (curIndex < item.columnIndex) { //fill missing empty columns if relevant
      while (curIndex < item.columnIndex) {
        res.push({
          property: {
            id: null,
            labelKey: '',
          }
        });
        curIndex++;
        indexDelta++;
      }
    }
    if (curIndex === item.columnIndex) {
      res.push(item);
    }
    if (curIndex > item.columnIndex) {
      //should never get here
      console.error('CSV Settings _normalize(): unappropriated data', item);
    }
  });

  return res;
}



function _getImportType(entityType) {
  switch (entityType) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL_TYPE'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP_TYPE'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('MATERIAL_TYPE'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT'):
    case 'ASN_SHIPMENT':
      return entityType;
    case 'OPERATION':
      return 'KIT_TYPE';
    case 'PART_TYPE':
      return 'PART_TYPE';
    case 'PROJECT':
        return 'PROJECT';
  }
  console.error('_getImportType(): unknown entity type ' + entityType);
  return '';
}
function _getExportType(entityType) {
  switch (entityType) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return 'STATION_ROLLS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return 'STATION_SPOOLS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
      return 'STATION_RESINS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return 'STATION_KITS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return 'STATION_GROUPS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return 'STATION_TOOLS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
      return 'OPEN_WOS';
    case getEnumValue(enumTypes.OBJECT_TYPE)('TASK'):
      return 'SCHEDULER_TASKS';
    case  getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
      return 'KIT_TYPES';
    case  getEnumValue(enumTypes.OBJECT_TYPE)('TOOL_TYPE'):
      return 'TOOL_TYPE';
    case  getEnumValue(enumTypes.OBJECT_TYPE)('GROUP_TYPE'):
      return 'GROUP_TYPE';
    case  getEnumValue(enumTypes.OBJECT_TYPE)('MATERIAL_TYPE'):
      return 'MATERIAL_TYPES';
    // case  getEnumValue(enumTypes.OBJECT_TYPE)('OPERATION'):  //TODO: L OPERATION
    //   return 'OPERATION';
    case 'OPERATION': //TODO: L OPERATION (temp here)
      return 'KIT_TYPES';
    case 'ROLL_USAGE': //TODO: L ROLL_USAGE
      return 'STATION_ROLLS_USAGE';
    case 'PART_TYPE':
      return 'PART_TYPES';
    case 'PROJECT':
      return 'PROJECTS';

  }
  console.error('_getExportType(): unknown entity type ' + entityType);
  return '';
}











