import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js'

import './timeField.scss'

export default class PL_TimeFieldForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_TimeField}/>
    );
  }
};


 export class PL_TimeField extends React.PureComponent{

   constructor(props) {
     super(props);

     this.labels = createLabelHelper('mat.controls.timefield.');

//TODO: make sure repeating the label makes sense in chinese, and make sure that if the input is a number in chinese it is translated to normal digits

     let maxHours = 23;
     this.placeholder = this.labels.get('placeholders.hours').repeat(2);
     this.normalizeMinutes = this.props.normalizeMinutes;
     this.normalizeSeconds = this.props.normalizeSeconds;

     if (this.props.hoursCount) {
       let maxHoursLength = this.props.maxHoursLength || 2;
       maxHours = props.isDays || props.isTwentyFourHourClock? 23 : Math.pow(10, maxHoursLength) - 1;
       this.placeholder = this.labels.get('placeholders.hours').repeat(maxHoursLength);

     this.normalizeDays = Normalize.number(true, 0, 999);
     this.normalizeHours = Normalize.number(true, 0, maxHours);
     this.normalizeMinutes = Normalize.number(true, 0, 59);
     this.normalizeSeconds = Normalize.number(true, 0, 59);

     this.state =
       {
         daysValue: null,
         hoursValue: null,
         minutesValue: null,
         secondsValue: null
       };
     }
   }

   onChangeDays = (val) => {
     if (val.toString().length == 3) {
       let hoursParent = ReactDOM.findDOMNode(this.hours);

       if (hoursParent) {
         let hoursInputs = hoursParent.getElementsByTagName("input");
         if (hoursInputs && hoursInputs.length > 0) {
           let minutes = hoursInputs[0];
           minutes.focus();
         }
       }
     }

     if(this.props.onChangeCallbackDays){
       val = this.normalizeDays(val, this.state.daysValue);
       this.props.onChangeCallbackDays(val);
     }
     this.setState({daysValue: val});
   }

   onChangeHours = (val) => {
     let maxHoursLength = this.props.maxHoursLength || 2;
     if (val.toString().length == maxHoursLength ) {
       let minutesParent = ReactDOM.findDOMNode(this.minutes);
       if (minutesParent) {
         let minutesInputs = minutesParent.getElementsByTagName("input");
         if (minutesInputs && minutesInputs.length > 0) {
           let minutes = minutesInputs[0];
           minutes.focus();
         }
       }
     }

     if(this.props.onChangeCallbackHours){
       val = this.normalizeHours(val, this.state.hoursValue);
       this.props.onChangeCallbackHours(val);
     }
     this.setState({hoursValue: val});
   };

   onChangeMinutes = (val) => {
    let maxMinutesLength = this.props.maxMinutesLength || 2;
    if (val.toString().length == maxMinutesLength ) {
      let secondsParent = ReactDOM.findDOMNode(this.seconds);
      if (secondsParent) {
        let secondsInputs = secondsParent.getElementsByTagName("input");
        if (secondsInputs && secondsInputs.length > 0) {
          let seconds = secondsInputs[0];
          seconds.focus();
        }
      }
    }

     if(this.props.onChangeCallbackMinutes){
       val = this.normalizeMinutes(val, this.state.minutesValue);
       this.props.onChangeCallbackMinutes(val);
     }

     this.setState({minutesValue: val});
   };

   onChangeSeconds = (val) => {

     if(this.props.onChangeCallbackSeconds){
       val = this.normalizeSeconds(val, this.state.secondsValue);
       this.props.onChangeCallbackSeconds(val);
     }

     this.setState({secondsValue: val});
   };



   formatSecondsHandler = (val) => {
     if (val !== undefined && val !== '' && val < 10) {
       return '0' + val;
     }
     return val === undefined ? '' : val;
   };

   formatMinutesHandler= (val) => {
     return (val || val === 0 ) ? (val < 10 ? '0' + val : val ) : '';
   };

   setHoursRef = (r) => {
     this.hours = r || this.hours;
   };

   setMinutesRef = (r) => {
     this.minutes = r || this.minutes;
   };
   setSecondsRef = (r) => {
     this.seconds = r || this.seconds;
   };

   validate = (value, allValues, props, name)=>{

   }

   renderDaysComponent = () => {
     return this.props.isDays ?
       (
         <React.Fragment>
           <TextField
             id={`days-${this.props.id}_days`}
             name={this.props.input.name + '_days'}
             className="inner days"
             placeholder={this.labels.get('placeholders.days')}
             normalize={this.normalizeDays}
             onChangeCallback={this.onChangeDays}
             disabled={this.props.disabled}
           />
           <span className="time-separator">:</span>
         </React.Fragment>) : null;
   };

   renderHoursComponent =()=> {
     if (!this.props.isHours) {
       return null;
     }

     return (  <React.Fragment>
       <TextField
       id={`left-${this.props.id}_hours`}
       name={this.props.input.name + '_hours'}
       className="inner hours"
       placeholder={this.placeholder}
       normalize={this.normalizeHours}
       onChangeCallback={this.onChangeHours}
       disabled={this.props.disabled}
       ref={this.setHoursRef}
     />
       <span className="time-separator">:</span>
     </React.Fragment>);
   };

   renderMinutesComponent = () => {
    let placeHolder = this.labels.get('placeholders.minutes').repeat(this.props.maxMinutesLength || 2);
    
    return (
      <TextField
        id={`${this.props.id}_minutes`}
        name={this.props.input.name + '_minutes'}
        className={cn('inner minutes', {count: this.props.minutesCount})}
        placeholder={placeHolder}
        normalize={this.normalizeMinutes}
        onChangeCallback={this.onChangeMinutes}
        format={!this.props.minutesCount ? this.formatMinutesHandler : null}
        disabled={this.props.disabled}
        ref={this.setMinutesRef}
        validate={this.validate}
      />
    );
  };

   renderSecondsComponent = () => {
     if (!this.props.isSeconds) {
       return null;
     }
     return (
       <React.Fragment>
         <span className="time-separator">:</span>
         <TextField
           id={`${this.props.id}_seconds`}
           name={this.props.input.name + '_seconds'}
           className="inner minutes"
           placeholder={this.labels.get('placeholders.seconds')}
           normalize={this.normalizeSeconds}
           onChangeCallback={this.onChangeSeconds}
           format={this.formatSecondsHandler}
           disabled={this.props.disabled}
           ref={this.setSecondsRef}
           validate={this.validate}
         />
       </React.Fragment>);
   };

   render()
   {
    return (
      <div className={cn('time-value-wrapper', {'disabled': this.props.disabled}, this.props.className)} id={this.props.id} >
        {this.renderDaysComponent()}
        {this.renderHoursComponent()}

        {this.renderMinutesComponent()}
        {this.renderSecondsComponent()}
        <ValidationComponent className ="validation-component" {...this.props.meta} />
      </div>
    );
  }
}
PL_TimeField.defaultProps = {
  isDays: false,
  isSeconds: false,
  isHours: true,
  isTwentyFourHourClock: false,
};

PL_TimeField.propTypes = {
  id: PropTypes.string.isRequired,
  isDays: PropTypes.bool,
  isSeconds: PropTypes.bool,
  isHours:PropTypes.bool,
  hoursCount: PropTypes.bool,
  minutesCount: PropTypes.bool,
  maxHoursLength: PropTypes.number, //number of digits in hour field
  maxMinutesLength: PropTypes.number, //number of digits in minutes field
  isTwentyFourHourClock: PropTypes.bool,
  disabled: PropTypes.bool,
  onChangeCallbackDays: PropTypes.func, //(days: int) => void;
  onChangeCallbackHours: PropTypes.func, //(hours: int) => void;
  onChangeCallbackMinutes: PropTypes.func, //(minutes: int) => void;
  onChangeCallbackSeconds: PropTypes.func, //(seconds: int) => void;
  normalizeMinutes: PropTypes.func,
  normalizeSeconds: PropTypes.func,
};
