import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import AllItemsList from './AllItemsList';
import OptionsList from './OptionsList';

import Item from './Item';

import './SingleLineMultiSelect.scss';

const checkLimit = (limit, selectedOptions = []) => {
  return !!limit && selectedOptions.length >= limit;
};

// fix useEffect infinite loop - created a constant default value reference that is not changed between re-renders
const DEFAULT_VALUE = [];

const SingleLineMultiSelect = ({
  onChange,
  value=DEFAULT_VALUE,
  options=[],
  limit=0,
  optionsTranslator=(options)=>options,
  name='select',
  asyncOnChangeMethods={},
  asyncLoaders={},
  onFocusOut=()=>false,
  disabled=false,
  placeholder='',
}) => {
  const [items, setItems] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [allItemsListOpen, setAllItemsListOpen] = useState(false);

  const elementRef = useRef();
  const eventRef = useRef();

  const { asyncFilterOptions, asyncOptionsLoader } = useMemo(() => {
    const { options: asyncFilterOptions } = asyncOnChangeMethods;
    const { options: asyncOptionsLoader } = asyncLoaders;
    return { asyncFilterOptions, asyncOptionsLoader };
  }, [asyncOnChangeMethods, asyncLoaders]);

  const getFilteredAsyncOptions = useMemo(() => (asyncFilterOptions ? debounce(asyncFilterOptions, 750) : false), [asyncFilterOptions]);

  const removeItem = (itemId) => {
    const newItems = items.filter(({ value }) => value !== itemId);
    setItems(newItems);
    onChange(newItems);
  };

  useEffect(() => {
    setItems(() => value ?? []);
  }, [value]);

  useEffect(() => {
    eventRef.current = (e) => {
      if (!elementRef?.current?.contains(e.target)) {
        handleFocusOut();
      }
    };
    window.addEventListener('click', eventRef.current);
    return () => {
      window.removeEventListener('click', eventRef.current);
    };
  }, [handleFocusOut]);

  const handleNumberHover = () => {
    setShowOptions(false);
    setAllItemsListOpen(true);
  };

  const handleNumberLeave = () => {
    setAllItemsListOpen(false);
  };

  const handleOptionClick = (itemValue) => {
    const item = options.find(({ value }) => value === itemValue);
    setItems((items) => [...items, item]);
    onChange([...items, item]);
  };

  const openSearch = () => {
    setShowOptions(true);
    setAllItemsListOpen(false);
  };

  const handleFocusOut = useCallback(() => {
    setShowOptions(false);
    onFocusOut();
  }, [onFocusOut]);

  return (
    <div className={classNames('single-line-multi-select', { disabled })} ref={elementRef} name={name} onMouseLeave={handleNumberLeave}>
      <div className={classNames('input-wrapper', { selected: showOptions })} onClick={openSearch}>
        <div className="selected-options">
          {items.length > 0 ? <Item item={items[0]} onDeleteClick={removeItem} disabled={disabled} /> : placeholder}
          {items.length > 1 ? (
            <span className="other-selected-options" onMouseEnter={handleNumberHover}>
              +{items.length - 1}
            </span>
          ) : null}
        </div>
      </div>
      {showOptions && !checkLimit(limit, items) ? (
        <OptionsList
          loader={asyncOptionsLoader}
          options={options}
          selectedItems={items}
          onOptionClick={handleOptionClick}
          asyncFilter={getFilteredAsyncOptions}
          optionsTranslator={optionsTranslator}
        />
      ) : null}
      {allItemsListOpen && !showOptions && items.length > 1 ? (
        <AllItemsList removeItem={removeItem} items={items} disabled={disabled}></AllItemsList>
      ) : null}
    </div>
  );
};

SingleLineMultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  getUniqueValue: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  optionsTranslator: PropTypes.func,
  // getOptionsAsync: PropTypes.func,
  limit: PropTypes.number,
  asyncOnChangeMethods: PropTypes.object,
  asyncLoaders: PropTypes.object,
  onFocusOut: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default SingleLineMultiSelect;
