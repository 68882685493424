import {isAssetRestricted} from './assetHelper';

export function isAllAssetsUncured (data) {
  let res = [];
  if (data) {
    data.map((item) => {
      if (item.isCured) {
        res.push(item.id);
      }
    });
  }
  return res.length === 0;
}

export function isAllAssetsUnrestricted (data) {
  let res = [];
  if (data) {
    data.map((item) => {
      if (isAssetRestricted(item)) {
        res.push(item.id);
      }
    });
  }
  return res.length === 0;
}
