import * as daHelper from '../../../Dialogs/dialogActionsHelper.js';
import {dialogsNames} from '../../../../enums/dialogsNames.js';
import * as sandboxService from '../../../../services/Sandbox/sandboxService';
import Network from 'infrastructure/js/modules/network';


const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isCloneMode ?
      sandboxService.cloneSandbox : sandboxService.createSandbox;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_SANDBOX_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_SANDBOX_DIALOG),

};

export let api = {
  ...daHelper.getApiActions(actions, config),

  stop: function (dispatch, getState) {
    return function() {
      sandboxService.stopSandboxCreation().then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Stopping the sandbox creation failed');
          return { success: false };
        }
        return { success: true };
      });
    }
  },
}

export let jsxActions = {
  ...daHelper.getJsxActions(api),
  stop: function () {
    return function (dispatch, getState) {
      api.stop(dispatch, getState)();
    };
  },
};
