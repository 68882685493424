import {actionTypes as importFilesWizardActions }from '../../../actions/Wizards/ImportFiles/importFilesWizardActions';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { Map} from 'immutable';

let defaultState = {
  errors: null,
  printLabelsErrors: null,
  loading: false,
  validatedFileData:null,
  fileToken:null,
  selectedFile: null,
  importFileData:null,
  selectedAssetType: null,
  parentComponent: null,
  currentDate: DateTimeHelper.DateFormat(Date.now()),
  importTypes: [],
  shouldIncludeLocation: false,
  showPrintLabels: false,
  acceptFileTypes:[]
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case importFilesWizardActions.IMPORT_FILES_SAVE_START_PARAMETERS:
      return state.set('importTypes', action.payload.importTypes)
                  .set('acceptFileTypes', action.payload.acceptFileTypes)
                  .set('shouldIncludeLocation', action.payload.shouldIncludeLocation ? action.payload.shouldIncludeLocation : false)
                  .set('showPrintLabels', action.payload.showPrintLabels ? action.payload.showPrintLabels : false)
                  .set('parentComponent', action.payload.parentComponent);

    case importFilesWizardActions.IMPORT_FILES_CLEAR_PARAMETERS:
      return Map(defaultState);


    case importFilesWizardActions.IMPORT_FILES_HAS_ERRORS:
      return state.set('errors', action.payload);

    case importFilesWizardActions.IMPORT_FILES_UPLOAD_FILE_IN_PROGRESS:
      return state.set('loading', true)
                  .set('printLabelsErrors', null);

    case importFilesWizardActions.IMPORT_FILES_UPLOAD_FILE_FINISHED:
      return state.set('loading', false);

    case importFilesWizardActions.IMPORT_FILES_UPLOAD_FILE_FAILED:
      return state.set('loading', false);

    case importFilesWizardActions.IMPORT_FILES_VALIDATE_FILE_IN_PROGRESS:
      return state.set('loading', true);

    case importFilesWizardActions.IMPORT_FILES_VALIDATE_FILE_FINISHED:
      return state.set('validatedFileData', action.payload.data)
                  .set('fileToken', action.payload.token)
                  .set('validationJobId', action.payload.validationJobId)
                  .set('loading', false);

    case importFilesWizardActions.IMPORT_FILES_VALIDATE_FILE_FAILED:
      return state.set('loading', false);

    case importFilesWizardActions.IMPORT_FILES_IMPORT_FILE_IN_PROGRESS:
      return state.set('loading', true)
                  .set('printLabelsErrors', null);

    case importFilesWizardActions.IMPORT_FILES_IMPORT_FILE_FINISHED:
      return state.set('importFileData', action.payload)
                  .set('selectedFile', null)
                  .set('loading', false);

    case importFilesWizardActions.IMPORT_FILES_IMPORT_FILE_FAILED:
      return state.set('errors', action.payload)
                  .set('loading', false);

    case importFilesWizardActions.IMPORT_FILES_WITH_PRINT_LABELS_FAILED:
      return state.set('printLabelsErrors', action.payload);

    case importFilesWizardActions.IMPORT_FILES_SELECTED_ASSET_TYPE_CHANGED:
      return state.set('selectedAssetType', action.payload);

    case importFilesWizardActions.IMPORT_FILES_SELECTED_FILE_CHANGED:
      return state.set('selectedFile', action.payload);

    default:
      return state;
  }
}




















