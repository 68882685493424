import React from 'react';

import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import CheckMarkCell         from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

class GroupTypesViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'businessId', filterName: 'groupTypeBusinessId', getOptions: false},
    {fieldName: 'active', filterName: 'groupTypeStatus', getOptions: false},
    {fieldName: 'description', filterName: 'groupTypeDescription', getOptions: false},
  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.grouptypes.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.groupType.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'groupTypeBusinessId',
        width: 300,
        fetchConfig: {
          entityType: EntityPropertyTypes.GROUP_TYPE_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.businessId;
          },
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        filterName: 'groupTypeStatus',
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
          valueGetter: (params) => {
            return params.data?.active;
          },
        }
      },
      {
        fieldName: 'description',
        title: this.labels.get('columns.groupName.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'groupTypeDescription',
        filterAlignment: filterAlignmentTypes.RIGHT,
        fetchConfig: {
          entityType: EntityPropertyTypes.GROUP_TYPE_NAME,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.description;
          },
        }
      },
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'groupTypesViewGrid'
  }
)(GroupTypesViewGrid);
