import React from 'react';

import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {navigationStates} from '../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {getAlertColumnFilterSections, scheduleAlertStatus, scheduleAlertType} from '../../../../utils/alertFilterSectionHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow';
import TaskStatusCell from '../../../Common/CustomGridCells/TaskStatusCell/taskStatusCell';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import ToolTypeHelper from '../../../../utils/toolTypeHelper';
import TaskAlertPopoverCell from '../../../Common/CustomGridCells/TaskAlertPopoverCell/taskAlertPopoverCell';

require('./activeToolsScheduleGrid.scss');

class ActiveToolsScheduleGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'valid', filterName: 'scheduleAlert'},
    // {fieldName: 'toolType.businessId', filterName: 'toolType',getOptions: true}, //TODO: L TOOL CAPACITY - temp no support
    {fieldName: 'plannedTool.businessId', filterName: 'plannedTool', getOptions: true},
    {fieldName: 'toolCategory', filterName: 'toolCategoryBusinessId', getOptions: true },
    {fieldName: 'workOrder.businessId', filterName: 'operationWorkOrder', getOptions: true},
    {fieldName: 'operationStatus', filterName: 'operationStatus', getOptions: true},
    {fieldName: 'plannedStartTime', filterName: 'plannedStartTime', getOptions: true},
    {fieldName: 'plannedEndTime', filterName: 'plannedEndTime', getOptions: true},
    {fieldName: 'plannedResource.businessId', filterName: 'operationResource', getOptions: true}
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.activeToolsSchedule.grid.');
    this.filterLabels = createLabelHelper('mat.filter.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'valid',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'scheduleAlert',
        filterWidth: 270,
        filterAlignment: filterAlignmentTypes.LEFT,
        filterSections: getAlertColumnFilterSections([scheduleAlertStatus.NO_ALERT, scheduleAlertStatus.LOW_LEVEL_WARNING, scheduleAlertStatus.HIGH_LEVEL_WARNING],
          [scheduleAlertType.DUE_DATE_CONFLICT,
            scheduleAlertType.DEPENDENCY_CONFLICT,
            scheduleAlertType.STATION_AVAILABILITY_CONFLICT,
            scheduleAlertType.TOOL_AVAILABILITY_CONFLICT,
            scheduleAlertType.PASSED_SCHEDULE,
            scheduleAlertType.MISCONFIGURATION]),
        width: 120,
        columnOptions: {
          cellComponent: TaskAlertPopoverCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.validationMessages,
            };
          },
        },
      },
      {
        fieldName: 'toolType.businessId',
        title: this.labels.get('columns.type.title'),
        // filterType: filterTypes.MULTI_SELECT,    //TODO: L TOOL CAPACITY - temp no support
        filterType: filterTypes.NONE,
        // filterName:  'toolType',
        columnOptions: {
          sortable: false,    //TODO: L TOOL CAPACITY - temp no support
          cellRenderer: 'agGroupCellRenderer',
          valueGetter: ({data}) => {
            return ToolTypeHelper.getToolTypeFullLabel(data.toolType.name, data.toolType.businessId);
          }
        }
      },
      {
        fieldName: 'plannedTool.businessId',
        title: this.labels.get('columns.toolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'plannedTool',
        fetchConfig: {
          entityType: EntityPropertyTypes.TOOL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.USED_FOR_TASK],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.plannedTool) {
              return {
                id: params.data.plannedTool.id,
                label: params.data.plannedTool.businessId,
                type: navigationStates.TOOL
              };
            }
            return {};
          }
        }
      },
      {
        fieldName: 'toolCategory',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolCategoryBusinessId',
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'operationWorkOrder',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.workOrder) {
              return {
                id: params.data.workOrder.id,
                label: params.data.workOrder.businessId,
                type: navigationStates.WORKORDER
              };
            }
            return {};
          }
        }
      },
      {
        fieldName: 'operationStatus',
        title: this.labels.get('columns.taskStatus.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'operationStatus',
        columnOptions: {
          cellComponent: TaskStatusCell,
        }
      },
      {
        fieldName: 'plannedStartTime',
        title: this.labels.get('columns.plannedStartTime.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'plannedStartTime',
        filterLabel: this.filterLabels.get('date.from'),
        isTime: true,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return ((params.data && params.data.plannedStartTime) ?  DateTimeHelper.DateTimeFormat(params.data.plannedStartTime.epochDateTime * 1000) : '');
          }
        }
      },
      {
        fieldName: 'plannedEndTime',
        title: this.labels.get('columns.plannedEndTime.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'plannedEndTime',
        filterLabel: this.filterLabels.get('date.before'),
        isTime: true,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return ((params.data && params.data.plannedEndTime) ?  DateTimeHelper.DateTimeFormat(params.data.plannedEndTime.epochDateTime * 1000) : '');
          }
        }
      },
      {
        fieldName: 'plannedResource.businessId',
        title: this.labels.get('columns.plannedStation.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'operationResource',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.plannedResource) {
              return {
                id: params.data.plannedResource.id,
                label: params.data.plannedResource.businessId,
                type: navigationStates.LOCATION,
              };
            }
            return {};
          }
        }
      }
    ]
  }


  // getRowHeight() {
  //   return 100;
  // }
  getRowHeight = (params) => {

    let rowHeight = 64;
    if (params.node && params.node.detail && params.data && params.data.extendedDetails) {
      let rowCount = Math.ceil(params.data.extendedDetails.length / 4);
      return rowCount * 55;
    }
    return rowHeight;
  }

  render() {
      return (
        <div className="active-tools-schedule-grid">
          <Grid gridName={gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS_SCHEDULE}
                columnsConfig={this.columnsConfig}
                filterConfig={this.filterConfig}
                actions={this.props.actions}
                gridProps={
                  {
                    checkboxSelection: false,
                    getRowHeight: this.getRowHeight,
                    masterDetailRowComponent: MasterDetailRow,
                    isMasterDetail:true,
                    filterPersistence: true,
                  }
                }
          >
          </Grid>
        </div>
      );
    }
 }

export default reduxForm({
    form: 'activeToolsScheduleGrid'
  }
)(ActiveToolsScheduleGrid);
