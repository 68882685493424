import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/Administration/UserSettingsPage/userSettingsPageActions';
import {UserSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import { default as createExternalNotificationDialogReducer } from './createExternalNotificationDialogReducer';

let defaultState = {
  selectedMenuItemId : UserSettingsMenuItemsIds.NAV_ELEMENT_USER_MANAGEMENT
};

export default function(state = Map(defaultState), action) {

  state = state.set('createExternalNotificationDialog', createExternalNotificationDialogReducer(state.get('createExternalNotificationDialog'), action));
  switch (action.type) {

    case actionTypes.ADMINISTRATION_USER_SETTINGS_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);

    default:
      return state;
  }
}



