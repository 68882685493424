import { useMemo, useEffect } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { jsxActions as logsPageActions } from '../../../../actions/Administration/LogsPage/logsPageActions.js';
import { jsxActions as schedulingLogsViewActions } from '../../../../actions/Administration/LogsPage/SchedulingLogsView/SchedulingLogsViewActions';
import { jsxActions as schedulingLogsGridActions } from '../../../../actions/Administration/LogsPage/SchedulingLogsView/SchedulingLogsGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as activityLogDialogActions } from '../../../../actions/ActivityLogDialog/activityLogDialogActions.js';
import { gridsNames } from '../../../../enums/gridsNames';
import { LogsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';
import SchedulingLogsGrid from './schedulingLogsGrid';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';

import './schedulingLogsView.scss';

const allActions = {
  logsPageActions,
  schedulingLogsViewActions,
  schedulingLogsGridActions,
  fetchEntitiesActions,
  activityLogDialogActions,
};

const stateSelector = (state) => {
  let schedulingLogsGrid = state.grid.get(gridsNames.ADMINISTRATION_SCHEDULING_LOGS);
  return {
    sSchedulingLogsGrid: schedulingLogsGrid,
  };
};

export default function SchedulingLogsView() {
  const labels = useMemo(() => createLabelHelper('mat.administration.logs.schedulingLogs.'), []);
  const { actions } = useRedux(allActions, stateSelector);

  useEffect(() => {
    actions.logsPageActions.onSelectedMenuItemChanged(LogsMenuItemsIds.NAV_ELEMENT_SCHEDULER_LOGS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerItems = {
    buttons: [
      {
        id: 'clearFilters',
        label: '',
        icon: 'pl pl-filter-clear',
        action: actions.schedulingLogsViewActions.clearFilters,
        tooltip: headerItemsLabels.CLEAR_FILTERS,
      },
      {
        id: 'refresh',
        label: '',
        icon: 'pl pl-refresh',
        action: actions.schedulingLogsViewActions.reload,
        tooltip: headerItemsLabels.REFRESH,
      },
    ],
  };

  return (
    <div className="scheduling-logs-view">
      <Header title={labels.get('title')} {...headerItems} />
      <SchedulingLogsGrid actions={{ ...actions.schedulingLogsGridActions,
        ...actions.fetchEntitiesActions ,
        ...actions.activityLogDialogActions }} />
    </div>
  );
}
