import { useState, useRef, useEffect, useMemo } from 'react';
import {Link, matchPath, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useOnClickOutside } from 'infrastructure/js/hooks/useOnClickOutside';
import { getNavigationElements, liveMapLinkPath, settingsLinkPath } from './sideNavigatorHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import NavigatorMenu from './navigatorMenu';
import { AppMainMenuItemsIds } from '../../enums/navigationMenuItemsIds';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ArrowRightIcon from 'infrastructure/assets/svg/arrow-right-thin.svg';
import SettingsIcon from 'infrastructure/assets/svg/header-settings-icon.svg';
import SearchIcon from 'infrastructure/assets/svg/search-icon-big.svg';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';

import './sideNavigator.scss';

function SideNavigator({ actions,
                         sData,
                         featureFlags={},
                         topNavigatorData={} }) {

  const labels = useMemo(() => createLabelHelper('mat.sideNavigator.'), []);
  const sideMenuRef = useRef(null);
  const menuItems = useMemo(() => getNavigationElements(sData, topNavigatorData, featureFlags), [sData, topNavigatorData, featureFlags]);
  const [expanded, setExpanded] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    actions.init(featureFlags.ALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeMenu = () => {
    setExpanded(false);
  };

  const openMenu = () => {
    setExpanded(true);
  };

  useOnClickOutside(sideMenuRef, closeMenu);

  const handleSideBarItemClick = (sideBarItemId) => () => {
    setExpandedItem(sideBarItemId);
    setExpanded(true);
  };

  const handleSaveFavorite = (favData) => {
    actions.saveFavorite(favData);
  };

  const handleDeleteFavorite = (favData) => {
    actions.deleteFavorite(favData);
  };

  const sideBarButtons = menuItems
    .filter(({ isAllowed }) => isAllowed)
    .map(({ icon: Icon, id, label, items }) => {

      const selected = (id === AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES) ? false :
                        items?.length ? !!items?.find(item => {return matchPath(pathname, item.link)}) : false;

      return (
        <Tooltip key={id} placement="right" value={label}>
          <button className={cn('side-bar-button', {selected})} onClick={handleSideBarItemClick(id)} data-uid={'SIDE_' + id}>
            {Icon && <Icon className="icon" />}
          </button>
        </Tooltip>
      );
    });

  const navigatorMenuActions = {
    saveFavorite: PermissionManager.hasSelfSustainingOrgPermissions() && handleSaveFavorite,
    deleteFavorite: PermissionManager.hasSelfSustainingOrgPermissions() && handleDeleteFavorite,
    setHomePage: actions.setHomePage,
    closeMenu,
    setExpandedItem,
  };

  const isFavoritesFull = sData.get('favorites').length >= PermissionManager.getOrgPreferences().maxFavoriteLinks;
  const settingsSelected = matchPath(pathname,'/Administration/settings/*');

  return (
    <div className="side-navigator">
      <div className="side-bar">
        <Tooltip placement="right" value={labels.get('search')}>
          <button className="side-bar-button" onClick={openMenu} data-uid={'SIDE_' + AppMainMenuItemsIds.NAV_ELEMENT_SEARCH}>
            <SearchIcon className="icon" />
          </button>
        </Tooltip>

        {sideBarButtons}

        <Tooltip placement="right" value={labels.get('openMenu')}>
          <button className="side-bar-button" onClick={openMenu} data-uid={'SIDE_' + AppMainMenuItemsIds.NAV_ELEMENT_OPENMENU}>
            <ArrowRightIcon className="icon" />
          </button>
        </Tooltip>


        {PermissionManager.hasMatSettingsTabPermissions() && !featureFlags.ORGANIZATIONS && (
          <Tooltip placement="right" value={labels.get('mainSettings')}>
            <Link className={cn('side-bar-button', {selected: settingsSelected})} to={settingsLinkPath} data-uid={'SIDE_' + AppMainMenuItemsIds.NAV_ELEMENT_MAIN_SETTINGS}>
              <SettingsIcon className="icon" />
            </Link>
          </Tooltip>
        )}
      </div>
      <div className={cn('side-menu', { expanded })} ref={sideMenuRef}>
        <NavigatorMenu actions={navigatorMenuActions} menuItems={menuItems} expandedItem={expandedItem} isFavoritesFull={isFavoritesFull} />

        {PermissionManager.hasMatSettingsTabPermissions() && !featureFlags.ORGANIZATIONS && (
          <Link className={cn('settings-button', {selected: settingsSelected})} to={settingsLinkPath} onClick={closeMenu}>
            <SettingsIcon className="settings-button-icon" />
            <span>{labels.get('mainSettings')}</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default SideNavigator;

SideNavigator.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  featureFlags: PropTypes.object,
  topNavigatorData: PropTypes.object,
};
