import { connect } from 'react-redux';
import {List} from 'immutable';
import { bindActionCreators } from 'redux';
import { jsxActions as shipmentsViewActions }  from '../../actions/LocationPage/ShipmentsView/shipmentsViewActions';
import { jsxActions as shipmentsGridActions }  from '../../actions/LocationPage/ShipmentsView/shipmentsGridActions';
import { jsxActions as headerActions }   from '../../actions/Header/headerActions';
import { jsxActions as exportActions }   from '../../actions/Export/exportActions.js';
import { jsxActions as locationPageActions }      from '../../actions/LocationPage/locationPageActions';
import { jsxActions as processTemperatureLogDialogActions } from '../../actions/Dialogs/ProcessTemperatureLogDialog/processTemperatureLogDialogActions';
import ShipmentsView from '../../components/LocationPage/ShipmentsView/shipmentsView.js';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as fetchEntitiesActions} from '../../actions/FetchEntities/fetchEntitiesActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      ...bindActionCreators(shipmentsViewActions, dispatch),
      headerActions: bindActionCreators(headerActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch),
      shipmentsGridActions:   bindActionCreators(shipmentsGridActions, dispatch),
      locationPageActions:       bindActionCreators(locationPageActions, dispatch),
      processTemperatureLogDialogActions: bindActionCreators(processTemperatureLogDialogActions, dispatch)
    }
  }
}

let ShipmentsViewContainer = connect(

  (state) => {
    let sGrid = state.grid.get(gridsNames.LOCATION_SHIPMENTS) ? state.grid.get(gridsNames.LOCATION_SHIPMENTS) : null;
    return {
      sGrid:  sGrid,
      sHasRows: sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData:      sGrid ? sGrid.get('selectedRowsData') : List(),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sProcessTemperatureLogDialogData: state.dialogs.get('processTemperatureLogDialog')
    };
  },
  mapDispatchToProps
)(ShipmentsView);

export default ShipmentsViewContainer;


