import { connect } from 'react-redux';
import {isDirty} from 'redux-form';
import { bindActionCreators } from 'redux';
import { gridsNames } from '../../../../enums/gridsNames';
import MisplacedMaterialView from '../../../../components/Administration/SettingsPage/Alerts/MisplacedMaterialView/misplacedMaterialView';
import { jsxActions as headerSettingsActions } from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions } from '../../../../actions/Administration/SettingsPage/settingsPageActions.js';
import { jsxActions as commonAlertsViewActions } from '../../../../actions/Administration/SettingsPage/commonAlertsViewActions';
import { jsxActions as misplacedMaterialRulesGridActions } from '../../../../actions/Administration/SettingsPage/misplacedMaterialRulesGridActions';
import { jsxActions as misplacedMaterialAlertRuleDialogActions } from '../../../../actions/Administration/SettingsPage/Dialogs/misplacedMaterialAlertRuleDialogActions';
import { jsxActions as misplacedMaterialAlertActions } from '../../../../actions/Administration/SettingsPage/misplacedMaterialAlertActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonAlertsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      settingsPageActions: bindActionCreators(settingsPageActions, dispatch),
      misplacedMaterialAlertActions: bindActionCreators(misplacedMaterialAlertActions, dispatch),
      misplacedMaterialRulesGridActions: bindActionCreators(misplacedMaterialRulesGridActions, dispatch),
      misplacedMaterialAlertRuleDialogActions: bindActionCreators(misplacedMaterialAlertRuleDialogActions, dispatch),
    },
  };
}

const MisplacedMaterialViewContainer = connect((state) => {
  let gridData = state.grid.get(gridsNames.ADMINISTRATION_ALERTS_MISPLACED_MATERIALS);
  return {
    sData: state.administration.getIn(['settingsPage', 'commonAlertsView']),
    sMisplacedMaterialAlertRulesGridData: gridData,
    sMisplacedMaterialAlertRuleDialogData: state.dialogs.get('misplacedMaterialAlertRuleDialog'),
    isDirty: isDirty('misplacedMaterialForm')(state)
  };
}, mapDispatchToProps)(MisplacedMaterialView);

export default MisplacedMaterialViewContainer;
