import Network                     from 'infrastructure/js/modules/network';
import { List }                    from 'immutable';
import * as daHelper               from '../../Dialogs/dialogActionsHelper';
import * as dialogHelper           from 'infrastructure/js/components/Dialog/dialogHelper';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi }  from '../locationPageActions.js';
import {dialogsNames}              from '../../../enums/dialogsNames';
import  * as shipmentsService      from '../../../services/Shipments/shipmentsService';

const config = {
  getSubmitMethod: () => shipmentsService.addToShipment,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.ADD_TO_SHIPMENT_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.ADD_TO_SHIPMENT_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit(data, messageDialogBuilder, reloadParentComponent) {
    return function (dispatch, getState) {
      data.locationId = locationPageApi.getLocationId(dispatch, getState)();
      return api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
    };
  },
};




