import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {List} from 'immutable';
import {jsxActions as headerActions} from '../../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions }      from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as assetsViewActions }        from '../../../actions/LocationPage/AssetsView/assetsViewActions';
import { jsxActions as assetsResinsGridActions }        from '../../../actions/LocationPage/AssetsView/assetsResinsGridActions';
import {jsxActions as createResinActions} from '../../../actions/LocationPage/AssetsView/createResinActions';
import {gridsNames} from '../../../enums/gridsNames';
import ResinsView from '../../../components/LocationPage/AssetsView/ResinsView/resinsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(assetsViewActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      headerActions:        bindActionCreators(headerActions, dispatch),
      assetsResinsGridActions:        bindActionCreators(assetsResinsGridActions, dispatch),
      createResinActions:        bindActionCreators(createResinActions, dispatch),
    }
  }
}

let ResinsViewContainer = connect(
  (state) => {
    let sGrid = state.grid?.get(gridsNames.LOCATION_ASSET_RESINS) || null;
    return {
      sGrid:                  sGrid,
      sHasRows:               sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData:      sGrid ? sGrid.get('selectedRowsData') : List(),
      sData:                  state.locationPage.getIn(['assetsView', 'resinsView']),
      sLocationDetails:       state.locationPage.get('data').get('locationDetails'),
      sLoggedInUser :         state.login.get('loggedInUser'),
      sDataCreateResin:       state.locationPage.get('createResinData'),
    };
  },
  mapDispatchToProps
)(ResinsView);

export default ResinsViewContainer;




