import {useMemo} from 'react';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {gridsNames} from '../../../../enums/gridsNames';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { jsxActions as assetsManagementGridActions } from '../../../../actions/Administration/PsManagementPage/assetsManagementGridActions';
import { jsxActions as assetsManagementViewActions } from '../../../../actions/Administration/PsManagementPage/assetsManagementViewActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions';
import {List} from 'immutable';
import AssetsManagementGrid from './AssetsManagementGrid/assetsManagementGrid';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

import'./assetsManagementView.scss';


const getState = (state) => {
  let grid = state.grid?.get(gridsNames.ADMINISTRATION_PS_MANAGEMENT_ASSETS) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
  };
};

const allActions = {
  assetsManagementViewActions,
  assetsManagementGridActions,
  fetchEntitiesActions,
};


export default function AssetsManagementView() {
  const labels = useMemo(() => createLabelHelper('mat.administration.psmanagement.assetsManagement.view.'), []);
  const { state, actions } = useRedux(allActions, getState);
  const {sSelectedRowsData } = state;

  const getHeaderItems = () => {
    return {
      title: labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.assetsManagementViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.assetsManagementViewActions.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id: 'removeAll',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
          action: actions.assetsManagementViewActions.removeAssets,
          actionData: sSelectedRowsData,
        }
      ],
    };
  }

  return (
    <div className='assets-management-view'>
      <Header {...getHeaderItems()}/>
      <AssetsManagementGrid gridName={gridsNames.ADMINISTRATION_PS_MANAGEMENT_ASSETS}
                         actions={{...actions.assetsManagementGridActions, ...actions.fetchEntitiesActions}}
      />
    </div>
  )
}

AssetsManagementView.propTypes = {
};
