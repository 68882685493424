import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';

import Header, { headerItemsLabels } from '../../../Common/Header/header';
import SchedulerOperationsGrid from './SchedulerOperationsGrid/schedulerOperationsGrid';
import ScheduleTaskDialog from './Dialogs/ScheduleTaskDialog/scheduleTaskDialog';
import AutoSchedulerDialog from './Dialogs/AutoSchedulerDialog/autoSchedulerDialog';
import CreateTaskDialog from './Dialogs/CreateTaskDialog/createTaskDialog';
import EditTaskAttributesDialog from '../../../Dialogs/EditTaskAttributesDialog/editTaskAttributesDialog';
import { gridsNames } from '../../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import { getEnumValue, enumTypes } from '../../../../utils/enumHelper';
import { autoSchedulingStatuses } from '../../../../../../mat/js/enums/autoSchedulingStatuses';
import SchedulerProgressIndicator from 'infrastructure/js/components/Scheduler/Components/SchedulerHeader/schedulerProgressIndicator';
import CollapseIcon from 'infrastructure/assets/svg/collapse.svg';
import ExpandIcon from 'infrastructure/assets/svg/expand.svg';
import { taskStatusIcons } from '../../../../../assets/svg';

import './schedulerOperationsView.scss';

const { StartedTask, CompletedTask, ScheduledTask, UnscheduledTask } = taskStatusIcons;
export default class SchedulerOperationsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.statusLabels = createLabelHelper('enum.planneroperation.status.');
    this.labels = createLabelHelper('mat.scheduler.operations.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  getEditTaskDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.UNASSIGNED,
      boEntityFilter: [FetchEntitiesFilters.UNSCHEDULED_TASKS],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TASK')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  isTaskOutOfRange = (task) => {
    const horizonStartDate = moment().subtract(2, 'weeks').startOf('day'); // Gantt horizon start date is always 2 weeks in the past
    const startTime = moment.unix(task?.startTime?.epochDateTime);
    return startTime.isBefore(horizonStartDate);
  };

  getHeaderItems() {
    let { actions } = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;
    let singleRowStatus = selectedRowsData.get(0) ? selectedRowsData.get(0).operationStatus : null;
    let hasCompletedOrStartedStatus = selectedRowsData.some((row) => row.operationStatus === 'COMPLETED' || row.operationStatus === 'STARTED');
    let editTaskDialogConfig = this.getEditTaskDialogConfig();
    const asyncSchedulingInProgress = this.props.asyncSchedulingData.get('status') === autoSchedulingStatuses.BUSY;
    const loading = this.props.loading;

    return {
      buttons: [
        {
          id: 'collapseAllButton',
          icon: <CollapseIcon />,
          tooltip: headerItemsLabels.COLLAPSE_ALL,
          action: () => this.gridApi?.collapseAll(),
        },
        {
          id: 'expandAllButton',
          icon: <ExpandIcon />,
          tooltip: headerItemsLabels.EXPAND_ALL,
          action: () => this.gridApi?.expandAll(),
        },
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.schedulerTasksPageActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH,
        },
        {
          id: 'goTo',
          label: '',
          icon: 'fa fa-map-marker',
          action: this.goToTask,
          disabled: !(hasRows && selectedRowsData.size === 1) || singleRowStatus === 'UNASSIGNED' || this.isTaskOutOfRange(selectedRowsData.get(0)),
          tooltip: headerItemsLabels.SHOW_ON_GANTT,
          actionData: {
            task: selectedRowsData.get(0)
          },
        },
        {
          id: 'autoSchedule',
          className: 'no-icon',
          label: headerItemsLabels.AUTO_SCHEDULE,
          action: this.autoSchedule,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size === 0 || asyncSchedulingInProgress,
          actionData: selectedRowsData,
        },
        {
          id: 'assign',
          icon: 'pl pl-scheduler-plus',
          label: '',
          tooltip: headerItemsLabels.SCHEDULE,
          action: this.assignSchedulerTask,
          disabled:
            !(hasRows && selectedRowsData.size === 1) ||
            singleRowStatus === 'SCHEDULED' ||
            singleRowStatus === 'COMPLETED' ||
            singleRowStatus === 'STARTED' || //TODO: L should be in Enum
            asyncSchedulingInProgress ||
            loading,
          actionData: selectedRowsData.get(0),
        },
        {
          id: 'unAssign',
          icon: 'pl pl-scheduler-minus',
          label: '',
          tooltip: headerItemsLabels.UNSCHEDULE,
          action: this.unAssign,
          disabled:
            !(
              hasRows &&
              selectedRowsData.size &&
              selectedRowsData.every((row) => {
                return !row.locked && row.operationStatus === 'SCHEDULED';
              })
            )
            || hasCompletedOrStartedStatus
            || asyncSchedulingInProgress || loading,
          actionData: selectedRowsData,
        },

        {
          id: 'lock',
          label: '',
          icon: 'pl pl-lock',
          tooltip: headerItemsLabels.LOCK,
          action: this.lockTasks,
          disabled:
            !(hasRows && selectedRowsData.size && selectedRowsData.every((row) => row.operationStatus === 'SCHEDULED'))
            || hasCompletedOrStartedStatus
            || asyncSchedulingInProgress,
          actionData: selectedRowsData,
        },
        {
          id: 'unlock',
          label: '',
          icon: 'pl pl-unlock',
          tooltip: headerItemsLabels.UNLOCK,
          action: this.unlockTasks,
          disabled:
            !(hasRows && selectedRowsData.size && selectedRowsData.every((row) => row.operationStatus === 'SCHEDULED'))
            || hasCompletedOrStartedStatus
            || asyncSchedulingInProgress,
          actionData: selectedRowsData,
        },
      ],
      kebab: {
        menuItems: [
          {
            id: 'createTask',
            label: headerItemsLabels.CREATE_TASK,
            action: actions.header.onCreateTaskClick,
          },
          {
            id: 'deleteTask',
            label: headerItemsLabels.DELETE_TASK,
            disabled: !hasRows || selectedRowsData?.size === 0,
            action: actions.header.onDeleteTasksClick,
            actionData: selectedRowsData,
          },
          {
            id: 'editTask',
            label: headerItemsLabels.EDIT_ATTRIBUTES,
            // disabled: !(hasRows && selectedRowsData.size > 0 && selectedRowsData.every((item) => item.operationStatus === 'UNASSIGNED')),
            disabled: !(hasRows && selectedRowsData.size === 1 &&
              (selectedRowsData.get(0).operationStatus === 'UNASSIGNED' || selectedRowsData.get(0).operationStatus === 'COMPLETED')),
            action: actions.header.onMenuItemEditTaskAttributesClick,
            actionData: editTaskDialogConfig,
          },
          {
            id: 'exportTasksList',
            label: headerItemsLabels.EXPORT_TASKS_LIST,
            action: actions.header.onExportTasksListClick,
            actionData: { gridName: gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS },
          },
        ],
      },
    };
  }

  getCounters = () => {
    let data = this.props.sCounters;
    if (!data) {
      return null;
    }
    return (
      <div className="tasks-counters">
        <span className="title">{this.labels.get('header.title', undefined, { count: data.allTasks })}</span>

        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('ALERTED')}>
            <span className="pl pl-error-icon"></span>
          </Tooltip>
          <span className="value">{data.nonValidCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('UNASSIGNED')}>
            <span className="counter">
              <UnscheduledTask />
            </span>
          </Tooltip>
          <span className="value">{data.unscheduledCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('SCHEDULED')}>
            <span className="counter">
              <ScheduledTask />
            </span>
          </Tooltip>
          <span className="value">{data.scheduledCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('STARTED')}>
            <span className="counter">
              <StartedTask />
            </span>
          </Tooltip>
          <span className="value">{data.startedCount}</span>
        </div>
        <div className="counter">
          <Tooltip placement="top" value={this.statusLabels.get('COMPLETED')}>
            <span className="counter">
              <CompletedTask />
            </span>
          </Tooltip>
          <span className="value">{data.completedCount}</span>
        </div>
      </div>
    );
  };

  autoSchedule = (tasks) => {
    if (tasks && tasks.size > 0) {
      let selectedTasksIds = tasks.map((task) => {
        return task.id;
      });
      this.props.actions.schedulerTasksPageActions.autoSchedule(selectedTasksIds.toJS());
    }
  };

  unAssign = (data) => {
    this.props.actions.schedulerTasksPageActions.unAssign(data);
  };

  assignSchedulerTask = (data) => {
    this.props.actions.schedulerTasksPageActions.assignSchedulerTask(data);
  };

  lockTasks = (data) => {
    this.props.actions.schedulerTasksPageActions.lockSchedulerTasks(data);
  };

  unlockTasks = (data) => {
    this.props.actions.schedulerTasksPageActions.unlockSchedulerTasks(data);
  };

  goToTask = ({task}) => {
    this.props.setSelectedRow({id: task.resource.id});
    this.props.history.push(`/PredefinedViews/scheduler/gantt/${task.id}`);
  };


  reloadPage = () => {
    const query = {};
    this.props.actions.schedulerTasksPageActions.refreshPageData(query, true);
  };

  renderProgressIndicator = () => {
    const schedulingStatus = this.props?.asyncSchedulingData.get('status');

    const { COMPLETED, BUSY } = autoSchedulingStatuses;

    if ([COMPLETED, BUSY].includes(schedulingStatus)) {
      const { abortAsyncScheduling, showAsyncSchedulingActivityLog } = this.props.actions.schedulerTasksPageActions;
      return <SchedulerProgressIndicator actions={{ abortAsyncScheduling, showAsyncSchedulingActivityLog }} statusData={this.props.asyncSchedulingData} />;
    }

    return null;
  };

  getHeaderLeftContent = () => {
    return (
      <div className="header-left-content">
        {this.getCounters()}
        {this.renderProgressIndicator()}
      </div>
    );
  }

  onGridReadyCallback = ({api}) => {
    this.gridApi = api;
  }

  render() {
    const asyncSchedulingInProgress = this.props.asyncSchedulingData.get('status') === autoSchedulingStatuses.BUSY;
    let headerItems = this.getHeaderItems();

    return (
      <div className="scheduler-operations-view">
        <Header {...headerItems} leftContent={this.getHeaderLeftContent()} />
        <SchedulerOperationsGrid
          actions={{
            ...this.props.actions.schedulerOperationsGridActions,
            ...this.props.actions.fetchEntitiesActions,
          }}
          sSchedulerData={this.props?.sSchedulerData}
          asyncSchedulingInProgress={asyncSchedulingInProgress}
          highlightedTaskId={this.props.highlightedTaskId}
          onGridReadyCallback={this.onGridReadyCallback}
        />

        <PL_DialogWrapper
          dialogComponent={ScheduleTaskDialog}
          show={this.props.sScheduleTaskDialogData.get('show')}
          actions={this.props.actions.scheduleTaskDialogActions}
          sData={this.props.sScheduleTaskDialogData}
          sLoggedInUser={this.props.sLoggedInUser}
          sSchedulerEngineInstance={this.props.sSchedulerEngineInstance}
        />

        <PL_DialogWrapper
          dialogComponent={AutoSchedulerDialog}
          show={this.props.sAutoSchedulerDialogData.get('show')}
          actions={this.props.actions.autoSchedulerDialogActions}
          sData={this.props.sAutoSchedulerDialogData}
          sLoggedInUser={this.props.sLoggedInUser}
          sSchedulerEngineInstance={this.props.sSchedulerEngineInstance}
        />

        <PL_DialogWrapper
          dialogComponent={CreateTaskDialog}
          show={this.props.sCreateTaskDialogData.get('show')}
          actions={this.props.actions.createTaskDialogActions}
          sData={this.props.sCreateTaskDialogData}
          sLoggedInUser={this.props.sLoggedInUser}
          sSchedulerEngineInstance={this.props.sSchedulerEngineInstance}
        />
        <PL_DialogWrapper
          dialogComponent={EditTaskAttributesDialog}
          show={this.props.sEditTaskAttributesData.get('show')}
          actions={this.props.actions.editTaskAttributesActions}
          sData={this.props.sEditTaskAttributesData}
          sLoggedInUser={this.props.sLoggedInUser}
          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}

SchedulerOperationsView.propTypes = {
  sGrid: PropTypes.object,
};
