import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {navigationStates} from '../../../../../enums/navigationStates';
import CheckMarkCell from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import MultiRowsCell from '../../../../../../../infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import LabelWithTooltipCell from "../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell";

export default class PrintersViewGrid extends React.PureComponent{

  filterConfig = [  ];

  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.printers.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'name',
        title: this.labels.get('columns.printername.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'host',
        title: this.labels.get('columns.host.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'port',
        title: this.labels.get('columns.port.title'),
        filterType: filterTypes.NONE,
        width: 85,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'zebraPrinterLocations',
        title: this.labels.get('columns.locations.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.zebraPrinterLocations.map(location =>  ({id : location.id , type: navigationStates.LOCATION , label: location.name})),
              componentType: EntityIdCell
            };
          }
        }
      },
      {
        fieldName: 'default',
        title: this.labels.get('columns.defaultprinter.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sort: 'desc',
          sortable: false,
          cellComponent: CheckMarkCell,
        }
      },
    ]
  };

  getRowHeight(params) {
    let rowHeight =  64;
    if (params.data.zebraPrinterLocations && params.data.zebraPrinterLocations.length > 1) {
      rowHeight = params.data.zebraPrinterLocations.length* 30 + 40;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_RFID_SETTINGS_PRINTERS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {getRowHeight: this.getRowHeight}
              }
        >
        </Grid>
      </div>
    );
  }
}

