import {createLabelHelper} from 'infrastructure/js/utils/labelHelper.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import {getEnumValue, enumTypes} from '../../../mat/js/utils/enumHelper';
function _createWoAlertData(item, mostSevereAlert, serverTimeSeconds) {

  if (!mostSevereAlert) {
    return;
  }
  if (mostSevereAlert.assetBusinessId) {
    let asset = item.relatedAssets.find((asset) => {
        return asset.businessId === mostSevereAlert.assetBusinessId;
      }
    );

    if (asset) {
      mostSevereAlert.alertDetails = {};
      let exposureTimeLeftBond = asset.exposureTimeLeftBond ? asset.exposureTimeLeftBond : { value: undefined };
      let exposureTimeLeftCure = asset.exposureTimeLeftCure ? asset.exposureTimeLeftCure : { value: undefined };
      mostSevereAlert.alertDetails.exposureTimeLeftBond = {};
      mostSevereAlert.alertDetails.exposureTimeLeftBond.value = exposureTimeLeftBond ? exposureTimeLeftBond.value : null;
      mostSevereAlert.alertDetails.exposureTimeLeftCure = {};
      mostSevereAlert.alertDetails.exposureTimeLeftCure.value = exposureTimeLeftCure ? exposureTimeLeftCure.value : null;

      mostSevereAlert.alertDetails.expirationDaysLeft = (asset.expirationDate && asset.expirationDate.value) ?
            DateTimeHelper.GetDifferenceInDays(asset.expirationDate.value, serverTimeSeconds) : '';

      mostSevereAlert.alertDetails.woDueDaysLeft =
            (item.dueDate) ? DateTimeHelper.GetDifferenceInDays(item.dueDate, serverTimeSeconds) : '';

      mostSevereAlert.alertDetails.dueDateMinutesLeft = item.dueDateMinutesLeft;
    }
  }
  return mostSevereAlert;
}

function _convertServerItemsToGridRowsData(items, serverTimeSeconds) {
  let gridRowsData = items.map((obj) => {
    let item = obj;

    let newObj = {};

    // this is a work around till server return entity type
    newObj.objectType = getEnumValue(enumTypes.OBJECT_TYPE)('WO');

    newObj.id = item.id;
    newObj.businessId = item.businessId;
    newObj.dueDate = item.dueDate;
    newObj.m_DueDate = _convertDueDateToMessage(item.dueDate, serverTimeSeconds);
    newObj.m_CompletionDate = DateTimeHelper.FormatDateObjectToDayMonth(item.lastStatusChangeDate);

    newObj.pickDate = item.pickDate;
    newObj.m_PickDate = DateTimeHelper.FormatDateObjectToDayMonth(item.pickDate);

    newObj.mostSevereAlert = _createWoAlertData(item, item.mostSevereAlert, serverTimeSeconds);
    let mostSevereExpirationDateAlert = _createWoAlertData(item, item.mostSevereExpirationDateAlert, serverTimeSeconds);
    let mostSevereWoDueDateAlert =_createWoAlertData(item, item.mostSevereWoDueDateAlert, serverTimeSeconds);
    let mostSevereETLBondAlert =_createWoAlertData(item, item.mostSevereETLBondAlert, serverTimeSeconds);
    let mostSevereETLCureAlert =_createWoAlertData(item, item.mostSevereETLCureAlert, serverTimeSeconds);
    newObj.alerts = [mostSevereExpirationDateAlert, mostSevereWoDueDateAlert, mostSevereETLBondAlert, mostSevereETLCureAlert ];
    newObj.warningKitCount = item.warningKitCount;
    newObj.errorKitCount = item.errorKitCount;
    // newObj.m_CompletionDate = DateTimeHelper.ConvertToDate(item.lastStatusChangeDate);
    newObj.hasAssets = item.hasAssets;
    newObj.workOrderStatus = item.workOrderStatus;


    newObj.project = item.project;
    newObj.kitType = item.kitType;
    newObj.partType = item.partType;
    newObj.workOrderType = item.workOrderType;
    newObj.quantity = item.quantity;
    newObj.lastTaskCompletedQuantity = item.lastTaskCompletedQuantity;
    newObj.startAfterWorkOrdersItems = item.startAfterWorkOrdersItems;
    newObj.taskStatusCounts = item.taskStatusCounts;

    if (item.relatedAssets) {
      newObj.relatedAssets = item.relatedAssets.map((asset)=> {
        let m_ExpirationDaysLeft = '';
        let m_DueDateLeft = '';
        if(asset.expirationDate && asset.expirationDate.value){
          m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(asset.expirationDate.value, serverTimeSeconds);
        }
        m_DueDateLeft = DateTimeHelper.GetDifferenceInDays(newObj.dueDate, serverTimeSeconds);

        return {
          id: asset.id,
          objectType: asset.objectType,
          businessId: asset.businessId,
          alertStatus: asset.alertStatus,
          hasAlerts: asset.hasAlerts,
          alerts: asset.alerts,
          active: asset.active,
          expirationDate: asset.expirationDate,
          exposureTimeLeftBond: asset.exposureTimeLeftBond ? asset.exposureTimeLeftBond : { value: undefined },
          exposureTimeLeftCure: asset.exposureTimeLeftCure ? asset.exposureTimeLeftCure : { value: undefined },
          expirationDaysLeft: m_ExpirationDaysLeft,
          dueDateLeft: m_DueDateLeft,
          dueDateMinutesLeft: item.dueDateMinutesLeft
        }});
    }

    if (item.lastOperation) {
      newObj.lastOperation = {
        activityType: item.lastOperation.activityType,
        activityTypeDisplayKey: item.lastOperation.activityTypeDisplayKey,
        operationReported: item.lastOperation.operationReported,
        locationName: item.lastOperation.locationName,
        operationStatus: item.lastOperation.operationStatus,
        m_time: item.lastOperation.createdDate ? DateTimeHelper.FormatDateObjectToRelativeTime(item.lastOperation.createdDate, serverTimeSeconds) : null
      }
    }

    return newObj;
  });
  return gridRowsData;
};

function _convertDueDateToMessage(date, serverTimeSeconds) {
  if (date === null || date == undefined){
    return '';
  }

  return  createLabelHelper('mat.locationpage.workorder.grid.').get('columns.due.body', undefined, {count: DateTimeHelper.GetDifferenceInDays(date, serverTimeSeconds)});
};


export default {_convertServerItemsToGridRowsData, _convertDueDateToMessage};
