import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import AdditionalField from '../../../../Common/Layout/AdditionalField/AdditionalField';

import Dialog from 'infrastructure/js/components/Dialog/dialog';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import _ from 'lodash';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import Label from 'infrastructure/js/components/Label/label.js';
import MaterialHelper from '../../../../../utils/materialHelper';
import { getDateFormatIcon } from 'infrastructure/js/components/controls/controlsUtils';
import Button from 'infrastructure/js/components/controls/Button/button';
import SingleLineMultiSelect from 'infrastructure/js/components/controls/SingleLineMultiSelect/singleLineMultiSelect.js';

require('./createResinDialog.scss');

class CreateResinDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.createResinDialog.');
    this.loggedInUser = PermissionManager.getLoggedInUser();
    this.isExposureByRange = PermissionManager.getOrgPreferences().defineEtlCureByDates;
    this.state = {
      isMaxExposureTimeCureByRange: this.isExposureByRange,
      MaxExposureCureStartTime: null,
      MaxExposureCureEndTime: null,
      isWeighSucceed: false,
      isWeightLoading: false,
    };

    this.expirationDatePickerRef = null;
  }

  componentDidMount() {
    if (this.isExposureByRange) {
      let initialValues = { headerCheckbox_maxExposureTimeCure: true };
      this.props.initialize(initialValues);
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),

          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          disabled: this.state.isWeightLoading,
          text: this.labels.get('footer.createResin'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };
  }

  onSubmit = (data) => {
    let maxExposureTimeBondMinutes = DateTimeHelper.ConvertHoursMinutesToMinutes(
      data.maxExposureTimeBond_hours,
      data.maxExposureTimeBond_minutes
    );
    let maxExposureTimeCureMinutes;

    if (this.isExposureByRange && data.maxExposureTimeCureEnd && data.maxExposureTimeCureStart) {
      let timeInMinutes = data.maxExposureTimeCureEnd.diff(
        data.maxExposureTimeCureStart,
        'minutes'
      );
      maxExposureTimeCureMinutes = timeInMinutes;
    } else {
      maxExposureTimeCureMinutes = DateTimeHelper.ConvertHoursMinutesToMinutes(
        data.maxExposureTimeCure_hours,
        data.maxExposureTimeCure_minutes
      );
    }

    let submitData = {
      ...data,
      maxExposureTimeBond: maxExposureTimeBondMinutes,
      maxExposureTimeCure: maxExposureTimeCureMinutes,
      viscosity: UnitHelper.userValueToServerValue(unitTypes.VISCOSITY, data.viscosity, 2) || 0,
      volume: UnitHelper.userValueToServerValue(unitTypes.VOLUME, data.volume, 2) || 0,
      weight: UnitHelper.userValueToServerValue(unitTypes.WEIGHT, data.weight, 2) || 0,
      maxStorageTemp: UnitHelper.userValueToServerValue(
        unitTypes.TEMPERATURE,
        data.maxStorageTemp,
        3
      ),
      additionalFieldsDefinitions: this.props.sData.get('additionalFields'),
    };
    this.props.actions.submit(submitData, this.getMessageDialogBuilder());
  };

  getMessageDialogBuilder = (location) => {
    return (data, fnCloseDialog) => {
      let children;
      let rows = [
        { label: this.labels.get('confirmation.message.resinId'), value: data.resinId },
        {
          label: this.labels.get('confirmation.message.weight'),
          value: UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, data.weight, 2),
        },
      ];

      if (data.maxExposureTimeBond) {
        let timeLeft = DateTimeHelper.ConvertMinutesToHoursMinutes(data.maxExposureTimeBond);
        rows.push({
          label: this.labels.get('confirmation.message.exposuretimeleftBond'),
          value: timeLeft,
        });
      }
      if (data.maxExposureTimeCure) {
        let timeLeft = DateTimeHelper.ConvertMinutesToHoursMinutes(data.maxExposureTimeCure);
        rows.push({
          label: this.labels.get('confirmation.message.exposuretimeleftCure'),
          value: timeLeft,
        });
      }

      if (data.expirationDate) {
        rows.push({
          label: this.labels.get('confirmation.message.expirationdate'),
          value: DateTimeHelper.FormatDateToDayMonth(data.expirationDate),
        });
      }

      if (data) {
        children = rows.map((item, index) => {
          return (
            <MessageDialog.DataRow
              key={`confirmMessageDataRow${index}`}
              label={item.label}
              value={item.value}
            />
          );
        });
      }

      let title = this.labels.get('confirmation.header');
      let buttons = [
        {
          id: 'close',
          text: this.dialogLabels.get('close'),
          action: fnCloseDialog,
          bsStyle: 'primary',
        },
      ];
      let className = 'create-resin-confirm-message';
      return { title, children, buttons, className };
    };
  };

  onEntered = () => {
    this.initData();
  };
  onHide = () => {
    this.props.actions.hide();
  };

  initData = () => {
    this.props.actions.fetchDialogData();
  };

  generateResinId = () => {
    this.props.actions.generateResinId().then((response) => {
      this.props.change('resinId', response.data);
    });
  };

  getComponentToRender(props) {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    let materialLabel = MaterialHelper.getMaterialFullLabel(
      option.data.materialName,
      option.data.businessId
    );

    return <Label text={materialLabel} />;
  }

  onWeighButtonClick = () => {
    this.props.change('weight', null);
    this.setState({ isWeighSucceed: false });
    this.setState({ isWeightLoading: true });

    this.props.actions.fetchWeight().then((response) => {
      let weight = response ? response.weight : null;
      if (weight && weight >= 0) {
        this.setState({ isWeighSucceed: true });
      }

      this.props.change('weight', weight);
      this.setState({ isWeightLoading: false });
    });
  };

  renderAdditionalFields = () => {
    if (!this.props.sData.get('additionalFields')) {
      return null;
    }
    return this.props.sData.get('additionalFields').map((field, index) => {
      return <AdditionalField key={index} field={field} index={index} />;
    });
  };

  getWeightIcon = () => {
    if (!this.props.sData.get('weightError') && !this.state.isWeighSucceed) {
      return null;
    }
    if (this.state.isWeighSucceed) {
      return <span className="pl pl-check-circle-icon-blue success" />;
    }
    return (
      <AlertPopover
        popoverType={'error'}
        popoverComponent={<div>{this.labels.get('weighError')}</div>}
      />
    );
  };
  getLabelExtraComponent = (value) => {
    return <span className="label-extra-component">{value}</span>;
  };

  renderMeasurementsSection = () => {
    return (
      <div className="measurements-section">
        <InputSection
          label={this.labels.get('viscosity')}
          labelExtraComponent={this.getLabelExtraComponent(
            `(${UnitHelper.getLabelForUnitType(unitTypes.VISCOSITY)}) `
          )}
          htmlFor="viscosity"
          className="inline"
        >
          <TextField
            id="viscosity"
            name="viscosity"
            className="short-textfield"
            normalize={Normalize.number(
              false,
              0,
              UnitHelper.getMaxValueForUnitType(unitTypes.VISCOSITY)
            )}
          />
        </InputSection>
        <InputSection
          label={this.labels.get('volume')}
          labelExtraComponent={this.getLabelExtraComponent(
            `(${UnitHelper.getLabelForUnitType(unitTypes.VOLUME)})`
          )}
          htmlFor="volume"
          className="inline"
        >
          <TextField
            id="volume"
            name="volume"
            className="short-textfield"
            normalize={Normalize.number(
              false,
              0,
              UnitHelper.getMaxValueForUnitType(unitTypes.VOLUME)
            )}
          />
        </InputSection>
      </div>
    );
  };

  renderWeightField = () => {
    return (
      <div className="measurements-section">
        <InputSection
          label={this.labels.get('weight')}
          labelExtraComponent={this.getLabelExtraComponent(
            `(${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}) ${'*'}`
          )}
          htmlFor="weight"
          className="inline "
        >
          <TextField
            id="weight"
            name="weight"
            className="short-textfield"
            normalize={Normalize.number(
              false,
              0,
              UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT)
            )}
            validate={Validation.required}
          />
        </InputSection>
        {PermissionManager.getOrgPreferences().weightscaleEnabled && (
          <InputSection className="inline weight-button">
            <Button
              loading={this.state.isWeightLoading}
              id="weightButton"
              bsStyle="primary"
              onClick={this.onWeighButtonClick}
            >
              {this.labels.get('weigh')}
            </Button>
            {this.getWeightIcon()}
          </InputSection>
        )}
      </div>
    );
  };

  renderMaxExposureTimeBond = () => {
    if (PermissionManager.isEtlBondEnabled()) {
      return (
        <InputSection
          label={this.labels.get('maxExposureTimeBond')}
          htmlFor="maxExposureTimeBond"
          className="inline right-side"
        >
          <TimeField
            id="maxExposureTimeBond"
            name="maxExposureTimeBond"
            hoursCount={true}
            maxHoursLength={3}
          />
        </InputSection>
      );
    }

    return <InputSection label="" className="spacer no-margin inline right-side" />;
  };

  renderMaxExposureTimeCureRange = () => {
    if (!this.isExposureByRange || !this.state.isMaxExposureTimeCureByRange) {
      return null;
    }
    return (
      <React.Fragment>
        <InputSection
          label={this.labels.get('maxExposureTimeCureStart')}
          htmlFor="maxExposureTimeCureStart"
          className="inline left-side"
          labelExtraComponent={getDateFormatIcon()}
        >
          <DatePicker
            id="maxExposureTimeCureStart"
            disabled={!this.state.isMaxExposureTimeCureByRange}
            name="maxExposureTimeCureStart"
            popperPlacement="auto"
            showTimeSelect
            dateFormat={DateTimeHelper.getDateTimeFormat()}
            timeCaption="time"
            timeIntervals={1}
            fixedHeight
            onChangeCallback={this.onMaxExposureCureStartTimeChange}
            validate={[Validation.date, this.validateMaxExposureCuringStartDate]}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('maxExposureTimeCureEnd')}
          htmlFor="maxExposureTimeCureEnd"
          className="inline right-side"
          labelExtraComponent={getDateFormatIcon()}
        >
          <DatePicker
            id="maxExposureTimeCureEnd"
            name="maxExposureTimeCureEnd"
            disabled={!this.state.isMaxExposureTimeCureByRange}
            selected={this.state.MaxExposureCureEndTime}
            popperPlacement="auto"
            showTimeSelect
            dateFormat={DateTimeHelper.getDateTimeFormat()}
            timeCaption="time"
            timeIntervals={1}
            fixedHeight
            onChangeCallback={this.onMaxExposureCureEndTimeChange}
            validate={[Validation.date, this.validateMaxExposureCuringDate]}
          />
        </InputSection>
      </React.Fragment>
    );
  };

  validateMaxExposureCuringStartDate = (value, allValues) => {
    let isStartDate = value && value._isValid;
    let isEndDate = allValues.maxExposureTimeCureEnd && allValues.maxExposureTimeCureEnd._isValid;

    if (!isStartDate && isEndDate) {
      return this.labels.get('validation.bothFieldRequire');
    }
    return undefined;
  };

  validateMaxExposureCuringDate = (value, allValues) => {
    if (!allValues || !allValues.headerCheckbox_maxExposureTimeCure) {
      return undefined;
    }
    let isStartDate =
      allValues.maxExposureTimeCureStart && allValues.maxExposureTimeCureStart._isValid;
    let isEndDate = value && value._isValid;
    if (!isStartDate && isEndDate) {
      return undefined;
    }

    if (isStartDate && !isEndDate) {
      return this.labels.get('validation.bothFieldRequire');
    }
    if (allValues.maxExposureTimeCureStart > value) {
      return this.labels.get('validation.endDateLaterThanStartDate');
    }

    if (isEndDate && isStartDate) {
      let hours = value.diff(allValues.maxExposureTimeCureStart, 'hours');
      if (hours > 999) {
        return this.labels.get('validation.etlLimit');
      }
    }
    return undefined;
  };

  onMaxExposureCureCheckboxChangeCallback = (event) => {
    this.setState({ isMaxExposureTimeCureByRange: event.target.checked });
    this.props.change('maxExposureTimeCure_hours', '');
    this.props.change('maxExposureTimeCure_minutes', '');
    this.props.change('maxExposureTimeCureStart', null);
    this.props.change('maxExposureTimeCureEnd', null);
  };

  onMaterialChanged = (material) => {
    let timeCure = material.data.maxExposureTimeCure;
    this.props.change(
      'maxExposureTimeCure_hours',
      timeCure || timeCure === 0 ? (timeCure / 60) | 0 : null
    );
    this.props.change(
      'maxExposureTimeCure_minutes',
      timeCure || timeCure === 0 ? timeCure % 60 | 0 : null
    );

    let timeBond = material.data.maxExposureTimeBond;
    this.props.change(
      'maxExposureTimeBond_hours',
      timeBond || timeBond === 0 ? (timeBond / 60) | 0 : null
    );
    this.props.change(
      'maxExposureTimeBond_minutes',
      timeBond || timeBond === 0 ? timeBond % 60 | 0 : null
    );
    this.props.change(
      'maxStorageTemp',
      material.data.maxStorageTemp || material.data.maxStorageTemp === 0
        ? UnitHelper.serverValueToUserValue(unitTypes.TEMPERATURE, material.data.maxStorageTemp, 0)
        : null
    );

    if (material.data.expiresWithinDays || material.data.expiresWithinDays === 0) {
      let msInDay = 86400000; //number of milliseconds in 24 hours
      let now = Date.now();
      let initialExpirationDate = new Date(
        now - (now % msInDay) + material.data.expiresWithinDays * msInDay
      );
      this.expirationDatePickerRef?.onChange(initialExpirationDate); //takes care of form change as well
    } else {
      this.expirationDatePickerRef?.onChange(null); //takes care of form change as well
    }
  };

  setExpirationDatePickerRef = (r) => {
    this.expirationDatePickerRef = r;
  };

  render() {
    let bodyClassName = _.isEmpty(this.props.sData.get('additionalFields'))
      ? ''
      : 'modal-body-overflow';

    return (
      <Dialog
        id="create-resin-dialog"
        bodyClassName={bodyClassName}
        className="create-resin-dialog"
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <InputSection
          label={this.labels.get('material') + '*'}
          htmlFor="material"
          className="inline left-side no-margin"
        >
          <Combobox
            id="material"
            name="material"
            options={this.props.sData.get('materials')}
            validate={Validation.required}
            autoFocus
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getComponentToRender}
            onChangeCallback={this.onMaterialChanged}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('manufacturer')}
          htmlFor="manufacturer"
          className="inline right-side no-margin"
        >
          <Combobox
            id="manufacturer"
            name="manufacturer"
            options={this.props.sData.get('manufacturers')}
            allowNewOption={true}
          />
        </InputSection>

        <InputSection label={this.labels.get('lot')} htmlFor="lot" className="inline left-side">
          <Combobox
            id="lot"
            name="lot"
            allowNewOption={true}
            options={this.props.sData.get('lots')}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('dateofmanufacturer')}
          htmlFor="dateOfManufacturer"
          className="inline right-side"
          labelExtraComponent={getDateFormatIcon()}
        >
          <DatePicker
            id="dateOfManufacturer"
            name="dateOfManufacturer"
            popperPlacement="auto"
            validate={Validation.date}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('resinId') + '*'}
          htmlFor="resinId"
          className="inline left-side"
        >
          <GenerateButton
            id="resinId"
            name="resinId"
            className="resinId"
            placeholder={this.labels.get('resinIddPlaceholder')}
            validate={Validation.required}
            onClick={this.generateResinId.bind(this, this)}
            buttonText={this.labels.get('generatebuttontext')}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('expirationdate')}
          htmlFor="expirationDate"
          className="inline right-side"
          labelExtraComponent={getDateFormatIcon()}
        >
          <DatePicker
            id="expirationDate"
            name="expirationDate"
            popperPlacement="auto"
            validate={Validation.date}
            setRef={this.setExpirationDatePickerRef}
          />
        </InputSection>

        {this.renderMeasurementsSection()}

        {this.renderMaxExposureTimeBond()}

        {this.renderWeightField()}

        <InputSection
          label={this.labels.get('maxExposureTimeCure')}
          htmlFor="maxExposureTimeCure"
          className="inline right-side"
          onCheckboxChangeCallback={
            this.isExposureByRange ? this.onMaxExposureCureCheckboxChangeCallback : null
          }
          checkBoxLabel={this.labels.get('maxExposureTimeCureTitle')}
        >
          <TimeField
            id="maxExposureTimeCure"
            name="maxExposureTimeCure"
            hoursCount={true}
            maxHoursLength={3}
            disabled={this.state.isMaxExposureTimeCureByRange}
          />
        </InputSection>

        {this.renderMaxExposureTimeCureRange()}

        <InputSection label={this.labels.get('tag')} htmlFor="tags" className="inline left-side">
          <SingleLineMultiSelect
            id="tags"
            name="tags"
            limit={10}
            options={this.props.sData.get('tags')}
            value={[]}
            onChange={(value) => {
              this.props.change('tags', value);
            }}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('maxstoragetemperature', undefined, {
            units: UnitHelper.getLabelForUnitType(unitTypes.TEMPERATURE),
          })}
          htmlFor="maxStorageTemp"
          className="inline right-side"
        >
          <TextField
            id="maxStorageTemp"
            name="maxStorageTemp"
            className="short-textfield"
            normalize={Normalize.number(
              true,
              UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0),
              UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0)
            )}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('project')}
          htmlFor="project"
          className="inline left-side"
        >
          <Combobox id="project" name="project" options={this.props.sData.get('projects')} />
        </InputSection>

        <InputSection
          label={this.labels.get('inspectionStatus')}
          htmlFor="inspectionStatus"
          className="inline right-side"
        >
          <Combobox
            id="inspectionStatus"
            name="inspectionStatus"
            options={this.props.sData.get('inspectionStatuses')}
          />
        </InputSection>

        <InputSection
          label={this.labels.get('batchNo')}
          htmlFor="batchNum"
          className="inline left-side"
        >
          <TextField
            id="batchNum"
            name="batchNum"
            className="short-textfield"
            maxLength={40}
          />
        </InputSection>
        <div>{this.renderAdditionalFields()}</div>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'createResin',
})(CreateResinDialog);
