import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import AfpPin from '../afpPin';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./afpCreel.scss');


export default class AfpCreel extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = this.props.labels;
    this.waitForScanTimeout = null;
    this.waitForScanPeriod = (PermissionManager.getOrgPreferences().timePeriodForRfidAssetScanningSeconds || 10) * 1000; //in ms

    this.state = {
      selectedTab: 'creel1',
      selectedPinIndex: null,
      waitingPinIndex: null,
      scannedAssets: props.scannedAssets || [],
    };

    this.updateRedux(this.state.scannedAssets);
  }

  componentWillUnmount() {
    this.stopWaiting();
  }

  onTabClick = (key) => {
    this.setState({selectedTab: key});
  };

  updateRedux = (assets) => {
    let reduxData = [];
    assets?.forEach((item, index) => {
      if (item) {
        reduxData.push({pinNumber: index, spoolBusinessId: item})
      }
    });
    this.props.change('scannedAssets', reduxData);
  }

  onClearHandler = (index) => {
    let assets = Object.assign([], this.state.scannedAssets, {[index]: ''})
    this.setState({scannedAssets: assets});
    this.updateRedux(assets);
  }

  onClickHandler = (index) => {
    if (null !== this.waitForScanTimeout) {
      clearTimeout(this.waitForScanTimeout);
    }
    this.waitForScanTimeout = setTimeout(this.stopWaiting, this.waitForScanPeriod);

    this.correlationId = new Date().getTime();

    this.fetchScannedAsset();

    this.setState({selectedPinIndex: index, waitingPinIndex: index});
  }

  fetchScannedAsset = () => {
    this.callbackId = new Date().getTime();
    let config = {
      callbackId: this.callbackId,
      correlationId: this.correlationId,
    };

    this.props.actions.fetchScannedAsset(config).then(fetchedData => {
      this.fetchScannedAssetHandler(fetchedData);
    })
    .catch((err) => {
      console.error('fetchScannedAsset() failed', err);
      this.stopWaiting();
    })

  }

  fetchScannedAssetHandler = (response) => {
    if (!response) {
      this.stopWaiting();
    }
    if (response?.callbackId !== this.callbackId) {
      return;
    }
    const scannedBusinessId = response?.data?.businessId;
    if (scannedBusinessId) {
      this.stopWaiting();

      if (this.validateScannedAsset(scannedBusinessId)) {
        this.handleScannedAsset(scannedBusinessId);
      }
    }
    else {
      if (this.waitForScanTimeout) {
        setTimeout(() => this.fetchScannedAsset(), 1000);
      }
    }
  };

  validateScannedAsset = (scannedBusinessId) => {
    let {scannedAssets, selectedPinIndex } = this.state;
    let selectedPinAsset = scannedAssets[selectedPinIndex];
    let scannedAssetPin = scannedAssets.findIndex((a) => {return a === scannedBusinessId} );

    if (scannedAssetPin > -1 && scannedAssetPin !== this.state.selectedPinIndex) { //scanned asset already applied on some other pin
      if (selectedPinAsset ) { //selected pin already has some other asset
        let confirmationConfig = {
          spoolBusinessId: scannedBusinessId,
          pinNumber: scannedAssetPin,
          submitHandler: this.handleScannedAssetSwap
        }

        this.props.actions.showConfirmation(confirmationConfig);
        return false;
      }
      else {

        let config = {
          spoolBusinessId: scannedBusinessId,
          pinNumber: scannedAssetPin,
        }
        this.props.actions.showError(config);
        return false;
      }
    }
    return true;
  }

  handleScannedAsset = (scannedBusinessId) => {
    let {scannedAssets, selectedPinIndex } = this.state;

    let assets = Object.assign([], scannedAssets, {[selectedPinIndex]: scannedBusinessId})
    this.setState({scannedAssets: assets});
    this.updateRedux(assets);
  }

  handleScannedAssetSwap = (scannedBusinessId) => {
    let {scannedAssets, selectedPinIndex } = this.state;
    let selectedPinAsset = scannedAssets[selectedPinIndex];
    let scannedAssetPin = scannedAssets.findIndex((a) => {return a === scannedBusinessId} );

    let assets = Object.assign([], scannedAssets, {[selectedPinIndex]: scannedBusinessId})
    assets[scannedAssetPin] = selectedPinAsset;
    this.setState({scannedAssets: assets});
    this.updateRedux(assets);
  }

  stopWaiting = () => {
    clearTimeout(this.waitForScanTimeout);
    this.waitForScanTimeout = null;
    this.setState({ waitingPinIndex: null });
  }

  renderCreel(startIndex, endIndex) {
    let pins = [];

    for (let index = startIndex; index <= endIndex; index++) {
      pins.push(
        <AfpPin pinNumber={index} key={index}
                onClearCallback={this.onClearHandler}
                onClickCallback={this.onClickHandler}
                selected={index === this.state.selectedPinIndex}
                // value={ index === this.state.waitingPinIndex ? 'Waiting...' : this.state.scannedAssets[index]}
                value={this.state.scannedAssets[index]}
                className={cn({'waiting': index === this.state.waitingPinIndex})}
        />
      )
    }
    return (
      <div className='afp-creel' key={this.state.waitingPinIndex ?? -1}>
        {pins}
      </div>
    )
  }

  render = () => {
    const {labels} = this.props;
    let selectedTab = this.state.selectedTab;

    return (
      <div className="afp-creel">

        <Tabs activeKey={selectedTab} tabType="default" onSelect={this.onTabClick} id="content-tabs">
          <Tab eventKey='creel1' animation={false} title={labels.get('selectComponentAssetsStep.creel') + ' 1'}>
            {this.renderCreel(1,24)}
          </Tab>
          <Tab eventKey='creel2' animation={false} title={labels.get('selectComponentAssetsStep.creel') + ' 2'}>
            {this.renderCreel(25,48)}
          </Tab>
        </Tabs>
      </div>
    );

  };
}

AfpCreel.defaultProps = {
  scannedAssets: [],
};

AfpCreel.propTypes = {
  scannedAssets: PropTypes.array,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};



