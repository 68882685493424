import { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import Button from 'infrastructure/js/components/controls/Button/button';

import './withEditor.scss';

const withEditor = (WrappedComponent) => {
  const WithEditor = ({
    typeSuffix='input',
    id,
    name,
    value,
    onChange=()=>false,
    onSubmit=()=>false,
    parse=(value, name)=>({ [name]: value }),
    onFocusOut=()=>false,
    disabled=false,
    editable=true,
    className='',
    ...props
  }) => {
    const [isActive, setIsActive] = useState(false);
    const componentValue = useRef(value);
    const lastSavedValue = useRef(value);

    const isDisabled = disabled || !editable;

    const handleComponentChange = (newValue) => {
      if (!isActive) {
        activateEditor();
      }
      componentValue.current = newValue;
      onChange(newValue);
    };

    const submit = () => {
      if (!isEqual(lastSavedValue.current, componentValue.current)) {
        const dataToSend = parse(componentValue.current, name);
        onSubmit(dataToSend);
        lastSavedValue.current = componentValue.current;
      }
      deactivateEditor();
    };

    const handleFocusOut = () => {
      submit();
      onFocusOut();
    };

    const activateEditor = () => {
      if (!isDisabled) {
        setIsActive(true);
      }
    };

    const deactivateEditor = () => {
      setIsActive(false);
    };

    return (
      <div
        className={classNames('editor', { active: isActive, disabled: isDisabled }, className)}
        onClick={activateEditor}
      >
        <WrappedComponent
          id={`${id}-${typeSuffix}`}
          name={name}
          value={value}
          onChange={handleComponentChange}
          onFocusOut={handleFocusOut}
          isEditorActive={isActive}
          disabled={isDisabled}
          {...props}
        />
        {isActive ? (
          <div className="buttons-container">
            <Button className="btn-save pl pl-check-icon-blue-hover" id="save" onClick={submit} />
          </div>
        ) : null}
      </div>
    );
  };

  WithEditor.propTypes = {
    typeSuffix: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    parse: PropTypes.func,
    onFocusOut: PropTypes.func,
    disabled: PropTypes.bool,
    editable: PropTypes.bool,
    className: PropTypes.string,
  };

  return WithEditor;
};

export default withEditor;
