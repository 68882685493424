import Network from "infrastructure/js/modules/network";
import * as dialogActionsHelper from "../../Dialogs/dialogActionsHelper.js";
import { api as messageDialogApi, api as messageDialogAPI } from "../../MessageDialog/messageDialogActions.js";
import * as barcodeService from "../../../services/Administration/barcodeImportService";
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {api as locationPageApi} from "../../LocationPage/locationPageActions.js";

const prefix = 'IMPORT_ASSETS_VIA_BARCODE';

export let actionTypes = {
  ...dialogActionsHelper.getActionTypes(prefix),
  IMPORT_ASSETS_VIA_BARCODE_SET_GRID_SETTINGS:             'IMPORT_ASSETS_VIA_BARCODE_SET_GRID_SETTINGS',
  IMPORT_ASSETS_VIA_BARCODE_SET_LOADING:                   'IMPORT_ASSETS_VIA_BARCODE_SET_LOADING',
  IMPORT_ASSETS_VIA_BARCODE_ASSET_SCANNED:                 'IMPORT_ASSETS_VIA_BARCODE_ASSET_SCANNED',
  IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FINISHED:   'IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FINISHED',
  IMPORT_ASSETS_VIA_BARCODE_REMOVE_ASSET:                  'IMPORT_ASSETS_VIA_BARCODE_REMOVE_ASSET',
  IMPORT_ASSETS_VIA_BARCODE_REMOVE_ALL_ERRORED_ASSETS:     'IMPORT_ASSETS_VIA_BARCODE_REMOVE_ALL_ERRORED_ASSETS',
  IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FAILED:     'IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FAILED',
  IMPORT_ASSETS_VIA_BARCODE_SET_VALIDATION_WARNINGS_DATA:  'IMPORT_ASSETS_VIA_BARCODE_SET_VALIDATION_WARNINGS_DATA'
};

let actions = {
  show(payload) {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_SHOW, payload: payload };
  },
  hide() {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_HIDE };
  },
  setGridSettings(gridSettings, delimiter) { // gridSettings: [{ label: string, propertyName: string, columnIndex: number }]
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_SET_GRID_SETTINGS, payload: { gridSettings, delimiter } };
  },
  setLoading(loading) {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_SET_LOADING, payload: loading };
  },
  assetScanned(asset) {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_ASSET_SCANNED, payload: asset };
  },
  submitScanAssetsStepFinished(payload) {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FINISHED, payload };
  },
  removeAsset(assetIndex) {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_REMOVE_ASSET, payload: assetIndex };
  },
  removeAllErroredAssets() {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_REMOVE_ALL_ERRORED_ASSETS };
  },
  submitScanAssetsStepFailed() {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_SUBMIT_SCAN_ASSETS_FAILED };
  },
  setValidationWarningsData(payload) {
    return { type: actionTypes.IMPORT_ASSETS_VIA_BARCODE_SET_VALIDATION_WARNINGS_DATA, payload };
  }
};

export let api = {
  show: (dispatch, getState) => (data) => {
    dispatch(actions.show(data));
    dispatch(actions.setLoading(true));
    barcodeService.fetchAssetsGridSettings(data.importType).then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('fetch assets grid settings failed', response);
        dispatch(actions.setLoading(false));
        return { success: false };
      }
      let labels = createLabelHelper("");
      dispatch(actions.setGridSettings(
        response.data.settings.map((setting) => {
          return {
            label: setting.isAdditionalField ? setting.property.propertyName : labels.get(setting.property.labelKey),
            propertyName: setting.property.propertyName,
            columnIndex: setting.columnIndex
          };
        }),
        response.data.delimiter
      ));
      return { success: true };
    });
  },

  hide: (dispatch, getState) => () => {
    dispatch(actions.hide());
  },

  scanBarcode: (dispatch, getState) => (data, assetType) => {
    dispatch(actions.setLoading(true));
    return barcodeService.parseAssetBarcode(data, assetType).then((response) => {
      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('parsing asset barcode failed', response);
        dispatch(actions.setLoading(false));
        return { success: false };
      }
      let { rollData, spoolData, resinData } = response.data;
      let assetData = rollData || spoolData || resinData;
      assetData.scanData = data;
      dispatch(actions.assetScanned(assetData));
      return { success: true };
    });
  },

  submitScanAssetsStep: (dispatch, getState) => (nextStep, assetType) => {
    let removeScanData = (item) => {
      let { scanData, ...restOfItem } = item;
      return restOfItem;
    };
    let data = _getStateData(getState, 'assets').map(removeScanData);
    dispatch(actions.setLoading(true));
    return barcodeService.validateScannedAssets(data, assetType).then((response) => {

      dispatch(actions.setLoading(false));

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('Scanned assets validation failed', response);
        dispatch(actions.submitScanAssetsStepFailed());   //?????????????????
        return { success: false };
      }

      let payload = {
        validatedFileData: response.data,
        validAssets: response.data.validDataItems
      }

      dispatch(actions.submitScanAssetsStepFinished(payload));
      if (nextStep) {
        nextStep();
      }
      return { success: true };
    });
  },

  removeAsset: (dispatch, getState) => (assetIndex) => {
    dispatch(actions.removeAsset(assetIndex));
  },
  removeAllErroredAssets: (dispatch, getState) => () => {
    dispatch(actions.removeAllErroredAssets());
  },

  confirmImport: (dispatch, getState) => (assetType, messageDialogBuilder, reloadParentComponent) => {
    dispatch(actions.setLoading(true));

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let data = _getStateData(getState, 'validAssets');

    return barcodeService.importValidAssets(locationId, data, assetType).then((response) => {

      dispatch(actions.setLoading(false));
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Import assets failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      if (messageDialogBuilder) {
        let messageDialogDescriptor = messageDialogBuilder(
          response.data,
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }
    });
  }
};


export let jsxActions = {
  show: (data) => (dispatch, getState) => {
    api.show(dispatch, getState)(data);
  },
  hide: () => (dispatch, getState) => {
    api.hide(dispatch, getState)();
  },
  scanBarcode: (data, assetType) => (dispatch, getState) => {
    api.scanBarcode(dispatch, getState)(data, assetType);
  },
  submitScanAssetsStep: (nextStep, assetType) => (dispatch, getState) => {
    api.submitScanAssetsStep(dispatch, getState)(nextStep, assetType);
  },
  removeAsset: (assetIndex) => (dispatch, getState) => {
    api.removeAsset(dispatch, getState)(assetIndex);
  },
  removeAllErroredAssets: () => (dispatch, getState) => {
    api.removeAllErroredAssets(dispatch, getState)();
  },
  confirmImport: (assetType, failureMessage, closeButtonText) => (dispatch, getState) => {
    api.confirmImport(dispatch, getState)(assetType, failureMessage, closeButtonText);
  }
};

//////////////////////////////////////////////////////////
//private helpers
function _getStateData(getState, data) {
  return getState().wizards.getIn(['importAssetsViaBarcodeWizard', data]);
}
