import { Map } from 'immutable';
import { default as promotionPageReducer } from './promotionsPageReducer';
import { default as createPromotionDialogReducer } from './Dialogs/createPromotionDialogReducer';


let defaultState = {};

export default function(state = Map(defaultState), action) {

  state = state.set('promotionsPage', promotionPageReducer(state.get('promotionsPage'), action));
  state = state.set('createPromotionDialog', createPromotionDialogReducer(state.get('createPromotionDialog'), action));

  return state;
}



