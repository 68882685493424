import {useMemo} from 'react';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import WorkOrderManagementGrid from '../../../Common/Grids/CommonWorkOrdersGrid/commonWorkOrdersGrid';
import {gridsNames} from '../../../../enums/gridsNames';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { jsxActions as workOrdersManagementGridActions } from '../../../../actions/Administration/PsManagementPage/workOrdersManagementGridActions';
import { jsxActions as workOrdersManagementViewActions } from '../../../../actions/Administration/PsManagementPage/workOrdersManagementViewActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions';
import {List} from 'immutable';
import {getDefaultWorkOrderType} from '../../../../utils/workOrderHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

import'./workOrdersManagementView.scss';

const getState = (state) => {
  let grid = state.grid?.get(gridsNames.ADMINISTRATION_PS_MANAGEMENT_WORK_ORDERS) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
  };
};

const allActions = {
  workOrdersManagementViewActions,
  workOrdersManagementGridActions,
  fetchEntitiesActions,
};


export default function WorkOrdersManagementView() {
  const labels = useMemo(() => createLabelHelper('mat.administration.psmanagement.workOrdersManagement.view.'), []);
  const { state, actions } = useRedux(allActions, getState);
  const {sHasRows = false, sSelectedRowsData } = state;

  const getHeaderItems = () => {
    return {
      title: labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.workOrdersManagementViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.workOrdersManagementViewActions.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id: 'removeAll',
          label: headerItemsLabels.REMOVE_ALL,
          icon: 'pl pl-delete-icon',
          disabled: !sHasRows,
          action: actions.workOrdersManagementViewActions.removeAllWorkOrders,
        }
      ],
      kebab: {
        menuItems: [
          {
            id: 'remove',
            label: headerItemsLabels.REMOVE,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            action: actions.workOrdersManagementViewActions.removeWorkOrders,
            actionData: sSelectedRowsData,
          },
        ]
      },
    };
  }

  return (
    <div className='work-orders-management-view'>
      <Header {...getHeaderItems()}/>
      <WorkOrderManagementGrid gridName={gridsNames.ADMINISTRATION_PS_MANAGEMENT_WORK_ORDERS}
                         actions={{...actions.workOrdersManagementGridActions, ...actions.fetchEntitiesActions}}
                         showStatusColumn={true}
                         defaultWorkOrderType={getDefaultWorkOrderType(PermissionManager.getOrgPreferences())}
      />
    </div>
  )
}

WorkOrdersManagementView.propTypes = {
};




