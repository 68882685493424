import { useEffect } from 'react';
import { getEnumValue, enumTypes } from '../../../../../utils/enumHelper';
import { SettingsMenuItemsIds } from '../../../../../enums/navigationMenuItemsIds';
import CommonAlertsView from '../Components/CommonAlertsView/commonAlertsView';

import './misplacedMaterialView.scss';

export default function MisplacedMaterialView({ actions, sData, sMisplacedMaterialAlertRuleDialogData, sMisplacedMaterialAlertRulesGridData, isDirty }) {

  useEffect(() => {
    actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_MISPLACED_MATERIAL);

    actions.load(getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'));
  }, []);

  return (
    <div className="misplaced-material-view">
      <CommonAlertsView
        actions={actions}
        sData={sData}
        sMisplacedMaterialAlertRulesGridData={sMisplacedMaterialAlertRulesGridData}
        sMisplacedMaterialAlertRuleDialogData={sMisplacedMaterialAlertRuleDialogData}
        isDirty={isDirty}
        alertType={getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL')}
      />
    </div>
  );
}
