import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Async from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

import {Field} from 'redux-form';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js'
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import {components} from 'react-select';

require('./combobox.scss');

//this component is the controlled version of the combobox. it doesn't handle changes internally
//to use, you must provide the current value/s and register changes through the onChangeCallback
export default class PL_ControlledComboBoxForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_ControlledComboBox} />
    );
  }
};

export class PL_ControlledComboBox extends React.PureComponent {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.controls.combobox.');
  }

  getClassName() {
    if (this.props.className) {
      return ' ' + this.props.className;
    }
    return '';
  }
  onChange = (data) =>{
    let oldValue = this.props.input.value;
    //Note: SingleValue combobox returns [] for empty value. Replace [] with null
    if (data && Array.isArray(data) && data.length === 0 && !this.props.isMulti)  {
      data = null;
    }
    this.props.input.onChange(data);
    if(this.props.onChangeCallback){
      //Create new instance, changes will not effect the source state.
      this.props.onChangeCallback(JSON.parse(JSON.stringify(data)) , oldValue);
    }
  };

  errorClass(){
    let {touched, error} = this.props.meta;
    return (touched && error ? " invalid" : "");
  }

  indicatorSeparator = (props) => {
    return null;
  };

  //NOTE: Temp workaround for the react-select bug in Creatable.js
  //Must be removed when the react-select is fixed

  isValidNewOptionTemp = (inputValue, selectValue, options) => {
    if (!inputValue) {
      return false;
    }
    let returnValue = true;
    options.forEach((option) => {
      if (inputValue.toLowerCase() === option.label.toLowerCase()) {
        returnValue = false;
      }
    });
    return returnValue;

  };

  isOptionDisabled = (option)=>{
    return !!option.isDisabled;
  };

  optionRenderer = (props) => {
    return (
      <components.Option {...props} >
        {this.props.optionRenderer(props)}
      </components.Option>
    );
  };

  singleValueRenderer = (props) => {
    return (
      <components.SingleValue {...props} >
        {this.props.singleValueRenderer(props)}
      </components.SingleValue>
    );
  };

  multiValueRenderer = (props) => {
    return (
      <components.MultiValueLabel {...props} >
        {this.props.multiValueRenderer(props)}
      </components.MultiValueLabel>
    );
  };

  multiValueRemoveRenderer = (props) => {
    return null;
  };

  createComboboxComponents = () => {
    let components = {IndicatorSeparator: this.indicatorSeparator};

    if (this.props.optionRenderer) {
      components.Option = this.optionRenderer;
    }

    if (this.props.singleValueRenderer) {
      components.SingleValue = this.singleValueRenderer;
    }
    if (this.props.multiValueRenderer) {
      components.MultiValueLabel = this.multiValueRenderer;
    }

    if (this.props.dropdownIndicatorRenderer) {
      components.DropdownIndicator = this.props.dropdownIndicatorRenderer;
    }

    if (!this.props.isMultiValueClearable) {
      components.MultiValueRemove = this.multiValueRemoveRenderer;
    }

    return components;
  };


  renderInnerComponent(){
    let {className, options, input, meta, allowNewOption, isClearable, ...props} = this.props;

    let InnerComponent = allowNewOption ? Creatable : Select;

    if (this.props.asyncMode) {
      InnerComponent = allowNewOption ? AsyncCreatableSelect : Async;
    }

    let components = this.createComboboxComponents();

    return (
      <InnerComponent
        classNamePrefix="pl-select"
        isClearable={isClearable}
        //backspaceRemovesValue={isClearable}
        components={components}
        placeholder={this.props.placeholder || this.labels.get('placeholder')}
        options={options}
        isOptionDisabled = {this.isOptionDisabled}
        getOptionValue={(option) => (option['label'])}
        isValidNewOption={this.isValidNewOptionTemp}
        {...input}
        {...props}
        value={this.props.selectedValue}
        onChange={this.onChange}
        onBlur={()=>input.onBlur(input.value || '')}
        formatCreateLabel= {(inputValue) => { return `${this.labels.get('create')} "${inputValue}"`}}
        noOptionsMessage= {() => {return this.labels.get('noOptions')}}
        disabled={this.props.disabled}
      />
    );
  }

  render() {

    return (
      <span id={this.props.id} className={"pl-combobox" + this.errorClass()+ this.getClassName()}>
        {this.renderInnerComponent()}
        <ValidationComponent {...this.props.meta} />
      </span>
    )
  }
}

PL_ControlledComboBox.defaultProps = {
  allowNewOption: false,
  isMultiValueClearable : true,
  isClearable : false,
};
PL_ControlledComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.array, //either options or loadOptions is required
  loadOptions: PropTypes.func, //(input, callback: (options: []) => void) => void;
  onChangeCallback : PropTypes.func, //(data: { } | [{ }], oldData: { } | [{ }] ) => void;
  allowNewOption: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMultiValueClearable: PropTypes.bool,
  optionRenderer: PropTypes.func,
  singleValueRenderer: PropTypes.func,
  multiValueRenderer: PropTypes.func,
  dropdownIndicatorRenderer: PropTypes.func,
};

PL_ControlledComboBoxForm.propTypes = PL_ControlledComboBox.propTypes;

