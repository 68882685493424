let normalize = {};

normalize.string = (allowSpecialchars) => {
  return (value, previousValue) => {
    if (value === '' || value === ' ') {
      return value.trim();
    }

    if (value === '-') {
      return previousValue;
    }

    const onlyLettersPattern = /^[A-Za-z]+$/;
    const specialCharsPattern = /^[A-Za-z -]*$/;

    let pattern = allowSpecialchars ? specialCharsPattern :  onlyLettersPattern;

    return !pattern.test(value) ? previousValue : value;

  }
}

normalize.number = (isInteger, min, max, preventEmptyValue) => {
  return (value, previousValue, allValues, previousAllValues) => {

    //When the min value can't be less than 0,
    // we check for negative numbers.
    if (value === '-' &&( min !== null) && (min >= 0)) {
      return previousValue;
    }

    // if empty value not allowed return 0
    if(preventEmptyValue && value === ''){
      return 0
    }

    //Allow - or empty value.
    if (value === '-' || value === '') {
      return value;
    }

    //Check if value is an illegal number.
    if (isNaN(value)) {
      return previousValue;
    }

    if (isInteger && (+value % 1 !== 0)) {
      return previousValue;
    }

    if ((min || min === 0) && (+value < min)) {
      return previousValue;
    }

    if ((max || max === 0) && (+value > max)) {
      return previousValue;
    }

    // Remove leading zeros and dots from integers.
    if (isInteger && value !== '') {
      return +value;
    }

    if (!isInteger) {
      //only one leading zero is allowed before the decimal point - '0.xx'( not '00.xx' or '034.xx')
      if ( (value.split('.')[0] || []).length > 1 && value.split('.')[0][0] === '0') {
        return previousValue;
      }
      //fixed to 2 digits after the decimal point
      if ( (value.split('.')[1] || []).length > 2) {
        return previousValue;
      }
    }

    return value;
  }
};

normalize.stringAndNumber = (allowSpecialchars) => {
  return (value, previousValue) => {
    if (value === '' || value === ' ') {
      return value.trim();
    }

    if (value === '-') {
      return previousValue;
    }

    const onlyLettersPattern = /^[A-Za-z0-9]+$/;
    const specialCharsPattern = /^[A-Za-z0-9 -]*$/;

    let pattern = allowSpecialchars ? specialCharsPattern :  onlyLettersPattern;

    return !pattern.test(value) ? previousValue : value;

  }
}

normalize.numberAndDot = () => {
  return (value, previousValue) => {

    let pattern = /^[0-9.,]+$/;

    if (pattern.test(value)) {
      return value;
    }

    return '';
  }
};

normalize.normalizeFloat = ( min, max, fixedTo=2) => {
  return (value, previousValue, allValues, previousAllValues) => {

    if (value === '') {
      return value;
    }

    if (isNaN(value)) {
      return previousValue;
    }

    if ( (value.split('.')[1] || []).length > fixedTo) {
      return previousValue;
    }

    if ((max || max === 0) && (+value > max)) {
      return previousValue;
    }

    if ((min || min === 0) && (+value < min)) {
      return previousValue;
    }

    if ((value.slice(-1) === '.' || value.slice(-2) === '.0' || value === '0.0') ) {
      return value;
    }
    return +value;
  }
}

export default normalize;






