import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import TimeField from "infrastructure/js/components/controls/TimeField/timeField";
import TextField from "infrastructure/js/components/controls/TextField/textField";
import moment from "moment";
import {enumTypes, getLookupOptions} from "../../../../../../utils/enumHelper";

require('./createTaskDialog.scss');

class CreateTaskDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.scheduler.operations.dialog.createTask.');
    this.minDate = moment();
    this.itemToEdit = this.props.sData.get('itemToEdit');
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  getResources() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.resources ? dialogData.resources : [];
  }

  onSubmit = (data) => {
    if (data) {
      let newData = {
        name: data.taskName,
        locationId:data.resource.value,
        duration: DateTimeHelper.ConvertHoursMinutesToMinutes(data.duration_hours, data.duration_minutes),
        dueDate: DateTimeHelper.ConvertFromDate(data.dueDate),
        priority: data.priority ? data.priority.value: null
      };
      this.props.actions.submit(newData);
    }
  };

  validateDuration = (value, allValues) => {
    return Validation.timeRequired('duration')(value, allValues);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  render() {
    return (
      <Dialog
        id="create-task-dialog"
        className="create-task-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get('taskName')+'*'} htmlFor="taskName" className="inline left-side">
          <TextField id="taskName" name="taskName" className="short-textfield" maxLength={30} validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get('resource') + '*'} htmlFor="resource" className="inline right-side">
          <Combobox id="resource" name="resource" options={this.getResources()} validate={Validation.required}/>
        </InputSection>

        <InputSection label={this.labels.get('dueDate') + '*'} htmlFor="dueDate" className="inline left-side">
          <DatePicker id="dueDate" name="dueDate" validate={[Validation.date, Validation.required]} minDate={this.minDate}/>
        </InputSection>

        <InputSection label={this.labels.get('duration') + '*'} htmlFor="duration" className="inline right-side">
          <TimeField id="duration" name="duration" hoursCount={true} maxHoursLength={3} validate={this.validateDuration} />
        </InputSection>

        <InputSection label={this.labels.get('priority')} htmlFor="priority" className="inline left-side">
          <Combobox id="priority" name="priority" options={getLookupOptions(enumTypes.PRIORITY_TYPE)}/>
        </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'createTaskDialog',
  }
)(CreateTaskDialog);

CreateTaskDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};


