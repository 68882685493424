import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Label from 'infrastructure/js/components/Label/label';
import Button from 'infrastructure/js/components/controls/Button/button';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { enumTypes, getEnumValue } from '../../../../utils/enumHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import HumanCapacityChip from './humanCapacityChip';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import './taskDetailRow.scss';

const ComboboxPortal = withPortal(Combobox);

const TaskDetailRow = forwardRef(
  ({ handleToolSelect, setSelectedWorkOrder, change, onOperationBtnClick, data, rowIndex, updateRowData, api, node }, ref) => {
    const [labels] = useState(() => createLabelHelper('mat.locationpage.view.tasks.grid.moreInfo.'));
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [operationStatus, setOperationStatus] = useState(data?.operationStatus);
    const [isSynced, setIsSynced] = useState(data?.isSync);
    const [selectedTool, setSelectedTool] = useState(null);

    useEffect(() => {
      if (!(data?.toolType?.id >= 0)) {
        setLoading(false);
      }

      let initialTool =
        operationStatus === getEnumValue(enumTypes.TASK_STATUS)('SCHEDULED')
          ? data?.plannedTool
          : operationStatus === getEnumValue(enumTypes.TASK_STATUS)('STARTED') || operationStatus === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')
          ? data?.actualTool
          : null;

      if (initialTool) {
        const defaultTool = {
          value: initialTool?.id,
          label: initialTool?.businessId,
          data: initialTool,
        };

        setOptions([...options, defaultTool]);
        setSelectedTool(initialTool);
        change(`${data?.id}_toolId`, defaultTool);
      }
      return () => {
        change(`${data?.id}_toolId`, undefined);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => {
      return {
        refresh(params) {
          if (params?.data?.tools) {
            setOptions(params?.data?.tools ?? []);
          }

          if (params.data?.operationStatus !== operationStatus) {
            setOperationStatus(params?.data?.operationStatus);
          }

          if (params.data?.isSync !== isSynced) {
            setIsSynced(params?.data?.isSync);
          }

          setLoading(false);
          return true;
        },
      };
    });

    const singleValueRenderer = ({ data }) => {
      let hasWarning = data?.data?.alertStatus === 'WARNING';
      return (
        <>
          {hasWarning && (
            <AlertPopover defaultOverlayShown={true} popoverType={'warning'} popoverComponent={<div>{labels.get('toolId.warning.tooltip')}</div>} />
          )}
          <Label text={data?.label || ''} />
        </>
      );
    };
    const optionRenderer = ({ data }) => {
      let hasWarning = data?.data?.alertStatus === 'WARNING';
      return (
        <div className="task-detail-tool-option-renderer">
          <span className={hasWarning ? 'pl pl-warning-icon warning ' : ''} />
          <Label text={data?.label || ''} />
        </div>
      );
    };

    const renderHumanCapacityAssignments = () => {
      if (PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled) {
        const assignments = data?.extendedDetails?.splitAssignments;

        return (
          <div className="task-detail-cell">
            <label>{labels.get('shifts')}</label>

            {assignments?.map((segmentData, i) => {
              const { plannedStartDateSegment, plannedEndDateSegment, humanCapacity = '', shiftHumanCapacity = '' } = segmentData;
              const startTime = DateTimeHelper.ConvertToDate(plannedStartDateSegment);
              const endTime = DateTimeHelper.ConvertToDate(plannedEndDateSegment);
              const timeRangeStr = `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;

              return (
                <div key={i} className="shift-assignment">
                  <span>{timeRangeStr}</span>
                  <HumanCapacityChip {...segmentData} />
                </div>
              );
            })}
          </div>
        );
      }
    };

    const onToolSelect = (value, oldValue) => {
      const newRowData = {
        ...data,
        selectedToolId: value.value,
      };

      setSelectedTool(value.data);
      setTimeout(() => updateRowData(newRowData), 0);
    };

    const isToolSelectDisabled =
      operationStatus === getEnumValue(enumTypes.TASK_STATUS)('STARTED') || operationStatus === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED');

    const isOperationDisabled =
      (operationStatus !== getEnumValue(enumTypes.TASK_STATUS)('STARTED') && operationStatus !== getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')) ||
      isSynced;

    const handleOperationBtnClick = () => {
      const operationData = {
        id: data?.operation?.id,
        operationType: {
          name: data?.operationType?.name,
        },
        displayName: data?.operation?.name,
        instructions: '',
        workOrder: data?.workOrder,
        preselectedTool: selectedTool,
        kitTypeId: data?.kitType?.id,
      };

      onOperationBtnClick(operationData);
    };

    return (
      <div className="task-detail-row">
        <div className="left-spacer"></div>
        <div className="task-detail-content">
          <div className="task-detail-cell operation">
            <label>{labels.get('operation')}</label>
            <Button id={'operation'} className="border-btn" disabled={isOperationDisabled} onClick={handleOperationBtnClick}>
              {data?.operation?.name}
            </Button>
          </div>
          <div className="task-detail-cell tool-cell">
            <label>{labels.get('tool')}</label>
            <ComboboxPortal
              id={`${data?.id}_toolId`}
              name={`${data?.id}_toolId`}
              options={options}
              isLoading={loading}
              singleValueRenderer={singleValueRenderer}
              optionRenderer={optionRenderer}
              onChangeCallback={onToolSelect}
              menuPortalTarget={document.body}
              styles={{
                menu: (base) => ({ ...base, marginTop: '0px' }),
              }}
              isDisabled={isToolSelectDisabled}
            />
          </div>
          <div className="task-detail-cell">
            <label>{labels.get('operationType')}</label>
            <div className="read-only-field">
              <Label text={data?.operation?.name} />
            </div>
          </div>
          <div className="task-detail-cell duration-cell">
            <label>{labels.get('duration')}</label>
            <div className="read-only-field">
              <Label text={data?.extendedDetails?.duration} />
            </div>
          </div>
          <div className="task-detail-cell">
            <label>{labels.get('kitTypeDescription')}</label>
            <div className="read-only-field">
              <Label text={data?.extendedDetails?.kitTypeDescription} />
            </div>
          </div>
          {renderHumanCapacityAssignments()}
        </div>
      </div>
    );
  }
);

TaskDetailRow.displayName = 'TaskDetailRow';

export default TaskDetailRow;
