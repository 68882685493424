// @ts-check

import { MapView } from "@mappedin/mappedin-js";

import { livemapIcons } from '../../../../../../../mat/assets/svg/index';
import React from "react";


import './searchFilters.scss';
import LabelManager from "../../../Utils/LabelManager";


/**
 * @typedef {import("mat/js/actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("mat/js/actions/LiveMap/types/liveMapPageTypes").LiveMapPageActions} LiveMapPageActions
 * 
 * @typedef {{
 *    mapView:  MapView | undefined, 
 *    sData:    PL_LiveMapPageState, 
 *    actions:  LiveMapPageActions
 * }} SearchFiltersProps

 * 
 */




/**
 * @param {SearchFiltersProps} props
 * 
 */
const SearchFilters = (props) => {
  
  let sData = props.sData;
  let searchFilter = sData.search.searchFilter;
  
  
  
  let filter_ALL_clicked = () => {
    // console.log('filter_ALL_clicked');
    props.actions.filterClicked("ALL");
  }
  
  let filter_TOOLS_clicked = () => {
    // console.log('filter_TOOLS_clicked');
    props.actions.filterClicked("TOOLS");
  }
  
  let filter_MATERIALS_clicked = () => {
    // console.log('filter_MATERIALS_clicked');
    props.actions.filterClicked("MATERIALS");
  }
  
  let filter_LOCATIONS_clicked = () => {
    // console.log('filter_LOCATIONS_clicked');
    props.actions.filterClicked("LOCATIONS");
  }
  
  let filter_WO_clicked = () => {
    // console.log('filter_WO_clicked');
    props.actions.filterClicked("WO");
  }
  
  
  // console.log('props.sData SFFFFFFF ===> ', props.sData);
  
  // @ts-ignore
  let isActiveClass_ALL = (searchFilter === "ALL") ? 'active' : '';

  // @ts-ignore
  let isActiveClass_TOOLS = (searchFilter === "TOOLS") ? 'active' : '';

  // @ts-ignore
  let isActiveClass_MATERIALS = (searchFilter === "MATERIALS") ? 'active' : '';

  // @ts-ignore
  let isActiveClass_LOCATIONS = (searchFilter === "LOCATIONS") ? 'active' : '';

  // @ts-ignore
  let isActiveClass_WO = (searchFilter === "WO") ? 'active' : '';
  
  
  return (
    <>
    
      <div className='search-filters'>

        <div className={`search-filter ${isActiveClass_ALL}`} onClick={filter_ALL_clicked}>
          <div className='search-filter-icon '>
            {livemapIcons.liveMapFilter_All()}
          </div>
          <div className='search-filter-text unselectable'>{LabelManager.getByKey('mat.livesearch.filter.ALL')}</div>
        </div>

        <div className={`search-filter ${isActiveClass_TOOLS}`} onClick={filter_TOOLS_clicked}>
          <div className='search-filter-icon'>
            {livemapIcons.liveMapFilter_Tools()}
          </div>
          <div className='search-filter-text unselectable'>{LabelManager.getByKey('mat.livesearch.filter.TOOLS')}</div>
        </div>

        <div  className={`search-filter ${isActiveClass_MATERIALS}`} onClick={filter_MATERIALS_clicked}>
          <div className='search-filter-icon'>
            {livemapIcons.liveMapFilter_Materials()}
          </div>
          <div className='search-filter-text unselectable'>{LabelManager.getByKey('mat.livesearch.filter.MATERIALS')}</div>
        </div>

        <div  className={`search-filter ${isActiveClass_LOCATIONS}`} onClick={filter_LOCATIONS_clicked}>
          <div className='search-filter-icon'>
            {livemapIcons.liveMapFilter_Locations()}
          </div>
          <div className='search-filter-text unselectable'>{LabelManager.getByKey('mat.livesearch.filter.LOCATIONS')}</div>
        </div>

        {/* <div  className={`search-filter ${isActiveClass_WO}`} onClick={filter_WO_clicked}>
          <div className='search-filter-icon'>
            {livemapIcons.liveMapFilter_WO()}
          </div>
          <div className='search-filter-text unselectable'>{LabelManager.getByKey('mat.livesearch.filter.WO')}</div>
        </div> */}

      </div>

    </>
  );

};

export default SearchFilters;







