import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import useLabels from 'infrastructure/js/hooks/useLabels';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import './createSandboxDialog.scss';

const maxLength20 = Validation.maxLength(20);

function CreateSandboxDialog({
                               sData,
                               show,
                               actions,
                               handleSubmit,
                               reloadParentComponent=null,
                             }) {

  const dialogLabels = useLabels('mat.dialog.');
  const labels = useLabels('mat.administration.dialog.createSandbox.');
  const { submit, hide, stop } = actions;
  const itemToClone = sData.get('itemToEdit');
  const isCloneMode = !!itemToClone?.id;


  const onSubmit = ({sandboxName}) => {
    const submitData = {
      orgBusinessId: sandboxName,
    }

    if (itemToClone) {
      submitData.parentOrgId = itemToClone.id;
    }

    submit(submitData, null, reloadParentComponent, isCloneMode);
  };

  const dialogButtons = {
    left: [
      {
        id: 'cancel',
        text: sData?.get('loading') ? dialogLabels.get('stop') : dialogLabels.get('cancel'),
        action: sData?.get('loading') ? stop : hide,
        enabledOnLoading: true,
      },
    ],
    right: [
      {
        id: 'submit',
        text: isCloneMode ? dialogLabels.get('clone') : dialogLabels.get('create'),
        bsStyle: 'primary',
        loading: sData.get('loading'),
        action: handleSubmit(onSubmit),
        disabled: sData.get('loading'),
      },
    ],
  };

  const getTitle = () => {
    const orgName = isCloneMode ? itemToClone.name : (PermissionManager.getLoggedInUser()?.loggedInOrg?.name || '');
    return labels.get('header.title', '', {orgName});
  }

  return (
    <Dialog
      id="create-sandbox-dialog"
      className="create-sandbox-dialog"
      titleText={getTitle()}
      show={show}
      onHide={hide}
      sData={sData}
      footerButtons={dialogButtons}
    >
      <InputSection label={labels.get('sandboxName') + '*'} htmlFor="sandboxName" className="inline left-side no-margin">
        <TextField
          id="sandboxName"
          name="sandboxName"
          className="short-textfield"
          validate={[Validation.required, maxLength20, Validation.orgNameFormat]}
        />
      </InputSection>
    </Dialog>
  );
}

export default reduxForm({
  form: 'createSandboxDialog',
})(CreateSandboxDialog);

CreateSandboxDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reloadParentComponent: PropTypes.any,
};
