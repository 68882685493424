import { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItems from './menuItems';
import ArrowLeftIcon from 'infrastructure/assets/svg/arrow-left-thin.svg';
import SearchIcon from 'infrastructure/assets/svg/search-icon-sm.svg';
import ClearIcon from 'infrastructure/assets/svg/clear.svg';

import './navigatorMenu.scss';

export default function NavigatorMenu({ actions={}, ...rest }) {
  const { closeMenu } = actions;

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="navigator-menu">
      <div className="navigator-menu-top-bar">
        {closeMenu && <ArrowLeftIcon className="close-menu-button" onClick={closeMenu} />}
        <div className="navigator-menu-search">
          <input type="text" placeholder="SEARCH" onChange={handleSearch} value={searchTerm} />
          {searchTerm ? <ClearIcon className="clear-icon" onClick={clearSearch} /> : <SearchIcon />}
        </div>
      </div>
      <MenuItems filter={searchTerm.toLowerCase()} actions={actions} {...rest} />
    </div>
  );
}

NavigatorMenu.propTypes = {
  actions: PropTypes.object,
  menuItems: PropTypes.array,
  expandedItem: PropTypes.string,
  isFavoritesFull: PropTypes.bool,
  defaultExpandedItem: PropTypes.string,
};
