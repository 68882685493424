// @ts-check

import { MappedinPolygon } from "@mappedin/mappedin-js";
import { PolygonInfo } from "./PolygonInfo";

/**
 * @typedef {import("../../../types/ServerTypes/Models/LiveMap").LivemapLocationDetailsResponseModel} LivemapLocationDetailsResponseModel
 * 
 */



export default class PolyData {
  
  /** @type {PolygonInfo[]} */
  polygons = [];
  
  /** @type {Map<string, PolygonInfo>} */
  polygonsByExternalId = new Map();
  
  /** @type {Map<string, PolygonInfo>} */
  polygonsById = new Map();
  
  /** @type {Map<string, string>} */
  floorNameByLocationName = new Map();
  
  
  
  /**
   * @param {MappedinPolygon} p
   * @param {string} normalColor
   * @param {boolean} interactive
   */
  addNewPolygon = (p, normalColor, interactive) => {
    
    let newPoly = new PolygonInfo(p, normalColor, interactive);
    
    this.polygons.push(newPoly);
    this.polygonsById.set(p.id, newPoly);
    
    if (p.externalId) {
      if (interactive) {
        this.polygonsByExternalId.set(p.externalId, newPoly);
        this.floorNameByLocationName.set(p.externalId, p.map.shortName);
      }
    }
    
  }
  
  
  /**
   * @param {string} polygonId
   */
  getPolygonInfoById = (polygonId) => {
    return this.polygonsById.get(polygonId);
  }
  
  /**
   * @param {string} externalId
   */
  getPolygonInfoByExternalId = (externalId) => {
    return this.polygonsByExternalId.get(externalId);
  }
  
  /**
   * @param {string} externalId
   */
  getPolygonInfoBySearchMarkerId = (externalId) => {
    let foundPolyInfo = this.polygons.find((polyInfo) => {
      return polyInfo.searchMarkerId === externalId;
    });
    return foundPolyInfo;
  }
  
  /**
   * @param {string} locationName
   */
  getFloorNameByLocationName = (locationName) => {
    return this.floorNameByLocationName.get(locationName) || null;
  }
  
  
  
}

