// this helper responsible for converting unit measurements. doc - https://www.npmjs.com/package/convert-units
import { IsJsonString } from 'infrastructure/js/utils/JSON.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { enumTypes, getEnumValue } from '../../../mat/js/utils/enumHelper';

import configureMeasurements, { temperature, length, area, mass, volume } from 'convert-units';

const viscosity = {
  systems: {
    SI : {
      cP: {
        name: {
          singular: 'Centipoise',
          plural: 'Centipoises',
        },
        to_anchor: 1,
      },
      p: {
        name: {
          singular: 'Poise',
          plural: 'Poises',
        },
        to_anchor: 1e2,
      },
      'pa-s': {
        name: {
          singular: 'Pascal Second',
          plural: 'Pascal Seconds',
        },
        to_anchor: 1e3,
      },
      'kg/m/s': {
        name: {
          singular: 'Kilogram per meter per second',
          plural: 'Kilograms per meter per second',
        },
        to_anchor: 1e3,
      },
      'n-s/m2': {
        name: {
          singular: 'Newton second per square meter',
          plural: 'Newton seconds per square meter',
        },
        to_anchor: 1e3,
      },
    },
  },
};

const convert = configureMeasurements({
  temperature,
  length,
  area,
  mass,
  viscosity,
  volume,
});


let loggedInUser = localStorage['loggedInUser'];
if (!(loggedInUser = IsJsonString(loggedInUser))) {
  loggedInUser = undefined;
}
let unitMeasurementInstance = function() {
  let obj = {};


  let _orgPrefs = {
    TEMPERATURE: "C",
    LENGTH: "cm",
    WIDTH: "cm",
    AREA: "cm2",
    WEIGHT: "g",
    VISCOSITY: "cP",
    VOLUME: "ml"
  };

  obj.getUomForTelemetry = function(uom) {
    let degreeSymbol = String.fromCharCode(176);
    switch (uom) {
      case getEnumValue(enumTypes.TELEMETRY_UOM)("CELSIUS"):
        return `${degreeSymbol}C`;
      case getEnumValue(enumTypes.TELEMETRY_UOM)("FAHRENHEIT"):
        return `${degreeSymbol}F`;
      case getEnumValue(enumTypes.TELEMETRY_UOM)("KELVIN"):
        return "K";
      case getEnumValue(enumTypes.TELEMETRY_UOM)("G_M3"):
        return "g/m³";
      case getEnumValue(enumTypes.TELEMETRY_UOM)("RELATIVE_PERCENTAGE"):
        return "%RH";
      default:
        console.log("no matching uom found");
        return "xxx";
    }
  };

  obj.setSystemUnits = function setSystemUnits(orgPreferences) {
    obj.setUnits(
      obj.getUnitFromEnumValue(orgPreferences.systemTemperatureUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemLengthUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemWidthUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemAreaUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemWeightUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemViscosityUnits),
      obj.getUnitFromEnumValue(orgPreferences.systemVolumeUnits),
    );
  };

  obj.setUnits = function setUnits(temperature, length, width, area, weight, viscosity, volume) {
    _orgPrefs = {
      LENGTH: length,
      WIDTH: width,
      AREA: area,
      WEIGHT: weight,
      TEMPERATURE: temperature,
      VISCOSITY: viscosity,
      VOLUME: volume,
    };
  };

  obj.serverValueToUserValue = function serverValueToUserValue(unitType, serverValue, precision = 10) {
    if (isNaN(serverValue) || null === serverValue || undefined === serverValue || "" === serverValue) {
      return null;
    }
    let sourceUOM = obj.getServerUOMFromUnitType(unitType),
      targetUOM = obj.getUserUOMFromUnitType(unitType);
    return round(
      convert(+serverValue).from(sourceUOM).to(targetUOM),
      precision);
  };

  obj.serverValueToUserValueWithLabel = function serverValueToUserValueWithLabel(unitType, serverValue, precision = 10, shortLabel = true) {
    return `${obj.serverValueToUserValue(unitType, serverValue, precision)} ${obj.getLabelForUnitType(unitType, shortLabel)}`;
  };

  obj.userValueToServerValue = function userValueToServerValue(unitType, userValue, precision = 10) {
    if (isNaN(userValue) || null === userValue || undefined === userValue || "" === userValue) {
      return null;
    }
    let sourceUOM = obj.getUserUOMFromUnitType(unitType),
      targetUOM = obj.getServerUOMFromUnitType(unitType);
    return round(
      convert(+userValue).from(sourceUOM).to(targetUOM),
      precision);
  };

  obj.round = round;
  obj.floor = floor;

/*
* protocol for adding new UOM Type:
* add it as an enum type in enumHelper.js
* add it as a dropdown in createOrganizationDialog.js
* add it in the _orgPrefs object above
* add it in the setSystemUnits function above
* add cases for it in getServerUOMFromUnitType
* add cases for it in getUserUOMFromUnitType
* add case for it in getMinValueForUnitType and getMaxValueForUnitType
* add possible values according to the protocol below

* protocol for adding new UOM:
*   add case in getLabelForUnitType
*   add case in getUnitFromEnumValue
 */

  obj.getServerUOMFromUnitType = function getServerUOMFromUnitType(unitType) {
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
        return "C";
      case "L":
      case "LENGTH":
      case unitTypes.LENGTH:
        return "cm";
      case "WI":
      case "WIDTH":
      case unitTypes.WIDTH:
        return "cm";
      case "A":
      case "AREA":
      case unitTypes.AREA:
        return "cm2";
      case "WE":
      case "WEIGHT":
      case unitTypes.WEIGHT:
        return "g";
      case unitTypes.VISCOSITY:
        return 'cP';
      case unitTypes.VOLUME:
        return 'ml';
    }
  };

  obj.getUserUOMFromUnitType = function getUserUOMFromUnitType(unitType) {
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
        return _orgPrefs.TEMPERATURE;
      case "L":
      case "LENGTH":
      case unitTypes.LENGTH:
        return _orgPrefs.LENGTH;
      case "WI":
      case "WIDTH":
      case unitTypes.WIDTH:
        return _orgPrefs.WIDTH;
      case "A":
      case "AREA":
      case unitTypes.AREA:
        return _orgPrefs.AREA;
      case "WE":
      case "WEIGHT":
      case unitTypes.WEIGHT:
        return _orgPrefs.WEIGHT;
      case unitTypes.VISCOSITY:
        return _orgPrefs.VISCOSITY;
      case unitTypes.VOLUME:
        return _orgPrefs.VOLUME;
    }
  };

  obj.getLabelForMajorUnitType = function getLabelForMajorUnitType(unitType) {
    let label = createLabelHelper("measurements.").get;
    switch (unitType) {
      case "Area":
      case unitTypes.AREA:
        return label("area");
      case "Length":
      case unitTypes.LENGTH:
        return label("length");
      case "Weight":
      case unitTypes.WEIGHT:
        return label("weight");
      default:
        console.error('getLabelForMajorUnitType(): unknown unitType:', unitType);
        return '';
    }
  };

  obj.getLabelForUnitType = function getLabelForUnitType(unitType, short = true) {
    let label = createLabelHelper("measurements.").get;
    let degSymbol = String.fromCharCode(176);
    switch (obj.getUserUOMFromUnitType(unitType)) {
      //temperature
      case "C":
        return short ? degSymbol + label("celsiusShort") : label("celsius");
      case "F":
        return short ? degSymbol + label("fahrenheitShort") : label("fahrenheit");
      //case "K":
      //  return short ? degSymbol + label("kelvinShort") : label("kelvin");
      //length
      case "mm":
        return short ? label("millimeterShort") : label("millimeter");
      case "cm":
        return short ? label("centimeterShort") : label("centimeter");
      case "m":
        return short ? label("meterShort") : label("meter");
      case "in":
        return short ? label("inchShort") : label("inch");
      case "ft":
        return short ? label("feetShort") : label("feet");
      case "yd":
        return short ? label("yardShort") : label("yard");
      //area
      case "mm2":
        return short ? label("squareMillimeterShort") : label("squareMillimeter");
      case "cm2":
        return short ? label("squareCentimeterShort") : label("squareCentimeter");
      case "m2":
        return short ? label("squareMeterShort") : label("squareMeter");
      case "in2":
        return short ? label("squareInchShort") : label("squareInch");
      case "ft2":
        return short ? label("squareFeetShort") : label("squareFeet");
      case "yd2":
        return short ? label("squareYardShort") : label("squareYard");
      //weight
      case "g":
        return short ? label("gramShort") : label("gram");
      case "kg":
        return short ? label("kilogramShort") : label("kilogram");
      case "oz":
        return short ? label("ounceShort") : label("ounce");
      case "lb":
        return short ? label("poundShort") : label("pound");
      //viscosity
      case 'cP':
        return short ? label('centipoiseShort') : label('centipoise');
      case 'pa-s':
        return short ? label('pascalSecondShort') : label('pascalSecond');
      case 'kg/m/s':
        return short ? label('kilogramMeterSecondShort') : label('kilogramMeterSecond');
      case 'p':
        return short ? label('poiseShort') : label('poise');
      case 'n-s/m2':
        return short ? label('newtonSecondSquareMeterShort') : label('newtonSecondSquareMeter');
      //volume
      case 'ml':
        return short ? label('milliliterShort') : label('milliliter');
      case 'l':
        return short ? label('literShort') : label('liter');
      case 'fl-oz':
        return short ? label('fluidOunceShort') : label('fluidOunce');
      case 'cup':
        return short ? label('cupShort') : label('cup');
      case 'pnt':
        return short ? label('pintShort') : label('pint');
      case 'qt':
        return short ? label('quartShort') : label('quart');
      case 'gal':
        return short ? label('gallonShort') : label('gallon');
      default:
        console.error('getLabelForUnitType(): unknown userUOM:', userUOM);
        return '';
    }
  };

  obj.getUnitFromEnumValue = function getUnitFromEnumValue(enumValue) {
    switch (enumValue) {
      case "CELSIUS":
        return "C";
      case "FAHRENHEIT":
        return "F";
      case "METER":
        return "m";
      case "CENTIMETER":
        return "cm";
      case "MILLIMETER":
        return "mm";
      case "INCH":
        return "in";
      case "FEET":
        return "ft";
      case "YARD":
        return "yd";
      case "SQUARE_METER":
        return "m2";
      case "SQUARE_CENTIMETER":
        return "cm2";
      case "SQUARE_MILLIMETER":
        return "mm2";
      case "SQUARE_INCH":
        return "in2";
      case "SQUARE_FEET":
        return "ft2";
      case "SQUARE_YARD":
        return "yd2";
      case "GRAM":
        return "g";
      case "KILOGRAM":
        return "kg";
      case "POUND":
        return "lb";
     case 'CENTIPOISE':
        return 'cP';
     case 'PASCAL_SECOND':
        return 'pa-s';
     case 'KILOGRAM_METER_SECOND':
        return 'kg/m/s';
     case 'POISE':
        return 'p';
     case 'NEWTON_SECOND_SQUARE_METER':
        return 'n-s/m2';
     case 'MILLILITER':
        return 'ml';
     case 'LITER':
        return 'l';
     case 'FLUID_OUNCE':
        return 'fl-oz';
     case 'CUP':
        return 'cup';
     case 'PINT':
        return 'pnt';
     case 'QUART':
        return 'qt';
     case 'GALLON':
        return 'gal';
    }
  };

  obj.getMinValueForUnitType = function getMinValueForUnitType(unitType) {
    let min = 0, precision = 2;
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
        min = -100;
    }
    let sourceUOM = obj.getServerUOMFromUnitType(unitType),
      targetUOM = obj.getUserUOMFromUnitType(unitType);
    return floor(
      convert(min).from(sourceUOM).to(targetUOM),
      precision
    );
  };

  obj.getMaxValueForUnitType = function getMaxValueForUnitType(unitType) {
    let max = 0, precision = 2;
    switch (unitType) {
      case "T":
      case "TEMPERATURE":
      case unitTypes.TEMPERATURE:
      {
        max = 999;
        break;
      }
      case "L":
      case "LENGTH":
      case unitTypes.LENGTH:
        max = 999999;
        if (_orgPrefs.LENGTH === "mm")
          precision = 0;
        break;
      case "A":
      case "AREA":
      case unitTypes.AREA:
        max = 999999900.99;
        if (_orgPrefs.AREA === "mm2")
          precision = 0;
        break;
      case "WI":
      case "WIDTH":
      case unitTypes.WIDTH:
        max = 999;
        if (_orgPrefs.WIDTH === "mm")
          precision = 0;
        break;
      case "WE":
      case "WEIGHT":
      case unitTypes.WEIGHT:
        max = 999999.99;
        if (_orgPrefs.WEIGHT === "kg")
          precision = 5;
        break;
      case unitTypes.VISCOSITY:
        max = 99999999;
        if (_orgPrefs.VISCOSITY === 'cP')
          precision = 0;
        break;
      case unitTypes.VOLUME:
        max = 99999999;
        if (_orgPrefs.VOLUME === 'ml')
          precision = 0;
        break;
    }
    let sourceUOM = obj.getServerUOMFromUnitType(unitType),
      targetUOM = obj.getUserUOMFromUnitType(unitType);
    // return floor(   //round the max values, according to SAAS-16548 - Overhaul of displaying validation messages in import assets
    return round(
      convert(max).from(sourceUOM).to(targetUOM),
      precision
    );
  };

  if (loggedInUser) {
    obj.setSystemUnits(loggedInUser.loggedInOrg.orgPreferences);
  }

  return obj;
}();

function round(value, precision = 2) {
  const multiplier = Math.pow(10, precision || 0);
  return (Math.round(value * multiplier) / multiplier);
}

function floor(value, precision = 2) {
  const multiplier = Math.pow(10, precision || 0);
  return (Math.floor(value * multiplier) / multiplier);
}

if (__DEV__) {
  window.uomHelper = unitMeasurementInstance;
}

export default unitMeasurementInstance;

export const unitTypes = {
  LENGTH: 'LENGTH',
  WIDTH: 'WIDTH',
  AREA: 'AREA',
  WEIGHT: 'WEIGHT',
  TEMPERATURE: 'TEMPERATURE',
  VISCOSITY: 'VISCOSITY',
  VOLUME: 'VOLUME',
};
