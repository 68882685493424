import React from 'react';

import { getEditorDataConverter } from 'infrastructure/js/utils/editorHelper.js';

import DetailsColumn from '../../../../Common/DetailsComponents/detailsColumn.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { isAssetActivated } from '../../../../../utils/assetHelper';
import { navigationStates } from '../../../../../enums/navigationStates.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as AssetDetailsHelper from '../utils/assetDetailsHelper';
import TextFieldEditor from 'infrastructure/js/components/editors/TextFieldEditor/textFieldEditor';
import NumericFieldEditor from 'infrastructure/js/components/editors/NumericFieldEditor/numericFieldEditor';
import DatepickerOverlayEditor from 'infrastructure/js/components/popover/DatepickerOverlayEditor/datepickerOverlayEditor';
import ComboBoxEditor from 'infrastructure/js/components/editors/ComboBoxEditor/comboBoxEditor';
import Link from '../../../../Common/Layout/Link/link';

import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import MaterialHelper from '../../../../../utils/materialHelper';
import { getEnumValue, enumTypes } from '../../../../../utils/enumHelper';
import Button from 'infrastructure/js/components/controls/Button/button';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import TagsAsyncEditor from '../../../Common/TagsAsyncEditor';

import './resinDetails.scss';

export default class ResinDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.assetpage.overview.details.');
    this.dateFormatTooltip = PermissionManager.isDateFormatEnabled()
      ? createLabelHelper('period.date.format.tooltip').get('', undefined, {
          format: DateTimeHelper.getDateFormat(),
        })
      : '';
  }

  convertEditorDataToServerData = getEditorDataConverter('resin');

  handleAssetEditInPlaceSubmit = (editorSubmitData) => {
    if (!editorSubmitData) {
      return;
    }
    let serverDataValue = this.convertEditorDataToServerData(editorSubmitData);

    if (serverDataValue === undefined) {
      return;
    }

    let newData = {};
    newData[editorSubmitData.fieldName] = serverDataValue;
    //If location changed, erase subLocation, send empty value.
    if (
      editorSubmitData.fieldName === 'locationId' &&
      this.props.asset.location.id !== serverDataValue
    ) {
      newData['subLocation'] = null;
    }
    this.props.actions.assetEditorActions.updateResin(this.props.asset.id, newData);
  };

  handleAdditionalFieldSubmit = (editorSubmitData, valueType, id) => {
    if (!editorSubmitData) {
      return;
    }
    let newData = AssetDetailsHelper.getAdditionalFieldValue(valueType, id, editorSubmitData);
    this.props.actions.assetEditorActions.updateResin(this.props.asset.id, newData);
  };

  renderLocationLink = (asset) => {
    if (asset.assetStatus === getEnumValue(enumTypes.ASSET_STATUS)('PHANTOM')) {
      return <span>{asset.locationName}</span>;
    }
    return (
      <div className='location-value-cell'>
        <Link
          label={asset.locationName}
          id={asset.locationId}
          type={navigationStates.LOCATION}
          className="link-details"
        />
        {AssetDetailsHelper.renderAssetLocationAlerts(asset, this.labels)}
      </div>
    );
  };

  renderSublocationEditor = (asset) => {
    return (
      <TextFieldEditor
        id="subLocation"
        name="subLocation"
        value={asset.subLocation}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
        validate={Validation.maxLength(50)}
      />
    );
  };

  renderTagEditor = (asset) => {
    //Convert server object value to singleLineMultiSelect object.

    return (
      <TagsAsyncEditor
        id="tags"
        name="tags"
        limit={10}
        asset={asset}
        onSubmit={this.handleAssetEditInPlaceSubmit}
        disabled={!isAssetActivated(asset.assetStatus)}
        parse={(data, name) => ({
          fieldName: name,
          tags: data.map(({ value }) => ({
            id: value,
          })),
        })}
      />
    );
  };

  renderExpirationDate = (asset) => {
    return asset.expirationDate ? (
      <div>
        <span>
          {asset.expirationDate
            ? DateTimeHelper.FormatDateObjectToDayMonth(asset.expirationDate.value)
            : ''}
        </span>
        {AssetDetailsHelper.getIcon(asset, getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'))}
      </div>
    ) : (
      this.renderUnavailableLabel()
    );
  };

  renderMaxExposureTime(maxExposureTime) {
    if (maxExposureTime === null) {
      return this.renderUnavailableLabel();
    }
    return <span>{DateTimeHelper.ConvertMinutesToHoursMinutes(maxExposureTime)}</span>;
  }

  renderMaxStorageTemperatureEditor = (asset) => {
    return (
      <NumericFieldEditor
        id="maxStorageTemp"
        name="maxStorageTemp"
        value={asset.maxStorageTemp}
        isInteger={true}
        min={UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0)}
        max={UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0)}
        displayValue={asset.m_MaxStorageTemp || this.labels.get('notavailable')}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderLotEditor = (asset) => {
    return (
      <ComboBoxEditor
        id="lot"
        name="lot"
        value={Parse.findOptionByLabel(asset.lot?.id, this.props.assetEditorData.optionsAssetLots)}
        displayValue={asset.lot?.name}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        options={this.props.assetEditorData.optionsAssetLots}
        allowNewOption={true}
        editable={isAssetActivated(asset.assetStatus)}
        //unparse={Parse.comboValueToDomainObject()}   //TODO: check it
      />
    );
  };

  renderMaterialEditor = (asset) => {
    return (
      <ComboBoxEditor
        id="materialId"
        name="materialId"
        value={asset.material.id}
        displayValue={MaterialHelper.getMaterialFullLabel(
          asset.material.materialName,
          asset.material.businessId
        )}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        validate={Validation.required}
        options={this.props.assetEditorData.optionsAssetMaterials}
        parse={Parse.comboValueOnly()}
        format={Format.findOptionByValue(this.props.assetEditorData.optionsAssetMaterials)}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderWeightEditor = (asset) => {
    let isScale = PermissionManager.getOrgPreferences().weightscaleEnabled;
    return (
      <div>
        <NumericFieldEditor
          id="weight"
          name="weight"
          value={asset.weight}
          min={0}
          max={UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT)}
          displayValue={asset.m_Weight}
          handleSubmit={this.handleAssetEditInPlaceSubmit}
          validate={Validation.required}
          editable={isAssetActivated(asset.assetStatus)}
          className={isScale ? 'button' : ''}
          withAdditionalComponent={isScale}
        />
      </div>
    );
  };

  renderManufacturerEditor = (asset) => {
    let assetManufacturer = {
      id: asset.manufacturerModel ? asset.manufacturerModel.id : null,
      name: asset.manufacturerModel ? asset.manufacturerModel.name : null,
    };

    /* TODO: API is currently bugged. */
    return (
      <ComboBoxEditor
        id="manufacturer"
        name="manufacturer"
        value={Parse.findOptionById(
          assetManufacturer.id,
          this.props.assetEditorData.optionsAssetManufacturers
        )}
        displayValue={assetManufacturer.name}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        options={this.props.assetEditorData.optionsAssetManufacturers}
        //unparse={Parse.comboValueToDomainObject()}  //TODO: check it
        allowNewOption={true}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderDateOfManufactureEditor = (asset) => {
    return (
      <DatepickerOverlayEditor
        id="dateManufacturer"
        name="dateManufacturer"
        placement="top"
        value={asset.dateManufacturer}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderBatchNumEditor = (asset) => {
    return (
      <TextFieldEditor
        id="batchNum"
        name="batchNum"
        value={asset.batchNum}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        editable={isAssetActivated(asset.assetStatus)}
        validate={Validation.maxLength(40)}
      />
    );
  };

  renderProjectEditor = (asset) => {
    let project = {
      id: asset.project ? asset.project.id : null,
      businessId: asset.project ? asset.project.businessId : null,
    };
    return (
      <ComboBoxEditor
        id="projectId"
        name="projectId"
        value={project.id}
        displayValue={project.businessId}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        options={this.props.assetEditorData.optionsAssetProjects}
        parse={Parse.comboValueOnly()}
        format={Format.findOptionByValue(this.props.assetEditorData.optionsAssetProjects)}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderInspectionStatusEditor = (asset) => {
    let option = Parse.findOptionById(
      asset.inspectionStatus,
      this.props.assetEditorData.optionsAssetInspectionStatuses
    );
    let displayValue = option ? option.label : asset.inspectionStatus;

    return (
      <ComboBoxEditor
        id="inspectionStatus"
        name="inspectionStatus"
        value={option}
        displayValue={displayValue}
        handleSubmit={this.handleAssetEditInPlaceSubmit}
        options={this.props.assetEditorData.optionsAssetInspectionStatuses}
        editable={isAssetActivated(asset.assetStatus)}
      />
    );
  };

  renderUnavailableLabel() {
    return <span>{this.labels.get('notavailable')}</span>;
  }

  onWeighButtonClick = () => {
    this.props.actions.weightWizardActions.openWeightWizard(this.props.asset.id);
  };

  getWeightItem = (asset) => {
    if (PermissionManager.getOrgPreferences().weightscaleEnabled) {
      return {
        label: this.labels.get('weight'),
        tooltip: this.labels.get('weight.tooltip'),
        body: this.renderWeightEditor(asset),
        button: (
          <Button id="weight" bsStyle="primary" onClick={this.onWeighButtonClick}>
            {this.labels.get('weigh')}
          </Button>
        ),
      };
    }
    return {
      label: this.labels.get('weight'),
      body: this.renderWeightEditor(asset),
    };
  };

  getAssetDetails(asset, labels) {
    let index0 = [
      {
        label: this.labels.get('location'),
        body: this.renderLocationLink(asset),
      },
      {
        label: this.labels.get('sublocation'),
        body: this.renderSublocationEditor(asset),
      },
      {
        label: this.labels.get('tag'),
        body: this.renderTagEditor(asset),
      },
      {
        type: 'hr',
        body: <hr />,
      },
      {
        label: this.labels.get('createdat'),
        body: (
          <span id="create-date-input">
            {DateTimeHelper.FormatDateObjectToDayMonth(asset.createdDate)}
          </span>
        ),
        tooltip: this.dateFormatTooltip,
      },
      {
        label: this.labels.get('createdby'),
        body: <span id="create-by-input">{asset.createdByUser}</span>,
      },
      {
        label: this.labels.get('manufacturer'),
        body: this.renderManufacturerEditor(asset),
      },
      {
        label: this.labels.get('dateofmanufacture'),
        body: this.renderDateOfManufactureEditor(asset),
        tooltip: this.dateFormatTooltip,
      },
      {
        label: this.labels.get('batchNo'),
        body: this.renderBatchNumEditor(asset),
      },
    ];

    let index1 = [
      {
        label: this.labels.get('lot'),
        body: this.renderLotEditor(asset),
      },
      {
        label: this.labels.get('material'),
        body: this.renderMaterialEditor(asset),
      },
      {
        type: 'hr',
        body: <hr />,
      },
      {
        label: this.labels.get('viscosity'),
        body: <span id="create-by-input">
                {UnitHelper.serverValueToUserValueWithLabel(unitTypes.VISCOSITY, asset.viscosity || 0, 2)}
              </span>,
      },
      {
        label: this.labels.get('volume'),
        body: <span id="create-by-input">
                    {UnitHelper.serverValueToUserValueWithLabel(unitTypes.VOLUME, asset.volumeLeft || 0, 2)}
              </span>,
      },
      {
        label: this.labels.get('project'),
        body: this.renderProjectEditor(asset),
      },
      {
        label: this.labels.get('inspectionStatus'),
        body: this.renderInspectionStatusEditor(asset),
      },
    ];

    let weightItem = this.getWeightItem(asset);
    index1.splice(2, 0, weightItem);

    let index2 = [
      {
        label: this.labels.get('expirationdate'),
        body: this.renderExpirationDate(asset),
        tooltip: this.dateFormatTooltip,
      },
      ...(PermissionManager.isEtlBondEnabled()
        ? [
            {
              label: this.labels.get('exposureTimeLeftBond'),
              body: AssetDetailsHelper.renderTimeLeft(
                asset,
                asset?.exposureTimeLeftBond?.value ?? null,
                getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND')
              ),
            },
          ]
        : []),
      {
        label: this.labels.get('exposureTimeLeftCure'),
        body: AssetDetailsHelper.renderTimeLeft(
          asset,
          asset?.exposureTimeLeftCure?.value ?? null,
          getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE')
        ),
      },

      ...(!PermissionManager.isEtlBondEnabled()
        ? [ {label: '', body: null } ]
        : []),
      {
        type: 'hr',
        body: <hr />,
      },
      ...(PermissionManager.isEtlBondEnabled()
        ? [
            {
              label: this.labels.get('maxExposureTimeBond'),
              body: this.renderMaxExposureTime(asset.maxExposureTimeBond),
            },
          ]
        : []),
      {
        label: this.labels.get('maxExposureTimeCure'),
        body: this.renderMaxExposureTime(asset.maxExposureTimeCure),
      },
      {
        label: this.labels.get('maxstoragetemp'),
        body: this.renderMaxStorageTemperatureEditor(asset),
      },
      {
        label: this.labels.get('defrostingStatus'),
        body: AssetDetailsHelper.renderDefrostingStatus(asset),
      },
      {
        label: this.labels.get('defrostingTime'),
        body: AssetDetailsHelper.renderTimeLeft(
          asset,
          asset?.defrostingTimeLeft ?? null,
          getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME')
        ),
      },
    ];

    let result = [index0, index1, index2];

    let additionalFields = this.props.assetEditorData?.additionalFields?.resinAdditionalFields || [];
    if (additionalFields && additionalFields.length > 0) {
      AssetDetailsHelper.alignColumns(result);
      AssetDetailsHelper.addAdditionalFields(result, asset, this.handleAdditionalFieldSubmit, additionalFields);
    }


    return result;
  }

  render() {
    let { asset } = this.props;
    if (!asset || !asset.id) {
      return null;
    }

    let columnsData = this.getAssetDetails(asset, this.labels);

    let columns = columnsData.map((column, index) => {
      return <DetailsColumn key={'resin' + index} rows={column} />;
    });

    return <div className="asset-details-section">{columns}</div>;
  }
}
