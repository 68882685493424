import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/Administration/MatSettingsPage/Dialogs/createKitTypeDialogActions.js';

let defaultState = {
  loading: false,
  show: false,
  itemToEdit: null,
  dialogData: null,
  isClone: false,
  // additionalFields:[]
};

export default function(state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.CREATE_KIT_TYPE_DIALOG_SHOW:
      return state.set('itemToEdit', action.payload.itemToEdit)
                  .set('isClone', action.payload.isClone)
                  .set('show', true);
    default:
      return defaultDialogReducer(state, action, dialogsNames.CREATE_KIT_TYPE_DIALOG, defaultState);
  }
}












