import { useEffect } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import useLabels from 'infrastructure/js/hooks/useLabels';
import { jsxActions as logsPageActions } from '../../../../actions/Administration/LogsPage/logsPageActions.js';
import { jsxActions as notificationLogsViewActions } from '../../../../actions/Administration/LogsPage/NotificationLogsView/notificationLogsViewActions';
import { jsxActions as notificationLogsGridActions } from '../../../../actions/Administration/LogsPage/NotificationLogsView/notificationLogsGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions.js';
import { gridsNames } from '../../../../enums/gridsNames';
import { LogsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';
import NotificationLogsGrid from './notificationLogsGrid';
import Header, { headerItemsLabels } from '../../../Common/Header/header';

import './notificationLogsView.scss';


const allActions = {
  logsPageActions,
  notificationLogsViewActions,
  notificationLogsGridActions,
  fetchEntitiesActions,
};

const stateSelector = (state) => {
  let sGrid = state.grid.get(gridsNames.ADMINISTRATION_NOTIFICATION_LOGS);
  return {
    sGrid: sGrid,
  };
};

export default function NotificationLogsView() {
  const labels = useLabels('mat.administration.logs.notificationLogs.');
  const { actions } = useRedux(allActions, stateSelector);

  useEffect(() => {
    actions.logsPageActions.onSelectedMenuItemChanged(LogsMenuItemsIds.NAV_ELEMENT_NOTIFICATION_LOGS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerItems = {
    buttons: [
      {
        id: 'clearFilters',
        label: '',
        icon: 'pl pl-filter-clear',
        action: actions.notificationLogsViewActions.clearFilters,
        tooltip: headerItemsLabels.CLEAR_FILTERS,
      },
      {
        id: 'refresh',
        label: '',
        icon: 'pl pl-refresh',
        action: actions.notificationLogsViewActions.reload,
        tooltip: headerItemsLabels.REFRESH,
      },
    ],
  };

  return (
    <div className="notification-logs-view">
      <Header title={labels.get('title')} {...headerItems} />
      <NotificationLogsGrid actions={{ ...actions.notificationLogsGridActions, ...actions.fetchEntitiesActions }} />
    </div>
  );
}
