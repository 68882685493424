import {statusIcons} from '../../assets/svg';
import {getEnumValue, enumTypes} from './enumHelper';

export function getStatusIcon (status) {
  switch(status) {
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('CREATED'):
      return statusIcons.statusCreated;
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('READY_TO_SHIP'):
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('CLOSED'):
      return statusIcons.statusReadyToShip;
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('TRANSIENT'):
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('IN_TRANSIT'):
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('SHIPPED_TO_CUSTOMER'):
      return statusIcons.statusInTransit;
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('RECEIVED'):
      return statusIcons.statusReceived;
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('REJECTED'):
    case getEnumValue(enumTypes.SHIPMENT_STATUS)('MISSING'):  //TODO: L - temp here (till it has its own icon)
      return statusIcons.statusRejected;
    case getEnumValue(enumTypes.ASSET_STATUS)('ACTIVE'):
      return statusIcons.statusActive;     //TODO: L - update .svg statusActive
    case getEnumValue(enumTypes.ASSET_STATUS)('LOCKED'):
      return statusIcons.statusLocked;     //TODO: L - update .svg statusLocked

    default:
      console.error('Unknown status: ' + status);
      return null;
  }
}

