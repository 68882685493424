import React from 'react';
import {reduxForm} from 'redux-form';
import { withRouter } from 'react-router-dom';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as topSearchHelper from './topSearchHelper';
import EntitiesMultiSelect from '../../../components/Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import TopSearchResult from './TopSearchResult/topSearchResult';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import SearchIcon from 'infrastructure/assets/svg/search-icon-big.svg';

require('./topSearch.scss');

class TopSearch extends React.PureComponent{
  constructor(props){
    super(props);

    this.labels = createLabelHelper('mat.header.search.');

    this.entitiesTypes = [
      getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'),
      getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'),
      getEnumValue(enumTypes.OBJECT_TYPE)('PART'),
      getEnumValue(enumTypes.OBJECT_TYPE)('KIT'),
      getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'),
      getEnumValue(enumTypes.OBJECT_TYPE)('WO'),
      getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION'),
      getEnumValue(enumTypes.OBJECT_TYPE)('STATION')];

    if (PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled) {
      this.entitiesTypes.push(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
    }
    if (PermissionManager.isResinsEnabled()) {
      this.entitiesTypes.push(getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'));
    }

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      //NOTE: currently the NOT_IN_PASSPORT_STATUS is relevant only for Rolls and filtered out for all others entities in fetchEntitiesActions.js
      filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.NOT_IN_PASSPORT_STATUS],
    };

    this.state = {
      opened: false,
      showResults: false,
      isLast: false,
      currentType: this.getInitialSearchType(),
      currentInput: '',
      currentResults : [],
      //temp workaround for react-select autoFocus
      autoFocusValue: true
    };

    this.resultsFetched = false;
    this.handleResultsOnFetchComplete = false;
  }

  getInitialSearchType = () => {
    let type = localStorage.getItem('currentSearchType');
    if ( !type ||
        (type === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP') && (!PermissionManager.hasGroupAssetPermissions() || !PermissionManager.getOrgPreferences().groupsEnabled)) ||
        (type === getEnumValue(enumTypes.OBJECT_TYPE)('RESIN') && !PermissionManager.isResinsEnabled()) ){
      return this.entitiesTypes[0];
    }
    return type;
  }

  openSearchComponentHandler = () => {
    this.setState({opened: true, autoFocusValue: true});
  };

  closeSearchComponentHandler = () => {
    this.setState({
      opened: false,
      showResults: false,
      isLast: false,
      currentInput: '',
      currentResults : [],
    });
  };

  // Collapses the Search component when clicking outside this component.
  // Note: Blur event's relatedTarget is used to detect the click inside the Search component.
  //       Not all children of the Search component are capable of gaining the focus by default,
  //       tabindex="0" is used to fix it.
  onBlurHandler = (e) => {
    if (e.relatedTarget === null || (this.searchRef && !this.searchRef.contains(e.relatedTarget)) ) {
      this.closeSearchComponentHandler();
    }
  };

  setSearchRef = (r) => {
    this.searchRef = r || this.searchRef;
  };

  onTypeChangeCallback = (value) => {
    // temp workaround for react-select autoFocus
    this.setState({autoFocusValue: undefined});

    this.setState({ currentType: value,
                    currentResults : [],
                    showResults: false
                  });
    localStorage.setItem('currentSearchType', value);
  };

  onInputChangeCallback = (e) => {
    this.setState({currentInput: e.target.value});
    this.resultsFetched = false;
  };

  onKeyPressCallback = (e) => {
    if (e.key === 'Enter' && this.state.currentInput) {

      if (!this.resultsFetched) {
        this.handleResultsOnFetchComplete = true;
        return;
      }

      if (this.handleResults(this.state.currentResults)) {
        this.closeSearchComponentHandler();
      }
    }
  };

  onFetchCompleteCallback = (value) => {
    this.resultsFetched = true;

    if (this.handleResultsOnFetchComplete) {
      this.handleResultsOnFetchComplete = false;
      if (value && value.options) {
        if (this.handleResults(value.options)) {
          return this.closeSearchComponentHandler();
        }
      }
    }

    if (value && value.options) {
      this.setState({
        isLast: value.last,
        currentResults: value.options,
        showResults: true
      });
    }
  };

  handleResults = (results) => {
    if (results) {
      let found = results.find((item) => {
        return (item.label.toUpperCase() === this.state.currentInput.toUpperCase());
      });
      if (found) {
        let link = topSearchHelper.getLinkByType(this.state.currentType, found.value);
        if (window.location.hash !== '#' + link) {
          this.props.history.push(link);
        }
        return true;
      }
    }
    return false;
  };

  renderSearchComponent() {
    if (this.state.opened) {
      return (
        <div className="expanded-search" tabIndex="0">
          <EntitiesMultiSelect
            { ...this.props }
            id="search-field"
            name="searchType"
            cache={false}
            // onBlurResetsInput={false} //removed
            className="search-field"
            entitiesTypes={this.entitiesTypes}
            fetchConfig={this.fetchConfig}
            onInputChangeCallback={this.onInputChangeCallback}
            onKeyPressCallback={this.onKeyPressCallback}
            onTypeChangeCallback={this.onTypeChangeCallback}
            hasCustomMenuRenderer={true}
            onFetchCompleteCallback={this.onFetchCompleteCallback}
            useSimpleInput={true}
            simpleInputAutoLoad={false}

            placeholder={this.labels.get('placeholder')}
            defaultOptions={false}
            autoFocus={this.state.autoFocusValue}
            selectedEntitiesType={this.state.currentType}
          />
          {this.renderSearchResults()}
        </div> );
    }
    return null;
  }

  renderSearchResults() {
    if (this.state.showResults) {
      return  <TopSearchResult
        results={this.state.currentResults }
        isLast={this.state.isLast}
        currentInput={ this.state.currentInput}
        currentType={this.state.currentType}
        onSearchResultClick={this.closeSearchComponentHandler}
      />
    }
    return null;
  }

  renderSearchOpenButton() {
    if (!this.state.opened) {
      return <SearchIcon className="top-bar-icon" onClick={this.openSearchComponentHandler}/>;
    }
    return null;
  }

  render() {
    return (
      <div id="search-component" className="search-component"  ref={this.setSearchRef} onBlur={this.onBlurHandler}>
        {this.renderSearchComponent()}
        {this.renderSearchOpenButton()}
      </div>
    );
  }
}


// export default reduxForm({
let TopSearchForm = reduxForm({
    form: 'TopSearch',
  }
)(TopSearch);

export default withRouter(TopSearchForm);
