// @ts-check

import RefContextHelper from "../Utils/RefContextHelper";


/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapLocationSearchResultsBlock} MapLocationSearchResultsBlock
 * 
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultItem} LiveSearchResultItem
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultLocation} LiveSearchResultLocation
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultBlock} LiveSearchResultBlock
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").PerLocationCount} PerLocationCount
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").BlockGroup} BlockGroup
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").BlockGroupSplitItem} BlockGroupSplitItem
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").PL_SearchState} PL_SearchState
 * 
 * @typedef {import("@mappedin/mappedin-js/renderer/internal").SmartTooltip} SmartTooltip
 * 
 */



export default class SearchResultsTransformer {


  /** 
   * Transform the search results into a map where the key is the location name.
   * 
   * @param {LiveSearchResultResponseModel | null} searchResults
   * @returns {Map<string, MapLocationSearchResultsBlock>}
   */
  static createSearchResultsByLocationNameMap(searchResults) {

    // console.log('searchResults ===> ', searchResults);


    /** @type {Map<string, MapLocationSearchResultsBlock>} */
    let resultMap = new Map();

    if (!searchResults) {
      return resultMap;
    }


    /** @param {string} locationName */
    let getOrCreateMapLocationBlock = (locationName) => {
      let mapLocationBlock = resultMap.get(locationName);
      if (!mapLocationBlock) {

        /** @type {MapLocationSearchResultsBlock} */
        mapLocationBlock = {
          locationName: locationName,
          items: [],
          totalCount: 0,
          perLocationCounts: [],
        };

        resultMap.set(locationName, mapLocationBlock);
      }
      return mapLocationBlock;
    };


    let allResultItems = searchResults.blocks.flatMap((block) => block.items);

    /** @param {string} locationName */
    let findAllResultItemsForLocation = (locationName) => {
      return allResultItems.filter((item) => {
        return item.location.locationName === locationName;
      });
    };


    // let findAllResultItems_ofTypeLocation = () => {
    //   let locationsResultBlock = searchResults.blocks.find((item) => {
    //     return item.type === "LOCATION";
    //   });

    //   if (!locationsResultBlock) {
    //     return [];
    //   }

    //   return locationsResultBlock.items;

    // };



    let allPerLocationCounts = searchResults.blocks.flatMap((block) => block.totalPerLocationCounts);
    allPerLocationCounts.forEach((c) => {

      let locationName = c.locationName;
      if (!locationName) {
        return;
      }

      let mapLocationBlock = getOrCreateMapLocationBlock(locationName);

      // Add this PerLocationCount for this ObjectType to the block.
      mapLocationBlock.perLocationCounts.push(c);
      mapLocationBlock.totalCount = mapLocationBlock.totalCount + c.count;

      // Find all items for this location.
      // Needs to be done only once.
      if (mapLocationBlock.items.length === 0) {
        let locationItems = findAllResultItemsForLocation(locationName);
        mapLocationBlock.items = locationItems;
      }

    });



    // //////////////////////////////////////////////////////////
    // // Location type blocks dont have PerLocationCounts.
    // // We will add only their items to the result map.
    // //
    // // It should not increase the total items count.
    // //
    // let locationItems = findAllResultItems_ofTypeLocation();
    // locationItems.forEach((item) => {

    //   let locationName = item.location.locationName;
    //   if (!locationName) {
    //     return;
    //   }

    //   let mapLocationBlock = getOrCreateMapLocationBlock(locationName);
    //   mapLocationBlock.items.push(item);

    // });


    return resultMap;
  };







  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {LiveSearchResultResponseModel | null} searchResults
   * 
   * @returns {LiveSearchResultResponseModel | null}
   */
  static createResultsView(refContext, searchResults) {
    if (!searchResults) {
      return null;
    }

    let sData = refContext.current.refSData.current;
    if (!sData) {
      return null;
    }

    let selection = sData.search.resultSelection;
    if (!selection) {
      return null;
    }

    
    // console.log('selection ===> ', selection);



    let viewResults = { ...searchResults };


    /////////////////////////////////////////////////
    // When user selected by-Location block title.
    //
    let locationName = selection.locationName;
    if (locationName) {

      let originalBLocks = searchResults.blocks;
      let byLocationBlocks = SearchResultsTransformer.transformBlocks_SplitByLocation(originalBLocks);
      // console.log('byLocationBlocks ===> ', byLocationBlocks);
      let block = byLocationBlocks.find((block) => {
        // return block.items.some((item) => {
        //   return item.location.locationName === locationName;
        // });
        return block.totalPerLocationCounts.some((c) => {
          return c.locationName === locationName;
        });
      });

      // console.log('block ===> ', block);

      if (block) {
        viewResults.blocks = [block];
        return viewResults;
      }

      return null;
    }


    //////////////////////////////////////
    // When user selected block title.
    //
    let category = selection.category;
    if (category) {

      let originalBLocks = searchResults.blocks;
      let block = originalBLocks.find((block) => {
        return block.type === category;
      });

      if (block) {
        viewResults.blocks = [block];
        return viewResults;
      }

      return null;
    }


    //////////////////////////////////////
    // When user selected specific item.
    //
    if (selection.item) {

      // console.log('selection.item ===> ', selection.item);

      let itemId = selection.item.id;
      let itemBusinessId = selection.item.businessId;

      let originalBLocks = searchResults.blocks;
      let block = originalBLocks.find((block) => {
        let item = block.items.find((item) => {
          return (item.id === itemId) && (item.businessId === itemBusinessId);
        });
        return item;
      });

      if (!block) {
        return null;
      }

      let viewBlock = { ...block };
      viewBlock.items = [selection.item];
      viewBlock.totalCount = 1;
      viewBlock.totalPerLocationCounts = [];
      viewBlock.totalPerLocationCounts.push({
        locationId: selection.item.location.locationId,
        locationName: selection.item.location.locationName,
        floorName: selection.item.location.floorName,
        objectType: selection.item.type,
        count: 1,
      });


      viewResults.blocks = [viewBlock];

      return viewResults;

    }



    return null;

  }






  /** 
   * @param {LiveSearchResultBlock[]} blocks
   * 
   */
  static transformBlocks_SplitByLocation = (blocks) => {

    let allResultItems = blocks.flatMap((b) => b.items);
    let allPerLocationCounts = blocks.flatMap((b) => b.totalPerLocationCounts);

    
    /** @param {string} locationName */
    let findItemsForLocation = (locationName) => {
      return allResultItems.filter((item) => {

        if (locationName === RefContextHelper.NO_FLOOR_NAME) {
          return !item.location.locationName;
        }

        return item.location.locationName === locationName;
      });
    };
    
    

    let countsByLocationName = allPerLocationCounts.reduce((/** @type {Record<string, PerLocationCount[]>} */ acc, c) => {
      let locationName = c.locationName || RefContextHelper.NO_LOCAITON_NAME;

      if (!acc[locationName]) {
        acc[locationName] = [];
      }
      acc[locationName].push(c);
      return acc;
    }, {});

    
    
    
    let locationNames = Object.keys(countsByLocationName);
    locationNames.sort();
    // console.log('locationNames ===> ', locationNames);


    /** @type {LiveSearchResultBlock[]} */
    let resultBlocks = [];

    locationNames.forEach((locationName) => {
      
      let counts = countsByLocationName[locationName];
      let items = findItemsForLocation(locationName);

      /** @type {LiveSearchResultBlock} */
      let block = {
        type: 'LOCATION',
        items: items,
        totalCount: counts.reduce((acc, c) => acc + c.count, 0),
        totalPerLocationCounts: counts,
      };

      resultBlocks.push(block);
    });

    return resultBlocks;
  }



  // /** 
  //  * Splits the blocks by Search type (TOOLS, MATERIALS, LOCATION, etc).
  //  * 
  //  * When applied to raw search results, that already split by search type,
  //  * it will generate additional dummy blocks based on perLocationCounts.
  //  * 
  //  * 
  //  * @param {LiveSearchResultBlock[]} blocks
  //  * 
  //  */
  // static transformBlocks_SplitBySearchType = (blocks) => {

  //   let allResultItems = blocks.flatMap((b) => b.items);
  //   let allPerLocationCounts = blocks.flatMap((b) => b.totalPerLocationCounts);

    
  //   /** @param {string} locationName */
  //   let findItemsForLocation = (locationName) => {
  //     return allResultItems.filter((item) => {

  //       if (locationName === RefContextHelper.NO_FLOOR_NAME) {
  //         return !item.location.locationName;
  //       }

  //       return item.location.locationName === locationName;
  //     });
  //   };
    
    

  //   let countsByLocationName = allPerLocationCounts.reduce((/** @type {Record<string, PerLocationCount[]>} */ acc, c) => {
  //     let locationName = c.locationName || RefContextHelper.NO_LOCAITON_NAME;

  //     if (!acc[locationName]) {
  //       acc[locationName] = [];
  //     }
  //     acc[locationName].push(c);
  //     return acc;
  //   }, {});

    
    
    
  //   let locationNames = Object.keys(countsByLocationName);
  //   locationNames.sort();
  //   // console.log('locationNames ===> ', locationNames);


  //   /** @type {LiveSearchResultBlock[]} */
  //   let resultBlocks = [];

  //   locationNames.forEach((locationName) => {
      
  //     let counts = countsByLocationName[locationName];
  //     let items = findItemsForLocation(locationName);

  //     /** @type {LiveSearchResultBlock} */
  //     let block = {
  //       type: 'LOCATION',
  //       items: items,
  //       totalCount: counts.reduce((acc, c) => acc + c.count, 0),
  //       totalPerLocationCounts: counts,
  //     };

  //     resultBlocks.push(block);
  //   });

  //   return resultBlocks;
  // }


  /** 
   * @param {LiveSearchResultBlock[]} blocks
   * 
   */
  static transformBlocks_regroupByFloor = (blocks) => {
    // console.log('refContext ===> ', refContext);
    // console.log('AAAAAAAAAAAAAAAAAAAAAAAA ===> ',);


    /////////////////////////////////////////////////////
    // Split each input block into floor blocks.
    //
    /** @type {BlockGroupSplitItem[]} */
    let groupSplits = [];
    blocks.forEach((block) => {
      // console.log(' Sending block ===> ', block);
      let groupSplitsFromOne = this.transformBlock_splitToFloors(block);
      // console.log(' splitBlocksFromOneBlock ===> ', splitBlocksFromOneBlock);
      if (groupSplitsFromOne) {
        groupSplits.push(...groupSplitsFromOne);
      }
    });

    // console.log('allSplitBlocks ===> ', allSplitBlocks);


    ///////////////////////////////////////////////////////////////
    // Regroup array of split blocks into array of floor blocks.
    //
    /** @type {BlockGroup[]} */
    let blocksByFloor = [];
    groupSplits.forEach((splitItem) => {
      // console.log(' processing ===> ', floorBlock.floorName);

      let accFloorGroup = blocksByFloor.find((group) => {
        return group.groupName === splitItem.groupName;
      });

      if (!accFloorGroup) {
        // console.log(' creating ===> ', floorBlock.floorName);
        accFloorGroup = {
          groupName: splitItem.groupName,
          groupType: 'FLOOR',
          blocks: [],
        };
        blocksByFloor.push(accFloorGroup);
      }

      accFloorGroup.blocks.push(splitItem.block);

    });
    
    
    ///////////////////////////////////////////////////////////////
    // Sort the blocks by elevation.
    //
    let floorNamesByElevation = RefContextHelper.getFloorNamesSortedByElevation();
    if (!floorNamesByElevation) {
      return blocksByFloor;
    }
    
    floorNamesByElevation.push(RefContextHelper.NO_FLOOR_NAME);
    
    blocksByFloor.sort((a, b) => {
      let aIndex = floorNamesByElevation.indexOf(a.groupName);
      let bIndex = floorNamesByElevation.indexOf(b.groupName);
      return aIndex - bIndex;
    });

    return blocksByFloor;

  }



  /** 
   * @param {LiveSearchResultBlock} block
   * 
   * @returns {BlockGroupSplitItem[] | null}
   * 
   */
  static transformBlock_splitToFloors = (block) => {

    let allResultItems = block.items;
    let allPerLocationCounts = block.totalPerLocationCounts;



    /** @param {string} floorName */
    let findItemsForFloor = (floorName) => {
      return allResultItems.filter((item) => {

        if (floorName === RefContextHelper.NO_FLOOR_NAME) {
          return !item.location.floorName;
        }

        return item.location.floorName === floorName;
      });
    };


    let countsByFloorName = allPerLocationCounts.reduce((/** @type {Record<string, PerLocationCount[]>} */ acc, c) => {
      let floorName = c.floorName || RefContextHelper.NO_FLOOR_NAME;

      if (!acc[floorName]) {
        acc[floorName] = [];
      }
      acc[floorName].push(c);
      return acc;
    }, {});


    // console.log('countsByFloorName ===> ', countsByFloorName);

    let floorNames = Object.keys(countsByFloorName);
    // console.log('floorNames ===> ', floorNames);



    /** @type {BlockGroupSplitItem[]} */
    let floorSplits = [];

    floorNames.forEach((floorName) => {

      let counts = countsByFloorName[floorName];
      let items = findItemsForFloor(floorName);

      /** @type {LiveSearchResultBlock} */
      let b = {
        type: block.type,
        items: items,
        totalCount: counts.reduce((acc, c) => acc + c.count, 0),
        totalPerLocationCounts: counts,
      };

      floorSplits.push({
        groupName: floorName,
        block: b,
      });

    });

    return floorSplits;
  }

  
  
  
  







}




