import { useMemo, useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import PL_FileChooser from 'infrastructure/js/components/controls/FileChooser/fileChooser';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import './processTemperatureLogDialog.scss';

export function ProcessTemperatureLogDialog({ asset, sData, sLocationDetails, actions, initialize, handleSubmit, reloadParentComponent }) {
  const { hide, submit, processTemperatureLogFile } = actions;
  const labels = useMemo(() => createLabelHelper('mat.locationpage.view.shipments.processTemperatureLog.dialog.'), []);
  const dialogLabels = useMemo(() => createLabelHelper('mat.dialog.'), []);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const initialValues = {
      reduceEtlBond: PermissionManager.isEtlBondEnabled(),
      reduceEtlCure: true,
    };

    initialize(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (formData) => {
    const { attachmentId, minutesToReduceFromEtl } = sData.get('logFileData');

    const submitData = {
      ...formData,
      id: asset.id,
      locationId: sLocationDetails.id,
      attachmentId,
      minutesToReduceFromEtl,
    };

    submit(submitData, reloadParentComponent);
  };

  const footerButtons = {
    left: [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: hide,
        disabled: sData.get('loading'),
      },
    ],
    right: [
      {
        id: 'submit',
        text: dialogLabels.get('confirm'),
        bsStyle: 'primary',
        loading: sData.get('loading'),
        action: handleSubmit(onSubmit),
        disabled: sData.get('loading') || !selectedFile || sData.get('errors'),
      },
    ],
  };

  const handleSelectedFileChanged = (file) => {
    setSelectedFile(file);
    const id = asset.id;
    processTemperatureLogFile(id, file);
  };

  const renderErrors = () => {
    const errors = sData.get('errors');
    if (errors) {
      let icon = 'pl pl-warning-icon';
      let errorType = errors.statusType;
      return (
        <div className="error-selection">
          <div className="error-item">
            <span className={`error-icon ${icon} ${errorType}`} />
            <label className="error-msg">{errors.message}</label>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderMessage = () => {
    const message = sData.get('logFileData')?.message;
    if (message) {
      return <div className="processed-file-message">{message}</div>;
    }

    return null;
  };

  const renderEtlBond = () => {
    return PermissionManager.isEtlBondEnabled() ?
    <InputSection className="">
      <Checkbox name="reduceEtlBond" id="active" label={labels.get('etlBond')} />
    </InputSection> : null
  };

  return (
    <Dialog
      id="process-temperature-log-dialog"
      className="process-temperature-log-dialog"
      titleText={labels.get('header.title')}
      show={sData.get('show')}
      onHide={hide}
      sData={sData}
      footerButtons={footerButtons}
      onEnterKeyPress={handleSubmit(onSubmit)}
    >
      <div className="body-title">
        {labels.get('body.title')} {asset?.businessId}
      </div>

      <div className="inputs-section">
        <div className="file-section-wrapper">
          <div className="file-select-label">{labels.get('fileSelectLabel')}</div>
          <PL_FileChooser
            placeHolder={labels.get('fileSelectPlaceholder')}
            buttonText={selectedFile ? dialogLabels.get('changeFile') : dialogLabels.get('browse')}
            accept={['.csv']}
            selectedFile={selectedFile}
            disabled={sData.get('loading')}
            onSelectedFileChanged={handleSelectedFileChanged}
          />
        </div>

        <div className="etls-section-wrapper">
          {renderEtlBond()}
          <InputSection className="">
            <Checkbox name="reduceEtlCure" id="active" label={labels.get('etlCure')} />
          </InputSection>
        </div>
      </div>

      {renderMessage()}
      {renderErrors()}
    </Dialog>
  );
}

export default reduxForm({
  form: 'processTemperatureDialog',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(ProcessTemperatureLogDialog);
