import Network from '../../../../infrastructure/js/modules/network';
import { api as printRfidTagsApi } from '../Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions';
import { api as systemApi } from '../System/systemActions';
import * as printerService from '../../services/Rfid/printersService';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import assetPrintTagsService from '../../services/Assets/assetPrintTagsService';
import { api as batchJobsApi } from '../BatchJobs/batchJobsActions.js';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.printEmptyRfidTags = function (dispatch, getState) {
  return function (locationId) {
    systemApi.setLoading(true)(dispatch, getState);
    printerService.fetchPrintersByLocation(locationId).then((response) => {
      systemApi.setLoading(false)(dispatch, getState);

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      printRfidTagsApi.show(dispatch, getState)([], true, response.dataList);
    })
    .catch((err) => {
      console.error('Fetching printers failed.', err);
      systemApi.setLoading(false)(dispatch, getState);
    });
  };
};

api.openPrintRfidTagsDialog = function (dispatch, getState) {
  return function (data) {
    systemApi.setLoading(true)(dispatch, getState);

    printerService.fetchAllPrinters().then((response) => {
      systemApi.setLoading(false)(dispatch, getState);

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if (!response.dataList?.length) {
        const labelKey = 'mat.locationpage.view.assets.printRfidTagsDialog.noPrinter.message.title';
        messageDialogApi.showError(dispatch, getState)(labelKey);
        return { success: false };
      }
      let printers = response.dataList?.sort((item) => {return item.default ? -1 : 0}) || [];
      printRfidTagsApi.show(dispatch, getState)([], false, printers, data);
    })
    .catch((err) => {
      console.error('Fetching printers failed.', err);
      systemApi.setLoading(false)(dispatch, getState);
    });
  };
};

//TODO: use the 'openPrintRfidTagsDialog', remove 'printRfidTags'
api.printRfidTags = function (dispatch, getState) {
  return function (data) {
    systemApi.setLoading(true)(dispatch, getState);

    printerService.fetchAllPrinters().then((response) => {
      systemApi.setLoading(false)(dispatch, getState);

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      if (!response.dataList?.length) {
        const labelKey = 'mat.locationpage.view.assets.printRfidTagsDialog.noPrinter.message.title';
        messageDialogApi.showError(dispatch, getState)(labelKey);
        return { success: false };
      }

      let printers = response.dataList?.sort((item) => {return item.default ? -1 : 0}) || [];
      printRfidTagsApi.show(dispatch, getState)([], false, printers, data);
    })
    .catch((err) => {
      console.error('Fetching printers failed.', err);
      systemApi.setLoading(false)(dispatch, getState);
    });
  };
};

api.printTags = function (dispatch, getState) {
  return function (assetsIds, printerId) {
    let queryData = {
      printerId: printerId ? printerId : null,
      assetIds: assetsIds,
    };

    assetPrintTagsService.printRfidTagsNew(queryData).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to print RFID tags', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      let pollingRequest = 'batchJobs/JOB_EXECUTION_ID/status-with-printing';

      batchJobsApi
        .runJob(dispatch, getState)(response.data, { pollingRequest })
        .then((jobResponse) => {
          return { success: true };
        })
        .catch((error) => {
          console.error('printTags.runJob failed, catch error: ', error);
          return { success: false };
        });
    });
  };
};

let utility = {};

utility.NormalizeResponseError = function (response) {
  let message;
  let statusType;
  let errorCode;

  if (
    !response ||
    !response.applicationResponseStatus ||
    !response.applicationResponseStatus.message
  ) {
    message = 'Print Tags Failed';
  } else {
    message = response.applicationResponseStatus.message;
    statusType = response.applicationResponseStatus.statusType.toLowerCase();
    errorCode = response.applicationResponseStatus.errorCode;
  }

  return {
    message,
    statusType: statusType,
    errorCode: errorCode,
  };
};

utility.NormalizePrinterPollingResponseError = function (type, id) {
  return {
    message: 'Failed To Print Tags On Asset ' + type + ' ' + id,
    statusType: 'error',
    errorCode: '0',
  };
};
