import React, {Component} from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';

import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../enums/gridsNames.js';
import ComponentWithErrorCell from '../../../Common/CustomGridCells/ComponentWithErrorCell/componentWithErrorCell.js';
import LabelCell from '../../Common/Cells/LabelCell/labelCell.js';
import editableViewCell from '../../Common/Cells/InputCell/editableViewCell';
import Label from 'infrastructure/js/components/Label/label';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import MaterialHelper from '../../../../utils/materialHelper';
require('./SelectNestsStep.scss');

export class SelectNestsFirstStep extends React.PureComponent {

  constructor(props) {
    super(props);

    this.gridLabels = createLabelHelper(`mat.wizards.import.files.importCutPlanWithRoll.grid.selectesnests.`);
    this.labels = createLabelHelper(`mat.wizards.import.files.importCutPlanWithRoll.`);
    this.columnsConfig = this.createColumnsConfig();
  }

  componentDidMount() {
    this.setIsRowDiabled();
  }

  createColumnsConfig = () => {
    let columnsConfig =  [
      {
        fieldName: 'nestBusinessId',
        title: this.gridLabels.get('columns.nest.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelCell,
          valueGetter: (params) => {
            return {errorMessage: this.getNestErrorMessage(params.data) ,value: params.data.nestBusinessId, showOnlyError: false};
          },
        }
      },
      {
        fieldName: 'materialBusinessId',
        title: this.gridLabels.get('columns.material.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelCell,
          valueGetter: (params) => {
            return {errorMessage: params.data.errorMaterial ,value: params.data.materialBusinessId, showOnlyError: true};
          },
          sortable: false,
        }
      },
      {
        fieldName: 'rollBusinessId',
        width:160,
        title: this.gridLabels.get('columns.rollId.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ComponentWithErrorCell,
          valueGetter: (params) => {
            return {errorMessage: params.data.errorRoll,value: params.data.rollBusinessId ,params: {change:this.props.change, enabled: !params.data.isRowDisabled ,value: params.data.rollBusinessId , rowIndex: params.node.rowIndex,row: params,selected:params.data.selected}, componentType: editableViewCell};
          },
          editable: (params) => {
            return !params.data.isRowDisabled
          },
          cellEditor:'editableCell',
          cellEditorParams: {
            inputType:'string',
          },
          onCellValueChanged: function (params) {
            params.data.nestWithRollFromFile.map((nestWithRoll) => {
              nestWithRoll.rollBusinessId = params.newValue.value;
            })
          },
          sortable: false,
        }
      },
     {
        fieldName: 'rollLength',
        title: `${this.gridLabels.get('columns.rollLength.title')}(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`,
        filterType: filterTypes.NONE,
       columnOptions: {
         cellComponent: ComponentWithErrorCell,
         valueGetter: (params) => {
           return {
             errorMessage: params.data.errorRollLength,
             value: params.data.rollLength,
             params: {
               change:this.props.change,
               enabled: !params.data.isRowDisabled,
               value: (params.data.errorRoll ? null : params.data.rollLength),
               rowIndex: params.node.rowIndex,
             },
             componentType: editableViewCell};
         },
         editable: (params) => {
           return !params.data.isRowDisabled
         },
         cellEditor:'editableCell',
         cellEditorParams: {
           inputType: 'numeric',
         },
         onCellValueChanged: function (params) {
           return params.data.nestWithRollFromFile.map((nestWithRoll) => {
             nestWithRoll.rollLength = params.newValue.value;
           })
         },
         sortable: false,
       }
      },
      {
        fieldName: 'rollWidth',
        title: `${this.gridLabels.get('columns.rollWidth.title')}(${UnitHelper.getLabelForUnitType(unitTypes.WIDTH)})`,
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ComponentWithErrorCell,
          valueGetter: (params) => {
            return {
              errorMessage: params.data.errorRollWidth,
              value: params.data.rollWidth,
              params: {
                change:this.props.change,
                enabled: !params.data.isRowDisabled,
                value: (params.data.errorRoll ? null : params.data.rollWidth),
                rowIndex: params.node.rowIndex
              },
              componentType: editableViewCell};
          },
          editable: (params) => {
            return !params.data.isRowDisabled
          },
          cellEditor:'editableCell',
          cellEditorParams: {
            inputType: 'numeric',
          },
          onCellValueChanged: function (params) {
            params.data.nestWithRollFromFile.map((nestWithRoll) => {
              nestWithRoll.rollWidth = params.newValue.value;
            })
          },
          sortable: false,
        }
      },
      {
        fieldName: 'nestWidth',
        title: `${this.gridLabels.get('columns.nestWidth.title')}(${UnitHelper.getLabelForUnitType(unitTypes.WIDTH)})`,
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelCell,
          valueGetter: (params) => {
            return {errorMessage: params.data.errorNestWidth ,value: params.data.nestWidth, showOnlyError: false};
          },
          sortable: false,
        }
      },
      {
        fieldName: 'expLengthCut',
        title: `${this.gridLabels.get('columns.expLengthCut.title')}(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`,
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelCell,
          headerComponentParams: {
            headerTooltip: `${this.gridLabels.get('columns.expLengthCut.tooltip')} (${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`,
          },
          valueGetter: (params) => {
            return {errorMessage: params.data.errorNestWidth ,value: params.data.expectedLengthCut, showOnlyError: false};
          },
          sortable: false,
        }
      },
      {
        fieldName: 'lengthCut',
        title: `${this.gridLabels.get('columns.actLengthCut.title')}(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`,
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ComponentWithErrorCell,
          headerComponentParams: {
            headerTooltip: `${this.gridLabels.get('columns.actLengthCut.tooltip')} (${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`,
          },
          valueGetter: (params) => {
            return {
              errorMessage: params.data.errorNestLengthCut,
              value: params.data.lengthCut,
              params: {
                change:this.props.change,
                enabled: !params.data.isRowDisabled,
                value: params.data.lengthCut,
                rowIndex: params.node.rowIndex
              },
              componentType: editableViewCell
            };
          },
          editable: (params) => {
            return !params.data.isRowDisabled
          },
          cellEditor:'editableCell',
          cellEditorParams: (params) => ({
            inputType: 'numeric',
          }),
          onCellValueChanged: this.actualCutLengthValueChanged,
          sortable: false,
        }
      },
      ...(PermissionManager.hasScrapViewPermissions() ? [{
        fieldName: 'scrap',
        title: `${this.gridLabels.get('columns.scrapLength.title')}(${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`,
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: ComponentWithErrorCell,
          valueGetter: (params) => {
            return {
              errorMessage: params.data.errorNestScrap,
              value: params.data.scrap,
              params: {
                change:this.props.change,
                enabled: !params.data.isRowDisabled,
                value: params.data.scrap,
                rowIndex: params.node.rowIndex
              },
              componentType: editableViewCell
            };
          },
          editable: (params) => {
            return !params.data.isRowDisabled
          },
          cellEditor:'editableCell',
          cellEditorParams: {
            inputType: 'numeric',
          },
          onCellValueChanged:  (params) => {
            const { nestWithRollFromFile } = params.data;
            nestWithRollFromFile.map((nestWithRoll, index) => {
              const newValue = Number(params.newValue.value);
              nestWithRoll.scrap = newValue;
              nestWithRoll.materialScrapAmount = MaterialHelper.relativeMaterialUsageCalculation(newValue, nestWithRoll.relativeLength, nestWithRollFromFile.length);
              params.data.materialScrapAmount[index] = MaterialHelper.relativeMaterialUsageCalculation(newValue, nestWithRoll.relativeLength, nestWithRollFromFile.length);
            })
          },
          sortable: false,
        }
      }] : [])
    ];

    return columnsConfig;
  };

  actualCutLengthValueChanged = (params) => {
    if(params.newValue.value !== params.oldValue.value){
      const { nestWithRollFromFile } = params.data;
      nestWithRollFromFile.map((nestWithRoll, index) => {
        const newValue = Number(params.newValue.value);
        nestWithRoll.nestLengthCut = newValue;
        nestWithRoll.materialUsedAmount = MaterialHelper.relativeMaterialUsageCalculation(newValue, nestWithRoll.relativeLength, nestWithRollFromFile.length);
        params.data.materialUsedAmount[index] = MaterialHelper.relativeMaterialUsageCalculation(newValue, nestWithRoll.relativeLength, nestWithRollFromFile.length);

        if(PermissionManager.hasScrapViewPermissions()){
          const newScrap = params.data.expectedLengthCut - newValue;
          params.data.scrap = newScrap > 0 ? newScrap : 0;
          nestWithRoll.materialScrapAmount = newScrap > 0 ? MaterialHelper.relativeMaterialUsageCalculation(newScrap, nestWithRoll.relativeLength, nestWithRollFromFile.length) : 0;
          params.data.materialScrapAmount[index] = newScrap > 0 ? MaterialHelper.relativeMaterialUsageCalculation(newScrap, nestWithRoll.relativeLength, nestWithRollFromFile.length) : 0;
        }
      })
      // refresh grid so changes that affect other cells are visible.
      this.gridRef?.api?.refreshCells()
    }
  }

  getNestErrorMessage = (data) => {
    let error;
    if(data.errorPlan){
      error = data.errorPlan
    }
    if(data.errorFoUniqueId){
      error += error ? `${', '} ${ data.errorFoUniqueId}` : data.errorFoUniqueId;
    }
    return error;
  };

  setIsRowDiabled = () => {
    if(this.props.selectedNests) {
      this.props.selectedNests.map((obj) => {
        obj.isRowDisabled = obj.hasNestError;
      });
    }
  };

  goOn = () => {
    var selectedNests = this.props.nestsItems.filter((nest) => {
      let isSelected = this.props.selectedNests.findIndex((selectedNest) => selectedNest.nestBusinessId === nest.nestBusinessId) != -1;
      nest.selected = isSelected;
      return isSelected;
    });
    this.props.actions.goOn(this.props.sSelectedFile ,selectedNests)
  };

  goBack = () => {
    let wizardActions = this.props.wizardActions;
    wizardActions.previous();
  };

  onHide = () => {
    this.props.actions.onWizardClose();
    let wizardActions = this.props.wizardActions;
    wizardActions.close();
  };

  getDialogButtons() {
    let buttonOK = {
      id:'submit',
      text: this.labels.get('footer.next'),
      bsStyle: 'primary',
      disabled: (this.props.sSelectedFile && !this.props.sNestsErrors && this.props.selectedNests.size > 0) ? this.props.loading : true,
      loading: this.props.loading,
      action: this.props.handleSubmit(this.goOn)
    };

    let buttonCancel = {
      id:'cancel',
      text: this.labels.get('footer.back'),
      action: this.goBack
    };

    return { left: [buttonCancel], right: [buttonOK] };
  }

  renderErrors = () => {
    if(this.props.sNestsErrors){
       return this.renderErrorSelection(this.props.sNestsErrors);
    }
    return null;
  }

  renderErrorSelection = (data) => {
     let icon = 'pl pl-warning-icon';
     let errorType = data.statusType;
      return (
        <div className="error-selection">
          <div className='error-item'>
              <span className={`error-icon ${icon} ${errorType}`}></span>
              <label className='error-msg'>{data.message}</label>
          </div>
        </div>
      );
  };

  // needed for updating grid on cell changes that affect other cells.
  setGridRef = (gridRef) => {
    this.gridRef = gridRef;
  }

  render() {
    let animation = this.props.isFirstLaunch;
    let plan = this.props?.nestsItems[0]?.nestWithRollFromFile[0]?.plan;
    return (
      <Dialog
        id="select-nests-dialog"
        className="select-nests-dialog"
        animation={animation}
        show={true}
        onHide={this.onHide}
        titleText= {this.labels.get('selectnests.title')}
        moreInfoTextHeader={`${this.labels.get('filename.title')}: ${this.props.sSelectedFile ? this.props.sSelectedFile.name : ''}`}
        footerButtons={this.getDialogButtons()}
        handleHashChange={false}
      >
        <div>
          <Label text={`${this.labels.get('plan.title')}: ${plan ? plan : ''}`} tooltip={plan ? plan : ''}/>
        </div>

        <div className="select-nests-grid">
          <Grid gridName={gridsNames.IMPORT_NESTS_WITH_ROLL_SELECT_NESTS}
                columnsConfig={this.columnsConfig}
                rows={this.props.nestsItems}
                isStatic = {true}
                gridProps={
                  {
                    shouldPreSelectRows:true,
                    setRef: this.setGridRef,
                    sideBar: false,
                  }
                }>
          </Grid>
        </div>

        {this.renderErrors()}

      </Dialog>
    );
  }
}

export default reduxForm({
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: false
  }
)(SelectNestsFirstStep);

SelectNestsFirstStep.propTypes = {
};
