import { Map } from 'immutable';
import { actionTypes as commonAlertsViewActions } from '../../../actions/Administration/SettingsPage/commonAlertsViewActions';

let defaultState = {
  loading : false,
  rollData : null,
  spoolData : null,
  resinData : null,
  kitData : null,
  groupData : null,
  toolData : null,
  taskData: null,
  misplacedMaterialData: null,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_SET_BUSY:
      return state.set('loading', action.payload);

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_FETCH_DATA_FINISHED:
      state = state.set('rollData', action?.payload?.rollData);
      state = state.set('spoolData', action?.payload?.spoolData);
      state = state.set('resinData', action?.payload?.resinData);
      state = state.set('kitData', action?.payload?.kitData);
      state = state.set('groupData', action?.payload?.groupData);
      state = state.set('toolData', action?.payload?.toolData);
      state = state.set('taskData', action?.payload?.taskData);
      state = state.set('misplacedMaterialData', action?.payload?.misplacedMaterialData);
      return state;

    case commonAlertsViewActions.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UNMOUNT:
      return Map(defaultState);

    default:
      return state;
  }
}












