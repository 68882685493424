import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import * as CutLengthHelper from '../../../../../../utils/cutLengthHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';

import'./subRollListItem.scss';

export default class SubRollListItem extends React.PureComponent {

  onClickCallback = () => {
    if (this.props.onGenerateClickCallback) {
      this.props.onGenerateClickCallback(this.props.index);
    }
  };

  onChangeCallback = (value) => {
    if (this.props.onQuantityChangedCallback) {
      this.props.onQuantityChangedCallback(value);
    }
  };

  maxLength50 = Validation.maxLength(50);

  renderLengthOrWeight=(name)=>{
    if(PermissionManager.isWeightSupported()){
      return ( <TextField id={ name + '.[weight]'}
                          name={ name + '.[weight]'}
                          className="short-textfield"
                          normalize={CutLengthHelper.normalizeFloat(0, parseFloat(this.props.quantityLeft))}
                          validate={CutLengthHelper.validateFloat }
                          onChangeCallback={this.onChangeCallback}
      />)
    }

    return ( <TextField id={ name + '.[cutLength]'}
                        name={ name + '.[cutLength]'}
                        className="short-textfield"
                        normalize={CutLengthHelper.normalizeFloat(0, parseFloat(this.props.quantityLeft))}
                        validate={CutLengthHelper.validateFloat }
                        onChangeCallback={this.onChangeCallback}
    />)
  };


  render() {
    let {name} = this.props;

    return (<div className="subroll-list-item">
      <GenerateButton id={ name + '.[subRoll]'}
                      name={ name + '.[subRoll]'}
                      className="rollId"
                      placeholder={this.props.labels.get('subrollslist.item.placeholder')}
                      validate={[Validation.required, this.maxLength50]}
                      onClick={this.onClickCallback}
                      buttonText={this.props.labels.get('subrollslist.item.button.generate')}
      />

      {this.renderLengthOrWeight(name)}
      </div>);
  }
}

SubRollListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};




