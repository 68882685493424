import * as partsService from '../../services/Parts/partsService';
import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {enumTypes, getEnumLabel} from '../../utils/enumHelper';
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ARCHIVE_PARTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return partsService.fetchArchivedParts(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj, index) => {
    obj.m_archivingDate = DateTimeHelper.FormatDateObjectToDayMonth(obj.lastStatusChangeDate);
    obj.assetStatusLabel = getEnumLabel(enumTypes.ASSET_STATUS)(obj.assetStatus);

    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }
    
    return obj;
  });

  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
