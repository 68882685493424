import {api as wizardApi} from 'infrastructure/js/components/Wizard/wizardActions';
import importFilesService from '../../../services/ImportFiles/importFilesService';
import { api as messageDialogApi }      from '../../MessageDialog/messageDialogActions';
import { api as locationPageApi } from '../../LocationPage/locationPageActions';
import { api as kittingApi } from '../../LocationPage/KittingView/kittingViewActions';
import Network from 'infrastructure/js/modules/network';
import importFilesHelper from '../../../utils/importFilesHelper';
import * as ImportResponseHelper from '../Common/ImportResponseHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import {ImportTypes} from '../../../enums/importTypes';
import {api as exportApi} from '../../Export/exportActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

let importFilesWizardApi = wizardApi.createWizardSpecificAdapter('IMPORT_FILES_WIZARD');

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  IMPORT_FILES_SAVE_START_PARAMETERS: 'IMPORT_FILES_SAVE_START_PARAMETERS',
  IMPORT_FILES_CLEAR_PARAMETERS :'IMPORT_FILES_CLEAR_PARAMETERS',

  IMPORT_FILES_HAS_ERRORS: 'IMPORT_FILES_HAS_ERRORS',

  IMPORT_FILES_UPLOAD_FILE_IN_PROGRESS: 'IMPORT_FILES_UPLOAD_FILE_IN_PROGRESS',
  IMPORT_FILES_UPLOAD_FILE_FINISHED: 'IMPORT_FILES_UPLOAD_FILE_FINISHED',
  IMPORT_FILES_UPLOAD_FILE_FAILED: 'IMPORT_FILES_UPLOAD_FILE_FAILED',

  IMPORT_FILES_VALIDATE_FILE_IN_PROGRESS: 'IMPORT_FILES_VALIDATE_FILE_IN_PROGRESS',
  IMPORT_FILES_VALIDATE_FILE_FINISHED: 'IMPORT_FILES_VALIDATE_FILE_FINISHED',
  IMPORT_FILES_VALIDATE_FILE_FAILED: 'IMPORT_FILES_VALIDATE_FILE_FAILED',

  IMPORT_FILES_IMPORT_FILE_IN_PROGRESS: 'IMPORT_FILES_IMPORT_FILE_IN_PROGRESS',
  IMPORT_FILES_IMPORT_FILE_FINISHED: 'IMPORT_FILES_IMPORT_FILE_FINISHED',
  IMPORT_FILES_IMPORT_FILE_FAILED: 'IMPORT_FILES_IMPORT_FILE_FAILED',

  IMPORT_FILES_SELECTED_FILE_CHANGED: 'IMPORT_FILES_SELECTED_FILE_CHANGED',
  IMPORT_FILES_SELECTED_ASSET_TYPE_CHANGED: 'IMPORT_FILES_SELECTED_ASSET_TYPE_CHANGED',

  IMPORT_FILES_WITH_PRINT_LABELS_FAILED: 'IMPORT_FILES_WITH_PRINT_LABELS_FAILED'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  saveStartParameters: function (payload) {
    return {type: actionTypes.IMPORT_FILES_SAVE_START_PARAMETERS, payload: payload};
  },
  clearParameters: function (payload) {
    return {type: actionTypes.IMPORT_FILES_CLEAR_PARAMETERS, payload: payload};
  },
  showErrors: function (payload) {
    return {type: actionTypes.IMPORT_FILES_HAS_ERRORS, payload: payload};
  },
  uploadFileInProgress: function () {
    return {type: actionTypes.IMPORT_FILES_UPLOAD_FILE_IN_PROGRESS};
  },
  uploadFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_FILES_UPLOAD_FILE_FINISHED, payload: payload};
  },
  uploadFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_FILES_UPLOAD_FILE_FAILED, payload: payload};
  },
  validateFileInProgress: function () {
    return {type: actionTypes.IMPORT_FILES_VALIDATE_FILE_IN_PROGRESS};
  },
  validateFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_FILES_VALIDATE_FILE_FINISHED, payload: payload};
  },
  validateFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_FILES_VALIDATE_FILE_FAILED, payload: payload};
  },
  importFileInProgress: function () {
    return {type: actionTypes.IMPORT_FILES_IMPORT_FILE_IN_PROGRESS};
  },
  importFileFinished: function (payload) {
    return {type: actionTypes.IMPORT_FILES_IMPORT_FILE_FINISHED, payload: payload};
  },
  importFileFailed: function (payload) {
    return {type: actionTypes.IMPORT_FILES_IMPORT_FILE_FAILED, payload: payload};
  },
  importFileWithPrintLabelsFailed: function (payload) {
    return {type: actionTypes.IMPORT_FILES_WITH_PRINT_LABELS_FAILED, payload: payload};
  },
  onSelectedAssetTypeChanged: function (payload) {
    return {type: actionTypes.IMPORT_FILES_SELECTED_ASSET_TYPE_CHANGED, payload: payload};
  },
  selectedFileChanged: function (payload) {
    return {type: actionTypes.IMPORT_FILES_SELECTED_FILE_CHANGED, payload: payload};
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.openWizard = function (config) {
  return function (dispatch, getState) {
    api.openImportFilesWizard(dispatch, getState)(config);
  }
};

jsxActions.onWizardClose = function () {
  return function (dispatch, getState) {
    dispatch(actions.clearParameters());
  }
};

jsxActions.uploadFile = function (file, assetType, shouldIncludeLocation) {
  return function (dispatch, getState) {
    api.uploadFile(dispatch, getState)(file, assetType,shouldIncludeLocation);
  }
};

jsxActions.importFile = function (data, messageDialogBuilder, reloadParentComponent) {
  return function (dispatch, getState) {
    api.importFile(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
  }
};

// jsxActions.importFileWithProgress = function (data, messageDialogBuilder, reloadParentComponent) {
//   return function (dispatch, getState) {
//     api.importFileWithProgress(dispatch, getState)( data, messageDialogBuilder, reloadParentComponent);
//   }
// };

jsxActions.hasErrors = function (data) {
  return function (dispatch, getState) {
    api.showErrors(dispatch, getState)(data);
  }
};

jsxActions.onSelectedAssetTypeChanged = function (data) {
  return function (dispatch, getState) {
    api.selectedAssetTypeChanged(dispatch, getState)(data);
  }
};

jsxActions.onSelectedFileChanged = function (file) {
  return function (dispatch, getState) {
    dispatch(actions.selectedFileChanged(file));
  }
};

jsxActions.exportValidationReport = function (data) {
  return function (dispatch, getState) {
    api.exportValidationReport(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.selectedAssetTypeChanged = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.onSelectedAssetTypeChanged(data));
  }
};


api.openImportFilesWizard = function (dispatch, getState) {
  return function (config) {
    if(config.importTypes && config.importTypes[0] === 'kits'){
      importFilesService.checkCutAndKitExistence().then((response) => {
        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          return;
        }

        let error = response?.applicationResponseStatus?.errors?.subErrors[0];
        if(error){
          let messageDialogDescriptor = dialogHelper.getMessageDialogDescriptorByErrorCode(error.id);
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
          return;
        }

        dispatch(actions.saveStartParameters(config));
        importFilesWizardApi.start(dispatch, getState)();
      });
    }
    else{
      dispatch(actions.saveStartParameters(config));
      importFilesWizardApi.start(dispatch, getState)();
    }
  }
};

api.showErrors = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.showErrors(data));
  }
};

api.uploadFile = function (dispatch, getState) {
  return function (file, assetType,shouldIncludeLocation) {
    dispatch(actions.uploadFileInProgress());

    let promiseUploadFile = importFilesService.uploadFile(file)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.uploadFileFailed(response));
          dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Upload File Failed', response);
          return null;
        }
        dispatch(actions.uploadFileFinished(response.data));

        return response.data;
      });

    return promiseUploadFile.then((res) => {
      return res ? api.validateFile(dispatch, getState)(res.data, assetType,shouldIncludeLocation) : null;
    })

  }
};

api.validateFile = function (dispatch, getState) {
  return function (token, assetType, shouldIncludeLocation) {
    if (assetType === ImportTypes.IMPORT_OPERATION_SEQUENCE) {
      return api.validateFileAsync(dispatch, getState)(token, assetType);
    }

    dispatch(actions.validateFileInProgress());
    let locationId =  locationPageApi.getLocationId(dispatch, getState)();
    let currentCutId = kittingApi.getCurrentCutId(dispatch, getState)();
    let queryData = importFilesHelper.getQueryDataForValidate(assetType , token , locationId , currentCutId, shouldIncludeLocation);
    importFilesService.validateFile(queryData, assetType)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.validateFileFailed());
          dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('File validation failed', response);
          return null;
        }
        dispatch(actions.validateFileFinished({data: response.data, token: token}));
        importFilesWizardApi.gotoStep(dispatch, getState)('step_2');
      });
  }
};

api.validateFileAsync = function (dispatch, getState) {
  return function (token, assetType) {
    dispatch(actions.validateFileInProgress());
    let queryData = importFilesHelper.getQueryDataForValidate(assetType , token);

    importFilesService.validateFileAsync(queryData, assetType)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.validateFileFailed());
          dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('File validation failed', response);
          return null;
        }
        if (response.data.generalError || response.data.jobStatus !== 'COMPLETED') {
          dispatch(actions.validateFileFailed());
          _handleValidationError(dispatch, getState)(response);
          return null;
        }
        let validationJobId = response.data.jobExecutionId;
        importFilesService.getValidationInfo( {validationJobId} )
          .then((response2) => {
            if (!Network.isResponseValid(response2)) {
              dispatch(actions.validateFileFailed());
              messageDialogApi.responseError(dispatch, getState)(response2);
              console.error('Get File validation info failed', response2);
              return null;
            }

            dispatch(actions.validateFileFinished({data: response2.data, token, validationJobId}));
            importFilesWizardApi.gotoStep(dispatch, getState)('step_2');
          });
      });
  }
};


api.exportValidationReport = function (dispatch, getState) {
  return function (data) {
    let validationJobId = getState().importFiles.get('validationJobId');
    return exportApi.exportValidationReportOfOperationSequence(dispatch, getState)(validationJobId);
  }
};

api.importFile = function (dispatch, getState) {
  return function (data, messageDialogBuilder, reloadParentComponent) {
    if (data.entityType === ImportTypes.IMPORT_OPERATION_SEQUENCE) {
      return api.importFileAsync(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
    }

    dispatch(actions.importFileInProgress());
    let locationId =  locationPageApi.getLocationId(dispatch, getState)();
    let currentCutId = kittingApi.getCurrentCutId(dispatch, getState)();

    let queryData = importFilesHelper.getQueryDataForImport(data.userFileName, data.token , data.printLabels , data.tagsCount, locationId , data.entityType ,currentCutId , data.shouldIncludeLocation);
    importFilesService.importFile(queryData, data.entityType)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.importFileFailed(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Import File Failed' + ' '  + response);
          return null;
        }

        if(data.printLabels){
          let isPrintLabelsResponseValid = utility.HandlePrintLabelsResponseStatus(response , dispatch);
          if(!isPrintLabelsResponseValid){
            return null;
          }
        }

        dispatch(actions.importFileFinished(response.data));

        importFilesWizardApi.close(dispatch, getState)();

        if (reloadParentComponent) {
          reloadParentComponent(dispatch, getState);
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );

          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      });
  }
};

api.importFileAsync = function (dispatch, getState) {
  return function (data, messageDialogBuilder, reloadParentComponent) {
    let jobExecutionId = getState().importFiles.get('validationJobId');
    importFilesService.importFile({jobId: jobExecutionId}, data.entityType)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.importFileFailed(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Import File Failed' + ' '  + response);
          return null;
        }
        dispatch(actions.importFileFinished(response.data));

        importFilesWizardApi.close(dispatch, getState)();

        if (reloadParentComponent) {
          reloadParentComponent(dispatch, getState);
        }

        if (messageDialogBuilder) {
          // the actionType is used for getting the relevant message (bug 3626)
          response.data.actionType = 'updated';
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );
          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }
      });
  }
};

// api.importFileWithProgress = function (dispatch, getState) {
//   return function (data, messageDialogBuilder, reloadParentComponent) {
//     dispatch(actions.importFileInProgress());
//
//     let locationId =  locationPageApi.getLocationId(dispatch, getState)();
//     let currentCutId = kittingApi.getCurrentCutId(dispatch, getState)();
//
//     let queryData = importFilesHelper.getQueryDataForImport(data.userFileName, data.token , data.printLabels , locationId , data.entityType ,currentCutId , data.shouldIncludeLocation);
//
//     importFilesService.startImportFile(queryData, data.entityType)
//       .then((response) => {
//         if (!Network.isResponseValid(response)) {
//           dispatch(actions.importFileFailed(ImportResponseHelper.NormalizeResponseError(response)));
//           console.error('Import File Failed' + ' '  + response);
//           return null;
//         }
//         dispatch(actions.importFileFinished(response.data));
//
//         importFilesWizardApi.close(dispatch, getState)();
//
//         let pollingRequest = 'batchJobs/JOB_EXECUTION_ID/status-with-printing';
//
//         batchJobsApi.runJob(dispatch, getState)(response.data, {pollingRequest})
//           .then((jobResponse) => {
//
//             if (data.printLabels) {
//               let isPrintLabelsResponseValid = utility.HandlePrintLabelsResponseStatus(jobResponse , dispatch);
//               if(!isPrintLabelsResponseValid){
//                 //return null; //TODO: L what should be done here? May should define it
//               }
//             }
//             let runningInBackground = (jobResponse && jobResponse.data && jobResponse.data.runningInBackground);
//             if (reloadParentComponent) {
//               reloadParentComponent(dispatch, getState);
//             }
//
//             if (!runningInBackground && messageDialogBuilder) {
//               let messageDialogDescriptor = messageDialogBuilder(jobResponse.data);
//               messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
//             }
//           })
//           .catch (error => {
//             console.error('importFile.runJob failed, catch error: ', error);
//           });
//
//       });
//   }
// };


let utility = {};

utility.HandlePrintLabelsResponseStatus = function (response, dispatch) {
  if(!utility.isPrintLabelsResponseValid(response)) {
    dispatch(actions.importFileWithPrintLabelsFailed(utility.NormalizePrintLabelsResponseError(response)));
    dispatch(actions.importFileFailed(utility.NormalizePrintLabelsResponseError(response)));
    console.error('Import File With Print Labels Failed' + ' ' + response);
    return false;
  }

  return true;
};

//Check if the response of the batch jobStatus is valid.
utility.isPrintLabelsResponseValid = function (response){
  try {
    if (response === null || response === undefined) {
      return false;
    }

    let statusType = response.data.jobStatus;

    if (statusType !== 'COMPLETED') {
      return false;
    }
    return true;
  }
  catch(e){
    return false;
  }
};

utility.NormalizePrintLabelsResponseError = function (responseError) {
  return {
    message: responseError.data.printerError,
    statusType: 'warning',
    errorCode: responseError.data.printerErrorCode
  };
};

///////////////////////////////////////////////////////////////////

function _handleValidationError (dispatch, getState) {
  return function (response) {
    if (response && response.data && response.data.generalError) {
      let generalErrorLabels = createLabelHelper('mat.wizards.import.files.kittypesoperationssequence.validation.grid.operationstatus.');
      let {generalError} = response.data;

      switch (generalError.labelType) {
        case 'STRING':
          dispatch(actions.showErrors({
            message: generalErrorLabels.get(generalError.labelKey, undefined, generalError.params),
            statusType: 'error'
          }));
          return;
        default:
          console.error('ImportFile unknown generalError labelType: ' + generalError.labelType);
      }
    }

    if (response.data.jobStatus !== 'COMPLETED') {
      console.error('File validation failed', response);
      messageDialogApi.showError(dispatch, getState)();
    }
  }
}
