import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import useRedux from 'infrastructure/js/hooks/useRedux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';
import Button from 'infrastructure/js/components/controls/Button/button';

import { jsxActions as headerActions } from '../../../actions/Mobile/Header/headerActions.js';
import { jsxActions as schedulerGanttPageActions } from '../../../actions/SchedulerPage/schedulerGanttPageActions.js';
import { jsxActions as scanWorkOrderPageActions } from '../../../actions/Mobile/ScanWorkOrderPage/scanWorkOrderPageActions.js';
import ScanHeader from '../Common/ScanHeader/scanHeader.js';
import ScanDetails from '../Common/ScanDetails/scanDetails.js';
import {
  getButtonStyles,
  getButtonsDataByStatus,
  getEventFormattedComponents,
  getModalHeaderByStatus,
  getPageTitle,
} from './utils/taskPageHelper.js';
import MobileModal from '../Common/MobileModal/mobileModal.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import {reduxForm} from 'redux-form';
import DatepickerMobile from 'infrastructure/js/components/controls/DatePickerMobile/datepickerMobile';
import moment from 'moment';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

import './taskPage.scss';

const getState = (state) => {
  return {
    tasks: state.mobile.get('scanWorkOrderPage').get('woTasks') ?? [],
    loading: state.mobile.get('scanWorkOrderPage').get('loading'),
    selectedStation: state.mobile.get('scanWorkOrderPage').get('selectedStation'),
    selectedWorkOrder: state.mobile.get('scanWorkOrderPage').get('selectedWorkOrder'),
  };
};

const allActions = {
  header: headerActions,
  gantt: schedulerGanttPageActions,
  scanWorkOrderPageActions,
};

const DEFAULT_QUANTITY = 1;

const buttonsLabels = createLabelHelper('mat.mobile.taskPage.modal.buttons.button.');
const labels = createLabelHelper('mat.mobile.taskPage.helper.field.');

const TaskPage = ({ history, change, handleSubmit }) => {
  const { state, actions } = useRedux(allActions, getState);
  const { tasks, loading } = state;
  const { taskId } = useParams();
  const [activeTask, setActiveTask] = useState(null);
  const [showStartModal, setShowStartModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showCompletedAtModal, setShowCompletedAtModal] = useState(false);
  const [showStartedAtModal, setShowStartedAtModal] = useState(false);

  const [maxTime, setMaxTime] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (!taskId || !tasks.length) {
      actions.header.onGoBack(history);
    } else {
      const task = tasks.find(({ task }) => `${task.id}` === taskId);
      if (task) {
        setActiveTask(task);
        change('completedQuantity', task?.task?.quantity ?? DEFAULT_QUANTITY);
      } else {
        alert('unknown task Id!');
        actions.header.onGoBack(history);
      }
    }
  }, [taskId, actions, tasks, history, loading]);

  const taskInfo = useMemo(() => {
    const data = getEventFormattedComponents(activeTask);
    return data
      .filter(({ fieldValue }) => !!fieldValue)
      .map(({ fieldName, fieldValue }) => {
        return { label: fieldName, body: <strong>{fieldValue}</strong> };
      });
  }, [activeTask]);

  const headerItems = {
    leftButton: {
      id: 'leftButton',
      icon: 'pl pl-arrow-left',
      action: actions.header.onGoBack,
      actionData: history,
    },
    middleButton: {
      id: 'middleButton',
      label: getPageTitle(activeTask),
    },
    rightButton: null,
  };

  const handleShowStartModal = useCallback((show = true) => {
    setShowStartModal(show);
  }, []);

  const handleShowStartAtModal = useCallback((show = true) => {
    if (show) {
      resetReportedAt();
    }
    setShowStartedAtModal(show);
  }, []);

  const handleShowCompleteModal = useCallback((show = true) => {
    setShowCompleteModal(show);
  }, []);

  const handleShowCompleteAtModal = useCallback((show = true) => {
    if (show) {
      resetReportedAt();
    }
    setShowCompletedAtModal(show);
  }, []);

  const resetReportedAt = useCallback (() => {
    change('reportedAt', null);
    setSelectedDate(null);
  }, []);

  const handleReportTaskProgress = (newStatus, isReportAsPlanned, data = null, startTime=null, endTime=null) => {
    const { selectedWorkOrder, selectedStation } = state;
    actions.gantt.reportTaskProgress({ taskData: activeTask, status: newStatus, isReportAsPlanned, reportData: data, startTime, endTime }).then(() => {
      actions.scanWorkOrderPageActions.submit(selectedWorkOrder, selectedStation);
    });
    handleShowStartModal(false);
    handleShowStartAtModal(false);
    handleShowCompleteModal(false);
    handleShowCompleteAtModal(false);
    setSelectedDate(null);
  };

  const handleStartNowClick = () => {
    handleReportTaskProgress('STARTED', false);
  };
  const handleStartAsPlannedClick = () => {
    handleReportTaskProgress('STARTED', true);
  };

  const handleStartAtClick = (data) => {
    const selectedDate = DateTimeHelper.ConvertFromDate(data.reportedAt);
    handleReportTaskProgress('STARTED', false, null, selectedDate);
  };

  const handleCompleteNowClick = (data) => {
    handleReportTaskProgress('COMPLETED', false, data);
  };
  const handleCompleteAsPlannedClick = (data) => {
    handleReportTaskProgress('COMPLETED', true, data);
  };

  const handleCompleteAtClick = (data) => {
    const selectedDate = DateTimeHelper.ConvertFromDate(data.reportedAt);
    handleReportTaskProgress('COMPLETED', false, data, null, selectedDate);
  };


  const bottomButtons = useMemo(() => {
    if (!activeTask) {
      return [];
    }
    const taskStatus = activeTask.operationStatus;
    const buttonData = getButtonsDataByStatus(taskStatus, { complete: handleShowCompleteModal, start: handleShowStartModal });

    return buttonData.map(({ id, action, label, style }) => {
      return (
        <Button key={id} id={id} className="task-page-button" bsStyle="primary" style={style} onClick={() => action()}>
          {label ?? ''}
        </Button>
      );
    });
  }, [activeTask, handleShowCompleteModal, handleShowStartModal]);

  const { scheduled } = getButtonStyles();

  const handleDateChange = (date) => {

    if (!date) {
      setSelectedDate(null);
      return;
    }

    if (!date?._isValid) {
      return;
    }

    setSelectedDate(date);

    if (moment().isSame(date, 'day')) {
      setMaxTime(moment());
      return;
    }

    setMaxTime(moment({ hour: 23, minute: 59 }));
  };

  const renderReportAt = (submitMethod) => {
    return (
      <>
        <DatepickerMobile
          id="reportedAt"
          name="reportedAt"
          maxDate={moment()}
          maxTime={maxTime}
          minTime={moment({ hour: 0, minute: 0 })}
          dateFormat={DateTimeHelper.getDateTimeFormat()}
          onChangeCallback={handleDateChange}
          timeIntervals={1}
          isClearable={true}
          selected={selectedDate}
        />
        <Button id="update" className="border-btn main-menu-item update-btn" disabled={!selectedDate}
                onClick={handleSubmit(submitMethod)}>
          {buttonsLabels.get('update')}
        </Button>
      </>
    )
  }

  if (!activeTask) {
    return null;
  }

  return (
    <div className="task-page">
      <ScanHeader {...headerItems} />
      <div className="task-page-task-info">
        <ScanDetails details={taskInfo} />
      </div>
      <div className="task-page-buttons">{bottomButtons}</div>

      <MobileModal
        show={showStartModal}
        header={getModalHeaderByStatus('start')}
        footer={
          <Button
            id="cancel-start"
            className="task-page-modal-button"
            style={scheduled}
            onClick={() => {
              handleShowStartModal(false);
            }}
          >
            {buttonsLabels.get('cancel')}
          </Button>
        }
      >
        <Button id="start-now" className="border-btn main-menu-item" onClick={handleStartNowClick}>
          {buttonsLabels.get('startNow')}
        </Button>
        <Button id="start-as-planned" className="border-btn main-menu-item" onClick={handleStartAsPlannedClick}>
          {buttonsLabels.get('startAsPlanned')}
        </Button>
        <Button id="start-at" className="border-btn main-menu-item" onClick={() => {handleShowStartAtModal(true); handleShowStartModal(false);}}>
          {buttonsLabels.get('startAt')}
        </Button>
      </MobileModal>


      <MobileModal
        show={showStartedAtModal}
        header={getModalHeaderByStatus('startAt')}
        footer={
          <Button
            id="back"
            className="task-page-modal-button"
            style={scheduled}
            onClick={() => {
              handleShowStartAtModal(false);
              handleShowStartModal(true);
            }}
          >
            {buttonsLabels.get('back')}
          </Button>
        }
      >
        {renderReportAt(handleStartAtClick)}
      </MobileModal>


      <MobileModal
        show={showCompleteModal}
        header={getModalHeaderByStatus('complete')}
        footer={
          <Button
            id="cancel-complete"
            className="task-page-modal-button"
            style={scheduled}
            onClick={() => {
              handleShowCompleteModal(false);
            }}
          >
            {buttonsLabels.get('cancel')}
          </Button>
        }
      >
        <Button id="complete-now" className="border-btn main-menu-item" onClick={handleSubmit(handleCompleteNowClick)}>
          {buttonsLabels.get('completeNow')}
        </Button>
        <Button id="complete-as-planned" className="border-btn main-menu-item" onClick={handleSubmit(handleCompleteAsPlannedClick)}>
          {buttonsLabels.get('completeAsPlanned')}
        </Button>
        <Button id="complete-at" className="border-btn main-menu-item" onClick={() => {handleShowCompleteAtModal(true); handleShowCompleteModal(false);}} >
          {buttonsLabels.get('completeAt')}
        </Button>
        <div className='completed-quantity-section'>
          <span className='completed-quantity__label'>{labels.get('completedQuantity')}</span>
          <div className='completed-quantity__value'>
            <TextField
              id="completedQuantity"
              name="completedQuantity"
              normalize={Normalize.number(true, 0, activeTask?.task?.quantity ?? DEFAULT_QUANTITY)}  //max should be task.quantity
            />
            <span>{`of ${activeTask?.task?.quantity ?? DEFAULT_QUANTITY}`}</span>
          </div>
        </div>
      </MobileModal>

      <MobileModal
        show={showCompletedAtModal}
        header={getModalHeaderByStatus('completeAt')}
        footer={
          <Button
          id="back"
          className="task-page-modal-button"
          style={scheduled}
          onClick={() => {
            handleShowCompleteAtModal(false);
            handleShowCompleteModal(true);
          }}
          >
        {buttonsLabels.get('back')}
          </Button>
        }
      >
        {renderReportAt(handleCompleteAtClick)}

      </MobileModal>
    </div>
  );
};

TaskPage.propTypes = {
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};

TaskPage.defaultProps = {
  history: null,
  handleSubmit: () => {
    console.error('TaskPage: handleSubmit is missing');
  },
  change: () => console.error('TaskPage: change is missing'),
};

export default reduxForm({
  form: 'taskPage',
})(TaskPage);
