import React from 'react';
import PropTypes from 'prop-types';

import ScheduleAction from '../../../modules/scheduleAction';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import UserManagementGrid from './UserManagementGrid/userManagementGrid';
import {UserAvailableActionsTypes} from '../../../enums/userAvailableActionsTypes';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

require('./userManagementPage.scss');

export default class UserManagementPage extends React.PureComponent{
  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.userSettings.userManagement.view.');
    this.dialogLabels = createLabelHelper('mat.dialogs.resetpassword.');
  }

  componentDidMount() {
    this.props.actions.init();
  }

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;

    let selectedRowsData = this.props.sSelectedRowsData;

    let actionConfig = {
      selectedUser:selectedRowsData.get(0),
      callbackAction: this.props.actions.reload,
      dialogLabels: this.dialogLabels
    };

    return {
      title: this.labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'createUser',
          label: headerItemsLabels.CREATE_USER,
          icon: 'pl pl-user-icon',
          action: actions.header.onCreateUserClick
        }
      ],
      kebab: {
        disabled: !hasRows,
        menuItems: [
          {
            id:'resetPassword',
            label: headerItemsLabels.RESET_PASSWORD,
            disabled: selectedRowsData.size !== 1 || !selectedRowsData.get(0).m_hasEditPermissions,
            action: actions.header.onMenuItemChangePasswordClick,
            actionData: actionConfig
          },
          {
            id:'unarchive',
            label: headerItemsLabels.UNARCHIVE,
            disabled: selectedRowsData.size !== 1  || this.isContainsAvailableAction(UserAvailableActionsTypes.UNARCHIVE) !== true || !selectedRowsData.get(0).m_hasEditPermissions,
            action: actions.header.onMenuItemChangeUsersStatusUnarchiveClick,
            actionData: actionConfig
          },
          {
            id:'archive',
            label: headerItemsLabels.ARCHIVE,
            disabled: selectedRowsData.size !== 1 || this.isContainsAvailableAction(UserAvailableActionsTypes.ARCHIVE) !== true || !selectedRowsData.get(0).m_hasEditPermissions || this.isInExpiredStatus(),
            action: actions.header.onMenuItemChangeUsersStatusArchiveClick,
            actionData: actionConfig
          },
          {
            id:'unlock',
            label: headerItemsLabels.UNLOCK,
            disabled: selectedRowsData.size !== 1 || this.isContainsAvailableAction(UserAvailableActionsTypes.UNLOCK) !== true || !selectedRowsData.get(0).m_hasEditPermissions || this.isInExpiredStatus(),
            action: actions.header.onMenuItemChangeUsersStatusUnlockClick,
            actionData: actionConfig
          }
        ]
      }
    };
  }

  isInExpiredStatus = () => {
    let selectedRowsData = this.props.sSelectedRowsData;
    if(selectedRowsData.size === 0) {return false};
    let res =  selectedRowsData.find((item) => {
      return item.status.name === 'CREDENTIALS_EXPIRED';
    });

    return !!res;
  }

  isContainsAvailableAction = (availableAction) => {
    let selectedRowsData = this.props.sSelectedRowsData;
    if(selectedRowsData.size === 0) {return false};
    let res =  selectedRowsData.filter((item) => {
      return item.availableActions.includes(availableAction);
    });

    return res.size > 0 ? true : false;
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="user-management-page">
          <Header {...headerItems} />
          <UserManagementGrid
            actions={{...this.props.actions.userManagementGridActions, ...this.props.actions.fetchEntitiesActions}} />
      </div>
    );
  }
};


UserManagementPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sUserManagementGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired
};
