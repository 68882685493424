import { useState } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

/**
 * Custom hook for managing labels.
 * Notice the use of a callback inside useState to avoid creating the labels object on every render.
 * Why not use useMemo with empty dependency list then? because its still not guaranteed that react will skip the useMemo callback.
 *
 * @param {string} key - The key used to create the label helper.
 * @returns {Object} - The labels object.
 */
export default function useLabels(key) {
  const [labels] = useState(() => createLabelHelper(key));
  return labels;
}
