import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Page from '../Common/Page/page';
import MaterialTypesViewContainer from '../../../containers/Administration/MatSettingsPage/materialTypesViewContainer.js';
import ToolTypesViewContainer from '../../../containers/Administration/MatSettingsPage/toolTypesViewContainer.js';
import KitTypesViewContainer from '../../../containers/Administration/MatSettingsPage/kitTypesViewContainer.js';
import PartTypesViewContainer from '../../../containers/Administration/MatSettingsPage/partTypesViewContainer';
import ProjectsViewContainer from '../../../containers/Administration/MatSettingsPage/projectsViewContainer';
import ShelfLifeUpdateViewContainer from '../../../containers/Administration/MatSettingsPage/Reasons/shelfLifeUpdateViewContainer';
import OperationsViewContainer from '../../../containers/Administration/MatSettingsPage/operationsViewContainer';
import ShiftTypesViewContainer from '../../../containers/Administration/MatSettingsPage/shiftTypesViewContainer';
import ShiftsTemplateViewContainer from '../../../containers/Administration/MatSettingsPage/shiftsTemplateViewContainer';
import WeeklyShiftsViewContainer from '../../../containers/Administration/MatSettingsPage/weeklyShiftsViewContainer';
import {MatSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import PageNotFoundPageContainer from '../../../containers/PageNotFoundPage/pageNotFoundPageContainer.js';
import GroupTypesViewContainer from '../../../containers/Administration/MatSettingsPage/groupTypesViewContainer';
import {getRoutesFor, getSideNavigatorMenuItems} from '../../../utils/routerHelper';
import AssetTypesViewContainer from '../../../containers/Administration/MatSettingsPage/assetTypesViewContainer';
import ToolCategoriesViewContainer from '../../../containers/Administration/MatSettingsPage/toolCategoriesViewContainer';
import DigitalTwinView from './DigitalTwinView/digitalTwinView';
import ToolGroupsView from './Tools/ToolGroupsView/toolGroupsView';

require('./matSettingsPage.scss');

export default class MatSettingsPage extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.sidenavigator.');

    this.isAuthorizedUser = PermissionManager.hasMatSettingsTabPermissions();
    this.navigationItems = this.getNavigationItems();
  }

  componentDidMount() {
    this.props.actions.init(true);
  }

  isExpanded(str) {
    return window.location.href.includes(str);
  }

  getNavigationItems() {
    let items = [];
    if(this.isAuthorizedUser) {
      items = [
        ...(PermissionManager.hasSchedulerAnalyticModePermissions() ? [  //TODO: L added temporarily according to this preference (for tests only)
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_ASSET_TYPES,
          label: this.labels.get('menuitem.assettypes'),
          link: '/Administration/matSettings/assetTypes',
          component: AssetTypesViewContainer,
          isAllowed: true
        }]: []),

        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_SHIFT_TYPES,
          label: this.labels.get('menuitem.shiftTypes'),
          link: '/Administration/matSettings/shiftTypes',
          component: ShiftTypesViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_SHIFTS_TEMPLATE,
          label: this.labels.get('menuitem.shiftsTemplate'),
          link: '/Administration/matSettings/shiftsTemplate',
          component: ShiftsTemplateViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_WEEKLY_SHIFTS,
          label: this.labels.get('menuitem.weeklyShifts'),
          link: '/Administration/matSettings/weeklyShifts',
          component: WeeklyShiftsViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_PROJECTS,
          label: this.labels.get('menuitem.projects'),
          link: '/Administration/matSettings/projects',
          component: ProjectsViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_PART_TYPES,
          label: this.labels.get('menuitem.parttypes'),
          link: '/Administration/matSettings/partTypes',
          component: PartTypesViewContainer,
          isAllowed: true,
        },
        ...(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled ? [
          {
            id : MatSettingsMenuItemsIds.NAV_ELEMENT_GROUP_TYPES,
            label: this.labels.get('menuitem.groupTypes'),
            link: '/Administration/matSettings/groupTypes',
            component: GroupTypesViewContainer,
            isAllowed: true,
          }]: []),

        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_MATERIAL_TYPES,
          label: this.labels.get('menuitem.materialtypes'),
          link: '/Administration/matSettings/materialTypes',
          component: MaterialTypesViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_KIT_TYPES,
          label: this.labels.get('menuitem.kittypes'),
          link: '/Administration/matSettings/kitTypes',
          component: KitTypesViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_OPERATIONS,
          label: this.labels.get('menuitem.operations'),
          link: '/Administration/matSettings/operations',
          component: OperationsViewContainer,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_TOOLS,
          label: this.labels.get('menuitem.tools'),
          expanded: this.isExpanded('/Administration/matSettings/tools'),
          isAllowed: true,
          items: [
            {
              id : MatSettingsMenuItemsIds.NAV_ELEMENT_TOOL_CATEGORIES,
              label: this.labels.get('menuitem.tools.children.toolCategories'),
              link: '/Administration/matSettings/tools/toolCategories',
              component: ToolCategoriesViewContainer,
              isAllowed: true,
            },
            {
              id : MatSettingsMenuItemsIds.NAV_ELEMENT_TOOL_TYPES,
              label: this.labels.get('menuitem.tools.children.toolTypes'),
              link: '/Administration/matSettings/tools/toolTypes',
              component: ToolTypesViewContainer,
              isAllowed: true,
            },
            {
              id : MatSettingsMenuItemsIds.NAV_ELEMENT_TOOL_GROUPS,
              label: this.labels.get('menuitem.tools.children.toolGroups'),
              link: '/Administration/matSettings/tools/toolGroups',
              component: ToolGroupsView,
              isAllowed: true,
            }
          ]
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_DIGITAL_TWIN,
          // label: this.labels.get('menuitem.digitalTwin'),   //TODO: L DT localize
          label: 'DIGITAL TWIN',
          link: '/Administration/matSettings/digitalTwin',
          component: DigitalTwinView,
          isAllowed: true,
        },
        {
          id : MatSettingsMenuItemsIds.NAV_ELEMENT_REASONS,
          label: this.labels.get('menuitem.reasons'),
          expanded: this.isExpanded('/Administration/matSettings/reasons'),
          isAllowed: true,
          items: [
            {
              id : MatSettingsMenuItemsIds.NAV_ELEMENT_REASONS_SHELF_LIFE_UPDATE,
              label: this.labels.get('menuitem.reasons.children.shelflife'),
              link: '/Administration/matSettings/reasons/shelfLifeUpdate',
              component: ShelfLifeUpdateViewContainer,
              isAllowed: true,
            }
          ]
        },
      ];
    }

    return items;
  }

  getDefaultRouteConfig() {
    return {
      key: `${this.navigationItems?.[0]?.id}_default`,
      path: '/Administration/matSettings',
      redirectPath: this.navigationItems?.[0]?.items?.[0]?.link || this.navigationItems?.[0]?.link,
      routeNotFoundComponent: PageNotFoundPageContainer
    }
  }

  render() {
    // return (<Page className="mat-settings-page mat-settings-page-responsive"
    return (<Page className="mat-settings-page"
                  selectedMenuItemId={this.props.sData.get('selectedMenuItemId')}
                  sideNavigatorMenuItems={getSideNavigatorMenuItems(this.navigationItems)}
                  actions={this.props.actions}
                  routes={getRoutesFor(this.navigationItems, this.getDefaultRouteConfig())}/>)
    }

}







