import * as settingsAlertsService from '../../../services/Alerts/settingsAlertsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_SET_BUSY: 'ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_SET_BUSY',
  ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_FETCH_DATA_FINISHED: 'ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_FETCH_DATA_FINISHED',

  ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UNMOUNT: 'ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UNMOUNT'

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  setBusy: function (payload) {
    return {type: actionTypes.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_SET_BUSY, payload: payload};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_FETCH_DATA_FINISHED, payload: payload};
  },
  unmount: function () {
    return {type: actionTypes.ADMINISTRATION_MAT_SETTINGS_COMMON_ALERTS_VIEW_UNMOUNT};
  }

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.load = function (alertType) {
  return function (dispatch, getState) {
    return api.fetchData(dispatch, getState)(alertType);
  }
};


jsxActions.update = function (dataQuery, alertType, messageDialogBuilder) {
  return function (dispatch, getState) {
    return api.update(dispatch, getState)(dataQuery, alertType, messageDialogBuilder);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.unmount());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

function fetchAssetPromise(alertType, assetType) {
  let promise = settingsAlertsService.fetchAssetAlertSettingByType({
    entityType: assetType,
    alertType: alertType
  });
  return promise;
}

api.fetchData = function (dispatch, getState) {
  return function (alertType) {
    dispatch(actions.setBusy(true));

    const promises = [];
    switch (alertType) {
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('KIT')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')));
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')));
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('KIT')));
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
      case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
        promises.push(fetchAssetPromise(alertType,'TOOL'));
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')));
        promises.push(fetchAssetPromise(alertType, getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')));
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('PASSED_SCHEDULE'):
        promises.push(fetchAssetPromise(alertType, 'SCHEDULER'));
        break;
      case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
        promises.push(fetchAssetPromise(alertType, 'MATERIAL'));
        break;
      default:
        console.error('alerts: fetch data - alert type not exist '  +  alertType);
    }
    return Promise.all(promises)
      .then((allResults) => {

        dispatch(actions.setBusy(false));

        const invalidResponse = allResults.find(response => {
          return !Network.isResponseValid(response);
        });

        if(invalidResponse){
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          return {success: false};
        }

        let rollData = allResults.find((item) => { return item.data.assetAlertEntityType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')});
        let spoolData = allResults.find((item) => { return item.data.assetAlertEntityType === getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')});
        let resinData = allResults.find((item) => { return item.data.assetAlertEntityType === getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')});
        let kitData = allResults.find((item) => { return item.data.assetAlertEntityType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT')});
        let groupData = allResults.find((item) => { return item.data.assetAlertEntityType === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')});
        let toolData = allResults.find((item) => { return item.data.assetAlertEntityType === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')});
        let taskData = allResults.find((item) => { return item.data?.assetAlertEntityType === 'SCHEDULER'});
        let misplacedMaterialData = allResults.find((item) => { return item.data?.assetAlertEntityType === 'MATERIAL'});

        let data = {
          rollData: rollData ? rollData.data : null,
          spoolData: spoolData?.data || null,
          resinData: resinData?.data || null,
          kitData: kitData ? kitData.data : null,
          groupData: groupData ? groupData.data : null,
          toolData: toolData ? toolData.data : null,
          taskData: taskData ? taskData.data : null,
          misplacedMaterialData: misplacedMaterialData?.data || null
        };

        dispatch(actions.fetchDataFinished(data));

        return {success: true};
      });
  }
};

api.update = function (dispatch, getState) {
  return function (dataQuery, alertType, messageDialogBuilder) {

    dispatch(actions.setBusy(true));

    return settingsAlertsService.update(dataQuery)
      .then((response) => {

        dispatch(actions.setBusy(false));

        if (!Network.isResponseValid(response)) {
          console.error('Mat Settings Common Alerts VIEW Update Failed' + ' ' + response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );

          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }

        return api.fetchData(dispatch, getState)(alertType);
      });
  }
};











