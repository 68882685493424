import { api as gridApi } from './promotionsGridActions';
import * as promotionsService from '../../../services/Administration/promotionsService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import {api as createPromotionDialogApi} from './Dialogs/createPromotionDialogActions';


// /////////////////////////////////////////
// // ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  PROMOTIONS_PAGE_SET_BUSY:               'PROMOTIONS_PAGE_SET_BUSY',
  PROMOTIONS_PAGE_FETCH_SETTINGS_FINISHED:   'PROMOTIONS_PAGE_FETCH_SETTINGS_FINISHED',
};

// ////////////////////////////////////////////////////////////////
// // PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  setBusy: function (payload) {
    return {type: actionTypes.PROMOTIONS_PAGE_SET_BUSY, payload: payload};
  },
  fetchSettingsFinished: function (payload) {
    return {type: actionTypes.PROMOTIONS_PAGE_FETCH_SETTINGS_FINISHED, payload: payload};
  },
};


// /////////////////////////////////////////////////////
// // METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  };
};

jsxActions.openCreatePromotionDialog = function (data) {
  return function (dispatch, getState) {
    createPromotionDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.activatePromotions = function (data) {
  return function (dispatch, getState) {
    api.togglePromotions(dispatch, getState)(data, true);
  };
};
jsxActions.deactivatePromotions = function (data) {
  return function (dispatch, getState) {
    api.togglePromotions(dispatch, getState)(data, false);
  };
};
jsxActions.deletePromotions = function (data) {
  return function (dispatch, getState) {
    api.deletePromotions(dispatch, getState)(data);
  };
};

jsxActions.getSettings = function () {
  return function (dispatch, getState) {
    api.getSettings(dispatch, getState)();
  };
};

jsxActions.saveSettings = function (data) {
  return function (dispatch, getState) {
    api.saveSettings(dispatch, getState)(data);
  };
};

// /////////////////////////////////////////////////////////////////////////
// // API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.togglePromotions = function(dispatch, getState) {
  return function (data, activate) {

    const ids = data?.map((item) => {
      return item.id;
    })

    const method = activate ? promotionsService.activatePromotions : promotionsService.deactivatePromotions;

    return method({ids})
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Activating/deactivating promotions failed', response);
          return { success: false };
        }
        gridApi.reload(dispatch, getState)();
      })
  }
}

api.deletePromotions = function(dispatch, getState) {
  return function (data) {

    dispatch(actions.setBusy(true));

    const ids = data?.map((item) => {
      return item.id;
    })

    return promotionsService.deletePromotions({ids})
      .then((response) => {

        dispatch(actions.setBusy(false));

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Delete promotions failed', response);
          return { success: false };
        }
        gridApi.reload(dispatch, getState)();
      })
  }
}

api.getSettings = function(dispatch, getState) {
  return function () {

    return promotionsService.getPromotionsSettings()
      .then((response) => {

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Getting the promotions settings failed', response);
          return { success: false };
        }

        dispatch(actions.fetchSettingsFinished(response?.data?.data ?? 0));

        return { success: true };
      })
  }
}

api.saveSettings = function(dispatch, getState) {
  return function (data) {

    dispatch(actions.setBusy(true));

    return promotionsService.savePromotionsSettings(data)
      .then((response) => {

        dispatch(actions.setBusy(false));

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Saving the promotions settings failed', response);
          return { success: false };
        }

        api.getSettings(dispatch, getState)();

        return { success: true };
      })
  }
}
