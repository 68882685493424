import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import ValueWithStateCell from '../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {navigationStates} from '../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../utils/materialHelper';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {getAssetEtl} from '../../../../utils/assetHelper';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import WeightCell from '../../../Common/CustomGridCells/WeightCell/weightCell';
import LastOperationCell from '../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';

import'./activeResinsGrid.scss';

class ActiveResinsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'material', getOptions: false},
    {fieldName: 'location', filterName: 'assetLocationName', getOptions: true},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'lot', filterName: 'lot', getOptions: false},
    {fieldName: 'weight', filterName: 'weight'},
    {fieldName: 'manufacturerModel.name', filterName: 'manufacturerLike', getOptions: true},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          resizable: true,
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '', alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              defrostingTimeLeft: params.data.defrostingTimeLeft,
              locationName: params.data.locationName,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'material',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_RESIN],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          resizable: true,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.resinId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.RESIN_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          resizable: true,
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              type: navigationStates.RESIN,
              label: params.data.businessId
            };
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      },
      {
        fieldName: 'lot',
        title: this.labels.get('columns.lot.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'lot',
        fetchConfig: {
          entityType: EntityPropertyTypes.LOT_NAME,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_RESIN],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => ({
            id: params.data.lot?.id,
            label: params.data.lot?.name,
            type: params.data.lot ? navigationStates.LOT : '',
          }),
        },
      },
      {
        fieldName: 'manufacturerModel.name',
        title: this.labels.get('columns.manufacturer.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'manufacturerLike',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ]
  }

  render() {
    return (
      <div className="active-resins-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ACTIVE_RESINS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  filterPersistence: true,
                  getRowHeight: () => 84
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'ActiveResinsGridForm'
  }
)(ActiveResinsGrid);


