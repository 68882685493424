import { gridsNames } from '../../../enums/gridsNames';
import * as gridActionsHelper from '../../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper';
import { fetchtAlertRules } from '../../../services/Alerts/misplacedMaterialAlertsRulesService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_ALERTS_MISPLACED_MATERIALS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    const alertSettingId = getState().administration.getIn(['settingsPage', 'commonAlertsView', 'misplacedMaterialData']).id;
    return fetchtAlertRules(query, alertSettingId);
  };
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;