import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { isDirty } from 'redux-form';
import SchedulingPreferencesView from '../../../../components/Administration/SettingsPage/Scheduling/SchedulingPreferences/schedulingPreferencesView';
import { jsxActions as SchedulingPreferencesViewActions } from '../../../../actions/Administration/SettingsPage/schedulingPreferencesViewActions';
import {jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(SchedulingPreferencesViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
    }
  }
}

const TerminologyViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'schedulingPreferencesView']),
      isDirty: isDirty('schedulingPreferencesView')(state),
    };
  },

  mapDispatchToProps
)(SchedulingPreferencesView);

export default TerminologyViewContainer;
