import { api as locationPageApi } from '../locationPageActions';
import { api as rollsGridApi} from './assetsRollsGridActions';
import { api as spoolsGridApi} from './assetsSpoolsGridActions';
import { api as resinsGridApi} from './assetsResinsGridActions';
import { api as kitsGridApi } from './assetsKitsGridActions';
import { api as partsGridApi } from './assetsPartsGridActions';
import { api as groupsGridApi } from './assetsGroupsGridActions';
import { api as toolsGridApi } from './assetsToolsGridActions';
import Network from 'infrastructure/js/modules/network';
import * as assetService from '../../../services/Assets/assetService';
import * as groupsService from '../../../services/Groups/groupsService';
import * as partsService from '../../../services/Parts/partsService';
import { api as printRfidTagsApi } from '../../PrintRfidActions/printRfidActions';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {gridsNames} from '../../../enums/gridsNames';
import PermissionManager from "infrastructure/js/utils/permissionManager";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.reloadParentComponent = function () {
  return function (dispatch, getState) {
    return locationPageApi.reload(dispatch, getState)();
  };
};

jsxActions.getLocationDetails = function () {
  return function (dispatch, getState) {
    return locationPageApi.getLocationDetails(dispatch, getState)();
  };
};

jsxActions.getAssetRelatedAssets = function (assetID, callback) {
  return function (dispatch, getState) {
    return api.getAssetRelatedAssets(dispatch, getState)(assetID, callback);
  };
};

jsxActions.onPrintRfidTagsClick = function (data) {
  return function (dispatch, getState) {
    return api.onPrintRfidTagsClick(dispatch, getState)(data);
  };
};

jsxActions.onPrintTagsWithDialogClick = function (asset) {
  return function (dispatch, getState) {
    return api.openPrintRfidTagsDialog(dispatch, getState)(asset);
  };
};

jsxActions.onPrintEmptyRfidTagsClick = function (data) {
  return function (dispatch, getState) {
    return api.onPrintEmptyRfidTagsClick(dispatch, getState)(data);
  };
};

jsxActions.printRfidTags = function (data) {
  return function (dispatch, getState) {
    return api.printRfidTags(dispatch, getState)(data);
  };
};

jsxActions.getAssetRelatedLayers = function(assetID, callback) {
  return function(dispatch, getState) {
    return api.getAssetRelatedLayers(dispatch, getState)(assetID, callback);
  };
};

jsxActions.onUngroupClick = function (data) {
  return function (dispatch, getState) {
    api.openUngroupConfirmationDialog(dispatch, getState)(data);
  };
};

jsxActions.clearFilters = function (data) {
  return function (dispatch, getState) {

    let gridApi = null;
    switch (data?.gridName) {
      case gridsNames.LOCATION_ASSET_ROLLS:
        gridApi = rollsGridApi;
        break;
      case gridsNames.LOCATION_ASSET_SPOOLS:
        gridApi = spoolsGridApi;
        break;
      case gridsNames.LOCATION_ASSET_RESINS:
        gridApi = resinsGridApi;
        break;
      case gridsNames.LOCATION_ASSET_KITS:
        gridApi = kitsGridApi;
        break;
      case gridsNames.LOCATION_ASSET_PARTS:
        gridApi = partsGridApi;
        break;
      case gridsNames.LOCATION_ASSET_TOOLS:
        gridApi = toolsGridApi;
        break;
      case gridsNames.LOCATION_ASSET_GROUPS:
        gridApi = groupsGridApi;
        break;
      default:
        console.error('Unknown grid name', data?.gridName);
    }

    gridApi?.clearFilters(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function (shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      rollsGridApi.reload(dispatch, getState)();   //TODO: L refactoring - load only relevant grids for the current location
      spoolsGridApi.reload(dispatch, getState)();
      if (PermissionManager.isResinsEnabled()) {
        resinsGridApi.reload(dispatch, getState)();
      }
      kitsGridApi.reload(dispatch, getState)();
      groupsGridApi.reload(dispatch, getState)();
      toolsGridApi.reload(dispatch, getState)();
      partsGridApi.reload(dispatch, getState)(); //TODO: L AFP load it for AFP station only
    }

    return Promise.resolve();
  };
};

api.getAssetRelatedAssets = function (dispatch, getState) {
  return function (assetID, callback) {
    return assetService.getAssetRelatedAssets(assetID).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch asset related assets failed');
        return { success: false };
      }
      if (callback) {
        callback(response.data);
      }
    });
  };
};

api.onPrintEmptyRfidTagsClick = function (dispatch, getState) {
  return function () {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    printRfidTagsApi.printEmptyRfidTags(dispatch, getState)(locationId);
  };
};

api.onPrintRfidTagsClick = function (dispatch, getState) {
  return function (data) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let assetsIds = data.selectedAssets.map((asset) => {
      return asset.id;
    });
    printRfidTagsApi.printRfidTags(dispatch, getState)(assetsIds, locationId);
  };
};

api.openPrintRfidTagsDialog = function (dispatch, getState) {
  return function (asset) {
    printRfidTagsApi.openPrintRfidTagsDialog(dispatch, getState)(asset['locationId'], asset);
  };
};


api.getAssetRelatedLayers = function(dispatch, getState) {
  return function (assetID, callback) {
    return partsService.getPartLayers(assetID).then((response) => { // relevant for parts only
      if (!Network.isResponseValid(response)) {
        console.error('Fetch asset related assets failed');
        return {success: false};
      }
      if (callback) {

        let componentAssets = response.dataList?.map((layer) => {  //to adjust the structure for the RelatedAssetsCell renderer
          return {
            id: layer.id,
            businessId: layer.businessId,
            relationCreationDate: layer.createdDate,
          }
        })

        callback({componentAssets});
      }
    });
  };
};

api.openUngroupConfirmationDialog = function (dispatch, getState) {
  return function (data) {
    let labels = createLabelHelper(
      'mat.locationpage.view.assets.view.groups.ungroup.confirmation.'
    );

    let confirmationConfig = {
      title: labels.get('title'),
      message: labels.get('message', '', { businessId: data.businessId }),
      cancelHandler: messageDialogApi.close(dispatch, getState),
      submitHandler: () => api.ungroup(dispatch, getState)(data),
    };
    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
  };
};

api.ungroup = function (dispatch, getState) {
  return function (selectedGroup) {
    messageDialogApi.close(dispatch, getState)();

    return groupsService.ungroup(selectedGroup.id).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Ungroup failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      locationPageApi.reload(dispatch, getState)();
      let label = 'mat.locationpage.view.assets.view.groups.ungroup.confirmation.success.message';
      messageDialogApi.showSuccess(dispatch, getState)(label);
    });
  };
};

let utility = {};

utility.NormalizeResponseError = function (response) {
  let message;
  let statusType;
  let errorCode;

  if (
    !response ||
    !response.applicationResponseStatus ||
    !response.applicationResponseStatus.message
  ) {
    message = 'Print Tags Failed';
  } else {
    message = response.applicationResponseStatus.message;
    statusType = response.applicationResponseStatus.statusType.toLowerCase();
    errorCode = response.applicationResponseStatus.errorCode;
  }

  return {
    message,
    statusType: statusType,
    errorCode: errorCode,
  };
};
