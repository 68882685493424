// @ts-check

/**
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * 
*/

export default class RefContextHelper {

  /** @type {React.MutableRefObject<LiveMapPageRefContext>} */
  static refContext;

  /** @type {string} */
  static NO_FLOOR_NAME = "---";

  /** @type {string} */
  static NO_LOCAITON_NAME = "---";




  static getFloorNamesSortedByElevation() {

    let venue = RefContextHelper.refContext.current.refMapView.current?.venue;
    if (!venue) {
      return null;
    }

    let mapsByElevation = venue.maps.slice().sort((a, b) => b.elevation - a.elevation);
    let floorNamesByElevation = mapsByElevation.map((m) => m.shortName);

    return floorNamesByElevation;
  }

  
  static getCurrentFloorName() {

    let mapView = RefContextHelper.refContext.current.refMapView.current;
    if (!mapView) {
      return null;
    }
    
    return mapView.currentMap.shortName;

  }




}



