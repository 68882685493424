// @ts-check

import React, { useCallback, useEffect, useState } from 'react';
import { Mappedin, MapView } from '@mappedin/mappedin-js';
import { DevLog as D } from './../../../../utils/devLog';


// @ts-ignore
import _ from 'lodash';

import SearchIcon from '../../../../../../infrastructure/assets/svg/search-icon-sm.svg';
import { livemapIcons } from '../../../../../../mat/assets/svg/index';


import './mapSearchOverlay.scss';
import SearchFilters from './SearchFilters/searchFilters';
import SearchResults from './SearchResults/searchResults';
import LiveSearchUtils from '../../Utils/LiveSearchUtils.js';



/**
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * 
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageActions} LiveMapPageActions
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * 
 * @typedef {{
 *    mapView:  MapView | undefined | null, 
 *    sData:    PL_LiveMapPageState, 
 *    actions:  LiveMapPageActions
 *    refContext: React.MutableRefObject<LiveMapPageRefContext>
 * }} MapToolbarOverlayProps

 * 
 */

/**
 * @typedef {import("../../../../types/ServerTypes/Models/LiveSearch").LiveSearchRequestModel} LiveSearchRequestModel
 * 
 * 
 */



/**
 * @param {MapToolbarOverlayProps} props
 * 
 */
const MapSearchOverlay = (props) => {

  // console.log('=========== OVERLAY RENDER ===> ', props);

  let mapView = props.mapView;
  if (!mapView) {
    return null;
  }
  
  
  ////////////////////////////////////////////////////////////
  // Search cannot function correctly without plant data.
  //
  if (!props.sData.plant.fullStatus) {
    return null;
  }


  // let searchFilter = props.sData.search.searchFilter;
  // D.log('MapSearchOverlay GGGGGGGGGGG searchFilter ===> ', searchFilter);

  
  
  

  const [searchValue, setSearchValue] = useState('');
  
  const handleInputChange = (/** @type {React.ChangeEvent<HTMLInputElement>} */ event) => {
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (/** @type {React.KeyboardEvent<HTMLInputElement>} */ event) => {
    // console.log(`${event.key} key pressed`);
    
    if (event.key === 'Escape') {
      event.stopPropagation();
      event.preventDefault();
      // console.log('Escape key pressed');
      setSearchValue('');
      clearSearch(props);
    }
    
  };

  
  /////////////////////////////////////////////////////////////////////
  // Debounce the search input
  // 
  // Upon each change of local state variable 'searchValue', trigger useEffect
  // which will call the debounced function 'debouncedSearch'.
  // 
    
  // @ts-ignore
  const debouncedSearch = useCallback(_.debounce((v, f) => {
    // console.log('------------ DEBOUNCED Value:', v, f);
    submitSearch(props, v, f)
  }, 300), []);
  
  useEffect(() => {

    let searchFilter = props.sData.search.searchFilter;
    // D.log('EFFECT DEBOUNCE GGGGGGGGGGG searchFilter ===> ', searchFilter);
    
    debouncedSearch(searchValue, searchFilter);
  }, [searchValue, debouncedSearch]);
  
  

  
  return (

    <div className="live-map-overlay-search-controls">

      <div className='search-controls-wrapper'>
        <div className='search-controls'>

          <div className='search-input-wrapper'>
            <div className='search-input'>
              {/* <SearchIcon /> */}
              {livemapIcons.liveMapInput_Find()}
              <input
                type='text'
                id='txtLivemapsearch'
                placeholder='Search'
                autoComplete='off'
                spellCheck='false'
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>


          <SearchFilters mapView={mapView} sData={props.sData} actions={props.actions} />

          <SearchResults mapView={mapView} sData={props.sData} actions={props.actions} refContext={props.refContext} />



        </div>
      </div>



      {/* <button id='btnLivemapsearch'
          // onClick={handleSearchClick}
        >Search</button> */}


    </div>

  );

};


export default MapSearchOverlay;




////////////////////////////////////
// Helper functions
//

/** 
 * @param {MapToolbarOverlayProps} props 
 * @param {string} searchValue 
 * @param {string} searchFilter 
 */
const submitSearch = (props, searchValue, searchFilter) => {
  // console.log('Searching for:', searchValue);
  
  
  if (!searchValue) {
    clearSearch(props);
  }
  
  if (searchValue.length < 3) {
    clearSearch(props);
    return;
  }
  
  if (searchValue.trim().length === 0) {
    clearSearch(props);
    return;
  }
  

  // let sData = props.sData;
  let actions = props.actions;
  // console.log('GGGGGGGGGGGGGGGGGGGGG props ===> ', props);
  // console.log('GGGGGGGGGGGGGGGGGGGGG sData ===> ', sData);
  // console.log('GGGGGGGGGGGGGGGGGGGGG actions ===> ', actions);
  
  /** @type {LiveSearchRequestModel} */
  let requestModel = {
    q: searchValue,
    max: 3,
    types: ['TOOL', 'MATERIAL', 'LOCATION', 'WO']
  };
  
  
  let newRequestModel = LiveSearchUtils.modifySearchRequestModelByFilter(requestModel, searchFilter);
  
  // let newRequestModel = requestModel;
  actions.searchMap(newRequestModel);

}


/** 
 * @param {MapToolbarOverlayProps} props 
 */
const clearSearch = (props) => {
  // console.log('Clearing Search:');
  let actions = props.actions;
  actions.clearSearch();
}


