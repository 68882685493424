import PropTypes from 'prop-types';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import './channelListItem.scss';

const ComboboxPortal = withPortal(Combobox);

export default function ChannelListItem ({name, index, fieldName,  options, onChangeCallback}) {

 const onItemChangeCallback = (value, oldValue) => {
    onChangeCallback?.(value, oldValue, index);
 };

  return (
    <>
      <span className='item-index'>{`${index + 1}.`}</span>
      <ComboboxPortal
        id={name + `.[${fieldName}]`}
        name={name + `.[${fieldName}]`}
        options={options}
        parse={Parse.comboValueOnly()}
        format={Format.findOptionByValue(options)}
        onChangeCallback={onItemChangeCallback}
      />
    </>
  )
}

ChannelListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
};
