import PropTypes from 'prop-types';
import Accordion from 'infrastructure/js/components/Accordion/accordion';
import SideNavAccordionSummary from './sideNavAccordionSummary';
import SideNavAccordionDetails from './sideNavAccordionDetail';
import {matchPath, useLocation} from 'react-router-dom';

export default function MenuItem({ itemData,
                                   expanded=false,
                                   onExpandChange=()=>{},
                                   actions={},
                                   isFavoritesFull }) {
  const { items } = itemData;
  const { pathname } = useLocation();

  const containsSelectedSubItem = items?.length ? !!items?.find(item => {return matchPath(pathname, item.link || '')}) : false;

  return (
    <Accordion expanded={expanded} onChange={onExpandChange}>
      <SideNavAccordionSummary {...itemData} containsSelectedSubItem={containsSelectedSubItem}/>
      {items && <SideNavAccordionDetails items={items} actions={actions} isFavoritesFull={isFavoritesFull} />}
    </Accordion>
  );
}

MenuItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  actions: PropTypes.object,
  isFavoritesFull: PropTypes.bool,
};
