import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as locationPageActions }    from '../../actions/LocationPage/locationPageActions';
import { jsxActions as cuttingViewActions }    from '../../actions/LocationPage/CuttingView/cuttingViewActions.js';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as bringAssetsActions }       from '../../actions/Dialogs/BringAssetsDialog/bringAssetsDialogActions';
import { jsxActions as archiveAssetsActions }     from '../../actions/Dialogs/ArchiveAssetsDialog/archiveAssetsDialogActions';
import { jsxActions as activateAssetsActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';
import { jsxActions as shelfLifeUpdateActions }   from '../../actions/Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialogActions';
import { jsxActions as printRfidTagsActions}      from '../../actions/Dialogs/PrintRfidTagsDialog/printRfidTagsDialogActions';
import { jsxActions as createGroupActions }   from '../../actions/Dialogs/CreateGroupDialog/createGroupDialogActions';
import { jsxActions as fetchEntitiesActions}      from '../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as addToGroupActions }        from '../../actions/LocationPage/AssetsView/addToGroupActions';
import { jsxActions as addToShipmentActions }        from '../../actions/LocationPage/AssetsView/addToShipmentActions';
import { jsxActions as shipToCustomerActions }        from '../../actions/LocationPage/ShipmentsView/shipToCustomerActions';
import { jsxActions as editAssetAttributesActions }      from '../../actions/Dialogs/EditAssetAttributesDialog/editAssetAttributesDialogActions.js';
import { jsxActions as printPdfLabelsActions}       from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions';
import { jsxActions as exportActions }      from '../../actions/Export/exportActions';
import { jsxActions as importAttributesUpdateWizardActions } from '../../actions/Wizards/ImportAttributesUpdateWizard/importAttributesUpdateWizardActions.js';
import { jsxActions as workOrderDialogActions} from '../../actions/LocationPage/WorkOrderView/workOrderDialogActions';
import { jsxActions as reportCutDialogActions} from '../../actions/LocationPage/CuttingView/reportCutDialogActions';
import { jsxActions as afpOperationWizardActions } from '../../actions/Wizards/AfpOperationWizard/afpOperationWizardActions.js';
import { jsxActions as createKitWizardActions} from '../../actions/LocationPage/KittingView/createKitWizardActions';
import LocationPage from '../../components/LocationPage/locationPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      cuttingViewActions : bindActionCreators(cuttingViewActions, dispatch),
      locationPage: bindActionCreators(locationPageActions, dispatch),
      moveAssetsActions:        bindActionCreators(moveAssetsActions, dispatch),
      bringAssetsActions:       bindActionCreators(bringAssetsActions, dispatch),
      archiveAssetsActions:     bindActionCreators(archiveAssetsActions, dispatch),
      activateAssetsActions:    bindActionCreators(activateAssetsActions, dispatch),
      shelfLifeUpdateActions:   bindActionCreators(shelfLifeUpdateActions, dispatch),
      printRfidTagsActions:     bindActionCreators(printRfidTagsActions, dispatch),
      createGroupActions:       bindActionCreators(createGroupActions, dispatch),
      // cutAndKitDialog: {
      //   dialog: bindActionCreators(cutAndKitDialogActions, dispatch),
      //   rollsToCut: bindActionCreators(rollsToCutActions, dispatch),
      //   kitToWorkOrders: bindActionCreators(kitToWorkOrdersActions, dispatch)
      // },
      addToGroupActions:        bindActionCreators(addToGroupActions, dispatch),
      addToShipmentActions:        bindActionCreators(addToShipmentActions, dispatch),
      shipToCustomerActions:        bindActionCreators(shipToCustomerActions, dispatch),
      editAssetAttributesActions:      bindActionCreators(editAssetAttributesActions, dispatch),
      printPdfLabelsActions:    bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch),
      importAttributesUpdateWizardActions: bindActionCreators(importAttributesUpdateWizardActions, dispatch),
      workOrderDialogActions: bindActionCreators(workOrderDialogActions, dispatch),
      reportCutDialog:  bindActionCreators(reportCutDialogActions, dispatch),
      createKitWizard:  bindActionCreators(createKitWizardActions, dispatch),
      afpOperationWizardActions: bindActionCreators(afpOperationWizardActions, dispatch),
    }
  }
}


let LocationPageContainer = connect(
  (state) => {
  	return {
      sData:              state.locationPage.get('data'),
      chartsData:         state.locationPage.get('chartsData'),
      assetsData:         state.locationPage.get('assetsViewData'),
      sMoveAssetsData:    state.dialogs.get('moveAssetsDialog'),
      sBringAssetsData:   state.dialogs.get('bringAssetsDialog'),
      sArchiveAssetsData: state.dialogs.get('archiveAssetsDialog'),
      sShelfLifeUpdateData:  state.dialogs.get('shelfLifeUpdateDialog'),
      sPrintRfidTagsData:  state.dialogs.get('printRfidTagsDialog'),
      sCreateGroupData:  state.dialogs.get('createGroupDialog'),
      // sCutAndKitDialog:  state.cutKitStation.get('cutAndKitDialog'),
      sLoggedInUser: state.login.get('loggedInUser'),
      sEditAssetAttributesData: state.dialogs.get('editAssetAttributesDialog'),
      sPrintPdfLabelsData:      state.dialogs.get('printPdfLabelsDialog'),
      sDataAddToGroup:    state.locationPage.get('addToGroupData'),
      sDataAddToShipment:    state.locationPage.get('addToShipmentData'),
      sDataShipToCustomer:    state.locationPage.get('shipToCustomerData'),
      sImportAttributesUpdateWizardData: state.wizards.get('importAttributesUpdateWizard'),
      sWorkOrderDialogData: state.locationPage.get('workOrderDialogData'),
      sReportCutDialog:  state.cutKitStation.get('reportCutDialog'),
      sCreateKitWizard:  state.cutKitStation.get('createKitWizard'),
      sSelectedCutToKit: state.cutKitStation.get('selectedCutToKit'),
      sAfpOperationWizardData: state.wizards.get('afpOperationWizard'),
    };
  },
  mapDispatchToProps
)(LocationPage);


// export default injectIntl(LocationPageContainer);
export default LocationPageContainer;



