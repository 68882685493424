import { api as locationPageApi }    from '../locationPageActions';
import {api as gridApi} from '../ShipmentsView/shipmentsGridActions';
import  * as shipmentsService      from '../../../services/Shipments/shipmentsService';
import Network from "infrastructure/js/modules/network";
import {api as messageDialogApi} from "../../MessageDialog/messageDialogActions";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.reload = function(shouldRefreshGrid) {
  return function(dispatch, getState) {
    api.init(dispatch, getState)(shouldRefreshGrid);
  }
};

jsxActions.getLocationDetails = function() {
  return function(dispatch, getState) {
    return locationPageApi.getLocationDetails(dispatch, getState)();
  };
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

jsxActions.shipmentReadyToShip = function (data) {
  return function (dispatch, getState) {
    api.shipmentReadyToShip(dispatch, getState)(data);
  }
};

jsxActions.markShipmentAsReceived = function (data) {
  return function (dispatch, getState) {
    api.markShipmentAsReceived(dispatch, getState)(data);
  }
};

jsxActions.deleteShipment = function (data) {
  return function (dispatch, getState) {
    api.deleteShipmentConfirmationDialog(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function(shouldRefreshGrid) {
    if (shouldRefreshGrid) {
      gridApi.reload(dispatch, getState)();
    }
  }
};
api.shipmentReadyToShip = function(dispatch, getState) {
  return function({selectedRowsData, reloadParentComponent}) {

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let shipmentId = selectedRowsData?.get(0)?.id || null;

    shipmentsService.shipmentReadyToShip(shipmentId, locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Shipment ready to ship failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return {success: true};
    });
  }
};

api.markShipmentAsReceived = function(dispatch, getState) {
  return function({selectedRowsData, reloadParentComponent}) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let shipmentId = selectedRowsData?.get(0)?.id || null;

    shipmentsService.markShipmentAsReceived(shipmentId, locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Mark shipment as received failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return {success: true};
    });
  }
};

api.deleteShipmentConfirmationDialog = function(dispatch, getState) {
  return function(data) {
    let confirmationConfig = {
      submitHandler: () => api.deleteShipment(dispatch, getState)(data)
    }
    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
  }
};

api.deleteShipment = function(dispatch, getState) {
  return function({selectedRowsData, reloadParentComponent}) {

    messageDialogApi.close(dispatch, getState)();

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let shipmentId = selectedRowsData?.get(0)?.id || null;

    shipmentsService.deleteShipment(shipmentId, locationId).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Delete Shipment failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      return {success: true};
    });
  }
};
