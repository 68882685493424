import React from 'react';
import PropTypes from 'prop-types';
import EntitiesMultiSelect from '../../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelectWithChildren.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import ComboBox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import { FetchEntitiesFilters } from '../../../../../../enums/fetchEntitiesFilters';
import { getEnumValue, enumTypes } from '../../../../../../utils/enumHelper';
import { DefaultWorkOrderTypes } from '../../../../../../utils/workOrderHelper';
import MergeOperationListItem from '../../listItems/MergeOperationListItem/mergeOperationListItem';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Button from 'infrastructure/js/components/controls/Button/button';
import { generateKitId } from '../../../../../../services/Kits/kitsService';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import List from 'infrastructure/js/components/List/list';

import './assetsMergeSection.scss';

export default class AssetsMergeSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    this.entitiesLabels = createLabelHelper("mat.entity.type.");
    this.usingFetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.NO_ATTACHED_ASSET]
    };

    this.entitiesTypes = this.props.entitiesTypes;

    this.toEntitiesTypes = [getEnumValue(enumTypes.OBJECT_TYPE)("ROLL"), getEnumValue(enumTypes.OBJECT_TYPE)("KIT")];
    this.addRollEntitiesType = [{
      value: getEnumValue(enumTypes.OBJECT_TYPE)("ROLL"),
      label: this.entitiesLabels.get("rolls")
    }];

    this.state = {
      // addCurrentType: this.entitiesTypes[0],
      addKitsDisabled: false,
      toCurrentType: this.entitiesTypes[0],
      addKey: 0,
      toKey: 0,
      usingKey: 0,
      usingAssetId: null,
      addRollsKey: null,
      rollsFromCurrentLocationOnly: true
    };

    let initialValues = {};
    initialValues[props.name] = {
      rollsFromCurrentLocationOnly: true
    };

    props.initialize(initialValues);
  }

  getReduxFieldName = (fieldName) => {
    return this.props.name + "." + fieldName;
  };

  componentDidMount() {
    this.props.change(this.getReduxFieldName("add-rolls-selection"), {
      label: this.entitiesLabels.get("rolls"),
      value: "ROLL"
    });
    this.props.change(this.getReduxFieldName("rollsList"), [{ assetId: null, cutLength: null }]);

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.workOrder !== this.props.workOrder) {
      this.props.change(this.getReduxFieldName("add-asset-selection") + ".assetsValue", null);
      this.props.change(this.getReduxFieldName("to-asset-selection") + ".assetsValue", null);
      this.props.change(this.getReduxFieldName("using-asset-selection") + ".assetsValue", null);

      this.setState({
          toKey: this.state.toKey === 0 ? 1 : 0,
          addKey: this.state.addKey === 0 ? 1 : 0,
          usingKey: this.state.usingKey === 0 ? 1 : 0,
          addRollsKey: this.state.addRollsKey === 0 ? 1 : 0
        }, () => {
          this.props.change(this.getReduxFieldName("rollsList"), [{ assetId: null, cutLength: null }]);
        }
      );
    }
  }

  onToTypeChangeCallback = (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }

    let newState = {};

    if (newValue === getEnumValue(enumTypes.OBJECT_TYPE)("ROLL")) {
      this.props.change(this.getReduxFieldName("add-asset-selection"), {});
      newState.addKey = (this.state.addKey === 0 ? 1 : 0);
    }

    newState.addKitsDisabled = (newValue === getEnumValue(enumTypes.OBJECT_TYPE)("ROLL"));

    if (this.state.usingAssetId !== null) {
      newState.usingAssetId = null;
      newState.usingKey = this.state.usingKey === 0 ? 1 : 0;
      this.props.change(this.getReduxFieldName("using-asset-selection") + ".assetsValue", null);
    }

    newState.toCurrentType = newValue;
    this.setState(newState);
  };

  onToValueChangeCallback = (value) => {
    let attachedAssetId =
      (value && value.data && value.data.attachedAsset && value.data.attachedAsset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')) ?
        value.data.attachedAsset.id : null;

    if (this.state.usingAssetId !== attachedAssetId) {

      let newUsingAsset = null;
      if (attachedAssetId) {
        newUsingAsset = {
          value: attachedAssetId,
          label: value.data.attachedAsset.businessId,
          data: {
            businessId: value.data.attachedAsset.businessId,
            toolType: { businessId: value.data.attachedAsset.toolType.businessId }
          }
        };
      }
      this.props.change(this.getReduxFieldName("using-asset-selection") + ".assetsValue", newUsingAsset);

      this.setState({
        usingAssetId: attachedAssetId,
        usingKey: this.state.usingKey === 0 ? 1 : 0
      });
    }
  };

  getNewOptionDataHandler = (inputValue, optionLabel) => {
    let { workOrder } = this.props;
    return {
      label: optionLabel,
      value: inputValue,
      __isNew__: true,
      data: {
        objectType: getEnumValue(enumTypes.OBJECT_TYPE)("KIT"),
        businessId: inputValue,
        kitType: {
          businessId: (workOrder && workOrder.kitType) ? workOrder.kitType.businessId : ""
        }
      }
    };
  };

  listItemfilterOptions = (fieldsData) => (candidate, input) => {
    if (fieldsData) {
      const isElementFoundInData = fieldsData.find(element => {
        return element.assetId && element.assetId.assetsValue && element.assetId.assetsValue.value === candidate.data.value;
      });

      if (isElementFoundInData) {
        return false;
      }
    }
    return true;
  };

  listItemRenderer = (data) => {
    const fetchConfigFilters = [...this.props.fetchConfig.filter];

    if (this.state.rollsFromCurrentLocationOnly) {
      fetchConfigFilters.push(FetchEntitiesFilters.ON_LOCATION);
    }

    return (
      <MergeOperationListItem
        {...this.props}
        fetchConfig={{
          ...this.props.fetchConfig,
          filter: fetchConfigFilters
        }}
        {...data}
        labels={this.labels}
        filterOption={this.listItemfilterOptions(data.fieldsData)}
      />
    );
  };

  onCurrentLocationOnlyCheckboxClick = (event) => {
    this.setState({
      rollsFromCurrentLocationOnly: event.target.checked
    });
  };

  renderRollsListSection = () => {
    let units = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
    let labelKey = PermissionManager.isWeightSupported() ? 'weightLeft' : 'amount';

    return (
      <div className="add-rolls-to-asset-container">
        <div className="rolls-list-title">
          <div className="column">
            <label>{this.labels.get("add")}</label>
            <ComboBox id={this.getReduxFieldName("add-rolls-selection")}
                      name={this.getReduxFieldName("add-rolls-selection")}
                      className="add-rolls-selection"
                      options={this.addRollEntitiesType}
                      isDisabled={true}
            />
            <Checkbox
              id={this.getReduxFieldName("rollsFromCurrentLocationOnly")}
              name={this.getReduxFieldName("rollsFromCurrentLocationOnly")}
              label={this.labels.get("assetsinlocationonly")}
              onClick={this.onCurrentLocationOnlyCheckboxClick}
            />
          </div>

          <div className="column">
            <label className="">{this.labels.get(labelKey)}</label>
            <label className="units">{`(${UnitHelper.getLabelForUnitType(units)})`}</label>
          </div>
        </div>

        <List name={this.getReduxFieldName("rollsList")}
                       key={this.state.addRollsKey}
                       itemRenderer={this.listItemRenderer}
                       maxItemsToRender={15}
                       addButtonLabel={this.labels.get("rollslist.button.add")}
                       maxItemsToOverflow={4}
        />
      </div>);
  };


  generateIdResponseHandler = (response) => {
    const newOption = {
      "value": response.data.data,
      "label": response.data.data,
      "data": {
        "id": response.data.data,
        objectType: this.state.toCurrentType
      }
    };
    this.props.change(this.getReduxFieldName("to-asset-selection") + ".assetsValue", newOption);
  };

  generateId = () => generateKitId().then(this.generateIdResponseHandler);


  render = () => {

    let {
      fetchConfig,
      workOrder,
      preselectedAssets,
      name,
      entitiesTypes,
      disableMarkAsConsumed,
      itemIndex,
      ...otherProps
    } = this.props;
    let allowNewOption = (workOrder && workOrder.workOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED && !!workOrder.kitType);
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? { autoFocus: true } : {};

    return <div className="assets-merge-section">
      <div className="add-to-asset-container">

        <div className="add-to-asset-section">
          <label className="add-to-asset-label">{this.labels.get("add")}</label>

          <EntitiesMultiSelect
            key={this.state.addKey}
            id={this.getReduxFieldName("add-asset-selection")}
            name={this.getReduxFieldName("add-asset-selection")}
            className="add-to-asset-selection"
            entitiesTypes={this.entitiesTypes}
            preSelectedEntities={preselectedAssets}
            dropdownMenuClassName="multi-select-field-workorder-dialog"
            {...otherProps}
            isDisabled={this.state.addKitsDisabled}
            fetchConfig={fetchConfig}
            {...focusProps}
          />
        </div>

        <label className="add-to-asset-arrow">
          <i className="pl pl-move-arrow-right"/>
        </label>

        <div className="add-to-asset-section">
          <label className="add-to-asset-label">{this.labels.get("to")}</label>
          <EntitiesMultiSelect
            key={this.state.toKey}
            id={this.getReduxFieldName("to-asset-selection")}
            name={this.getReduxFieldName("to-asset-selection")}
            className="assets-to-asset-selection"
            entitiesTypes={this.toEntitiesTypes}
            selectedEntitiesType={this.state.toCurrentType}
            validate={Validation.required}
            onTypeChangeCallback={this.onToTypeChangeCallback}
            onValueChangeCallback={this.onToValueChangeCallback}
            dropdownMenuAttachment="right"
            dropdownMenuClassName="multi-select-field-workorder-dialog"
            {...otherProps}
            isMulti={false}
            allowNewOption={allowNewOption}
            getNewOptionData={this.getNewOptionDataHandler}
            fetchConfig={fetchConfig}
          >
            {
              allowNewOption && this.state.toCurrentType === "KIT" && <div className="input-with-generate-button">
                <Button id={"-button"} bsStyle="default" onClick={this.generateId}
                        className={" generateButton border-btn btn btn-default"}>Generate</Button>
              </div>
            }
          </EntitiesMultiSelect>
          <InputSection label={this.labels.get("using")} className="using-asset-section">

            <EntitiesMultiSelect id={this.getReduxFieldName("using-asset-selection")}
                                 name={this.getReduxFieldName("using-asset-selection")}
                                 key={this.state.usingKey}
                                 className="using-asset-selection"
                                 entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')]}
                                 disableTypeChange={true}
                                 {...otherProps}
                                 fetchConfig={this.usingFetchConfig}
                                 isMulti={false}
                                 isDisabled={!!this.state.usingAssetId}
            />
          </InputSection>
        </div>

      </div>

      {this.renderRollsListSection()}

    </div>;
  };

}

AssetsMergeSection.defaultProps = {
  entitiesTypes: ["ROLL"]
};

AssetsMergeSection.propTypes = {
  value: PropTypes.object,
  entitiesTypes: PropTypes.array
};




