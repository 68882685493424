import React from 'react';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Button from 'infrastructure/js/components/controls/Button/button';
import {reduxForm} from 'redux-form';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import './onHandInventoryHeader.scss';

class OnHandInventoryHeader extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.onHandInvertory.header.');
    this.filterAlertslabels = createLabelHelper('mat.filter.alerts.');

    this.alertStatusOptions = [
      {label: this.filterAlertslabels.get('alertstatus.noalert'), value: 'noAlert'},
      {label: this.filterAlertslabels.get('alertstatus.warning'), value: 'warning'},
      {label: this.filterAlertslabels.get('alertstatus.error'), value: 'error'},
    ];

    this.alertTypeOptions = [
      {label: this.filterAlertslabels.get('alerttype.etlCure'), value: 'etlCure'},
      {label: this.filterAlertslabels.get('alerttype.expiration'), value: 'expiration'},
    ];
    if (PermissionManager.isEtlBondEnabled()) {
      this.alertTypeOptions.unshift({label: this.filterAlertslabels.get('alerttype.etlBond'), value: 'etlBond'})
    }

    this.state = {
      isAlertStatusSelected: false
    };
  }

  componentDidMount() {
    this.props.actions.init();
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  onAlertsStatusChange=(value)=>{
    if (!value || value.length === 0 || this._isNoAlertStatusSelected(value)) {
      this.setState({isAlertStatusSelected: (false)});
      this.props.change('selectAlertType', []);
      return;
    }

    this.setState({isAlertStatusSelected: (true)});
  };
  _isNoAlertStatusSelected = (value)=>{
    return value.length === 1 && value[0].value === 'noAlert';
  };

  onSubmit = (data) => {
    let query = {
      locations: data.selectLocation || [],
      alertStatuses: data.selectAlertStatus || [],
      alertTypes: data.selectAlertType || []
    }
    this.props.actions.fetchGridData(query);
  };

  render() {
    return (
      <div className="on-hand-inventory-header">

        <InputSection label={this.labels.get('location')} htmlFor="selectLocation" className="inline">
          <PL_MultiSelectField
                               name="selectLocation"
                               id="selectLocation"
                               options={this.props.sData.get('locationOptions')}
                               placeholder={this.labels.get('selectPlaceholder')}
                               isSearchable={true}
                               allowNewOption={false}
                               closeMenuOnSelect={false}
                               className="multi-select-field">
          </PL_MultiSelectField>
        </InputSection>

        <InputSection label={this.labels.get('alertStatus')} htmlFor="selectAlertStatus" className="inline">
          <PL_MultiSelectField
                               name="selectAlertStatus"
                               id="selectAlertStatus"
                               options={this.alertStatusOptions}
                               placeholder={this.labels.get('selectPlaceholder')}
                               isSearchable={true}
                               allowNewOption={false}
                               className="multi-select-field"
                               closeMenuOnSelect={false}
                               onChangeCallback={this.onAlertsStatusChange}>
          </PL_MultiSelectField>
        </InputSection>

        {this.state.isAlertStatusSelected &&
          <InputSection label={this.labels.get('alertType')} htmlFor="selectAlertType" className="inline">
            <PL_MultiSelectField
                                 name="selectAlertType"
                                 id="selectAlertType"
                                 options={this.alertTypeOptions}
                                 placeholder={this.labels.get('selectPlaceholder')}
                                 isSearchable={true}
                                 allowNewOption={false}
                                 closeMenuOnSelect={false}
                                 className="multi-select-field">
            </PL_MultiSelectField>
          </InputSection>
        }

        <Button id='submit' bsStyle="primary" onClick={this.props.handleSubmit(this.onSubmit)}>{this.labels.get('search')}</Button>

      </div>
    );
  }

};
export default reduxForm({
    form: 'OnHandInventoryHeader'
  }
)(OnHandInventoryHeader);

