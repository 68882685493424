import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { MatSettingsMenuItemsIds } from '../../../../enums/navigationMenuItemsIds';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ShiftsScheduler from '../../Common/ShiftsScheduler/shiftsScheduler';
import ShiftsPanel from '../../Common/ShiftsScheduler/shiftsPanel';
import WeekNavigation from './weekNavigation';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import ShiftSettingsDialog from '../../Common/ShiftsScheduler/Dialogs/shiftSettingsDialog';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import ShiftsSchedulerCell from '../../Common/ShiftsScheduler/shiftsSchedulerCell';
import { getWeekDaysAndDates } from '../../Common/ShiftsScheduler/shiftsSchedulerHelpers';
import Button from 'infrastructure/js/components/controls/Button/button';
import { ElementSelectionProvider, useElementSelectionContext } from 'infrastructure/js/contexts/elementSelectionContext';
import { filterTypes } from '../../../../enums/shiftSchedulerEnums';

import './weeklyShiftsView.scss';

export default function WeeklyShiftsView({ actions={},
                                           sShiftsSchedulerData={},
                                           sShiftSettingsDialogData={},
                                           sWeeklyShiftsViewData=[]
                                          }) {
  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.weeklyShifts.view.'), []);

  useEffect(() => {
    actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_WEEKLY_SHIFTS);

    actions.init(true);

    return () => actions.shiftsSchedulerActions.unmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadPage = () => {
    actions.init();
  };

  const weekStartDate = sWeeklyShiftsViewData.get('weekStartDate');
  const columnsConfig = useMemo(() => {
    return getWeekDaysAndDates(weekStartDate).map((weekDay) => ({
      id: weekDay.date,
      title: <DayHeaderTitle dayData={weekDay} />,
      valueGetter: (rowData) => {
        const currentDayAssignments = rowData?.assignments?.filter((assignment) => assignment.day === weekDay.day);
        const currentDayShifts = currentDayAssignments?.reduce((accumulator, assignment) => {
          return [...accumulator, ...assignment.shifts]
        }, [])

        return {
          assignments: {
            shifts: currentDayShifts,
            day: weekDay.day,
            workDate: weekDay.date
          },
          resourceData: rowData,
          day: weekDay.day,
          date: weekDay.date,
        };
      },
      cellComponent: ShiftsSchedulerCell,
      headerData: {
        day: weekDay.day,
        date: weekDay.date,
      },
    }));
  }, [weekStartDate]);

  return (
    <div className="administration-view weekly-shifts-view">
      <span className="title">{labels.get('title')}</span>
      <ElementSelectionProvider>
        <WeeklyShiftsHeader actions={actions} weekStartDate={weekStartDate} labels={labels} />
        <div className="shift-scheduler-wrapper">
          <ShiftsScheduler
            data={sShiftsSchedulerData.get('shiftsSchedulerData')}
            columnsConfig={columnsConfig}
            actions={actions.shiftsSchedulerActions}
          />
        </div>
      </ElementSelectionProvider>

      <ShiftsPanel shiftTypes={sShiftsSchedulerData.get('shiftTypes')} />

      {(sShiftsSchedulerData.get('loadingShifts') || sShiftsSchedulerData.get('loadingData')) && <Overlay.Loading />}

      <PL_DialogWrapper
        dialogComponent={ShiftSettingsDialog}
        show={sShiftSettingsDialogData.get('show')}
        actions={actions.shiftSettingsDialogActions}
        sData={sShiftSettingsDialogData}
        reloadParentComponent={reloadPage}
      />
    </div>
  );
}

WeeklyShiftsView.propTypes = {
  actions: PropTypes.object,
  sShiftsSchedulerData: PropTypes.object,
  sShiftSettingsDialogData: PropTypes.object,
  sWeeklyShiftsViewData: PropTypes.object,
};

function DayHeaderTitle({ dayData={} }) {
  return (
    <div className="day-header-title">
      <span>{dayData.day}</span>
      <span>({dayData.date?.format('DD/MM')})</span>
    </div>
  );
}

DayHeaderTitle.propTypes = {
  dayData: PropTypes.object,
};


function WeeklyShiftsHeader({ actions, weekStartDate, labels }) {
  const { selected, setSelected } = useElementSelectionContext();

  const handleEditSelected = () => {
    if (!selected.length) {
      return;
    }

    if (selected.length > 1) {
      const shifts = selected.map(({ data }) => data.shiftData);
      actions.showShiftSettingsDialog({ shifts, isMultiSelectedShifts: true });
      return;
    }

    const assignmentData = selected[0].data;
    actions.showShiftSettingsDialog({ ...assignmentData, isMultiSelectedShifts: false });
  };

  const handleRemoveSelected = () => {
    const filterData = {
      filterBy: filterTypes.SELECTED,
    };

    actions.shiftsSchedulerActions.removeShifts({ filterData, assignments: selected.map((item) => item.data) }).then((res) => setSelected([]));
  };

  return (
    <div className="header">
      <WeekNavigation updateWeekStartDate={actions.updateWeekStartDate} init={actions.init} weekStartDate={weekStartDate} labels={labels} />
      <div className="header-right">
        <Button
          id="redeployTemplate"
          name="redeployTemplate"
          className={'border-btn right-side shift-scheduler-action'}
          onClick={actions.redeployTemplate}
        >
          {labels.get('header.redeployTemplate')}
        </Button>
        <Button
          id="removeSelected"
          name="removeSelected"
          className={'border-btn right-side shift-scheduler-action'}
          onClick={handleRemoveSelected}
          disabled={!selected.length}
        >
          {labels.get('header.removeSelected')} {selected.length > 0 && `(${selected.length})`}
        </Button>
        <Button
          id="editAssignments"
          name="editAssignments"
          className={'border-btn right-side shift-scheduler-action'}
          onClick={handleEditSelected}
          disabled={!selected.length}
        >
          {labels.get('header.edit')}
        </Button>
      </div>
    </div>
  );
}
