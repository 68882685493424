// @ts-check

import { E_CAMERA_EVENT, E_SDK_EVENT, MapView } from "@mappedin/mappedin-js";
import { useCallback, useEffect } from "react";

/**
 * @typedef {import("@mappedin/mappedin-js").CAMERA_EVENT_PAYLOAD} CAMERA_EVENT_PAYLOAD
 * 
 */


/**
 * @param {MapView | undefined | null} mapView
 * @param {(payload: CAMERA_EVENT_PAYLOAD[E_CAMERA_EVENT.CHANGED]) => void} onCameraChanged
 */
export function useMapCameraChanged(mapView, onCameraChanged) {


  const handleCameraChanged = useCallback(
    
    /**
    * @param {CAMERA_EVENT_PAYLOAD[E_CAMERA_EVENT.CHANGED]} payload
    */
    (payload) => {
      onCameraChanged(payload);
    },

    [onCameraChanged]
  );


  // Subscribe to 
  useEffect(() => {

    if (mapView == null) {
      return;
    }
    // console.log('initializing map zoom handler');

    mapView.Camera.on(E_CAMERA_EVENT.CHANGED, handleCameraChanged);

    // Cleanup
    return () => {
      // console.log('cleaning up map zoom handler');
      try {
        mapView.Camera.off(E_CAMERA_EVENT.CHANGED, handleCameraChanged);
      } catch (e) {
        console.log('Error cleaning up map zoom handler ===> ', e);
      }
    };

  }, [mapView, handleCameraChanged]);

}

