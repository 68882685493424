import * as rollsService from '../../services/Rolls/rollsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import UnitHelper, { unitTypes } from "../../../../infrastructure/js/utils/uomHelper";
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ACTIVE_ROLLS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return rollsService.fetchRolls(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let rolls = rows.map((obj, index) => {
    if (obj.expirationDate) {
      obj.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }
    obj.m_lengthLeft = obj.lengthLeft ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, obj.lengthLeft, 2) : 0;
    obj.m_lengthLeftLabel = `${obj.m_lengthLeft} ${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)}`;

    obj.m_weightLabel = UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, obj.weight, 2);

    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }
    
    return obj;
  });

  return rolls;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
