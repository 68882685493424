import { useMemo } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';
import { getRoutesFor } from '../../../utils/routerHelper';
import { jsxActions as logsPageActions } from '../../../actions/Administration/LogsPage/logsPageActions';
import { LogsMenuItemsIds } from '../../../enums/navigationMenuItemsIds';
import { useEffect } from 'react';
import Page from '../Common/Page/page';
import SchedulingLogsView from './SchedulingLogsView/schedulingLogsView';
import NotificationLogsView from './NotificationLogsView/notificationLogsView';
import PageNotFoundPageContainer from '../../../containers/PageNotFoundPage/pageNotFoundPageContainer.js';

import './logsPage.scss';

const stateSelector = (state) => {
  return {
    sSelectedMenuItemId: state.administration.get('logsPage').selectedMenuItemId,
  };
};

export default function LogsPage(params) {
  const labels = useMemo(() => createLabelHelper('mat.administration.logs.sidenavigator.'), []);
  const { state, actions } = useRedux(logsPageActions, stateSelector);

  const navigationItems = [
    {
      id: LogsMenuItemsIds.NAV_ELEMENT_SCHEDULER_LOGS,
      label: labels.get('menuitem.schedulingLogs'),
      link: '/Administration/logs/schedulingLogs',
      component: SchedulingLogsView,
      isAllowed: true,
    },
    {
      id: LogsMenuItemsIds.NAV_ELEMENT_NOTIFICATION_LOGS,
      label: labels.get('menuitem.notificationLogs'),
      link: '/Administration/logs/notificationLogs',
      component: NotificationLogsView,
      isAllowed: true,
    },
  ];

  useEffect(() => {
    actions.init(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultRouteConfig = () => {
    return {
      key: `${navigationItems?.[0]?.id}_default`,
      path: '/Administration/logs',
      redirectPath: navigationItems?.[0]?.items?.[0]?.link || navigationItems?.[0]?.link,
      routeNotFoundComponent: PageNotFoundPageContainer,
    };
  };

  return (
    <Page
      className="logs-page"
      selectedMenuItemId={state.sSelectedMenuItemId}
      sideNavigatorMenuItems={navigationItems}
      actions={actions}
      routes={getRoutesFor(navigationItems, getDefaultRouteConfig())}
    />
  );
}
