import React from 'react';
import PropTypes from 'prop-types';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import { enumTypes, getEnumValue } from '../../../../../../utils/enumHelper';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import './operationListItem.scss';

const ComboboxPortal = withPortal(Combobox);

export default class OperationListItem extends React.PureComponent {
    onChangeCallback = (value, oldValue) => {
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(value, oldValue, this.props.index);
        }
    };


    stationTypeSpecificValidation = (value, allValues) => {
        if (this.props.name?.includes('secondaryOperations')) {
            const cutOperationType = getEnumValue(enumTypes.OPERATION_TYPE)('CUT');
            const kitOperationType = getEnumValue(enumTypes.OPERATION_TYPE)('KIT');

            switch (this.props.stationType) {
                case getEnumValue(enumTypes.LOCATION_TYPE)('KIT'):
                    if (this.isOperationAlreadySelected(cutOperationType, value, allValues)) {
                        this.props.touch(this.props.name + '.[operation]');
                        return this.props.labels.get('validation.operationType');
                    }
                    break;
                case getEnumValue(enumTypes.LOCATION_TYPE)('CUT'):
                    if (this.isOperationAlreadySelected(kitOperationType, value, allValues)) {
                        this.props.touch(this.props.name + '.[operation]');
                        return this.props.labels.get('validation.operationType');
                    }
                    break;
                case getEnumValue(enumTypes.LOCATION_TYPE)('CUT_KIT'):
                    return undefined;
                default:
                    if (
                        this.isOperationAlreadySelected(kitOperationType, value, allValues) ||
                        this.isOperationAlreadySelected(cutOperationType, value, allValues)
                    ) {
                        this.props.touch(this.props.name + '.[operation]');
                        return this.props.labels.get('validation.operationType');
                    }
                    break;
            }
        }
        return undefined;
    };

    isOperationAlreadySelected = (operationType, value, allValues) => {
        return (
            this.props.getOperationTypeById(value) === operationType &&
            allValues.secondaryOperations.some((value, index) => {
                return (
                    this.props.getOperationTypeById(value.operation) === operationType &&
                    this.props.index !== index
                );
            })
        );
    };

    render() {
        let { options, name } = this.props;

        return (
            <div className='operation-list-item'>
                <ComboboxPortal
                    id={name + '.[operation]'}
                    name={name + '.[operation]'}
                    validate={[Validation.dropdown.required, this.stationTypeSpecificValidation]}
                    options={options}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(options)}
                    onChangeCallback={this.onChangeCallback}
                    isDisabled={this.props.disabled}
                />
            </div>
        );
    }
}

OperationListItem.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    getOperationTypeById: PropTypes.func,
    stationType: PropTypes.string,
    labels: PropTypes.object,
    options: PropTypes.array,
};
