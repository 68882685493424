// @ts-check


/**
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchRequestModel} LiveSearchRequestModel
 * 
 * 
 */


export default class LiveSearchUtils {
  
  
  static ABSOLUTE_MAX_ITEMS_PER_BLOCK = 20;
  
  

  /** 
  * @param {LiveSearchRequestModel} requestModel
  * @param {string} filterType 
  * 
  * @returns {LiveSearchRequestModel}
  * 
  * */
  static modifySearchRequestModelByFilter = (requestModel, filterType) => {
    
    // console.log('filterType ===> ', filterType);

    if (filterType === 'ALL') {
      // requestModel.types = ["TOOL", "MATERIAL", "LOCATION", "WO"];
      requestModel.types = ["TOOL", "MATERIAL", "LOCATION"];
      requestModel.max = 3;
      return requestModel;
    }

    if (filterType === 'TOOLS') {
      requestModel.types = ["TOOL"];
      requestModel.max = 20;
      return requestModel;
    }

    if (filterType === 'MATERIALS') {
      requestModel.types = ["MATERIAL"];
      requestModel.max = 20;
      return requestModel;
    }

    if (filterType === 'LOCATIONS') {
      requestModel.types = ["LOCATION"];
      requestModel.max = 20;
      return requestModel;
    }

    if (filterType === 'WO') {
      requestModel.types = ["WO"];
      requestModel.max = 20;
      return requestModel;
    }

    throw new Error('Unknown filter type');
  }


  /** 
  * @param {LiveSearchRequestModel} requestModel
  * @param {number} locationId 
  * 
  * @returns {LiveSearchRequestModel}
  * 
  * */
  static createSearchRequestModelForLocation_More = (requestModel, locationId) => {

    let resultModel = {...requestModel};
    
    resultModel.locationId = locationId;
    resultModel.max = this.ABSOLUTE_MAX_ITEMS_PER_BLOCK;
    
    return resultModel;
  }



}








