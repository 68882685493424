import React from 'react';
import PropTypes from 'prop-types';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Label from 'infrastructure/js/components/Label/label.js';
import cn from 'classnames';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import './toolTypeKitTypeListItem.scss';

const ComboboxPortal = withPortal(Combobox);

export default class ToolTypeKitTypeListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      capacity: props.preSelectedItem?.capacity || 1,
      tokensPerPart: props.preSelectedItem?.tokensPerPart || null,
    }
  }

  onItemChangeCallback = (value, oldValue) => {

    this.props.onChangeCallback?.(value, oldValue, this.props.index);

    this.setState({tokensPerPart: value?.data?.tokensPerPart || null})
    let reduxValue = this.state.capacity && value?.data?.tokensPerPart ? this.state.capacity * value?.data?.tokensPerPart : null;
    this.props.change(this.props.name + '.[tokenValue]', reduxValue);

    this.props.onItemChangeCallback?.();
  };

  onValueChangeCallback = (value, oldValue) => {
    this.setState({capacity: +value || null});
    let reduxValue = +value && this.state.tokensPerPart ? +value * this.state.tokensPerPart : null;
    this.props.change(this.props.name + '.[tokenValue]', reduxValue);

    this.props.onItemChangeCallback?.();
  }

  getComponentToRender = (props) => {
    return props && props.data ? <Label text={props.data.label} /> : null;
  };

  render() {
    const { options, name  } = this.props;

    return (
      <div className={cn('tool-type-kit-type-list-item')}>
        <div className="column">
          <ComboboxPortal
            id={name + '.[kitTypeName]'}
            name={name + '.[kitTypeName]'}
            options={options}
            parse={Parse.comboValueOnly()}
            format={Format.findOptionByValue(options)}
            onChangeCallback={this.onItemChangeCallback}
            validate={Validation.required}
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getComponentToRender}
          />
        </div>

        <div className="column">
          <TextField id={ name + '.[capacity]'}
                     name={ name + '.[capacity]'}
                     normalize={Normalize.number(true, 1, 99999)}
                     validate={Validation.required}
                     onChangeCallback={this.onValueChangeCallback}
          />
        </div>

        <div className="column">
          <TextField id={ name + '.[tokenValue]'}
                     name={ name + '.[tokenValue]'}
                     disabled={true}
          />
        </div>
      </div>
    );
  }
}

ToolTypeKitTypeListItem.defaultProps = {
  options: [],
};

ToolTypeKitTypeListItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};
