import PermissionManager from '../../../../infrastructure/js/utils/permissionManager';
import { navigationStates } from '../../enums/navigationStates';
import { getPathByType } from '../../utils/navigationHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { AppMainMenuItemsIds } from '../../enums/navigationMenuItemsIds';
import { getNavigationStateByHomePageType } from '../../utils/navigationHelper';
import { enumTypes, getEnumValue } from '../../utils/enumHelper';
import icons from '../../../assets/svg';
import { livemapIcons } from '../../../assets/svg';
import { isSandboxOrg } from 'infrastructure/js/utils/appHelper';

export const settingsLinkPath = getPathByType(navigationStates.SETTINGS);
export const liveMapLinkPath = getPathByType(navigationStates.LIVE_MAP);

export function getNavigationElements(sData, topNavigatorData, featureFlags) {
  const labels = createLabelHelper('mat.sideNavigator.');

  if (featureFlags.ORGANIZATIONS) {
    return [
      {
        id: AppMainMenuItemsIds.NAV_ELEMENT_ORGS_MANAGEMENT,
        label: labels.get('administration.orgsmanagement'),
        icon: _getIconByType(navigationStates.ORG_MANAGEMENT),
        isAllowed: true,
        items: [
          {
            entityType: navigationStates.ORG_MANAGEMENT,
            icon: _getIconByType(navigationStates.ORG_MANAGEMENT),
            link: getPathByType(navigationStates.ORG_MANAGEMENT),
            label: labels.get('administration.orgsmanagement'),
            isAllowed: true,
          },
          {
            entityType: navigationStates.DEMO_ORG_MANAGEMENT,
            icon: _getIconByType(navigationStates.DEMO_ORG_MANAGEMENT),
            link: getPathByType(navigationStates.DEMO_ORG_MANAGEMENT),
            label: labels.get('administration.demoOrgsManagement'),
            isAllowed: true,
          },
          {
            entityType: navigationStates.USER_MANAGEMENT,
            icon: _getIconByType(navigationStates.USER_MANAGEMENT),
            link: getPathByType(navigationStates.USER_MANAGEMENT),
            label: labels.get('administration.usermanagement'),
            isAllowed: _getPermissionByNavState(navigationStates.USER_MANAGEMENT),
          },
        ],
      },
      ...(PermissionManager.hasLoginManagementPagePermissions()  ? [
        {
          id: AppMainMenuItemsIds.NAV_ELEMENT_LOGIN_MANAGEMENT,
          label: labels.get('administration.loginManagement'),
          icon: _getIconByType(navigationStates.LOGIN_MANAGEMENT),
          isAllowed: true,
          items: [
            {
              entityType: navigationStates.PROMOTIONS_MANAGEMENT,
              icon: _getIconByType(navigationStates.PROMOTIONS_MANAGEMENT),
              link: getPathByType(navigationStates.PROMOTIONS_MANAGEMENT),
              label: labels.get('administration.promotionsManagement'),
              isAllowed: true,
            },
          ]
        }] : []),
    ];
  }


  const favorites = _getFavorites(sData.get('favorites'));
  const locations = _getLocationsAndStations(sData.get('locationsAndStations'));
  const maps = _getMaps(sData.get('maps'));

  let navigatorItems = [
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES,
      label: labels.get('section.favorites'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES),
      items: favorites,
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER,
      label: labels.get('section.scheduler'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.SCHEDULER_GANTT,
          icon: _getIconByType(navigationStates.SCHEDULER_GANTT),
          link: getPathByType(navigationStates.SCHEDULER_GANTT),
          label: labels.get('schedulerGantt'),
          isAllowed: _getPermissionByNavState(navigationStates.SCHEDULER_GANTT),
        },
        {
          entityId: -1,
          entityType: navigationStates.SCHEDULER_TASKS,
          icon: _getIconByType(navigationStates.SCHEDULER_TASKS),
          link: getPathByType(navigationStates.SCHEDULER_TASKS),
          label: labels.get('schedulerTasks'),
          isAllowed: _getPermissionByNavState(navigationStates.SCHEDULER_TASKS),
        },
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER,
      label: labels.get('section.operations'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.SMART_SELECTION,
          icon: _getIconByType(navigationStates.SMART_SELECTION),
          link: getPathByType(navigationStates.SMART_SELECTION),
          label: labels.get('materialSmartSelection'),
          isAllowed: _getPermissionByNavState(navigationStates.SMART_SELECTION),
        },
        {
          entityId: -1,
          entityType: navigationStates.PICK_LISTS,
          icon: _getIconByType(navigationStates.PICK_LISTS),
          link: getPathByType(navigationStates.PICK_LISTS),
          label: labels.get('pickLists'),
          isAllowed: _getPermissionByNavState(navigationStates.PICK_LISTS),
        },
        {
          entityId: -1,
          entityType: navigationStates.ON_HAND_INVENTORY,
          icon: _getIconByType(navigationStates.ON_HAND_INVENTORY),
          link: getPathByType(navigationStates.ON_HAND_INVENTORY),
          label: labels.get('onHandInventory'),
          isAllowed: _getPermissionByNavState(navigationStates.ON_HAND_INVENTORY),
        },
        {
          entityId: -1,
          entityType: navigationStates.WORK_ORDERS,
          icon: _getIconByType(navigationStates.WORK_ORDERS),
          link: getPathByType(navigationStates.WORK_ORDERS),
          label: labels.get('workorders'),
          isAllowed: _getPermissionByNavState(navigationStates.WORK_ORDERS),
        },
        {
          entityId: -1,
          entityType: navigationStates.LOTS,
          icon: _getIconByType(navigationStates.LOTS),
          link: getPathByType(navigationStates.LOTS),
          label: labels.get('lots'),
          isAllowed: _getPermissionByNavState(navigationStates.LOTS),
        },
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER,
      label: labels.get('section.assets'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.RAW_MATERIALS,
          icon: _getIconByType(navigationStates.RAW_MATERIALS),
          link: getPathByType(navigationStates.RAW_MATERIALS),
          label: labels.get('rawMaterials'),
          isAllowed: _getPermissionByNavState(navigationStates.RAW_MATERIALS),
        },
        {
          entityId: -1,
          entityType: navigationStates.PARTS,
          icon: _getIconByType(navigationStates.PARTS),
          link: getPathByType(navigationStates.PARTS),
          label: labels.get('parts'),
          isAllowed: _getPermissionByNavState(navigationStates.PARTS),
        },
        {
          entityId: -1,
          entityType: navigationStates.KITS,
          icon: _getIconByType(navigationStates.KITS),
          link: getPathByType(navigationStates.KITS),
          label: labels.get('kits'),
          isAllowed: _getPermissionByNavState(navigationStates.KITS),
        },
        {
          entityId: -1,
          entityType: navigationStates.TOOLS,
          icon: _getIconByType(navigationStates.TOOLS),
          link: getPathByType(navigationStates.TOOLS),
          label: labels.get('tools'),
          isAllowed: _getPermissionByNavState(navigationStates.TOOLS),
        },
        {
          entityId: -1,
          entityType: navigationStates.GROUPS,
          icon: _getIconByType(navigationStates.GROUPS),
          link: getPathByType(navigationStates.GROUPS),
          label: labels.get('groups'),
          isAllowed: _getPermissionByNavState(navigationStates.GROUPS),
        },
        {
          entityId: -1,
          entityType: navigationStates.MATERIAL_SCRAP,
          icon: _getIconByType(navigationStates.MATERIAL_SCRAP),
          link: getPathByType(navigationStates.MATERIAL_SCRAP),
          label: labels.get('scrap'),
          isAllowed: _getPermissionByNavState(navigationStates.MATERIAL_SCRAP),
        },
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS,
      label: labels.get('section.locationsStations'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS),
      items: locations,
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS),
    },
    //only for ADMIN, PS_USER, SYS_ADMIN, RFID_ADMIN_USER
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION,
      label: labels.get('section.administration'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.MAT_SETTINGS,
          icon: _getIconByType(navigationStates.MAT_SETTINGS),
          link: getPathByType(navigationStates.MAT_SETTINGS),
          label: labels.get('masterData'),
          isAllowed: _getPermissionByNavState(navigationStates.MAT_SETTINGS),
        },
        {
          entityId: -1,
          entityType: navigationStates.USER_SETTINGS,
          icon: _getIconByType(navigationStates.USER_SETTINGS),
          link: getPathByType(navigationStates.USER_SETTINGS),
          label: labels.get('userSettings'),
          isAllowed: _getPermissionByNavState(navigationStates.USER_SETTINGS),
        },
        {
          entityId: -1,
          entityType: navigationStates.RFID_SETTINGS,
          icon: _getIconByType(navigationStates.RFID_SETTINGS),
          link: getPathByType(navigationStates.RFID_SETTINGS),
          label: labels.get('rfidSettings'),
          isAllowed: _getPermissionByNavState(navigationStates.RFID_SETTINGS),
        },
        {
          entityId: -1,
          entityType: navigationStates.BILLING,
          icon: _getIconByType(navigationStates.BILLING),
          link: getPathByType(navigationStates.BILLING),
          label: labels.get('billing'),
          isAllowed: _getPermissionByNavState(navigationStates.BILLING),
        },
        {
          entityId: -1,
          entityType: navigationStates.SANDBOX,
          icon: _getIconByType(navigationStates.SANDBOX),
          link: getPathByType(navigationStates.SANDBOX),
          label: labels.get('sandbox'),
          isAllowed: _getPermissionByNavState(navigationStates.SANDBOX),
        },
        {
          entityId: -1,
          entityType: navigationStates.PS_MANAGEMENT,
          icon: _getIconByType(navigationStates.PS_MANAGEMENT),
          link: getPathByType(navigationStates.PS_MANAGEMENT),
          label: labels.get('psManagement'),
          isAllowed: _getPermissionByNavState(navigationStates.PS_MANAGEMENT),
        },
        {
          entityId: -1,
          entityType: navigationStates.LOGS,
          icon: _getIconByType(navigationStates.LOGS),
          link: getPathByType(navigationStates.LOGS),
          label: labels.get('logs'),
          isAllowed: _getPermissionByNavState(navigationStates.LOGS),
        },
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_REPORTS,
      label: labels.get('section.reports'),
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_REPORTS),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_REPORTS),
      items: [
        {
          entityId: -1,
          entityType: navigationStates.MANAGER_REPORT,
          icon: _getIconByType(navigationStates.MANAGER_REPORT),
          link: getPathByType(navigationStates.MANAGER_REPORT),
          label: labels.get('managerView'),
          isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.MANAGER_REPORT),
        },
        {
          entityId: -1,
          entityType: navigationStates.PRODUCTION_DASHBOARD,
          icon: _getIconByType(navigationStates.PRODUCTION_DASHBOARD),
          link: getPathByType(navigationStates.PRODUCTION_DASHBOARD),
          label: labels.get('productionDashboard'),
          isAllowed: _getPermissionByNavState(navigationStates.PRODUCTION_DASHBOARD),
        },
        {
          entityId: -1,
          entityType: navigationStates.PROGRESS_DASHBOARD,
          icon: _getIconByType(navigationStates.PROGRESS_DASHBOARD),
          link: getPathByType(navigationStates.PROGRESS_DASHBOARD),
          label: labels.get('progressDashboard'),
          isAllowed: _getPermissionByNavState(navigationStates.PROGRESS_DASHBOARD),
        },
        {
          entityId: -1,
          entityType: navigationStates.TOOL_MAINTENANCE_DASHBOARD,
          icon: _getIconByType(navigationStates.TOOL_MAINTENANCE_DASHBOARD),
          link: getPathByType(navigationStates.TOOL_MAINTENANCE_DASHBOARD),
          label: labels.get('toolsMaintenanceDashboard'),
          isAllowed: _getPermissionByNavState(navigationStates.TOOL_MAINTENANCE_DASHBOARD),
        },
        {
          entityId: -1,
          entityType: navigationStates.INVENTORY_DASHBOARD,
          icon: _getIconByType(navigationStates.INVENTORY_DASHBOARD),
          link: getPathByType(navigationStates.INVENTORY_DASHBOARD),
          label: labels.get('inventoryDashboard'),
          isAllowed: _getPermissionByNavState(navigationStates.INVENTORY_DASHBOARD),
        },
      ],
    },
    {
      id: AppMainMenuItemsIds.NAV_ELEMENT_LIVE_MAP,
      label: labels.get('section.maps'),
      // label: "Maps",
      icon: _getIconByType(AppMainMenuItemsIds.NAV_ELEMENT_LIVE_MAP),
      isAllowed: _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_LIVE_MAP),
      items: [

        ...maps,

      ],
    },




  ];

  _markHomeAndFavorites(navigatorItems, favorites, topNavigatorData?.get('homePage'));

  return navigatorItems;
}

function _markHomeAndFavorites(navigatorItems, favorites, homePageData) {
  if (favorites) {
    navigatorItems.forEach((section) => {
      section.items.forEach((item) => {
        item.isFavorite = favorites.some((favorite) => {
          return favorite.entityType === item.entityType && favorite.entityId === item.entityId;
        });

        const homePageType = homePageData.get('type');
        const homePageId = homePageData.get('id');
        const _homePageType = getNavigationStateByHomePageType(homePageType);
        item.isHomePage = item.entityType === _homePageType && homePageId === item.entityId;
      });
    });
  }
}

function _getFavorites(favorites) {
  return favorites.map((favorite) => {
    const icon = _getIconByType(favorite.entityType);
    return {
      ...favorite,
      icon,
      link: getPathByType(favorite.entityType, favorite.entityId),
      isFavoriteMenu: true,
      isAllowed: true,
    };
  }).filter((favorite) => favorite.link);
}

function _getLocationsAndStations(locations) {
  return locations.map((location) => {
    const icon = _getIconByType(location.entityType);
    const isAllowed = _getLocationPermission(location.locationType);
    return {
      ...location,
      icon,
      link: getPathByType(navigationStates.LOCATION, location.entityId),
      isAllowed,
    };
  });
}


function _getMaps(sMaps) {

  let isAllowed = _getPermissionByNavState(AppMainMenuItemsIds.NAV_ELEMENT_LIVE_MAP);

  return sMaps.map((x) => {
    const icon = _getIconByType(navigationStates.LIVE_MAP);
    return {
      ...x,
      icon,
      link: getPathByType(navigationStates.LIVE_MAP, x.entityId),
      isAllowed,
    };
  });

}

function _getIconByType(type) {
  switch (type?.toUpperCase()) {
    case navigationStates.LIVE_MAP:
      return livemapIcons.liveMapSmall;
    case navigationStates.KITS:
      return icons.kitSmall;
    case navigationStates.GROUPS:
      return icons.groupSmall;
    case navigationStates.TOOLS:
      return icons.toolSmall;
    case navigationStates.STATION:
      return icons.stationSmall;
    case navigationStates.LOCATION:
      return icons.locationSmall;
    case navigationStates.NOTIFICATIONS:
      return icons.notifications;
    case navigationStates.PRODUCTION_DASHBOARD:
      return icons.productionDashboardSmall;
    case navigationStates.PROGRESS_DASHBOARD:
      return icons.progressDashboardSmall;
    case navigationStates.INVENTORY_DASHBOARD:
      return icons.inventoryDashboardSmall;
    case navigationStates.TOOL_MAINTENANCE_DASHBOARD:
      return icons.toolMaintenanceSmall;
    case navigationStates.MATERIAL_SCRAP:
      return icons.scrapSmall;
    case navigationStates.SMART_SELECTION:
      return icons.materialSmartSelection;
    case navigationStates.PICK_LISTS:
    case navigationStates.PICK_LIST:
      return icons.pickList;
    case navigationStates.LOT:
    case navigationStates.LOTS:
      return icons.lot;
    case navigationStates.SCHEDULER_GANTT:
    case navigationStates.SCHEDULER_TASKS:
      return icons.schedulerSmall;
    case navigationStates.ON_HAND_INVENTORY:
      return icons.onHandInventory;
    case navigationStates.RAW_MATERIALS:
      return icons.rollSmall;
    case navigationStates.MANAGER_REPORT:
      return icons.managerReport;
    case navigationStates.ORG_MANAGEMENT:
    case navigationStates.DEMO_ORG_MANAGEMENT:
      return icons.matSettings;
    case navigationStates.LOGIN_MANAGEMENT:
    case navigationStates.PROMOTIONS_MANAGEMENT:
      return icons.circledUser;
    case navigationStates.WORK_ORDERS:
      return icons.workOrder;
    case navigationStates.PARTS:
      return icons.part;
    case navigationStates.MAT_SETTINGS:
      return icons.tpoMasterDataSmall;
    case navigationStates.USER_MANAGEMENT:
    case navigationStates.USER_SETTINGS:
      return icons.userManagementSmall;
    case navigationStates.RFID_SETTINGS:
      return icons.rfidSettingsSmall;
    case navigationStates.BILLING:
      return icons.billingSettingsSmall;
    case navigationStates.SANDBOX:
      return icons.sandbox;
    case navigationStates.PS_MANAGEMENT:
      return icons.psManagementSmall;
    case navigationStates.LOGS:
      return icons.logs;
    case AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION:
      return icons.user;
    case AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES:
      return icons.favorite;
    case AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER:
      return icons.schedulerBig;
    case AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER:
      return icons.operationsManager;
    case AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER:
      return icons.assetsManager;
    case AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS:
      return icons.locationsAndStationsBig;
    case AppMainMenuItemsIds.NAV_ELEMENT_REPORTS:
      return icons.report;
    case AppMainMenuItemsIds.NAV_ELEMENT_LIVE_MAP:
      return livemapIcons.liveMapSmall;


    default:
      return '';
  }
}

function _getPermissionByNavState(type) {
  switch (type?.toUpperCase()) {

    case navigationStates.LIVE_MAP:
      return true;


    case navigationStates.KITS:
    case navigationStates.TOOLS:
    case navigationStates.ON_HAND_INVENTORY:
    case navigationStates.RAW_MATERIALS:
    case navigationStates.WORK_ORDERS:
    case navigationStates.PARTS:
    case AppMainMenuItemsIds.MANAGER_REPORT:
    case AppMainMenuItemsIds.NAV_ELEMENT_ASSETS_MANAGER:
    case AppMainMenuItemsIds.NAV_ELEMENT_REPORTS:
    case AppMainMenuItemsIds.NAV_ELEMENT_FAVORITES:
      return PermissionManager.hasSelfSustainingOrgPermissions();
    case navigationStates.GROUPS:
      return (
        PermissionManager.hasSelfSustainingOrgPermissions() &&
        PermissionManager.hasGroupAssetPermissions() &&
        PermissionManager.getOrgPreferences().groupsEnabled
      );
    case navigationStates.STATION:
    case navigationStates.LOCATION:
    case navigationStates.LOT:
    case navigationStates.LOTS:
    case AppMainMenuItemsIds.NAV_ELEMENT_OPERATIONS_MANAGER:
    case AppMainMenuItemsIds.NAV_ELEMENT_LOCATIONS_AND_STATIONS:
      return (
        PermissionManager.hasSelfSustainingOrgPermissions() ||
        PermissionManager.hasSupplierOrgPermissions() ||
        PermissionManager.hasCustomerOrgPermissions()
      );
    case navigationStates.PRODUCTION_DASHBOARD:
    case navigationStates.PROGRESS_DASHBOARD:
    case navigationStates.TOOL_MAINTENANCE_DASHBOARD:
      return PermissionManager.hasSelfSustainingOrgPermissions() && PermissionManager.hasDashboardsTabPermissions();
    case navigationStates.INVENTORY_DASHBOARD:
      return PermissionManager.hasSelfSustainingOrgPermissions() && PermissionManager.hasInventoryDashboardsTabPermissions();
    case navigationStates.MATERIAL_SCRAP:
      return PermissionManager.hasSelfSustainingOrgPermissions() && PermissionManager.hasScrapViewPermissions();
    case navigationStates.SMART_SELECTION:
    case navigationStates.PICK_LISTS:
    case navigationStates.PICK_LIST:
      return (
        PermissionManager.hasSelfSustainingOrgPermissions() &&
        PermissionManager.getOrgPreferences().smartSelectionEnabled &&
        PermissionManager.hasPredefinedViewSmartSelectionPermissions()
      );
    case navigationStates.RFID_SETTINGS:
      return PermissionManager.hasAdministrationTabPermissions() && PermissionManager.hasRfidTabPermissions();
    case navigationStates.BILLING:
      return PermissionManager.hasAdministrationTabPermissions() && PermissionManager.hasBillingTabPermissions();
    case navigationStates.SANDBOX:
      return PermissionManager.hasAdministrationTabPermissions() && PermissionManager.hasSandboxTabPermissions() && !isSandboxOrg();
    case navigationStates.PS_MANAGEMENT:
      return PermissionManager.hasAdministrationTabPermissions() && PermissionManager.hasPSManagementTabPermissions();
    case navigationStates.USER_MANAGEMENT:
      return PermissionManager.hasOrgPlataineUserManagementTabPermissions();
    case navigationStates.USER_SETTINGS:
    case navigationStates.MAT_SETTINGS:
    case navigationStates.LOGS:
    case AppMainMenuItemsIds.NAV_ELEMENT_ADMINISTRATION:
      return PermissionManager.hasAdministrationTabPermissions();
    case AppMainMenuItemsIds.NAV_ELEMENT_SCHEDULER:
      return (
        PermissionManager.hasSelfSustainingOrgPermissions() &&
        PermissionManager.getOrgPreferences().schedulerEnabled &&
        PermissionManager.hasPredefinedViewSchedulerPermissions()
      );
    case AppMainMenuItemsIds.NAV_ELEMENT_LIVE_MAP:
      return (
        PermissionManager.getOrgPreferences().livemapEnabled
      );
    case navigationStates.SCHEDULER_GANTT:
    case navigationStates.SCHEDULER_TASKS:
    case navigationStates.ORG_MANAGEMENT:
    case navigationStates.DEMO_ORG_MANAGEMENT:
      return true;
    default:
      return false;
  }
}

function _getLocationPermission(locationType) {
  switch (locationType) {
    case getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS'):
    case getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION'):
      return PermissionManager.hasSupplierOrgPermissions();
    case getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS'):
      return PermissionManager.hasCustomerOrgPermissions();
    default:
      return PermissionManager.hasSelfSustainingOrgPermissions();
  }
}
