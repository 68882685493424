// @ts-check

import { useMemo } from "react";
import { useMapCameraChanged as useMapCameraChanged } from "./Mappedin/useMapCameraChanged";
import React from "react";
import ZoomProcessor from "../Logic/ZoomProcessor";

// import { jsxActions as actions } from './../../../actions/LiveMap/liveMapPageActions';


/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapNodeRef} MapNodeRef
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").CameraEvent} CameraEvent
 * 
 * 
 */


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * 
 */
export default function useHandleCameraChanged(refContext) {

  let mapView = refContext.current.refMapView.current;

  /////////////////////////////////////////////////////
  // Memoize handler that closes over refContext.
  //
  let handler = useMemo(() => {
    let h = getCameraChangedHandler(refContext);
    return h;
  }, [mapView]);


  /////////////////////////////////////////////////////
  // Whenever handler function or mapView changes,
  // this hook will destroy the previous event listener
  // and create a new one.
  //
  // useMapClick(mapView, (p) => { console.log('p ===> ', p); });
  //
  useMapCameraChanged(mapView, handler);


  return [

  ];
}



/////////////////////////////////////////////////////


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * 
 * @returns {(payload: CameraEvent) => void}
 */
export let getCameraChangedHandler = (refContext) => {

  // console.log('getMapClickHandler ===> ');

  /**
   * @param {CameraEvent} payload
   */
  let cameraChangedHandler = (payload) => {
    // console.log('cameraChangeHandler ===> 1', payload);
    ZoomProcessor.processCameraChanged(refContext, payload);
  };

  return cameraChangedHandler;

};







