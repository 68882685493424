// @ts-check


import { CAMERA_EASING_MODE, COLLISION_RANKING_TIERS, MARKER_ANCHOR, MapView, Mappedin, MappedinNode, getVenue } from "@mappedin/mappedin-js";
import { PolygonInfo } from "../types/PolygonInfo";
import MapControl from "../MapControl/MapControl";

import { livemapIcons } from '../../../../assets/svg/index';
import { LiveMapIcons_STATIC as LiveMapAssetsIcons } from '../liveMapAssets';
import LinkBuilder from "./LinkBuilder";
import MapLabelManager from "../Utils/MapLabelManager";
import LabelManager from "../Utils/LabelManager";
import SearchResultsTransformer from "./SearchResultsTransformer";
import RefContextHelper from "../Utils/RefContextHelper";


/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapLocationSearchResultsBlock} MapLocationSearchResultsBlock
 * 
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultItem} LiveSearchResultItem
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultLocation} LiveSearchResultLocation
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").PL_SearchState} PL_SearchState
 * 
 * @typedef {import("@mappedin/mappedin-js/renderer/internal").SmartTooltip} SmartTooltip
 * 
 */


// import zzz from "../../../../assets/svg/livemap/livemap-marker-search-pin.png";


export class MapSearchResultsLogic {

  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   */
  static process = (refContext) => {

    let floorChanged = autoChangeFloorBasedOnData(refContext);
    // if (floorChanged) {
    //   // We modified sData, cya next render.
    //   console.log(' CYA NEXT RENDER ===> ', );
    //   return;
    // }

    // console.log(' WILL RENDER ===> ', );

    renderMarkers(refContext);
  }

}




////////////////////////////////////////
// Helper functions.
//

/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * 
 * @returns {boolean}
 */
function autoChangeFloorBasedOnData(refContext) {

  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return false;
  }

  let venue = mapView.venue;
  if (!venue) {
    return false;
  }

  let mapView_currentMapShortName = mapView.currentMap.shortName;
  // console.log('mapView_currentMapShortName ===> ', mapView_currentMapShortName);

  let sDataFloorName = refContext.current.refSData.current.floorName;
  // console.log('sDataFloorName ===> ', sDataFloorName);


  let searchResults = refContext.current.refSData.current.search.searchResults;

  let allItems = searchResults?.blocks.flatMap((block) => block.items);
  if (!allItems) {
    return false;
  }

  // console.log('allItems ===> ', allItems);

  
  let allPerLocationCounts = searchResults?.blocks.flatMap((block) => block.totalPerLocationCounts);
  if (!allPerLocationCounts) {
    return false;
  }
  
  // console.log('allPerLocationCounts ===> ', allPerLocationCounts);


  /////////////////////////////////////////////////////
  // If there are items to show on the current floor,
  // then don't change the floor.
  //
  let anyItemsFromCurrentFloor = allItems.some((item) => {
    return item.location.floorName === mapView_currentMapShortName;
  });
  
  if (anyItemsFromCurrentFloor) {
    return false;
  }

  
  /////////////////////////////////////////////////////
  // If there are counts to show on the current floor,
  // then don't change the floor.
  //
  let anyPerLocationCountsFromCurrentFloor = allPerLocationCounts.some((c) => {
    return c.floorName === mapView_currentMapShortName;
  });
  
  if (anyPerLocationCountsFromCurrentFloor) {
    return false;
  }


  /////////////////////////////////////////////////////////////
  // If there are no items to show on the current floor,
  // then change the floor to the floor of the 
  // first item with valid floor.
  //

  let firstValidItem = allItems.find((item) => {
    
    if (!item.location.floorName) {
      return false;
    }
    
    if (item.location.floorName === RefContextHelper.NO_FLOOR_NAME) {
      return false;
    }
    
    return true;
    
  });
  
  // console.log('firstValidItem ===> ', firstValidItem);
  if (!firstValidItem) {
    return false;
  }

  let firstItemFloorName = firstValidItem.location.floorName;
  if (!firstItemFloorName) {
    return false;
  }


  // Allow camera to animate again for the new floor.
  refContext.current.cameraAnimatedForSearchId = null;

  // Set the new floor to sData.
  MapControl.setFloorData(refContext, firstItemFloorName);

  return true;

}


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
function renderMarkers(refContext) {
  // console.log('renderMarkers ===> SEARCH');

  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return;
  }

  let venue = mapView.venue;
  if (!venue) {
    return;
  }

  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }



  let zoomLevel = refContext.current.refSData.current.normal.mapZoomLevel;



  //////////////////////////////////////////////////////////
  // Remove all normal markers if not in SEARCH mode.
  //
  let mapMode = refContext.current.refSData.current.mapMode;
  if (mapMode !== "SEARCH") {
    // console.log('----- REMOVING SEARCH MARKERS ===> ');

    polyData.polygons.forEach((polygonInfo) => {
      removeSearchMarker(polygonInfo, mapView);
      return;
    });

    return;
  }


  MapControl.setInteractivity__MODE_SEARCH(refContext);


  let searchResults = refContext.current.refSData.current.search.searchResults;
  let currentSarchId = refContext.current.refSData.current.search.searchResultsId;
  let currentSelectionId = refContext.current.refSData.current.search.resultSelection.selectionId;

  // console.log('searchResults ===> ', searchResults);


  let searchResultsByLocationNameMap = SearchResultsTransformer.createSearchResultsByLocationNameMap(searchResults);
  // console.log('searchResultsByLocationNameMap ===> ', searchResultsByLocationNameMap);


  let searchResultsView = SearchResultsTransformer.createResultsView(refContext, searchResults);
  if (searchResultsView) {
    // console.log('searchResultsView ===> ', searchResultsView);
    searchResults = searchResultsView;
    searchResultsByLocationNameMap = SearchResultsTransformer.createSearchResultsByLocationNameMap(searchResultsView);
    // console.log('searchResultsByLocationNameMap VIEW ===> ', searchResultsByLocationNameMap);
  }


  ////////////////////////////////////
  // Render markers
  //
  polyData.polygons.forEach((polygonInfo) => {

    let location = polygonInfo.locationData;
    if (!location) {
      return;
    }

    let node = polygonInfo.entranceNode;
    if (!node) {
      return;
    }


    // Don't rerender the marker if it's already rendered.
    if (currentSarchId === polygonInfo.searchMarkerForSearchId) {
      // console.log(' NOPE 1 ===> ', );

      if (currentSelectionId === polygonInfo.searchMarkerForSelectionId) {
        // console.log(' NOPE 2 ===> ', );

        return;
      }
    }



    let currentlySelectedNode = refContext.current.refCurrentMapNode.current;
    if (currentlySelectedNode?.id === node.id) {
      // console.log(' ================== TOOLTIP DETECTED ! ===> ', polygonInfo.markerId);
      removeSearchMarker(polygonInfo, mapView);
      return;
    }




    let matchingSearchResultBlock = searchResultsByLocationNameMap.get(location.name);
    if (!matchingSearchResultBlock) {
      removeSearchMarker(polygonInfo, mapView);
      return;
    }

    // console.log('matchingSearchResultBlock ===> ', location.name, matchingSearchResultBlock);

    // console.log('markerHtml? ===> ', location.name);
    let markerHtml = getMarkerHtml_SEARCH(matchingSearchResultBlock);
    if (!markerHtml) {
      return;
    }

    // console.log('markerHtml ===> ', location.name);

    let targetLink = getMarkerTargetLink_SEARCH(matchingSearchResultBlock);
    if (!markerHtml) {
      return;
    }

    if (polygonInfo.searchMarkerHTML === markerHtml) {
      return;
    }


    removeSearchMarker(polygonInfo, mapView);




    // Section Markers will not be clickable.
    let isInteractiveMarker = true;
    if (matchingSearchResultBlock.items.length === 1) {
      if (matchingSearchResultBlock.items[0].type === "SECTION") {
        isInteractiveMarker = false;
      }
    }


    // console.log('markerHtml ===> ', markerHtml);
    let marker = mapView.Markers.add(node, markerHtml, {
      anchor: MARKER_ANCHOR.RIGHT,
      rank: COLLISION_RANKING_TIERS.ALWAYS_VISIBLE,
      interactive: isInteractiveMarker,
    });
    polygonInfo.searchMarkerId = marker.id;
    polygonInfo.searchMarkerZoomLevel = zoomLevel;
    polygonInfo.searchMarkerHTML = markerHtml;
    polygonInfo.searchMarkerForSearchId = refContext.current.refSData.current.search.searchResultsId;
    polygonInfo.searchMarkerForSelectionId = refContext.current.refSData.current.search.resultSelection.selectionId;
    polygonInfo.searchMarkerTargetLink = targetLink;


  });



  MapControl.DOM_repositionAndActivateCurrentMarkers_with_delays();

  // focusCameraOnData(refContext);
  focusCameraOnData__Await_Till_Same_Floor(refContext);




}


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * @param {number} tryCount
 */
function focusCameraOnData__Await_Till_Same_Floor(refContext, tryCount = 0) {

  let DELAY_MSEC = 100;
  let MAX_TRIES = 10;


  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return;
  }

  let mapView_currentMapShortName = mapView.currentMap.shortName;
  // console.log('mapView_currentMapShortName ===> ', mapView_currentMapShortName);

  let sDataFloorName = refContext.current.refSData.current.floorName;
  // console.log('sDataFloorName ===> ', sDataFloorName);


  if (!sDataFloorName || sDataFloorName === RefContextHelper.NO_FLOOR_NAME) {
    return;
  }


  if (sDataFloorName !== mapView_currentMapShortName) {

    if (tryCount > MAX_TRIES) {
      console.log('Cannot animate camera. Data floor differs from current map view floor.',);
      return;
    }

    setTimeout(() => {
      // console.log('Focus camera retry ===> ', tryCount);
      focusCameraOnData__Await_Till_Same_Floor(refContext, tryCount + 1);
    }, DELAY_MSEC);

    return;
  }

  focusCameraOnData(refContext);

}






/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
function focusCameraOnData(refContext) {

  //////////////////////////////////////////////////////////////////////
  // Check if we already animated the camera for this search result.
  // Or for this search result + this user selection.
  //
  let currentSarchId = refContext.current.refSData.current.search.searchResultsId;
  let currentSelectionId = refContext.current.refSData.current.search.resultSelection.selectionId;

  let cameraAnimatedForSearchId = refContext.current.cameraAnimatedForSearchId;
  let cameraAnimatedForSelectionId = refContext.current.cameraAnimatedForSelectionhId;

  // console.log(` R ===> ${currentSarchId} [${cameraAnimatedForSearchId}] - ${currentSelectionId} [${cameraAnimatedForSelectionId}]`);

  if (cameraAnimatedForSearchId === currentSarchId) {
    // console.log(' NOPE A 1 ===> ',);

    if (cameraAnimatedForSelectionId === currentSelectionId) {
      // console.log(' NOPE A 2 ===> ',);
      return;
    }

  }

  // console.log(' ----------ANIMATE !!! ===> ');



  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }

  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return;
  }

  let mapView_currentMapShortName = mapView.currentMap.shortName;
  // console.log('mapView_currentMapShortName ===> ', mapView_currentMapShortName);

  let sDataFloorName = refContext.current.refSData.current.floorName;
  // console.log('sDataFloorName ===> ', sDataFloorName);

  if (sDataFloorName !== mapView_currentMapShortName) {
    console.log('Cannot animate camera. Data floor differs from current map view floor.',);
    return;
  }



  let currentLevelPolygonsWithMarkers = polyData.polygons.filter((polygonInfo) => {
    if (!polygonInfo.searchMarkerId) {
      return false;
    }
    if (polygonInfo.mapName !== mapView_currentMapShortName) {
      // console.log('NNNNNNOPE !!! ===> ', );
      return false;
    }

    return true;
  });

  // console.log('currentLevelPolygonsWithMarkers ===> ', currentLevelPolygonsWithMarkers);


  /** @type {MappedinNode[]} */
  let nodesToFocusOn = [];

  for (let i = 0; i < currentLevelPolygonsWithMarkers.length; i++) {
    let polygonInfo = currentLevelPolygonsWithMarkers[i];
    if (!polygonInfo) {
      continue;
    }
    if (!polygonInfo.entranceNode) {
      continue;
    }
    nodesToFocusOn.push(polygonInfo.entranceNode);
  }

  if (nodesToFocusOn.length === 0) {
    // console.log('---------- reset cam ===> ');
    // MapControl.setupCamera__GO_DEFAULT__SMOOTH(refContext);
    markAnimationComplete(refContext);
    return;
  }

  // mapView.Camera.focusOn({ nodes: nodesToFocusOn }, {
  //   easing: CAMERA_EASING_MODE.EASE_OUT,
  //   duration: 500,
  // });



  let polysToFocusOn = nodesToFocusOn.map((node) => {
    return node.polygon;
  });
  mapView.Camera.focusOn({ polygons: polysToFocusOn }, {
    easing: CAMERA_EASING_MODE.EASE_OUT,
    duration: 500,
  });


  markAnimationComplete(refContext);

}

/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
function markAnimationComplete(refContext) {

  let currentSarchId = refContext.current.refSData.current.search.searchResultsId;
  let currentSelectionId = refContext.current.refSData.current.search.resultSelection.selectionId;

  // let cameraAnimatedForSearchId = refContext.current.cameraAnimatedForSearchId;
  // let cameraAnimatedForSelectionId = refContext.current.cameraAnimatedForSelectionhId;
  // console.log(` M 1 ===> ${currentSarchId} [${cameraAnimatedForSearchId}] - ${currentSelectionId} [${cameraAnimatedForSelectionId}]`);

  // Mark that we animated the camera for this search result.
  // let currentSarchId = refContext.current.refSData.current.search.searchResultsId;
  refContext.current.cameraAnimatedForSearchId = currentSarchId;

  // And for this selection.
  // let currentSelectionId = refContext.current.refSData.current.search.resultSelection.selectionId;
  refContext.current.cameraAnimatedForSelectionhId = currentSelectionId;

}


/**
 * @param {PolygonInfo} polygonInfo
 * @param {MapView} mapView
 */
function removeSearchMarker(polygonInfo, mapView) {
  if (polygonInfo.searchMarkerId) {
    mapView.Markers.remove(polygonInfo.searchMarkerId);
    polygonInfo.searchMarkerId = null;
    polygonInfo.searchMarkerZoomLevel = null;
    polygonInfo.searchMarkerHTML = null;
    polygonInfo.searchMarkerForSearchId = null;
    polygonInfo.searchMarkerForSelectionId = null;
    polygonInfo.searchMarkerTargetLink = null;
  }
}







/**
 * @param {MapLocationSearchResultsBlock} block
 * 
 * @returns {string | null}
 */
function findProminentAssetType(block) {

  if (!block) {
    return null;
  }

  let assetTypes = new Set();
  // block.items.forEach((item) => {
  //   assetTypes.add(item.type);
  // });
  block.perLocationCounts.forEach((c) => {
    assetTypes.add(c.objectType);
  });

  if (assetTypes.size === 1) {
    return assetTypes.values().next().value;
  }

  return null;

}





/**
 * @param {MapLocationSearchResultsBlock} block
 */
function getMarkerTargetLink_SEARCH(block) {

  if (!block) {
    return null;
  }

  let isEmpty = block.perLocationCounts.length === 0;
  if (isEmpty) {
    return null;
  }

  // console.log('block ===> ', block);

  // let firstItem = block.items[0];
  let firstCount = block.perLocationCounts[0];

  let isMultiItemsBlock = block.perLocationCounts.length > 1 || block.totalCount > 1;
  let isSectionItemBlock = firstCount.objectType === "SECTION";
  let isLocationItemBlock = firstCount.objectType === "LOCATION" || firstCount.objectType === "STATION";



  ////////////////////////////
  // Target => Multi-Assets
  //
  if (isMultiItemsBlock) {

    let locationId = firstCount.locationId;
    let targetType = findProminentAssetType(block) || null;

    let link = LinkBuilder.buildLink(locationId, targetType, null);
    return link;

  }

  //////////////////////////
  // Target => Section
  //
  if (isSectionItemBlock) {
    // return "SECTION LINK HERE !!!  I DONT EXIST !!! SECTIONS ARE NOT CLICKABLE AS THEY GOT NOWHERE TO LINK TO !!!";
    return null;
  }

  //////////////////////////
  // Target => Location
  //
  if (isLocationItemBlock) {

    let locationId = firstCount.locationId;

    let link = LinkBuilder.buildLink(locationId, null, null);
    return link;

  }

  ///////////////////////////////
  // Target => Specific Asset
  //
  let firstItem = block.items[0];
  if (!firstItem) {
    // console.log('NO FIRST ITEM !!!', block);
    return null;
  }
  
  let targetType = firstItem.type;
  let assetId = firstItem.id;

  let link = LinkBuilder.buildLink(null, targetType, assetId);
  return link;

}


/**
 * @param {MapLocationSearchResultsBlock} block
 */
function getMarkerHtml_SEARCH(block) {

  if (!block) {
    return null;
  }

  // let isEmpty = block.items.length === 0;
  // if (isEmpty) {
  //   return null;
  // }
  let isEmpty = block.perLocationCounts.length === 0;
  if (isEmpty) {
    return null;
  }


  let firstItem = block.items[0];

  let isMultiItemsBlock = block.items.length > 1 || block.totalCount > 1;
  let isSectionItemBlock = firstItem && (firstItem.type === "SECTION");
  let isLocationItemBlock = firstItem && (firstItem.type === "LOCATION" || firstItem.type === "STATION");



  if (isMultiItemsBlock) {
    return getMarkerHtml_SEARCH__MultipleAssets(block);
  }

  if (isSectionItemBlock) {
    return getMarkerHtml_SEARCH__SingleSection(block);
  }

  if (isLocationItemBlock) {
    return getMarkerHtml_SEARCH__SingleLocation(block);
  }

  return getMarkerHtml_SEARCH__SingleAsset(block);

}


/**
 * @param {MapLocationSearchResultsBlock} block
 */
function getMarkerHtml_SEARCH__SingleAsset(block) {

  if (!block) {
    return null;
  }

  if (block.items.length !== 1) {
    return null;
  }

  let item = block.items[0];
  let title = `${block.locationName}`;
  let assetInfo = `${item.businessId}`;


  // <div class="marker-search-result-pin custom-marker" data-anchor-top="-16" data-anchor-left="-20">
  return `
  <div class="marker-search-result-pin-single-asset custom-marker" data-anchor-top="0" data-anchor-left="-20">
  
  
    <div class="marker-icon">
      ${LiveMapAssetsIcons.liveMap_Marker_Search_Pin}
    </div>
    
    
    <div class="marker-bubble-single">
      <div class="marker-title">
        ${title}
      </div>
      <div class="marker-asset-info">
        ${assetInfo}
      </div>
      
    </div>
      
    
    
  </div>
  `;

}


/**
 * @param {MapLocationSearchResultsBlock} block
 */
function getMarkerHtml_SEARCH__SingleLocation(block) {

  if (!block) {
    return null;
  }

  if (block.items.length !== 1) {
    return null;
  }

  let title = `${block.locationName}`;

  // <div class="marker-search-result-pin custom-marker" data-anchor-top="-16" data-anchor-left="-20">
  // <div class="marker-search-result-pin-single-location custom-marker" data-anchor-top="-19" data-anchor-left="-20">
  return `
  <div class="marker-search-result-pin-single-location custom-marker" data-anchor-top="0" data-anchor-left="-20">
  
    <div class="marker-icon">
      ${LiveMapAssetsIcons.liveMap_Marker_Search_Pin}
    </div>
    
    <div class="marker-bubble-single-location">
      <div class="marker-title">
        ${title}
      </div>
      <div class="marker-asset-info">
      </div>
    </div>
    
    
  </div>
  `;

}


/**
 * @param {MapLocationSearchResultsBlock} block
 */
function getMarkerHtml_SEARCH__SingleSection(block) {

  if (!block) {
    return null;
  }

  if (block.items.length !== 1) {
    return null;
  }

  let item = block.items[0];
  let title = `${block.locationName}`;
  let assetInfo = `${item.businessId}`;


  // <div class="marker-search-result-pin custom-marker" data-anchor-top="-16" data-anchor-left="-20">
  return `
  <div class="marker-search-result-pin-single-section custom-marker" data-anchor-top="0" data-anchor-left="-20">
  
  
    <div class="marker-icon">
      ${LiveMapAssetsIcons.liveMap_Marker_Search_Pin}
    </div>
    
    
    <div class="marker-bubble-single-section">
      <div class="marker-title">
        ${title}
      </div>
      <div class="marker-asset-info">
      </div>
      
    </div>
      
    
    
  </div>
  `;

}


/**
 * @param {MapLocationSearchResultsBlock} block
 */
function getMarkerHtml_SEARCH__MultipleAssets(block) {

  if (!block) {
    return null;
  }

  // if (block.items.length <= 1) {
  //   return null;
  // }

  let title = `${block.locationName}`;
  // let itemsLength = block.items.length;
  let totalItemsLength = block.totalCount;


  let itemsTypeLabel = LabelManager.getByKey("mat.livemap.marker.assets");

  let prominentAssetType = findProminentAssetType(block);
  if (prominentAssetType) {
    itemsTypeLabel = MapLabelManager.getTooltipMultiItemTypeLabel(prominentAssetType);
  }


  // <div class="marker-search-result-pin custom-marker" data-anchor-top="-16" data-anchor-left="-20">
  // <img src="${livemapIcons.liveMapResult_Marker_Searchresult_Pin}" />
  // <div class="marker-search-result-pin-multi-asset custom-marker" data-anchor-top="-19" data-anchor-left="-20">
  return `
  <div class="marker-search-result-pin-multi-asset custom-marker" data-anchor-top="0" data-anchor-left="-20">
  
  
    <div class="marker-icon">
      ${LiveMapAssetsIcons.liveMap_Marker_Search_Pin}
    </div>
    
    
    <div class="marker-bubble-multi-asset">
      <div class="marker-title">
        ${title}
      </div>
      <div class="marker-info-count">
        ${totalItemsLength}
      </div>
      <div class="marker-info-type">
        ${itemsTypeLabel}
      </div>
      
    </div>
      
    
    
  </div>
  `;

}







