import * as daHelper from '../../../Dialogs/dialogActionsHelper.js';
import {dialogsNames} from '../../../../enums/dialogsNames.js';
import * as promotionsService from '../../../../services/Administration/promotionsService';


const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
      promotionsService.editPromotion : promotionsService.createPromotion;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_PROMOTION_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_PROMOTION_DIALOG),

};

export let api = {
  ...daHelper.getApiActions(actions, config),
}

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};
