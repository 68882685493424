import Network from 'infrastructure/js/modules/network';

export function getRollActivityLog(assetId, query) {
  return Network.post(`rolls/${assetId}/activities`, query);
}

export function PrintRollLabel(data) {
  return Network.post(`download/label/roll`, data, { blobResponse: true });
}

export function fetchRolls(query = {}) {
  return Network.post('rolls/items?active=true', query);
}

export function fetchArchivedRolls(query = {}) {
  return Network.post('rolls/items?active=false', query);
}

export function createSubRolls(data) {
  let rollId = data.parentRollId;
  delete data.parentRollId;
  return Network.post(`rolls/${rollId}/subrolls`, data);
}
export function createSubRollsByWeight(data) {
  let rollId = data.parentRollId;
  delete data.parentRollId;
  return Network.post(`rolls/${rollId}/subrolls-by-weight`, data);
}

export function generateSubRollId(parentRollId) {
  return Network.get(`rolls/${parentRollId}/generateSubRollId`);
}

export function updateRoll(rollId, data) {
  return Network.put(`rolls/${rollId}`, data);
}

export function GenerateRollId() {
  return Network.get('rolls/generateId');
}

export function CreateRoll(rollData, locationId) {
  return Network.post('rolls', rollData);
}

export function getRollsDataByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/rolls/items?active=true`, query);
}

export function getShippedRollsByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/rolls/items?shippedToCollaborator=true`, query);
}

export function getReceivingRollsByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/rolls/items?active=true&inTransit=true`, query);
}
export function getShippingPreparaionRollsByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/rolls/items?active=true&locked=true`, query);

}

export function cancelCutReport(locationId, reportedCutId, addConsumedValuesBack) {
  return Network.post(`stations/${locationId}/not-kitted-reported-cuts/${reportedCutId}/cancel`, {
    addConsumedValuesBack,
  });
}

export function reportCuts(locationId, cutsToReport) {
  return Network.post(`stations/${locationId}/multiCuts`, cutsToReport);
}
export function reportCutsByWeight(locationId, cutsToReport) {
  return Network.post(`stations/${locationId}/multiCuts-by-weight`, cutsToReport);
}

export function getRollDetails(assetId) {
  return Network.get(`rolls/${assetId}`);
}

export function updateShelfLife(data, callback) {
  //data.rollsIds = data.rollsIds.concat(_createDummyIds(200));
  return Network.polling.post('rolls/MultiShelfLifeUpdate', data, { callback });
}

export function editRollAttributes(data, callback) {
  return Network.polling.put('rolls/update', data, callback);
}
export function getRollsBySmartSelection(data) {
  //Add default sorting by multiple columns. this should be removed when multiple columns sort will be enabled
  if (data.sortByProperty === '') {
    data.sortByProperty = 'rollEtlDateFilter asc, rollExpirationDateFilter asc, length asc';
  }
  // return Network.post('rolls/pickup/items', data);
  return Network.post('materials/pickup/items', data);
}

export function getMSSInitialLocations() {
  return Network.get('rolls/pickup/items/getLocationsFilterSelection');
}

export function getRollByBusinessId(data) {
  return Network.post('rolls/search', data, { ignore404NotFound: true });
}
export function addToPickList(ids = [], pickDateFrom, pickDateTo) {
  return Network.post('rolls/pickup/allocate', { ids, pickDateFrom, pickDateTo });
}
export function fetchPickList(query = {}) {
  return Network.post('rolls/picklist/items', query);
}
export function removeFromPickList(ids = []) {
  return Network.post('rolls/pickup/deallocate', { ids: ids });
}

export function fetchInspectionStatuses(cutId) {
  return Network.get('rolls/getRollInspectionStatuses');
}
