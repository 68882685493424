import * as exportService from '../../services/Export/exportService';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import Network from 'infrastructure/js/modules/network';
import {gridsNames} from '../../enums/gridsNames';
import { api as locationPageApi } from '../LocationPage/locationPageActions';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from '../../../../infrastructure/js/utils/permissionManager';
import gridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';


const MAX_EXPORT_ROWS = 1000;
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.export = function (data) {
  return function (dispatch, getState) {
    api.exportGridRows(dispatch, getState)(data);
  }
};

jsxActions.exportUsage = function (data) {
  return function (dispatch, getState) {
    api.exportUsageGridRows(dispatch, getState)(data);
  }
};

jsxActions.exportSmartSelection = function (data) {
  return function (dispatch, getState) {
    api.exportSmartSelection(dispatch, getState)(data);
  }
};

jsxActions.exportCsvTemplate = function (data) {
  return function (dispatch, getState) {
    api.exportCsvTemplate(dispatch, getState)(data);
  }
};

jsxActions.exportKitReport = function (kitIds) {
  return function (dispatch, getState) {
    api.exportKitReport(dispatch, getState)(kitIds);
  }
};

jsxActions.exportGroupReport = function (kitIds) {
  return function (dispatch, getState) {
    api.exportGroupReport(dispatch, getState)(kitIds);
  }
};

jsxActions.exportTasksProgressGrid = function (data) {
  return function (dispatch, getState) {
    api.exportTaskProgressGridRows(dispatch, getState)(data);
  }
};

jsxActions.exportOrgsDashboardGrid = function (data) {
  return function (dispatch, getState) {
    api.exportOrgsDashboardGridRows(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.exportPickListItems = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportPickListItems);
  }
};

api.doExportPickListItems = function(dispatch, getState) {
  return function(data) {
    let gridData = getState().grid.get(data?.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);
    return exportService.exportPickListItems(data?.pickListId, query).then((response) => {
      _handleBlobResponse(response, dispatch, getState);

    });
  }
};

api.exportSmartSelection = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportSmartSelection);
  }
};

api.doExportSmartSelection = function(dispatch, getState) {
  return function(data) {

    let gridData = getState().grid.get(data.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);

    let selectWoPickDate = getState().predefinedList.get('smartSelectionHeader').get('selectWoPickDate');
    let selectWoPickDateFrom = getState().predefinedList.get('smartSelectionHeader').get('selectWoPickDateFrom');
    let selectWoPickDateTo = getState().predefinedList.get('smartSelectionHeader').get('selectWoPickDateTo');
    if(selectWoPickDate){
      selectWoPickDateFrom = selectWoPickDateTo = selectWoPickDate
    }
    let selectWo = getState().predefinedList.get('smartSelectionHeader').get('selectWo');
    let selectMaterial = getState().predefinedList.get('smartSelectionHeader').get('selectMaterial');
    let selectLocation = getState().predefinedList.get('smartSelectionHeader').get('selectLocation');
    let locationFilter = query.filters.find(f => f.filterName === 'assetLocationName');
    locationFilter.values = selectLocation ?  selectLocation.map((location)=>{return location.label}) : [];

    query = gridHelper.applyRowSelectionAsFilter(
      gridData, query,
      'businessIdSearch',
      (rowData) => { return rowData.businessId; }
    );

    query.woPickDateFrom = selectWoPickDateFrom ? DateTimeHelper.ConvertFromDate(selectWoPickDateFrom).epochDateTime.toString() : null;
    query.woPickDateTo = selectWoPickDateTo ? DateTimeHelper.ConvertFromDate(selectWoPickDateTo).epochDateTime.toString() : null;
    query.workOrderIds = selectWo ? selectWo.map((wo)=>{return wo.value}) : [];
    query.materialIds = selectMaterial ? selectMaterial.map(material => material.data.displayMaterial.id) : [];

    return exportService.exportSmartSelectionItems(query).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportOnHandInventory = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportOnHandInventory);
  }
};

api.doExportOnHandInventory = function(dispatch, getState) {
  return function(data) {

    let gridData = getState().grid.get(data.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);

    let onHandInventoryHeader = getState().predefinedList.get('onHandInventoryHeader');
    let selectedLocations = onHandInventoryHeader.get('selectedLocations');
    let locationFilter = query.filters.find(f => f.filterName === 'location');
    locationFilter.values = selectedLocations ?  selectedLocations.map((location)=>{return location.value}) : [];
    let selectedAlertStatuses = onHandInventoryHeader.get('selectedAlertStatuses') || [];
    let selectedAlertTypes = onHandInventoryHeader.get('selectedAlertTypes') || [];
    let alertFilter = query.filters.find(f => f.filterName === 'assetAlert');
    alertFilter.values = selectedAlertStatuses.map((value)=>{return value.value}).concat(selectedAlertTypes.map((value)=>{return value.value}));

    let method = PermissionManager.isWeightSupported() ? exportService.exportOnHandInventoryByWeight : exportService.exportOnHandInventory;
    return method(query).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportRollUsageById = function(dispatch, getState) {
  return function(selectedAssetsId) {
    return exportService.exportRollUsageById(selectedAssetsId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportSpoolUsageById = function(dispatch, getState) {
  return function(selectedAssetsId) {
    return exportService.exportSpoolUsageById(selectedAssetsId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportCsvTemplate = function(dispatch, getState) {
  return function (type) {
    return exportService.exportCsvTemplate(type).then((response) => {
      _handleBlobResponse(response, dispatch, getState, type === 'kits' ? 'json' : 'csv');
    });
  }
};

api.exportValidationReportOfImportNestsWithRoll = function(dispatch, getState) {
  return function (batchJobId) {
    return exportService.exportValidationReportOfImportNestsWithRoll(batchJobId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportValidationReportOfImportNests = function(dispatch, getState) {
  return function (batchJobId) {
    return exportService.exportValidationReportOfImportNests(batchJobId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportValidationReportOfImportNestsWithMultiRoll = function(dispatch, getState) {
  return function (batchJobId) {
    return exportService.exportValidationReportOfImportNestsWithMultiRoll(batchJobId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportValidationReportOfImportNestsWithAnyRoll = function(dispatch, getState) {
  return function (batchJobId) {
    return exportService.exportValidationReportOfImportNestsWithAnyRoll(batchJobId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportValidationReportOfOperationSequence = function(dispatch, getState) {
  return function (batchJobId) {
    return exportService.exportValidationReportOfOperationSequence(batchJobId).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportTaskProgressGridRows = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportTaskProgressGridRows);
  }
};

api.doExportTaskProgressGridRows = function(dispatch, getState) {
  return function(data) {

    const viewDate = getState().locationPage.get('tasksViewData').get('viewDate');
    let gridData = getState().grid.get(data.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);
    query.viewDate = viewDate;

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let url = _getExportUrlForGrid(data, locationId);

    if (url) {
     return exportService.exportGridRows(url, query).then((response) => {
       _handleBlobResponse(response, dispatch, getState);
     });
    }
  }
};

api.exportGridRows = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportGridRows);
  }
};

api.doExportGridRows = function(dispatch, getState) {
  return function(data) {

    let gridData = getState().grid.get(data.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let url = _getExportUrlForGrid(data, locationId);

    if (url) {
     return exportService.exportGridRows(url, query).then((response) => {
       _handleBlobResponse(response, dispatch, getState);
     });
    }
  }
};

api.exportUsageGridRows = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportUsageGridRows);
  }
};

api.doExportUsageGridRows = function(dispatch, getState) {
  return function(data) {

    let gridData = getState().grid.get(data.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let url = _getExportUsageUrlForGrid(data, locationId);

    if (url) {
     return exportService.exportGridRows(url, query).then((response) => {
       _handleBlobResponse(response, dispatch, getState);
     });
    }
  }
};

api.exportDashboardTemplate = function(dispatch, getState) {
  return function (data) {
    return exportService.exportDashboardTemplate(data).then((response) => {
      _handleBlobResponse(response, dispatch, getState, 'json');
    });
  }
};

api.exportKitReport = function(dispatch, getState) {
  return function (kitIds) {
    return exportService.exportKitReport(kitIds).then((response) => {
      _handleBlobResponse(response, dispatch, getState, kitIds.size === 1 ? 'csv' : 'zip');
    });
  }
};

api.exportKitRollsReport = function(dispatch, getState) {
  return function (kitIds) {
    return exportService.exportKitRollsReport(kitIds).then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportGroupReport = function(dispatch, getState) {
  return function (containerIds) {
    return exportService.exportGroupReport(containerIds).then((response) => {
      _handleBlobResponse(response, dispatch, getState, containerIds.size === 1 ? 'csv' : 'zip');
    });
  }
};

api.exportOperationSequence = function(dispatch, getState) {
  return function() {
    return exportService.exportOperationSequence().then((response) => {
      _handleBlobResponse(response, dispatch, getState);
    });
  }
};

api.exportKitTypesDomSchema = function(dispatch, getState) {
  return function() {
    return exportService.exportKitTypesDomSchema().then((response) => {
      _handleBlobResponse(response, dispatch, getState, 'json');
    });
  }
};

api.exportToolTypesDomSchema = function(dispatch, getState) {
  return function() {
    return exportService.exportToolTypesDomSchema().then((response) => {
      _handleBlobResponse(response, dispatch, getState, 'json');
    });
  }
};

api.exportOrgsDashboardGridRows = function(dispatch, getState) {
  return function(data) {
    _handleExport(dispatch, getState, data, api.doExportOrgsDashboardGridRows);
  }
};

api.doExportOrgsDashboardGridRows = function(dispatch, getState) {
  return function (data) {
    let gridData = getState().grid.get(data.gridName);
    let query = GridHelper.createGetDataQueryByGridData(gridData);
    query.fromDate = getState().administration.getIn(['organizationsPage', 'orgsDashboardView', 'reportDateFrom']) || null;
    query.toDate = getState().administration.getIn(['organizationsPage', 'orgsDashboardView', 'reportDateTo']) || null;

    let url = _getExportUrlForGrid(data);

    if (url) {
      return exportService.exportGridRows(url, query).then((response) => {
        _handleBlobResponse(response, dispatch, getState);
      });
    }
  }
}



///////////////////////////////////////////////////
function _handleBlobResponse(response,dispatch, getState, fileType = 'csv' ) {
  if (!response.ok) {
    console.error('Export CSV failed', response);
    messageDialogApi.responseError(dispatch, getState)();
    return [];
  }
  Network.loadBlobResponse(response, fileType).then((loadResponse) => {
    if (!Network.isResponseValid(loadResponse)) {
      console.error('Export CSV failed', loadResponse);
      messageDialogApi.responseError(dispatch, getState)(loadResponse);
    }
    return [];
  });
}

function _getExportUsageUrlForGrid(data, locationId=null) {
  switch (data.gridName) {

    case gridsNames.LOCATION_ASSET_ROLLS:
      return `export/stations/${locationId}/rolls/usage`;
    case gridsNames.PREDEFINED_LIST_ACTIVE_ROLLS:
      return 'export/rolls/usage?active=true';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS:
      return 'export/rolls/usage?active=false';

    case gridsNames.LOCATION_ASSET_SPOOLS:
      return `export/stations/${locationId}/spools/usage`;
    case gridsNames.PREDEFINED_LIST_ACTIVE_SPOOLS:
      return 'export/spools/usage?active=true';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_SPOOLS:
      return 'export/spools/usage?active=false';

    case gridsNames.LOCATION_ASSET_RESINS:
      return `export/stations/${locationId}/resins/usage`;
    case gridsNames.PREDEFINED_LIST_ACTIVE_RESINS:
      return 'export/resins/usage?active=true';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_RESINS:
      return 'export/resins/usage?active=false';
    default:
      console.error(`_getExportUsageUrlForGrid(): Unknown grid name: ${data.gridName}`, data);
  }
}

function _getExportUrlForGrid(data, locationId=null) {
  switch (data.gridName) {
    case gridsNames.PREDEFINED_LIST_ALERTED_ROLLS:
      return 'export/rolls/alerted';
    case gridsNames.PREDEFINED_LIST_ALERTED_KITS:
      return 'export/kits/alerted';
    case gridsNames.PREDEFINED_LIST_ALERTED_GROUPS:
      return 'export/groups/alerted';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_ROLLS:
      return 'export/rolls/archived';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_KITS:
      return 'export/kits/archived';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_GROUPS:
      return 'export/groups/archived';
    case gridsNames.PREDEFINED_LIST_OPEN_WORK_ORDER:
      return 'export/workorders?active=true';
    case gridsNames.PREDEFINED_LIST_COMPLETED_WORK_ORDER:
      return 'export/workorders/completed';
    case gridsNames.LOCATION_WORK_ORDER:
      return 'export/workorders?active=true';
    case gridsNames.PREDEFINED_LIST_ACTIVE_TOOLS:
      return 'export/tools/active';
    case gridsNames.PREDEFINED_LIST_ARCHIVED_TOOLS:
      return 'export/tools/archived';
    case gridsNames.ASSET_PAGE_ACTIVITY_LOG:
      return `export/${data.assetType.toLowerCase()}s/${data.assetId}/activities`;
    case gridsNames.WO_PAGE_ACTIVITY_LOG:
      return `export/workorders/${data.locationId}/activities`;
    case gridsNames.LOCATION_ACTIVITIES:
      return `export/locations/${locationId}/activities`;
    case gridsNames.PREDEFINED_LIST_ACTIVE_ROLLS:
      return 'export/rolls/active';
    case gridsNames.PREDEFINED_LIST_ACTIVE_KITS:
      return 'export/kits/active';
    case gridsNames.PREDEFINED_LIST_ACTIVE_GROUPS:
      return 'export/groups/active';
    case gridsNames.PREDEFINED_LIST_ACTIVE_SPOOLS:
      return 'export/spools/active';
    case gridsNames.PREDEFINED_LIST_ALERTED_SPOOLS:
      return 'export/spools/alerted';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_SPOOLS:
      return 'export/spools/archived';
    case gridsNames.LOCATION_ASSET_SPOOLS:
      return `export/stations/${locationId}/spools`;
    case gridsNames.PREDEFINED_LIST_ACTIVE_RESINS:
      return 'export/resins/active';
    case gridsNames.PREDEFINED_LIST_ALERTED_RESINS:
      return 'export/resins/alerted';
    case gridsNames.PREDEFINED_LIST_ARCHIVE_RESINS:
      return 'export/resins/archived';
    case gridsNames.LOCATION_ASSET_RESINS:
      return `export/stations/${locationId}/resins`;
    case gridsNames.LOCATION_ASSET_ROLLS:
      return `export/stations/${locationId}/rolls`;
    case gridsNames.LOCATION_ASSET_KITS:
      return `export/stations/${locationId}/kits`;
    case gridsNames.LOCATION_ASSET_GROUPS:
      return `export/stations/${locationId}/groups`;
    case gridsNames.LOCATION_ASSET_TOOLS:
      return `export/stations/${locationId}/tools`;
    // case gridsNames.LOCATION_KITTING:   //Note: remove unused Server APIs
    //   return `export/stations/${locationId}/unfinishedreportedcuts/${data.cutId}/unfinishedkits`;
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_KIT_TYPES:
      return 'export/kittypes';
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES:
      return 'export/tooltypes';
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_GROUPS:
      return 'export/toolgroups';
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_MATERIAL_TYPES:
      return 'export/materialtypes';
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_GROUP_TYPES:
      return 'export/grouptypes';
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_PART_TYPES:
      return 'export/parttypes';
    case gridsNames.ADMINISTRATION_MAT_SETTINGS_PROJECTS:
      return 'export/projects';
    case gridsNames.ADMINISTRATION_ORGS_DASHBOARD:
      return 'export/ops/org-statistics';
    case gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS:
      return 'export/schedulerTasks';
    case gridsNames.PREDEFINED_MATERIAL_SCRAP:
      return 'export/materials/scrap';
    case gridsNames.LOCATION_TASKS:
      return `export/stations/${locationId}/tasks`;
    case gridsNames.ASSET_PAGE_ETL_LOG:
      return `export/assets/${data.assetId}/etl-log`;
    default:
      console.error(`_getExportUrlForGrid(): Unknown grid name: ${data.gridName}`, data);
  }
  return null;
}


//Note: Temporary solution for the Server side issues (see SAAS-21614)
function _handleExport(dispatch, getState, data, submitHandler) {

  const gridData = getState().grid.get(data.gridName);
  const totalElements = gridData?.get('queryResultInfo')?.totalElements ?? 0;

  if (totalElements > MAX_EXPORT_ROWS) {
    let confirmationConfig = {
      message: 'Only up to ' + MAX_EXPORT_ROWS + ' rows will be exported at a time. Use the column filters to refine your data selection if needed.',
      cancelHandler: messageDialogApi.close(dispatch, getState),
      submitButtonLabel: 'Export',
      submitHandler: () => {
        messageDialogApi.close(dispatch, getState)();
        submitHandler?.(dispatch, getState)(data);
      },
    };
    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
    return false;
  }
  submitHandler?.(dispatch, getState)(data);
  return true;
}
