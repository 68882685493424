import { api as schedulerGanttPageApi } from './schedulerGanttPageActions';
import {
  actions as reportTaskProgressDialogActions,
  api as reportTaskProgressDialogApi,
  jsxActions as reportTaskProgressDialogJsxActions,
} from '../../actions/Dialogs/ReportTaskProgressDialog/reportTaskProgressDialogActions';

export let api = {
  ...reportTaskProgressDialogApi,

  show(dispatch, getState) {
    return function (item = null) {
      dispatch(reportTaskProgressDialogActions.show(item));
    };
  },

  submit(dispatch, getState) {
    return function (taskData) {
      schedulerGanttPageApi.reportTaskProgress(dispatch, getState)({ ...taskData, isReportAsPlanned: false });
      dispatch(reportTaskProgressDialogActions.hide());
    };
  },
};

export let jsxActions = {
  ...reportTaskProgressDialogJsxActions,

  submit(taskData) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(taskData);
    };
  },
};
