import { Map } from 'immutable';
import { actionTypes as notificationsMenuActionTypes } from '../../actions/Notifications/notificationsActions';

let defaultState = {
  notifications: [],
  loading:false,
  show: false,
  count: 0,
  criticals: null,
};


export default function(state = Map(defaultState), action) {
  switch(action.type) {

    case notificationsMenuActionTypes.FETCH_NOTIFICATIONS_COUNT_READY:
      return state.set('count', action.payload.count);
    case notificationsMenuActionTypes.FETCH_NOTIFICATIONS_CRITICALS_READY:
      return state.set('criticals', action.payload.criticals);
    case notificationsMenuActionTypes.FETCH_NOTIFICATIONS_IN_PROGRESS:
      return state.set( 'loading', true);

    case notificationsMenuActionTypes.FETCH_NOTIFICATIONS_READY:
      state = state.set( 'notifications', action.payload);
      state = state.set( 'loading', false);
      return state;

    case notificationsMenuActionTypes.NOTIFICATIONS_TOGGLE:
      let showNotificationsMenu = state.get('show');
      if (showNotificationsMenu) {
        return state.set('show', false);
      }
      else {
        return state.set('show', true);
      }

    case notificationsMenuActionTypes.NOTIFICATIONS_HIDE:
        return state.set('show', false);


    case notificationsMenuActionTypes.CHANGE_NOTIFICATION_READ_STATUS_IN_PROGRESS:
      return state.set('loading', true);

    case notificationsMenuActionTypes.CHANGE_NOTIFICATION_READ_STATUS_READY:
      return state.set('loading', false);

    case notificationsMenuActionTypes.NOTIFICATIONS_MARK_ALL_AS_READ_IN_PROGRESS:
      return state.set('loading', true);

    case notificationsMenuActionTypes.NOTIFICATIONS_MARK_ALL_AS_READ_READY:
      return state.set('loading', false);


    default:
      return state;
  }
}





