import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as archivedResinsPageActions }    from '../../actions/ArchivedResinsPage/archivedResinsPageActions.js';
import {gridsNames} from '../../enums/gridsNames';
import {jsxActions as headerActions} from '../../actions/Header/headerActions';
import { jsxActions as activateAssetsDialogActions }    from '../../actions/Dialogs/ActivateAssetsDialog/activateAssetsDialogActions';
import { jsxActions as archivedResinsGridActions }    from '../../actions/ArchivedResinsPage/archivedResinsGridActions';
import { jsxActions as moveAssetsActions }        from '../../actions/Dialogs/MoveAssetsDialog/moveAssetsDialogActions';
import { jsxActions as printPdfLabelsActions }      from '../../actions/Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialogActions.js';
import { jsxActions as fetchEntitiesActions }      from '../../actions/FetchEntities/fetchEntitiesActions.js';
import {jsxActions as exportActions} from '../../actions/Export/exportActions';

import ArchivedResinsPage from '../../components/PredefinedList/ArchivedResinsPage/archivedResinsPage';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(archivedResinsPageActions, dispatch),
      header: bindActionCreators(headerActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      archivedResinsGridActions: bindActionCreators(archivedResinsGridActions, dispatch),
      activateAssetsDialogActions:  bindActionCreators(activateAssetsDialogActions, dispatch),
      moveAssetsActions:      bindActionCreators(moveAssetsActions, dispatch),
      printPdfLabelsActions:      bindActionCreators(printPdfLabelsActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    }
  }
}

let ArchivedResinsPageContainer = connect(
  (state) => {
    let sGrid = state.grid?.get(gridsNames.PREDEFINED_LIST_ARCHIVE_RESINS) || null;
    return {
      sGrid:  sGrid,
      sHasRows: sGrid && sGrid.get('rows') ? sGrid.get('rows').size > 0 : false,
      sSelectedRowsData: sGrid ? sGrid.get('selectedRowsData') : List(),
      sActivateAssetsDialogData: state.dialogs.get('activateAssetsDialog'),
      sMoveAssetsData:     state.dialogs.get('moveAssetsDialog'),
      sPrintPdfLabelsData: state.dialogs.get('printPdfLabelsDialog'),
    };
  },
  mapDispatchToProps
)(ArchivedResinsPage);


export default ArchivedResinsPageContainer;
