import Network from 'infrastructure/js/modules/network';

const getUsersData = function(query = {}){
  return Network.post('users', query);
};

const getSystemUsersData = function(query = {}){
  return Network.post('system/users', query);
};

const update = function(user){
  let id = user.id;
  delete user.id;
  return Network.put(`users/${id}`, user);
};
const updateSystemUser = function(user){
  let id = user.id;
  delete user.id;
  return Network.put(`system/users/${id}`, user);
};

const remove = function(userID){
  return Network.delete('users/', userID)
    .then(function(response){
    return Network.getDto(response);
  });
};

const changeSystemUserPassword = function(queryData) {
  return Network.put(`system/users/  ${queryData.userId } /changePassword`, {password: queryData.password});
};
const changePassword = function(queryData) {
  return Network.put(`users/  ${queryData.userId } /changePassword`, {password: queryData.password});
};

const changeUsersStatus = function(queryData) {
  return Network.put(`users/${queryData.id}`, {action: queryData.newStatus});
};
const changeSystemUsersStatus = function(queryData) {
  return Network.put(`system/users/${queryData.id}`, {action: queryData.newStatus});
};

const selfUpdate = function(user){
  let id = user.id;
  delete user.id;
  return Network.put('users/selfupdate', user);
};

const getCurrentUserProfile = function(){
  return Network.get('users/profile');
};

const fetchPromotions = function(){
  return Network.get('promotions-carousel',{}, {ignore404NotFound : true});
}

const changeMyPassword = function(queryData){
  return Network.post('users/changeMyPassword', queryData);
};

const create = function(values){
  return Network.put('users/create', values);
};
const createSystemUser = function(values){
  return Network.put('system/users/create', values);
};


export default {
  getUsersData,
  update,
  remove,
  changePassword,
  selfUpdate,
  changeMyPassword,
  create,
  changeUsersStatus,
  getCurrentUserProfile,
  getSystemUsersData,
  changeSystemUsersStatus,
  changeSystemUserPassword,
  createSystemUser,
  updateSystemUser,
  fetchPromotions
};
