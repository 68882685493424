// @ts-check

import { MapView, Mappedin, MappedinMap, getVenue } from "@mappedin/mappedin-js";



/**
 * @param { MappedinMap } map
 */
export function createMapCalculations(map) {
  // let timeStart = window.performance.now();
  
  let boundaries = calculateMapPolygonBoundaries(map);
  if (!boundaries) {
    return null;
  }
  
  let centerX = (boundaries.minX + boundaries.maxX) / 2;
  let centerY = (boundaries.minY + boundaries.maxY) / 2;
  
  
  let minZoomLimit = calculateLimitToMinZoom(map, 375);
  

  
  let coordinate_Default = map.createCoordinateByXY(centerX -150, centerY + 700);
  let camera_Default = {
    rotation: -0.3,
    tilt: 0.9,
    zoom: 2500,
    position: coordinate_Default
  };
  
  let coordinate_Top = map.createCoordinateByXY(centerX, centerY);
  let camera_Top = {
    rotation: 0,
    tilt: 0,
    zoom: 4000,
    position: coordinate_Top
  };
  
  
  
  // let timeEnd = window.performance.now();
  // let timeDiff_Micros = timeEnd - timeStart;
  // console.log('timeDiff_Micros ===> ', timeDiff_Micros);
  

  
  
  /** @type {import("./liveMapPageTypes").MapCalculations} */
  let mapCalculations = {
    boundaries,
    centerX,
    centerY,
    minZoomLimit,
    camera_Default,
    camera_Top,
  };
  
  return mapCalculations;
  
}




/** 
 * @param { MappedinMap } map
 * @param {number} minZoom 
 */
let calculateLimitToMinZoom = function (map, minZoom) {
  if (!map.scale) {
    return 300;
  }
  return minZoom * map.scale * 7.9; // Magic number.
};



/**
 * @param { MappedinMap } map
 */
function calculateMapPolygonBoundaries(map) {
  if (!map) {
    return null;
  }
  if (!map?.polygons) {
    return null;
  }


  let polygons = map.polygons;
  

  let minX = 100000000;
  let minY = 100000000;
  let maxX = 0;
  let maxY = 0;

  polygons.forEach((p) => {

    let vertexes = p.vertexes;
    if (!vertexes) {
      return;
    }

    vertexes.forEach((vertex) => {

      if (vertex.x < minX) {
        minX = vertex.x;
      }
      if (vertex.y < minY) {
        minY = vertex.y;
      }
      if (vertex.x > maxX) {
        maxX = vertex.x;
      }
      if (vertex.y > maxY) {
        maxY = vertex.y;
      }

    });

  });


  let boundaries = {
    minX,
    minY,
    maxX,
    maxY,
  };

  return boundaries;
};









