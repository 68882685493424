import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as liveMapPageActions } from '../../actions/LiveMap/liveMapPageActions.js';
import LiveMapPage from '../../components/LiveMap/liveMapPage.js';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(liveMapPageActions, dispatch),
    }
  }
}


let LiveMapPageContainer = connect(

  (state) => {
    return {
      sData: state.liveMapPage,
      sLoggedInUser: state.login.get('loggedInUser')
    };
  },
  mapDispatchToProps

)(LiveMapPage);


export default LiveMapPageContainer;



