import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, getFormSyncErrors } from 'redux-form';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import List from 'infrastructure/js/components/List/list';
import KitTypeMaterialListItem from '../../../../../Common/Controls/AddRemoveList/AddRemoveListItem/KitTypeMaterialListItem/kitTypeMaterialListItem';
import AddRemoveListExt from '../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import KitTypeOperationListItem from '../../KitTypeOperationListItem/kitTypeOperationListItem';
import KitTypeLocationListItem from '../../KitTypeLocationListItem/kitTypeLocationListItem';
import AdditionalField from '../../../../../Common/Layout/AdditionalField/AdditionalField';
import {
  TOOL_REQUIRED_OPTIONS_TYPES,
  checkToolRequiredPreviousOptions,
  parseNewAddedItem,
  checkToolPreviousOptionDisabled,
} from '../../../../../../utils/toolRequiredHelper';

import './createKitTypeDialog.scss';

class CreateKitTypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createkittype.');

    this.isClone = this.props.sData.get('isClone');
    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.isOperationsSequenceEnabled = false;
    this.isLocationsSequenceEnabled = false;

    let idsInUse = [];

    this.isTaskDurationInSeconds = PermissionManager.getOrgPreferences().taskDurationUnits === 'SECONDS';

    if (this.isEditMode && this.itemToEdit.kitTypeMaterials) {
      this.itemToEdit.kitTypeMaterials.map((item) => {
        idsInUse.push(item.material.id);
      });
    }
    this.operationsIdsInUse = [];
    if (this.isEditMode && this.itemToEdit.operations) {
      this.itemToEdit.operations.forEach((item) => {
        this.operationsIdsInUse.push(item.operationId);
      });
    }

    this.locationsIdsInUse = [];
    if (this.isEditMode && this.itemToEdit.locations) {
      this.itemToEdit.locations.map((item) => {
        this.locationsIdsInUse.push(item.locationId);
      });
    }

    this.state = {
      optionsInUse: idsInUse,
      selectedTab: 'details',
      operationsDummyKey: 0,
    };
    this.addtionalFieldsInitialValues = [];
  }

  componentDidMount() {
    let operationPredictionEnabled = PermissionManager.getOrgPreferences().operationPredictionEnabled;
    let productionTimeCalculationEnabled = PermissionManager.getOrgPreferences().productionTimeCalculationEnabled;
    this.isOperationsSequenceEnabled =
      operationPredictionEnabled || productionTimeCalculationEnabled || PermissionManager.getOrgPreferences().schedulerEnabled;

    this.isLocationsSequenceEnabled = PermissionManager.getOrgPreferences().locationPredictionEnabled;

    let initialValues = { active: true, materials: [{}]};

    if (this.isEditMode) {
      initialValues = {
        active: this.itemToEdit.active,
        kitType: this.isClone ? '' : this.itemToEdit.businessId,
        partType: this.itemToEdit.partType.id,
        project: this.itemToEdit.project.id,
        description: this.itemToEdit.description,
        tokenValue: this.itemToEdit.tokensPerPart,
        maxDaisyChains: this.itemToEdit.maxDaisyChains,
        materials: KitTypeMaterialListItem.getInitialValues(this.itemToEdit.kitTypeMaterials),
        operations: this.getInitialOperations(this.itemToEdit.operations),
        locations: this.getInitialLocations(this.itemToEdit.locations),
        additionalFields: {},
      };

      let additionalFields = this.props.sData.get('dialogData').additionalFields;
      additionalFields.map((field) => {
        let match = this.itemToEdit.additionalFieldsValues ? this.itemToEdit.additionalFieldsValues.find((x) => x.field.id === field.id) : null;
        if (match) {
          if (match.field.type.type === 'DATE_PICKER') {
            this.addtionalFieldsInitialValues.push(match);
          }
          initialValues.additionalFields[field.name] =
            match.field.type.type === 'DATE_PICKER' ? DateTimeHelper.ConvertToDate(match.value) : match.value;
        }
      });
    }

    this.props.initialize(initialValues);
  }

  getInitialOperations = (operations) => {
    if (operations) {
      return operations.map((item) => {
        const operationData = this.getAllOperations()?.find((operation) => operation.data.id === item.operationId)?.data;

        let obj = {
          operationName: item.operationId,
          splitEnabled: item.splittable,
          duration: {
            [this.isTaskDurationInSeconds  ? '_minutes' : '_hours']: item.stdDuration || item.stdDuration === 0 ? (item.stdDuration / 60) | 0 : 0, //set 0 when there is no value (for backward compatibility)
            [this.isTaskDurationInSeconds ? '_seconds' :'_minutes']: item.stdDuration || item.stdDuration === 0 ? item.stdDuration % 60 | 0 : 0, //set 0 when there is no value (for backward compatibility)
          },
          durationPerPart: item.perPart,
          setupTime: {
            _hours: item.setupTime || item.setupTime === 0 ? (item.setupTime / 60) | 0 : '',
            _minutes: item.setupTime || item.setupTime === 0 ? item.setupTime % 60 | 0 : '',
          },
          minHumanCapacity: item.minHumanCapacity,
          maxHumanCapacity: operationData?.manHours ? item.maxHumanCapacity : null,
          startAfterPercentage: item.startAfterPercentage,
          maxHumanCapacityEnabled: operationData?.manHours,
          minimumSplitBlock: {
            _hours: item.splittable && (item.minimumSplitBlock || item.minimumSplitBlock === 0) ? (item.minimumSplitBlock / 60) | 0 : '',
            _minutes: item.splittable && (item.minimumSplitBlock || item.minimumSplitBlock === 0) ? item.minimumSplitBlock % 60 | 0 : '',
          },
          maximumTotalDuration: {
            _hours: item.splittable && (item.maximumTotalDuration || item.maximumTotalDuration === 0) ? (item.maximumTotalDuration / 60) | 0 : '',
            _minutes: item.splittable && (item.maximumTotalDuration || item.maximumTotalDuration === 0) ? item.maximumTotalDuration % 60 | 0 : '',
          },

          maxDistanceEnabled: item.isMaxDistanceEnabled ?? false,
          maxDistance: {
            _hours: item.isMaxDistanceEnabled && (item.maxDistanceMinutes || item.maxDistanceMinutes === 0) ? (item.maxDistanceMinutes / 60) | 0 : '',
            _minutes: item.isMaxDistanceEnabled && (item.maxDistanceMinutes || item.maxDistanceMinutes === 0) ? item.maxDistanceMinutes % 60 | 0 : '',
          },

          toolRequired: item.toolRequirement ?? TOOL_REQUIRED_OPTIONS_TYPES.NO,
          recipe: item.recipe,
          disallowedStations: item.disallowedStations
        };

        return obj;
      });
    }
    return [];
  };

  getInitialLocations = (items) => {
    if (items) {
      let res = items.map((item) => {
        return { locationName: item.filter((id) => !!id) }; //TODO: L currently to filter out a null from array
      });
      return res;
    }
    return [];
  };

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: !this.isEditMode || this.isClone ? this.dialogLabels.get('create') : this.dialogLabels.get('edit'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled:
            (this.isEditMode && this.props.pristine) ||
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  }

  getMaterials = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.materials ? dialogData.materials : [];
  };

  getParts = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.partTypes ? dialogData.partTypes : [];
  };

  getProjects = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.projects ? dialogData.projects : [];
  };

  getAllOperations = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.operations ? dialogData.operations : [];
  };

  getAllLocations = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.locations ? dialogData.locations : [];
  };

  getRequiredMaterials = (materials) => {
    if (materials) {
      return materials.map((item) => {
        let res = {
          material: {
            id: item.material,
          },
          quantityExpectedPlies: item.quantity,
          length: item.length || item.length === 0 ? UnitHelper.userValueToServerValue(unitTypes.LENGTH, item.length) : null,
          weight: item.weight || item.weight === 0 ? UnitHelper.userValueToServerValue(unitTypes.WEIGHT, item.weight) : null,
          substituteMaterials: item.substituteMaterials
            ? item.substituteMaterials.map((m) => {
                return { id: m.value };
              })
            : [],
        };

        return res;
      });
    }
    return [];
  };

  getOperationDurationByTaskUOM = (duration) => {
    if(!duration){
      return null;
    }

    return this.isTaskDurationInSeconds
      ? DateTimeHelper.ConvertMinutesSecondsToSeconds(duration._minutes, duration._seconds)
      : DateTimeHelper.ConvertHoursMinutesToMinutes(duration._hours, duration._minutes)
  }

  getKitTypeOperations = (operations) => {
    if (operations) {
      //filter out the empty operations
      let filtered = operations.filter((op) => {
        return !!op.operationName;
      });

      return filtered.map((item) => {
        let res = {
          operationId: item.operationName,
          stdDuration: this.getOperationDurationByTaskUOM(item.duration),
          perPart: item.durationPerPart,
          setupTime: item.setupTime ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.setupTime._hours, item.setupTime._minutes) : null,
          splittable: item.splitEnabled,
          minHumanCapacity: item.minHumanCapacity,
          minimumSplitBlock:
            item.splitEnabled && item.minimumSplitBlock
              ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.minimumSplitBlock._hours, item.minimumSplitBlock._minutes)
              : null,
          maximumTotalDuration:
            item.splitEnabled && item.maximumTotalDuration
              ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.maximumTotalDuration._hours, item.maximumTotalDuration._minutes)
              : null,
          isMaxDistanceEnabled: item.maxDistanceEnabled,
          maxDistanceMinutes:
            item.maxDistanceEnabled && item.maxDistance
              ? DateTimeHelper.ConvertHoursMinutesToMinutes(item.maxDistance._hours, item.maxDistance._minutes)
              : null,

          startAfterPercentage: (item.startAfterPercentage || item.startAfterPercentage === 0) ? item.startAfterPercentage : null,
          toolRequirement: item.toolRequired,
          recipe: item.recipe,
          disallowedStations: item.disallowedStations
        };

        const operationData = this.getAllOperations()?.find((operation) => operation.data.id === item.operationName)?.data;
        if (operationData?.manHours) {
          res.maxHumanCapacity = item.maxHumanCapacity;
        }

        return res;
      });
    }
    return [];
  };

  getKitTypeLocations = (items) => {
    return items
      ? items.map((item) => {
          return item.locationName ? item.locationName : [];
        })
      : [];
  };

  onSubmit = (data) => {
    let newData = {
      businessId: data.kitType,
      partType: { id: data.partType },
      project: { id: data.project },
      description: data.description,
      maxDaisyChains: data.maxDaisyChains,
      tokensPerPart: data.tokenValue || null,
      requiredMaterials: this.getRequiredMaterials(data.materials),
      operations: this.getKitTypeOperations(data.operations),
      locations: this.getKitTypeLocations(data.locations),
      additionalFieldsDefinitions: this.props.sData.get('dialogData').additionalFields,
      additionalFields: data.additionalFields,
      ignoreValidationWarnings:
        this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox ? data.footerValidationCheckbox : false,
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    } else {
      newData.active = data.active;
    }

    this.props.actions.submit(newData, this.isEditMode, this.isClone, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onTabClick = (selectedTab) => {

    const submitFunc = this.props.handleSubmit(() => this.setState({ selectedTab }));

    // If there are any validation errors the handleSubmit callback is NOT invoked.
    const invalidFields = submitFunc();

    // If any of the current tab's fields are invalid prevent moving on to other tabs.
    if(invalidFields && this.isCurrentTabValid(invalidFields)){
      this.setState({ selectedTab })
    }
  };

  isCurrentTabValid = (invalidFields = {}) => {
    const currentTab = this.state.selectedTab;
      const tabsValidationFields = {
        details: ['kitType', 'partType', 'project', 'materials', 'maxDaisyChains'],
        operationsSequence: ['operations'],
        locationsSequence: ['locations']
      }

      const validationFields = tabsValidationFields[currentTab];
      const isCurrentTabInvalid = Object.keys(invalidFields).some(field => validationFields.includes(field));

      return !isCurrentTabInvalid
  }

  /**this method checks if the 'yes' was selected in previous line and enable or disable the current 'Previous' option */
  checkIfPreviousOptionDisabled = (index, option) => {
    const { fieldsValues: items } = this.props;
    return checkToolPreviousOptionDisabled(index, option, items);
  };

  /////////////// Details Tab ///////////////////////////

  getMaterialsOptionsInUse = (itemIndex) => {
    let idsInUse = [...this.state.optionsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  getAvailableMaterialsOptions = (itemIndex) => {
    let allOptions = this.getMaterials();
    let optionsInUse = this.getMaterialsOptionsInUse(itemIndex);

    return allOptions.filter((option) => {
      return optionsInUse.indexOf(option.value) < 0;
    });
  };

  getAvailableSubstituteMaterialsOptions = (itemIndex) => {
    let idsInUse = [...this.state.optionsInUse];

    let allOptions = this.getMaterials();
    let itemId = idsInUse[itemIndex];
    if (itemId) {
      return allOptions.filter((option) => {
        return option.value !== itemId;
      });
    }
    return allOptions;
  };

  onMaterialChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...this.state.optionsInUse];

    if (oldValue) {
      //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) {
      //no value when clear the selection
      idsInUse[index] = value.value;
    }

    this.setState({ optionsInUse: idsInUse });
  };

  onMaterialRemoveCallback = (index) => {
    let idsInUse = [...this.state.optionsInUse];

    if (index > -1) {
      idsInUse.splice(index, 1);
    }

    this.setState({ optionsInUse: idsInUse });
  };

  onMaterialAddCallback = () => {
    let idsInUse = [...this.state.optionsInUse];

    idsInUse.push(null); //hold index in optionsInUse for the new added item

    this.setState({ optionsInUse: idsInUse });
  };

  listItemRenderer = (data) => {
    return (
      <KitTypeMaterialListItem
        {...data}
        onChangeCallback={this.onMaterialChangeCallback}
        options={this.getAvailableMaterialsOptions(data.index)}
        substituteOptions={this.getAvailableSubstituteMaterialsOptions(data.index)}
        {...this.props}
      />
    );
  };

  renderLengthOrWeight = () => {
    return PermissionManager.isWeightSupported() ? (
      <label className="column">{`${this.labels.get('weight')} (${UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)})`}</label>
    ) : (
      <label className="column">{`${this.labels.get('length')} (${UnitHelper.getLabelForUnitType(unitTypes.LENGTH)})`}</label>
    );
  };

  renderMaterialsList = () => {
    return (
      <div>
        <div className="list-materials-title ">
          <label className="column wide-column">
            {this.labels.get('materialsnumber', '', {
              materialsNumber: this.state.optionsInUse.length,
            })}
          </label>
          <label className="column wide-column">{this.labels.get('submaterials')}</label>
          <label className="column">{this.labels.get('pliesnumber') + '*'}</label>
          {this.renderLengthOrWeight()}
        </div>

        <List
          name="materials"
          itemRenderer={this.listItemRenderer}
          preSelectedItems={this.isEditMode ? this.itemToEdit.kitTypeMaterials : [null]}
          onRemoveCallback={this.onMaterialRemoveCallback}
          onAddCallback={this.onMaterialAddCallback}
          addButtonLabel={this.labels.get('addmaterial')}
          maxItemsToOverflow={4}
        />
      </div>
    );
  };

  renderAdditionalFields = () => {
    let dialogData = this.props.sData.get('dialogData');
    if (!dialogData || !dialogData.additionalFields) {
      return null;
    }
    return dialogData.additionalFields.map((field, index) => {
      return (
        <AdditionalField key={index} field={field} index={2} className={'short-textfield'} fieldsInitialValues={this.addtionalFieldsInitialValues} />
      );
    });
  };

  maxLength30 = Validation.maxLength(30);

  renderDetailsTab = () => {
    return (
      <Tabs.Tab className="details-tab" eventKey="details" title={this.labels.get('tabs.details')} animation={false}>
        <div>
          <InputSection htmlFor="active">
            <Checkbox name="active" id="active" label={this.labels.get('active')} disabled={this.isEditMode} />
          </InputSection>

          <div className="params-section">
            <InputSection label={this.labels.get('kittype') + '*'} htmlFor="kitType">
              <TextField id="kitType" name="kitType" className="short-textfield" validate={[Validation.required, this.maxLength30]} />
            </InputSection>

            <InputSection label={this.labels.get('parttype') + '*'} htmlFor="partType">
              <Combobox
                id="partType"
                name="partType"
                options={this.getParts()}
                parse={Parse.comboValueOnly()}
                format={Format.findOptionByValue(this.getParts())}
                validate={Validation.required}
              />
            </InputSection>

            <InputSection label={this.labels.get('project') + '*'} htmlFor="project">
              <Combobox
                id="project"
                name="project"
                options={this.getProjects()}
                parse={Parse.comboValueOnly()}
                format={Format.findOptionByValue(this.getProjects())}
                validate={Validation.required}
              />
            </InputSection>
          </div>

          <InputSection label={this.labels.get('description')} htmlFor="description" className="full-width description-section">
            <TextAreaField id="description" name="description" maxLength="1000" />
          </InputSection>

          <div className="params-section">
            <InputSection label={this.labels.get('tokenValue')} htmlFor="tokenValue" className="">
              <TextField id={'tokenValue'} name={'tokenValue'}  normalize={Normalize.number(true, 1, 9999)}/>
            </InputSection>

            {PermissionManager.getOrgPreferences().compositeOrgDisplayEnabled && (
              <InputSection label={this.labels.get('maxDaisyChains') + '*'} htmlFor="maxDaisyChains" className="daisy-chains-section">
                <TextField id={'maxDaisyChains'} name={'maxDaisyChains'}
                           validate={Validation.required}
                           normalize={Normalize.number(true, 1, 999)}
                />
              </InputSection>
            )}
          </div>

          <div className="additional-fields">{this.renderAdditionalFields()}</div>

          {this.renderMaterialsList()}
        </div>
      </Tabs.Tab>
    );
  };

  /////////////// Operations Tab ///////////////////////////

  hasValidationError = (index) => {
    if (this.props.submitFailed) {
      let operations = this.props.synchronousError?.operations?.[index];
      if (operations) {
        let fields = Object.keys(operations)?.filter((key) => {
          return key !== 'operationName' && key !== 'duration';
        });
        return fields?.length > 0;
      }
    }
    return false;
  }

  getKitTypeOperationListItem = (itemProps) => {
    let curOperations = this.props.fieldsValues;
    let splitEnabled = curOperations[itemProps.index]?.splitEnabled ?? false;
    let maxDistanceEnabled = curOperations[itemProps.index]?.maxDistanceEnabled ?? false;

    return (
      <KitTypeOperationListItem
        key={this.state.operationsDummyKey + '-' + itemProps.index}
        {...itemProps}
        selectedOperation={curOperations[itemProps.index]}
        onToolRequiredChangeCallback={this.onToolRequiredChangeCallback}
        onSplitEnabledChangeCallback={this.onSplitEnabledChangeCallback}
        onMaxDistanceChangeCallback={this.onMaxDistanceChangeCallback}
        allOperations={this.getAllOperations()}
        labels={this.labels}
        splitEnabled={splitEnabled}
        maxDistanceEnabled={maxDistanceEnabled}
        checkToolPreviousOptionDisabled={this.checkIfPreviousOptionDisabled}
        hasValidationError={this.hasValidationError(itemProps.index)}
        itemRendererData={this.props.fieldsValues[itemProps.index]}
      />
    );
  };

  onToolRequiredChangeCallback = () => {
    setTimeout(() => {
      this.handleToolRequiredChange();
    }, 0);
  };

  onSplitEnabledChangeCallback = () => {
    setTimeout(() => {
      this.updateDummyKey();
    }, 0);
  };

  onMaxDistanceChangeCallback = () => {
    setTimeout(() => {
      this.updateDummyKey();
    }, 0);
  };

  onOperationAddAtCallback = () => {
    setTimeout(() => {
      this.handleToolRequiredChange();
    }, 0);
  };

  onOperationRemoveCallback = () => {
    setTimeout(() => {
      this.updateDummyKey();
    }, 0);
  };

  handleToolRequiredChange = () => {
    let newOperations = checkToolRequiredPreviousOptions(this.props.fieldsValues);
    this.props.change('operations', newOperations);
    this.updateDummyKey();
  };

  updateDummyKey = () => {
    let newState = { operationsDummyKey: this.state.operationsDummyKey === 0 ? 1 : 0 };
    this.setState(newState);
  };

  renderOperationsSequenceTab = () => {
    if (!PermissionManager.hasLocationsAndOperationsSequenceTabPermissions()) {
      return null;
    }

    let allOptions = this.getAllOperations();

    return (
      <Tabs.Tab
        className="sequence-tab"
        eventKey="operationsSequence"
        title={this.labels.get('tabs.operationsSequence')}
        animation={false}
        disabled={!this.isOperationsSequenceEnabled}
      >
        <div>
          <div
            className={cn('operations-list-header', {
              'scheduler-enabled': PermissionManager.getOrgPreferences().schedulerEnabled,
            })}
          >
            <label className="column">{this.labels.get('operations.order')}</label>
            <label className="column">{this.labels.get('operations.operation') + '*'}</label>
            <label className="column">{this.labels.get('operations.duration') + '*'}</label>
            {PermissionManager.isToolsSchedulingEnabled() && (
                <label className="column tool-type">{this.labels.get('operations.toolRequired')}</label>
            )}
          </div>

          <AddRemoveListExt
            updateKey={this.state.operationsDummyKey}
            {...this.props}
            name={'operations'}
            itemRendererComponent={this.getKitTypeOperationListItem}
            itemRendererOptions={allOptions}
            preSelectedItems={this.itemToEdit ? this.itemToEdit.operations : []}
            preSelectedItemsIds={this.operationsIdsInUse}
            addButtonLabel={this.labels.get('operations.add')}
            showRemoveAllButton={true}
            removeAllButtonLabel={this.labels.get('sequence.remove')}
            onRemoveCallback={this.onOperationRemoveCallback}
            onAddAtCallback={this.onOperationAddAtCallback}
            parseNewAddedItem={parseNewAddedItem}
            maxItemsToOverflow={10000}
            maxHeightToOverflow={400}
          />
        </div>
      </Tabs.Tab>
    );
  };

  /////////////// Locations Tab ///////////////////////////

  renderLocationsSequenceTab = () => {
    if (!PermissionManager.hasLocationsAndOperationsSequenceTabPermissions()) {
      return null;
    }

    let allOptions = this.getAllLocations();

    return (
      <Tabs.Tab
        className="sequence-tab"
        eventKey="locationsSequence"
        title={this.labels.get('tabs.locationsSequence')}
        animation={false}
        disabled={!this.isLocationsSequenceEnabled}
      >
        <div>
          <div className="locations-list-header ">
            <label className="column">{this.labels.get('locations.order')}</label>
            <label className="column">{this.labels.get('locations.locations')}</label>
          </div>

          <AddRemoveListExt
            {...this.props}
            name={'locations'}
            itemRendererComponent={KitTypeLocationListItem}
            itemRendererOptions={allOptions}
            preSelectedItemsIds={this.locationsIdsInUse}
            addButtonLabel={this.labels.get('locations.add')}
            showRemoveAllButton={true}
            removeAllButtonLabel={this.labels.get('sequence.remove')}
            maxItemsToRender={15}
            maxItemsToOverflow={10000}
            maxHeightToOverflow={420}
          />
        </div>
      </Tabs.Tab>
    );
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox')
      ? { label: this.dialogLabels.get('ignorewarnings'), onChange: this.onFooterValidationChange }
      : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    let titleInfo = this.itemToEdit?.businessId || '';

    if (this.isClone) {
      titleText = this.labels.get('header.title.clone');
    }

    let selectedTab = this.state.selectedTab;

    return (
      <Dialog
        id="create-kit-type-dialog"
        className={cn('create-kit-type-dialog', {
          wide: PermissionManager.isToolsSchedulingEnabled(),
        })}
        titleText={titleText}
        moreInfoTextHeader={titleInfo}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <Tabs activeKey={selectedTab} tabType="default" onSelect={this.onTabClick} id="content-tabs" mountOnEnter={false} unmountOnExit={false}>
          {this.renderDetailsTab()}
          {this.renderOperationsSequenceTab()}
          {this.renderLocationsSequenceTab()}
        </Tabs>
      </Dialog>
    );
  }
}

CreateKitTypeDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  fieldsValues: PropTypes.array,
  initialize: PropTypes.func,
  handleSubmit: PropTypes.func,
  reloadParentComponent: PropTypes.func,
  change: PropTypes.func,
  pristine: PropTypes.bool,
};

CreateKitTypeDialog.defaultProps = {
  fieldsValues: [],
  pristine: false,
  initialize: () => console.error('initialize is missing!'),
  handleSubmit: () => console.error('handleSubmit is missing!'),
  reloadParentComponent: () => console.error('reloadParentComponent is missing!'),
  change: () => console.error('change is missing!'),
};

const selector = formValueSelector('createKitTypeDialog');
const mapStateToProps = (state) => {
  return { fieldsValues: selector(state, 'operations'),
           synchronousError: getFormSyncErrors('createKitTypeDialog')(state),};
};
const reduxFormConfig = {
  form: 'createKitTypeDialog',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
};

export default compose(connect(mapStateToProps), reduxForm(reduxFormConfig))(CreateKitTypeDialog);
