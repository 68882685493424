import PropTypes from 'prop-types';
import DroppableWrapper from './droppableWrapper';
import AssignedShift from './assignedShift';
import cn from 'classnames';
import { useState } from 'react';
import { filterTypes } from '../../../../enums/shiftSchedulerEnums';

export default function ShiftsSchedulerCell({ data={ resourceData: {},
                                                          assignments: {},
                                                          day: '',
                                                          date: {},
                                                        },
                                              actions={},
                                              isTemplate=false,
                                              checkIsDroppable=()=>true,
                                              highlighted=()=>null,
                                              setHighlighted=()=>{}
                                            }) {

  const { resourceData, assignments, day, date } = data;
  const [cellHighlighted, setCellHighlighted] = useState(false);

  const onDragEnter = (e) => {
    setCellHighlighted(true);
  };

  const onDragLeave = (e) => {
    setCellHighlighted(false);
  };

  const classNames = cn('grid-item', 'grid-cell', {
    'highlighted-row': highlighted?.direction === 'row' && highlighted?.headerId === resourceData.id,
    'highlighted-column': highlighted?.direction === 'column' && highlighted?.headerId === day,
    'highlighted-cell': cellHighlighted,
    'highlighted-all': highlighted?.direction === 'all',
  });

  const dropAction = (shiftAssignData) => {
    setCellHighlighted(false);

    if (shiftAssignData?.shiftData?.id === 'eraser') {
      const filterData = {
        filterBy: filterTypes.DAY_AND_RESOURCE,
        day,
        resourceId: resourceData.id,
      };
      actions.removeShifts({ filterData });
      return;
    }

    actions.assignShift(shiftAssignData);
  };

  return (
    <DroppableWrapper
      className={classNames}
      action={dropAction}
      assignmentData={{ resourceData, day, date }}
      isTemplate={isTemplate}
      checkIsDroppable={checkIsDroppable}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      setHighlighted={setHighlighted}
    >
      {assignments?.shifts?.map((shift) => (
        <AssignedShift
          key={`${shift.id} ${shift.templateId}`}
          shiftData={{ ...shift, day, date }}
          resourceData={resourceData}
          actions={actions}
          isTemplate={isTemplate}
          highlighted={highlighted}
        />
      ))}
    </DroppableWrapper>
  );
}

ShiftsSchedulerCell.propTypes = {
  data: PropTypes.shape({
    resourceData: PropTypes.object,
    assignments: PropTypes.object,
    day: PropTypes.string,
    date: PropTypes.object,
  }),
  actions: PropTypes.object,
  isTemplate: PropTypes.bool,
  checkIsDroppable: PropTypes.func,
  highlighted: PropTypes.object,
  setHighlighted: PropTypes.func,
};
