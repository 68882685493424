import Network from 'infrastructure/js/modules/network';

export function fetchSandboxes(query) {
  return Network.post('sandbox-orgs/items', query);
}
export function createSandbox(data) {
  return Network.polling.post('sandbox-orgs', data);
}
export function cloneSandbox(data) {
  return Network.polling.post('sandbox-orgs', data);
}
export function editSandbox(data, id) {
  return Network.put(`sandbox-orgs/${id}`, data);
}
export function resetSandbox(data) {
  return Network.polling.put(`sandbox-orgs/${data?.id}/refresh`);
}
export function deleteSandboxes(data) {
  return Network.delete('sandbox-orgs', data);
}
export function stopSandboxCreation() {
  return Network.post('sandbox-orgs/cancel');
}


