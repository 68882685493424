import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import {enumTypes, getLookupOptions, getEnumValue} from '../../../../../utils/enumHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {openLocationInNewTab} from '../../../../../utils/locationHelper';

require('./locationSettingsForm.scss');

const maxLength50 = Validation.maxLength(50);

function isLocationTypeAllowed(location) {
  switch (location.value) {
    case getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS'):
    case getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION'):
      return PermissionManager.isSupplierOrg();
    case getEnumValue(enumTypes.LOCATION_TYPE)('AREA'):
    case getEnumValue(enumTypes.LOCATION_TYPE)('FREEZER'):
    case getEnumValue(enumTypes.LOCATION_TYPE)('DEFROSTING_AREA'):
    case getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA'):
      return PermissionManager.isSelfSustainingOrg();
    case getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS'):
      return PermissionManager.isCustomerOrg();
    default:
      console.error('LocationSettingForm - unknown location type: ' + location.value);
      return false;
  }
}

const LocationSettingsForm  = ({ initialize=() => { console.error('initialize is missing!'); },
                                 pristine=true,
                                 handleSubmit=() => { console.error('handleSubmit is missing!'); },
                                 actions,
                                 change=() => { console.error('change is missing!'); },
                                 itemToEdit=null,
                                 onSave=()=>{},
                                 onDelete=()=>{}
                                }) => {

    const labels = createLabelHelper('mat.administration.matsettings.digitalTwin.location.');
    const locationTypes = useMemo(() => getLookupOptions(enumTypes.LOCATION_TYPE, 'LOCATION').filter(isLocationTypeAllowed), []);

    const [locationType, setLocationType] = useState(null);
    const [asFreezer, setAsFreezer] = useState(true);

    useEffect(() => {
      initFormValues();
    }, [itemToEdit]);


  const initFormValues = () =>  {
    const type = getInitialLocationType();

    let initialValues = {
      locationType: type,
      etlDecayRate: getInitialEtlDecayRate(type),
    };

    if (isEditMode()) {
      initialValues.isUsedForSmartSelection = itemToEdit.isUsedForSmartSelection;
      // initialValues.locationName = itemToEdit.displayName;
      initialValues.locationName = itemToEdit.name;
      initialValues.isAsFreezer = itemToEdit.isFreezer;

      setAsFreezer(itemToEdit.isFreezer);
    }

    initialize(initialValues);
    setLocationType(type);
  }

  const isEditMode = () => {
    return itemToEdit?.id;
  }

  const getInitialLocationType = () => {
    if (isEditMode()) {
      return itemToEdit?.locationType;
    }
    //by default the AREA type is selected
    let found = locationTypes.find((item) => {return item.value === getEnumValue(enumTypes.LOCATION_TYPE)('AREA'); });
    return found ? found.value : null;
  };

  const getInitialEtlDecayRate = (locType) => {
    if (isEditMode()) {
      return itemToEdit?.etlDecayRate;
    }
    return (locType === getEnumValue(enumTypes.LOCATION_TYPE)('FREEZER') ||
            locType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION') ||
            locType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
            locType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS') ||
            locType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA') ? 0 : 100);
  }

  const onSubmit = (data) => {
    let newData = {
      name: data.locationName,
      locationType: data.locationType,
      etlDecayRate: data.etlDecayRate,
      isUsedForSmartSelection: data.isUsedForSmartSelection,
      parentId: itemToEdit.parentId,
      prevSiblingId: null,
      nextSiblingId: null,
    };

    if (data.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION') ||
        data.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
        data.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
    ) {
      newData.isFreezer = data.isAsFreezer;
    }

    if (isEditMode()) {
      newData.id = itemToEdit.id;
      newData.parentId = null;  // do not send parentId on Edit (Server side request)
    }

    actions?.viewActions?.submit(newData, isEditMode(), itemToEdit.isStation, false)
      .then((response) => {
        if (response && response.success) {
          onSave?.(response.data);
        }
      });
  };

  const onOpenInNewTabHandler = () => {
    openLocationInNewTab(itemToEdit?.id);
  }

  const onDeleteHandler = () => {
    actions?.viewActions?.delete(itemToEdit.id).then((response) => {
        if (response && response.success) {
          onDelete?.();
        }
      });
  }

  const onLocationTypeChange = (newValue, oldValue) => {
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    if (newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA') ||
        newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
        newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
    ) {
      change('isUsedForSmartSelection', null);
    }
    if (newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('FREEZER') ||
        newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA')) {
      change('etlDecayRate', 0);
    }
    if (newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION') ||
        newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
        newValue?.value === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
    ) {
      change('isAsFreezer', true);
      change('etlDecayRate', 0);
      setAsFreezer(true);
    }
    setLocationType(newValue?.value || null);
  };

  const onAsFreezerChanged = (e) => {
    setAsFreezer(e.target.checked);
    if (e.target.checked) {
      change('etlDecayRate', 0);
    }
  }

  const getHeaderItems = () => {
    return {
      title: isEditMode() ? labels.get('header.title.edit') : labels.get('header.title.create'),
      buttons: [
        ...(isEditMode() ? [
          {
            id: 'openInNewTab',
            icon: 'pl pl-export-icon',
            tooltip: headerItemsLabels.OPEN_IN_NEW_TAB,
            action: onOpenInNewTabHandler
          }]: []),
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: pristine,
          action: handleSubmit(onSubmit),
        },
        ...(isEditMode() ? [
          {
            id: 'delete',
            label: headerItemsLabels.DELETE,
            className: 'no-icon',
            action: onDeleteHandler,
          }]: []),
      ]
    }
  };

  const renderAsFreezer = () => {
    if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION') ||
        locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
        locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
    ) {
      return (
        <InputSection htmlFor="isAsFreezer" className='with-margin' >
          <Checkbox id='isAsFreezer' name='isAsFreezer'
                    label={labels.get('asFreezer')}
                    onChange={onAsFreezerChanged}
          />
        </InputSection>
      )
    }
    return <InputSection />;
  }

  let headerItems = getHeaderItems();

    return (
      <div className='location-settings-form'>
        <Header {...headerItems}/>

        <div className='section'>
          <InputSection htmlFor="isUsedForSmartSelection" className="inline right-side">
            <Checkbox id="isUsedForSmartSelection" name="isUsedForSmartSelection"
                      label={labels.get('isUsedForSmartSelection')}
                      disabled={locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA')  ||
                                locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS')  ||
                                locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
                               }
                      />
          </InputSection>
        </div>

        <div className='section'>
          <InputSection label={labels.get('locationname')+'*'} htmlFor="locationName" >
            <TextField id="locationName" name="locationName" className="short-textfield"
                       maxLength={50}
                       validate={[Validation.required, maxLength50]}
            />
          </InputSection>

          <InputSection label={labels.get('locationtype')+'*'} htmlFor="locationType" >
            <Combobox id="locationType" name="locationType"
                      options={locationTypes}
                      onChangeCallback={onLocationTypeChange}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(locationTypes)}
                      validate={Validation.dropdown.required}
            />
          </InputSection>
        </div>

        <div className='section'>
          <InputSection label={labels.get('etlDecayRate')+'*'} htmlFor="etlDecayRate" >
            <TextField id="etlDecayRate" name="etlDecayRate"
                       className="short-textfield"
                       normalize={Normalize.number(true, 0, 100)}
                       validate={Validation.required}
                       disabled={locationType === getEnumValue(enumTypes.LOCATION_TYPE)('FREEZER') ||
                                 locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA') ||
                                 ( ( locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION') ||
                                   locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
                                   locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
                                   ) && asFreezer
                                 )
                                }
            />
          </InputSection>

          {renderAsFreezer()}
        </div>

      </div>
    );
}

LocationSettingsForm.propTypes = {
  itemToEdit: PropTypes.object,
  actions : PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  change: PropTypes.func,
  pristine: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

export default reduxForm({
    form: 'locationSettingsForm',
  }
)(LocationSettingsForm);




