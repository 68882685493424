import PropTypes from 'prop-types';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';

require('./iconWithTooltipCell.scss');

export default function IconWithTooltipCell(props) {

  if (props.value?.icon) {
    let {icon, tooltip, className} = props.value;

    const Icon = icon;

    return <Tooltip
      placement="top"
      value={tooltip}
      delayShow={500}>
      <Icon className={className}/>
    </Tooltip>
  }
  return null;
}

IconWithTooltipCell.propTypes = {
  value: PropTypes.object.isRequired,
};

