import React from 'react';
import PropTypes from 'prop-types';
import FilterFooter from '../Common/FilterFooter/filterFooter';
import FilterLayout from '../Common/FilterLayout/filterLayout';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {PL_DatePicker} from 'infrastructure/js/components/controls/DatePicker/datepicker';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
let moment = require('moment');

require('./dateSelectFilter.scss');

export default class PL_DateSelectFilter extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.filter.');

    this.state = {
      selectedDate: null,
      dummyKey: 0, //dummy key to force the component remount
    }

    this.currentState = {...this.state}
  }

  componentDidMount() {
    this.filterName = this.props.filterName;
    this.setModel();
  }

  setModel() {
    let {values} = GridHelper.getActivatedFilter(this.props.filterName, this.props.context?.componentParent?.props?.filterState?.toJS());
    if (values && values.length > 0) {
      let value = values[0];
      value = DateTimeHelper.ConvertToDate({epochDateTime: value});
      this.currentState = { selectedDate: value, dummyKey: 0 }
    }
  }

  // called by agGrid
  afterGuiAttached(params) {
    this.setState({selectedDate: this.currentState.selectedDate, dummyKey: 1-this.state.dummyKey })
    this.hidePopup = params.hidePopup;

    //to prevent the appearance of DatePicker calendar on the Filter opening
    if (this.datePickerRef && this.datePickerRef.pickerRef) {
      this.datePickerRef.pickerRef.setOpen(false);
    }
  }

  // called by agGrid
  doesFilterPass(params) {
    return true;
  }

// called by agGrid
  isFilterActive() {
    return (this.currentState.selectedDate !== null);
  }

  // called by agGrid
  onNewRowsLoaded() {
    this.handleFilterState();
  }

  handleFilterState = () => {
    let filterState = this.props.context?.componentParent?.props?.filterState;

    let isFilterStateActive = filterState?.some((item) => {
      return item.filterName === this.filterName && item.values;
    });

    if (!isFilterStateActive && this.isFilterActive()) {
      this.deactivateFilter();
    }
  }

  deactivateFilter = () => {
    let newState = { selectedDate: null, dummyKey: (this.state.dummyKey === 0 ? 1 : 0) };
    this.setState(newState);
    this.currentState = newState;
  }

  apply(){
    this.currentState = {...this.state};

    this.props.colDef?.filterParams?.onFilterChanged?.(this.getModel());
    this.hidePopup();
  }

  cancel() {
    this.hidePopup();
  }

  getModel() {
    let { selectedDate } = this.state;
    //set timezone to UTC
    let d = selectedDate ? moment(selectedDate, DateTimeHelper.getDateFormat(), DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true) : null;
    //convert to epoch format
    let date2 = DateTimeHelper.ConvertFromDate(d);
    if (date2?.epochDateTime){
      return [{filterName: this.filterName, values: [date2.epochDateTime]}];
    }
    return [{filterName: this.filterName, values: []}];
  }

  //not in use
  getApi() {
    return {
      getModel: this.getModel.bind(this)
    };
  }

  onDateChange = (date) => {
    if(!date || !date._isValid){
      this.setState({selectedDate: null});
      return;
    }
    this.setState({selectedDate: date});
  };

  onKeyDownHandler = (e) => {
    e.preventDefault()
  };

  renderDatePicker = () => {
    if (this.props.isTime) {
      return (<PL_DatePicker id="dateTimePicker"
                             ref={r => this.datePickerRef = r}
                             key={this.state.dummyKey}
                             showTimeSelect
                             dateFormat={DateTimeHelper.getDateTimeFormat()}
                             timeCaption="time"
                             timeIntervals={10}
                             popperPlacement="auto"
                             validate={Validation.date}
                             selected={this.state.selectedDate}
                             onChangeCallback={this.onDateChange}
                             onKeyDown={this.onKeyDownHandler}
                             isClearable
      />)
    }

    return (<PL_DatePicker id="datePicker"
                           ref={r => this.datePickerRef = r}
                           key={this.state.dummyKey}
                           popperPlacement="auto"
                           validate={Validation.date}
                           selected={this.state.selectedDate}
                           onChangeCallback={this.onDateChange}
                           onKeyDown={this.onKeyDownHandler}
                           isClearable
    />)
  };

  render() {

    return (
      <FilterLayout className="date-select-filter"
                    columnWidth={this.props.column.actualWidth}
                    filterWidth={350}
                    filterAlignment={this.props.filterAlignment}
      >

        <span>{this.props.label}</span>
        {this.renderDatePicker()}

        <FilterFooter
          okText={this.labels.get('submit') || 'FILTER'}
          cancelText={this.labels.get('cancel') || 'CANCEL'}
          onCancel={this.cancel.bind(this)}
          onOk={this.apply.bind(this)}
        />

      </FilterLayout>);
  }
}

PL_DateSelectFilter.defaultProps = {
  label: '',
}

PL_DateSelectFilter.propTypes = {
  filterName: PropTypes.string.isRequired,
  label: PropTypes.string,
}
