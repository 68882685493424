import Network from 'infrastructure/js/modules/network';
import * as navigationService from '../../services/Navigation/navigationService';
import * as locationsService from '../../services/Locations/locationsService';
import * as liveMapService from '../../services/LiveMap/liveMapService';
import { navigationStates } from '../../enums/navigationStates';
import { api as navigatorActionsApi } from '../AppTopbar/navigatorActions';
import { getHomePageTypeByNavigationState } from '../../utils/navigationHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager'

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SIDE_NAVIGATOR_FETCH_LOCATIONS_FINISHED: 'SIDE_NAVIGATOR_FETCH_LOCATIONS_FINISHED',
  SIDE_NAVIGATOR_FETCH_FAVORITES_FINISHED: 'SIDE_NAVIGATOR_FETCH_FAVORITES_FINISHED',
  SIDE_NAVIGATOR_FETCH_MAPS_FINISHED: 'SIDE_NAVIGATOR_FETCH_MAPS_FINISHED',
  SIDE_NAVIGATOR_FAVORITE_SAVED: 'SIDE_NAVIGATOR_FAVORITE_SAVED',
  SIDE_NAVIGATOR_FAVORITE_DELETED: 'SIDE_NAVIGATOR_FAVORITE_DELETED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchLocationsFinished: function (payload) {
    return { type: actionTypes.SIDE_NAVIGATOR_FETCH_LOCATIONS_FINISHED, payload };
  },
  fetchFavoritesFinished: function (payload) {
    return { type: actionTypes.SIDE_NAVIGATOR_FETCH_FAVORITES_FINISHED, payload };
  },
  fetchMapsFinished: function (payload) {
    return { type: actionTypes.SIDE_NAVIGATOR_FETCH_MAPS_FINISHED, payload };
  },
  saveFavorite: function (payload) {
    return { type: actionTypes.SIDE_NAVIGATOR_FAVORITE_SAVED, payload };
  },
  deleteFavorite: function (payload) {
    return { type: actionTypes.SIDE_NAVIGATOR_FAVORITE_DELETED, payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchFavorites = function () {
  return function (dispatch, getState) {
    api.fetchFavorites(dispatch, getState)();
  };
};

jsxActions.saveFavorite = function (data) {
  return function (dispatch, getState) {
    api.saveFavorite(dispatch, getState)(data);
  };
};

jsxActions.deleteFavorite = function (data) {
  return function (dispatch, getState) {
    api.deleteFavorite(dispatch, getState)(data);
  };
};

jsxActions.fetchLocations = function () {
  return function (dispatch, getState) {
    api.fetchLocations(dispatch, getState)();
  };
};

jsxActions.setHomePage = function (itemData) {
  return function (dispatch, getState) {
    const { entityType, entityId } = itemData;
    const homePageType = getHomePageTypeByNavigationState(entityType);

    if(PermissionManager.hasAccessToMultipleOrgs()){
      navigatorActionsApi.setHomePage(dispatch, getState)(homePageType, entityId);
      return;
    }

    navigatorActionsApi.setHomePageWithServerUpdate(dispatch, getState)(homePageType, entityId);
  };
};

jsxActions.init = function (shouldFetchLocations) {
  return function (dispatch, getState) {
    api.init(dispatch, getState)(shouldFetchLocations);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function (shouldFetchLocations = true) {
    if (shouldFetchLocations) {
      api.fetchLocations(dispatch, getState)();
    }

    api.fetchFavorites(dispatch, getState)();
    api.fetchMaps(dispatch, getState)();
  };
};

api.fetchLocations = function (dispatch, getState) {
  return function () {
    let method = utility.isHideInactiveLocations(getState) ? locationsService.fetchActiveLocations : locationsService.fetchOrderedLocations;
    return method().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetch location failed');
        return { success: false };
      }

      let searchResultsData = utility.ConvertSearchResponseToMenuItems(response.dataList);

      dispatch(actions.fetchLocationsFinished(searchResultsData));
    });
  };
};

api.fetchFavorites = function (dispatch, getState) {
  return function () {
    return navigationService.getAllFavorites().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetch favorites failed');
        return { success: false };
      }

      let searchResultsData = utility.ConvertFavoritesObjectsToUIStructureObjects(response.dataList);

      dispatch(actions.fetchFavoritesFinished(searchResultsData));
    });
  };
};

api.fetchMaps = function (dispatch, getState) {
  return function () {
    return liveMapService.fetchMapMenuItems().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetch maps failed');
        return { success: false };
      }

      let mapsMenuData = utility.convertMapNamesObjectsToUIStructureObjects(response.dataList);

      dispatch(actions.fetchMapsFinished(mapsMenuData));
    });
  };
};

api.saveFavorite = function (dispatch, getState) {
  return function (data) {
    const { entityId = -1, entityType, label } = data;
    dispatch(actions.saveFavorite({ entityId, entityType, label }));

    return navigationService
      .saveFavorite({ entityId, entityType, label })
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.deleteFavorite(data));
          console.error('save favorite failed', response);
          return { success: false };
        }
        return { success: true };
      })
      .catch((err) => {
        dispatch(actions.deleteFavorite(data));
        console.error('saveFavorite() catch', err);
        return { success: false };
      });
  };
};

api.deleteFavorite = function (dispatch, getState) {
  return function (data) {
    const { entityId: linkEntityId, entityType: linkType, label } = data;
    dispatch(actions.deleteFavorite(data));

    return navigationService
      .deleteFavorite({ linkEntityId, linkType, label })
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          dispatch(actions.saveFavorite(data));
          console.error('delete favorite failed', response);
          return { success: false };
        }
        return { success: true };
      })
      .catch((err) => {
        dispatch(actions.saveFavorite(data));
        console.error('deleteFavorite() catch', err);
        return { success: false };
      });
  };
};

let utility = {};

utility.ConvertSearchResponseToMenuItems = function (originalDataItems) {
  return originalDataItems.map((obj) => {
    let { name, id, isStation, locationType } = obj;
    let itemName = obj.isActive === false ? name + ' (Inactive)' : name;
    return {
      label: itemName,
      entityId: id,
      entityType: isStation ? navigationStates.STATION : navigationStates.LOCATION,
      isActive: obj.isActive,
      locationType
    };
  });
};

utility.ConvertFavoritesObjectsToUIStructureObjects = function (originalDataItems) {
  return originalDataItems.map((obj) => {
    let { label, entityId, entityType } = obj;

    return {
      entityId,
      entityType,
      label,
    };
  });
};

utility.convertMapNamesObjectsToUIStructureObjects = function (originalDataItems) {
  return originalDataItems.map((x) => {

    return {
      entityId: x.id,
      entityType: navigationStates.LIVE_MAP,
      label: x.name,
    };
  });
};


utility.isHideInactiveLocations = function (getState) {
  let loggedInUser = getState().login.get('loggedInUser');
  return loggedInUser ? loggedInUser?.loggedInOrg?.orgPreferences?.hideInactiveLocations : false;
};
