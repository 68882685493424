import React from 'react';

import cn from 'classnames';
import PropTypes, { func, number, string, object, bool, node, oneOf, oneOfType, array, arrayOf, exact, shape } from 'prop-types';
import Dialog, { PL_DialogWrapper } from '../dialog.js';
import Tabs from '../../Tabs/tabs.js';
import Tab from 'react-bootstrap/Tab';
import Overlay from '../../Overlay/overlay.js'
import { createLabelHelper } from '../../../utils/labelHelper';
import './wizardDialog.scss';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import mapDispatchToProps from 'react-redux/lib/connect/mapDispatchToProps';

export default class PL_WizardDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialog.wizard.');

    this.closeConfirmationButtons = {
      left: [],
      right:
        [
          {
            id: `${this.props.id}_cancelHideButton`,
            text: this.labels.get('cancel', 'cancel'),
            action: this.hideCloseConfirmationDialog,
            bsStyle: 'default'
          },
          {
            id: `${this.props.id}_confirmCloseButton`,
            text: this.labels.get('close', 'close'),
            action: this.onClose,
            bsStyle: 'primary'
            //className: 'btn-primary'
          }
        ]
    };

    this.closeConfirmationHeader = (
      <div>
        <span className={`header-icon pl ${props.closeConfirmationDialog?.iconClass || 'pl-warning-icon'}`}/>
        <label>{props.closeConfirmationDialog?.titleText || this.labels.get('closeConfirmation.title')}</label>
      </div>
    );

    this.state = {
      selectedStepIndex: this.props.currentStep || 0,
      showCloseConfirmation: false,
      nextButtonDisabled: false
    };

    this.onEnterEvents = [];
    this.onExitEvents = [];
  }

  componentDidMount() {
    if (this.props.setRef)
      this.props.setRef(this);
  }

  //Dialog Events
  onEntered = () => {
    this.setState(
      { selectedStepIndex: this.props.currentStep || 0, nextButtonDisabled: false },
      () => {
        if (this.props.onEntered)
          this.props.onEntered(this);
        this.onEnterEvents[this.props.currentStep || 0]?.();
      }
    );
  }

  onClose = (isUnmounting) => {
    this.setState({ showCloseConfirmation: false });
    if (!isUnmounting) {
      if (this.props.onClose)
        this.props.onClose(this);
    }
  }

  showCloseConfirmationDialog = () => {
    this.setState({ showCloseConfirmation: true });
  }

  hideCloseConfirmationDialog = () => {
    this.setState({ showCloseConfirmation: false });
  }

  setNextButtonEnabled = (enabled) => {
    this.setState({ nextButtonDisabled: !enabled });
  }

  moveTab = (stepIndex) => {
    this.onExitEvents[this.state.selectedStepIndex]?.();
    this.props.onMoveStep?.(stepIndex);
    this.setState(
      { selectedStepIndex: stepIndex, nextButtonDisabled: false },
      this.onEnterEvents[stepIndex]
    );
  }

  getDefaultNextButtonAction = (lastStep, selectedStepIndex) => {
    return lastStep ?
      () => { this.props.defaultButtons.onFinishButtonClick(this.onClose, selectedStepIndex); } :
      () => {
        this.props.defaultButtons.onNextButtonClick(() => { this.moveTab(selectedStepIndex + 1) }, selectedStepIndex);
      };
  }

  addDefaultButtons = (selectedStepIndex, stepCount, left, right, defaultButtons, loading, closeConfirmationDialog) => {

    const cancel = {
      id: `${this.props.id}_cancelButton`,
      text: this.labels.get('cancel', 'cancel'),
      action: closeConfirmationDialog ? this.showCloseConfirmationDialog : this.onClose,
      className: defaultButtons.buttonsClassNames?.cancel,
      bsStyle: 'default',
    };

    if (left)
      left = [cancel, ...left];
    else left = [cancel];

    if (right)
      right = [...right];
    else right = [];

    if ( 0 !== selectedStepIndex)
      right.push({
      id: `${this.props.id}_backButton`,
      text: this.labels.get('back', 'back'),
      className: defaultButtons.buttonsClassNames?.back,
      bsStyle: 'default',
      action: () => { this.moveTab(selectedStepIndex - 1) },
      disabled: loading
    });

    let lastStep = selectedStepIndex === (stepCount - 1);
    right.push({
      id: `${this.props.id}_nextButton`,
      text: defaultButtons.getNextButtonText ? defaultButtons.getNextButtonText(selectedStepIndex) :
        lastStep ? this.labels.get('finish', 'finish') : this.labels.get('next', 'next'),
      action: this.getDefaultNextButtonAction(lastStep, selectedStepIndex),
      className: defaultButtons.buttonsClassNames?.next,
      bsStyle: 'primary',
      disabled: lastStep ?
        (this.state.nextButtonDisabled ||
        loading ||
        this.props.sData?.get('hasError') ||
        (this.props.sData?.get('showIgnoreValidationCheckbox') && !this.props.sData?.get('isIgnoreValidationWarnings'))) :
        this.state.nextButtonDisabled || loading
    });
    return { left, right };
  }

  wrapValidationForStep = (index) => (func) => {
    return (value, formValues, form, props) => {
      if (index === this.state.selectedStepIndex)
        return func(value, formValues, form, props);
      return undefined;
    }
  }

  renderSteps(steps, selectedStepIndex, id, loading, props) {
    return steps.map((stepItem, index) => {
      let { name, component, tabClassName, type } = stepItem;

      if (type && index !== selectedStepIndex)
        tabClassName = type + (tabClassName ? ` ${tabClassName}` : '');

      return (
        <Tab
          title={name}
          key={`${id}${index}`}
          eventKey={index}
          disabled={true}
          tabClassName={tabClassName}
        >
          {loading ? <Overlay.Loading/> : null}
          {component(
            { ...props,
              setNextButtonEnabled: this.setNextButtonEnabled,
              isCurrentStep: selectedStepIndex === index,
              wrapValidation: this.wrapValidationForStep(index), //wrap validation methods through this function to disable them when step is unselected
              registerOnEnter: (onEnter) => { this.onEnterEvents[index] = onEnter; }, //call this.props.registerOnEnter(callback) and callback will be called when step is selected
              registerOnExit: (onExit) => { this.onExitEvents[index] = onExit; } //call this.props.registerOnExit(callback) and callback will be called when another step is selected
            })
          }
        </Tab>
      );
    })
  }

  isLastStep = () => {
    return this.state.selectedStepIndex === (this.props.steps.length - 1);
}

  render() {
    let { titleText, steps, id, tabType, footerButtons, defaultButtons, show, currentStep, loading, closeConfirmationDialog, useMounting, sData, footerValidationCheckbox } = this.props;
    let { selectedStepIndex, showCloseConfirmation } = this.state;

    if (undefined !== currentStep) {
      selectedStepIndex = currentStep;
    }

    if (defaultButtons) {
      let { left, right } = footerButtons || {};
      footerButtons = this.addDefaultButtons(selectedStepIndex, steps.length, left, right, defaultButtons, loading, closeConfirmationDialog);
    }

    return (
      <React.Fragment>
        {closeConfirmationDialog ?
          <PL_DialogWrapper
            dialogComponent={Dialog}
            titleComponent={this.closeConfirmationHeader}
            id={`${this.props.id}_closeConfirmationDialog`}
            className={cn(closeConfirmationDialog.className, 'close-wizard-confirmation-dialog message-dialog')}
            show={showCloseConfirmation}
            onHide={this.hideCloseConfirmationDialog}
            bodyClassName={closeConfirmationDialog.bodyClassName}
            footerButtons={this.closeConfirmationButtons}>
              {closeConfirmationDialog.contents ??
              <div>
                {/*{this.labels.get('closeDialog.line1')}
                {this.labels.get('closeDialog.line2')}
                {this.labels.get('closeDialog.line3')}*/}
              </div>}
          </PL_DialogWrapper>
          : null
        }
        <Dialog
          titleText={titleText} show={show} id={id}
          onHide={closeConfirmationDialog ? this.showCloseConfirmationDialog : this.onClose}
          onEntered={this.onEntered}
          onExited={this.props.onExited}
          footerButtons={footerButtons}
          sData={sData}
          footerValidationCheckbox={footerValidationCheckbox}
          className={cn('wizard-dialog', this.props.className, {'wizard-last-step': this.isLastStep()})} bodyClassName={cn('wizard-dialog-body', this.props.bodyClassName)}
        >
          <Tabs onSelect={() => { }} activeKey={selectedStepIndex} id={`${id}_tabs`} tabType={tabType} mountOnEnter={useMounting} unmountOnExit={useMounting}>
            {this.renderSteps(steps, selectedStepIndex, id, loading, this.props)}
          </Tabs>
        </Dialog>
      </React.Fragment>
    );
  }
}

const step = exact({
  name: string.isRequired,
  component: func.isRequired, //(props) => node; renders the children using this.props; see renderSteps()
  type: oneOf(['completed', 'filled', undefined, null, '']),
  tabClassName: string, //custom tab link class name
})

const button = shape({
  id: string.isRequired,
  text: string.isRequired,
  action: func.isRequired, //() => void; called on button click
  loading: bool,
  style: object,
  bsStyle: string,
  disabled: bool,
  className: string
});

const closeConfirmationDialogItems = exact({
  titleText: string,
  iconClass: string, //title icon class
  className: string,
  bodyClassName: string,
  contents: node
});

const defaultButtonsItems = PropTypes.shape({
  onNextButtonClick: func.isRequired, // (nextTab: () => void, currentStep: int) => void; called when clicking next.

  onFinishButtonClick: func.isRequired, // (hideCallback: () => void, currentStep: int) => void;
  //hideCallback: a hide function to call once the finish method is done executing

  //if not provided, default text is grabbed from labels, "next" for all steps and "finish" on last step
  getNextButtonText: func, //(currentStep: int) => string; called on render to get the next button text

  buttonsClassNames: exact({
    cancel: string, //back button classname
    back: string, //back button classname
    next: string //next button classname
  })
});

PL_WizardDialog.propTypes = {
  show: bool.isRequired,
  titleText: string,
  id: string.isRequired,
  tabType: oneOf(['wizard-sidebar', 'wizard-horizontal']).isRequired,
  loading: bool,
  footerButtons: exact(
    {
      //see above definition for button
      left: arrayOf(button),
      right: arrayOf(button)
    }
  ),

  onClose: func, // (ref: PL_WizardDialog) => void; called when dialog wants to close
  onEntered: func, //(ref: PL_WizardDialog) => void; called on dialog enter
  /*
    steps={[
      { name: '', component: < />, type: 'filled', ...otherProps },
      { name: '', component: < /> }
    ]}
  */
  steps: arrayOf(step).isRequired,
  currentStep: number, //provide to override selected step or don't provide to let defaultButtons control the steps
  onMoveStep: func, //(stepIndex: number) => void; called on move

  //whether to add back, next, and cancel buttons, or to render footerButtons as provided
  //this prop is not required, but if it is provided, it has some required properties
  //look at defaultButtonsItems definition to see what object to provide
  defaultButtons: defaultButtonsItems,

  setRef: func, //(ref: PL_WizardDialog) => void; called on mount to give reference to parent

  //provide to use the close confirmation dialog.
  closeConfirmationDialog: closeConfirmationDialogItems,

  useMounting: bool, //whether to mount-unmount on step swap; this is unnecessary for validation when using this.props.wrapValidation inside steps
};

class PL_FormWizardDialog extends PL_WizardDialog {
  constructor(props) {

    super(props);

    this.getDefaultNextButtonAction = (lastStep, selectedStepIndex) => {
      return this.props.handleSubmit(
        (data, dispatch, props) => { //define callback function that passes data, dispatch, props into the handleSubmit
          // as well as the appropriate nextStep/hide callback
          this.props.defaultButtons.onSubmit(
            data, dispatch, props,
            lastStep ?
              this.onClose
              :
              () => { this.moveTab(selectedStepIndex + 1); },
            selectedStepIndex
          );
        }
      );
    };
  }

}

PL_FormWizardDialog.propTypes = {
  ...PL_WizardDialog.propTypes,
  defaultButtons: exact({
    //(data: {}, dispatch: (action: { }) => void, props: { }, nextTab: () => void, stepIndex: int) => void;
    // call nextStep() to advance to the next step (after validating current step)
    //data, dispatch, and props provided by redux-form
    onSubmit: func.isRequired,

    //if not provided, default text is grabbed from labels, "next" for all steps and "finish" on last step
    getNextButtonText: func, //(stepIndex: int) => string; text for next button depending on the step index,

    buttonsClassNames: exact({
      cancel: string, //back button classname
      back: string, //back button classname
      next: string //next button classname
    })
  })
}

export const FormWizardDialog = (formName, formConfig = { }) => {
  return reduxForm({
    form: formName,
    ...formConfig
  })(PL_FormWizardDialog)
};

export const ConnectedFormWizardDialog = (formName, formConfig = { }, mapStateToProps = null, mapDispatchToProps = null) => {
  return connect(
    function (state) {
      return {
        formData: state.form?.[formName],
        ...(mapStateToProps?.(state) || { })
      };
    },
    mapDispatchToProps
  )(FormWizardDialog(formName, formConfig));
};

export const ConnectedWizardDialog = (mapStateToProps, mapDispatchToProps = undefined) => {
  return connect(mapStateToProps, mapDispatchToProps)(PL_WizardDialog);
}

/*usage:

class Component extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.dialogComponent = FormWizardDialog('formName'); //create decorated component type
  }

  (...)

  render() {
    return (
      <this.dialogComponent                             //render decorated component
        {...dialogComponentProps}
      />
    );
  }
*/
//example in /#/TestPage (infrastructure/js/components/TestPage/testPage.js)

