import React from 'react';

import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {RfidSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {headerItemsLabels} from '../../../../Common/Header/header';
import MqttSensorsSettingsViewGrid from './MqttSensorsSettingsViewGrid/mqttSensorsSettingsViewGrid';
import Header from '../../../../Common/Header/header';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import CreateMqttSensorDialog from './Dialogs/CreateMqttSensorDialog/createMqttSensorDialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import PL_ComboBox from 'infrastructure/js/components/controls/Combobox/combobox';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';


require('./mqttSensorsSettingsView.scss');

class MqttSensorsSettingsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.rfidsettings.mqtt.sensors.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_MQTT_SETTINGS_SENSORS);
    this.props.actions.init(false);
  }

  componentDidUpdate(prevProps) {
    let brokersOptions = this.props.sData.get('brokersOptions');
    if (brokersOptions && brokersOptions !== prevProps.sData.get('brokersOptions')){
      if (brokersOptions.length > 0) {
        this.props.change('brokers', brokersOptions[0]);
        this.props.actions.onBrokerChanged(brokersOptions[0]);
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  handleRemoveSensors = () => {
    let selectedSensorsIds = this.props.sSelectedRowsData.map((item) => {
      return item.id;
    });

    this.props.actions.onRemoveSensorsClick(this.getMessageDialogBuilder(), selectedSensorsIds);
  }

  getMessageDialogBuilder = () => {
    return (fnCloseDialog, callback) => {
      let title = this.labels.get('removeSensors.confirmation.title');
      let message = this.labels.get('removeSensors.confirmation.message');
      let children =  [<div key='confirmMessageDataRow'>{message}</div> ];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove',text: this.dialogLabels.get('remove'), action: callback , bsStyle: 'primary'}
      ];
      let messageDialogDescriptor = {title, buttons, children,  className: '', type: 'warning'};
      return messageDialogDescriptor;
    };
  };

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;
    return {
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreateMqttSensorClick,
          disabled: this.props.sData.get('brokersOptions').length === 0
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditMqttSensorClick,
          actionData: selectedRowsData.get(0)
        },
        {
          id:'remove',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: this.handleRemoveSensors
        },
      ],
    };
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderHeader = () => {
    let headerItems = this.getHeaderItems();
    return (
      <div className='mqtt-sensors-view-header'>
        <InputSection label={this.labels.get('header.dropdown.title')} htmlFor="brokers" className=''>
          <PL_ComboBox
            name="brokers"
            id="brokers"
            options={this.props.sData.get('brokersOptions')}
            placeholder={this.labels.get('header.notSelected')}
            isSearchable={false}
            onChangeCallback={this.props.actions.onBrokerChanged}
            isDisabled={this.props.sData.get('brokersOptions').length <= 1}
          >
          </PL_ComboBox>
        </InputSection>

        <Header {...headerItems} />
      </div>
    )
  }

  renderGrid = () => {
    if(!this.props.sData.get('selectedBroker')) {
      return null;
    }

    return <MqttSensorsSettingsViewGrid actions={this.props.actions.mqttSensorsSettingsViewGridActions} />
  }

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();

    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        {this.renderHeader()}
        {this.renderGrid()}

        <PL_DialogWrapper dialogComponent={CreateMqttSensorDialog}
                          show={this.props.sCreateMqttSensorDialogData.get('show')}
                          actions={this.props.actions.createMqttSensorDialogActions}
                          sData={this.props.sCreateMqttSensorDialogData}
                          broker={this.props.sData.get('selectedBroker')}
                          reloadParentComponent={this.reloadPage}
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'mqttSensorsForm'
  }
)(MqttSensorsSettingsView);

MqttSensorsSettingsView.propTypes = {
  sGrid: PropTypes.object,
};
