import React from 'react';

import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { gridsNames } from '../../../../enums/gridsNames';
import { EntityPropertyTypes } from '../../../../enums/entityPropertyTypes';
import UnitHelper, { unitTypes } from '../../../../../../infrastructure/js/utils/uomHelper';
import SmartSelectionDetailGrid from './smartSelectionDetailGrid';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import MaterialHelper from '../../../../utils/materialHelper';
import AllocatedAmountCell from '../../../Common/CustomGridCells/AllocatedAmountCell/allocatedAmountCell';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./smartSelectionGrid.scss');

class SmartSelectionGrid extends React.PureComponent {
  filterConfig = [
    { fieldName: 'location', filterName: 'assetLocationName', getOptions: true },
    { fieldName: 'material.businessId', filterName: 'material', getOptions: true },
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  /**
   * Gets the required amount of material for a given material name.
   *
   * @param {string} materialName The name of the material.
   * @returns {number} The required amount of material.
   */
  getRequiredMaterialAmount(materialBusinessId) {
    // Get the required material amount prop from the props.
    const { requiredMaterialAmount } = this.props;

    // If the required material amount is not defined, return 0.
    if (!requiredMaterialAmount) {
      return 0;
    }

    // Find the required material amount item for the given material name.
    const requiredMaterialAmountItem = requiredMaterialAmount.find((item) => item.businessId === materialBusinessId);

    // If the required material amount item is not found, return 0.
    if (!requiredMaterialAmountItem) {
      return 0;
    }

    // Return the required amount of material.
    return requiredMaterialAmountItem.amount;
  }

  createColumnsConfig = () => {
    let isWeightSupported = PermissionManager.isWeightSupported();

    let config = [
      {
        fieldName: 'collapse',
        title: '',
        filterType: filterTypes.NONE,
        width: 20,
        columnOptions: {
          resizable: false,
          pinned: 'left',
          lockPosition: 'left',
          suppressColumnsToolPanel: true,
          cellRenderer: 'agGroupCellRenderer',
          sortable: false,
          cellStyle: { borderRight: 'none' },
          headerClass: 'group-invisible-header',
          valueGetter: (params) => {
            return '';
          },
        },
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'material',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            if (!params.data.material) {
              return { text: '', description: '' };
            }
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          },
        },
      },
      {
        fieldName: isWeightSupported ? 'weight' : 'lengthLeft',
        title: this.labels.get('columns.allocated.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: AllocatedAmountCell,
          valueGetter: (params) => {
            //Get the businessId from the params
            const { businessId } = params?.node?.data?.material;

            // Return calculated requiredAmount and selectedAmount.
            return {
              requiredAmount: this.getRequiredMaterialAmount(businessId),
              selectedAmount: isWeightSupported ? params.data.weight : params.data.lengthLeft,
            };
          },
        },
      },
    ];

    return config;
  };

  getRowHeight(params) {
    const isDetailRow = params.node.detail;

    if (!isDetailRow) {
      return 71;
    }

    // if this is a detail row return height based on number of rows in detail grid + offset
    const offset = 89; // accounts for detail container padding, header height, etc...
    // const detailPanelHeight = params.data.rolls.length * 190 + offset;
    const detailPanelHeight = params.data.assets.length * 190 + offset;
    return detailPanelHeight;
  }

  //
  /**
   * Calculates and preselects rolls until quantity reaches required material amount.
   *
   * @param {GridApi} gridApi The gridApi instance.
   * @returns {void}
   */
  calculatePreselectedRolls = (gridApi) => {
    // Get the isWeightSupported flag from the PermissionManager.
    let isWeightSupported = PermissionManager.isWeightSupported();

    // Get the required material amount prop from the props.
    const { requiredMaterialAmount } = this.props;

    // If the required material amount is not defined, return.
    if (!requiredMaterialAmount) {
      return;
    }

    // Initialize the selectedRows array.
    let selectedRows = [];

    // Iterate over the nodes in the grid.
    gridApi.forEachNode((node) => {
      // Get the material businessId and amount from the node.
      const { businessId } = node.data.material;
      const amount = this.getRequiredMaterialAmount(businessId);

      // Initialize the selectedAmount variable.
      let selectedAmount = 0;

      // Initialize the selectedChildNodes array.
      const selectedChildNodes = [];

      // Iterate over the assets in the node.
      node.data?.assets?.forEach((roll) => {
        // If the selected amount is less than the required amount, add the roll to the selectedChildNodes array.
        if (selectedAmount < amount) {
          const quantity = UnitHelper.serverValueToUserValue(
            isWeightSupported ? unitTypes.WEIGHT : unitTypes.LENGTH,
            isWeightSupported ? roll.weight : roll.lengthLeft,
            2
          );
          selectedAmount += quantity;
          selectedChildNodes.push({ ...roll, parentId: `detail_${node.id}` });
        }
      });

      // Add the selectedChildNodes array to the selectedRows array.
      selectedRows = [...selectedRows, ...selectedChildNodes];

      // If the weight is supported, set the weight of the node to the sum of the weights of the selected rolls.
      if (isWeightSupported) {
        const allocatedSum = selectedChildNodes.reduce((acc, curr) => acc + Number(curr?.weight), 0);
        node.setDataValue('weight', allocatedSum);
      } else {
        // Otherwise, set the lengthLeft of the node to the sum of the lengthLefts of the selected rolls.
        const allocatedSum = selectedChildNodes.reduce((acc, curr) => acc + Number(curr?.lengthLeft), 0);
        node.setDataValue('lengthLeft', allocatedSum);
      }
    });

    // Call the onSelectedRowsChanged action with the selectedRows array.
    this.props.actions.onSelectedRowsChanged(gridsNames.PREDEFINED_LIST_SMART_SELECTION, selectedRows);
  };

  render() {
    return (
      <div className="smart-selection-grid">
        <Grid
          gridName={gridsNames.PREDEFINED_LIST_SMART_SELECTION}
          columnsConfig={this.columnsConfig}
          actions={{ ...this.props.actions, onSelectedRowsChanged: null }}
          filterConfig={this.filterConfig}
          gridProps={{
            sideBar: false,
            checkboxSelection: false,
            getRowHeight: this.getRowHeight,
            onRowDataChanged: this.calculatePreselectedRolls,
            isMasterDetail: true,
            masterDetailRowComponent: SmartSelectionDetailGrid,
            detailCellRendererParams: {
              onSelectedRowsChanged: this.props.actions.onSelectedRowsChanged,
              gridName: gridsNames.PREDEFINED_LIST_SMART_SELECTION,
              getSelectedRows: () => this.props.selectedRows,
            },
            // detail row is created / destroyed each time it is expanded / collapsed,
            // set keepDetailRows to true in order to keep detail row state ( sort order, selected rows, etc...)
            // https://www.ag-grid.com/archive/24.1.0/javascript-grid-master-detail-detail-grids/#keeping-row-details
            keepDetailRows: true,
          }}
        />
      </div>
    );
  }
}

export default reduxForm({
  form: 'SmartSelectionGrid',
})(SmartSelectionGrid);
