import { fromJS } from 'immutable';
import overviewTabReducer from './overviewTabReducer';
import { actionTypes as lotPageActionTypes } from '../../actions/LotPage/lotPageActions';

const defaultState = {
  data: {
    lot: {
      id: null,
      objectType: null,
    },
  },
};

export default function (state = fromJS(defaultState), action) {
  state = state.set('overviewTab', overviewTabReducer(state.get('overviewTab'), action));

  switch (action.type) {
    case lotPageActionTypes.LOT_PAGE_FETCHDATA_FINISHED:
      state = state.setIn(['data', 'lot', 'id'], action.payload.id);
      state = state.setIn(['data', 'lot', 'objectType'], action.payload.objectType);
      return state;
    case lotPageActionTypes.LOT_PAGE_UNMOUNT:
      return fromJS(defaultState);
    default:
      return state;
  }
}
