import * as daHelper from '../../Dialogs/dialogActionsHelper.js';
import { dialogsNames } from '../../../enums/dialogsNames.js';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.REPORT_TASK_PROGRESS_DIALOG),
};

export const actions = {
  ...daHelper.getActions(dialogsNames.REPORT_TASK_PROGRESS_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};
