import { gridsNames } from '../../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import {fetchExternalNotificationLogs} from '../../../../services/Notifications/externalNotificationService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_NOTIFICATION_LOGS, _fetchDataAction);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return fetchExternalNotificationLogs(query);
  };
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;
