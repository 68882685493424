import { api as gridApi } from './sandboxGridActions';
import * as sandboxService from '../../../services/Sandbox/sandboxService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import {api as createSandboxDialogApi} from './Dialogs/createSandboxDialogActions';
import {api as loginApi} from '../../Login/loginActions';


// /////////////////////////////////////////
// // ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SANDBOX_PAGE_SET_LOADING:               'SANDBOX_PAGE_SET_LOADING',
};

// ////////////////////////////////////////////////////////////////
// // PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  setLoading: function (payload) {
    return {type: actionTypes.SANDBOX_PAGE_SET_LOADING, payload: payload};
  },
};


// /////////////////////////////////////////////////////
// // METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  };
};

jsxActions.openCreateSandboxDialog = function (data) {
  return function (dispatch, getState) {
    createSandboxDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.resetSandbox = function (data) {
  return function (dispatch, getState) {
    api.resetSandbox(dispatch, getState)(data);
  };
};
jsxActions.openSandbox = function (data) {
  return function (dispatch, getState) {
    api.openSandbox(dispatch, getState)(data);
  };
};
jsxActions.deleteSandbox = function (data) {
  return function (dispatch, getState) {
    api.deleteSandbox(dispatch, getState)(data);
  };
};

// /////////////////////////////////////////////////////////////////////////
// // API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.resetSandbox = function(dispatch, getState) {
  return function (data) {
    dispatch(actions.setLoading(true));

    return sandboxService.resetSandbox(data)
      .then((response) => {
        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Reset sandbox failed', response);
          return { success: false };
        }
        gridApi.reload(dispatch, getState)();
      })
  }
}

api.openSandbox = function(dispatch, getState) {
  return function (data) {
    let url = window.location.origin + window.location.pathname + '#/Login' + Network.getQueryString(data);

    loginApi.logout(false)(dispatch, getState)
      .then((response) => {
        console.log('openSandbox(): logout', response);
        let win = window.open(url, '_blank');
        win.focus();
      })
  }
}

api.deleteSandbox = function(dispatch, getState) {
  return function (data) {

    dispatch(actions.setLoading(true));

    let ids = data?.map((item) => {
      return item.id;
    })

    return sandboxService.deleteSandboxes({orgIds: ids})
      .then((response) => {

        dispatch(actions.setLoading(false));

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('Delete sandboxes failed', response);
          return { success: false };
        }
        gridApi.reload(dispatch, getState)();
      })
  }
}
