import cn from 'classnames';
import PropTypes from 'prop-types';

require('./checkboxCell.scss');
export default function CheckboxCell({ data, node }) {
  return (
    <label className={cn('checkbox', data?.isRowDisabled ? 'disabled' : '')}>
      {node.selected === undefined ? <i className="fa fa-minus" /> : node.selected ? <i className="fa fa-check" /> : null}
    </label>
  );
}

CheckboxCell.propTypes = {
  data: PropTypes.object,
  node: PropTypes.object,
};