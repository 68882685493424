import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {gridsNames} from '../../../../enums/gridsNames';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {isIncomingShipments, isOutgoingShipments, isShippingPreparation} from '../../../../utils/locationHelper';
import {enumTypes, getEnumLabel} from '../../../../utils/enumHelper';
import CheckMarkCell from '../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import IconWithTooltipCell from '../../../Common/CustomGridCells/IconWithTooltipCell/iconWithTooltipCell';
import {getStatusIcon} from '../../../../utils/statusHelper';

require('./shipmentsGrid.scss');

class ShipmentsGrid extends React.PureComponent {

    filterConfig = [
      {fieldName: 'shipmentBusinessId', filterName: 'shipmentBusinessId', getOptions: false},
      {fieldName: 'status', filterName: 'shipmentStatus', getOptions: true},
      {fieldName: 'createdDate.epochDateTime', filterName: 'shipmentCreatedDate', getOptions: false},
      {fieldName: 'updatedOn.epochDateTime', filterName: 'shipmentUpdatedOn', getOptions: false},
      {fieldName: 'createdByUser', filterName: 'shipmentCreatedByUser', getOptions: true},
      {fieldName: 'supplierName', filterName: 'supplierName', getOptions: true},
      {fieldName: 'customerName', filterName: 'customerName', getOptions: true},
      {fieldName: 'tempLogProcessed', filterName: 'tempLogProcessed', getOptions: false},
      {fieldName: 'acceptedByERP', filterName: 'acceptedByERP', getOptions: false},
    ];

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.grid.');
    this.columnsConfig = this.createColumnsConfig();

  }
  getFormattedDate = (data) => {
    return ((data) ?  DateTimeHelper.DateFormat(data * 1000) : '')
  }

  createColumnsConfig = () => {

    return [
      {
        fieldName: 'shipmentBusinessId',
        title: this.labels.get('columns.shipmentId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'shipmentBusinessId',
        fetchConfig: {
          entityType:  EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntitiesActions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId
          }
        }
      },
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'shipmentStatus',
        columnOptions: {
          cellComponent: IconWithTooltipCell,
          valueGetter: (params) => {
            return {
              tooltip: params.data.status ? (getEnumLabel(enumTypes.SHIPMENT_STATUS)(params.data.status) || params.data.status) : '',
              icon: getStatusIcon(params.data.status),
            };
          },
        }
      },
      {
        fieldName: 'createdDate.epochDateTime',
        title: this.labels.get('columns.createdDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'shipmentCreatedDate',
        valueFormatter: (params) => {
          return this.getFormattedDate(params.data?.createdDate?.epochDateTime)
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return ((params.data && params.data.createdDate) ?  DateTimeHelper.DateFormat(params.data.createdDate.epochDateTime * 1000) : '');
          }
        }
      },
      ...(this.getCustomColumns()),
      ]
  }

  getCustomColumns = () => {
    const {locationDetails} = this.props;

    if (isShippingPreparation(locationDetails) || isOutgoingShipments(locationDetails) ) {
      return [
        {
          fieldName: 'customerName',
          title: this.labels.get('columns.customerName.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'customerName',
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.customerName || ''
            }
          }
        },
      ];
    }
    else if (isIncomingShipments(locationDetails)) {
      return [
        {
          fieldName: 'supplierName',
          title: this.labels.get('columns.supplier.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'supplierName',
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.supplierName || ''
            }
          }
        },
        {
          fieldName: 'tempLogProcessed',
          title: this.labels.get('columns.tempLogProcessed.title'),
          filterType: filterTypes.NONE,
          filterName: 'tempLogProcessed',
          columnOptions: {
            cellComponent: CheckMarkCell,
            valueGetter: (params) => {
              return params.data.tempLogProcessed
            }
          }
        },
        {
          fieldName: 'acceptedByERP',
          title: this.labels.get('columns.acceptedByErp.title'),
          filterType: filterTypes.NONE,
          filterName: 'acceptedByERP',
          columnOptions: {
            cellComponent: CheckMarkCell,
            valueGetter: (params) => {
              return params.data.acceptedByERP
            }
          }
        },
      ];
    }

    return [
      {
        fieldName: 'updatedOn.epochDateTime',
        title: this.labels.get('columns.updatedDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'shipmentUpdatedOn',
        isTime: true,
        valueFormatter: (params) => {
          return this.getFormattedDate(params.data?.updatedOn?.epochDateTime)
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return ((params.data && params.data.updatedOn) ?  DateTimeHelper.DateTimeFormat(params.data.updatedOn.epochDateTime * 1000) : '');
          }
        }
      },
      {
        fieldName: 'createdByUser',
        title: this.labels.get('columns.createdBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'shipmentCreatedByUser',
        fetchConfig: {
          entityType: EntityPropertyTypes.SHIPMENT_STATUS,
          filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntitiesActions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.createdByUser
          }
        }
      },
      {
        fieldName: 'shipmentDocLink',
        title: this.labels.get('columns.docLink.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          sortable: false,
          valueGetter: (params) => {
            return params.data.fileName;
          }
        }
      },
    ];
  }

  getRowHeight = (params) => {

    let rowHeight = 64;
    //check if row is a master detail row (the return height is for the detail row)
    //then check if need more then a single row
    if (params.node && params.node.detail && params.data && params.data.extendedDetails) {
      let rowCount = Math.ceil(params.data.extendedDetails.length / 4);
      return rowCount > 0 ? rowHeight * rowCount : rowHeight;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="shipments-grid">
        <Grid gridName={gridsNames.LOCATION_SHIPMENTS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions.shipmentsGridActions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  gridNameSuffix: this.props.locationDetails?.locationType,
                }
              }
          >
        </Grid>
      </div>
    );
  }
}


export default reduxForm({
    form: 'ShipmentsGrid'
  }
)(ShipmentsGrid);

ShipmentsGrid.propTypes = {
  actions: PropTypes.object.isRequired,
  locationDetails: PropTypes.object.isRequired,
};





