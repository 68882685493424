import React from 'react';
import { reduxForm } from "redux-form";
import AddRemoveListExt from "../../../../Common/Controls/AddRemoveListExt/addRemoveListExt";
import Header from "../../../../Common/Header/header";
import PL_Checkbox from "infrastructure/js/components/controls/Checkbox/checkbox";
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import PL_TextField from "infrastructure/js/components/controls/TextField/textField";
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import { SettingsMenuItemsIds } from "../../../../../enums/navigationMenuItemsIds";
import { Prompt } from "react-router-dom";

require('./ipWhitelistView.scss');


class IPWhitelistView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper("mat.administration.settings.accessControl.ipWhitelistView.");
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
  }

  componentDidMount() {
    this.props.actions.init().then(this.props.initialize);
    this.props.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ACCESS_CONTROL_IP_WHITELIST);
  }

  renderRow = ({name, id, onChangeCallback }) => {
    return <div className="ip-list-item">
      <PL_TextField
        name={name}
        id={id}
        // onChangeCallback={(...args) =>{ onChangeCallback(...args); this.props.touch(name); }}
        onChangeCallback={(...args) =>{ onChangeCallback(...args); }}
        className="ip-list-item-input" validate={[Validation.required, Validation.ipOrRangeFormat]}
      />
    </div>;
  }

  onSubmit = (data) => {
    this.props.actions.submit(data).then((status) => {
      if (status.success)
        this.props.initialize(data); //disables save button
    });
  }

  render() {
    return <div className="ip-whitelist-view-container">
      { this.props.sData.get('loading') ? <Overlay.Loading /> : null }
      {/*<Header title={this.labels.get('title')} buttons={[{ className: 'no-icon', id: 'save', label: 'save', action: this.props.handleSubmit(this.onSubmit), disabled: this.props.pristine || this.props.invalid }]} />*/}
      <Header title={this.labels.get('title')} buttons={[{ className: 'no-icon', id: 'save', label: 'save', action: this.props.handleSubmit(this.onSubmit), disabled: this.props.pristine}]} />
      <div className="ip-whitelist-view-content">
        <PL_Checkbox className="enable-ip-whitelist-checkbox" id="Active" name={"enabled"} label={this.labels.get('checkboxLabel')} />
        <div className="ip-list-container">
          <div className="list-header">{this.labels.get('list.header')}</div>
        </div>

        <AddRemoveListExt itemRendererComponent={this.renderRow}
                          hideAddAtButton name="ips"
                          defaultItem=""
                          addButtonLabel={this.labels.get('list.add')} />

      </div>
      <Prompt message={this.navigationLabels.get('datanotsaved.text')} when={this.props.dirty} />
    </div>
  }
}

export default reduxForm({
  form: "ipWhitelistForm"
})(IPWhitelistView);
