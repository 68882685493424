import { api as navigatorApi } from '../../AppTopbar/navigatorActions.js';
import {navigationStates} from '../../../enums/navigationStates';
import { api as organizationGridApi} from './demoOrganizationsGridActions.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as orgsService from '../../../services/Administration/organizationsService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

/////////////////////////////////////////
export const actionTypes = {
  DEMO_ORGANIZATIONS_PAGE_FETCH_DATA_READY: 'DEMO_ORGANIZATIONS_PAGE_FETCH_DATA_READY',
};

/////////////////////////////////////////

const actions = {
  fetchPageDataReady: function(payload) {
    return {type: actionTypes.DEMO_ORGANIZATIONS_PAGE_FETCH_DATA_READY, payload: payload };
  },
};

/////////////////////////////////////////
export let jsxActions = {};

jsxActions.init = function() {
  return function(dispatch, getState) {
    api.getBaselineOrg(dispatch, getState)();
    api.setTopNavigator(dispatch, getState)();
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.getBaselineOrg(dispatch, getState)();
    organizationGridApi.reload(dispatch, getState)();
  }
};

/////////////////////////////////////////
export let api = {};

api.getBaselineOrg = function(dispatch, getState) {
  return function() {
    orgsService.getBaselineOrganization().then((response) => {

      if (!Network.isResponseValid(response)) {
        console.error('Get demo org baseline failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      let {name, zeroTime} = response?.data;
      let data = {
        baselineOrgName: name || '',
        zeroTime: zeroTime ? DateTimeHelper.DateTimeFormat(zeroTime.epochDateTime * 1000) : null,
      }
      dispatch(actions.fetchPageDataReady(data));
      return { success: true };
    });
  }
}

api.setTopNavigator = function(dispatch, getState) {
  return function() {

    let topNavigatorData = {
      name: createLabelHelper('mat.header.navigator.administration.').get('demoOrgsManagement'),
      type: navigationStates.DEMO_ORG_MANAGEMENT,
      id: 0,
      components: []
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);
  };
};

