import { navigationStates } from '../../enums/navigationStates.js';
import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as overviewTabApi } from './overviewTabActions';
import { api as attachmentGridApi} from '../AssetPage/attachmentTabGridActions';
import { getEnumValue, enumTypes } from '../../utils/enumHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOT_PAGE_FETCHDATA_FINISHED: 'LOT_PAGE_FETCHDATA_FINISHED',
  LOT_PAGE_CLEAR_DATA: 'LOT_PAGE_CLEAR_DATA',
  LOT_PAGE_UNMOUNT: 'LOT_PAGE_UNMOUNT',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataFinished: function (payload) {
    return { type: actionTypes.LOT_PAGE_FETCHDATA_FINISHED, payload: payload };
  },
  clearData: function (payload) {
    return { type: actionTypes.LOT_PAGE_CLEAR_DATA, payload: payload };
  },
  unmount: function () {
    return { type: actionTypes.LOT_PAGE_UNMOUNT };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (lotId) {
  return function (dispatch, getState) {
    api.fetchData(dispatch, getState)(lotId);
  };
};

jsxActions.unmount = function() {
  return function(dispatch, getState) {
    dispatch(actions.unmount());
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchData = function (dispatch, getState) {
  return function (lotId) {
    navigatorApi.setLoading(true)(dispatch, getState);

    const overviewTabPromise = overviewTabApi.init(dispatch, getState)(lotId);
    const attachmentGridPromise = attachmentGridApi.reload(dispatch, getState)();

    Promise.all([overviewTabPromise, attachmentGridPromise])
      .then((allResults) => {
        const lotData = allResults[0];
        lotData.objectType = getEnumValue(enumTypes.OBJECT_TYPE)('LOT')
        let topNavigatorData = _createNavigatorData(lotData);
        navigatorApi.setData(dispatch, getState)(topNavigatorData);

        dispatch(actions.fetchDataFinished(lotData));
        navigatorApi.setLoading(false)(dispatch, getState);
      })
      .catch((err) => {
        navigatorApi.setLoading(false)(dispatch, getState);
        console.error('Fetch shift types data failed.', err);
      });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
function _createNavigatorData(lotData) {
    return {
        id: lotData.id,
        name: lotData.name,
        type: navigationStates.LOT,
    }
}