import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../../enums/gridsNames';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import LabelWithTitleCell from '../../../../Common/CustomGridCells/LabelWithTitleCell/labelWithTitleCell';
import {enumTypes, getEnumLabel} from '../../../../../utils/enumHelper';
import CheckMarkCell from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';

import'./externalNotificationsGrid.scss';

class ExternalNotificationsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'type', filterName: 'externalNotificationTrigger', getOptions: true},
    {fieldName: 'createdByUser', filterName: 'externalNotificationCreatedBy', getOptions: true},
    {fieldName: 'active', filterName: 'active', getOptions: false},
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.userSettings.externalNotifications.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'type',
        title: this.labels.get('column.trigger.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'externalNotificationTrigger',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.NOTIFICATION_TRIGGER_TYPE)(params.data.type);
          }
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('column.active.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
        }
      },
      {
        fieldName: 'method',
        title: this.labels.get('column.method.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.methods.filter(item => item.label),
              componentType: LabelWithTitleCell
            };
          }
        }
      },
      {
        fieldName: 'createdByUser',
        title: this.labels.get('column.createdBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'externalNotificationCreatedBy',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
    ]
  }

  getRowHeight(params) {
    let {methods} = params.data;
    let methodsNum = methods.filter((item) => !!item.label)?.length;

    return methodsNum ? methodsNum * 56 + 30 : 64;
  }

  render() {
    return (
      <div className="external-notifications-settings-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_USER_SETTINGS_EXTERNAL_NOTIFICATION}
              columnsConfig={this.columnsConfig}
              filterConfig={this.filterConfig}
              actions={this.props.actions}
              gridProps={
                {
                  getRowHeight: this.getRowHeight
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'externalNotificationsGrid'
  }
)(ExternalNotificationsGrid);
