import React from 'react';
import PropTypes from 'prop-types';
import Button from 'infrastructure/js/components/controls/Button/button';
import {FieldArray} from 'redux-form';
import cn from 'classnames';

import './list.scss';


const LIST_ITEM_DEFAULT_HEIGHT = 46;

export default class List extends React.PureComponent {

  constructor(props) {
    super(props);

    this.listRef = React.createRef();
  }

  ignoreAll = (event) => {
    event.preventDefault();
  }

  renderRemoveButton = (index, fields) => {
    if (this.props.isViewOnly) {
      return null;
    }

    if (fields?.get(index)?.disableRemove) {
      return null;
    }
    return (
      <span className="pl pl-x-input-close remove-btn" onClick={() => { this.removeButtonClickHandler(index, fields); }} onKeyPress={this.ignoreAll}/>
    );
  }

  removeButtonClickHandler = (index, fields) => {
    fields.remove(index);

    this.props.onRemoveCallback?.(index);
  }

  renderAddButton = (fields) => {

    let {isViewOnly, addButtonDisabled, addButtonLabel, maxItemsToRender} = this.props;

    if (isViewOnly) {
      return null;
    }

    let disabled = addButtonDisabled || (maxItemsToRender && fields.length >= maxItemsToRender);

    return (
      <Button id="add-row-btn" disabled={disabled} className="add-row" onKeyPress={this.ignoreAll} onClick={() => { this.addButtonClickHandler(fields); }}>
        <span className="pl pl-icon-add"/> {addButtonLabel}
      </Button>
    );
  }

  scrollToLastItem = () => {
    let lastItem = this.listRef?.current?.querySelector?.('.list-item:last-child');
    lastItem?.scrollIntoView({behavior: 'smooth'});
  }

  addButtonClickHandler = (fields) => {
    let defItem = this.props.defaultItem || {};
    fields.push(defItem);

    this.props.onAddCallback?.();

    setTimeout(() => {this.scrollToLastItem()}, 0);
  }

  renderRows = ({fields , meta: {error}}) => {
    let {maxItemsToOverflow, itemRenderer, className} = this.props;

    let isOverflow = maxItemsToOverflow && fields.length > maxItemsToOverflow;

    return (
      <div className={cn('add-remove-list-container', className)}>
        <div className={cn('add-remove-list', {'list-overflow': isOverflow})}
             ref={this.listRef}
             style={isOverflow ? {maxHeight: maxItemsToOverflow * LIST_ITEM_DEFAULT_HEIGHT} : {}}
        >
          {fields.map((fieldName, index) => {
              let data = {
                id: fieldName,
                name: fieldName,
                index: index,
                fieldData: fields.get(index),
                allFieldsData: fields.getAll(),
              };
              return (
                <div className="list-item" key={'list-item' + index}>
                  {itemRenderer?.(data)}
                  {this.renderRemoveButton(index, fields)}
                </div>);
            }
          )}
        </div>
        {this.renderAddButton(fields)}
      </div>
    )
  }

  render() {
    return (
      <FieldArray name={this.props.name} updateKey={this.props.updateKey} component={this.renderRows}/>
    )
  }
}

List.defaultProps = {
  isViewOnly: false,
  preSelectedItems: [],
  onAddCallback: null,
  onRemoveCallback: null,
  addButtonDisabled: false,
  maxItemsToRender: null,
  maxItemsToOverflow: 0,
  addButtonLabel: 'ADD',
  updateKey: '',
};

List.propTypes = {
  name: PropTypes.string.isRequired,
  isViewOnly: PropTypes.bool,
  preSelectedItems: PropTypes.array,
  onAddCallback: PropTypes.func,
  onRemoveCallback: PropTypes.func,
  itemRenderer: PropTypes.func.isRequired,
  addButtonDisabled: PropTypes.bool,
  maxItemsToRender: PropTypes.number,
  maxItemsToOverflow: PropTypes.number,
  addButtonLabel: PropTypes.string,
  updateKey: PropTypes.string,
};

