import * as resinsService from '../../services/Resins/resinsService';
import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ACTIVE_RESINS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return resinsService.fetchResins(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj, index) => {
    if (obj.expirationDate) {
      obj.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }
    
    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }

    obj.m_weightLabel = UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, obj.weight, 2);

    return obj;
  });

  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
