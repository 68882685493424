// @ts-check

import {
  Mappedin,
  MapView,
  showVenue
} from "@mappedin/mappedin-js";
import { useEffect, useState } from "react";


/**
 * @typedef {import("@mappedin/mappedin-js").TShowVenueOptions} TShowVenueOptions
 * @typedef {import("@mappedin/mappedin-js").TGetVenueOptions} TGetVenueOptions
 * @typedef {import("@mappedin/mappedin-js").TMapViewOptions} TMapViewOptions
 * 
 * @typedef {import("./../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 */


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * @param {Mappedin | undefined} venue
 * @param {TMapViewOptions} mapViewOptions
 */
export default function useMapView(refContext, venue, mapViewOptions) {

  /** 
  * @type {[MapView | undefined, Function]}
  */
  const [mapView, setMapView] = useState();


  useEffect(() => {


    ////////////////////////////////////////////
    // Detect if changing to a new venue.
    // Clear current map artefacts from sData.
    //
    if (venue != null && mapView != null) {
      if (mapView.venue.venue.id !== venue.venue.id) {
        // console.log('---------- venue ===> resetMapData ');
        let actions = refContext.current.actions;
        actions.resetMapData();
      }
    }



    async function renderVenue() {

      // let el = elRef.current;
      let el = refContext.current.mapHtmlTargetRef.current;

      if (el == null || venue == null) {
        return;
      }

      if (mapView != null && mapView.venue.venue.id === venue.venue.id) {
        return;
      }

      if (mapView != null) {
        // console.log('---------- mapView.destroy() ===> ');
        mapView.destroy();
      }

      try {
        const _mapView = await showVenue(el, venue, mapViewOptions);
        // console.log('mapView created ===> ');
        setMapView(_mapView);
        refContext.current.refMapView.current = _mapView;
      } catch (e) {
        setMapView(undefined);
        refContext.current.refMapView.current = undefined;
      }


    }

    renderVenue();
  }, [venue]);
  // }, [venue, options, mapView]);
  // }, [el, venue, options, mapView]);

  return mapView;
}



