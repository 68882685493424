import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useRedux from 'infrastructure/js/hooks/useRedux';
import useLabels from 'infrastructure/js/hooks/useLabels';
import PropTypes from 'prop-types';
import { useSchedulerContext } from 'infrastructure/js/contexts/schedulerContext';
import SchedulerOperationsView from '../SchedulerOperationsView/schedulerOperationsView';
import SchedulerAnalyticMode from '../SchedulerAnalyticModePanel/SchedulerAnalyticModeContainer';
import { jsxActions as schedulerTasksPageActions } from '../../../../actions/SchedulerPage/schedulerTasksPageActions.js';
import { jsxActions as headerActions } from '../../../../actions/Header/headerActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as schedulerOperationsGridActions } from '../../../../actions/SchedulerPage/schedulerOperationsGridActions';
import { jsxActions as scheduleTaskDialogActions } from '../../../../actions/SchedulerPage/scheduleTaskDialogActions';
import { jsxActions as autoSchedulerDialogActions } from '../../../../actions/SchedulerPage/autoSchedulerDialogActions';
import { jsxActions as createTaskDialogActions } from '../../../../actions/SchedulerPage/createTaskDialogActions';
import { jsxActions as editTaskAttributesActions } from '../../../../actions/Dialogs/EditTaskAttributesDialog/editTaskAttributesDialogActions';
import { List } from 'immutable';
import { gridsNames } from '../../../../enums/gridsNames';
import ArrowRightIcon from 'infrastructure/assets/svg/arrow-right.svg';
import Overlay from 'infrastructure/js/components/Overlay/overlay';

import './schedulerTasksPage.scss';

const allActions = {
  schedulerTasksPageActions,
  header: headerActions,
  fetchEntitiesActions,
  schedulerOperationsGridActions,
  scheduleTaskDialogActions,
  autoSchedulerDialogActions,
  createTaskDialogActions,
  editTaskAttributesActions,
};

const getState = (state) => {
  let schedulerOperationsGrid = state.grid.get(gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS) || null;
  return {
    sHasRows: schedulerOperationsGrid?.get('rows')?.size > 0,
    sSelectedRowsData: schedulerOperationsGrid?.get('selectedRowsData') || List(),
    sData: state.predefinedList.get('scheduler'),
    sScheduleTaskDialogData: state.predefinedList.getIn(['scheduler', 'scheduleTaskDialog']),
    sAutoSchedulerDialogData: state.predefinedList.getIn(['scheduler', 'autoSchedulerDialog']),
    sCreateTaskDialogData: state.predefinedList.getIn(['scheduler', 'createTaskDialog']),
    sEditTaskAttributesData: state.dialogs.get('editTaskAttributesDialog'),
  };
};
export default function SchedulerTasksPage({ history, match }) {
  const { state, actions } = useRedux(allActions, getState);
  const { setSelectedRow } = useSchedulerContext();
  const labels = useLabels('mat.scheduler.tasks.page.');

  useEffect(() => {
    actions.schedulerTasksPageActions.init(true);

    return () => {
      actions.schedulerTasksPageActions.unmount();
    };
    // eslint-disable-next-line
  }, []);

  const highlightedTaskId = match.params.taskId;

  return (
    <div className="scheduler-tasks-page">
      <div className="header">
        <div className="title">{labels.get('title')}</div>
        <Link to="/PredefinedViews/scheduler/gantt" className="gantt-link">
          {labels.get('ganttLink')}
          <ArrowRightIcon className="arrow-right" />
        </Link>
      </div>
      <div className="scheduler-grid-wrapper">
        <SchedulerAnalyticMode />

        <SchedulerOperationsView
          actions={actions}
          sSelectedRowsData={state.sSelectedRowsData}
          sScheduleTaskDialogData={state.sScheduleTaskDialogData}
          sAutoSchedulerDialogData={state.sAutoSchedulerDialogData}
          sCreateTaskDialogData={state.sCreateTaskDialogData}
          sEditTaskAttributesData={state.sEditTaskAttributesData}
          sHasRows={state.sHasRows}
          sCounters={state.sData.get('counters')}
          setSelectedRow={setSelectedRow}
          sSchedulerData={state.sData.get('schedulerData')}
          asyncSchedulingData={state.sData.get('asyncScheduling')}
          loading={state.sData.get('loading')}
          history={history}
          highlightedTaskId={highlightedTaskId}
        />
      </div>
      {state.sData.get('loading') && <Overlay.Loading />}
    </div>
  );
}

SchedulerTasksPage.propTypes = {
  history: PropTypes.object.isRequired,
};
