import React, {useEffect, useMemo} from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useRedux from 'infrastructure/js/hooks/useRedux';
import Header, {headerItemsLabels} from '../../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import {MatSettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
// import {isAllItemsActive, isAllItemsDeactive} from '../../utils/matSettingsHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {gridsNames} from '../../../../../enums/gridsNames.js';
// import {ImportAcceptFileTypes, ImportTypes} from '../../../../../enums/importTypes';
import ToolGroupsViewGrid from './ToolGroupsViewGrid/toolGroupsViewGrid';
import CreateToolGroupDialog from './Dialogs/CreateToolGroupDialog/createToolGroupDialog';
// import ImportToolGroupsDialog from './Dialogs/ImportToolGroupsDialog/importToolGroupsDialog';
import {List} from 'immutable';
import {jsxActions as headerSettingsActions} from '../../../../../actions/Header/headerSettingsActions';
import {jsxActions as toolGroupsViewActions } from '../../../../../actions/Administration/MatSettingsPage/toolGroupsViewActions';
import {jsxActions as toolGroupsViewGridActions} from '../../../../../actions/Administration/MatSettingsPage/toolGroupsViewGridActions';
import {jsxActions as createToolGroupDialogActions} from '../../../../../actions/Administration/MatSettingsPage/Dialogs/createToolGroupDialogActions';
import {jsxActions as fetchEntitiesActions} from '../../../../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as matSettingsPageActions } from '../../../../../actions/Administration/MatSettingsPage/matSettingsPageActions.js';


const getState = (state) => {
  let grid = state.grid?.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_GROUPS) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
    sCreateToolGroupDialogData: state.administration.getIn(['matSettingsPage', 'createToolGroupDialog']),
    sImportToolGroupsDialogData: state.administration.getIn(['matSettingsPage', 'importToolGroupsDialog']),
  };
};

const allActions = {
  headerSettingsActions,
  toolGroupsViewActions,
  toolGroupsViewGridActions,
  createToolGroupDialogActions,
  fetchEntitiesActions,
  matSettingsPageActions: matSettingsPageActions,
};

export default function ToolGroupsView() {

  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.toolGroups.view.'), []);
  const { state, actions } = useRedux(allActions, getState);
  const {sHasRows = false, sSelectedRowsData, sCreateToolGroupDialogData } = state;

  useEffect(() => {
    actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_TOOL_GROUPS);
  }, []);


  const getHeaderItems = () => {

    return  {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.toolGroupsViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        ...(PermissionManager.hasCreateOrEditToolGroupsPermissions() ? [ //Note: show the buttons only for PS users
            {
              id:'create',
              label: headerItemsLabels.CREATE,
              icon: 'pl pl-icon-add',
              action: actions.headerSettingsActions.onCreateToolGroupClick,
            },
            {
              id:'edit',
              label: headerItemsLabels.EDIT,
              icon: 'pl pl-edit-icon',
              disabled: !(sHasRows && sSelectedRowsData.size === 1),
              action: actions.headerSettingsActions.onEditToolGroupClick,
              actionData: sSelectedRowsData.get(0)
            },
          ] : []),
        ],
      kebab: {
        menuItems: [
          // {//NOTE: these features currently are not supported
          //   id:'activate',
          //   label: headerItemsLabels.ACTIVATE,
          //   action: actions.headerSettingsActions.onActivateToolGroupsClick,
          //   disabled: (!sSelectedRowsData || sSelectedRowsData.size !== 1 || !isAllItemsDeactive(sSelectedRowsData)),
          //   actionData:  sSelectedRowsData,
          // },
          // {
          //   id:'deactvate',
          //   label: headerItemsLabels.DEACTIVATE,
          //   action: actions.headerSettingsActions.onDeactivateToolGroupsClick,
          //   disabled: (!sSelectedRowsData || sSelectedRowsData.size !== 1 || !isAllItemsActive(sSelectedRowsData)),
          //   actionData:  sSelectedRowsData,
          // },
          // {  //NOTE: this feature was moved to phase 2
          //   id:'importToolGroups',
          //   label: headerItemsLabels.IMPORT_TOOL_GROUPS,
          //   action: actions.headerSettingsActions.importItems,
          //   disabled: false,
          //   actionData: {
          //     importTypes: [ImportTypes.IMPORT_TOOL_GROUPS],   //TODO: L TG - replace it according to Server API
          //     acceptFileTypes: [ImportAcceptFileTypes.CSV],
          //     parentComponent: actions.toolGroupsViewActions.reload  //TODO: L TG - check it works
          //   }
          // },
          // {
          //   id:'exportToolGroups',
          //   label: headerItemsLabels.EXPORT_TOOL_GROUPS,
          //   action: actions.headerSettingsActions.exportItems,
          //   disabled: !sHasRows,
          //   actionData: {gridName: gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_GROUPS}
          // },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.headerSettingsActions.onRemoveToolGroupsClick,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            actionData:  sSelectedRowsData,
          },
        ]
      }
    };
  }

  const reloadPage = () => {
    actions.toolGroupsViewActions.reload();
  };

  return (
    <div className="administration-view">
      <div className="title">{labels.get('title')}</div>

      <Header {...getHeaderItems()}/>

      <ToolGroupsViewGrid actions={{...actions.toolGroupsViewGridActions, ...actions.fetchEntitiesActions}} />

      <PL_DialogWrapper dialogComponent={CreateToolGroupDialog}
                        show={sCreateToolGroupDialogData.get('show')}
                        actions={actions.createToolGroupDialogActions}
                        sData={sCreateToolGroupDialogData}
                        reloadParentComponent={reloadPage}
      />

      {/*<PL_DialogWrapper dialogComponent={ImportToolGroupsDialog}*/}
      {/*                  show={this.props.sImportToolTypesDialogData.get('show')}*/}
      {/*                  actions={this.props.actions.importToolTypesDialogActions}*/}
      {/*                  sData={this.props.sImportToolTypesDialogData}*/}
      {/*                  reloadParentComponent={this.reloadPage}*/}
      {/*/>*/}
    </div>
  )
}



