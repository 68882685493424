import React from 'react';
import PropTypes from 'prop-types';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import'./kitTypeLocationListItem.scss';

const MultiSelectField = withPortal(PL_MultiSelectField);

export default class KitTypeLocationListItem extends React.PureComponent {

  onChangeCallback = (value, oldValue) => {
    if (value && value.length > 20) {
      this.props.change( this.props.name + '.[locationName]', Parse.comboValuesOnly()(oldValue) );
    }
  };


  render() {
    let {options, name, index} = this.props;

    return (<div className="kit-type-location-list-item">
              <label id={name + '.[order]'}
                     name={ name + '.[order]'}
                     className="column column-1"
              >{(index+1)}</label>

                <MultiSelectField id={ name + '.[locationName]'}
                                     name={ name + '.[locationName]'}
                                     className="column column-2"
                                     options={options}
                                     parse={Parse.comboValuesOnly()}
                                     format={Format.findOptionsByValues(options)}
                                     closeMenuOnSelect={false}
                                     onChangeCallback={this.onChangeCallback}
                                     validate={Validation.required}
                />

           </div>);
  }
}

KitTypeLocationListItem.defaultProps = {
  options: [],
};

KitTypeLocationListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array,
};




