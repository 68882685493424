import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import {PL_DatePicker} from 'infrastructure/js/components/controls/DatePicker/datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../Button/button';
import {Field} from 'redux-form';

import './datepickerTouch.scss';


export default class PL_DatePickerTouchForm extends React.PureComponent {
  render() {
    return <Field {...this.props} component={PL_DatePickerTouch} />;
  }
}

export class PL_DatePickerTouch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.datePickerComponent = props.input ? DatePicker : PL_DatePicker;

    this.state = {
      showTimeSelectOnly: false,
    };
  }

  openCalendar = () => {
    this.setState({showTimeSelectOnly: false});
    this.pickerRef?.setOpen(true);
  }

  openTimes = () => {
    this.setState({showTimeSelectOnly: true});
    this.pickerRef?.setOpen(true);
  }

  setPickerRef = (ref) => {
    this.pickerRef = ref;
    if (this.pickerRef?.input) {
      this.pickerRef.input.readOnly = true;
    }
  }

  render() {
    let {showTimeSelect, ...others} = this.props;
    return (
      <div className={cn('pl-datepicker-touch', {'with-time-select': showTimeSelect})}>
        <this.datePickerComponent
          autoComplete="off"
          fixedHeight
          showTimeSelect={this.state.showTimeSelectOnly}
          showTimeSelectOnly={this.state.showTimeSelectOnly}
          {...others}
          showMonthDropdown={false}
          showYearDropdown={false}
          setInnerRef={this.setPickerRef}
          preventOpenOnFocus={true}
        />

        <div className='buttons-container'>
          <Button id="selectDay" className="border-btn only-icon" icon='pl pl-calendar'
                  onClick={this.openCalendar}>
          </Button>
          {showTimeSelect && <Button id="selectTime" className="border-btn only-icon" icon='pl pl-activity-shelf-life'
                  onClick={this.openTimes}>
          </Button>}
        </div>
      </div>
    );
  }
}

PL_DatePickerTouch.propTypes = {
  id: PropTypes.string.isRequired,
};
