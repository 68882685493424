// @ts-check


/**
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * 
 */


export default class ClickProcessor {

  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {import("@mappedin/mappedin-js").TMapClickEvent} payload
   */
  static processMapClick(refContext, payload) {
    // console.log('processMapClick ==> ', refContext, payload);
    // console.log('processMapClick ==> ', payload);

    // Having both refContext and payload as arguments here
    // allows us more flexibility in future.
    refContext.current.refClickPayload.current = payload;



    let mapMode = refContext.current.refSData.current.mapMode;
    if (mapMode === 'SEARCH') {
      processClick__MODE_SEARCH(refContext, payload);
      return;
    }

    processClick__MODE_NORMAL(refContext, payload);

  }

}




/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * @param {import("@mappedin/mappedin-js").TMapClickEvent} payload
 */
let processClick__MODE_NORMAL = (refContext, payload) => {

  // console.log('processClick__MODE_NORMAL ===> ', payload);


  // console.log("map click 3", payload);
  // console.log(`position => lat ${payload.position.latitude}, lng ${payload.position.longitude}`);

  let actions = refContext.current.actions;


  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return;
  }

  let refCurrentMapNode = refContext.current.refCurrentMapNode;

  // console.log('ZZZZZZZZZUPPPPPPPPPPAAAAAAAAAAA ===> ', mapView.Camera.rotation, mapView.Camera.tilt, mapView.Camera.zoom);

  let polygons = payload.polygons;

  // No polygons means we clicked on the map non-interactive area.
  if (polygons.length < 1) {

    // Hide location tooltip via message to the store.
    actions.hideLocationTooltip(null);

    // Reset the current map node.
    refCurrentMapNode.current = null;

    return;
  }


  let selectedPolygon = polygons[0];
  if (!selectedPolygon) {
    return;
  }


  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }

  let polyInfo = polyData.getPolygonInfoById(selectedPolygon.id);
  if (!polyInfo) {
    return;
  }
  
  // console.log('polyInfo ===> ', polyInfo, selectedPolygon);

  /////////////////////////////////////////// 
  // In a real map, the externalId will be 
  // equal to the Location name.
  //
  let externalId = polyInfo.polygonExternalId || 'NONE';
  // console.log('Map externalId ===> ', externalId);

  // Start async fetch
  // actions.fetchLocationDetails({ externalId: externalId });
  actions.fetchLocationDetails({ externalId: externalId });


  let entrance = polyInfo.entranceNode;
  if (!entrance) {
    return;
  }
  // console.log('mapClickHandler ===> A ', entrance);
  // Update the current map node.
  refCurrentMapNode.current = entrance;
  // console.log('entrance.id ===> ', entrance.id);


};


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * @param {import("@mappedin/mappedin-js").TMapClickEvent} payload
 */
let processClick__MODE_SEARCH = (refContext, payload) => {

  // console.log('processClick__MODE_SEARCH ===> ', payload);

  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return;
  }


  let markers = payload.markers;

  // No markers means we clicked on the map non-interactive area.
  if (markers.length < 1) {
    return;
  }

  let selectedMarker = markers[0];
  if (!selectedMarker) {
    return;
  }


  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }

  let polyInfo = polyData.getPolygonInfoBySearchMarkerId(selectedMarker.id);
  if (!polyInfo) {
    return;
  }

  // console.log('CLICKED MARKER ====> polyInfo ===> ', polyInfo, polyInfo.searchMarkerTargetLink);
  let targetLink = polyInfo.searchMarkerTargetLink;
  if (!targetLink) {
    // console.log('NO TARGET LINK ====> polyInfo ===> ', polyInfo, polyInfo.searchMarkerTargetLink);
    return;
  }

  //////////////////////
  // New Window.
  //
  window.open(targetLink, '_blank');

  // //////////////////////
  // // Same window.
  // //
  // try {
  //   refContext.current.refMapView.current?.destroy();
  // } catch (e) {
  //   console.log('processClick__MODE_SEARCH ===> map destroy ', e);
  // }
  // window.location.href = targetLink;
  
  // console.log('targetLink ===> ', targetLink);


};



