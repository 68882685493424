import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import CheckMarkCell         from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import MaterialsCell         from './CustomCells/MaterialsCell/materialsCell';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

class KitTypesViewGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'businessId', filterName: 'kitTypeBusinessId', getOptions: false},
    {fieldName: 'active', filterName: 'kitTypeStatus', getOptions: true},
    {fieldName: 'partType.businessId', filterName: 'kitTypePart', getOptions: false},
    {fieldName: 'project.businessId', filterName: 'kitTypeProject', getOptions: true},
  ];


  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.kittypes.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.kittype.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'kitTypeBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.KIT_TYPE_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
        }
      },
      {
        fieldName: 'kitTypeMaterials',
        title: this.labels.get('columns.expectedmaterials.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MaterialsCell,
        }
      },
      {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.parttype.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'kitTypePart',
        fetchConfig: {
          entityType: EntityPropertyTypes.PART_TYPE_BUSINESS_ID,
          // filter: BO_EntityFilters.ALL,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'project.businessId',
        title: this.labels.get('columns.project.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitTypeProject',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
    ]
  };

  getRowHeight(params) {
    let rowHeight = 64;
    // increase row height for rows with many materials types
    if (params.data.kitTypeMaterials.length > 0) {
      let subMaterialRowsNum = params.data.kitTypeMaterials.reduce((previous, current) => {
            let cur = (current && current.substituteMaterials) ?  current.substituteMaterials.length : 0;

            return previous + cur;
          }, 0);

      rowHeight = (params.data.kitTypeMaterials.length * 45 + subMaterialRowsNum * 25 ) + 40;
    }

    return rowHeight;
  }

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_KIT_TYPES}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'kitTypesViewGrid'
  }
)(KitTypesViewGrid);
