import Network from 'infrastructure/js/modules/network';

export function fetchOrganizations(query) {
  return Network.post('orgs',query );
}

export function fetchAllOrganizations() {
  return Network.get('orgs');
}

export function fetchCurrentOrgAndChildren() {
  return Network.get('org/children');
}

export function fetchDeletionRequests() {
  return Network.get('orgs/deletionRequests');
}

export function createOrganization(data) {
  return Network.post('orgs/create', data);
}

export function editOrganization(data, organizationId) {
  return Network.put(`orgs/${organizationId}`, data);
}

export function cloneOrganization(data, organizationId) {
  return Network.post(`orgs/${organizationId}/clone`, data);
}

export function requestOrganizationDeletion(data) {
  let newData = {comment: data.comment ? data.comment : ''};
  return Network.post(`orgs/requestDeletion/${data.id}`, newData);
}

export function rejectOrganizationDeletion(data) {
  return Network.post(`orgs/rejectDeletionRequest/${data.id}`);
}

export function acceptOrganizationDeletion(data) {
  return Network.post(`orgs/acceptDeletionRequest/${data.id}`);
}


export function fetchBillingForOrg(query) {
  return Network.post('billing/max-daily-activity', query);
}

export function fetchOrganizationsStatistics(query) {
  return Network.post('ops/getAllOrgsStatistics', query );
}

export function fetchDemoOrganizations(query) {
  return Network.post('demo-orgs/items',query );
}

export function createDemoOrganization(data) {
  return Network.polling.post('demo-orgs', data);
}

export function editDemoOrganization(data, organizationId) {
  return Network.put(`demo-orgs/${organizationId}`, data);
}

export function deleteDemoOrganization(orgIds) {
  return Network.delete('demo-orgs', {orgIds});
}

export function getBaselineOrganization() {
  return Network.get('demo-orgs/baseline');
}





