import React from 'react';
import PropTypes from 'prop-types';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Label from 'infrastructure/js/components/Label/label.js';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import cn from 'classnames';
import { getToolRequiredOptions } from '../../../../../utils/toolRequiredHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import './kitTypeOperationListItem.scss';


const ComboboxPortal = withPortal(Combobox);

const validateDurationTimeMin_hoursMinutes = (value, allValues) => {
  const minValue = {
    _hours: 0,
    _minutes: 1,
  };

  return Validation.timeField.min(minValue)(value, allValues);
};

const validateDurationTimeMin_minutesSeconds = (value, allValues) => {
  const minValue = {
    _minutes: 0,
    _seconds: 1,
  };

  return Validation.timeField.minMinutesSeconds(minValue)(value, allValues);
};



export default class KitTypeOperationListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createkittype.');

    this.toolRequiredOptions = getToolRequiredOptions();
    this.taskDurationInSeconds = PermissionManager.getOrgPreferences().taskDurationUnits === 'SECONDS';
  }

  onOperationChangeCallback = (value, oldValue) => {
    this.props.change(this.props.name + '.[disallowedStations]', []);
    this.props.change(this.props.name + '.[maxHumanCapacityEnabled]', value?.data?.manHours);
    if (!value?.data?.manHours) {
      this.props.change(this.props.name + '.[maxHumanCapacity]', null);
    }

    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
    if (this.props.onOperationChangeCallback) {
      this.props.onOperationChangeCallback(value, oldValue, this.props.index);
    }
  };

  onToolTypeChangeCallback = (value, oldValue) => {
    this.props.onToolRequiredChangeCallback(value, oldValue, this.props.index);
  };

  onCheckboxClick = (event) => {
    if (!event.target.checked) {
      this.props.change(this.props.name + '.[minimumSplitBlock]', '');
      this.props.change(this.props.name + '.[maximumTotalDuration]', '');
      this.props.onSplitEnabledChangeCallback?.(); //validate the expanded area errors
    }
  };

  onMaxDistanceCheckboxClick = (event) => {
    if (!event.target.checked) {
      this.props.change(this.props.name + '.[maxDistance]', '');
      this.props.onMaxDistanceChangeCallback?.(); //validate the expanded area errors
    }
  };

  validateMaximumTotalDuration = (value, allValues) => {
    let { index } = this.props;
    let duration = allValues.operations && allValues.operations[index] ? allValues.operations[index].duration : null;
    if (duration) {
      return Validation.timeField.min(duration)(value, allValues);
    }
    return undefined;
  };

  getComponentToRender = (props) => {
    return props && props.data ? <Label text={props.data.label} /> : null;
  };

  validateMaxHumanCapacity = (value, allValues) => {
    let { index } = this.props;
    if (!value) {
      return undefined;
    }

    const minHumanCapacity = allValues?.operations[index]?.minHumanCapacity;
    if (value < minHumanCapacity) {
      const { labels } = this.props;
      return labels?.get?.('operations.humanCapacityMax.validation.maxIsLowerThanMin');
    }

    return undefined;
  };

  maxLength100 = Validation.maxLength(100);

  disallowedStationsMultiValueRenderer = (props) => {
    if(props?.data?.label){
      return <Label text={props.data.label}/>
    }

    return null;
  };

  onArrowClick = (e) => {
    this.props.change(this.props.name + '.[isExpanded]', !this.props.itemRendererData?.isExpanded);
  }

  renderDurationField = () => {
    if (this.taskDurationInSeconds) {
      return (
        <TimeField
          id={this.props.name + '.[duration]'}
          name={this.props.name + '.[duration]'}
          minutesCount={true}
          isSeconds={true}
          isHours={false}
          maxMinutesLength={3}
          normalizeMinutes={Normalize.number(true, 0, 999)}
          normalizeSeconds={Normalize.number(true, 0, 59)}
          validate={[Validation.timeField.required, validateDurationTimeMin_minutesSeconds]}
        />
      );
    }

    return (
      <TimeField
        id={this.props.name + '.[duration]'}
        name={this.props.name + '.[duration]'}
        hoursCount={true}
        maxHoursLength={3}
        validate={[Validation.timeField.required, validateDurationTimeMin_hoursMinutes]}
      />
    );
  };

  render() {

    const { itemRendererData, name, index, allOperations, checkToolPreviousOptionDisabled, selectedOperation } = this.props;

    const disallowedStationsOptions =
      this.props?.options?.find((operation) => selectedOperation?.operationName === operation?.data?.id)?.data?.stations || [];

    return (
      <div className={cn('kit-type-operation-list-item')} >
        <div className='main-area'>
          <div className="column">
            <div id={name + '.[order]'}>{index + 1}</div>
          </div>

          <div className="column">
            <ComboboxPortal
              id={name + '.[operationName]'}
              name={name + '.[operationName]'}
              options={allOperations}
              parse={Parse.comboValueOnly()}
              format={Format.findOptionByValue(allOperations)}
              onChangeCallback={this.onOperationChangeCallback}
              className=""
              validate={Validation.required}
              singleValueRenderer={this.getComponentToRender}
              optionRenderer={this.getComponentToRender}
            />
          </div>

          <div className="column time-field-mandatory">
            {this.renderDurationField()}
          </div>

          {PermissionManager.getOrgPreferences().schedulerEnabled && (
            <>
              {PermissionManager.isToolsSchedulingEnabled() && (
                <div className="column tool-required">
                  <ComboboxPortal
                    id={name + '.[toolRequired]'}
                    name={name + '.[toolRequired]'}
                    options={this.toolRequiredOptions}
                    isOptionDisabled={(option) => checkToolPreviousOptionDisabled(index, option)}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.toolRequiredOptions)}
                    singleValueRenderer={this.getComponentToRender}
                    optionRenderer={this.getComponentToRender}
                    onChangeCallback={this.onToolTypeChangeCallback}
                  />
                </div>
              )}

              <div className={itemRendererData.isExpanded ? 'pl pl-arrow-down' : 'pl pl-arrow-right'} onClick={this.onArrowClick}/>
              {this.props.hasValidationError && <div className={'pl pl-error-icon error-icon'}/>}
            </>
            )}
        </div>

        {PermissionManager.getOrgPreferences().schedulerEnabled && (
          <div className={cn('expanded-area', {'expanded': itemRendererData.isExpanded})}>
            <div className="expanded-area-row">
              <div className="column">
                <Checkbox id={name + '.[durationPerPart]'} name={name + '.[durationPerPart]'}
                          label={this.labels.get('operations.durationPerPart')}/>
              </div>

              {PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled && (
                <>
                  <div className="column">
                    <label>{this.labels.get('operations.setupTime')}</label>
                    <TimeField
                      id={name + '.[setupTime]'}
                      name={name + '.[setupTime]'}
                      hoursCount={true}
                    />
                  </div>

                  <div className="column">
                    <label>{this.labels.get('operations.humanCapacityMin') + '*'}</label>
                    <TextField
                      id={name + '.[minHumanCapacity]'}
                      name={name + '.[minHumanCapacity]'}
                      className="short-text-field"
                      normalize={Normalize.number(true, 1, 999)}
                      validate={Validation.required}
                    />
                  </div>

                  <div className="column">
                    <label>{this.labels.get('operations.humanCapacityMax') + '*'}</label>
                    <TextField
                      id={name + '.[maxHumanCapacity]'}
                      name={name + '.[maxHumanCapacity]'}
                      className="short-text-field"
                      validate={itemRendererData.maxHumanCapacityEnabled ? [this.validateMaxHumanCapacity, Validation.required] : []}
                      normalize={Normalize.number(true, 1, 999)}
                      disabled={!itemRendererData.maxHumanCapacityEnabled}
                    />
                  </div>
                </>
              )}
              <div className="column">
                <label>{this.labels.get('operations.startAfterPercent') + '*'}</label>
                <TextField
                  id={name + '.[startAfterPercentage]'}
                  name={name + '.[startAfterPercentage]'}
                  className="short-text-field"
                  validate={Validation.required}
                  normalize={Normalize.number(true, 0, 100)}/>
              </div>
            </div>

            <div className="expanded-area-row">
              <div className="column">
                <Checkbox
                  id={name + '.[splitEnabled]'}
                  name={name + '.[splitEnabled]'}
                  onClick={this.onCheckboxClick}
                  label={this.labels.get('operations.splitEnabled')}
                />
              </div>

              <div className="column time-field-mandatory">
                <label>{this.labels.get('operations.minimumSplitBlock')}</label>
                <TimeField
                  id={name + '.[minimumSplitBlock]'}
                  name={name + '.[minimumSplitBlock]'}
                  hoursCount={true}
                  maxHoursLength={3}
                  disabled={!this.props.splitEnabled}
                  validate={this.props.splitEnabled ? Validation.timeField.required : undefined}
                />
              </div>

              <div className="column time-field-mandatory">
                <label>{this.labels.get('operations.maximumTotalDuration')}</label>
                <TimeField
                  id={name + '.[maximumTotalDuration]'}
                  name={name + '.[maximumTotalDuration]'}
                  hoursCount={true}
                  maxHoursLength={3}
                  disabled={!this.props.splitEnabled}
                  validate={this.props.splitEnabled ? [Validation.timeField.required, this.validateMaximumTotalDuration] : undefined}
                />
              </div>

              <div className="column">
                <label>{this.labels.get('operations.recipe')}</label>
                <TextField id={name + '.[recipe]'} name={name + '.[recipe]'} className="" validate={this.maxLength100}/>
              </div>
              <div className="column">
              </div>
            </div>

            <div className="expanded-area-row">
              <div className="column wide-2">
                <Checkbox id={name + '.maxDistanceEnabled]'}
                          name={name + '.[maxDistanceEnabled]'}
                          onClick={this.onMaxDistanceCheckboxClick}
                          label={this.labels.get('operations.maxDistanceFromPrevOperation')}/>
              </div>

              <div className="column time-field-mandatory">
                <label>{this.labels.get('operations.maxDistance')}</label>
                <TimeField
                  id={name + '.[maxDistance]'}
                  name={name + '.[maxDistance]'}
                  hoursCount={true}
                  maxHoursLength={3}
                  disabled={!this.props.maxDistanceEnabled}
                  validate={this.props.maxDistanceEnabled ? Validation.timeField.required : undefined}
                />
              </div>
            </div>

            <div className="expanded-area-row">
              <div className="column disallowed-stations">
                <label>{this.labels.get('operations.disallowedStations')}</label>
                <PL_MultiSelectField
                  id={name + '.[disallowedStations]'}
                  name={name + '.[disallowedStations]'}
                  parse={Parse.comboValuesOnly()}
                  format={Format.findOptionsByValues(disallowedStationsOptions)}
                  options={disallowedStationsOptions}
                  closeMenuOnSelect={false}
                  multiValueRenderer={this.disallowedStationsMultiValueRenderer}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

KitTypeOperationListItem.defaultProps = {
  options: [],
  splitEnabled: false,
  maxDistanceEnabled: false,
  toolTypes: [],
  allOperations: [],
  preSelectedItem: null,
  onToolRequiredChangeCallback: () => console.error('onToolRequiredChangeCallback is missing!'),
  checkToolPreviousOptionDisabled: () => {
    console.error('checkToolPreviousOptionDisabled is missing!');
    return false;
  },
  change: () => console.error('change is missing!'),
  onChangeCallback: null,
  onOperationChangeCallback: null,
  selectedOperation: null
};

KitTypeOperationListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  labels: PropTypes.object.isRequired,
  options: PropTypes.array,
  splitEnabled: PropTypes.bool,
  maxDistanceEnabled: PropTypes.bool,
  toolTypes: PropTypes.array,
  allOperations: PropTypes.array,
  onToolRequiredChangeCallback: PropTypes.func,
  checkToolPreviousOptionDisabled: PropTypes.func,
  preSelectedItem: PropTypes.object,
  change: PropTypes.func,
  onChangeCallback: PropTypes.func,
  onOperationChangeCallback: PropTypes.func,
  selectedOperation: PropTypes.object
};
