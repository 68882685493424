import useLabels from 'infrastructure/js/hooks/useLabels';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

import './dateRangeView.scss';


export default function DateRangeView({ startDate, endDate }) {
    const labels = useLabels('mat.scheduler.gantt.kpi.');

    const getDateRange = () => {
        return (
            <div className="date-range-view">
                <span>{labels.get('horizon.from') + ':'}</span>
                <div>{DateTimeHelper.DateFormat(startDate)}</div>
                <span>{labels.get('horizon.to') + ':'}</span>
                <div>{DateTimeHelper.DateFormat(endDate)}</div>
            </div>
        );
    }

    const getDate = () => {
        return (
            <div className="date-range-view single-date">
                <span>{DateTimeHelper.DateFormat(startDate)}</span>
            </div>
        )
    }

    return startDate ? ( endDate ? getDateRange() : getDate() ) : null;
}
