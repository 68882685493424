import React from 'react';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import MaterialHelper from '../../../utils/materialHelper';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./scannedAssetListItem.scss');

export default class ScannedAssetListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.entity.type.');
  }

  getUomSection = (asset) => {
    if ( asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL') && !PermissionManager.isWeightSupported() ||
         asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')  ) {

      return UnitHelper.serverValueToUserValueWithLabel(unitTypes.LENGTH, asset.lengthLeft || 0, 2) ;
    }
    return UnitHelper.serverValueToUserValueWithLabel(unitTypes.WEIGHT, asset.weight || 0, 2) ;
  }

  renderRawMaterialItem = (asset) => {
    const expirationDate = asset.expirationDate ?
      (DateTimeHelper.FormatDateObjectToDayMonth(asset.expirationDate.value) + ',') : '';
    const exposureTimeLeftCure = (asset.exposureTimeLeftCure && asset.exposureTimeLeftCure.value !== null) ?
      (DateTimeHelper.ConvertMinutesToHoursMinutes(asset.exposureTimeLeftCure.value) + ',') : '';
    const materialDetails = MaterialHelper.getMaterialFullLabel(asset.material.materialName, asset.material.businessId);

    return (
      <div className="scanned-asset-list-item">

        <div className="section asset-id">
          <label>{this.labels.get(asset.objectType?.toLowerCase())}</label>
          <label>{asset.businessId}</label>
        </div>
        <div className="section material-type">
          <label>{materialDetails}</label>
        </div>
        <div className="section asset-info">
          <label>{`${exposureTimeLeftCure} ${expirationDate} ${this.getUomSection(asset)}`}</label>
        </div>
        <div className="section asset-location">
          <label>{asset.location.name}</label>
        </div>
      </div>
    )
  }

  renderKitItem = (asset) => {
    let expirationDate = asset.expirationDate ?
      (DateTimeHelper.FormatDateObjectToDayMonth(asset.expirationDate.value)) : '';
    let exposureTimeLeftCure = (asset.exposureTimeLeftCure && asset.exposureTimeLeftCure.value !== null) ?
      (DateTimeHelper.ConvertMinutesToHoursMinutes(asset.exposureTimeLeftCure.value) + (expirationDate ? ',' : '') ) : '';

    return (
      <div className="scanned-asset-list-item">

        <div className="section asset-id">
          <label>{this.labels.get('kit')}</label>
          <label>{asset.businessId}</label>
        </div>
        <div className="section material-type">
          <label>{asset.kitType?.businessId || ''}</label>
        </div>
        <div className="section asset-info">
          <label>{`${exposureTimeLeftCure} ${expirationDate} `}</label>
        </div>
        <div className="section asset-location">
          <label>{asset.location.name}</label>
        </div>
      </div>
    )
  }

  renderToolItem = (asset) => {
    return (
      <div className="scanned-asset-list-item">

        <div className="section asset-id">
          <label>{this.labels.get('tool')}</label>
          <label>{asset.businessId}</label>
        </div>
        <div className="section material-type">
          <label>{asset.toolType?.businessId || ''}</label>
        </div>
        <div className="section asset-location">
          <label>{asset.location.name}</label>
        </div>
      </div>
    )
  }

  renderGroupItem = (asset) => {
    return (
      <div className="scanned-asset-list-item">

        <div className="section asset-id">
          <label>{this.labels.get('group')}</label>
          <label>{asset.businessId}</label>
        </div>
        <div className="section material-type">
          <label>{asset.groupType?.businessId || ''}</label>
        </div>
        <div className="section asset-location">
          <label>{asset.location.name}</label>
        </div>
      </div>
    )
  }

  render() {

    let {asset} = this.props;
    if (!asset) {
      return null;
    }

    if (asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL') ||
        asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL') ||
        asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('RESIN') ) {
      return this.renderRawMaterialItem(asset)
    }
    else if (asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('KIT')) {
      return this.renderKitItem(asset)
    }
    else if (asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')) {
      return this.renderToolItem(asset)
    }
    else if (asset.objectType === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')) {
      return this.renderGroupItem(asset)
    }
    return null;
  }
}


ScannedAssetListItem.propTypes = {
};

ScannedAssetListItem.defaultProps = {
};

