import React from 'react';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import Form from '../../../../Common/Form/form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';

class CommonAlertsForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.alerts.form.');

    this.state = {
      isEnableAlerts : false
    };
  }

  componentDidMount (){
    this.initFormValues();
    this.setStateStartValues();
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  setStateStartValues = () => {
    this.setState({isEnableAlerts: !this.props.sData.enabled});
  };

  isEtlAlert = (alertType) => {
    return (alertType === getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND') ||  alertType === getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'));
  };

  initFormValues = () => {

    let data = this.props.sData;

    let initialValue = {
      [this.withPrefix('EnableAlertsCheckbox')]: data.enabled,
      [this.withPrefix(  'DefaultValuesWarning')]: this.isEtlAlert(this.props.alertType) ? data.warningThreshold / 60 : data.warningThreshold, // convert to hours
      [this.withPrefix('DefaultValuesError')] : this.isEtlAlert(this.props.alertType) ? data.errorThreshold / 60 : data.errorThreshold, // convert to hours
    };

    this.props.initialize(initialValue);
  }

  onCheckboxClick = () => {
    this.setState({isEnableAlerts: !this.state.isEnableAlerts});
  }

  onSubmit = (data) => {
    let {sData, alertType} = this.props;
    let defaultValuesWarning = data[this.withPrefix('DefaultValuesWarning')];
    let defaultValuesError = data[this.withPrefix('DefaultValuesError')];

    let newData = {
      id: sData?.id,
      enabled: data[this.withPrefix('EnableAlertsCheckbox')],
      warningThreshold: this.isEtlAlert(alertType) ? defaultValuesWarning * 60 : defaultValuesWarning, //convert to minutes
      errorThreshold: this.isEtlAlert(alertType)? defaultValuesError * 60 : defaultValuesError, //convert to minutes
    };

    this.props.actions.update(newData, alertType, this.getMessageDialogBuilder()).then((response) => {
      if (response.success) {
        setTimeout(() => {this.initFormValues()}); //Initialize form values and reset pristine.
      }
    });

  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get('confirmation.success');
      let className = 'oneBackground';
      return {title, className};
    };
  };

  getFormButtons() {
    return (
      [
        {
          text: this.labels.get('buttons.save'),
          className: 'common-alerts-form-button-save border-btn btn',
          action: this.props.handleSubmit(this.onSubmit),
          disabled:this.props.pristine
        }
      ]
    );
  }

  withPrefix = (value) => {
    if (!value && value !== 0) {
      throw new Error('Invalid function parameter');
    }
    return this.props.entityType + value;
  }

  getDefaultUnitsByAlertType = (alertType) => {
    switch (alertType) {
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
        return 'units.days';
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
        return 'units.hours';
      case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
        return 'units.cycles';
      case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
        return 'units.daysUtl';
      default:
        console.error('Unknown alert type: ' + alertType);
    }
  }

  renderEnableAlertsSection() {
    let {entityType, alertType} = this.props;

    let entityLabel = this.labels.get(entityType);
    let alertLabel = this.labels.get(alertType);

    return (
      <div className="common-alerts-form-enable-alerts-section" >
        <label htmlFor={this.withPrefix('EnableAlertsCheckbox')} >
          <Checkbox name={this.withPrefix('EnableAlertsCheckbox')} id={this.withPrefix('EnableAlertsCheckbox')}
                    value={false} onClick={this.onCheckboxClick} />
          <span>{this.labels.get('checkbox.title' , '',{alertType: alertLabel, assetType: entityLabel})}</span>
        </label>
      </div>
    );
  }


  renderDefaultValuesSection() {
    let {alertType} = this.props;
    if (alertType === getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME') ||
        alertType === getEnumValue(enumTypes.ALERT_TYPE)('RESERVED')
    ) {
      return null;
    }

    let alertLabel = this.labels.get(alertType);
    let unitsLabelKey = this.getDefaultUnitsByAlertType(alertType);
    let alertMaxValue = alertType === getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME') ? 999 : 9999;

    return (
      <div className='common-alerts-form-default-values-container'>

        <div className="common-alerts-form-default-values-header">
          <label className='common-alerts-form-default-values-title'>{this.labels.get('defaultValues.section.header', '', {alertType: alertLabel})}</label>
        </div>

        <div className="common-alerts-form-default-values-section">
          <InputSection label={this.labels.get('defaultValues.title.warning')} htmlFor={this.withPrefix('DefaultValuesWarning')}
                        className="inline">
            <TextField id={this.withPrefix('DefaultValuesWarning')}
                       name={this.withPrefix('DefaultValuesWarning')}
                       disabled={this.state.isEnableAlerts}
                       validate={Validation.required}
                       normalize={Normalize.number(true, 0, alertMaxValue)} />
            <span className='additional-info'>{this.labels.get(unitsLabelKey)}</span>
          </InputSection>

          <InputSection label={this.labels.get('defaultValues.title.error')} htmlFor={this.withPrefix('DefaultValuesError')}
                        className="inline">
            <TextField id={this.withPrefix('DefaultValuesError')}
                       name={this.withPrefix('DefaultValuesError')}
                       disabled={this.state.isEnableAlerts}
                       validate={Validation.required}
                       normalize={Normalize.number(true, 0, alertMaxValue)} />
            <span className='additional-info'>{this.labels.get(unitsLabelKey)}</span>
          </InputSection>
        </div>

      </div>
    );
  }

  render() {
    return (
      <Form className="common-alerts-form" formButtons={this.getFormButtons()}>
        {this.renderEnableAlertsSection()}
        {this.renderDefaultValuesSection()}
      </Form>
    );
  }
}

export default reduxForm({
  //Note: redux form name is set dynamicly from the parent component
  }
)(CommonAlertsForm);


CommonAlertsForm.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  alertType: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};
