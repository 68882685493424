const RGBAToHexA = (r, g, b, a) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;
  if (a) {
    a = Math.round(a * 255).toString(16);
    if (a.length == 1) a = '0' + a;
  }

  return `#${r}${g}${b}${a ?? ''}`;
};

const RGBToHSL = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;

  return {
    hue: 60 * h < 0 ? 60 * h + 360 : 60 * h,
    saturation: 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0) * 100,
    luminance: (100 * (2 * l - s)) / 2,
  };
};

const getColorSpaces = (color) => {
  const temp = document.createElement('div');
  temp.style.color = color;
  document.body.appendChild(temp);
  const style = getComputedStyle(temp);
  // Get the RGB values from the computed style
  const rgb = style.color.match(/\d+/g).map(Number);

  const [red, green, blue, alpha = 1] = rgb;

  // Remove the temporary element from the document
  document.body.removeChild(temp);

  // Return the values as an object
  return {
    RGB: { red, green, blue, alpha },
    HSL: RGBToHSL(red, green, blue),
    hex: RGBAToHexA(red, green, blue),
    hexA: RGBAToHexA(red, green, blue, alpha),
  };
};

/**
 * @function getSystemColors
 * @description Gets the system colors for tasks types.
 * @returns {Object} An object with the following properties:
 *   - completed: The color for completed tasks.
 *   - maintenance: The color for maintenance tasks.
 *   - scheduled: The color for scheduled tasks.
 *   - started: The color for started tasks.
 *   - unscheduled: The color for unscheduled tasks.
 */
export const getSystemColors = () => {
  const { loggedInOrg } = JSON.parse(localStorage.getItem('loggedInUser'));
  const {
    orgPreferences: { completedTaskColor: completed, maintenanceTaskColor: maintenance, scheduledTaskColor: scheduled, startedTaskColor: started },
  } = loggedInOrg;

  return { completed, maintenance, scheduled, started, unscheduled: '#ff85c2' };
};

export const setSystemColors = (colorsToSet) => {
  const { completed, maintenance, scheduled, started } = getSystemColors();
  const { completed: newCompleted, maintenance: newMaintenance, scheduled: newScheduled, started: newStarted } = colorsToSet;
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
  const newColors = {
    completedTaskColor: newCompleted ?? completed,
    maintenanceTaskColor: newMaintenance ?? maintenance,
    scheduledTaskColor: newScheduled ?? scheduled,
    startedTaskColor: newStarted ?? started,
  };
  loggedInUser.loggedInOrg.orgPreferences = { ...loggedInUser.loggedInOrg.orgPreferences, ...newColors };
  localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
};

export default getColorSpaces;
