import React, {useMemo} from 'react';
import { gridsNames } from '../../../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import LabelWithTooltipCell from '../../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';

import './misplacedMaterialAlertsRulesGrid.scss';

function MisplacedMaterialGrid({ actions }) {

  const labels = useMemo(() => createLabelHelper('mat.administration.settings.misplacedMaterial.alertRules.grid.'), []);

  const destinationsValueGetter = (destinations) => {
    return { dataList: destinations.map(({ name }) => name), componentType: LabelWithTooltipCell };
  };

  const recipientsValueGetter = (recipients) => {
    return { dataList: recipients, componentType: LabelWithTooltipCell };
  };

  const columnsConfig = [
    {
      fieldName: 'origin.name',
      title: labels.get('column.origin.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'destinations',
      title: labels.get('column.destinations.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: MultiRowsCell,
        valueGetter: ({ data }) => {
          return destinationsValueGetter(data.destinations);
        },
      },
    },
    {
      fieldName: 'timer',
      title: labels.get('column.timer.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'recipients',
      title: labels.get('column.emailRecipients.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: MultiRowsCell,
        valueGetter: ({ data }) => {
          return recipientsValueGetter(data.recipients);
        },
      },
    },
  ];

  const getRowHeight = (params) => {
    let rowHeight = 64;
    if (params?.data?.recipients?.length > 1 || params?.data?.destinations?.length > 1) {
      rowHeight = Math.max(params.data.recipients.length, params?.data?.destinations?.length) * 32;
    }
    return rowHeight;
  };

  // must memoize gridProps otherwise the grid columns not auto stretch to fit width.
  const gridProps = useMemo(() => ({ getRowHeight }), [])

  return (
    <div className="misplaced-material-alerts-grid">
      <Grid
        gridName={gridsNames.ADMINISTRATION_ALERTS_MISPLACED_MATERIALS}
        columnsConfig={columnsConfig}
        actions={actions}
        gridProps={gridProps}
      />
    </div>
  );
};

MisplacedMaterialGrid.propTypes = {};

export default MisplacedMaterialGrid;
