import {useEffect, useMemo, useState} from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';
import { jsxActions as viewActions } from '../../../actions/Reports/managerViewPageActions.js';
import Search from 'infrastructure/js/components/Search/search';
import ManagerViewTree from './ManagerViewTree/managerViewTree';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import SplitPanel from 'infrastructure/js/components/SplitPanel/splitPanel';
import ManagerView from './ManagerView/managerView';

import './managerViewPage.scss';

const getState = (state) => {
  const viewState =  state.managerViewPage;

  return {
    treeData: viewState.get('treeData') ?? [],
    viewData: viewState.get('viewData') ?? [],
    loading: viewState.get('loading') ?? false,
  };
};

const allActions = {
  viewActions: viewActions,
};

const filterBy = (array, searchText) => {
  return searchText ? array?.reduce((acc, item) => {
    if( item.children?.length ) {
      const filtered = filterBy(item.children, searchText);

      if( filtered?.length ) {
        return [...acc, {...item, children: filtered}]
      }
    }

    const {children, ...itemWithoutChildren} = item;
    return item.name?.toLowerCase().includes(searchText.toLowerCase()) ? [...acc, itemWithoutChildren] : acc
  },[]) : array;
}



const ManagerViewPage = () => {
  const labels = useMemo(() => createLabelHelper('mat.reports.managerView.'), []);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [dummyKey, setDummyKey] = useState(new Date().getTime());

  const {state, actions} = useRedux(allActions, getState);
  const {treeData = null, viewData = null, loading} = state;

  useEffect(() => {
    actions.viewActions.init().then((response) => {
      if (response.success) {
        setCurrentItem(response?.data?.root || null);
      }
    })
    return () => actions.viewActions.unmount();
  }, []);

  useEffect(() => {
    setDummyKey(new Date().getTime());
  }, [searchTerm]);

  useEffect(() => {
    if (currentItem?.id) {
      actions.viewActions.reload(currentItem.id);
    }
  }, [currentItem]);

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const onSearchClear = () => {
    setSearchTerm('');
  };

  const onTreeItemSelect = (item) => {
    if (item?.isSection) {
      setCurrentItem(item || null);
    }
  }

  const getFirstPaneOverflowSize = () => {
    return 340;
  };

  const filteredTreeData = filterBy(treeData, searchTerm);

  return (
    <div className="administration-view">
      <div className="manager-view-page">

        <SplitPanel className='scheduler-panel' direction={'horizontal'} firstPaneMaxOverflowSize={getFirstPaneOverflowSize()} firstPaneInitialSize={340}>
          <div className="manager-view-page__tree">
            <div className="title">
              <span>{labels.get('title')}</span>
            </div>
            <Search value={searchTerm} onChange={onSearchChange} onClear={onSearchClear}/>
            <ManagerViewTree  key={dummyKey}
                              treeData={filteredTreeData}
                              onSelect={onTreeItemSelect}
                              selectedItem={currentItem}
                              defaultExpandAll={true}
            />
          </div>

          <div className="manager-view-page__details">
            <ManagerView {...viewData} title={currentItem?.businessId || ''}/>
          </div>
        </SplitPanel>
        {loading ? <Overlay.Loading /> : null}
      </div>

    </div>
  );
}

ManagerViewPage.propTypes = {
};

export default ManagerViewPage;
