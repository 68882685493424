import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Normalize from '../../../../../infrastructure/js/components/controls/controlsNormalizations';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import * as BatchJobHelper from '../../../utils/batchJobHelper';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import Label from 'infrastructure/js/components/Label/label';

import './printRfidTagsDialog.scss';

class PrintRfidTagsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.printRfidTagsDialog.');
    this.batchJobLabels = createLabelHelper('mat.batchJob.');

    this.entitiesTypes = props.sData?.get('entitiesTypes')?.toJS();
    this.isGeneric = props.sData.get('isGeneric');

    this.fetchConfig = {
      action: props.actions.fetchEntities,
      filter: props.sData.get('boEntityFilter')
    };
  }

  componentDidMount() {
    if (this.props.sData.get('printers') && this.props.sData.get('printers').length > 0) {
      this.props.change('printer', this.props.sData.get('printers')[0]);
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.labels.get('footer.print'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading'),
        },
      ],
    };
  }

  onSubmit = (data) => {
    let newData = {
      printerId: data.printer ? data.printer.value : null,
      tagsCount: data.numberOfTags,
      printerName: data.printer?.label || '',
    };

    if (this.isGeneric) {
      newData.isGeneric = true;
    } else {
      let row = data['entities-multi-select'];
      newData.assetIds = (row && row.assetsValue) ? row.assetsValue.map((asset) => {return asset.value}) : [];
    }

    this.props.actions.submitWithConfirmation(
      newData,
      this.getMessageDialogBuilder(),
      this.props.reloadParentComponent
    );
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = BatchJobHelper.createJobTitle(
        response.jobName,
        response.totalJobItems,
        this.batchJobLabels
      );
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title, false);
    };
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onStop = () => {
    this.props.actions.stop();
  };

  customValueRenderer  = (value) => {
    let numberOfTags = Array.isArray(value.data?.tags) ? value.data.tags.length : 0;
    return (
      <Label className='column custom-data' text={numberOfTags + ' tags attached'}  />);
  };

  renderAssetsSelection = () => {
    if (this.isGeneric) {
      return null;
    }
    let {sData} = this.props;
    const preselectedAssets = sData?.get('preselectedAssets');
    const disableOnPreselect = sData?.get('disableOnPreselect');

    return <div className="select-assets">
      {/*<InputSection label={this.labels.get('selectassets.text')} className="no-margin" />*/}
      <InputSection label={this.labels.get('assets')+'*'} className="no-margin" htmlFor="entities-multi-select"/>

      <EntitiesMultiSelect id="entities-multi-select"
                           name="entities-multi-select"
                           entitiesTypes={this.entitiesTypes}
                           validate={Validation.required}
                           preSelectedEntities={preselectedAssets}
                           fetchConfig={this.fetchConfig}
                           onValueChangeCallback={this.onSelectedAssetsChanged}
                           disableTypeChange={true}
                           disableOnPreselect={disableOnPreselect}
                           dropdownMenuClassName="entities-multi-select-edit-attributes-dialog"
                           customValueRenderer={this.customValueRenderer}
                           { ...this.props }/>

    </div>
  };

  renderNumberOfTags = () => {
    return (
      <InputSection
        label={this.labels.get(this.isGeneric ? 'numberOfGenericTags' : 'numberOfTags')}
        htmlFor="numberOfTags"
        className="inline left-side"
      >
        <TextField
          id="numberOfTags"
          name="numberOfTags"
          className="number-of-tags"
          placeholder={!this.asset ? this.labels.get('numberOfTags.placeholder') : ''}
          validate={Validation.required}
          normalize={Normalize.number(true, 1, 10)}
        />
      </InputSection>
    );
  };

  render() {
    return (
      <Dialog
        id="printRfidTagsDialog"
        className='print-rfid-tags-dialog'
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        {this.renderAssetsSelection()}

        {this.renderNumberOfTags()}

        <InputSection
          label={this.labels.get('printer')}
          htmlFor="printer"
          className="inline right-side"
        >
          <Dropdown
            id="printer-dropdown"
            name="printer"
            placeholder={this.labels.get('printer.placeholder')}
            validate={Validation.dropdown.required}
            options={this.props.sData.get('printers')}
            isDisabled={
              (this.props.sData.get('printers') && this.props.sData.get('printers').length === 1) ||
              this.props.sData.get('loading')
            }
          />
        </InputSection>
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'printRfidTagsDialog',
})(PrintRfidTagsDialog);

PrintRfidTagsDialog.defaultProps = {
  asset: null,
};

PrintRfidTagsDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  asset: PropTypes.object,
};
