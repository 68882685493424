import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';

import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';

import ActiveToolsContainer from '../../../containers/ActiveToolsPage/activeToolsContainer';
import ArchivedToolsContainer from '../../../containers/ArchivedToolsPage/archivedToolsContainer';

import { navigationStates } from '../../../enums/navigationStates';

import './toolsPage.scss';

const labels = createLabelHelper('mat.tools.page.');
const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

const ToolsPage = ({ match={} }) => {
  const updateNavBar = useNavBarUpdate();
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl('PredefinedViews/tools', Object.values(tabKeys), 'active');

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  useEffect(() => {
    updateNavBar(navigationStates.TOOLS, labels.get('title'));
  }, [updateNavBar, setTabKeyInURL]);

  return (
    <div className="tools-page">
      <FullScreenTabsSeparator />
      <Tabs id="tools-tabs" tabType="page" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActiveToolsContainer match={match} />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedToolsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

ToolsPage.propTypes = {
  match: PropTypes.object,
};

export default ToolsPage;
