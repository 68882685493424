import PropTypes from 'prop-types';

export default function DroppableWrapper({ children='',
                                           assignmentData={},
                                           action=()=>console.error('no action!'),
                                           className='',
                                           isHeader=false,
                                           isTemplate=false,
                                           checkIsDroppable=()=>true,
                                           onDragEnter=()=>{},
                                           onDragLeave=()=>{},
                                           setHighlighted=()=>{},
                                         }) {

  const drop = (e) => {
    e.preventDefault();
    setHighlighted(null);

    const { date } = assignmentData;


    const shiftData = e.dataTransfer.getData('data');

    if (shiftData) {
      const parsedShiftData = JSON.parse(shiftData);

      //stop change by drop if trying to assign a shift that starts in the past.
      if (!checkIsDroppable(date, parsedShiftData.startTime)) {
        return;
      }

      // if this is a move action of an already assigned shift dont do anything when dropping on a header
      if (parsedShiftData?.templateId && isHeader) {
        return;
      }

      const shiftAssignData = {
        shiftData: parsedShiftData,
        ...assignmentData,
        isTemplate,
      };

      action(shiftAssignData);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    onDragLeave?.(e);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    onDragEnter?.(e);
  };

  return (
    <div
      onDrop={drop}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      className={className}
    >
      {children}
    </div>
  );
}

DroppableWrapper.propTypes = {
  children: PropTypes.node,
  assignmentData: PropTypes.object,
  action: PropTypes.func,
  checkIsDroppable: PropTypes.func,
  className: PropTypes.string,
  isHeader: PropTypes.bool,
  isTemplate: PropTypes.bool,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  setHighlighted: PropTypes.func
};
