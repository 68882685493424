// @ts-check

import Network from '../../../../infrastructure/js/modules/network';


/**
 * @typedef {import('mat/js/types/ServerTypes/Models/LiveSearch').LiveSearchRequestModel} LiveSearchRequestModel
 * 
 * @typedef {import('mat/js/types/ServerTypes/Models/LiveSearch').LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * @typedef {import('mat/js/types/ServerTypes/Models/LiveSearch').R_LiveSearchResultResponseModel} R_LiveSearchResultResponseModel
 * 
 * 
 * 
 */



/**
 * @param {LiveSearchRequestModel} requestModel
 */
export function liveSearch(requestModel) {
  
  let url = `livesearch`;
  
  let requestData = {
    q: requestModel.q,
    max: requestModel.max,
    types: requestModel.types.join(','),
  };
  
  if (requestModel.locationId) {
    // @ts-ignore
    requestData.locationId = requestModel.locationId;
  }
  
  
  /** @type {Promise<R_LiveSearchResultResponseModel>} */
  // @ts-ignore
  let result = Network.get(url, requestData);
  
  return result;
  
}



