import { useCallback } from 'react';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import UnitHelper from 'infrastructure/js/utils/uomHelper';
import useLabels from 'infrastructure/js/hooks/useLabels';
import comparisonOperatorsEnum from '../../../../../../enums/comparisonOperators';

import './defrostingRangeListItem.scss';

const ComboboxPortal = withPortal(Combobox);

const { GT, GTE, LT, LTE, BETWEEN } = comparisonOperatorsEnum;

const operatorOptions = [
  { value: GT, label: '>' },
  { value: GTE, label: '>=' },
  { value: LT, label: '<' },
  { value: LTE, label: '<=' },
  { value: BETWEEN, label: '-' },
];

export default function DefrostingRangeListItem({ name, change, fieldData, index, unitType, ...rest }) {
  const labels = useLabels('mat.administration.matsettings.dialog.creatematerialtype.defrostingRangeListItem.');
  const validationLabels = useLabels('mat.validation.messages.');
  const majorUOMLabel = UnitHelper.getLabelForMajorUnitType(unitType);
  const rangeOperatorSelected = fieldData?.operator?.value === BETWEEN;

  const UOMLabel = UnitHelper.getLabelForUnitType(unitType);

  const minQuantityInputPlaceholder = `${labels.get('min')} ${majorUOMLabel} (${UOMLabel})`;
  const maxQuantityInputPlaceholder = `${rangeOperatorSelected ? labels.get('max') : ''} ${majorUOMLabel} (${UOMLabel})`;

  const onChangeCallback = (value, oldValue) => {
    if (value?.value === oldValue?.value) {
      return;
    }

    change(name + '.[operator]', value);
  };

  const validateRangeConflict = useCallback((value, allValues) => {
    const currentRow = allValues?.defrostingRanges[index];

    if (!currentRow?.operator?.value || value === undefined || value === null) {
      return;
    }

    const { operator, minQuantity, quantity } = currentRow;
    const minQuantityVal = parseInt(minQuantity);
    const quantityVal = parseInt(quantity);

    if(minQuantityVal > quantityVal) {
      return validationLabels.get('minGreaterThanMax');
    }

    let error;

    allValues.defrostingRanges.forEach((otherRow, otherIndex) => {
      if (index !== otherIndex) {
        const { operator: otherOperator, minQuantity: otherMinQuantity, quantity: otherQuantity } = otherRow;
        const otherMinQuantityVal = parseInt(otherMinQuantity);
        const otherQuantityVal = parseInt(otherQuantity);

        if (!otherOperator?.value || isNaN(otherQuantityVal) || (otherOperator?.value === BETWEEN && isNaN(otherMinQuantityVal))) {
          return; // Skip invalid rows
        }

        const conflict = checkConflict(
          { operator: operator?.value, minQuantity: minQuantityVal, quantity: quantityVal },
          { operator: otherOperator?.value, minQuantity: otherMinQuantityVal, quantity: otherQuantityVal }
        );

        if (conflict) {
          error = validationLabels.get('rangeConflict');
        }
      }
    });

    return error;
  }, [index, validationLabels]);

  return (
    <div className="defrosting-range-list-item">
      <div className="defrosting-range-list-item_quantities">
        {rangeOperatorSelected && (
          <TextField
            id="minQuantity"
            name={name + '.[minQuantity]'}
            placeholder={minQuantityInputPlaceholder}
            normalize={Normalize.number(true, 0, UnitHelper.getMaxValueForUnitType(unitType))}
            validate={[Validation.number.required, validateRangeConflict]}
          />
        )}

        <ComboboxPortal
          id={name + '.[operator]'}
          name={name + '.[operator]'}
          options={operatorOptions}
          validate={Validation.dropdown.required}
          onChangeCallback={onChangeCallback}
          //   blurInputOnSelect={true}
        />

        <TextField
          id="quantity"
          name={name + '.[quantity]'}
          placeholder={maxQuantityInputPlaceholder}
          normalize={Normalize.number(true, 0, UnitHelper.getMaxValueForUnitType(unitType))}
          validate={[Validation.number.required, validateRangeConflict]}
        />
      </div>

      <TimeField
        id="defrostingTime"
        name={name + '.[defrostingTime]'}
        hoursCount={true}
        maxHoursLength={3}
        validate={Validation.timeField.required}
      />
    </div>
  );
}

function checkConflict(range1, range2) {
  const { operator: op1, minQuantity: min1, quantity: max1 } = range1;
  const { operator: op2, minQuantity: min2, quantity: max2 } = range2;

  // Helper function to determine if two ranges overlap
  const hasOverlap = (start1, end1, start2, end2, inclusive1, inclusive2) => {
    if (inclusive1 && inclusive2) {
      return start1 <= end2 && start2 <= end1;
    } else {
      return start1 < end2 && start2 < end1;
    }
  };

  // Convert operators to range bounds
  const getBounds = (operator, value) => {
    switch (operator) {
      case BETWEEN:
        return { start: value.min, end: value.max, startInclusive: true, endInclusive: true };
      case GT:
        return { start: value.max, end: Infinity, startInclusive: false, endInclusive: false };
      case LT:
        return { start: -Infinity, end: value.max, startInclusive: false, endInclusive: false };
      case GTE:
        return { start: value.max, end: Infinity, startInclusive: true, endInclusive: false };
      case LTE:
        return { start: -Infinity, end: value.max, startInclusive: false, endInclusive: true };
    }
  };

  const bounds1 = getBounds(op1, { min: min1, max: max1 });
  const bounds2 = getBounds(op2, { min: min2, max: max2 });

  // Check for overlap between the ranges
  return hasOverlap(
    bounds1.start,
    bounds1.end,
    bounds2.start,
    bounds2.end,
    bounds1.startInclusive || bounds1.endInclusive,
    bounds2.startInclusive || bounds2.endInclusive
  );
}
