import { actionTypes as tasksViewActionTypes } from '../../../actions/LocationPage/TasksView/tasksViewActions';
import { Map } from 'immutable';

let defaultState = {
  viewDate: null,
  viewEndDate: null,
  history: {
    commandStack: [],
    redoStack: [],
  },
};

export default function (state = Map(defaultState), action) {

  switch (action.type) {
    case tasksViewActionTypes.LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE:
      return state.set('viewDate', action.payload?.viewDate)
                  .set('viewEndDate', action.payload?.viewEndDate);

    case tasksViewActionTypes.LOCATIONPAGE_TASKSVIEW_HISTORY_ADD_COMMAND: {
      const history = state.get('history');
      history.commandStack.push(action.payload);
      history.redoStack = [];
      return state.set('history', history);
    }

    case tasksViewActionTypes.LOCATIONPAGE_TASKSVIEW_HISTORY_UNDO: {
      const history = state.get('history');
      const { commandStack, redoStack } = history;
      redoStack.push(commandStack.pop());
      return state.set('history', history);
    }

    case tasksViewActionTypes.LOCATIONPAGE_TASKSVIEW_HISTORY_REDO: {
      const history = state.get('history');
      const { commandStack, redoStack } = history;
      commandStack.push(redoStack.pop());
      return state.set('history', history);
    }

    default:
      return state;
  }
}
