import { reduxForm } from 'redux-form';
import useLabels from 'infrastructure/js/hooks/useLabels';
import { gridsNames } from '../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import MasterDetailRow from 'infrastructure/js/components/Grid/MasterDetailRow/MasterDetailRow';
import ActivityTypeCell from '../../../Common/CustomGridCells/ActivityTypeCell/activityTypeCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import { navigationStates } from '../../../../enums/navigationStates';
import { filterAlignmentTypes } from 'infrastructure/js/enums/filterAlignmentTypes';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import ValueWithStateCell from '../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import { getAssetEtl } from '../../../../utils/assetHelper';

import './etlLogGrid.scss';

const filterConfig = [
  { fieldName: 'time.epochDateTime', filterName: 'fromDate' },
  { fieldName: 'activityType', filterName: 'activityType', getOptions: true },
  { fieldName: 'newLocation', filterName: 'newLocationName', getOptions: true },
  { fieldName: 'location', filterName: 'locationName', getOptions: true },
  { fieldName: 'exposureTimeLeft', filterName: 'etl', getOptions: true },
  { fieldName: 'username', filterName: 'reportedBy', getOptions: true },
];

function EtlLogGrid({ actions }) {
  const labels = useLabels('mat.grid.');
  const etlLabels = useLabels('enum.etl.type.');

  const columnsConfig = [
    {
      fieldName: 'time.epochDateTime',
      title: labels.get('columns.time.title'),
      filterType: filterTypes.NONE,
      valueFormatter: (params) => {
        return params?.data?.m_Time ?? '';
      },
      width: 165,
      columnOptions: {
        cellRenderer: 'agGroupCellRenderer',
        sort: 'desc',
        valueGetter: (params) => {
          return params.data?.time?.epochDateTime;
        },
      },
    },
    {
      fieldName: 'activityType',
      title: labels.get('columns.type.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'activityType',
      width: 220,
      columnOptions: {
        cellComponent: ActivityTypeCell,
        valueGetter: (params) => {
          return { activityType: params.data.activityType, activityTypeDisplayKey: params.data.activityTypeDisplayKey };
        },
      },
    },
    {
      fieldName: 'newLocation',
      title: labels.get('columns.newLocation.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'newLocationName',
      width: 220,
      columnOptions: {
        cellComponent: EntityIdCell,
        valueGetter: (params) => {
          return {
            id: params?.data?.newLocation?.id ?? null,
            type: navigationStates.LOCATION,
            label: params?.data?.newLocation?.name ?? null,
          };
        },
      },
    },
    {
      fieldName: 'location',
      title: labels.get('columns.location.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'locationName',
      width: 220,
      columnOptions: {
        cellComponent: EntityIdCell,
        valueGetter: (params) => {
          return {
            id: params.data.location ? params.data.location.id : null,
            type: navigationStates.LOCATION,
            label: params.data.location ? params.data.location.name : null,
          };
        },
      },
    },
    {
      fieldName: 'exposureTimeLeft',
      title: labels.get('columns.etl.title'),
      filterType: filterTypes.NONE,
      width: 140,
      columnOptions: {
        cellComponent: ValueWithStateCell,
        valueGetter: (params) => {
          return getAssetEtl(params.data, etlLabels);
        },
      },
    },
    {
      fieldName: 'username',
      title: labels.get('columns.reportedBy.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'reportedBy',
      filterAlignment: filterAlignmentTypes.RIGHT,
      width: 220,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: (params) => {
          return params.data.username;
        },
      },
    },
  ];

  return (
    <div className="etl-log-grid">
      <Grid
        gridName={gridsNames.ASSET_PAGE_ETL_LOG}
        columnsConfig={columnsConfig}
        actions={actions}
        filterConfig={filterConfig}
        gridProps={{
          getRowHeight: () => 64,
          checkboxSelection: false,
          masterDetailRowComponent: MasterDetailRow,
          isMasterDetail: true,
        }}
      ></Grid>
    </div>
  );
}

export default reduxForm({
  form: 'etlLogGrid',
})(EtlLogGrid);
