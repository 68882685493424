import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import classNames from 'classnames';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, {unitTypes} from 'infrastructure/js/utils/uomHelper';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import'./newKitTypeMaterialListItem.scss';

const ComboboxPortal = withPortal(Combobox);

export default class newKitTypeMaterialListItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.amountUnitMaxValue = PermissionManager.isWeightSupported()
      ? UnitHelper.getMaxValueForUnitType(unitTypes.WEIGHT)
      : UnitHelper.getMaxValueForUnitType(unitTypes.LENGTH)
  }

  onChangeCallback = (value, oldValue) => {
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  render() {
    let {options, name, item, cutLength} = this.props;

   return (<div  className={classNames('new-kittype-material-list-item', this.props.className)} >
      <ComboboxPortal id={ name + '.[requiredMaterial]'}
                name={ name + '.[requiredMaterial]'}
                validate={Validation.dropdown.required}
                options={options}
                onChangeCallback={this.onChangeCallback}
      />
      <TextField id={ name + '.[quantityExpectedPlies]'}
                 name={ name + '.[quantityExpectedPlies]'}
                 className="short-textfield expected-plies"
                 normalize={Normalize.number(true, 0, 99999)}
                 validate={Validation.required}
      />

      <TextField id={ name + '.[quantityKittedPlies]'}
                 name={ name + '.[quantityKittedPlies]'}
                 className="short-textfield kitted-plies"
                 normalize={Normalize.number(true, 0, 999)}
                 validate={item && item.isDefaultMaterial ? this.props.validate : undefined }
                 disabled={item && !item.isDefaultMaterial}
      />


     <div className="big-input-separator"></div>

     <TextField id={ name + '.[amount]'}
                name={ name + '.[amount]'}
                normalize={Normalize.number(false, 0, cutLength || this.amountUnitMaxValue)}
                disabled={item && !item.isDefaultMaterial}
                onChangeCallback={this.onChangeCallback}
     />


     {PermissionManager.hasScrapViewPermissions() &&
       <React.Fragment>
         <div className="big-input-separator"></div>

         <TextField id={ name + '.[scrap]'}
                    name={ name + '.[scrap]'}
                    normalize={Normalize.number(false, 0, this.amountUnitMaxValue)}
                    disabled={item && !item.isDefaultMaterial}
                    onChangeCallback={this.onChangeCallback}
         />
       </React.Fragment>
     }
      </div>);
  }
}

newKitTypeMaterialListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};




