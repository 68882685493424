import { api as gridApi } from './SchedulingLogsGridActions';

// /////////////////////////////////////////
// // ACTION TYPES - PUBLIC, FOR REDUCERS
// export const actionTypes = {
// };

// ////////////////////////////////////////////////////////////////
// // PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

// const actions = {
// };


// /////////////////////////////////////////////////////
// // METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};


jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  };
};

// /////////////////////////////////////////////////////////////////////////
// // API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

// export let api = {};
