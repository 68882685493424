
//The fetch filters for the entities (Rolls, Kits, Tools, Groups, Work Orders)

export const FetchEntitiesFilters = {
  ALL:                'ALL',
  ACTIVE:             'ACTIVE',
  ALERTED:            'ALERTED',
  ARCHIVED:           'ARCHIVED',
  COMPLETED:          'COMPLETED',
  IN_PROGRESS:        'IN_PROGRESS',
  NOT_IN_PROGRESS:    'NOT_IN_PROGRESS',
  IN_PASSPORT_STATUS: 'IN_PASSPORT_STATUS',
  NOT_IN_PASSPORT_STATUS: 'NOT_IN_PASSPORT_STATUS',
  IS_LOCKED:           'IS_LOCKED',

  ON_LOCATION:        'ON_LOCATION',
  ON_SUPPLIER_LOCATION: 'ON_SUPPLIER_LOCATION',
  EXCEPT_LOCATION:    'EXCEPT_LOCATION',
  HAS_ATTACHED_ASSET: 'HAS_ATTACHED_ASSET',
  NO_ATTACHED_ASSET:  'NO_ATTACHED_ASSET',
  CURED:              'CURED',
  NOT_CURED:          'NOT_CURED',
  NOT_CONTAINED:      'NOT_CONTAINED',
  NOT_RELATED:        'NOT_RELATED',
  NO_KIT_PART_TYPE:   'NO_KIT_PART_TYPE', //for WOs with no Kit / Part Type

  //Fetch filters for the Scheduler tasks
  ALL_TASKS:          'ALL_TASKS',
  SCHEDULED_TASKS:    'SCHEDULED_TASKS',
  STARTED_TASKS:      'STARTED_TASKS',
  COMPLETED_TASKS:    'COMPLETED_TASKS',
  UNSCHEDULED_TASKS:  'UNSCHEDULED_TASKS',
  TASKS_ON_LOCATION:  'TASKS_ON_LOCATION',
  USED_FOR_TASK:      'USED_FOR_TASK',

  //Fetch filters for the Assets' Materials
  ASSET_ACTIVE:                 'ASSET_ACTIVE',
  ASSET_ALERTED:                'ASSET_ALERTED',
  ASSET_ARCHIVED:               'ASSET_ARCHIVED',
  ASSET_TYPE_ROLL:              'ASSET_TYPE_ROLL',
  ASSET_IN_PASSPORT_STATUS:     'ASSET_IN_PASSPORT_STATUS',
  ASSET_NOT_IN_PASSPORT_STATUS: 'ASSET_NOT_IN_PASSPORT_STATUS',
  ASSET_TYPE_SPOOL:             'ASSET_TYPE_SPOOL',
  ASSET_TYPE_RESIN:             'ASSET_TYPE_RESIN',
  ASSET_LOCKED:                 'ASSET_LOCKED',
  ASSET_ON_LOCATION:            'ASSET_ON_LOCATION',

  SEARCH_BY_EMAIL:              'SEARCH_BY_EMAIL',
  SEARCH_BY_PHONE_NUMBER:       'SEARCH_BY_PHONE_NUMBER',
};

