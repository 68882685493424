import Button from '../controls/Button/button.js';

import './marketingLayoutDefault.scss';

export default function MarketingLayoutDefault() {
  const openMarketingSite = () => {
    window.open('https://www.plataine.com/book-a-demo-4/?utm_source=iot&utm_medium=banner&utm_campaign=cover_page', '_blank');
  };

  return (

    <div className="marketing-layout-default">
      <h1>Increase User Engagement with 3D Map View</h1>
      <p>
        Enable creation of customized views, visual asset tracking & management, while receiving all the relevant
        alerts
        based on the map
        location.
      </p>
      <div id="openMarketingSite" className="btn no-icon marketing-button" onClick={openMarketingSite}>
        <span>Book a Demo</span>
      </div>
    </div>
  );
}

