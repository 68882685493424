import React from 'react';

import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import StatusCell     from './CustomCells/StatusCell/statusCell.js';
import MaterialCell   from './CustomCells/MaterialCell/materialCell.js';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../enums/gridsNames';
import {reduxForm} from 'redux-form';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import {navigationStates} from '../../../../enums/navigationStates';

import './kittingGrid.scss';

class KittingGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'status', filterName: 'kitStatus', getOptions: true},
    {fieldName: 'kitType', filterName: 'kitType', getOptions: true},
    {fieldName: 'workOrder.businessId', filterName: 'kitWorkOrder', getOptions: true},
    {fieldName: 'project.businessId', filterName: 'kitProject', getOptions: true},
    {fieldName: 'businessId', filterName: 'businessIdSearch', getOptions: true},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'status',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitStatus',
        width: 175,
        columnOptions: {
          cellComponent: StatusCell,
          sortable: false,
        }
      },
      {
        fieldName: 'kitType',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'materials',
        title: this.labels.get('columns.materials.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MaterialCell,
          sortable: false
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.kitId.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'businessIdSearch',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.businessId) {
              return {
                id: params.data.id,
                label: params.data.businessId,
                type: navigationStates.KIT
              };
            }
            return {};
          }
        }
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitWorkOrder',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.workOrder) {
              return {
                id: params.data.workOrder.id,
                label: params.data.workOrder.businessId,
                type: navigationStates.WORKORDER
              };
            }
            return {};
          }
        }
      },
      {
        fieldName: 'project.businessId',
        title: this.labels.get('columns.project.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitProject',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
    ]
  }

  getRowHeight(params) {
    let rowHeight = 100;
    // increase row height for rows with many materials types

    if (params.data.materials.length > 2) {
      rowHeight = params.data.materials.length * 30 + 40;
    }

    return rowHeight;
  }

  getOverlayNoRowsTemplate = () => {
    return '';
  }

  render() {
    return (
      <div className="kitting-grid">
        <Grid gridName={gridsNames.LOCATION_KITTING}
              columnsConfig={this.columnsConfig}
              filterConfig={this.filterConfig}
              actions={this.props.actions}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  overlayNoRowsTemplate: this.getOverlayNoRowsTemplate
                }
              }
        >
        </Grid>

      </div>
    );
  }
}


export default reduxForm({
    form: 'KittingGrid'
  }
)(KittingGrid);
