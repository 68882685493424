import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import Button from '../controls/Button/button';
import classNames from 'classnames';

require('./topIndicationBar.scss');

export default class TopIndicationBar extends React.PureComponent{
  constructor(props){
    super(props);
    this.labels = createLabelHelper('network.topindicationbar.');
  }

  buildDataForOfflineMode = () => {
    return {type: 'offline', icon: 'fa-plug'};
  }

  buildDataForVersionMismatchMode = () => {

    return {
      type: 'versionMismatch',
      button : {
        className: 'btn btn-link',
        action: this.props.actions.loginActions.onDoLogout,
        label : 'upgrade'
      }
    };
  };

  buildDataForPreviewMode = () => {
    return {type: 'preview'};
  };

  getTopIndicationBarData = (topIndicatorBarData) => {
    if(topIndicatorBarData.get('offlineMode')) {
      return this.buildDataForOfflineMode();
    }
    if (topIndicatorBarData.get('versionMismatchMode')) {
      return this.buildDataForVersionMismatchMode();
    }
    if (topIndicatorBarData.get('previewMode')) {
      return this.buildDataForPreviewMode();
    }
    if (topIndicatorBarData.get('maintenanceMode')) {
      return {type: topIndicatorBarData.get('maintenanceMode')};
    }
    return null;
  }

  getDefaultText = (type) => {
    let text;
    switch (type) {
      case "offline":
        text = "NO CONNECTION";
        break;
      case "prepareForUpgradeMode":
        text = "In few minutes, the system will be shortly unavailable for upgrade. Please complete all operations.";
        break;
      case "upgradeInProgressMode":
        text = "The system is being upgraded. This may take few minutes.";
        break;
      case "upgradeCompletedMode":
        text = "System upgrade completed.";
        break;
      default:
        console.error("invalid value for getDefaultText: " + type)

    }
    return text;
  }

  renderText = (data) => {
    let value = this.labels.get(`${data.type}.text`, this.getDefaultText(data.type));

    if(data.type === 'upgradeCompletedMode') {
      value = (<label>{value + " "} <a className='upgrade-link' onClick={() => this.onUpgradeCompletedRefreshClicked()}>{this.labels.get(`reloadMessage`, "Press here to reload.")}</a></label>);
    }

    return value;
  };

  onUpgradeCompletedRefreshClicked(){
    this.props.actions.systemActions.onRefreshAppByMaintenance();
  }

  renderIcon = (data) => {
    if(data.icon) {
      return <i className={`fa ${data.icon}`}></i>;
    }
    return null;
  }

  renderButton = (data) => {

    let button = data.button;

    if (!button) {
      return null;
    }

    return <Button
          id={button.id}
          className={button.className}
          icon={button.icon}
          counter={button.counter}
          loading={button.loading}
          onClick={() => this.onClick(button)}
          disabled={button.disabled}>
          {button.label ? this.labels.get(`${data.type}.${button.label}`) : ''}
      </Button>;
  };

  onClick = (button) => {
    if (button.action) {
      button.action();
    }
  }

  render() {
    let data = this.getTopIndicationBarData(this.props.data);
    if(!data) {
      return null;
    }

    return (
      <div className={classNames('top-indication-bar-wrapper', {'on': !!data}, {'bar-only': (data && (data.type === 'preview' || data.type === 'prepareForUpgradeMode'))})}>
        <div className="top-indication-bar">
          {this.renderIcon(data)}
          <label htmlFor="">{this.renderText(data)}</label>
          {this.renderButton(data)}
        </div>
      </div>
    );
  }
}

TopIndicationBar.propTypes = {
  data: PropTypes.object
};
