import Network from 'infrastructure/js/modules/network';

export function fetchResins(query = {}) {
  return Network.post('resins/items?active=true', query);
}

export function fetchArchivedResins(query = {}) {
  return Network.post('resins/items?active=false', query);
}

export function updateResin(spoolId, data) {
  return Network.put(`resins/${spoolId}`, data);
}

export function generateResinId() {
  return Network.get('resins/generateId');
}

export function createResin(data, locationId) {
  return Network.post('resins', data);
}

export function getResinsDataByStationId(stationID, query = {}) {
  return Network.post(`stations/${stationID}/resins/items?active=true`, query);
}

export function getResinDetails(assetId){
  return Network.get(`resins/${assetId}`);
}

export function updateShelfLife(data, callback) {
  return Network.polling.post('resins/multiShelfLifeUpdate' , data, {callback});
}

export function editResinAttributes(data, callback){
  return Network.polling.put('resins/update' , data, callback);
}

