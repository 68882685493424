import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';

import {Field} from 'redux-form';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js'
import classNames from 'classnames';

require('./textField.scss');

import {getPropsWithReduxForm} from '../controlsUtils';

export default class PL_TextFieldForm extends React.PureComponent{
  render() {
    return (
      <Field {...this.props} component={PL_TextField}/>
    );
  }
};

export class PL_TextField extends React.PureComponent{

  getValue = () => {
    // Detect if props are in Redux-Forms format.
    return this.props.input ? this.props.input.value : this.props.value;
  };

  getClassName (){
    if(this.props.className){
      return ' ' + this.props.className;
    }
    return '';
  }

  getIconClassName() {
    if (this.props.iconClass) {
      return ' ' + this.props.iconClass;
    }
    return '';
  }
  onBlur=()=>{
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  onChange = (data) =>{
    // this.props.input.onChange(data);
    if (this.props.input) {
      this.props.input.onChange(data);
    }

    if(this.props.onChangeCallback){
      this.props.onChangeCallback(data.target.value);
    }
  };

  errorClass() {
    // When used as inner component, and not connected itself to any Form Field ever.
    if (this.props.passive) {
      return "";
    }

    let {touched, error} = this.props.meta;

    return (touched && error ? "invalid" : "");
  }

  render() {
    let {units, iconClass, passive, normalize, onChangeCallback, ...cleanProps} =  this.props;
    return (
      <span className={classNames("PL-textField", this.errorClass(), this.getClassName(), this.getIconClassName())} >
        <FormControl  autoComplete={this.props.autoComplete ? this.props.autoComplete : 'off'} type="text" {...getPropsWithReduxForm(cleanProps)} onChange={this.onChange} onBlur={this.onBlur} value={this.getValue()}/>
        <ValidationComponent className ="validation-component" {...this.props.meta} />
      </span>
    );
  }
}

PL_TextField.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClass: PropTypes.string
};
