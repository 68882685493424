import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import * as schedulerService from '../../../../services/Scheduler/schedulerService';

/////////////////////////////////////////

const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
      schedulerService.editSchedulingAutomationTrigger : schedulerService.createSchedulingAutomationTrigger;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes('CREATE_SCHEDULING_TRIGGER_DIALOG'),
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY

const actions = {
  ...daHelper.getActions('CREATE_SCHEDULING_TRIGGER_DIALOG'),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...daHelper.getJsxActions(api)
};

/////////////////////////////////////////////////////
