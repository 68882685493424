import './linkCell.scss';
import PropTypes from 'prop-types';

const LinkCell = ({ value }) => {

  if (!value) {
    return null;
  }

  const {label, onClickHandler} = value;

  return <div className='link-cell' onClick={onClickHandler}>{label}</div>;
};

LinkCell.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
  })
};

export default LinkCell;
