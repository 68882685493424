import PermissionManager from 'infrastructure/js/utils/permissionManager';

export function isMobileApp() {
  if (__DEV__) {
    return window.navigator.userAgent.indexOf('Mobi') >= 0 || window.screen.width < 400;
  }

  return window.navigator.userAgent.indexOf('Mobi') >= 0;
}

export function isTouchApp() {
  const touch = matchMedia('(hover: none), (pointer: coarse)').matches;
  return touch;
}

export function isSandboxOrg() {
  return PermissionManager.getLoggedInUser()?.loggedInOrg?.isSandbox ?? false;
}

export function isSandboxMode() {
  if (sessionStorage.getItem('sandboxMode') === 'true') {
    return true;
  }

  let queryString = window.location.href.split('?')[1];
  if (queryString) {
    let params = new URLSearchParams(queryString);
    let sandboxMode = params.get('sandboxMode');
    let orgName = params.get('orgName');

    if (sandboxMode === 'true' && orgName) {
      sessionStorage.setItem('sandboxMode', 'true');
      sessionStorage.setItem('orgName', orgName);
      return true;
    }
  }
  return false;
}


export function isPreviewMode() {
  if (sessionStorage.getItem('previewMode') === 'true') {
    return true;
  }

  let queryString = window.location.href.split('?')[1];
  if (queryString) {
    let params = new URLSearchParams(queryString);
    let previewMode = params.get('previewMode');
    let localeId = parseInt(params.get('localeId'));

    if (previewMode === 'true' && localeId) {
      sessionStorage.setItem('previewMode', 'true');
      sessionStorage.setItem('localeId', localeId);
      return true;
    }
  }
  return false;
}

export function getServerPath() {
  if (__DOCKER__) {
    // console.log(' ============= Using dynamic serverpath via client proxy =============');
    return location.origin + '/api/';
  }

  if (__DEV__) {
    //can be modified for local tests
    //EnvironmentVairables - doesnt exists in local app
    return 'http://localhost:3001/api/';
  }

  if (typeof LocalVariables === 'undefined') {
    return EnvironmentVairables.ServerApiPath;
  }

  if (!LocalVariables.serverPath) {
    console.log('getServerPath: serverPath is not defined');
    alert('serverPath is not defined on file');
    return;
  }

  return LocalVariables.serverPath;
}

export function getServerCdnPath() {
  if (typeof LocalVariables !== 'undefined') {
    if (!LocalVariables.serverCdnPath) {
      console.log('getServerCdnPath: serverCdnPath is not defined');
      alert('serverCdnPath is not defined on file');
    }
    return LocalVariables.serverCdnPath;
  }

  let serverPath = getServerPath();
  if (_isDev(serverPath)) {
    return 'http://apigw-cdn-dev.plataine.com:8080/';
  }
  //production
  if (_isProduction(serverPath)) {
    return 'https://apigw-cdn.plataine.com/';
  }
  //qa
  if (_isQa(serverPath)) {
    return 'http://apigw-cdn-qa.plataine.com:8080/';
  }
  //staging
  if (_isStaging(serverPath)) {
    return 'https://apigw-cdn-staging.plataine.com/';
  }
  console.error('getServerCdnPath: no matching env found');
  return null;
}

export function getApplicationStatusPath() {
  if (typeof LocalVariables !== 'undefined') {
    if (!LocalVariables.applicationStatusPath) {
      console.log('getApplicationStatusPath: serverCdnPath is not defined');
      alert('applicationStatusPath is not defined on file');
    }
    return LocalVariables.applicationStatusPath;
  }

  let serverPath = getServerPath();
  if (_isDev(serverPath)) {
    return 'https://wraq4vpq28.execute-api.us-east-1.amazonaws.com/default/getJenkinsStatus';
  }
  //production
  if (_isProduction(serverPath)) {
    return 'https://9nrrkl3dr0.execute-api.us-east-1.amazonaws.com/default/getJenkinsJobStatus';
  }
  //qa
  if (_isQa(serverPath)) {
    return 'https://fvjysphwe2.execute-api.us-east-1.amazonaws.com/default/getQABuildStatus';
  }
  //staging
  if (_isStaging(serverPath)) {
    return 'https://6dphqmconb.execute-api.us-east-1.amazonaws.com/default/getStagingBuildStatus';
  }
  console.error('getApplicationStatusPath: no matching env found');
  return null;
}

function _isDev(serverPath) {
  return __DEV__ || serverPath.includes('apigw-dev');
}
function _isQa(serverPath) {
  return serverPath.includes('apigw-qa');
}
function _isStaging(serverPath) {
  return serverPath.includes('apigw-staging');
}
function _isProduction(serverPath) {
  return serverPath.includes('apigw.plataine');
}
