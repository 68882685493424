import Network from 'infrastructure/js/modules/network';
import {ImportTypes} from '../../enums/importTypes';


export function fetchAssetsGridSettings(assetType) {
  return Network.get(`import/${normalizeAssetType(assetType)}/barcode/parse/settings`);
}

export function parseAssetBarcode(data, assetType) {
  return Network.post(`import/${normalizeAssetType(assetType)}/barcode/parse`, typeof(data) === 'string' ? { scanData: data } : data);
}

export function validateScannedAssets(data, assetType) {
  return Network.post(`import/${normalizeAssetType(assetType)}/barcode/validate`,  { requests: data} );
}

export function importValidAssets(locationId, data, assetType) {
  return Network.polling.post(`import/${normalizeAssetType(assetType)}/barcode/import`, { locationId, requests: data });
}

function normalizeAssetType (assetType) {
  switch (assetType) {
    case ImportTypes.IMPORT_ROLLS:
      return 'rolls';
    case ImportTypes.IMPORT_SPOOLS:
      return 'spools';
    case ImportTypes.IMPORT_RESINS:
      return 'resins';
    default:
      console.error('barcodeImportService(): unknown import type', assetType);
      return '';
  }
}
