import { useMemo } from 'react';

const { useDispatch, useSelector, useStore, shallowEqual } = require('react-redux');
const { bindActionCreators } = require('redux');

const defSelector = (state) => state;

/**
 * This function wraps action creators in a function that calls the dispatch function.
 * You can provide your action creators as a single function, an array of functions, or and object containing functions.
 * The function will drill down recursively until all action creators are warped in dispatch.
 *
 * @param {object|array|function} actions The actions to be wrapped.
 * @param {function} dispatch The dispatch function.
 * @returns {object} The wrapped actions.
 */
export const wrapActionsInDispatch = (actions, dispatch) => {
  // Check if the item is not function, array or object.
  if (!actions && typeof actions !== 'object') {
    console.error('useRedux: invalid actions!', actions);
    return null;
  }

  // If the actions are a single function, wrap it in an array, and call wrapActionsInDispatch recursively.
  if (typeof actions === 'function') {
    return wrapActionsInDispatch([actions], dispatch);
  }

  // If the actions are an array, convert an object with the key+index as keys and call wrapActionsInDispatch recursively.
  if (Array.isArray(actions)) {
    const objectOfActions = actions.reduce((obj, action, index) => {
      obj['key' + index] = action;
      return obj;
    }, {});
    return wrapActionsInDispatch(objectOfActions, dispatch);
  }

  // If actions is object and all of the values are functions, return the result of calling bindActionCreators.
  if (Object.values(actions).every((action) => typeof action === 'function')) {
    return bindActionCreators(actions, dispatch);
  }

  // If actions is object and but not all of the values are functions, iterate over all the the values and call wrapActionsInDispatch
  // recursively for each object/array/function.
  const newObject = {};
  Object.entries(actions).forEach(([key, actionsGroup]) => {
    if (typeof actionsGroup === 'object') {
      newObject[key] = wrapActionsInDispatch(actionsGroup, dispatch);
    }
  });
  return newObject;
};

/**
Custom hook for accessing Redux store, getting the state with a selector function and wrapping actions in dispatch.
@param {Function} actions - An object contain action creator functions.
@param {Function} selector - A selector function that get the state as parameter and returns the required part of the state.
if no selector function is provided the entire state will return.
@returns {Object} An object containing state (filtered by the selector function), actions (wrapped in dispatch), the dispatch function and a ref to the store .
*/
const useRedux = (actionsObj = {}, selector = defSelector) => {
  const store = useStore();
  const dispatch = useDispatch();
  const state = useSelector(selector, shallowEqual);
  const actions = useMemo(() => wrapActionsInDispatch(actionsObj, dispatch), [actionsObj, dispatch]);

  return { state, actions, dispatch, store };
};

export default useRedux;
