import React, {Component} from 'react';


import {buildWizardContainer}   from './wizardContainer';




export default class PL_Wizard extends React.PureComponent {

  /* ---------------------------------------------------------------- */
  // Dynamically connects the base wizard component to the store.

  constructor(props) {
    super(props);
    this.wizardContainer = buildWizardContainer(this.props.wizardName);
  }

  render() {

    let WizardContainer = buildWizardContainer(this.props.wizardName);

    return (
      <this.wizardContainer steps={this.steps} {...this.props} />
    );
  }

}


export class PL_Wizard_Prerenderer extends React.PureComponent {

  /* ---------------------------------------------------------------- */
  // Gives us a chance to control rendering of PL_WizardRenderer based
  // on Store state.
  //
  // This is the component that is actually connected by the wizard container.
  //


  render() {

    // Don't render anything if this specific wizard data doesn't exist.
    if (!this.props.sWizardData.get('isActive')) {
      return null;
    }

    return (
      <PL_WizardRenderer {...this.props} />
    );
  }

}




export class PL_WizardRenderer extends React.PureComponent {

  /* ---------------------------------------------------------------- */
  // Selects and renders current wizard step.
  //
  // Sends initial steps data to the store.
  //


  // componentWillMount = () => { //moved to componentDidMount
  //   if (!this.props.steps) {
  //     return;
  //   }
  //
  //   this.props.wizardActions.initSteps({steps: this.props.steps, defaultFormName: this.props.form});
  // };

  componentDidMount = () => {
    window.addEventListener('hashchange', this.handleHashChange);

    if (this.props.steps) {
      this.props.wizardActions.initSteps({steps: this.props.steps, defaultFormName: this.props.form});
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.handleHashChange);

    if (this.props.actions && this.props.actions.onWizardClose) {
      this.props.actions.onWizardClose();
    }
    this.props.wizardActions.close();
  };

  handleHashChange = (e) => {
    window.removeEventListener('hashchange', this.handleHashChange);
    if (this.props.actions && this.props.actions.onWizardClose) {
      this.props.actions.onWizardClose();
    }
    this.props.wizardActions.close();
  };


  renderSteps = () => {

    // Collect and save all step keys for later use in render.
    let stepsKeys = Object.keys(this.props.steps);


    // When the current step is null, choose first step by default.
    let currentStepKey = this.props.sWizardData.get('currentStep') || stepsKeys[0];

    let foundStep = this.props.steps[currentStepKey];
    if (!foundStep) {
      return null;
    }


    let isFirstLaunch = !(this.props.sWizardData.get('currentStep'));

    if (this.props.preventStepsUnmountOnNavigation) {
      let steps = stepsKeys.map((stepKey, index) => {

        let step = this.props.steps[stepKey];
        return (
          <step.type key={index}
                     {...this.props}
                     {...foundStep.props}
                     show={(stepKey === currentStepKey) }
          />
        )
      });

      return steps;
    }

    // Clone step component with props.
    return (
      <foundStep.type isFirstLaunch={isFirstLaunch} {...this.props} {...foundStep.props} />
    );

  };



  render() {

    // Don't render anything if this specific wizard data doesn't exist / or is inactive.
    if (!this.props.sWizardData.get('isActive')) {
      return null;
    }

    return (
      <div className="pl-wizard">
        {this.renderSteps()}
      </div>
    );
  }

}














