import {createContext, useState, useContext} from 'react';
import PropTypes from 'prop-types';

const schedulerContext = createContext();

export function SchedulerProvider({children}) {
    const [schedulerInstance, setSchedulerInstance] = useState();
    const [selectedRow, setSelectedRow] = useState();

    const context = {
      schedulerInstance,
      setSchedulerInstance,
      selectedRow,
      setSelectedRow
    }

    return (
        <schedulerContext.Provider value={context}>
            {children}
        </schedulerContext.Provider>
    );
}

SchedulerProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export function useSchedulerContext() {
  const context = useContext(schedulerContext)
  if (context === undefined) {
    throw new Error('useSchedulerContext must be used within a SchedulerProvider')
  }
  return context
}
