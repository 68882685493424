import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import * as lotService from '../../services/Lots/lotsService';
import Network from 'infrastructure/js/modules/network';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOT_PAGE_OVERVIEW_TAB_FETCHDATA_STARTED: 'LOT_PAGE_OVERVIEW_TAB_FETCHDATA_STARTED',
  LOT_PAGE_OVERVIEW_TAB_FETCHDATA_FINISHED: 'LOT_PAGE_OVERVIEW_TAB_FETCHDATA_FINISHED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  fetchDataStarted: function () {
    return { type: actionTypes.LOT_PAGE_OVERVIEW_TAB_FETCHDATA_STARTED };
  },
  fetchDataFinished: function (payload) {
    return { type: actionTypes.LOT_PAGE_OVERVIEW_TAB_FETCHDATA_FINISHED, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function (lotId) {
  return function (dispatch, getState) {
    api.init(dispatch, getState)(lotId);
  };
};

jsxActions.reload = function (lotId) {
  return function (dispatch, getState) {
    api.reload(dispatch, getState)(lotId);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function (dispatch, getState) {
  return function (lotId) {
    return api.fetchData(dispatch, getState)(lotId);
  };
};

api.reload = function (dispatch, getState) {
  return function (lotId) {
    return api.fetchData(dispatch, getState)(lotId);
  };
};

api.fetchData = function (dispatch, getState) {
  return function (lotId) {
    dispatch(actions.fetchDataStarted());

    return lotService
      .fetchLotById(lotId)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Get lot data by id failed.', response);
          dispatch(actions.setLoading(false));
          messageDialogApi.responseError(dispatch, getState)(response);
          return null;
        }

        const lotData = response.data;

        dispatch(actions.fetchDataFinished(lotData));
        return lotData;
      })
      .catch((err) => {
        console.error('Get lot data by id failed.', err);
      });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
