import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm, formValueSelector} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import {enumTypes, getEnumValue, getLookupOptions} from '../../../../../../../utils/enumHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import {AddRemoveListExt} from '../../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import ToolTypeKitTypeListItem from '../../../Common/ToolTypeKitTypeListItem/toolTypeKitTypeListItem';
import ToolTypeListItem from '../../../Common/ToolTypeListItem/toolTypeListItem';

import './createToolGroupDialog.scss';

class CreateToolGroupDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createToolGroup.');

    this.itemToEdit = props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.kitTypesIdsInUse = this.isEditMode ?
      this.itemToEdit.kitTypes?.map((item) => item.kitTypeId) : [];

    this.toolTypesIdsInUse = this.isEditMode ?
      this.itemToEdit.toolTypes?.map((item) => item.toolTypeId) : [];

    this.preSelectedItems = this.getPreselectedItems();
    this.toolTypesPreSelectedItems = this.getToolTypesPreselectedItems();

    this.kitTypeConfigurationOptions = getLookupOptions ( enumTypes.TOOL_CONFIGURATION_TYPE);

    this.state = {
      totalTokenValue: 0,
      kitTypeConfiguration: this.itemToEdit?.configurationType ?? getEnumValue(enumTypes.TOOL_CONFIGURATION_TYPE)('INTERCHANGEABLE'),
    };
  }

  componentDidMount() {
    let initialValues = {
      active: true,
      kitTypeConfiguration: getEnumValue(enumTypes.TOOL_CONFIGURATION_TYPE)('INTERCHANGEABLE'),
    };

    if (this.isEditMode) {
      initialValues = {
        active: this.itemToEdit.active,
        code: this.itemToEdit.businessId,
        name: this.itemToEdit.name ? {value: this.itemToEdit.name, label: this.itemToEdit.name} : null,
        kitTypeConfiguration: this.itemToEdit.configurationType
      };
    }

    initialValues.toolTypes = this.isEditMode && this.itemToEdit.toolTypes?.length > 0 ?
      this.itemToEdit.toolTypes?.map((item) => {
        return {
          toolTypeName: item.toolTypeId,
          quantity: item.quantity,
        }}) :
      [{quantity: 1}];

    initialValues.toolCategory = this.isEditMode ?
      {value: this.itemToEdit.toolCategory?.id, label: this.itemToEdit.toolCategory?.businessId, data: this.itemToEdit.toolCategory}:
      this.getDefaultToolCategory();

    initialValues.kitTypes = this.isEditMode && this.itemToEdit.kitTypes?.length > 0 ?
      this.itemToEdit.kitTypes?.map((item) => {

        let tokensPerPart = this.getTokensByKitType(item.kitTypeId);
        return {
          kitTypeName: item.kitTypeId,
          capacity: item.capacity,
          tokenValue: (tokensPerPart && item.capacity) ? (tokensPerPart * item.capacity) : null,
        }}) :
      [{capacity: 1}];

      this.props.initialize(initialValues);

    setTimeout(() => {this.updateTotalTokenValue()}, 0);
  }

  getTokensByKitType = (kitTypeId) => {
    return this.getAllKitTypes()?.find((kt => kt.value === kitTypeId))?.data?.tokensPerPart || null;
  }

  getDefaultToolCategory = () => {
    let items = this.getCategories();

    let defaultCategory = items.find(item => item.data?.defaultCategory);
    return defaultCategory || null;
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  getToolGroupsNames() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.toolGroupsNames ? dialogData.toolGroupsNames : [];
  }
  getCategories() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.toolCategories ? dialogData.toolCategories : [];
  }

  onSubmit = (data) => {
    const {
      name,
      toolCategory,
      code,
      active,
      kitTypeConfiguration,
    } = data;

    let newData = {
      active,
      businessId: code,
      name: name?.label || null,
      toolCategoryId: toolCategory?.value || null,
      kitTypes: this.createKitTypesData(data.kitTypes),
      toolTypes:  this.createToolTypesData(data.toolTypes),
      configurationType: kitTypeConfiguration,
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    } else {
      newData.active = active;
    }

    this.props.actions.submit(newData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  createKitTypesData = (kitTypes) => {
    return kitTypes.map((item) => {
      return {kitTypeId: item.kitTypeName, capacity: item.capacity}
    })
  }

  createToolTypesData = (toolTypes) => {
    return toolTypes?.map((item) => {
      return {toolTypeId: item.toolTypeName, quantity: item.quantity}
    })
  }

  onHide = () => {
    this.props.actions.hide();
  };

  onKitTypeConfigurationChangeHandler = (newValue) => {
    this.setState({kitTypeConfiguration: newValue?.value },
      () => {
        this.updateTotalTokenValue()
      });
  }

  getAllToolTypes = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.toolTypes ? dialogData.toolTypes : [];
  };

  getAllKitTypes = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.kitTypes ? dialogData.kitTypes : [];
  };

  onKitTypeListItemChanged = () => {
    setTimeout(() => {this.updateTotalTokenValue()}, 0);
  };

  onItemRemoveCallback = () => {
    setTimeout(() => {this.updateTotalTokenValue()}, 0);
  };

  updateTotalTokenValue = () => {
    this.setState({totalTokenValue: this.getTotalTokenValue()});
  };

  getTotalTokenValue = () => {
    let allRows = this.props.fieldsValues;

    if (allRows) {
      if (this.state.kitTypeConfiguration === getEnumValue(enumTypes.TOOL_CONFIGURATION_TYPE)('PARALLEL')) {
        return allRows?.reduce((previous, current) => {
          //+(value) to convert string to number
          let value = current.tokenValue ?? 0;
          return previous + (+(value));
        }, 0);
      }
      else {
        let min = Math.min( ...allRows.map(item => item.tokenValue ?? 0));
        let max = Math.max( ...allRows.map(item => item.tokenValue ?? 0));
        return min === max ? min : `${min} - ${max}`;
      }
    }
    return null;
  }

  getPreselectedItems = () => {
    if (this.itemToEdit) {
      return this.itemToEdit.kitTypes?.map((item) => {
        return {...item, tokensPerPart: this.getTokensByKitType(item.kitTypeId)}
      }) || [];
    }
    return [];
  }
  getToolTypesPreselectedItems = () => {
    if (this.itemToEdit) {
      return this.itemToEdit.toolTypes ?? [];
    }
    return [];
  }

  getToolTypeListItem = (itemProps) => {
    return (
      <ToolTypeListItem
        key={itemProps.id + '-' + itemProps.index}
        {...itemProps}
        change={this.props.change}
        labels={this.labels}
      />
    );
  }

  getKitTypeListItem = (itemProps) => {
    return (
      <ToolTypeKitTypeListItem
        key={itemProps.id + '-' + itemProps.index}
        {...itemProps}
        change={this.props.change}
        labels={this.labels}
        onItemChangeCallback={this.onKitTypeListItemChanged}
      />
    );
  }

  renderToolCategory = () => {
    return (
      <InputSection label={this.labels.get('toolCategory') + '*'} htmlFor="toolCategory"
                    className="inline left-side">
        <Combobox id="toolCategory" name="toolCategory"
                  isClearable={false}
                  options={this.getCategories()}
                  validate={Validation.required}
        />
      </InputSection>
    );
  }

  renderTotalTokens = () => {
    return  (
      <div className="total-token-value-container">
        <label>{this.labels.get('list.totalTokenValue', undefined, { totalTokenValue: this.state.totalTokenValue } )}</label>
      </div>
    )
  }

  renderToolTypesList = () => {
    let allOptions = this.getAllToolTypes();
    return (
      <div className="list-container input-section">
        <div className="list-title input-section">
          <label className="column">{this.labels.get('list.toolType') + '*'}</label>
          <label className="column">{this.labels.get('list.quantity') + '*'}</label>
        </div>
        <AddRemoveListExt className="tool-types-list list-body"
                          name='toolTypes'
                          id='toolTypes'
                          itemRendererComponent={this.getToolTypeListItem}
                          itemRendererOptions={allOptions}
                          preSelectedItemsIds={this.toolTypesIdsInUse}
                          preSelectedItems={this.toolTypesPreSelectedItems}
                          addButtonLabel={this.labels.get('list.toolType.addButton')}
                          hideAddAtButton={true}
                          defaultItem={{quantity: 1}}
                          maxItemsToOverflow={3}
                          minFieldsCount={1}/>
      </div>
    )
  }

  renderKitTypesList = () => {
    let allOptions = this.getAllKitTypes();
    return (
      <div className="kit-types-list-container list-container input-section">
        <div className="kit-types-list-title list-title input-section">
          <label className="column">{this.labels.get('list.kitType') + '*'}</label>
          <label className="column">{this.labels.get('list.capacity') + '*'}</label>
          <label className="column">{this.labels.get('list.tokenValue')}</label>
        </div>
        <AddRemoveListExt className="kit-types-list list-body"
                          name='kitTypes'
                          id='kitTypes'
                          itemRendererComponent={this.getKitTypeListItem}
                          itemRendererOptions={allOptions}
                          preSelectedItemsIds={this.kitTypesIdsInUse}
                          preSelectedItems={this.preSelectedItems}
                          addButtonLabel={this.labels.get('list.addButton')}
                          onRemoveCallback={this.onItemRemoveCallback}
                          hideAddAtButton={true}
                          defaultItem={{capacity: 1}}
                          maxItemsToOverflow={3}
                          minFieldsCount={1}/>
        {this.renderTotalTokens()}
      </div>
    )
  }

  maxLength100 = Validation.maxLength(100);
  maxLengthDropDown = Validation.dropdown.maxLength(50);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-tool-group-dialog"
        className="create-tool-group-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active" label={this.labels.get('active')} disabled={this.isEditMode}/>
        </InputSection>

        { this.renderToolTypesList() }

        <InputSection label={this.labels.get('code') + '*'} htmlFor="code" className="inline left-side">
          <TextField id="code" name="code" className="short-textfield"
                     validate={[Validation.required, this.maxLength100]}
          />
        </InputSection>

        <InputSection label={this.labels.get('name')} htmlFor="name" className="inline right-side">
          <Combobox id="name" name="name" options={this.getToolGroupsNames()}
                    allowNewOption={true}
                    validate={[this.maxLengthDropDown]}
          />
        </InputSection>

        { this.renderToolCategory() }

        <InputSection label={this.labels.get('kitTypeConfiguration') + '*'} htmlFor="kitTypeConfiguration" className="inline right-side">
          <Combobox id="kitTypeConfiguration" name="kitTypeConfiguration"
                    options={this.kitTypeConfigurationOptions}
                    validate={Validation.required}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.kitTypeConfigurationOptions)}
                    onChangeCallback={this.onKitTypeConfigurationChangeHandler}
          />
        </InputSection>

        { this.renderKitTypesList() }
      </Dialog>
    );
  }
}

CreateToolGroupDialog = reduxForm({
    form: 'createToolGroupDialog',
  }
)(CreateToolGroupDialog);


const selector = formValueSelector('createToolGroupDialog');

export default CreateToolGroupDialog = connect(state => {
  return {  fieldsValues: selector(state, 'kitTypes') }
})( CreateToolGroupDialog );

CreateToolGroupDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};



