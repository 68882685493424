import {IsJsonString} from 'infrastructure/js/utils/JSON.js';

import {
  isAdmin,
  isHookUser,
  isLimitedUser,
  isPresenterUser,
  isPMUser,
  isPSUser,
  isRfidAdminUser,
  isSysAdmin,
  isUser,
  isQAManager
} from "../../../mat/js/utils/userHelper";
import {enumTypes, getEnumValue} from "../../../mat/js/utils/enumHelper";

let loggedInUser = localStorage['loggedInUser'];
if(!(loggedInUser = IsJsonString(loggedInUser))) {
  loggedInUser = undefined;
}
//todo - remove the user helper after server return role settings config
let PermissionManagerInstance = function () {
  let obj = {};

  let _loggedInUser = null;

  obj.init = function(user) {
    _loggedInUser = user;
  };

  if(loggedInUser){
    obj.init(loggedInUser);
  }
  obj.getLoggedInUser = function() {
    return _loggedInUser;
  };

  obj.getOrgPreferences = function() {
    return _loggedInUser.loggedInOrg.orgPreferences;
  };

  obj.isSelfSustainingOrg = function () {
    return _loggedInUser.loggedInOrg?.designations?.includes('SELF_SUSTAINING')
  }

  obj.isCustomerOrg = function () {
    return _loggedInUser.loggedInOrg?.designations?.includes('CUSTOMER')
  }

  obj.isSupplierOrg = function () {
    return _loggedInUser.loggedInOrg?.designations?.includes('SUPPLIER')
  }

  obj.isResinsEnabled = function(){
    return true;                         //TODO: L RESINS currently always enabled
  };


  obj.isToolsSchedulingEnabled = function(){
    // return this.getOrgPreferences().toolsSchedulingEnabled; //TODO: L TOOLS
    return this.getOrgPreferences().schedulerEnabled; //TODO: L TOOLS currently enabled when the Scheduler is enabled
  };

  obj.isSchedulerEnabled = function(){
    return this.getOrgPreferences().schedulerEnabled;
  };

  obj.isEtlBondEnabled = function(){
    return this.getOrgPreferences().presentETLBondCounter;
  };

  obj.isDateFormatEnabled = function(){
    return this.getOrgPreferences().showingDateFormatEnabled;
  };

  // obj.isYardForLengthSupported = function() {
  //   return this.getOrgPreferences().imperialSystemUnits === getEnumValue(enumTypes.IMPERIAL_SYSTEM_UNITS)('YARDS') &&
  //           UnitHelper.getUnitMeasurementType() === UNIT_TYPES.IMPERIAL;
  // };

  obj.isPartTypeOriented = function(){
    return this.getOrgPreferences().systemDefaultWorkOrderType  === 'PARTTYPE_ORIENTED';
  };

  obj.isWeightSupported = function() {
    return this.getOrgPreferences().rollsMainUomIsWeight;
  };

  obj.hasSelfSustainingOrgPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || this.isSelfSustainingOrg()
  }

  obj.hasSupplierOrgPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || this.isSupplierOrg()
  }

  obj.hasCustomerOrgPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || this.isCustomerOrg()
  }

  obj.hasAdministrationTabPermissions = function(){
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isRfidAdminUser(_loggedInUser)
  };
  obj.hasRfidTabPermissions = function(){
   return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isRfidAdminUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasUpdateShelfLifePermissions = function(){
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isRfidAdminUser(_loggedInUser) || isPMUser(_loggedInUser) || isQAManager(_loggedInUser)
  };
  obj.hasBillingTabPermissions = function(){
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasSandboxTabPermissions = function(){
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasPSManagementTabPermissions = function(){
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasUserManagementTabPermissions = function(){
    return isAdmin(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser)
  };
  obj.hasUserSettingsTabPermissions = function(){
    return isAdmin(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser)
  };

  obj.hasDashboardsTabPermissions = function(){
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isPresenterUser(_loggedInUser)
  };
  obj.hasInventoryDashboardsTabPermissions = function(){
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isPresenterUser(_loggedInUser)
  };
  obj.hasMatSettingsTabPermissions = function(){
    return isAdmin(_loggedInUser) ||  isRfidAdminUser(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser)
  };
  obj.hasCreateAssetsPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasImportAssetsPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasArchiveAssetsPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasActivateAssetsPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasMarkWoCompletePermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasMarkWoCanceledPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasReopenWoPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasEditAttributesPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasEditAssetDetailsPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasGroupAssetPermissions = function(){
    return !isLimitedUser(_loggedInUser)
  };
  obj.hasCreateOrEditKitTypesPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isAdmin(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasCreateOrEditToolCategoriesPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isAdmin(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasCreateOrEditToolTypesPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isAdmin(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasCreateOrEditToolGroupsPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isAdmin(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasTerminologyTabPermissions = function () {
    return isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser)
  };
  obj.hasIPWhitelistTabPermissions = function () {
    return isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser)
  };
  obj.hasPdfLabelTabPermissions = function () {
    return isAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser)
  };
  //includes alerts/csv/additional fields
  obj.hasBasicSettingsTabPermissions = function () {
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasPreferencesSettingsTabPermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasLocationsAndOperationsSequenceTabPermissions = function () {
    return isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasOrgTabInOrgPagePermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasAdminTabInOrgPagePermissions = function () {
    return isSysAdmin(_loggedInUser)
  };
  obj.hasDashboardInOrgPagePermissions = function () {
    return isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser)
  };
  obj.hasLoginManagementPagePermissions = function(){
    return isPMUser(_loggedInUser);
  };
  //apptopbar
  obj.hasOrganizationDataForPSPermissions = function () {
    return !isSysAdmin(_loggedInUser) && !isPSUser(_loggedInUser)
  };
  //sysActions
  obj.hasPlataineOrgFeatureFlagsPermissions = function () {
    return isPMUser(_loggedInUser) || isPSUser(_loggedInUser) || isSysAdmin(_loggedInUser)
  };
  //sysActions
  obj.hasHookUserPermissions = function () {
    return isHookUser(_loggedInUser)
  };
  obj.hasReportCuringInRetrospectPermissions = function () {
    return isAdmin(_loggedInUser) || isRfidAdminUser(_loggedInUser) || isPSUser(_loggedInUser) || isSysAdmin(_loggedInUser) || isPMUser(_loggedInUser);
  };
  obj.hasOrgPlataineUserManagementTabPermissions = function(){
    return isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser)
  };
  obj.hasRemoveComponentAssetPermissions = function(){
    return isAdmin(_loggedInUser) || isRfidAdminUser(_loggedInUser) || isPSUser(_loggedInUser) || isSysAdmin(_loggedInUser) || isPMUser(_loggedInUser);
  };
  obj.hasAllLabelsModeInTerminologyPermissions = function(){
    return isPMUser(_loggedInUser) || isPSUser(_loggedInUser) || isSysAdmin(_loggedInUser);
  };
  obj.hasIgnoreValidationsPermissions = function(hasMediumSeverityWarning, hasHighSeverityWarning) {
    if (!hasMediumSeverityWarning && !hasHighSeverityWarning) {
      return true;
    }

    if(isAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isSysAdmin(_loggedInUser) || isPMUser(_loggedInUser)){
      return true;
    }

    let canIgnoreMediumWarnings = !this.getOrgPreferences().disableIgnoringMediumWarnings;
    let canIgnoreHighWarnings = !this.getOrgPreferences().disableIgnoringHighWarnings;

    if(canIgnoreMediumWarnings && canIgnoreHighWarnings){
      return true;
    }
    if(canIgnoreMediumWarnings && !hasHighSeverityWarning){
      return true;
    }
    if (canIgnoreHighWarnings && hasHighSeverityWarning && !hasMediumSeverityWarning){
      return true;
    }

    return false;
  };

  obj.hasPredefinedViewPickListPermissions = function() {
    return !(_loggedInUser.loggedInOrg.status === 'TRIAL');
  };
  obj.hasLocationPickListTabPermissions = function() {
    return (_loggedInUser.loggedInOrg.status === 'TRIAL');
  };

  obj.hasPredefinedViewSchedulerPermissions = function() {
    return isRfidAdminUser(_loggedInUser) || isAdmin(_loggedInUser) || isSysAdmin(_loggedInUser) || isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isPresenterUser(_loggedInUser)
  };

  obj.hasPredefinedViewSmartSelectionPermissions = function() {
    return !(_loggedInUser.loggedInOrg.status === 'TRIAL');
  };
  obj.hasLocationSmartSelectionTabPermissions = function() {
    return (_loggedInUser.loggedInOrg.status === 'TRIAL');
  };

  obj.editLengthInFreezerPermissions = function() {
    return (_loggedInUser.loggedInOrg.status === 'TRIAL');
  };

  obj.hasAssetTypesTabPermissions = function () {
    return isPSUser(_loggedInUser) || isPMUser(_loggedInUser) || isSysAdmin(_loggedInUser)
  };

  obj.hasScrapViewPermissions = function() {
    return obj.getOrgPreferences().scrapEnabled;
  }

  obj.hasSchedulerAnalyticModePermissions = function() {
    return obj.getOrgPreferences().schedulerAnalyticModeEnabled;
  }

  obj.hasAccessToMultipleOrgs = function() {
    return isPSUser(_loggedInUser) || isPMUser(_loggedInUser);
  }

  return obj;
}();

//For debug and testing use only, to make role-switching easy
//do NOT remove dev check
if (__DEV__) {
  window.permissionManager = PermissionManagerInstance;

  function toRole(obj) {
    if (typeof(obj) === 'string')
      return { name: obj };
    else if (typeof(obj) === 'object' && obj.name)
      return obj;
    else throw new Error('Role required to be of type \'string\' or { name: \'string\' }');
  }

  PermissionManagerInstance.addRole = (role) => {
    PermissionManagerInstance.getLoggedInUser().roles.push(toRole(role));
    return PermissionManagerInstance.getLoggedInUser().roles;
  }

  PermissionManagerInstance.addRoles = (...roles) => {
    PermissionManagerInstance.getLoggedInUser().roles.push(...roles.map(toRole));
    return PermissionManagerInstance.getLoggedInUser().roles;
  }

  PermissionManagerInstance.setRole = (role) => {
    PermissionManagerInstance.getLoggedInUser().roles = [toRole(role)];
    return PermissionManagerInstance.getLoggedInUser().roles;
  }

  PermissionManagerInstance.setRoles = (...roles) => {
    PermissionManagerInstance.getLoggedInUser().roles = roles.map(toRole);
    return PermissionManagerInstance.getLoggedInUser().roles;
  }
}

export default PermissionManagerInstance;
