import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/Administration/OrganizationsPage/demoOrganizationsPageActions.js';

let defaultState = {
  baselineOrgName: '',
  zeroTime: null,
};

export default function (state = Map(defaultState), action) {

  switch (action.type) {
    case actionTypes.DEMO_ORGANIZATIONS_PAGE_FETCH_DATA_READY:
      return state.set('baselineOrgName', action.payload.baselineOrgName)
                  .set('zeroTime', action.payload.zeroTime);
    default:
      return state;
  }
}
