import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import List from 'infrastructure/js/components/List/list';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DefrostingRangeListItem from '../../../../../Common/Controls/AddRemoveList/AddRemoveListItem/DefrostingRangeListItem/defrostingRangeListItem';
import comparisonOperatorsEnum from '../../../../../../enums/comparisonOperators';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
import { components } from 'react-select';

import './createMaterialTypeDialog.scss';

const { GT, GTE, LT, LTE, BETWEEN } = comparisonOperatorsEnum;

const operatorOptions = [
  { value: GT, label: '>' },
  { value: GTE, label: '>=' },
  { value: LT, label: '<' },
  { value: LTE, label: '<=' },
  { value: BETWEEN, label: '-' },
];

class CreateMaterialTypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.creatematerialtype.');
    this.defrostingRangesUOMOptions = [
      { label: UnitHelper.getLabelForMajorUnitType(unitTypes.LENGTH), value: unitTypes.LENGTH },
      { label: UnitHelper.getLabelForMajorUnitType(unitTypes.AREA), value: unitTypes.AREA },
    ]

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;
    this.renderETLBond = PermissionManager.isEtlBondEnabled();
    this.majorUOMType = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;

    if(!PermissionManager.isWeightSupported() && this.isEditMode && this.itemToEdit.defrostingRangeRules){
      this.majorUOMType = this.itemToEdit.defrostingRangeRules.areaUnits ? unitTypes.AREA : unitTypes.LENGTH;
    }

    this.state = {
      majorUOMType: this.majorUOMType,
    }
  }

  componentDidMount() {
    let initialValues = {
      active: true,
    };
    let defaultDefrostingTime = PermissionManager.getOrgPreferences().defaultDefrostingTime;
    if(defaultDefrostingTime){
      initialValues.defrostingTime_minutes = defaultDefrostingTime % 60 | 0;
      initialValues.defrostingTime_hours = defaultDefrostingTime / 60 | 0;
    }
    if (this.isEditMode) {
      initialValues = {
        active: this.itemToEdit.active,
        materialCode: this.itemToEdit.businessId,
        materialName: {value: this.itemToEdit.id, label: this.itemToEdit.materialName, data: this.itemToEdit},
        expiresWithinDays: this.itemToEdit.expiresWithinDays,
        defrostingDecayRate: this.itemToEdit.defrostingDecayRate ?? null,
        defrostingRanges: this.getInitialDefrostingRanges(),
        defrostingRangesUOM: this.majorUOMType,
      };
      let _width = this.itemToEdit.width;
      if (null !== _width && undefined !== _width) {
        initialValues.width = UnitHelper.serverValueToUserValue(unitTypes.WIDTH, _width, 2);
      }
      let _maxStorageTemperature = this.itemToEdit.maxStorageTemp;
      if (null !== _maxStorageTemperature && undefined !== _maxStorageTemperature) {
        initialValues.maxStorageTemp = UnitHelper.serverValueToUserValue(unitTypes.TEMPERATURE, _maxStorageTemperature, 0);
      }
      if(this.itemToEdit.defrostingTime !== null){
        initialValues.defrostingTime_minutes = this.itemToEdit.defrostingTime % 60 | 0;
        initialValues.defrostingTime_hours = this.itemToEdit.defrostingTime / 60 | 0;
      }
      if(this.itemToEdit.maxExposureTimeBond !== null){
        initialValues.maxExposureTimeBond_minutes = this.itemToEdit.maxExposureTimeBond % 60 | 0;
        initialValues.maxExposureTimeBond_hours = this.itemToEdit.maxExposureTimeBond / 60 | 0;
      }
      if(this.itemToEdit.maxExposureTimeCure !== null){
        initialValues.maxExposureTimeCure_minutes = this.itemToEdit.maxExposureTimeCure % 60 | 0;
        initialValues.maxExposureTimeCure_hours = this.itemToEdit.maxExposureTimeCure / 60 | 0;
      }
    }

    this.props.initialize(initialValues);
  }

  getInitialDefrostingRanges() {
    if(this.isEditMode && this.itemToEdit?.defrostingRangeRules?.rules){
      return this.itemToEdit.defrostingRangeRules.rules.map((rule) => {
        // aliasing arguments to _arguments to avoid conflict with arguments keyword
        const { comparisonOperator, arguments: _arguments, defrostingTimeInMinutes } = rule;
        
        let minQuantity, quantity;

        if(_arguments.length === 2){
          minQuantity = _arguments[0];
          quantity = _arguments[1];
        } else {
          minQuantity = undefined;
          quantity = _arguments[0];
        }

        return {
          operator: operatorOptions.find((op) => op.value === comparisonOperator),
          quantity,
          minQuantity,
          defrostingTime: {
            _hours: defrostingTimeInMinutes / 60 | 0,
            _minutes: defrostingTimeInMinutes % 60 | 0,
          }
        };
      });
    }

    return [];
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  getMaterials() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.materials ? dialogData.materials : [];
  }

  getDefrostingRanges(defrostingRanges) {
    if(!defrostingRanges?.length){
      return null;
    }
    
    return defrostingRanges?.map((range) => {
      const { operator, minQuantity, quantity, defrostingTime } = range;
      return {
        comparisonOperator: operator.value,
        arguments: minQuantity ? [minQuantity, quantity] : [quantity],
        defrostingTimeInMinutes: DateTimeHelper.ConvertHoursMinutesToMinutes(defrostingTime._hours, defrostingTime._minutes),
      };
    });
  }

  onSubmit = (data) => {

    let materialNameLabel = data.materialName ? data.materialName.label : null;
    let defrostingRangeRules = null;
    if(data.defrostingRanges?.length){
      defrostingRangeRules = {
        areaUnits: this.state.majorUOMType === unitTypes.AREA,
        rules: this.getDefrostingRanges(data.defrostingRanges)
      };
    }

    let newData = {
      businessId: data.materialCode,
      materialName: materialNameLabel,
      defrostingTime: DateTimeHelper.ConvertHoursMinutesToMinutes(data.defrostingTime_hours, data.defrostingTime_minutes),
      defrostingDecayRate: (data.defrostingDecayRate || data.defrostingDecayRate === 0) ? data.defrostingDecayRate : null,
      width: (data.width || data.width === 0) ? UnitHelper.userValueToServerValue(unitTypes.WIDTH, data.width, 2) : null,
      maxExposureTimeBond: DateTimeHelper.ConvertHoursMinutesToMinutes(data.maxExposureTimeBond_hours, data.maxExposureTimeBond_minutes),
      maxExposureTimeCure: DateTimeHelper.ConvertHoursMinutesToMinutes(data.maxExposureTimeCure_hours, data.maxExposureTimeCure_minutes),
      expiresWithinDays: data.expiresWithinDays,
      maxStorageTemp: UnitHelper.userValueToServerValue(unitTypes.TEMPERATURE, data.maxStorageTemp, 0),
      defrostingRangeRules
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
      newData.orgId = this.itemToEdit.orgId;
    }
    else {
      newData.active = data.active;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onMajorUOMChange = ({value}) => {
    this.setState({ majorUOMType: value });
  }

  getLabelExtraComponent = (value) => {
    return (
      <span className="label-extra-component">{value}</span>
    )
  }

  maxLength50 = Validation.maxLength(50);
  maxLengthDropDown = Validation.dropdown.maxLength(50);

  renderMaxExposureTimeBond = () => {
    return (
      <InputSection label={this.labels.get('maxExposureTimeBond')} htmlFor="maxExposureTimeBond" className="inline left-side">
        <TimeField id="maxExposureTimeBond" name="maxExposureTimeBond" hoursCount={true} maxHoursLength={3} />
      </InputSection>);
  }

  renderMaxExposureTimeCure = () => {
    return (
      <InputSection label={this.labels.get('maxExposureTimeCure')} htmlFor="maxExposureTimeCure" className="inline left-side">
        <TimeField id="maxExposureTimeCure" name="maxExposureTimeCure" hoursCount={true} maxHoursLength={3} />
      </InputSection>
    );
  }

  renderMaxTemperature = () => {
    let side = this.renderETLBond ? "right-side" : "left-side";
    return (
      <InputSection label={this.labels.get('maxstoragetemperature', undefined, { units: UnitHelper.getLabelForUnitType(unitTypes.TEMPERATURE) })}  htmlFor="maxStorageTemp" className={"inline " + side}>
        <TextField id="maxStorageTemp" name="maxStorageTemp" className="short-textfield" normalize={Normalize.number(true,  UnitHelper.getMinValueForUnitType(unitTypes.TEMPERATURE, 0),  UnitHelper.getMaxValueForUnitType(unitTypes.TEMPERATURE, 0))}/>
      </InputSection>);
  }

  listItemRenderer = (data) => {
    return (
      <DefrostingRangeListItem
        {...data}
        unitType={this.state.majorUOMType}
        {...this.props}
      />
    )
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-material-type-dialog"
        className="create-material-type-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active"  label={this.labels.get('active')} disabled={this.isEditMode}/>
        </InputSection>

        <InputSection label={this.labels.get('code')+'*'} htmlFor="materialCode" className="inline left-side">
          <TextField id="materialCode" name="materialCode" className="short-textfield"
                     validate={[Validation.required, this.maxLength50]}
          />
        </InputSection>

        <InputSection label={this.labels.get('name')} htmlFor="materialName" className="inline right-side">
          <Combobox id="materialName" name="materialName" options={this.getMaterials()}
                    allowNewOption={true}
                    // parse={Parse.comboValueOnly()}
                    // format={Format.findOptionByValue(this.getMaterials())}
                    validate={this.maxLengthDropDown}
          />
        </InputSection>
        <div className='input-section inline left-side'>
          <div className='defrosting-section'>
            <InputSection label={this.labels.get('defrostingTime')} htmlFor="defrostingTime"  className="no-margin">
              <TimeField id="defrostingTime" name="defrostingTime" hoursCount={true} maxHoursLength={3} />
            </InputSection>

            <InputSection label={this.labels.get('defrostingDecayRate')} htmlFor="defrostingDecayRate" className="no-margin">
              <TextField id="defrostingDecayRate" name="defrostingDecayRate" normalize={Normalize.number(true, 0, 100)} />
            </InputSection>
          </div>
        </div>

        <InputSection label={this.labels.get('width')} htmlFor="width" className="inline right-side"
                      labelExtraComponent={this.getLabelExtraComponent(`(${UnitHelper.getLabelForUnitType(unitTypes.WIDTH)})`) }>
          <TextField id="width" name="width" className="short-textfield" normalize={Normalize.number(false, 0, UnitHelper.getMaxValueForUnitType(unitTypes.WIDTH))} />
        </InputSection>

        { (this.renderETLBond ? this.renderMaxExposureTimeBond() : this.renderMaxExposureTimeCure() )}

        <InputSection label={this.labels.get('expiresWithinDays')} htmlFor="expiresWithinDays" className="inline right-side">
          <TextField id="expiresWithinDays" name="expiresWithinDays" normalize={Normalize.number(true, 0, 99999)}/>
        </InputSection>

        {this.renderETLBond ? this.renderMaxExposureTimeCure() : null}
        {this.renderMaxTemperature()}

        <div className="defrosting-ranges">
          <div className="defrosting-ranges__header">
            <div className="defrosting-ranges__column-header">
            <label>
                {this.labels.get('defrostingRangeList.header.range') + '*'} ({UnitHelper.getLabelForUnitType(this.state.majorUOMType)})
                {this.state.majorUOMType !== unitTypes.WEIGHT && (
                  <Combobox
                    id="defrostingRangesUOM"
                    name="defrostingRangesUOM"
                    options={this.defrostingRangesUOMOptions}
                    onChangeCallback={this.onMajorUOMChange}
                    dropdownIndicatorRenderer={DropdownIndicator}
                    isSearchable={false}
                    placeholder={null}
                  />
                )}
              </label>
            </div>
            <label>{this.labels.get('defrostingRangeList.header.time')  + '*'}</label>
          </div>

          <List
            name="defrostingRanges"
            itemRenderer={this.listItemRenderer}
            addButtonLabel={'Add Range'}
            maxItemsToOverflow={6}
            maxItemsToRender={10}
          />
        </div>

      </Dialog>
    );
  }
}

CreateMaterialTypeDialog = reduxForm({
    form: 'createMaterialTypeDialog',
  }
)(CreateMaterialTypeDialog);

const selector = formValueSelector('createMaterialTypeDialog');

export default CreateMaterialTypeDialog = connect((state) => {
  return {
    fieldsValues: {
      defrostingRanges: selector(state, 'defrostingRanges'),
    },
  };
})(CreateMaterialTypeDialog);

CreateMaterialTypeDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};

const DropdownIndicator = forwardRef((params, ref) => {
  return (
    <Tooltip placement="top" value={'Select Unit Type'} delayShow={500}>
      <div>
      <components.DropdownIndicator {...params} ref={ref}/>
      </div>
    </Tooltip>
  );
})

DropdownIndicator.displayName = 'DropdownIndicator';