// @ts-check

import React from 'react';
import { MapView } from '@mappedin/mappedin-js';

import './mapLevelSelectorOverlay.scss';



/**
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageActions} LiveMapPageActions
 * 
 * @typedef {{
 *    mapView:  MapView | null | undefined, 
 *    sData:    PL_LiveMapPageState, 
 *    actions:  LiveMapPageActions
 *    refContext: React.MutableRefObject<LiveMapPageRefContext>
 * }} MapLevelSelectorOverlayProps * 
 * 
 * 
 */




/**
 * @param {MapLevelSelectorOverlayProps} props
 */
const MapLevelSelectorOverlay = (props) => {

  // console.log('=========== OVERLAY RENDER ===> ', props);

  let mapView = props.mapView;
  if (!mapView) {
    return null;
  }

  let sData = props.sData;
  if (!sData) {
    return null;
  }
  
  let actions = props.actions;
  if (!actions) {
    return null;
  }

  
  
  let currentFloorName = sData.floorName;
  

  /** @param {string} floorName */
  let setFloor = (floorName) => {
    actions.setFloor(floorName);
  }
  

  /**
   * @param {MapView | undefined} mapView
   */
  let getOverlayContent = (mapView) => {
    if (!mapView) {
      return null;
    }

    let venue = mapView.venue;
    if (!venue) {
      return null;
    }

    // console.log('=========== OVERLAY GET ===> ', props, mapView.currentMap.id);


    // Order maps by elevation.
    let maps = venue.maps.slice().sort((a, b) => b.elevation - a.elevation);
    // console.log('maps ===> ', maps);
    

    return (
      <>
        {/* <div>OVERLAY !!!</div> */}

        <div className='live-map-overlay-level-chooser-buttons'>
          {
            maps.map((m) => {

              // let isCurrent = m.id === currentMapId;
              let isCurrent = m.shortName === currentFloorName;
              let className = isCurrent ? 'current' : '';

              return (
                <div key={m.id}>
                  <button className={className}
                    onClick={(e) => {
                      e.preventDefault();
                      setFloor(m.shortName);
                    }}
                  >
                    {m.shortName}
                  </button>

                </div>
              );
            })
          }

        </div>
      </>
    );
  };



  return (
    <div className="live-map-overlay-level-chooser">
      {getOverlayContent(mapView)}
    </div>
  );

};


export default MapLevelSelectorOverlay;


