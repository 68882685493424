import { useEffect } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import SandboxesGrid from './SandboxesGrid/sandboxesGrid';
import { jsxActions as sandboxGridActions } from '../../../actions/Administration/SandboxPage/sandboxGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as sandboxPageActions } from '../../../actions/Administration/SandboxPage/sandboxPageActions';
import { jsxActions as createSandboxDialogActions } from '../../../actions/Administration/SandboxPage/Dialogs/createSandboxDialogActions';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import { navigationStates } from '../../../enums/navigationStates';
import useLabels from 'infrastructure/js/hooks/useLabels';
import {gridsNames} from '../../../enums/gridsNames';
import {List} from 'immutable';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import CreateSandboxDialog from './Dialogs/CreateSandboxDialog/createSandboxDialog';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import './sandboxPage.scss';


const getState = (state) => {
  const pageState =  state.administration.get('sandboxPage');
  const grid = state.grid?.get(gridsNames.ADMINISTRATION_SANDBOX) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
    sRowsNumber: grid?.get('rows')?.size ?? 0,
    loading: pageState.get('loading'),
    sCreateSandboxDialogData: state.administration.getIn(['sandboxPage', 'createSandboxDialog']),
  };
};

const allActions = {
  sandboxGridActions,
  fetchEntitiesActions,
  sandboxPageActions,
  createSandboxDialogActions,
};

export default function SandboxPage() {
  const labels = useLabels('mat.administration.sandbox.');
  const updateNavBar = useNavBarUpdate();
  const { state, actions } = useRedux(allActions, getState);
  const {sHasRows = false, sSelectedRowsData, sRowsNumber=0, sCreateSandboxDialogData, loading } = state;

  useEffect(() => {
    updateNavBar(navigationStates.SANDBOX, labels.get('title'));
  }, [updateNavBar, labels]);

  const getTitle = () => {
    const maxAllowedSandboxes = PermissionManager.getLoggedInUser()?.loggedInOrg?.allowedNumberOfSandboxes ?? 0;
    return labels.get('view.title', '', {count: sRowsNumber, maxCount : maxAllowedSandboxes});
  }

  const getHeaderItems = () => {
    return  {
      title: getTitle(),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.sandboxPageActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.sandboxPageActions.reload,
          tooltip: headerItemsLabels.REFRESH,
        },
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.sandboxPageActions.openCreateSandboxDialog,
        },
        {
          id: 'duplicate',
          label: headerItemsLabels.CLONE,
          icon: 'pl pl-duplicate',
          disabled: !(sHasRows && sSelectedRowsData.size === 1),
          action: actions.sandboxPageActions.openCreateSandboxDialog,
          actionData: sSelectedRowsData.get(0),
        },
        {
          id: 'reset',
          label: headerItemsLabels.RESET,
          icon: 'pl pl-refresh',
          disabled: !(sHasRows && sSelectedRowsData.size === 1),
          action: actions.sandboxPageActions.resetSandbox,
          actionData: sSelectedRowsData.get(0),
        },
        {
          id:'open',
          label: headerItemsLabels.OPEN,
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.OPEN_IN_NEW_TAB,
          disabled: !(sHasRows && sSelectedRowsData.size === 1),
          action: actions.sandboxPageActions.openSandbox,
          actionData: {
            sandboxMode: true,
            orgName: sSelectedRowsData.get(0)?.businessId ?? ''
          },
        },
      ],
      kebab: {
        menuItems: [
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.sandboxPageActions.deleteSandbox,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            actionData:  sSelectedRowsData,
          },
        ]
      }
    };
  }

  const reloadPage = () => {
    actions.sandboxPageActions.reload();
  }

  return (
    <div className="sandbox-page">
      <Header {...getHeaderItems()}/>

      <SandboxesGrid actions={{ ...actions.sandboxGridActions, ...actions.fetchEntitiesActions }} />

      {loading ? <Overlay.Loading /> : null}

      <PL_DialogWrapper dialogComponent={CreateSandboxDialog}
                        show={sCreateSandboxDialogData.get('show')}
                        actions={actions.createSandboxDialogActions}
                        sData={sCreateSandboxDialogData}
                        reloadParentComponent={reloadPage}
      />
    </div>
  );
}
