import React from 'react';
import PropTypes from 'prop-types';
import FilterFooter from '../Common/FilterFooter/filterFooter';
import FilterLayout from '../Common/FilterLayout/filterLayout';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {PL_DatePicker} from 'infrastructure/js/components/controls/DatePicker/datepicker';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import moment from 'moment';
import ComboBox from '../../../controls/Combobox/combobox';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';

import './horizonFilter.scss';

const PickerType = {
  start: 'START',
  end: 'END'
};

const noneDateString = 'none';

export default class PL_HorizonFilter extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.filter.');

    this.startDate = null;
    this.endDate = null;
    this.state = {
      selectedStartDate: null,
      selectedEndDate: null,
      selectedFilterName: props.select ? null : props.filterName,
      error: null,
      dummyKey: 0 //dummy key to force the component remount;
    }
    this.startDatePickerRef = this.endDatePickerRef = null;
  }

  componentDidMount() {
    this.filterName = this.props.filterName;
    this.setModel();
  }

  setModel() {
    let {values} = GridHelper.getActivatedFilter(this.props.filterName, this.props.context?.componentParent?.props?.filterState?.toJS());
    if (values && values.length > 1) {
      let startDateVal = values[0];
      let endDateVal = values[1];
      this.startDate = startDateVal === noneDateString ? null :  {epochDateTime: startDateVal};
      this.endDate = endDateVal === noneDateString ? null :  {epochDateTime: endDateVal};
    }
  }

  // called by agGrid
  afterGuiAttached(params) {
    this.setState({
      selectedStartDate: this.startDate || null,
      selectedEndDate: this.endDate || null,
      dummyKey: 1-this.state.dummyKey
    });
    this.hidePopup = params.hidePopup;

    //to prevent the appearance of DatePicker calendar on the Filter opening
    if (this.startDatePickerRef && this.startDatePickerRef.pickerRef) {
      this.startDatePickerRef.pickerRef.setOpen(false);
    }
    if (this.endDatePickerRef && this.endDatePickerRef.pickerRef) {
      this.endDatePickerRef.pickerRef.setOpen(false);
    }
  }

  // called by agGrid
  doesFilterPass(params) {
    return true;
  }

  // called by agGrid
  isFilterActive() {
    return (this.startDate !== null || this.endDate !== null);
  }

  // called by agGrid
  onNewRowsLoaded() {
    this.handleFilterState();
  }

  handleFilterState = () => {
    let filterState = this.props.context?.componentParent?.props?.filterState;

    if (!filterState || filterState.size ===  0 ) {
      return;
    }
    let isFilterStateActive = filterState?.some((item) => {
      return item.filterName === this.filterName && item.values;
    });
    if (!isFilterStateActive && this.isFilterActive()) {
      this.deactivateFilter();

    }
  }

  deactivateFilter = () => {
    this.startDate = null;
    this.endDate = null;

    this.setState(
      {
        selectedStartDate: null,
        selectedEndDate: null,
        error: null,
        dummyKey: 1 - this.state.dummyKey
      });
  }

  apply() {
    if (this.props.select && !this.state.selectedFilterName) {
        this.setState({ error: this.labels.get('horizon.noFilterNameSelected') });
        return;
    }
    this.startDate = this.state.selectedStartDate;
    this.endDate = this.state.selectedEndDate;
    this.filterName = this.state.selectedFilterName;

    this.props.colDef?.filterParams?.onFilterChanged?.(this.getModel());
    this.hidePopup();
  }

  cancel() {
    this.hidePopup();
  }

  getModel() {
    let startDate = this.startDate && this.startDate.epochDateTime, endDate = this.endDate && this.endDate.epochDateTime;
    if (startDate || endDate){
      return [{ filterName: this.state.selectedFilterName, values: [startDate || noneDateString, endDate || noneDateString] }];
    }
    return [{ filterName: this.state.selectedFilterName, values: [] }];
  }

  //not in use
  getApi() {
    return {
      getModel: this.getModel.bind(this)
    }
  }

  onSelectChange = (option) => {
    if (this.state.selectedFilterName)
    {
      if (!option || !option.value || option.value !== this.state.selectedFilterName)
        this.props.colDef?.filterParams?.onFilterChanged?.([{ filterName: this.state.selectedFilterName, values: [] }]);
    }
    this.setState({ selectedFilterName: option && option.value, error: null});
  }

  dateToEpoch(date) {
    let d = date ? moment(date, DateTimeHelper.getDateFormat(), DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true) : null;
    return DateTimeHelper.ConvertFromDate(d);
  }

  onDateChange = (date, isStart) => {
    if(!date || !date._isValid){
      this.setState(isStart ? { selectedStartDate: null } : { selectedEndDate: null });
      return;
    }
    let epoch = this.dateToEpoch(date);

    this.setState(isStart ? { selectedStartDate: epoch } : { selectedEndDate: epoch });
  }

  onKeyDownHandler = (e) => {
    e.preventDefault();
  }

  //filters dates that are after the selected end date
  filterFromDates = (date) => {
    let endDate = this.state.selectedEndDate && this.state.selectedEndDate.epochDateTime;
    if (endDate)
      return this.dateToEpoch(date).epochDateTime <= endDate;
    return true;
  }

  //filters dates that are before the selected start date
  filterToDates = (date) => {
    let startDate = this.state.selectedStartDate && this.state.selectedStartDate.epochDateTime;
    if (startDate)
      return this.dateToEpoch(date).epochDateTime >= startDate;
    return true;
  }

  renderDatePicker = (initialDate, pickerType) => {
    let isStartDatePicker = pickerType === PickerType.start, { isTime } = this.props;
    return (
      <div className="horizon-filter-datepicker-wrapper">
        <span>
          {this.labels.get(isStartDatePicker ? 'horizon.from' : 'horizon.to')}
        </span>
        <div className="horizon-filter-datepicker">
          <PL_DatePicker
            id={`datePicker${pickerType}`}
            ref={(r) => { if (isStartDatePicker) { this.startDatePickerRef = r; } else { this.endDatePickerRef = r; } }}
            key={isStartDatePicker ? this.state.dummyKey + '0' : this.state.dummyKey + '1'}
            popperPlacement="auto"
            validate={Validation.date}
            showTimeSelect={isTime}
            dateFormat={isTime ? DateTimeHelper.getDateTimeFormat() : DateTimeHelper.getDateFormat()}
            timeCaption="time"
            timeIntervals={10}
            initialDate={initialDate}
            onChangeCallback={ (date) => { this.onDateChange(date, isStartDatePicker); } }
            onKeyDown={this.onKeyDownHandler}
            filterDate={isStartDatePicker ? this.filterFromDates : this.filterToDates}
            isClearable
          />
        </div>
      </div>

    );
  }

  render() {
    let { select, options, fieldName } = this.props;
    let initialStartDate = this.startDate ? DateTimeHelper.ConvertToDate(this.startDate).startOf('day') : null;
    let initialEndDate = this.endDate ? DateTimeHelper.ConvertToDate(this.endDate).startOf('day') : null;

    return (
      <FilterLayout className="date-select-filter"
                    columnWidth={this.props.column.actualWidth}
                    filterWidth={select ? 600 : 400}
                    filterAlignment={this.props.filterAlignment}
      >
        {
          select ?
          <div className='filter-name-select-container'>
            <ComboBox
              id={`${fieldName}-horizon-filter-name-select`}
              options={options}
              onChange={this.onSelectChange}
              name={'filterNameSelection'}
            />
          </div>
          :
          null
        }
        <div className={select ? 'datepicker-container-with-select' : ''}>
          {this.renderDatePicker(initialStartDate, PickerType.start)}
          {this.renderDatePicker(initialEndDate, PickerType.end)}
        </div>
        <FilterFooter
          okText={this.labels.get('submit') || 'FILTER'}
          cancelText={this.labels.get('cancel') || 'CANCEL'}
          onCancel={this.cancel.bind(this)}
          onOk={this.apply.bind(this)}
          showSelectedCount={!!this.state.error}
          statusText={this.state.error}
          okDisabled={!!this.state.error}
        />
      </FilterLayout>
    );
  }
}

PL_HorizonFilter.propTypes = {
  //in columnConfig.filterProps, you can choose to have a select for filterName values.
  //if you choose so, provide:
  /*
    filterProps: {
      select: true,
      options: [
        { label: option1Label, value: option1FilterName },
        ...
      ]
    }
   */
  select: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })),
  filterName: PropTypes.string //only required if select is false, otherwise the filterNames are passed in the value field of the options array items
}
