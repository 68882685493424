import Network from 'infrastructure/js/modules/network';
import { gridsNames } from '../../../../enums/gridsNames';
import { dialogsNames } from '../../../../enums/dialogsNames.js';
import * as daHelper from '../../../Dialogs/dialogActionsHelper.js';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { createAlertRule, updateAlertRule } from '../../../../services/Alerts/misplacedMaterialAlertsRulesService.js';
import { api as messageDialogApi } from '../../../../actions/MessageDialog/messageDialogActions.js';
import * as locationsService from '../../../../services/Locations/locationsService';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.MISPLACED_MATERIAL_ALERT_RULE_DIALOG),
};

export const actions = {
  ...daHelper.getActions(dialogsNames.MISPLACED_MATERIAL_ALERT_RULE_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function (selectedRules) {
      const usedOrigins = getState()
        .grid.get(gridsNames.ADMINISTRATION_ALERTS_MISPLACED_MATERIALS)
        ?.get('rows')
        ?.map((row) => row.origin);

      const usedDestinations = getState()
        .grid.get(gridsNames.ADMINISTRATION_ALERTS_MISPLACED_MATERIALS)
        ?.get('rows')
        ?.map((row) => row.destinations)
        ?.toJS()
        ?.flat();

      dispatch(actions.show({ selectedRules, usedOrigins, usedDestinations }));
      dispatch(actions.setBusy(true));

      locationsService.fetchActiveLocations().then((response) => {
        dispatch(actions.setBusy(false));

        if(!Network.isResponseValid(response)) {
          console.error('Fetch all active stations/locations failed');
          return {success: false};
        }

        let locations = response.dataList?.map((item) => {return {value: item.id, label: item.name, data: item} }) || [];

        dispatch(actions.fetchDialogDataReady({ locations }));
      });
    };
  },

  submit(dispatch, getState) {
    return function (data, isEditMode, reloadParentComponent) {
      dispatch(actions.setBusy(true));
      const alertSettingId = getState().administration.getIn(['settingsPage', 'commonAlertsView', 'misplacedMaterialData']).id;
      const submitMethod = isEditMode ? updateAlertRule : createAlertRule;

      submitMethod(data, alertSettingId)
        .then((response) => {
          dispatch(actions.setBusy(false));

          let validations = dialogHelper.getResponseValidationDetails(response);
          if (validations) {
            dispatch(actions.setValidationWarningsData(validations));
            return response; //TODO: L test it!
          }

          dispatch(actions.hide());

          if (!Network.isResponseValid(response)) {
            console.error('submit misplaced material alert rule failed ', response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return response; //TODO: L test it!
          }

          if (reloadParentComponent) {
            reloadParentComponent();
          }
        })
        .catch((error) => {
          console.error('submit misplaced material alert rule failed ', error);
          dispatch(actions.setBusy(false));
          dispatch(actions.hide());
        });
    };
  },
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};
