import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { gridsNames } from '../../../enums/gridsNames';
import { jsxActions as orgsDashboardViewGridActions } from '../../../actions/Administration/OrganizationsPage/orgsDashboardViewGridActions';
import { jsxActions as orgsDashboardViewActions } from '../../../actions/Administration/OrganizationsPage/orgsDashboardViewActions';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as exportActions} from '../../../actions/Export/exportActions';
import OrgsDashboardView from '../../../components/Administration/OrganizationsPage/OrgsDashboardView/orgsDashboardView.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      orgsDashboardViewGridActions: bindActionCreators(orgsDashboardViewGridActions, dispatch),
      orgsDashboardViewActions: bindActionCreators(orgsDashboardViewActions, dispatch),
      fetchEntitiesActions: bindActionCreators(fetchEntitiesActions, dispatch),
      exportActions:      bindActionCreators(exportActions, dispatch)
    },
  };
}

let OrgsDashboardViewContainer = connect((state) => {
  let grid = state.grid.get(gridsNames.ADMINISTRATION_ORGS_DASHBOARD) ? state.grid.get(gridsNames.ADMINISTRATION_ORGS_DASHBOARD) : null;
  return {
    sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
  };
}, mapDispatchToProps)(OrgsDashboardView);

export default OrgsDashboardViewContainer;
