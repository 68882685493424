import Network from 'infrastructure/js/modules/network';
import * as lotsService from '../../../services/Lots/lotsService';
import * as materialsService from '../../../services/Materials/materialsService';
import * as manufacturersService from '../../../services/Manufacturers/manufacturersService';
import * as tagsService from '../../../services/Tags/tagsService';
import * as projectsService from '../../../services/Projects/projectsService';
import * as rollsService from '../../../services/Rolls/rollsService';
import * as spoolsService from '../../../services/Spools/spoolsService';
import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi } from '../locationPageActions.js';
import DateTimeHelper from '../../../../../infrastructure/js/utils/dateTimeHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as additionalFieldsHelper from '../../../components/Common/Helpers/AdditionalFieldsHelper';
import { getEnumValue, enumTypes } from '../../../utils/enumHelper';
import * as deviceAppsService from '../../../services/Rfid/deviceAppsService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_SPOOL_DIALOG_SUBMIT_IN_PROGRESS: 'CREATE_SPOOL_DIALOG_SUBMIT_IN_PROGRESS',
  CREATE_SPOOL_DIALOG_SUBMIT_FINISHED: 'CREATE_SPOOL_DIALOG_SUBMIT_FINISHED',
  CREATE_SPOOL_DIALOG_SHOW_DIALOG: 'CREATE_SPOOL_DIALOG_SHOW_DIALOG',
  CREATE_SPOOL_DIALOG_HIDE_DIALOG: 'CREATE_SPOOL_DIALOG_HIDE_DIALOG',
  CREATE_SPOOL_DIALOG_FETCH_DATA_READY: 'CREATE_SPOOL_DIALOG_FETCH_DATA_READY',
  // CREATE_SPOOL_DIALOG_GENERATED_ID:                 'CREATE_SPOOL_DIALOG_GENERATED_ID',
  CREATE_SPOOL_DIALOG_FETCH_WEIGHT_IN_PROGRESS: 'CREATE_SPOOL_DIALOG_FETCH_WEIGHT_IN_PROGRESS',
  CREATE_SPOOL_DIALOG_FETCH_WEIGHT_READY: 'CREATE_SPOOL_DIALOG_FETCH_WEIGHT_READY',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  createSpoolInProgress: function (payload) {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_SUBMIT_IN_PROGRESS, payload: payload };
  },
  createSpoolFinished: function (payload) {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_SUBMIT_FINISHED, payload: payload };
  },
  show: function (payload) {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_SHOW_DIALOG, payload: payload };
  },
  hide: function (payload) {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_HIDE_DIALOG, payload: payload };
  },
  fetchDialogDataReady: function (payload) {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_FETCH_DATA_READY, payload: payload };
  },
  // generatedRollId: function(payload) {
  //   return {type: actionTypes.CREATE_SPOOL_DIALOG_GENERATED_ID, payload: payload };
  // },
  fetchWeightInProgress: function () {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_FETCH_WEIGHT_IN_PROGRESS };
  },
  fetchWeightReady: function (payload) {
    return { type: actionTypes.CREATE_SPOOL_DIALOG_FETCH_WEIGHT_READY, payload: payload };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchDialogData = function () {
  return function (dispatch, getState) {
    api.fetchDialogData(dispatch, getState)();
  };
};

jsxActions.generateSpoolId = function () {
  return function (dispatch, getState) {
    return api.generateSpoolId(dispatch, getState)();
  };
};

jsxActions.submit = function (data, messageDialogBuilder) {
  return function (dispatch, getState) {
    api.submit(dispatch, getState)(data, messageDialogBuilder);
  };
};
// jsxActions.show = function() {
//   return function(dispatch, getState) {
//     dispatch(actions.show());
//   };
// };

jsxActions.fetchWeight = function () {
  return function (dispatch, getState) {
    return deviceAppsService.getWeight().then((response) => {
      let weightData = { weight: null, error: null };

      if (!Network.isResponseValid(response)) {
        weightData.error = 'There was an error retreating the value, please try again';
        dispatch(actions.fetchWeightReady(weightData));
        return;
      }

      weightData.weight = response.data.jobStatus == 'COMPLETED' ? response.data.payloadData : null;
      weightData.error =
        response.data.jobStatus == 'COMPLETED' ? null : response.data.genericErrorCode;
      dispatch(actions.fetchWeightReady(weightData));
      return weightData;
    });
  };
};

jsxActions.hide = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
  };
};

jsxActions.fetchAdditionalFields = function () {
  return function (dispatch, getState) {
    return api.fetchAdditionalFields(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.show = function (dispatch, getState) {
  return function () {
    dispatch(actions.show());
  };
};

api.fetchDialogData = function (dispatch, getState) {
  return function () {
    let fetchMaterialsPromise = materialsService.fetchActiveMaterials();
    let fetchLotsPromise = lotsService.fetchLots();
    let fetchManufacturersPromise = manufacturersService.fetchManufacturers();
    let fetchTagsPromise = tagsService.fetchTags();
    let fetchProjectsPromise = projectsService.fetchActiveProjects();
    let fetchInspectionStatusesPromise = rollsService.fetchInspectionStatuses();
    let fetchAdditionalFieldsPromise = additionalFieldsService.fetchAdditionalFields({
      entityType: getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'),
    });

    return Promise.all([
      fetchMaterialsPromise,
      fetchLotsPromise,
      fetchManufacturersPromise,
      fetchTagsPromise,
      fetchProjectsPromise,
      fetchInspectionStatusesPromise,
      fetchAdditionalFieldsPromise,
    ]).then((allResults) => {
      if (
        !Network.isResponseValid(allResults[0]) ||
        !Network.isResponseValid(allResults[1]) ||
        !Network.isResponseValid(allResults[2]) ||
        !Network.isResponseValid(allResults[3]) ||
        !Network.isResponseValid(allResults[4]) ||
        !Network.isResponseValid(allResults[5]) ||
        !Network.isResponseValid(allResults[6])
      ) {
        console.error('Fetch Spool data failed');
        messageDialogApi.responseError(dispatch, getState)(null);
        return { success: false };
      }

      let materials = allResults[0];
      let lots = allResults[1];
      let manufacturers = allResults[2];
      let tags = allResults[3];
      let projects = allResults[4];

      let InspectionStatuses = [];
      if (allResults[5].data && allResults[5].data.items) {
        let labels = createLabelHelper('');
        InspectionStatuses = allResults[5].data.items.map((obj) => {
          return { value: obj.name, label: labels.get(obj.labelKey) };
        });
      }
      let additionalFields = allResults[6];

      let dialogData = {
        materials: materials,
        lots: lots,
        manufacturers: manufacturers,
        tags: tags,
        projects: projects,
        InspectionStatuses: InspectionStatuses,
        additionalFields: additionalFields,
      };

      dispatch(actions.fetchDialogDataReady(dialogData));
      return dialogData;
    });
  };
};

api.fetchInspectionStatuses = function (dispatch, getState) {
  return function () {
    rollsService.fetchInspectionStatuses().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Fetch Inspection Statuses Failed');
        return { success: false };
      }

      let payload = [];
      if (response.data && response.data.items) {
        let labels = createLabelHelper('');
        payload = response.data.items.map((obj) => {
          return { value: obj.name, label: labels.get(obj.labelKey) };
        });
      }

      dispatch(actions.fetchInspectionStatusesReady(payload));
    });
  };
};

api.generateSpoolId = function (dispatch, getState) {
  return function () {
    return spoolsService.generateSpoolId().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Generate Spool Id Failed', response);
        return { success: false };
      }
      return response.data;
    });
  };
};

api.submit = function (dispatch, getState) {
  return function (data, messageDialogBuilder) {
    let dialogData = { ...data };
    dispatch(actions.createSpoolInProgress());

    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let additionalFields = additionalFieldsHelper.convertToAdditionalFields(
      data['additionalFields'],
      data.additionalFieldsDefinitions
    );

    let submitData = {
      materialId: data.material.value,
      lot: convertNewOption(data.lot),
      businessId: data.spoolId,
      length: data.length,
      weight: data.weight,
      width: data.width,
      manufacturer: convertNewOption(data.manufacturer),
      dateManufacturer: DateTimeHelper.ConvertFromDate(data.dateOfManufacturer),
      expirationDate: DateTimeHelper.ConvertFromDate(data.expirationDate),
      maxExposureTimeBond: data.maxExposureTimeBond,
      maxExposureTimeCure: data.maxExposureTimeCure,
      maxStorageTemp: data.maxStorageTemp,
      locationId: locationId,
      tags: data.tags ? data.tags.map(({ value }) => ({ id: value })) : [],
      projectId: data.project ? data.project.value : null,
      inspectionStatus: data.inspectionStatus ? data.inspectionStatus.value : null,
      batchNum: data.batchNum,
      additionalFieldsValues: additionalFields,
    };

    spoolsService.createSpool(submitData, locationId).then((response) => {
      dispatch(actions.createSpoolFinished(response));
      dispatch(actions.hide(response));

      if (!Network.isResponseValid(response)) {
        console.error('Create Spool failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }
      locationPageApi.reload(dispatch, getState)();

      // Prepare and open Confirmation Message Dialog.
      let messageDialogDescriptor = messageDialogBuilder(
        dialogData,
        messageDialogApi.close(dispatch, getState)
      );

      messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
    });
  };
};

function convertNewOption(data) {
  if (!data || data.label == null) {
    return null;
  }

  return {
    id: data.value !== data.label ? data.value : null,
    name: data.label,
  };
}
