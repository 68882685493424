// @ts-check

import * as ActivityTypeHelper from '../../../utils/activityTypeHelper';
import * as AssetHelper from '../../../utils/assetHelper';
import React from "react";
import * as ReactDOMServer from 'react-dom/server';
import Label from "../../../../../infrastructure/js/components/Label/label";
import { createLabelHelper } from "../../../../../infrastructure/js/utils/labelHelper";
import Link from "../../Common/Layout/Link/link";
import { navigationStates } from "../../../enums/navigationStates";
import { COLLISION_RANKING_TIERS } from '@mappedin/mappedin-js';




/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageProps} LiveMapPageProps
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapMode} MapMode
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LocationTooltipState} LocationTooltipState
 * @typedef {import("@mappedin/mappedin-js/renderer/internal").SmartTooltip} SmartTooltip
 * 
 */



let assetTypeLabels = createLabelHelper('');


export class MapLocationTooltipLogic {


  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   */
  static process = (refContext) => {

    let sData = refContext.current.refSData.current;
    if (!sData) {
      return;
    }

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    let polyData = refContext.current.refPolyData.current;
    if (!polyData) {
      return;
    }


    // let sLocationDetails = sData.get('locationDetails');
    let sLocationDetails = sData.normal.locationDetails;

    // /** @type {LocationTooltipState} */
    let sLocationTooltipState = sData.normal.locationTooltipState;


    this.removeCurrentLocationTooltip(refContext);

    if (sLocationTooltipState === "HIDDEN") {
      return;
    }


    let entrance = refContext.current.refCurrentMapNode.current;
    if (!entrance) {
      return;
    }

    let parentPolygon = entrance.polygon;
    refContext.current.refCurrentLocationTooltip.current.parentPolygon = parentPolygon;



    // Highlight current polygon
    mapView.setPolygonColor(parentPolygon, "#6BC0F4");
    mapView.setPolygonHoverColor(parentPolygon, "#6BC0F4");


    /** @type {SmartTooltip | null} */
    let tooltipObject = null; // Could be used to remove the specific tooltip later.

    if (sLocationTooltipState === "LOADING") {
      let tooltipHtml = getLocationTooltipLoadingHtml(sLocationDetails);
      tooltipObject = mapView.createTooltip(entrance, tooltipHtml, { collisionRank: COLLISION_RANKING_TIERS.ALWAYS_VISIBLE });
    }

    if (sLocationTooltipState === "VISIBLE") {
      let tooltipHtml = getLocationTooltipHtml(sLocationDetails);
      tooltipObject = mapView.createTooltip(entrance, tooltipHtml, { collisionRank: COLLISION_RANKING_TIERS.ALWAYS_VISIBLE });
    }

    refContext.current.refCurrentLocationTooltip.current.tooltipObj = tooltipObject;

  }


  /**
  * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
  */
  static removeCurrentLocationTooltip = (refContext) => {

    let mapView = refContext.current.refMapView.current;
    if (!mapView) {
      return;
    }

    let polyData = refContext.current.refPolyData.current;
    if (!polyData) {
      return;
    }


    // Fallback - remove all tooltips.
    let existingTooltip = refContext.current.refCurrentLocationTooltip.current.tooltipObj;
    if (!existingTooltip) {
      mapView.removeAllTooltips();
      refContext.current.refCurrentLocationTooltip.current.tooltipObj = null;
      refContext.current.refCurrentLocationTooltip.current.parentPolygon = null;
      return;
    }

    // Remove original location tooltip.
    mapView.removeTooltip(existingTooltip);
    refContext.current.refCurrentLocationTooltip.current.tooltipObj = null;


    // Reset the color of the parent polygon to original.
    let parentPolygon = refContext.current.refCurrentLocationTooltip.current.parentPolygon;
    if (!parentPolygon) {
      return;
    }


    let polyInfo = polyData.getPolygonInfoById(parentPolygon.id);
    if (!polyInfo) {
      return;
    }

    let originalColor = polyInfo.normalColor;
    if (originalColor) {
      mapView.setPolygonColor(parentPolygon, originalColor);
      mapView.setPolygonHoverColor(parentPolygon, originalColor);
    }

    refContext.current.refCurrentLocationTooltip.current.parentPolygon = null;

  }

}


////////////////////////////////////////
// Helper functions.
//

/**
 * @param {any} sLocationDetails
 */
let getLocationTooltipLoadingHtml = (sLocationDetails) => {
  return `<span class="messageTooltip"> LOADING... </span>`;
}

/**
 * @param {any} sLocationDetails
 */
let getLocationTooltipErrorHtml = (sLocationDetails) => {
  return `<span class="messageTooltip"> No data </span>`;
}



/**
 * @param {any} sLocationDetails
 */
let getLocationTooltipHtml = (sLocationDetails) => {

  if (sLocationDetails === null) {
    return getLocationTooltipErrorHtml(sLocationDetails);
  }


  // console.log('xxxxxxxxxxxxx TOOLTIP ===> sLocationDetails ===> ', sLocationDetails);

  let name = sLocationDetails.name;

  let numberOfAsstes = sLocationDetails.numberOfAsstes;
  let numberOfResources = sLocationDetails.numberOfResources;
  let numberOfWorkOrders = sLocationDetails.numberOfWorkOrders;





  let tooltipBodyContent = ``;
  tooltipBodyContent += createDataLine('Assets', numberOfAsstes, 'pl-assets');
  tooltipBodyContent += createDataLine('Resources', numberOfResources, 'pl-resources');
  tooltipBodyContent += createDataLine('Work Orders', numberOfWorkOrders, 'pl-header-icon-work_order');

  let headerContentHtml = ReactDOMServer.renderToString(getHeaderContent(sLocationDetails));
  let footerContentHtml = ReactDOMServer.renderToString(getFooterContent(sLocationDetails));


  let tooltipHtml = `
    <div class="stationTooltip">
      
      <div class="tooltip-header">
        ${headerContentHtml}
      </div>
      
      <hr class="tooltip-header-hr" />
      
      
      <div class="tooltip-body">${tooltipBodyContent}</div>
      
      
      <hr class="tooltip-footer-hr" />
      
      <div class="tooltip-footer">
        ${footerContentHtml}        
      </div>
      
    </div>`;

  return tooltipHtml;
};


/**
 * @param {string} name
 * @param {string} value
 * @param {string | null} iconClass
 */
let createDataLine = (name, value, iconClass) => {

  if (!iconClass) {
    iconClass = null;
  }

  return `
      <div class="tooltip-data-line">
        <span class="tooltip-data-line-icon pl ${iconClass}"></span>
        <span class="tooltip-data-line-value">${value}</span>
        <span class="tooltip-data-line-label">${name}</span>
      </div>`;
}


/**
 * @param {any} sLocationDetails
 */
let getLocationIconClass = (sLocationDetails) => {
  let isSection = sLocationDetails.section;
  if (isSection) {
    return `pl pl-section-sm`;
  }
  let isStation = sLocationDetails.station;
  if (isStation) {
    return `pl location-icon pl pl-station-icon`;
  }
  return `pl pl-icon pl-location-icon-blue`;
}


/**
 * @param {any} sLocationDetails
 */
let getLocationAlertIconClass = (sLocationDetails) => {
  let alertSeverity = sLocationDetails.alertSeverity;
  if (alertSeverity === 'WARNING') {
    return `pl alert-icon pl-warning-icon`;
  }
  if (alertSeverity === 'ERROR') {
    return `pl alert-icon pl-error-icon`;
  }
  return ``;
}


/**
 * @param {any} assetType 
 * @returns {JSX.Element | null}
 */
let getAssetAndIcon = (assetType) => {
  if (!assetType) {
    return null;
  }
  let assetIcon = assetType ? AssetHelper.getAssetIconComponent(assetType) : '';
  return (
    <div className="asset-with-icon">
      {assetIcon}
      <span className={'asset-type'}>{assetType}</span>
    </div>
  )

}


/**
 * 
 * @param {any} item 
 * @returns {JSX.Element}
 */
let getFooterContent = (item) => {

  let activityData = ActivityTypeHelper.GetActivityTypeDetails(item.activityType, item.activityTypeDisplayKey);
  let assetType = item.assetTypeDisplayKey ? assetTypeLabels.get(item.assetTypeDisplayKey) : '';

  return (
    <>
      <div className="activity-type">
        <span className={'icon ' + activityData.icon}> </span>
        <Label className="activity-inline-left" text={activityData.description} />
      </div>
      <div className="second-row">
        <Label className="activity-inline-left" text={item.m_ActivityTime} />
        {/* <Label className="activity-inline-left" text={'23/08/2017 16:49'} /> */}
        {getAssetAndIcon(assetType)}
      </div>

    </>
  );
};

/**
 * 
 * @param {any} item 
 * @returns {JSX.Element}
 */
let getHeaderContent = (item) => {

  let locationType = navigationStates.LOCATION;
  if (item.station) {
    locationType = navigationStates.STATION;
  }



  // Sections should not have a link to the location details page.
  if (item.section) {
    return (
      <>
        <i className={"tooltip-header-icon" + ' ' + getLocationIconClass(item)}></i>
        <span className="tooltip-header-title">{item.m_name}</span>
        <i className={"tooltip-header-alert-icon" + ' ' + getLocationAlertIconClass(item)}></i>
      </>
    );
  }


  return (
    <>
      <i className={"tooltip-header-icon" + ' ' + getLocationIconClass(item)}></i>
      <Link className="tooltip-header-title" label={item.m_name} id={item.id} type={locationType} />
      <i className={"tooltip-header-alert-icon" + ' ' + getLocationAlertIconClass(item)}></i>
    </>
  );

};




