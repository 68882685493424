import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}     from 'infrastructure/js/components/Dialog/dialog';
import OpenWorkOrderGrid from '../../Common/Grids/CommonWorkOrdersGrid/commonWorkOrdersGrid';
import EditWoAttributesDialog       from '../../Dialogs/EditWoAttributesDialog/editWoAttributesDialog';
import EditWorkOrdersStatusDialog from  '../../Dialogs/EditWorkOrdersStatusDialog/editWorkOrdersStatusDialog';
import {gridsNames} from '../../../enums/gridsNames';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import CreateWorkOrderDialog from '../../Dialogs/CreateWorkOrderDialog/createWorkOrderDialog';
import {ImportAcceptFileTypes, ImportTypes} from '../../../enums/importTypes';
import ImportAttributesUpdateWizard from '../../Wizards/ImportAttributesUpdate/importAttributesUpdateWizard';
require('./openWorkOrdersPage.scss');

export default class OpenWorkOrdersPage extends Component {

  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ACTIVE,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('WO')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getMarkAsCompleteConfig = () => {
    let config = this.getDialogConfig();
    config.status = getEnumValue(enumTypes.WORK_ORDER_STATUS)('COMPLETED');
    return config;
  };

  getMarkAsCanceledConfig = () => {
    let config = this.getDialogConfig();
    config.status = getEnumValue(enumTypes.WORK_ORDER_STATUS)('CANCELED');
    return config;
  };

  getImportConfig = () => {
    return {
      importTypes: [ImportTypes.IMPORT_WO],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: this.reloadPage
    };
  };

  getImportAttributesConfig = () => {
    let importType =  PermissionManager.isPartTypeOriented() ?
      getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_PART_TYPE') :
      getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_KIT_TYPE');
    return {importType};
  };

  hasKebabItems() {
    return (
      PermissionManager.hasImportAssetsPermissions() ||
      PermissionManager.hasCreateAssetsPermissions() ||
      PermissionManager.hasEditAttributesPermissions() ||
      PermissionManager.hasMarkWoCanceledPermissions()
    )
  }

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        ...(PermissionManager.hasMarkWoCompletePermissions() ? [{
          id:'markComplete',
          label: headerItemsLabels.MARK_COMPLETE,
          icon: 'pl pl-mark-complete',
          counter: sSelectedRowsData.size,
          disabled: !sHasRows,
          action: actions.header.onMarkCompleteClick,
          actionData: this.getMarkAsCompleteConfig()
        }] : []),
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_OPEN_WORK_ORDER}
        }
      ],

      kebab: {
        disabled: !this.hasKebabItems(),
        menuItems: [
          {
            id: 'importWos',
            label: headerItemsLabels.IMPORT_WORK_ORDERS,
            action: actions.header.importItems,
            actionData: this.getImportConfig(),
          },
          ...(PermissionManager.hasCreateAssetsPermissions() ? [{
            id: 'createWo',
            label: headerItemsLabels.CREATE_WORK_ORDER,
            action: actions.header.onMenuItemCreateWoClick,
          }] : []),
          ...(PermissionManager.hasEditAttributesPermissions() ? [{
            id: 'importAttributesUpdate',
            label: headerItemsLabels.IMPORT_ATTRIBUTES_UPDATE,
            action: actions.header.onMenuItemImportAttributesUpdateClick,
            actionData: this.getImportAttributesConfig(),
          }] : []),
          ...(PermissionManager.hasEditAttributesPermissions() ? [{
            id: 'editAttributes',
            label: headerItemsLabels.EDIT_ATTRIBUTES,
            action: actions.header.onMenuItemEditWoAttributesClick,
            disabled: !sHasRows,
            actionData: this.getDialogConfig(),
          }] : []),
          ...(PermissionManager.hasMarkWoCanceledPermissions() ? [{
            id:'markCanceled',
            label: headerItemsLabels.MARK_CANCELED,
            disabled: !sHasRows,
            action: actions.header.onMarkCanceledClick,
            actionData: this.getMarkAsCanceledConfig()
          }] : []),
        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderEditWoAttributesDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={EditWoAttributesDialog}
                        show={this.props.sEditWoAttributesData.get('show')}
                        actions={this.props.actions.editWoAttributesActions}
                        sData={this.props.sEditWoAttributesData}
                        defaultWorkOrderType={this.props.sDefaultWorkOrderType}
                        sLoggedInUser = {this.props.sLoggedInUser}
                        reloadParentComponent={this.reloadPage}/>
    );
  };




  renderCreateWorkOrderDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={CreateWorkOrderDialog}
                        defaultWorkOrderType={this.props.sDefaultWorkOrderType}
                        show={this.props.sCreateWorkOrderDialogData.get('show')}
                        sData={this.props.sCreateWorkOrderDialogData}
                        actions={{...this.props.actions.createWorkOrderDialogActions, ...this.props.actions.fetchEntitiesActions}}
                        sLoggedInUser={this.props.sLoggedInUser}
                        reloadParentComponent={this.reloadPage} />
    );
  };
 renderEditWorkOrdersStatusDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={EditWorkOrdersStatusDialog}
                        show={this.props.sEditWorkOrdersStatusData.get('show')}
                        sData={this.props.sEditWorkOrdersStatusData}
                        actions={this.props.actions.editWorkOrdersStatusActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  renderImportAttributesUpdateWizardDialog = () => {
    let sData = this.props.sImportAttributesUpdateWizardData;

    if (sData && sData.get('show')) {
      return (
        <ImportAttributesUpdateWizard
          importType={sData.get('importType')}
          actions={this.props.actions.importAttributesUpdateWizardActions}
          sData={sData}
          reloadParentComponent={this.reloadPage}
        />
      );
    }
    return null;
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className='open-work-orders-page'>
        <Header {...headerItems}/>
        <OpenWorkOrderGrid gridName={gridsNames.PREDEFINED_LIST_OPEN_WORK_ORDER}
                           actions={{...this.props.actions.openWorkOrdersGridActions, ...this.props.actions.fetchEntitiesActions}}
                           showHasAssetsColumn={false}
                           defaultWorkOrderType={this.props.sDefaultWorkOrderType}
                           showPickDate={PermissionManager.getOrgPreferences().woPickDateSupported}
                           showTasksStatus={PermissionManager.getOrgPreferences().schedulerEnabled}
        />

        { this.renderCreateWorkOrderDialog() }
        { this.renderEditWoAttributesDialog() }
        { this.renderEditWorkOrdersStatusDialog() }
        { this.renderImportAttributesUpdateWizardDialog() }
      </div>
    )
  }
};

OpenWorkOrdersPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool,
  sSelectedRowsData: PropTypes.object,
};




