
const charCodeOfA = 'A'.charCodeAt(0);
const alphabetLength = 'Z'.charCodeAt(0) - charCodeOfA + 1;


export const LabelKeys = {
  ROLL: 'roll',
  SPOOL: 'spool',
  RESIN: 'resin',
  KIT: 'kit',
  GROUP: 'group',
  GROUP_TYPE: 'groupType',
  TOOL: 'tool',
  WO: 'workorder',
  TASK: 'task',
  KIT_TYPE: 'kitType',
  TOOL_TYPE: 'toolType',
  MATERIAL_TYPE: 'materialType',
  PART_TYPE: 'partType',
  PROJECT: 'project',
  OPERATION: 'operation',
  ROLL_USAGE: 'rollUsage',
  SHIPMENT: 'shipment',
  ASN_SHIPMENT: 'asnShipment',
};

export function numberToLetters(nNum) {
  if (nNum <= alphabetLength) {
    return convertNumberToLetter(nNum);
  }
  else {
    let firstNumber = Math.floor((nNum - 1) / alphabetLength);
    let firstLetter = convertNumberToLetter(firstNumber);

    let secondNumber = (nNum % alphabetLength) || alphabetLength;
    let secondLetter = convertNumberToLetter(secondNumber);

    return firstLetter + secondLetter;
  }
}

function convertNumberToLetter(nNum) {
  let charCode = charCodeOfA + nNum - 1;
  return String.fromCharCode(charCode);
}
