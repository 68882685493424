import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MultiEntitiesMultiSelect from '../../../../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import DatePickerTouch from 'infrastructure/js/components/controls/DatePickerTouch/datepickerTouch';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { getDateFormatIcon } from 'infrastructure/js/components/controls/controlsUtils';
import { enumTypes, getEnumValue } from '../../../../../../utils/enumHelper';
import moment from 'moment';

import './assetsCuringSection.scss';


class AssetsCuringSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = this.props.labels;

    this.hasPreSelectedWo = !!this.props.workOrder;

    this.orgTime = DateTimeHelper.ConvertToDate({ epochDateTime: this.props.orgTime });

    this.cachedKitLogs = {};

    this.state = {
      dummyKey: 0,
      datePickerDummyKey: 0,
      lastOperationTime: 0
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.workOrder && nextProps.selectedKits && nextProps.selectedKits.length) {
      let curKits = this.props.selectedKits, nextKits = nextProps.selectedKits;
      let curCount = curKits?.length, nextCount = nextKits?.length;
      if (nextCount) {
        //at least one kit selected
        if ((!curCount && 0 < nextCount) || (curKits.length !== nextKits.length) || (!this.props.workOrder)) {
          //check if last selected kit already exists in the cache
          //this will also be true when a kit is deselected, so no repeat server calls
          let kitId = nextKits[nextKits.length - 1].value;
          if (this.cachedKitLogs[kitId]) {
            //new kit already exists in cache, just recalculate
            this.setState({ selectedKits: nextKits }, () => { this.calculateLastOperationTime(nextKits); });
          }
          else {
            //this means a new kit was selected
            this.getKitActivityLog(kitId, nextKits);
          }
        }
      }
    }
    else {
      //discard last operation time
      this.setState({ lastOperationTime: 0 });
    }
  }

  getKitActivityLog = (kitId, selectedKits) => {

    this.props.actions.getAssetActivityLog(
      getEnumValue(enumTypes.OBJECT_TYPE)("KIT"),
      kitId,
      (data, error) => { this.getKitActivityLogCallback(selectedKits, kitId, data, error); }
    );
  };

  getKitActivityLogCallback = (selectedKits, kitId, data, error) => {
    if (error) {
      return;
    }

    this.cachedKitLogs[kitId] = data;

    this.calculateLastOperationTime(selectedKits);
  };

  calculateLastOperationTime = (selectedKits) => {
    let lastOperationTime = 0;
    for (let i in selectedKits) {
      if (selectedKits.hasOwnProperty(i) && selectedKits[i]) {
        let kitLog = this.cachedKitLogs[selectedKits[i].value];
        for (let j in kitLog) {
          if (kitLog.hasOwnProperty(j) && kitLog[j].time && kitLog[j].activityType) {
            let kitLogItem = kitLog[j];
            if (kitLogItem.time.epochDateTime && lastOperationTime < kitLogItem.time.epochDateTime && (kitLogItem.activityType === "Asset Added" || kitLogItem.activityType === "Shelf Life Update")) {
              lastOperationTime = kitLog[j].time.epochDateTime;
            }
          }
          else {
            /*-*/ //raise error that one of the kitLog activities is broken, or that one of the requests failed
            console.error(kitLog);
          }
        }
      }
    }
    this.setState({ lastOperationTime });
  };

  getReduxFieldName = (fieldName) => {
    return this.props.name + "." + fieldName;
  };

  maxDateValidate = Validation.maxDate(this.orgTime);

  renderCuringTimeSection = () => {

    if (!PermissionManager.hasReportCuringInRetrospectPermissions()) {
      return null;
    }
    return <div className="pl-datepicker datepicker-section">
      <label className="curing-picker-header">
        {this.labels.get("curingTime")}
        {getDateFormatIcon()}
      </label>

      <DatePickerTouch id={this.getReduxFieldName('curingTime')}
                  name={this.getReduxFieldName('curingTime')}
                  key={this.state.datePickerDummyKey}
                  showTimeSelect
                  dateFormat={DateTimeHelper.getDateTimeFormat()}
                  timeCaption="time"
                  popperPlacement="right"
                  timeIntervals={1}
                  placeholderText={this.labels.get('now')}
                  isClearable={true}
                  fixedHeight
                  filterDate={this.filterDate}
        //filterTime={this.filterTime}
                  minTime={this.getMinTime()}
                  maxTime={this.getMaxTime()}
        //validate={[this.maxDateValidate, this.validateCuringDate]}
                  onChangeCallback={this.onCuringDateTimeChange}
                  selected={this.state.selectedDate}
      />
    </div>;
  };

  dateToGMTEpoch(date) {
    if (date._d) {
      date = date._d;
    }
    //getTimeZoneOffset returns the number of minutes by which the date is ahead of GMT
    //for example, adding that number to a date that is locally midnight would return GMT midnight
    //the date picker gives dates that are in the Org's locale, so subtracting the offset will
    //return the actual number of seconds since midnight, 1/1/1970 at UTC
    return Math.floor(date.getTime() / 1000) - (date.getTimezoneOffset() * 60);
  }

  filterDate = (date) => {
    let asEpoch = this.dateToGMTEpoch(date);
    let startOfOperationDay = this.state.lastOperationTime - (this.state.lastOperationTime % 86400);
    //date (as epoch) must be equal or greater than midnight at the day of last operation, but less than right now.
    return startOfOperationDay <= asEpoch && asEpoch < this.dateToGMTEpoch(new Date());
  };

  filterTime = (time) => {
    let asEpoch = this.dateToGMTEpoch(time);
    //time is between last operation and right now
    return this.state.lastOperationTime < asEpoch && asEpoch < this.dateToGMTEpoch(new Date());
  };

  getMinTime = () => {
    let date = this.state.selectedDate || moment();
    let startOfSelectedDay = this.dateToGMTEpoch(date);
    startOfSelectedDay = startOfSelectedDay - (startOfSelectedDay % 86400);
    if (this.state.lastOperationTime < startOfSelectedDay) {
      //operation in past, can select every hour
      return moment({ hour: 0, minute: 0 });
    }
    //operation happened today, can select times after it
    let lastOperationTimeOfDayInMinutes = this.state.lastOperationTime % 86400;
    lastOperationTimeOfDayInMinutes = Math.floor(lastOperationTimeOfDayInMinutes / 60);
    return moment({
      hour: Math.floor(lastOperationTimeOfDayInMinutes / 60),
      minute: lastOperationTimeOfDayInMinutes % 60
    });
  };

  getMaxTime = () => {
    let date = this.state.selectedDate || moment();
    let startOfSelectedDay = this.dateToGMTEpoch(date), startOfToday = this.dateToGMTEpoch(new Date());
    startOfSelectedDay = startOfSelectedDay - (startOfSelectedDay % 86400);
    startOfToday = startOfToday - (startOfToday % 86400);
    if (startOfSelectedDay === startOfToday) {
      return moment();
    }
    else {
      return moment({ hour: 23, minute: 59 });
    }
  };


  validateCuringDate = (value) => {
    if (!value) {
      return undefined;
    }
    //SAAS-5564 - in current date picker version, while typing future date which is disabled by the picker,
    //it wont fire the onChange event, hence the value is null but the display show a valid date.
    if (!value._isValid) {
      return this.labels.get("curing.dateIsNotValid");
    }

    let mmt = this.orgTime.clone().utc(true);
    let valueAsUtc = value.utc(true);
    if (value.isSame(this.orgTime.clone().toDate(), "day") && valueAsUtc.clone().isAfter(mmt.clone())) {
      return this.labels.get("curing.futureTimeSelected");
    }
    return undefined;
  };

  onCuringDateTimeChange = (date) => {
    if (!date) {
      this.setState({ selectedDate: null });
    }

    if (!date._isValid) {
      return;
    }

    this.setState({ selectedDate: date });
  };


  onValueChangeCallback = (value) => {
    this.props.change(this.getReduxFieldName("curingTime"), null);
    this.setState({ datePickerDummyKey: 1 - this.state.datePickerDummyKey });
  };


  render = () => {
    let { name, fetchConfig, preselectedAssets, entitiesTypes, itemIndex, ...otherProps } = this.props;
    let focusProps = (itemIndex === 0 && this.hasPreSelectedWo) ? { autoFocus: true } : {};

    return (
      <div className="selection-rows">
        <label>{this.labels.get("relatedassets")}</label>
        <MultiEntitiesMultiSelect
          id={this.getReduxFieldName("reportedAssets")}
          name={this.getReduxFieldName("reportedAssets")}
          entitiesTypes={entitiesTypes}
          key={this.state.dummyKey}
          preSelectedEntities={preselectedAssets}
          fetchConfig={fetchConfig}
          disableOnPreselect={false}
          onValueChangeCallback={this.onValueChangeCallback}
          {...focusProps}
          {...otherProps}/>

        {this.renderCuringTimeSection()}

      </div>
    );

  };
}

AssetsCuringSection.defaultProps = {
  entitiesTypes: []
};

AssetsCuringSection.propTypes = {
  workOrder: PropTypes.object,
  entitiesTypes: PropTypes.arrayOf(PropTypes.string),
  fetchConfig: PropTypes.object.isRequired,
  selectedKits: PropTypes.arrayOf(PropTypes.object)
};

export default connect(state => {
  let selectedKits = state.form.workOrderDialog &&
    state.form.workOrderDialog.values &&
    state.form.workOrderDialog.values.reportOperation0 &&
    state.form.workOrderDialog.values.reportOperation0.reportedAssets &&
    state.form.workOrderDialog.values.reportOperation0.reportedAssets[0] &&
    state.form.workOrderDialog.values.reportOperation0.reportedAssets[0].assetsValue;
  return { orgTime: state.system.get("serverDatetime"), selectedKits };
})(AssetsCuringSection);


