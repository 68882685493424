import { Component } from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';
import { Prompt } from 'react-router-dom';
import moment from 'moment';

import { SettingsMenuItemsIds } from '../../../../../enums/navigationMenuItemsIds';
import Header, { headerItemsLabels } from '../../../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import SortableList from 'infrastructure/js/components/controls/SortableList/sortableList';
import Switch from 'infrastructure/js/components/controls/Switch/switch';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import cn from 'classnames';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import AlertPopover, { AlertPopoverType } from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import ColorPickerTileField from 'infrastructure/js/components/controls/ColorPickerTileField/colorPickerTileField';
import { schedulingPreferencesKeys } from '../../../../../enums/schedulingPreferencesKeys';
import { getPatternStyle } from 'infrastructure/js/components/DynamicIcons/TaskStarted';
import { setSystemColors } from 'infrastructure/js/utils/colorHelper';
import { getColorInformation } from '../../../../Common/Helpers/SchedulerHelper';

import './schedulingPreferencesView.scss';

const comboboxValues = [
  { label: 'SUNDAY', value: 'SUNDAY' },
  { label: 'MONDAY', value: 'MONDAY' },
  { label: 'TUESDAY', value: 'TUESDAY' },
  { label: 'WEDNESDAY', value: 'WEDNESDAY' },
  { label: 'THURSDAY', value: 'THURSDAY' },
  { label: 'FRIDAY', value: 'FRIDAY' },
  { label: 'SATURDAY', value: 'SATURDAY' },
];

comboboxValues.forEach((item) => {
  item.key = schedulingPreferencesKeys.START_DAY_OF_WEEK;
});

class SchedulingPreferencesView extends Component {
  constructor(props) {
    super(props);

    this.allLabels = createLabelHelper('');
    this.labels = createLabelHelper('mat.administration.settings.schedulingPreferences.view.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');

    this.WHSComboBoxValues = [
      { label: this.labels.get('preferences.weekHorizonStart.options.today'), value: 'today', key: schedulingPreferencesKeys.WEEK_HORIZON_START },
      {
        label: this.labels.get('preferences.weekHorizonStart.options.fdotw'),
        value: 'first_day_of_week',
        key: schedulingPreferencesKeys.WEEK_HORIZON_START,
      },
    ];

    this.tileNameComboBoxValues = [
      {label: this.labels.get('preferences.tileName.option.workOrder'), value: 'work_order'},
      {label: this.labels.get('preferences.tileName.option.workOrderDescription'), value: 'work_order_description'},
      {label: this.labels.get('preferences.tileName.option.kitType'), value: 'kit_type'},
      {label: this.labels.get('preferences.tileName.option.kitTypeDescription'), value: 'kit_type_description'}
    ]

    this.state = {
      datePickerDummyKey: new Date(),
    };
  }

  componentDidMount() {
    this.props.actions.init().then(this.initForm);

    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_SCHEDULING_PREFERENCES);
  }

  initForm = (data) => {
    if (data?.startOfDay?.value) {
      data.startOfDay.value = moment(data.startOfDay.value.trim(), 'HH:mm');
    }

    const { started, completed, maintenance, scheduled } = data.colors;
    const [
      { color: startedColor, defaultColor: startedDefaultColor },
      { color: completedColor, defaultColor: completedDefaultColor },
      { color: maintenanceColor, defaultColor: maintenanceDefaultColor },
      { color: scheduledColor, defaultColor: scheduledDefaultColor },
    ] = getColorInformation([started, completed, maintenance, scheduled]);

    this.defaultColors = {
      started: startedDefaultColor,
      completed: completedDefaultColor,
      maintenance: maintenanceDefaultColor,
      scheduled: scheduledDefaultColor,
    };

    const tileColors = {
      scheduledTaskColor: scheduledColor,
      startedTaskColor: startedColor,
      completedTaskColor: completedColor,
      maintenanceTaskColor: maintenanceColor,
    };

    data.weekHorizonStart = this.WHSComboBoxValues.find(({ value }) => value === data.weekHorizonStart.value);
    data.tileName = this.tileNameComboBoxValues.find(({ value }) => value === data?.tileName?.value);

    this.props.initialize({ ...data, ...tileColors });
    //this.props.initialize(data);
    this.setState({
      datePickerDummyKey: new Date(),
    });
  };

  renderLoadingOverlay = () => {
    if (!this.props?.sData?.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  renderPrompt = () => {
    return <Prompt when={this.props?.isDirty} message={() => this.navigationLabels.get('datanotsaved.text')} />;
  };

  getHeaderItems = () => {
    return {
      title: this.labels.get('title'),
      buttons: [
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: this.props.pristine,
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };
  };

  itemRenderer = (item) => {
    let name = this.allLabels.get(item?.schedulingFactorType?.labelKey);
    const id = name.split(' ').join('-');
    return (
      <div className={cn('goals-item-cell')}>
        <div>{name}</div>
        <AlertPopover
          popoverId={id}
          holdOnHover={true}
          popoverType={AlertPopoverType.HINT}
          popoverComponent={<div style={{ whiteSpace: 'pre-line' }}>{this.allLabels.get(`${item?.schedulingFactorType?.labelKey}.description`)}</div>}
        />
      </div>
    );
  };

  onSubmit = (data) => {
    const { completedTaskColor, maintenanceTaskColor, scheduledTaskColor, startedTaskColor } = data;
    const newData = {
      goals: {
        schedulingFactors: data?.goals.map((goal) => ({
          factorTypeId: goal?.schedulingFactorType?.id,
        })),
      },
      rule: data?.enablePastDueDate,
      preferences: {
        settings: [
          {
            key: 'generalsetting.orgpreferences.scheduler.task.status.color.completed',
            value: completedTaskColor,
          },
          {
            key: 'generalsetting.orgpreferences.scheduler.task.status.color.scheduled',
            value: scheduledTaskColor,
          },
          {
            key: 'generalsetting.orgpreferences.scheduler.task.status.color.started',
            value: startedTaskColor,
          },
          {
            key: 'generalsetting.orgpreferences.scheduler.task.status.color.maintenance',
            value: maintenanceTaskColor,
          },
          {
            key: data.weekHorizonStart.key,
            value: data.weekHorizonStart.value,
          },
          {
            key: data?.startOfDay?.key,
            value: moment(data?.startOfDay?.value).format('HH:mm'),
          },
          {
            key: data?.startDayOfWeek?.key,
            value: data?.startDayOfWeek?.value,
          },
          {
            key: data?.humanCapacityEnabled?.key,
            value: data?.humanCapacityEnabled?.value,
          },
          {
            key: data?.minimumToolCapacity?.key,
            value: data?.minimumToolCapacity?.value,
          },
          {
            key: data?.maximumToolCapacity?.key,
            value: data?.maximumToolCapacity?.value,
          },
          {
            key: data?.tasksCompletionNotificationThreshold?.key,
            value: data?.tasksCompletionNotificationThreshold?.value,
          },
          {
            key: data?.showIncompleteTasksRatio?.key,
            value: data?.showIncompleteTasksRatio?.value,
          },
          {
            key: schedulingPreferencesKeys.TILE_NAME,
            value: data?.tileName?.value,
          },
        ],
      },
    };

    setSystemColors({ completed: completedTaskColor, maintenance: maintenanceTaskColor, scheduled: scheduledTaskColor, started: startedTaskColor });
    this.props.actions.submit(newData).then(this.initForm);
  };

  resetColor = (type) => {
    this.props.actions.resetColor(type).then(this.initForm);
  };

  maxToolCapacityMinValue = (value, allValues) => {
    if (value && value < allValues?.minimumToolCapacity?.value) {
      return this.labels.get('preferences.maxToolCapacity.validation.maxToolCapacityMinValue');
    }

    return undefined;
  }

  render() {
    let headerItems = this.getHeaderItems();
    let sortableListItems = this.props.sData.get('formData')?.goals;

    let orders = sortableListItems?.map((item, index) => {
      return (
        <div className="order-cell" key={index}>
          {index + 1}{' '}
        </div>
      );
    });

    const getColorPickerStyle = (color) => {
      return getPatternStyle(color).react;
    };

    return (
      <div className="scheduling-preferences-view">
        <Header {...headerItems} />
        <div className="scheduling-preferences-container">
          <div className="goals-section">
            <div className="preferences-header">
              <label className="preferences-title">{this.labels.get('goalsList.title')}</label>
            </div>
            <div className="order-configuration-table">
              <div className="header">
                <label className="header-column">{this.labels.get('goalsList.column.priority.title')}</label>
                <label className="header-column">{this.labels.get('goalsList.column.goals.title')}</label>
              </div>
              {sortableListItems?.length > 0 && (
                <div className="body">
                  <div className="order-column">{orders}</div>
                  <SortableList className="items-column" name="goals" id="goals" items={sortableListItems} itemRenderer={this.itemRenderer} />
                </div>
              )}
            </div>
          </div>
          <div className="preferences-section">
            <div className="preferences-header">
              <label className="preferences-title">{this.labels.get('rules.title')}</label>
            </div>
            <div className="preferences-input">
              <label>{this.labels.get('rules.label')}</label>
              <Switch id="enablePastDueDate" name="enablePastDueDate.weight" />
            </div>
          </div>
          <div className="preferences-section">
            <div className="preferences-header">
              <label className="preferences-title">{this.labels.get('preferences.title')}</label>
            </div>
            <div className="preferences-input">
              <label htmlFor="startOfDay">{this.labels.get('preferences.startOfDay')}</label>
              <DatePicker
                key={this.state.datePickerDummyKey}
                containerClassName="hour-picker"
                id="startOfDay"
                name="startOfDay.value"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeFormat={DateTimeHelper.getTimeFormat()}
                dateFormat={DateTimeHelper.getTimeFormat()}
                timeCaption=""
                placeholderText={this.labels.get('preferences.time')}
                validate={Validation.time}
              />
            </div>
            <div className="preferences-input">
              <label htmlFor="startOfDay">{this.labels.get('preferences.firstDayOfWeek')}</label>
              <Combobox className="firstDayOfWeek-combobox" id="startDayOfWeek" name="startDayOfWeek" options={comboboxValues} />
            </div>
            <div className="preferences-input">
              <label htmlFor="weekHorizonStart">{this.labels.get('preferences.weekHorizonStart.title')}</label>
              <Combobox className="weekHorizonStart-combobox" id="weekHorizonStart" name="weekHorizonStart" options={this.WHSComboBoxValues} />
            </div>

            <div className="preferences-input">
              <label>{this.labels.get('preferences.enableHumanCapacity')}</label>
              <Switch id="humanCapacityEnabled" name="humanCapacityEnabled.value" />
            </div>

            <div className="preferences-input">
              <label htmlFor="minimumToolCapacity">{this.labels.get('preferences.minToolCapacity')}</label>
              <TextField
                id="minimumToolCapacity"
                name="minimumToolCapacity.value"
                normalize={Normalize.number(true, 0, 100, true)}
              />
              <span className="additional-info">{this.labels.get('preferences.toolCapacityUnits')}</span>
              <AlertPopover
                popoverId='minToolCapacityId'
                holdOnHover={true}
                popoverType={AlertPopoverType.HINT}
                popoverComponent={<div style={{ whiteSpace: 'pre-line' }}>{this.labels.get('preferences.minToolCapacity.tooltip')}</div>}
              />
            </div>
            <div className="preferences-input">
              <label htmlFor="maximumToolCapacity">{this.labels.get('preferences.maxToolCapacity')}</label>
              <TextField
                id="maximumToolCapacity"
                name="maximumToolCapacity.value"
                normalize={Normalize.number(true, 1, 9999999, true)}
                validate={this.maxToolCapacityMinValue}
              />
              <span className="additional-info">{this.labels.get('preferences.toolCapacityUnits')}</span>
              <AlertPopover
                popoverId='maxToolCapacityId'
                holdOnHover={true}
                popoverType={AlertPopoverType.HINT}
                popoverComponent={<div style={{ whiteSpace: 'pre-line' }}>{this.labels.get('preferences.maxToolCapacity.tooltip')}</div>}
              />
            </div>

            <div className="preferences-input">
              <label htmlFor="tileName">{this.labels.get('preferences.tileName.title')}</label>
              <Combobox className='tile-name-combobox' id="tileName" name="tileName" options={this.tileNameComboBoxValues} />
            </div>

            <div className="preferences-input">
              <label htmlFor="tasksCompletionNotificationThreshold">{this.labels.get('preferences.tasksCompletionNotificationThreshold')}</label>
              <TextField
                id="tasksCompletionNotificationThreshold"
                name="tasksCompletionNotificationThreshold.value"
                normalize={Normalize.number(true, 0, 100)}
              />
              <span className="additional-info">{this.labels.get('preferences.tasksCompletionNotificationThresholdUnits')}</span>
              <AlertPopover
                popoverId='tasksCompletionNotificationThresholdId'
                holdOnHover={true}
                popoverType={AlertPopoverType.HINT}
                popoverComponent={<div style={{ whiteSpace: 'pre-line' }}>{this.labels.get('preferences.tasksCompletionNotificationThreshold.tooltip')}</div>}
              />
            </div>
            <div className="preferences-input">
              <label>{this.labels.get('preferences.showIncompleteTasksRatio')}</label>
              <Switch id="showIncompleteTasksRatio" name="showIncompleteTasksRatio.value" />
            </div>

          </div>
          <div className="preferences-section">
            <div className="preferences-header">
              <label className="preferences-title">{'Customize Colors'}</label>
            </div>
            <div className="preferences-items-list">
              <ColorPickerTileField
                name="scheduledTaskColor"
                tileName="Scheduled"
                originalSystemColor={this.defaultColors?.scheduled}
              />
              <ColorPickerTileField
                name="startedTaskColor"
                tileName="Started"
                styleGenerator={getColorPickerStyle}
                originalSystemColor={this.defaultColors?.started}
              />
              <ColorPickerTileField
                name="completedTaskColor"
                tileName="Completed"
                originalSystemColor={this.defaultColors?.completed}
              />
              <ColorPickerTileField
                name="maintenanceTaskColor"
                tileName="Maintenance"
                originalSystemColor={this.defaultColors?.maintenance}
              />
            </div>
          </div>
        </div>

        {this.renderLoadingOverlay()}
        {this.renderPrompt()}
      </div>
    );
  }
}

SchedulingPreferencesView.propTypes = {
  actions: PropTypes.object,
  sData: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  isDirty: PropTypes.bool,
  pristine: PropTypes.bool,
};

SchedulingPreferencesView.defaultProps = {
  actions: {},
  sData: {},
  handleSubmit: () => console.error('Missing handleSubmit function!'),
  initialize: () => console.error('Missing initialize function!'),
  isDirty: false,
  pristine: false,
};

export default reduxForm({
  form: 'schedulingPreferencesView',
})(SchedulingPreferencesView);
