import React from 'react';
import PropTypes from 'prop-types';
import {Prompt} from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Header from '../../../../../Common/Header/header';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import CommonCsvSettingsForm from '../CommonCsvSettingsForm/commonCsvSettingsForm';
import {LabelKeys} from '../../../utils/csvSettingsHelper';

import'./commonCsvSettingsView.scss';

const TabKeys = {
  IMPORT: 'importTab',
  EXPORT: 'exportTab',
};


 export default class CommonCsvSettingsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.csvSettings.view.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');

    this.state = {
      selectedTab: props.importEnabled ? TabKeys.IMPORT : props.exportEnabled ? TabKeys.EXPORT : null,
    };
  }

  componentDidMount () {
    const {entityType, menuItemId} = this.props;
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(menuItemId);
    this.props.actions.load(entityType);
  }

  componentWillUnmount() {
     this.props.actions.unmount();
  }

  getHeaderItems = () => {
    return {
      title: this.getHeaderTitle(),
    };
  };

  getHeaderTitle = () => {
    let {entityType} = this.props;
    let entityLabel = this.entityLabels.get( LabelKeys[entityType]);
    if (!entityLabel) {
      console.error('getHeaderTitle(): unknown entity type ' + entityType);
    }
    return this.labels.get('title', '', {entityType: entityLabel});
  };

  onTabClick = (key) => {
    this.setState({selectedTab: key});
  };

  getTabTitles = () => {
    let importTab = <TabTitle title={this.labels.get('tabs.import')} />;
    let exportTab = <TabTitle title={this.labels.get('tabs.export')} />;

    return {importTab, exportTab};
  };

  renderLoadingOverlay = () => {
    if (!this.props.sData.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

   renderPrompt = () => {
     return (
       <Prompt
         when={this.props.isDirty}
         message={(location) =>
           this.navigationLabels.get('datanotsaved.text')
         }
       />
     );
   };

   renderImportTab = (tabTitles) => {
     let {sData, entityType, actions, importEnabled} = this.props;

     if ( importEnabled && sData && sData.get('importData')) {

       return (
         <Tab eventKey={TabKeys.IMPORT} animation={false} title={tabTitles.importTab}>
           <CommonCsvSettingsForm
             formType="IMPORT"
             form={'cssSettingsImportForm'}
             formData={sData.get('importData')}
             entityType={entityType}
             labels={this.labels}
             actions={actions}
           />
         </Tab>
       );
     }
     return null;
   };

   renderExportTab = (tabTitles) => {
     let {sData, entityType, actions, exportEnabled} = this.props;

     if ( exportEnabled && sData && sData.get('exportData')) {

       return (
         <Tab eventKey={TabKeys.EXPORT} animation={false} title={tabTitles.exportTab}>
           <CommonCsvSettingsForm
             formType="EXPORT"
             form={'cssSettingsExportForm'}
             formData={sData.get('exportData')}
             entityType={entityType}
             labels={this.labels}
             actions={actions}
           />
         </Tab>
       );
     }
     return null;
   };

  renderTabs = () => {
    let tabTitles = this.getTabTitles();

    return (
      <div className="common-csv-settings-view-tabs-section">
        <Tabs activeKey={this.state.selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">

          {this.renderImportTab(tabTitles)}
          {this.renderExportTab(tabTitles)}

        </Tabs>
      </div>
    );
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <div className='common-csv-settings-view'>
        <Header {...headerItems}/>
        {this.renderTabs()}

        {this.renderLoadingOverlay()}
        {this.renderPrompt()}
      </div>
    );
  }
}

CommonCsvSettingsView.defaultProps = {
  importEnabled: true,
  exportEnabled: true,

};

CommonCsvSettingsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  menuItemId: PropTypes.string.isRequired,
  importEnabled: PropTypes.bool,
  exportEnabled: PropTypes.bool,
};




