// @ts-check

import { MappedinNode, MappedinPolygon } from "@mappedin/mappedin-js";

/**
 * @typedef {import("../../../types/ServerTypes/Models/LiveMap").LivemapLocationDetailsResponseModel} LivemapLocationDetailsResponseModel
 * 
 */



export class PolygonInfo {
  
  /** @type {string | null} */
  polygonExternalId = null;
  
  /** @type {string} */
  polygonId;
  
  /** @type {string | null} */
  layerName;
  
  /** @type {string} */
  mapName;
  
  /** @type {LivemapLocationDetailsResponseModel | null} */
  locationData = null;
  
  /** @type {string | null} */
  normalColor = null;
  
  /** @type {boolean} */
  interactive = false;
  
  /** @type {MappedinNode | null} */
  entranceNode = null;
  
  /** @type {string | null} */
  tooltipId = null;
  
  
  /** @type {string | null} */
  normalMarkerId = null;
  
  /** @type {string | null} */
  normalMarkerZoomLevel = null;

  
  /** @type {string | null} */
  searchMarkerId = null;
  
  /** @type {string | null} */
  searchMarkerZoomLevel = null;
  
  /** @type {string | null} */
  searchMarkerHTML = null;
  
  /** @type {string | null} */
  searchMarkerForSearchId = null;
  
  /** @type {string | null | undefined} */
  searchMarkerForSelectionId = null;
  
  /** @type {string | null} */
  searchMarkerTargetLink = null;
  
  
  
  
  
  /**
   * @param {MappedinPolygon} p
   * @param {string} normalColor
   * @param {boolean} interactive
   */
  constructor(p, normalColor, interactive) {
    
    this.polygonId = p.id;
    this.polygonExternalId = p.externalId || null;
    
    this.layerName = p.layer || null;
    // this.mapName = p.map.name;
    this.mapName = p.map.shortName;
    
    this.normalColor = normalColor;
    this.interactive = interactive;
    
    if (p.entrances.length > 0) {
      this.entranceNode = p.entrances[0];
    }
    
    
  }
  
  
  
}