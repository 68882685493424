import { actionTypes as activityLogDialogActionTypes } from '../../actions/ActivityLogDialog/activityLogDialogActions';
import { default as activityLogDialogGridReducer } from './activityLogDialogGridReducer';
import { Map } from 'immutable';

let defaultState = Map({
  show : false,
  loading: false,
  data : Map({}),
});


export default function(state = Map(defaultState), action) {
  state = state.set('gridData', activityLogDialogGridReducer(state.get('gridData'), action));
  switch (action.type) {

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_SHOW:
      return state.set('show', true);

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_HIDE:
      return Map(defaultState);

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_SET_BUSY:
      return state.set('loading', action.payload);

    case activityLogDialogActionTypes.ACTIVITYLOG_DIALOG_SET_CONFIG_DATA:
      state = state.setIn(['data','activityLogTitle'], action.payload.activityLogTitle)
                   .setIn(['data','jobName'], action.payload.jobName)
                   .setIn(['data','activityLogId'], action.payload.activityLogId);
      return state;
    default:
      return state;
  }
}

