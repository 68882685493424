import PropTypes from 'prop-types';
import Label from 'infrastructure/js/components/Label/label.js';

require('./labelWithTitleCell.scss');

export default function LabelWithTitleCell({value}) {

  let {title='', label=''} = value;

  return (
    <div className='label-with-title-cell'>
      <div className='label-with-title-cell__title'>{title}</div>
      <Label className='label-with-title-cell__label' text={label}/>
    </div>
  )
}

LabelWithTitleCell.propTypes = {
  value : PropTypes.object,
};




