import React from 'react';
import PropTypes from 'prop-types';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import EntitiesSelect from '../../../../../Wizards/CutAndKit/Steps/KitToWorkOrderSubcomponents/entitiesSelect';
import {EntityPropertyTypes} from '../../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../../enums/fetchEntitiesFilters';
import Label from 'infrastructure/js/components/Label/label';
import './phoneListItem.scss';

const ComboboxPortal = withPortal(EntitiesSelect);

export default class PhoneListItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.phoneFetchConfig = {
      entityType: EntityPropertyTypes.USER_NAME,
      filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.SEARCH_BY_PHONE_NUMBER],
      action: this.props.actions.fetchEntities
    };
  }

  validatePhone = (value, allValues) => {
    return Validation.phone(value?.data?.phone || '');
  };

  validateSingleValue = (value, allValues) => {
    if (!value) {
      return undefined;
    }
    if (this.isItemAlreadySelected(value, allValues)) {
      this.props.touch(this.props.name + '.[phoneNumber]');
      return 'Duplicated item selection';
    }
    return undefined;
  };

  isItemAlreadySelected = (value, allValues) => {
      let res = allValues.phoneNumbers.some((item, index) => {
        return (
          value?.data?.phone === item?.phoneNumber?.data?.phone && this.props.index !== index
        );
      });
      return res;
  };

  getNewOptionDataHandler = (inputValue, optionLabel) => {
    return {
      label: optionLabel,
      value: inputValue,
      __isNew__: true,
      data: {
        fullName: '',
        phone: inputValue,
      }
    };
  };

  getComponentToRender = (props) => {
    return <Label text={props?.data?.data?.phone || ''} />;
  };

  getOptionComponentToRender = (props) => {
    if (props?.data) {
      let compLabel = `${props.data?.data?.fullName || ''} ${props.data?.data?.phone || ''}`;
      return (props.data.__isNew__ ? props.data.label : <Label text={compLabel}/>);
    }
    return null;
  };


  render() {
    let {  name, change, index } = this.props;
    return (
      <>
          <span className='item-index'>{`${index + 1}.`}</span>
          <ComboboxPortal
            id={name + '.[phoneNumber]'}
            name={name + '.[phoneNumber]'}
            entityType={'USER_NAME'}
            fetchConfig={this.phoneFetchConfig}
            placeholder={'Enter phone number'}
            validate={[this.validatePhone, this.validateSingleValue]}
            change={change}
            allowNewOption
            isClearable
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getOptionComponentToRender}
            getNewOptionData={this.getNewOptionDataHandler}
          />
          </>
        )
    }
}

PhoneListItem.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
};
