import React from 'react';

import { object, arrayOf, func, string, bool } from 'prop-types';
import classNames from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import PL_DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import Button from 'infrastructure/js/components/controls/Button/button';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {Overlay, Popover} from 'react-bootstrap';
import './dateRangePicker.scss';

const PickerType = {
  start: 'START',
  end: 'END'
};

export default class DateRangePicker extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.controls.dateRangePicker.');
    this.startDatePickerRef = this.endDatePickerRef = null;

    this.state = {
      selectedStartDate: props.selectedStartDate || null,
      selectedEndDate: props.selectedEndDate || null,
      popoverOpen: false
    }
  }

  //update local date state
  onDateChange = (date, isStart) => {
    if (!date || !date._isValid) {
      this.setState(isStart ? { selectedStartDate: null } : { selectedEndDate: null }, this.callbackIfNotPopover);
      return;
    }
    this.setState(isStart ? { selectedStartDate: date } : { selectedEndDate: date }, this.callbackIfNotPopover);
  }

  callbackIfNotPopover = () => {
    this.props.onDateChange?.(this.state.selectedStartDate, this.state.selectedEndDate);
  }

  onKeyDownHandler = (e) => {
    e.preventDefault();
  }

  //filters dates that are after the selected end date
  filterFromDates = (date) => {
    if (this.state.selectedEndDate)
      return !date.isAfter(this.state.selectedEndDate); //!isAfter = isBefore || isSame
    return true;
  }

  //filters dates that are before the selected start date
  filterToDates = (date) => {
    if (this.state.selectedStartDate)
      return !date.isBefore(this.state.selectedStartDate); //!isBefore = isAfter || isSame
    return true;
  }

  renderDatePicker = (pickerType, fieldName) => {
    let isStartDatePicker = pickerType === PickerType.start;
    return (
      <div className='date-range-datepicker-container'>
        <span>
          {this.labels.get(isStartDatePicker ? 'from' : 'to')}
        </span>
        <div className='date-range-datepicker'>
          <PL_DatePicker
            name={fieldName}
            id={`datePicker${pickerType}`}
            ref={(r) => { if (isStartDatePicker) { this.startDatePickerRef = r; } else { this.endDatePickerRef = r; } }}
            key={isStartDatePicker ? 0 : 1}
            popperPlacement="auto"
            initialDate={isStartDatePicker ? this.props.selectedStartDate : this.props.selectedEndDate}
            validate={Validation.date}
            onChangeCallback={ (date) => { this.onDateChange(date, isStartDatePicker); } }
            onKeyDown={this.onKeyDownHandler}
            filterDate={isStartDatePicker ? this.filterFromDates : this.filterToDates}
            includeDates={this.props.includeDates}
            isClearable
          />
        </div>
      </div>
    );
  };

  //when clicking FILTER to confirm dates
  onFilterClicked = () => {
    this.props.onDateChange(this.state.selectedStartDate, this.state.selectedEndDate);
    this.setState({ popoverOpen: false })
  }

  //when clicking CANCEL
  onCancelClicked = () => {
    this.setState({ popoverOpen: false })
  }

  getComponentInsidePopover = () => {
    let { fromName, toName } = this.props;
    let divClassName = this.props.renderAsPopover ? 'date-range-datepicker-area' : classNames('date-range-datepicker-area', this.props.className);
    return (
      <div className={divClassName}>
        {this.renderDatePicker(PickerType.start, fromName)}
        {this.renderDatePicker(PickerType.end, toName)}
        {this.props.renderAsPopover && this.props.renderAsPopoverWithButtons ?
            <div className='date-range-buttons-container'>
              <Button id={'dateRangePickerCancel'} onClick={this.onCancelClicked}>
                {this.labels.get('cancel')}
              </Button>
              <Button className={'btn-primary'} id={'dateRangePickerFilter'} onClick={this.onFilterClicked}>
                {this.labels.get('filter')}
              </Button>
            </div>
          : null
        }
      </div>
    );
  };

  togglePopover = (event) => {
    event?.stopPropagation();
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };

  onHide = () => {
    this.setState({ popoverOpen: false });
  };

  //date inside the pseudo-datepicker rendered on the page
  getDateText = () => {
    let { selectedStartDate, selectedEndDate } = this.props;
    if (!selectedStartDate && !selectedEndDate)
      return '';

    let startDateString = selectedStartDate ? DateTimeHelper.FormatDateObjectToDayMonth({epochDateTime: selectedStartDate.unix() }) :  this.labels.get('noDate'),
      endDateString = selectedEndDate ? DateTimeHelper.FormatDateObjectToDayMonth({ epochDateTime: selectedEndDate.unix() }) :  this.labels.get('noDate')

    return `${startDateString} - ${endDateString}`;
  }

  render() {
    if (this.props.renderAsPopover) {
      return (
        <div id='date-range-popover' className='date-range-popover'>
          <div
            className={classNames('date-range-popover-target', this.props.className)}
            ref={(t) => (this.target = t)}
            onClick={this.togglePopover}
          >
            <label className='date-range-label'>
              {this.getDateText()}
            </label>
          </div>
          <Overlay
            target={this.target}
            show={this.state.popoverOpen}
            placement='bottom'
            rootClose={true}
            onHide={this.onHide}
          >
            <Popover id='dateRangePopover' className={'pl-popover date-range-popover date-range-picker'} placement='bottom'>
              <div className='popover-content'>
                <div className='popover-component'>
                  {this.getComponentInsidePopover()}
                </div>
              </div>
            </Popover>
          </Overlay>
        </div>
      );
    }
    else {
      return this.getComponentInsidePopover();
    }
  }
}

DateRangePicker.defaultProps = {
  renderAsPopoverWithButtons: true,
}

DateRangePicker.propTypes = {
  renderAsPopover: bool,
  renderAsPopoverWithButtons: bool, //to handle the 'Cancel' and 'Filter' buttons displaying
  className: string,
  selectedStartDate: object, //moment object for the start date selected when opening the datepicker
  selectedEndDate: object, //moment object for the end date selected when opening the datepicker
  includedDates: arrayOf(object), //array of moment dates to include in the datepickers
  onDateChange: func, //(startDate: Date, endDate: Date) => void; confirm dates have changed
  fromName: string, //name of from field
  toName: string, //name of to field
}
