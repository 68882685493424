import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, { headerItemsLabels } from '../../../../../Common/Header/header';
import Form from '../../../../Common/Form/form';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import { List } from 'immutable';
import Label from 'infrastructure/js/components/Label/label.js';
import MisplacedMaterialRulesGrid from './MisplacedMaterialAlertsRulesGrid/misplacedMaterialAlertsRulesGrid';
import MisplacedMaterialAlertRuleDialog from './misplacedMaterialAlertRuleDialog';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';

import './misplacedMaterialForm.scss';

class MisplacedMaterialForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formLabels = createLabelHelper('mat.administration.settings.alerts.form.');
    this.labels = createLabelHelper('mat.administration.settings.misplacedMaterial.form.');

    this.state = {
      alertsDisabled: false,
    };
  }

  componentDidMount() {
    this.setState({ alertsDisabled: !this.props.sData.enabled });
    this.initFormValues();
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  initFormValues = () => {
    let data = this.props.sData;

    let initialValue = {
      alertOnMisplaced: data.enabled,
    };

    this.props.initialize(initialValue);
  };

  onSubmit = (data) => {
    let newData = {
      id: this.props.sData.id,
      enabled: data.alertOnMisplaced,
    };

    this.props.actions.update(newData, this.props.alertType, this.getMessageDialogBuilder()).then((response) => {
      if (response && response.success) {
        setTimeout( () => {this.initFormValues()}); //Initialize form values and reset pristine.
      }
    });
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.formLabels.get('confirmation.success');
      let className = 'oneBackground';
      return { title, className };
    };
  };
  getFormButtons() {
    return [
      {
        text: this.formLabels.get('buttons.save'),
        className: 'common-alerts-form-button-save border-btn btn',
        action: this.props.handleSubmit(this.onSubmit),
        disabled: this.props.pristine,
      },
    ];
  }

  getPreselectedLocations = () => {
    return this.props.sData.locations ? List(this.props.sData.locations) : List([]);
  };

  recipientsValidation = (value = '') => {
    const emailsArr = value.replace(/ /g, '').split(',');
    let validationMessage = undefined;

    emailsArr.forEach((email) => {
      const message = Validation.email(email);
      if (message) {
        validationMessage = this.formLabels.get('validationMessage.email');
      }
    });

    return validationMessage;
  };

  onEnableAlertsClick = () => {
    this.setState({ alertsDisabled: !this.state.alertsDisabled });
  };

  multiSelectElementRenderer = (isOption) =>
    function renderer({ data }) {
      if (!data?.label) {
        return null;
      }

      return (
        <div className={`select-row ${isOption ? 'select-option-row' : 'select-value-row'}`}>
          <Label text={data.label} />
        </div>
      );
    };

  getHeaderItems() {
    let { actions, sMisplacedMaterialAlertRulesGridData } = this.props;
    let loading = sMisplacedMaterialAlertRulesGridData?.get('loading');
    let selectedRules = sMisplacedMaterialAlertRulesGridData?.get('selectedRowsData') || List();

    let items = {
      buttons: [
        {
          id: 'createRule',
          label: 'create',
          className: 'no-icon',
          action: actions.misplacedMaterialAlertRuleDialogActions.show,
          tooltip: headerItemsLabels.CREATE_RULE,
          disabled: loading,
        },
        {
          id: 'editRule',
          icon: 'pl pl-edit-icon',
          action: () => actions.misplacedMaterialAlertRuleDialogActions.show(selectedRules),
          tooltip: headerItemsLabels.EDIT_RULE,
          disabled: loading || !(selectedRules?.size === 1),
        },
        {
          id: 'deleteRule',
          icon: 'pl pl-delete-icon',
          action: () => actions.misplacedMaterialAlertActions.deleteRules(selectedRules, this.props.sData.id),
          tooltip: headerItemsLabels.DELETE_RULE,
          disabled: loading || !(selectedRules?.size > 0),
        },
      ],
      title: this.labels.get('header.title'),
    };

    return items;
  }

  reloadPage = () => {
    this.props.actions.misplacedMaterialAlertActions.init();
  };

  render() {
    let headerItems = this.getHeaderItems();

    return (
      <Form className="common-alerts-form misplaced-material-form" formButtons={this.getFormButtons()}>
        <InputSection htmlFor="alertOnMisplaced">
          <label htmlFor="alertOnMisplaced">
            <Checkbox name="alertOnMisplaced" id="alertOnMisplaced" onClick={this.onEnableAlertsClick} value={false} />
            <span>{this.labels.get('checkbox.alertOnMisplaced.title')}</span>
          </label>
        </InputSection>

        <Header {...headerItems} />

        <MisplacedMaterialRulesGrid actions={this.props.actions.misplacedMaterialRulesGridActions} />

        <PL_DialogWrapper
          dialogComponent={MisplacedMaterialAlertRuleDialog}
          show={this.props.sMisplacedMaterialAlertRuleDialogData.get('show')}
          actions={{...this.props.actions.misplacedMaterialAlertRuleDialogActions, ...this.props.actions.fetchEntitiesActions}}
          sData={this.props.sMisplacedMaterialAlertRuleDialogData}
          reloadParentComponent={this.reloadPage}
        />
      </Form>
    );
  }
}

export default reduxForm({
  form: 'misplacedMaterialForm',
})(MisplacedMaterialForm);
