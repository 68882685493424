import { Map } from 'immutable';
import {MatSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import { default as createSandboxDialogReducer } from './createSandboxDialogReducer';
import { actionTypes as actionTypes } from '../../../actions/Administration/SandboxPage/sandboxPageActions';


let defaultState = {
  selectedMenuItemId : MatSettingsMenuItemsIds.NAV_ELEMENT_MATERIAL_TYPES,
  loading: false,
};

export default function(state = Map(defaultState), action) {

  state = state.set('createSandboxDialog', createSandboxDialogReducer(state.get('createSandboxDialog'), action));

  switch (action.type) {

    case actionTypes.SANDBOX_PAGE_SET_LOADING:
      return state.set('loading', action.payload);

    default:
      return state;
  }
}



