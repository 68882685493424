import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { jsxActions as toolTypesViewActions} from '../../../actions/Administration/MatSettingsPage/toolTypesViewActions'
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions.js';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { jsxActions as toolTypesViewGridActions} from '../../../actions/Administration/MatSettingsPage/toolTypesViewGridActions';
import { jsxActions as createToolTypeDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createToolTypeDialogActions.js';
import { jsxActions as importToolTypesDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/importToolTypesDialogActions';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { gridsNames} from '../../../enums/gridsNames';
import ToolTypesView from '../../../components/Administration/MatSettingsPage/Tools/ToolTypesView/ToolTypesView';

function mapDispatchToProps(dispatch) {
  return {

    actions: {
      ...bindActionCreators(toolTypesViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      toolTypesViewGridActions: bindActionCreators(toolTypesViewGridActions, dispatch),
      createToolTypeDialogActions : bindActionCreators(createToolTypeDialogActions, dispatch),
      importToolTypesDialogActions : bindActionCreators(importToolTypesDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }

  }
}

let ToolTypesViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES) : null;
  	return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateToolTypeDialogData: state.administration.getIn(['matSettingsPage', 'createToolTypeDialog']),
      sImportToolTypesDialogData: state.administration.getIn(['matSettingsPage', 'importToolTypesDialog']),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(ToolTypesView);


export default ToolTypesViewContainer;



