import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {useElementSelectionContext} from 'infrastructure/js/contexts/elementSelectionContext';
import {useTreeItemDragContext} from 'infrastructure/js/components/Tree/treeItemDragContext';
import Label from 'infrastructure/js/components/Label/label.js';


import './treeItem.scss';


const TreeItem = ({ item, level, treeMap,
                    onSelect=()=>{},
                    draggable=false,
                    onDrop=()=>{},
                    selectedItem,
                    defaultExpandAll=false,
                    itemIconsRenderer=null,
                    itemActionsRenderer=null   }) => {

  const { current: isControlled } = useRef(selectedItem !== undefined);
  const [expanded, setExpanded] = useState(defaultExpandAll);
  const { selected, setSelected } = useElementSelectionContext();
  const { dragged, setDragged } = useTreeItemDragContext();         //the currently dragged item id
  const [droppable, setDroppable] = useState('');

  const itemRef = useRef(null);
  const isSelected = selected.includes(item?.id);
  const isBranch = !!item.children;

  useEffect(() => {
    if (selectedItem && selectedItem.id || selectedItem === null) {  //for the controlled item
      setSelected(selectedItem ? [selectedItem.id] : []);
    }
  }, [selectedItem]);

  const onItemIconClick = (e) => {
    setExpanded(prev => !prev);
  };

  const onItemLabelClick = (e) => {
    isControlled ? onSelect?.(e.ctrlKey ? null : item) : setSelected( e.ctrlKey ? [] : [item?.id]);
  };

  const onItemActionsClick = (e) => {
    e.stopPropagation();
    setSelected( [item?.id]);
  };

  //------------DnD-----------------------
  const onDragStart = (e) => {
    e.dataTransfer.clearData();
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('data', JSON.stringify(item));
    setDragged(item.id);
    e.currentTarget.classList.add('dragged');
  };

  const onDragEnd = (e) => {
    setDragged(null);
    e.target.classList.remove('dragged');
  };

  const onDragEnter = (e) => {
    if (treeMap.isDescendant(dragged, item.id) ) {
      setDroppable('');
      return;
    }
    updateDropIndicator(e);
  };

  const onDragLeave = (e) => {
    setDroppable('');
  };

  const onDragOver = (e) => {
    if (treeMap.isDescendant(dragged, item.id) ) {
      setDroppable('');
      return;
    }
    updateDropIndicator(e);
  };


  const onDropHandler = (e) => {
    e.preventDefault();

    const data = e.dataTransfer.getData('data');

    if (data) {
      const draggedItem = JSON.parse(data);

      let dropData = {};
      if (droppable === 'droppable-inside') {
        dropData.parentId = item.id;
        dropData.prevSiblingId = null;
        dropData.nextSiblingId = null;
      }
      else  if (droppable === 'droppable-before'){
        dropData.parentId = item.parentId;
        dropData.prevSiblingId = item.prevSiblingId;
        dropData.nextSiblingId = item.id;
      }
      else  if (droppable === 'droppable-after'){
        if (item.children?.length && expanded) {
          dropData.parentId = item.id;
          dropData.prevSiblingId = null;
          dropData.nextSiblingId = null;
        }
        else {
          dropData.parentId = item.parentId;
          dropData.prevSiblingId = item.id;
          dropData.nextSiblingId = item.nextSiblingId;
        }
      }

      onDrop?.(e, draggedItem, dropData);
    }

    setDroppable('');
  };

  const updateDropIndicator = (e) => {
    let { clientY} = e;
    const rect = itemRef.current.getBoundingClientRect();

    let divider = isBranch ? 3 : 2;

    let isBefore = clientY < rect.top + rect.height/divider;
    let isAfter = clientY >= rect.bottom - rect.height/divider;

    let draggedItem = treeMap.getItem(dragged);

    if (isAfter && draggedItem.id === item.nextSiblingId  ||                                       //the dragged item is already after me
        isBefore && draggedItem.id === item.prevSiblingId ||                                       //the dragged item is already before me
        isAfter && draggedItem.parentId === item.id && draggedItem.prevSiblingId === null  ||   //the dragged item is my first child
        isBefore && !item.parentId                                                              //I am the root item
    ) {
      setDroppable('');
      return;
    }

    e.preventDefault();
    isBranch ? setDroppable(isBefore ? 'droppable-before' : isAfter ? 'droppable-after' : 'droppable-inside') :
               setDroppable(isBefore ? 'droppable-before' : isAfter ? 'droppable-after' : '');

  };

  const renderTreeItems = () => {
    if (item?.children?.length > 0) {
      const newLevel = level + 1;

      return item?.children?.map((child) => {
        return <TreeItem key={child.id} item={child} level={newLevel} treeMap={treeMap}
                         onSelect={onSelect}
                         draggable={draggable}
                         onDrop={onDrop}
                         selectedItem={selectedItem}
                         defaultExpandAll={defaultExpandAll}
                         itemActionsRenderer={itemActionsRenderer}
                         itemIconsRenderer={itemIconsRenderer}
        />
      });
    }
    return null;
  };

  let node = {...item, expanded, selected: isSelected }

  return (
    <>
      <div className={cn('tree-item', {branch: isBranch}, {leaf: !isBranch}, {selected: isSelected}, {[droppable]: droppable}  )}
            ref={itemRef}
            style={{ paddingLeft: `${level * 16}px`}}
            draggable={draggable}                               //make element draggable
            onDragStart={draggable ? onDragStart : undefined}   //make element draggable
            onDrop={draggable ? onDropHandler : undefined}      //make element droppable
            onDragEnter={draggable ? onDragEnter : undefined}
            onDragOver={draggable ? onDragOver : undefined}     //make element droppable
            onDragLeave={draggable ? onDragLeave : undefined}
            onDragEnd={draggable ? onDragEnd : undefined}
      >
        <div className='tree-item__icons' onClick={onItemIconClick}>
          {itemIconsRenderer?.(node)}
        </div>
        <div className='tree-item__label' onClick={onItemLabelClick}>
          <Label text={item.name} />
        </div>
        <div className='tree-item__actions' onClick={onItemActionsClick}>
          {itemActionsRenderer?.(item)}
        </div>
      </div>

      {expanded && renderTreeItems()}
    </>
  );
};

TreeItem.propTypes = {
  item: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  treeMap: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  draggable: PropTypes.bool,
  onDrop: PropTypes.func,
  selectedItem: PropTypes.object,
  defaultExpandAll: PropTypes.bool,
  itemIconsRenderer: PropTypes.func,
  itemActionsRenderer: PropTypes.func,
}

export default TreeItem;

