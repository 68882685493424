import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as tasksService from '../../services/Tasks/tasksService';
const gridActions = gridActionsHelper.getGridActions(gridsNames.TASKS_ASSIGNMENTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query = _addFiltersDataToQuery(query, getState);
    return tasksService.fetchTasksAssignments(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {
  let activities = rows.map((obj) => {
    return obj;
  });
  return activities;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;


////////////////////////////////////
function _addFiltersDataToQuery(query, getState) {
  let operationStatusFilter = query?.filters.find((filter) => {
    return filter.filterName === 'operationStatus';
  });
  if (operationStatusFilter) {
    operationStatusFilter.values = ['COMPLETED'];
  }

  let taskAssignmentIdFilter = query?.filters.find((filter) => {
    return filter.filterName === 'taskAssignmentId';
  });
  if (taskAssignmentIdFilter) {
    let grid = getState().grid.get(gridsNames.BO_ACTIVITY_LOG) //Note: take tasks ids from the BO_ACTIVITY_LOG grid for the first time (init())
    if (grid) {
      taskAssignmentIdFilter.values = grid.get('rows')?.map(item => item.entityId);
    }
    else {  //take tasks ids from the TASKS_ASSIGNMENTS grid for reload()
      grid = getState().grid.get(gridsNames.TASKS_ASSIGNMENTS);
      taskAssignmentIdFilter.values = grid.get('rows')?.map(item => item.id);
    }
  }
  return query;
}
