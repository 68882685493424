import PropTypes from 'prop-types';
import cn from 'classnames';
import Promotion from 'infrastructure/js/components/LoginLayout/Promotion/promotion';
import Carousel from 'infrastructure/js/components/Carousel/carousel';
import MarketingLayoutDefault from 'infrastructure/js/components/LoginLayout/marketingLayoutDefault';
import Overlay from 'infrastructure/js/components/Overlay/overlay';

import './marketingLayout.scss';

export default function MarketingLayout({ children, className, promotions, slideSpeed=0 }) {

  const showPromotions = promotions?.length > 0 && slideSpeed > 0;

  //Note: show the default screen only when the fetched from the server promotions are empty
  return (
    <div className={cn('marketing-layout', {default: promotions && !showPromotions}, className)}>
      <div className="marketing-layout-bg"></div>
      <div className="marketing-layout-left">{children}</div>
      <div className="marketing-layout-right">

        { showPromotions ?
          <Carousel interval={slideSpeed * 1000} >

            { promotions?.map((item, index) => {
              return ( <Promotion key={index} item={item} /> )
            }) }

          </Carousel>
          :
          <>
            {promotions === null ? <Overlay.Loading /> : <MarketingLayoutDefault />}
          </>
        }
      </div>
    </div>
  );
}

MarketingLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  promotions: PropTypes.array,
  slideSpeed: PropTypes.number,
};
