import React, { Component } from 'react';
import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import InfoChart from '../Common/InfoChart/infoChart';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import MoveAssetsDialog from            '../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import BringAssetsDialog from           '../Dialogs/BringAssetsDialog/bringAssetsDialog';
import ArchiveAssetsDialog from   '../Dialogs/ArchiveAssetsDialog/archiveAssetsDialog';
import ShelfLifeUpdateDialog from       '../Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialog';
import PrintRfidTagsDialog from       '../Dialogs/PrintRfidTagsDialog/printRfidTagsDialog';
import CreateGroupDialog from '../Dialogs/CreateGroupDialog/createGroupDialog';
import ImportAttributesUpdateWizard from '../Wizards/ImportAttributesUpdate/importAttributesUpdateWizard';
import FullScreenTabsSeparator from '../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
// import ScheduleAction from '../../modules/scheduleAction.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager'
import AssetsViewContainer      from '../../containers/LocationPage/AssetsView/assetsViewContainer.js';
import ActivitiesViewContainer  from '../../containers/LocationPage/activitiesViewContainer.js';
import ShipmentsViewContainer from '../../containers/LocationPage/shipmentsViewContainer';
import WorkOrderViewContainer   from '../../containers/LocationPage/workOrderViewContainer.js';
import CuttingViewContainer     from '../../containers/LocationPage/cuttingViewContainer.js';
import KittingViewContainer     from '../../containers/LocationPage/kittingViewContainer.js';
import ReportedCutsOverlay from './CuttingView/ReportedCutsOverlay/reportedCutsOverlay';
import {getEnumValue, enumTypes} from '../../utils/enumHelper';
import SmartSelectionPageContainer from '../../containers/SmartSelectionPage/smartSelectionContainer';
import TasksViewContainer from '../../containers/LocationPage/tasksViewContainer';
import EditAssetAttributesDialog from '../Dialogs/EditAssetAttributesDialog/editAssetAttributesDialog';
import PrintPdfLabelsDialog from '../Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialog';
import AddToGroupDialog from './AssetsView/Dialogs/AddToGroupDialog/addToGroupDialog';
import {
  isTransitArea,
  isAFPStation,
  isShippingPreparation,
  isOutgoingShipments,
  isIncomingShipments, isFreezerLocation
} from '../../utils/locationHelper';
import PickListsPageContainer from '../../containers/PickListsPage/pickListsContainer';
import WorkOrderDialog from './WorkOrderView/WorkOrderDialog/workOrderDialog';
import ReportCutDialog from './CuttingView/ReportCutDialog/reportCutDialog';
import AfpOperationWizard from '../Wizards/AfpOperationWizard/afpOperationWizard';
import CreateKitWizard from './KittingView/CreateKitWizard/createKitWizard';
import AddToShipmentDialog from './AssetsView/Dialogs/AddToShipmentDialog/addToShipmentDialog';
import ShipToCustomerDialog from './ShipmentsView/Dialogs/ShipToCustomerDialog/shipToCustomerDialog';

require('./locationPage.scss');


const TabKeys = {
  CUTTING: 'cutting',
  KITTING: 'kitting',
  WORKORDER: 'workOrder',
  TASKS: 'tasks',
  ASSETS: 'assets',
  ACTIVITY: 'activity',
  SHIPMENTS: 'shipments',
  SMART_SELECTION: 'smartSelection',
  PICK_LISTS: 'pickLists',
};

export default class LocationPage extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.');

    let locationId = this.props.match.params.locationId;
    this.props.actions.locationPage.setLocationId(locationId);
  }

  componentDidMount() {
    let locationId = this.props.match.params.locationId;
    this.props.actions.locationPage.load(locationId, true);

    //at the moment this will cause a mismatch between number of assets to actual assets on the grid.
    //this.locationPageTimerRefresh = ScheduleAction("Location Page Timer Refresh", this.props.actions.locationPage.onTimerRefresh, 20);
  }

  componentWillUnmount() {
    this.props.actions.locationPage.ResetGridState();
    //this.locationPageTimerRefresh.kill();
  }

  componentDidUpdate(prevProps) {
    let oldLocationId = prevProps.match.params.locationId;
    let newLocationId = this.props.match.params.locationId;
    if (newLocationId !== oldLocationId) {
      this.props.actions.locationPage.load(newLocationId, true);
    }

    let newTabId = this.props.match.params.tabId;
    //Check if tab changed and resize if needed.
    if(prevProps.match.params.tabId !== newTabId){
      window.dispatchEvent(new Event('resize'));
    }

    if (newTabId && !(Object.values(TabKeys).includes(newTabId))) {
      window.location.hash = '#/PageNotFound';
    }
  }

  getLocationDetails = () => {
    return this.props.sData.get('locationDetails');
  }

  isInitialized = () => {
    if (this.getLocationDetails()) {
      return true;
    }

    return false;
  }

  isSchedulerEnabled = () => {
    return PermissionManager.getOrgPreferences().schedulerEnabled;
  };

  hasOperationOfType = ( operationType, operations) => {
    if (operations) {
      let found =  operations.find((op) => {
        return op.operationType.name === operationType;
      });
      return !!found;
    }
    return false;
  };

  renderChartsSection = () => {
    let selectedTab = this.getSelectedTab();
    let currentChartsItems  = this.props.chartsData.get(selectedTab);

    if (!currentChartsItems) {
      return null;
    }

    let objectsArray = [];
    currentChartsItems.forEach((item, index) => {
      if (item.get('unkittedCuts')) {
        objectsArray.push(<ReportedCutsOverlay  key='reportedCutsOverlay' data={item} cancelCutReportAction={this.props.actions.cuttingViewActions.onCancelCutReportClick}>
          <div>
            <InfoChart data={item} className='pointer'/>
          </div>
        </ReportedCutsOverlay>);
      } else {
        objectsArray.push(<InfoChart key={`topbarChart_${index}`} data={item}/>);
      }
    });

    return (
      <div className="right-top-charts-main-container">
        {objectsArray}
      </div>
    );
  };

  renderTasksTab = () => {
    const locationDetails = this.getLocationDetails();
    if (this.isSchedulerEnabled() && locationDetails.isResource) {
      return (
        <Tabs.Tab eventKey={TabKeys.TASKS} title={this.labels.get('tabs.tasks')} animation={false}>
          <TasksViewContainer match={this.props.match}/>
        </Tabs.Tab>
      );
    }

    return null
  };

  renderWorkOrderTab = () => {
    let locationDetails = this.getLocationDetails();
    if (!locationDetails || !locationDetails.isStation || (this.isSchedulerEnabled() && locationDetails.isResource)) {
      return null;
    }

    return (
      <Tabs.Tab eventKey={TabKeys.WORKORDER} tabClassName="wide" title={this.labels.get('tabs.workorders')} animation={false}>
        <WorkOrderViewContainer match={this.props.match}/>
      </Tabs.Tab>
    );
  };

  renderAssetsTab = () => {
    let counters  = this.props.assetsData.get('counters');
    let assetsCounter = ' (' + (counters?.get('allAssets') ?? 0) + ')';

    return (
      <Tabs.Tab eventKey={TabKeys.ASSETS}  tabClassName="wide" title={this.labels.get('tabs.assets') + assetsCounter} animation={false}>
        <AssetsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  };

  renderActivityTab = () => {
    let locationDetails = this.getLocationDetails();
    if (isTransitArea(locationDetails)) {
      return null;
    }
    return (
      <Tabs.Tab eventKey={TabKeys.ACTIVITY} title={this.labels.get('tabs.activity')} animation={false}>
        <ActivitiesViewContainer match={this.props.match} actions/>
      </Tabs.Tab>
    );
  };

  renderShipmentsTab = () => {
    let locationDetails = this.getLocationDetails();
    if (isTransitArea(locationDetails)  ||
        isShippingPreparation(locationDetails) ||
        isOutgoingShipments(locationDetails) ||
        isIncomingShipments(locationDetails)
    ) {
      return (
        <Tabs.Tab eventKey={TabKeys.SHIPMENTS} title={this.labels.get('tabs.shipments')} animation={false}>
          <ShipmentsViewContainer match={this.props.match} actions/>
        </Tabs.Tab>
      );
    }
  };

  renderCuttingTab = () => {
    let locationDetails = this.getLocationDetails();

    //Not relevant if the location is not a station type.
    if (!locationDetails || !locationDetails.isStation) {
      return null;
    }
    //Check if this station includes CUT operation
    if (!locationDetails.operations || !this.hasOperationOfType(getEnumValue(enumTypes.OPERATION_TYPE)('CUT'), locationDetails.operations)) {
      return null;
    }

    return (
      <Tabs.Tab eventKey={TabKeys.CUTTING} title={this.labels.get('tabs.cutting')} animation={false}>
        <CuttingViewContainer />
      </Tabs.Tab>
    );
  };

  renderKittingTab = () => {
    let locationDetails = this.getLocationDetails();
    if (!locationDetails || !locationDetails.isStation) {
      return null;
    }

    //Check if this station includes KIT operation
    if (!locationDetails.operations || !this.hasOperationOfType(getEnumValue(enumTypes.OPERATION_TYPE)('KIT'), locationDetails.operations)) {
      return null;
    }
    return (
      <Tabs.Tab eventKey={TabKeys.KITTING} title={this.labels.get('tabs.kitting')} animation={false}>
        <KittingViewContainer />
      </Tabs.Tab>
    );
  };

  renderSmartSelectionTab = () => {
    let locationDetails = this.getLocationDetails();
    if (this.isSmartSelectionEnabled() &&  PermissionManager.hasLocationSmartSelectionTabPermissions() && isFreezerLocation(locationDetails) ) {
      return (
        <Tabs.Tab eventKey={TabKeys.SMART_SELECTION} tabClassName="wide" title={this.labels.get('tabs.smartSelection')} animation={false}>
          <SmartSelectionPageContainer/>
        </Tabs.Tab>
      );
    }
    return null;
  };

  renderPickListTab = () => {
    let locationDetails = this.getLocationDetails();
    if (this.isSmartSelectionEnabled() && PermissionManager.hasLocationPickListTabPermissions() && isFreezerLocation(locationDetails)) {
      return (
        <Tabs.Tab eventKey={TabKeys.PICK_LISTS} title={this.labels.get('tabs.pickList')} animation={false}>
          <PickListsPageContainer/>
        </Tabs.Tab>
      );
    }
    return null;
  };

  isSmartSelectionEnabled = () => {
    return PermissionManager.getOrgPreferences().smartSelectionEnabled;
  };

  onTabClick = (tabKey) => {
    if (tabKey) {
      let locationId = this.props.match.params.locationId;
      let newHash = `#/Location/${locationId}/${tabKey}`;
      if (newHash !== window.location.hash)
        window.location.hash = newHash;
    }
  };

  getSelectedTab = () => {
    let tabId = this.props.match.params.tabId;
    let selectedTab = tabId ? tabId : this.getDefaultTab();
    return selectedTab;
  };

  getDefaultTab = () => {
    let locationDetails = this.getLocationDetails();

    let {isStation, locationType} = locationDetails;
    if(!isStation){
      return TabKeys.ASSETS;
    }
    if (locationType === 'KIT') {
      return TabKeys.KITTING;
    }
    if (locationType === 'CUT' || locationType === 'CUT_KIT') {
      return TabKeys.CUTTING;
    }
    if (this.isSchedulerEnabled() && locationDetails.isResource) {
      return TabKeys.TASKS;
    }

    return TabKeys.WORKORDER;
  };

  renderTabs = () => {
    let selectedTab = this.getSelectedTab();

    return (
      <Tabs id="topbar-tabs" activeKey={selectedTab} tabType="page" onSelect={this.onTabClick}>
        {this.renderCuttingTab()}
        {this.renderKittingTab()}
        {this.renderTasksTab()}
        {this.renderWorkOrderTab()}
        {this.renderAssetsTab()}
        {this.renderShipmentsTab()}
        {this.renderActivityTab()}
        {this.renderSmartSelectionTab()}
        {this.renderPickListTab()}
      </Tabs>
    );
  };

  reloadLocationPage = () => {
    this.props.actions.locationPage.load(this.props.match.params.locationId, false);
  };

  renderImportAttributesUpdateWizardDialog = () => {
    let sData = this.props.sImportAttributesUpdateWizardData;

    if (sData && sData.get('show')) {
      return (
        <ImportAttributesUpdateWizard
          importType={sData.get('importType')}
          actions={this.props.actions.importAttributesUpdateWizardActions}
          sData={sData}
          reloadParentComponent={this.reloadLocationPage}
        />
      );
    }
    return null;
  };
  renderAfpOperationWizardDialog = () => {
    let sData = this.props.sAfpOperationWizardData;

    if (sData && sData.get('show')) {
      return (
        <AfpOperationWizard
          actions={{...this.props.actions.afpOperationWizardActions, ...this.props.actions.fetchEntitiesActions}}
          sData={sData}
          reloadParentComponent={this.reloadLocationPage}
        />
      );
    }
    return null;
  };

  renderReportCutDialog = () => {
    const locationOperations = this.getLocationDetails()?.operations
    return (
      <PL_DialogWrapper
        dialogComponent={ReportCutDialog}
        show={this.props.sReportCutDialog.get('show')}
        sData={this.props.sReportCutDialog}
        actions={this.props.actions.reportCutDialog}
        fetchEntitiesActions={this.props.actions.fetchEntitiesActions}
        sLocationOperations={locationOperations}
      />
    )
  }

  render() {
    // Don't try to render unless the data is ready.
    if (!this.isInitialized()) {
      return null;
    }

    return (

      <div className={cn('cut-kit-station', {'afp-station': isAFPStation(this.getLocationDetails())}) }>
        {this.renderChartsSection()}
        <FullScreenTabsSeparator/>
        {this.renderTabs()}


        <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                          show={this.props.sMoveAssetsData.get('show')}
                          actions={this.props.actions.moveAssetsActions}
                          sData={this.props.sMoveAssetsData}
                          reloadParentComponent={this.reloadLocationPage}
                          currentLocationId={Number(this.props.match.params.locationId)}
                          isLocationBound={true}/>
        <PL_DialogWrapper dialogComponent={BringAssetsDialog}
                          show={this.props.sBringAssetsData.get('show')}
                          actions={this.props.actions.bringAssetsActions}
                          sData={this.props.sBringAssetsData}
                          currentLocation={this.props.actions.locationPage.getLocationDetails()}
                          reloadParentComponent={this.reloadLocationPage}/>
        <PL_DialogWrapper dialogComponent={ArchiveAssetsDialog}
                          show={this.props.sArchiveAssetsData.get('show')}
                          sData={this.props.sArchiveAssetsData}
                          actions={this.props.actions.archiveAssetsActions}
                          reloadParentComponent={this.reloadLocationPage} />
        <PL_DialogWrapper dialogComponent={ShelfLifeUpdateDialog}
                          show={this.props.sShelfLifeUpdateData.get('show')}
                          actions={this.props.actions.shelfLifeUpdateActions}
                          sData={this.props.sShelfLifeUpdateData}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper dialogComponent={PrintRfidTagsDialog}
                          show={this.props.sPrintRfidTagsData.get('show')}
                          actions={{...this.props.actions.printRfidTagsActions, ...this.props.actions.fetchEntitiesActions}}
                          sData={this.props.sPrintRfidTagsData}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper dialogComponent={CreateGroupDialog}
                          show={this.props.sCreateGroupData.get('show')}
                          actions={{...this.props.actions.createGroupActions, ...this.props.actions.fetchEntitiesActions}}
                          sData={this.props.sCreateGroupData}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper dialogComponent={EditAssetAttributesDialog}
                          show={this.props.sEditAssetAttributesData.get('show')}
                          actions={this.props.actions.editAssetAttributesActions}
                          sData={this.props.sEditAssetAttributesData}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper dialogComponent={PrintPdfLabelsDialog}
                          show={this.props.sPrintPdfLabelsData.get('show')}
                          actions={this.props.actions.printPdfLabelsActions}
                          sData={this.props.sPrintPdfLabelsData}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper dialogComponent={AddToGroupDialog} show={this.props.sDataAddToGroup.get('show')}
                          actions={{...this.props.actions.addToGroupActions, ...this.props.actions.fetchEntitiesActions}}
                          sData={this.props.sDataAddToGroup}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper dialogComponent={AddToShipmentDialog} show={this.props.sDataAddToShipment.get('show')}
                          actions={{...this.props.actions.addToShipmentActions, ...this.props.actions.fetchEntitiesActions}}
                          sData={this.props.sDataAddToShipment}
                          reloadParentComponent={this.reloadLocationPage}/>
        <PL_DialogWrapper dialogComponent={ShipToCustomerDialog} show={this.props.sDataShipToCustomer.get('show')}
                          actions={{...this.props.actions.shipToCustomerActions, ...this.props.actions.fetchEntitiesActions}}
                          sData={this.props.sDataShipToCustomer}
                          reloadParentComponent={this.reloadLocationPage}/>

        <PL_DialogWrapper
          dialogComponent={WorkOrderDialog}
          sLoggedInUser={this.props.sLoggedInUser}
          show={this.props.sWorkOrderDialogData.get('show')}
          sData={this.props.sWorkOrderDialogData}
          sLocationDetails={this.getLocationDetails()}
          actions={{...this.props.actions.workOrderDialogActions, ...this.props.actions.fetchEntitiesActions}}
        />

        <CreateKitWizard
          actions={{...this.props.actions.createKitWizard, ...this.props.actions.fetchEntitiesActions}}
          sData={this.props.sCreateKitWizard}
          currentCut={this.props.sSelectedCutToKit}
          sLoggedInUser={this.props.sLoggedInUser}
        />

        { this.renderImportAttributesUpdateWizardDialog() }
        { this.renderReportCutDialog() }
        { this.renderAfpOperationWizardDialog() }
      </div>
    )
  }
}











