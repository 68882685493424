import {useEffect} from 'react';
import PropTypes from 'prop-types';
import useLabels from 'infrastructure/js/hooks/useLabels';
import {Prompt} from 'react-router-dom';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Button from 'infrastructure/js/components/controls/Button/button';
import { reduxForm } from 'redux-form';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Validation from 'infrastructure/js/components/controls/controlsValidations';

import './promotionsHeader.scss';

function PromotionsHeader({ actions,
                            slideSpeed=5,
                            pristine=true,
                            handleSubmit,
                            initialize=()=>{ console.error('initialize is missing!'); }
                          }) {

  const labels = useLabels('mat.administration.promotionsManagement.header.');
  const dialogLabels = useLabels('mat.dialog.');
  const navigationLabels = useLabels('mat.navigation.confirmation.');

  useEffect(() => {
    initFormData();
  }, [slideSpeed]);


  const initFormData = () => {
    let initialValue = {
      slideSpeed: slideSpeed,
    }
    initialize(initialValue);
  }

  const onSubmit = (data) => {

    const submitData = {slideSpeedInSeconds: data.slideSpeed};
    actions?.saveSettings(submitData);
  };

  const renderPrompt = () => {
    return (
      <Prompt
        when={!pristine}
        message={() =>
          navigationLabels.get('datanotsaved.text')
        }
      />
    );
  };

  return (
    <div className="promotions-header">
      <InputSection label={labels.get('slideSpeed')} htmlFor="slideSpeed" className="" >
        <TextField id="slideSpeed" name="slideSpeed"
                   normalize={Normalize.number(true, 1, 120)}
                   validate={Validation.required}
        />
      <label>{labels.get('slideSpeed.seconds')}</label>
      </InputSection>

      <Button id="save" bsStyle="primary" disabled={pristine} onClick={handleSubmit(onSubmit)} >
              {dialogLabels.get('save')}
      </Button>

      {renderPrompt()}
    </div>
  );
}

export default reduxForm({
  form: 'promotionsHeader',
})(PromotionsHeader);

PromotionsHeader.propTypes = {
  actions: PropTypes.object.isRequired,
  slideSpeed: PropTypes.number,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  pristine: PropTypes.bool,
}
