import React from 'react';
import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import LastOperationCell from '../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {navigationStates} from '../../../../enums/navigationStates';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
require('./archivedPartsGrid.scss');

class ArchivedPartsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'partType.businessId', filterName: 'partTypeBusinessId', getOptions: true},
    {fieldName: 'assetStatusLabel', filterName: 'assetStatus', getOptions: true},
    {fieldName: 'workOrder.businessId', filterName: 'woBusinessId', getOptions: true},
    {fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'lastStatusChangeDate'},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ];

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'assetStatusLabel',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus'
      },
      {
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.archivingDate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return (params?.data?.m_archivingDate ?? '');
        },
        columnOptions:{
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.partType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'partTypeBusinessId',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.partId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.PART_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ARCHIVED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.PART
            };
          }
        }
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woBusinessId',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ]
  }

  render() {
    return (
      <div className="archive-parts-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ARCHIVE_PARTS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  filterPersistence: true,
                  getRowHeight: () => 84
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'archivedPartsGrid'
  }
)(ArchivedPartsGrid);






