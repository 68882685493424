import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';

import ScanHeader from '../Common/ScanHeader/scanHeader';
import ScanInput from '../Common/ScanInput/scanInput';

import './scanWorkOrderPage.scss';

const scanImg = require('images/scan-illustration.png');
const pageLabels = createLabelHelper('mat.mobile.scanWorkOrderPage.');

const getLastSavedStation = () => {
  return localStorage.getItem('lastSavedStationId') ?? null;
};

const setLastSavedStation = (value) => {
  return localStorage.setItem('lastSavedStationId', value);
};

export function ScanWorkOrderPage({ sPageData, actions, history, handleSubmit: handleFormSubmit, change }) {
  useEffect(() => {
    actions.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const stationId = getLastSavedStation();
    if (stationId) {
      const stations = sPageData.get('stations');
      const station = stations.find(({ value }) => stationId === '' + value);
      if (station) {
        change('station', station);
      }
    }
  }, [sPageData, change]);

  const getHeaderItems = () => {
    let leftButton = {
      id: 'leftButton',
      icon: 'pl pl-arrow-left',
      action: actions.header.onGoToStart,
      actionData: history,
    };

    let middleButton = {
      id: 'middleButton',
      label: pageLabels.get('header.buttons.button.scanWorkOrder'),
    };

    let rightButton = null;

    return { leftButton, middleButton, rightButton };
  };

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      handleFormSubmit(onSubmit)();
    }
  };

  const onSubmit = (data) => {
    if (!data.barcodeInput) {
      return;
    }
    const woId = data.barcodeInput;
    const stationId = data.station?.value;
    handleSubmit(woId, stationId);
  };

  const handleSubmit = (woId, stationId) => {
    let barcodeSections = woId ? woId.split('|') : null;
    //Barcode Example: 4500050319|00010|5000085238|0001|1018003 |N/A| 0000009527|(blank)|
    //more info at SAAS-4761
    if (barcodeSections && barcodeSections.length === 9) {
      woId = barcodeSections[6];
    }
    actions.submit(woId, stationId, onSubmitCallback);
  };

  const handleStationChange = (station) => {
    const { value } = station ?? { value: null };
    change('station', value);
    setLastSavedStation(value);
  };

  const onSubmitCallback = () => {
    let path = '/ScannedWorkorderTasks';
    history.push(path);
  };

  return (
    <div className="scan-workorder-page">
      <ScanHeader {...getHeaderItems()} />

      <div className="content">
        <div className="scan-input-section">
          <label htmlFor="station">{pageLabels.get('selectStation.label')}</label>
          <Combobox
            id="station"
            name="station"
            className="station-select"
            options={sPageData.get('stations')}
            blurInputOnSelect={false}
            placeholder={pageLabels.get('input.placeholder')}
            onChange={handleStationChange}
            hideClearIndicator={false}
          />
        </div>

        <div className="scan-input-section">
          <ScanInput
            id="barcodeInput"
            name="barcodeInput"
            placeholder={pageLabels.get('barcode.placeholder')}
            onKeyPressHandler={onKeyPressHandler}
            onClickHandler={handleFormSubmit(onSubmit)}
            change={change}
            disabled={sPageData.get('loading')}
            error={sPageData.get('error')}
          />
        </div>

        <div className="section prompt-text">
          <label>{pageLabels.get('prompt.text')}</label>
        </div>

        <div className="section barcode-image">
          <img src={scanImg} alt="Plataine Logo" className="pointer" />
        </div>
      </div>
      {sPageData.get('loading') && <Overlay.Loading />}
    </div>
  );
}

ScanWorkOrderPage.propTypes = {
  sPageData: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};

ScanWorkOrderPage.defaultProps = {
  sPageData: {},
  actions: {},
  history: {},
  handleSubmit: () => {
    console.error('ScanWorkOrderPage: handleSubmit is missing');
  },
  change: () => console.error('ScanWorkOrderPage: change is missing'),
};

export default reduxForm({
  form: 'scanWorkOrderPage',
})(ScanWorkOrderPage);
