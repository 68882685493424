import Network from 'infrastructure/js/modules/network';
import * as schedulerService from '../../services/Scheduler/schedulerService';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LAST_TRIGGERS_PANEL_SET_BUSY: 'LAST_TRIGGERS_PANEL_SET_BUSY',
  LAST_TRIGGERS_PANEL_FETCH_DATA_FINISHED: 'LAST_TRIGGERS_PANEL_FETCH_DATA_FINISHED',
  LAST_TRIGGERS_PANEL_UNMOUNT: 'LAST_TRIGGERS_PANEL_UNMOUNT'

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  setBusy: function (payload) {
    return {type: actionTypes.LAST_TRIGGERS_PANEL_SET_BUSY, payload: payload};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.LAST_TRIGGERS_PANEL_FETCH_DATA_FINISHED, payload: payload};
  },
  unmount: function () {
    return {type: actionTypes.LAST_TRIGGERS_PANEL_UNMOUNT};
  }
}

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.init = function () {
  return function (dispatch, getState) {
    api.init(dispatch, getState)();
  };
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.unmount());
  }
};
/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function() {
    dispatch(actions.setBusy(true));

    return schedulerService.getSchedulingTriggersLastStatus()
      .then((response) => {
        dispatch(actions.setBusy(false));
        if(!Network.isResponseValid(response)) {
          console.error('getSchedulingTriggersLastStatus failed', response);
          return {success: false};
        }

        dispatch(actions.fetchDataFinished(response.dataList));
      });
  }
};










