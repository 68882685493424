import Network from 'infrastructure/js/modules/network';
import * as assetService from '../../../services/Assets/assetService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import { api as gridApi} from './assetsManagementGridActions';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.reload = function () {
  return function (dispatch, getState) {
    gridApi.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};


jsxActions.removeAssets = function(data) {
  return function(dispatch, getState) {
    api.removeAssets(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.removeAssets = function(dispatch, getState) {
  return function (data) {
    let assetIds = data?.map(item => item.id)?.toJS() || [];

    let labels = createLabelHelper('mat.administration.psmanagement.assetsManagement.view.');
    let confirmationConfig = {
      message: labels.get('remove.confirmation.message', '', {count: assetIds?.length}),
      cancelHandler: messageDialogApi.close(dispatch, getState),
      submitHandler: () => api.doRemoveAssets(dispatch, getState)({assetIds}),
    };

    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
  }
};

api.doRemoveAssets = function(dispatch, getState) {
  return function(data) {
    messageDialogApi.close(dispatch, getState)();

    assetService.removeAssets(data).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Failed to remove assets', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
      gridApi.reload(dispatch, getState)();
    });
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
