import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../Button/button';

import './datepickerMobile.scss';

export default class PL_DatePickerMobile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showTimeSelectOnly: false,
      popperPlacement: 'bottom',
    };
  }

  openCalendar = () => {
    this.setState({showTimeSelectOnly: false, popperPlacement: 'bottom-start'});
    this.pickerRef?.setOpen(true);
  }

  openTimes = () => {
    this.setState({showTimeSelectOnly: true, popperPlacement: 'bottom-end'});
    this.pickerRef?.setOpen(true);
  }

  setPickerRef = (ref) => {
    this.pickerRef = ref;
    if (this.pickerRef?.input) {
      this.pickerRef.input.readOnly = true;
    }
  }

  render() {
    return (
      <div className={'pl-datepicker-mobile'}>
        <DatePicker
          autoComplete="off"
          fixedHeight
          showTimeSelect={this.state.showTimeSelectOnly}
          showTimeSelectOnly={this.state.showTimeSelectOnly}
          popperPlacement={this.state.popperPlacement}
          {...this.props}
          showMonthDropdown={false}
          showYearDropdown={false}
          setInnerRef={this.setPickerRef}
          preventOpenOnFocus={true}
        />

      <div className='buttons-container'>
        <Button id="selectDay" className="border-btn only-icon" icon='pl pl-header-icon-work_order'
                onClick={this.openCalendar}>
        </Button>
        <Button id="selectTime" className="border-btn only-icon" icon='pl pl-activity-shelf-life'
                onClick={this.openTimes}>
        </Button>
      </div>
      </div>
    );
  }
}

PL_DatePickerMobile.propTypes = {
  id: PropTypes.string.isRequired,
};
