import * as pdfLabelSettingsService from '../../../services/Administration/pdfSettingsService';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {numberToLetters} from '../../../components/Administration/SettingsPage/utils/csvSettingsHelper';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_TAB_SELECTED: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_TAB_SELECTED',

  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FINISHED: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FINISHED',
  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FAILED: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FAILED',

  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS',
  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_FINISHED: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_FINISHED',
  ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_RESET_DATA: 'ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_RESET_DATA',


};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  submitInProgress: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_IN_PROGRESS, payload: payload};
  },
  submitFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FINISHED, payload: payload};
  },
  submitFailed: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_SUBMIT_FAILED, payload: payload};
  },
  fetchDataInProgress: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS, payload: payload};
  },
  fetchDataFinished: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_FETCH_DATA_FINISHED, payload: payload};
  },
  resetState: function (payload) {
    return {type: actionTypes.ADMINISTRATION_SETTINGS_COMMON_PDF_LABEL_SETTINGS_VIEW_RESET_DATA, payload: payload};
  },

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.load = function (entityType) {
  return function (dispatch, getState) {
    return api.fetchData(dispatch, getState)(entityType);
  }
};

jsxActions.submit = function (data, entityType, messageDialogBuilder) {
  return function (dispatch, getState) {
    return api.submit(dispatch, getState)(data, entityType, messageDialogBuilder);
  }
};

jsxActions.preview = function (entityType) {
  return function (dispatch, getState) {
    return api.preview(dispatch, getState)(entityType);
  }
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    dispatch(actions.resetState());
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.fetchData = function (dispatch, getState) {
  return function (entityType) {
    dispatch(actions.fetchDataInProgress());

    let importType = {pdfType: _getPdfType(entityType)};

    let promise1 = pdfLabelSettingsService.fetchPdfLabelPropertiesOptions(importType);
    let promise2 = pdfLabelSettingsService.fetchPdfLabelSettings(importType);

    return Promise.all([promise1, promise2]).then((allResults) => {
      const invalidResponse = allResults.find(response => {
        return !Network.isResponseValid(response);
      });

      if(invalidResponse){
        console.error('Failed to fetch Pdf Label Settings');
        messageDialogApi.responseError(dispatch, getState)(invalidResponse);
        dispatch(actions.fetchDataFinished());
        return {success: false};
      }

      let labels = createLabelHelper('');
      let pdfOptions = allResults[0].dataList.map((item) => {
        let label = item.labelKey ? labels.get(item.labelKey) : item.name;
        return {value: item.id, label: label, data: item}
      });

      let pdfData = {
        settings: (allResults[1].data.settings),
      };

      let data = {
        formData: {
          pdfOptions: pdfOptions,
          pdfData: pdfData,
        },
      };

      dispatch(actions.fetchDataFinished(data));

      return {success: true};
    });

  }
};

api.submit = function (dispatch, getState) {
  return function (data, entityType, messageDialogBuilder) {

    dispatch(actions.submitInProgress());

    data.pdfType =  _getPdfType(entityType);

    return pdfLabelSettingsService.setPdfLabelSettings(data)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Failed to update Pdf label Settings', response);
          dispatch(actions.submitFailed());
          messageDialogApi.responseError(dispatch, getState)(response);
          return {success: false};
        }

        if (messageDialogBuilder) {
          let messageDialogDescriptor = messageDialogBuilder(
            response.data
          );

          messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
        }

        dispatch(actions.submitFinished(response.data));

        return api.fetchData(dispatch, getState)(entityType);
      });
  }
};

api.preview = function (dispatch, getState) {
  return function ( entityType) {
    return pdfLabelSettingsService.preview({pdfType: entityType})
      .then((response) => {
        response.blob().then((blobResponse) => {
          if (blobResponse.size === 0) {
            console.error('Pdf Labels preview failed');
            messageDialogApi.responseError(dispatch, getState)();
            return {success: false};
          }

          let myURL = window.URL || window.webkitURL;
          let file = new Blob([blobResponse], {type: 'application/pdf'});
          let fileURL = myURL.createObjectURL(file);
          window.open(fileURL, '_blank');
        });
      })
      .catch((err) => {
        console.error('Pdf Labels preview catch ', err);
        messageDialogApi.responseError(dispatch, getState)();
        return {success: false};
      })
  }
};




///////////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS


function _getPdfType(entityType) {
  switch (entityType) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return entityType;
  }
  console.error('_getPdfType(): unknown entity type ' + entityType);
  return '';
}











