import { fetchSchedulingLogs } from '../../../../services/Scheduler/schedulerService';
import { gridsNames } from '../../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_SCHEDULING_LOGS, _fetchDataAction);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return fetchSchedulingLogs(query);
  };
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;