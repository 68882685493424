import Network from 'infrastructure/js/modules/network';

export function exportGridRows(url, query = {}) {
  return Network.post(url, query, {blobResponse:true});
}

export function exportRollUsageById(id) {
  return Network.get(`export/rolls/usage/${id}`,{}, {blobResponse:true});
}

export function exportSpoolUsageById(id) {
  return Network.get(`export/spools/usage/${id}`,{}, {blobResponse:true});
}

export function exportSmartSelectionItems(query = {}) {
  return Network.post('export/materials/pickup/items', query, {blobResponse:true});
}
export function exportOnHandInventory(query = {}) {
  return Network.post('export/inventory',query,{blobResponse:true});
}
export function exportOnHandInventoryByWeight(query = {}) {
  return Network.post('export/inventory-by-weight',query,{blobResponse:true});
}

export function exportPickListItems(pickListId, query = {}) {
  return Network.post(`export/picklists/${pickListId}/materials/items`, query,{blobResponse:true});
}
export function exportCsvTemplate(importType) {
  return Network.post('export/templates/import', {importType}, {blobResponse:true});
}

export function exportValidationReportOfImportNests (jobExecutionId) {
  return Network.get(`export/validation/cutAndKits/${jobExecutionId}`, {}, {blobResponse:true});
}

export function exportValidationReportOfImportNestsWithRoll (jobExecutionId) {
  return Network.get(`export/validation/cutPlanWithRoll/${jobExecutionId}`, {}, {blobResponse:true});
}

export function exportValidationReportOfImportNestsWithMultiRoll (jobExecutionId) {
  return Network.get(`export/validation/cutAndKitsMultiRolls/${jobExecutionId}`, {}, {blobResponse:true});
}

export function exportValidationReportOfImportNestsWithAnyRoll (jobExecutionId) {
  return Network.get(`export/validation/cutAndKitsMultiRollsRobustCutting/${jobExecutionId}`, {}, {blobResponse:true});
}


export function exportValidationReportOfOperationSequence (jobExecutionId) {
  return Network.get(`export/validation/kittypes/operationssequence/${jobExecutionId}`, {}, {blobResponse:true});
}

export function exportDashboardTemplate(data) {
  return Network.post('dashboard/demo/export/templates', data, {blobResponse:true});
}

export function exportKitReport(kitIds) {
  return Network.post('export/kits/customer-report', {assetIds: kitIds}, {blobResponse:true});
}

export function exportKitRollsReport(kitIds) {
  return Network.post('export/kits/rolls/report', {assetIds: kitIds}, {blobResponse:true});
}

export function exportGroupReport(groupIds) {
  return Network.post('export/groups/report', {assetIds: groupIds}, {blobResponse:true});
}

export function exportOperationSequence() {
  return Network.post('export/kittypes/operationssequence', {}, {blobResponse:true});
}

export function exportKitTypesDomSchema() {
  return Network.get('json-schemas/batch-import-kittypes-with-operation-sequences', {}, {blobResponse:true});
}

export function exportToolTypesDomSchema() {
  return Network.get('json-schemas/batch-import-tooltypes', {}, {blobResponse:true});
}
