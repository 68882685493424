import { useContext } from 'react';
import { Link, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AccordionContext from 'infrastructure/js/contexts/accordionContext';
import { useLocation } from 'react-router-dom';

import './sideNavAccordionSummary.scss';

export default function SideNavAccordionSummary({ icon: Icon,
                                                  label='',
                                                  onClick,
                                                  link,
                                                  id,
                                                  containsSelectedSubItem=false }) {

  const { disabled = false, expanded, toggle } = useContext(AccordionContext);
  const { pathname } = useLocation();

  const handleChange = (event) => {
    if (toggle) {
      toggle(event);
    }
    if (onClick) {
      onClick(event);
    }
  };

  const arrowIcon = expanded ? 'pl-arrow-down' : 'pl-arrow-right';

  if (link) {
    const active = matchPath(pathname, link)

    return (
      <div className={cn('side-nav-accordion-summary', { disabled, active })} data-uid={id}>
        <Link to={link} onClick={handleChange}>
          {Icon && <Icon className="accordion-summary-icon" />}
          <span className="title">{label}</span>
        </Link>
      </div>
    );
  }

  const selected = !expanded && !disabled && containsSelectedSubItem;

  return (
    <div className={cn('side-nav-accordion-summary', { disabled, selected })} onClick={handleChange} data-uid={id}>
      {Icon && <Icon className="accordion-summary-icon" />}
      <span className="title">{label}</span>
      <span className={`pl ${arrowIcon}`} />
    </div>
  );
}

SideNavAccordionSummary.propTypes = {
  icon: PropTypes.func,
  label: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
  id: PropTypes.string,
  containsSelectedSubItem: PropTypes.bool,
};
