import React from 'react';

import { createLabelHelper } from '../../../../../../infrastructure/js/utils/labelHelper';
import Label from 'infrastructure/js/components/Label/label.js';
import { taskStatusIcons } from '../../../../../assets/svg';

import './taskStatusCell.scss';

const { StartedTask, CompletedTask, ScheduledTask, UnscheduledTask } = taskStatusIcons;

export default class TaskStatusCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('enum.planneroperation.');
  }

  getIcon(value) {
    if (value) {
      switch (value) {
        case 'SCHEDULED':
          return <ScheduledTask/>;
        case 'UNASSIGNED':
          return <UnscheduledTask/>;
        case 'STARTED':
          return <StartedTask/>;
        case 'COMPLETED':
          return <CompletedTask/>;
        default:
          return null;
      }
    }
    return null;
  }

  getDescription = (value) => {
    if (value) {
      let description = '';

      switch (value) {
        case 'SCHEDULED':
          description = this.labels.get('status.SCHEDULED');
          break;
        case 'UNASSIGNED':
          description = this.labels.get('status.UNASSIGNED');
          break;
        case 'STARTED':
          description = this.labels.get('status.STARTED');
          break;
        case 'COMPLETED':
          description = this.labels.get('status.COMPLETED');
      }
      return <span className="description">{description}</span>;
    }

    return null;
  };

  render() {
    let { value } = this.props;

    return (
      <div className="task-status-cell">
        <span>{this.getIcon(value)}</span>
        <Label className="description" text={this.getDescription(value)} />
      </div>
    );
  }
}
