import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import {reduxForm, formValueSelector  } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';

require('./editTaskAttributesDialog.scss');

class EditTaskAttributes extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialogs.edittaskattributes.');
    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();
    this.preselectedAssets = this.props.sData.get('preselectedAssets');
    this.itemToEdit = this.preselectedAssets?.size > 0 ? this.preselectedAssets.get(0) : null;

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };

    this.state = {
      splitEnabled: false,
      dummyKey: 0,
    }
  }

  componentDidMount() {
    if (this.preselectedAssets && this.preselectedAssets.size === 1) {

      if (this.itemToEdit) {
        let initialValues = this.getInitialData(this.itemToEdit);
        this.props.initialize(initialValues);

        this.setState({splitEnabled: this.itemToEdit.splittable});
      }
    }
  }

  getInitialData = (item) => {
      let obj = {
        splitEnabled: !!item.splittable,
        plannedDuration: {
          _hours:   (item.plannedDuration || item.plannedDuration === 0) ? (item.plannedDuration / 60 | 0) : '' ,
          _minutes: (item.plannedDuration || item.plannedDuration === 0) ? (item.plannedDuration % 60 | 0) : '',
        },
        minimumSplitBlock: {
          _hours:   (item.splittable && (item.minimumSplitBlock || item.minimumSplitBlock === 0)) ? (item.minimumSplitBlock / 60 | 0) : '' ,
          _minutes: (item.splittable && (item.minimumSplitBlock || item.minimumSplitBlock === 0)) ? (item.minimumSplitBlock % 60 | 0) : '' ,
        },
        maximumTotalDuration: {
          _hours: (item.splittable && (item.maximumTotalDuration || item.maximumTotalDuration === 0)) ? (item.maximumTotalDuration / 60 | 0) : '',
          _minutes: (item.splittable && (item.maximumTotalDuration || item.maximumTotalDuration === 0)) ? (item.maximumTotalDuration % 60 | 0) : '',
        },
        groupName: item.groupName,
        completedQuantity: item.completedQuantity ?? item.quantity,
    };
    return obj;
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get("cancelToken") ;
    return {
      left: [{
        id:'cancel',
        text: this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get("cancel"),
        action: this.props.sData.get('loading') ? this.onStop : this.onHide,
        enabledOnLoading: cancelTokenEnabled

      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.updatetasks'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action:  this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  onSubmit = (data) => {
    let row = data['select-tasks'];
    let taskIds = (row && row.assetsValue) ? row.assetsValue.map((asset) => {return asset.value}) : [];

    let submitData = {
      tasksAssignmentsIds: taskIds,
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };
    if (this.itemToEdit?.operationStatus === getEnumValue(enumTypes.TASK_STATUS)('COMPLETED')) {
      submitData.completedQuantity = data.completedQuantity;
    }
    else {
     submitData.duration = data.plannedDuration ? DateTimeHelper.ConvertHoursMinutesToMinutes(data.plannedDuration._hours, data.plannedDuration._minutes) : null;
     submitData.splittable = data.splitEnabled;
     submitData.minimumSplitBlock = data.splitEnabled && data.minimumSplitBlock ? DateTimeHelper.ConvertHoursMinutesToMinutes(data.minimumSplitBlock._hours, data.minimumSplitBlock._minutes) : null;
     submitData.maximumTotalDuration = data.splitEnabled && data.maximumTotalDuration ? DateTimeHelper.ConvertHoursMinutesToMinutes(data.maximumTotalDuration._hours, data.maximumTotalDuration._minutes) : null;
     submitData.groupName = data.groupName;
    }

    this.props.actions.submit(submitData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get('confirmation.message.title', "", { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  onCheckboxClick = (val) => {
    this.setState({splitEnabled: !this.state.splitEnabled}, () => {
      this.props.change('[minimumSplitBlock]', '');
      this.props.change('[maximumTotalDuration]', '');
      //Note: workarond - force call to render()
      this.setState({dummyKey: this.state.dummyKey === 0 ? 1 : 0})
    });
  }

  isOperationalTask = () => {
    return (this.itemToEdit?.taskType === 'OPERATIONAL');
  }

  validateMinimumSplitBlock = (value, allValues) => {
    let duration = allValues.plannedDuration;
    if (duration) {
      return Validation.timeField.max(duration)(value, allValues);
    }
    return undefined;
  };

  validateMaximumTotalDuration = (value, allValues) => {
    let duration = allValues.plannedDuration;
    if (duration) {
      return Validation.timeField.min(duration)(value, allValues);
    }
    return undefined;
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  renderSplitSection = () => {
    if (this.isOperationalTask()) {
      return (
        <React.Fragment>
          <InputSection htmlFor="splitEnabled" className="left-side">
            <Checkbox name="splitEnabled" id="splitEnabled"  label={this.labels.get('splitEnabled')}
                      onClick={this.onCheckboxClick}
            />
          </InputSection>

          <InputSection label={this.labels.get('minSplitBlock') + '*'} htmlFor="minimumSplitBlock" className="inline left-side">
            <TimeField id="minimumSplitBlock"
                       name="[minimumSplitBlock]"
                       key={this.state.dummyKey + 'block'}
                       hoursCount={true} maxHoursLength={3}
                       disabled={!this.state.splitEnabled}
                       validate={this.state.splitEnabled ? [Validation.timeField.required, this.validateMinimumSplitBlock] : undefined}
            />
          </InputSection>

          <InputSection label={this.labels.get('maxTotalDuration') + '*'} htmlFor="maximumTotalDuration" className="inline right-side">
            <TimeField id="maximumTotalDuration"
                       name="[maximumTotalDuration]"
                       key={this.state.dummyKey + 'total'}
                       hoursCount={true} maxHoursLength={3}
                       disabled={!this.state.splitEnabled}
                       validate={this.state.splitEnabled ? [Validation.timeField.required, this.validateMaximumTotalDuration] : undefined}
            />
          </InputSection>
        </React.Fragment>)
    }
    return null;
  }

  renderUnassignedTaskAttributes = () => {
    return (
      <>
        <InputSection label={this.labels.get('duration') + '*'} htmlFor="plannedDuration" className="inline left-side">
          <TimeField id="plannedDuration"
                     name="[plannedDuration]"
                     hoursCount={true}
                     maxHoursLength={3}
                     validate={Validation.timeField.required}
          />
        </InputSection>

        <InputSection label={this.labels.get('recipe')} htmlFor="recipe" className='inline right-side' >
          <TextField id="recipe" name="groupName"
                     validate={this.maxLength30} />
        </InputSection>

        {this.renderSplitSection()}

    </>);
  }

  renderCompletedTaskAttributes = () => {
    let woQuantity = this.itemToEdit?.quantity ?? 1;
    return (
      <>
        <InputSection label={this.labels.get('completedQuantity')} htmlFor="completedQuantity" className='inline left-side' >
          <TextField id="completedQuantity" name="completedQuantity" className="short-textfield"
                     normalize={Normalize.number(true, 0, woQuantity)}
          />
          <span className="field-info">{`of ${woQuantity}`}</span>
        </InputSection>
      </>
    );
  }

  renderAttributes = () => {
    switch (this.itemToEdit?.operationStatus) {
      case getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'):
        return this.renderCompletedTaskAttributes();
      case getEnumValue(enumTypes.TASK_STATUS)('UNASSIGNED'):
        return this.renderUnassignedTaskAttributes();
      default:
        return null;
    }
  }

  maxLength30 = Validation.maxLength(30);

  render() {
    let bodyClassName = isEmpty(this.props.sData.get('additionalFields')) ? '' : 'modal-body-overflow';
    return (
      <Dialog
        id="edit-task-attributes-dialog"
        className="edit-task-attributes-dialog"
        bodyClassName={bodyClassName}
        show={!!this.props.sData.get("show")}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get("header.title")}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get("selecttasks") +'*'} className="no-margin" />
          <EntitiesMultiSelect
            id="select-tasks"
            name="select-tasks"
            entitiesTypes={this.entitiesTypes}
            validate={Validation.required}
            preSelectedEntities={this.preselectedAssets}
            fetchConfig={this.fetchConfig}
            autoFocus={true}
            disableOnPreselect={this.preselectedAssets && this.preselectedAssets.size === 1}
            { ...this.props }
          />

        {this.renderAttributes()}

      </Dialog>
    );
  }
}

EditTaskAttributes = reduxForm({
    form: 'editTaskAttributes',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(EditTaskAttributes);

const selector = formValueSelector('editTaskAttributes')

export default EditTaskAttributes = connect( state => {
  return {  fieldsValues: selector(state, 'duration') }
})( EditTaskAttributes );
