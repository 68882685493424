import { gridsNames } from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import { fetchLotsGrid } from '../../services/Lots/lotsService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_LOTS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return fetchLotsGrid(query);
  };
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;
