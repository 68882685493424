import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import ArchivedKitsGrid from './ArchivedKitsGrid/archivedKitsGrid';
import {gridsNames} from '../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import ActivateAssetsDialog from  '../../Dialogs/ActivateAssetsDialog/activateAssetsDialog';
import MoveAssetsDialog     from  '../../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import PrintPdfLabelsDialog       from '../../Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialog';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./archivedKitsPage.scss');

export default class ArchivedKitsPage extends Component {


  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ARCHIVED,
      boEntityFilter: [FetchEntitiesFilters.ARCHIVED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ARCHIVED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('KIT')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getPrintPdfLabelsDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ALL,
      boEntityFilter: [FetchEntitiesFilters.ALL],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('KIT'), getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')]
    };
  };

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        ...(PermissionManager.hasActivateAssetsPermissions() ? [{
          id:'activate',
          label: headerItemsLabels.ACTIVATE,
          icon: 'pl pl-activate',
          counter: sSelectedRowsData.size,
          disabled: !sHasRows,
          action: actions.header.onActivateClick,
          actionData: dialogConfig
        }] : []),
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ARCHIVE_KITS}
        },
      ],

      kebab: {
        disabled: !sHasRows,
        menuItems: [
          {
            id:'moveTo',
            label: headerItemsLabels.MOVE_TO,
            action: actions.header.onMenuItemMoveToClick,
            actionData: this.getMoveAssetsDialogConfig()
          },
          {
            id:'printLabel',
            label: headerItemsLabels.PRINT_LABEL,
            action: actions.header.onMenuItemPrintLabelClick,
            actionData:  this.getPrintPdfLabelsDialogConfig(),
            disabled: !sHasRows,
          },

        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderActivateAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ActivateAssetsDialog}
                        show={this.props.sActivateAssetsDialogData.get('show')}
                        sData={this.props.sActivateAssetsDialogData}
                        actions={this.props.actions.activateAssetsDialogActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  renderMoveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                        show={this.props.sMoveAssetsData.get('show')}
                        sData={this.props.sMoveAssetsData}
                        actions={this.props.actions.moveAssetsActions}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  renderPrintPdfLabelsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={PrintPdfLabelsDialog}
                        show={this.props.sPrintPdfLabelsData.get('show')}
                        actions={this.props.actions.printPdfLabelsActions}
                        sData={this.props.sPrintPdfLabelsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (<div className="archived-kits-page">
        <Header {...headerItems}/>
        <ArchivedKitsGrid actions={{...this.props.actions.archivedKitsGridActions, ...this.props.actions.fetchEntitiesActions}} />

        { this.renderActivateAssetsDialog() }
        { this.renderMoveAssetsDialog() }
        { this.renderPrintPdfLabelsDialog() }
      </div>
    );
  }
};

ArchivedKitsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sArchivedKitsGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};






