// @ts-check


import { CAMERA_EASING_MODE, COLLISION_RANKING_TIERS, MARKER_ANCHOR, MapView, Mappedin, MappedinNode, getVenue } from "@mappedin/mappedin-js";
import { PolygonInfo } from "../types/PolygonInfo";
import MapControl from "../MapControl/MapControl";

import { livemapIcons } from '../../../../assets/svg/index';
import LinkBuilder from "./LinkBuilder";
import MapLabelManager from "../Utils/MapLabelManager";
import LabelManager from "../Utils/LabelManager";
import SearchResultsTransformer from "./SearchResultsTransformer";


/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapLocationSearchResultsBlock} MapLocationSearchResultsBlock
 * 
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultItem} LiveSearchResultItem
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultLocation} LiveSearchResultLocation
 * @typedef {import("../../../types/ServerTypes/Models/LiveSearch").PL_SearchState} PL_SearchState
 * 
 * @typedef {import("@mappedin/mappedin-js/renderer/internal").SmartTooltip} SmartTooltip
 * 
 */


// import zzz from "../../../../assets/svg/livemap/livemap-marker-search-pin.png";


export class MapFloorSelectorLogic {

  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   */
  static process = (refContext) => {
    
    let mapViewFloor = refContext.current.refMapView.current?.currentMap.shortName;
    let sDataFloor = refContext.current.refSData.current?.floorName;
    
    if (!mapViewFloor || !sDataFloor) {
      return;
    }
    
    if (mapViewFloor !== sDataFloor) {
      // console.log('----- REACTING TO FLOOR CHANGE ===> ', );
      MapControl.setFloor(refContext, sDataFloor);
    }
    
  }

}




////////////////////////////////////////
// Helper functions.
//

