import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import'./createSchedulingTriggerDialog.scss';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import DoubleRangeSlider from 'infrastructure/js/components/controls/DoubleRangeSlider/doubleRangeSlider';
import {enumTypes, getEnumValue, getLookupOptions} from '../../../../../../utils/enumHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';


class CreateSchedulingTriggerDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.settings.schedulingAutomation.dialog.createSchedulingTrigger.');
    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.schedulingTriggerCycleOptions = getLookupOptions(enumTypes.SCHEDULING_TRIGGER_CYCLE);
    this.schedulingTriggerTypeOptions = getLookupOptions(enumTypes.SCHEDULING_TRIGGER_TYPE);

    this.state = {curTriggerType: this.itemToEdit?.triggerType || getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('PERIODICAL')};
  }

  componentDidMount() {

    let initialValues = {
      active: true,
      triggerType: getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('PERIODICAL'),
      triggerCycle: getEnumValue(enumTypes.SCHEDULING_TRIGGER_CYCLE)('DAILY'),
      minUnscheduleWOsNum: 50,
      taskDelayHours: 1,
      schedulingRange: {min: 0, max: 30},

    };
    if (this.isEditMode) {
      initialValues = {
        active: this.itemToEdit.active,
        name: this.itemToEdit.name,
        triggerType: this.itemToEdit.triggerType,
        schedulingRange: {
          min: this.itemToEdit.schedulingStartTimeDays,
          max: this.itemToEdit.schedulingEndTimeDays,
        },
        enableReschedule: this.itemToEdit.enableReschedule,

        triggerCycle: this.itemToEdit.triggerCycle ?? getEnumValue(enumTypes.SCHEDULING_TRIGGER_CYCLE)('DAILY'),
        triggerActivationTime: this.itemToEdit.triggerActivationTime ? moment(this.itemToEdit.triggerActivationTime) : null,
        minUnscheduleWOsNum: this.itemToEdit.minUnscheduleWOsNum ?? 50,
        cooldownDurationHours: this.itemToEdit.cooldownDurationHours,
        taskDelayHours: this.itemToEdit.taskDelayHours ?? 1,
      };
    }

    this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  onSubmit = (data) => {
    let newData = {
      active: data.active,
      name: data.name,
      triggerType: data.triggerType,
      schedulingStartTimeDays: data.schedulingRange.min,  //?????
      schedulingEndTimeDays:data.schedulingRange.max,    //??????
      enableReschedule: data.enableReschedule ?? false,
      triggerActivationTime: null,
      triggerCycle: null,
      minUnscheduleWOsNum: null,
      cooldownDurationHours: null,
      taskDelayHours: null,
    };

    if (this.state.curTriggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('PERIODICAL')) {
      newData.triggerActivationTime = DateTimeHelper.ConvertFromDate(data.triggerActivationTime);
      newData.triggerCycle = data.triggerCycle;
    }
    if (this.state.curTriggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('UNSCHEDULED_WORK_ORDERS')) {
      newData.minUnscheduleWOsNum = data.minUnscheduleWOsNum;
      newData.cooldownDurationHours = data.cooldownDurationHours;
    }
    if (this.state.curTriggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('DELAYED_TASKS')) {
      newData.taskDelayHours = data.taskDelayHours;
      newData.cooldownDurationHours = data.cooldownDurationHours;
    }

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }

    this.props.actions.submit(newData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onTriggerTypeChange = (value, oldValue) => {
    this.setState({curTriggerType: value?.value || null});
  };

  renderCooldownTime = () => {
    return (
      <InputSection label={this.labels.get('cooldownTime') + '*'} htmlFor="cooldownDurationHours" className="inline right-side">
        <TextField id="cooldownDurationHours" name="cooldownDurationHours" validate={Validation.required}
                   normalize={Normalize.number(true, 0, 999999)}/>
      </InputSection>
    )
  }

  renderSectionByTriggerType =() => {
    if (this.state.curTriggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('PERIODICAL')) {
      return (
        <>
          <InputSection label={this.labels.get('triggerActivationTime')+'*'} htmlFor="triggerActivationTime" className="inline left-side">
            <DatePicker id="triggerActivationTime"
                        name="triggerActivationTime"
                        dateFormat={DateTimeHelper.getDateTimeFormat()}
                        showTimeSelect
                        timeIntervals={10}
                        validate={Validation.required} />
          </InputSection>

          <InputSection label={this.labels.get('triggerCycle')+'*'} htmlFor="triggerCycle" className="inline right-side">
            <Combobox id="triggerCycle" name="triggerCycle"
                      options={this.schedulingTriggerCycleOptions}
                      isClearable={false}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(this.schedulingTriggerCycleOptions)}
                      validate={Validation.required} />
          </InputSection>
        </>
      )
    }
    if (this.state.curTriggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('UNSCHEDULED_WORK_ORDERS')) {
      return (
        <>
          <InputSection label={this.labels.get('unscheduledWorkOrdersNum') + '*'} htmlFor="minUnscheduleWOsNum"
                        className="inline left-side">
            <TextField id="minUnscheduleWOsNum"
                       name="minUnscheduleWOsNum"
                       normalize={Normalize.number(true, 1, 99999)}
                       validate={Validation.required}/>
          </InputSection>

          {this.renderCooldownTime()}
        </>
      )
    }

    if (this.state.curTriggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('DELAYED_TASKS')) {
      return (
        <>
          <InputSection label={this.labels.get('taskDelay') + '*'} htmlFor="taskDelayHours" className="inline left-side">
            <TextField id="taskDelayHours" name="taskDelayHours" validate={Validation.required}
                       normalize={Normalize.number(true, 0, 99999)}/>
          </InputSection>

          {this.renderCooldownTime()}
        </>
      )
    }
  }

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    return (
      <Dialog
        id="create-scheduling-trigger-dialog"
        className="create-scheduling-trigger-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <InputSection label={this.labels.get('name')+'*'} htmlFor="name" className="inline no-margin left-side">
          <TextField id="name" name="name" className="short-textfield" validate={Validation.required} />
        </InputSection>

        <InputSection htmlFor="active" className="inline right-side">
          <Checkbox name="active" id="active" label={this.labels.get('active')}/>
        </InputSection>

        <InputSection label={this.labels.get('trigger')+'*'} htmlFor="triggerType" className="two-columns ">
          <Combobox id="triggerType" name="triggerType"
                    options={this.schedulingTriggerTypeOptions}
                    onChangeCallback={this.onTriggerTypeChange}
                    isClearable={false}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.schedulingTriggerTypeOptions)}
                    validate={Validation.required} />
        </InputSection>

        {this.renderSectionByTriggerType()}

        <InputSection label={this.labels.get('schedulingPeriod')} htmlFor="schedulingPeriod" className="">
          <DoubleRangeSlider name='schedulingRange' min={0} max={120} />
        </InputSection>

        <InputSection htmlFor="enableReschedule" className="">
          <Checkbox name="enableReschedule" id="enableReschedule" label={this.labels.get('enableReschedule')}/>
        </InputSection>

      </Dialog>
    );
  }
}

CreateSchedulingTriggerDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};

export default reduxForm({
    form: 'CreateSchedulingTriggerDialog',
  }
)(CreateSchedulingTriggerDialog);
