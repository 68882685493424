import PropTypes from 'prop-types';
import useLabels from 'infrastructure/js/hooks/useLabels';
import Label from 'infrastructure/js/components/Label/label';
import {enumTypes, getEnumLabel, getEnumValue} from '../../../../utils/enumHelper';
import moment from 'moment';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

import './schedulingPreferencesCell.scss';

export default function SchedulingPreferencesCell({data={}}) {

  const labels = useLabels('mat.administration.settings.schedulingAutomation.cell.schedulingPreferences.');

  const renderPreferencesByType = () => {
    let prefs = [];
    if (data.triggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('PERIODICAL')) {
      prefs = [
        {
          prefName: labels.get('triggerActivationTime'),
          prefValue: moment(data.triggerActivationTime)?.format(DateTimeHelper.getDateTimeFormat()) ?? '',
        },
        {
          prefName: labels.get('triggerCycle'),
          prefValue: getEnumLabel(enumTypes.SCHEDULING_TRIGGER_CYCLE)(data.triggerCycle),
        },
      ]
    } else if (data.triggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('UNSCHEDULED_WORK_ORDERS')) {
      prefs = [
        {
          prefName: labels.get('unscheduledWorkOrders'),
          prefValue: data.minUnscheduleWOsNum ?? '',
        },
        {
          prefName: labels.get('cooldownDuration'),
          prefValue: `${data.cooldownDurationHours} ${labels.get('hours')}` ?? '',
        },
      ]
    } else if (data.triggerType === getEnumValue(enumTypes.SCHEDULING_TRIGGER_TYPE)('DELAYED_TASKS')) {
      prefs = [
        {
          prefName: labels.get('taskDelay'),
          prefValue: `${data.taskDelayHours} ${labels.get('hours')}` ?? '',
        },
        {
          prefName: labels.get('cooldownDuration'),
          prefValue: `${data.cooldownDurationHours} ${labels.get('hours')}` ?? '',
        },
      ]
    }
    prefs.push(...[
      {
        prefName: labels.get('schedulingPeriod'),
        prefValue: `${data.schedulingStartTimeDays} - ${data.schedulingEndTimeDays} ${labels.get('days')}`,
      },
      {
        prefName: labels.get('rescheduling'),
        prefValue: data.enableReschedule ? labels.get('true') : labels.get('false'),
      },
    ])

    let res = prefs.map((item, index) => {return (
      <div key={'pref-' + index} className='scheduling-preferences-cell-row'>
        <label>{`${item.prefName}:`} </label>
        <Label text={item.prefValue}/>
      </div>
    )});

    return res;
  }

  return (
    <div className="scheduling-preferences-cell">
      {renderPreferencesByType()}
    </div>
  )
}


SchedulingPreferencesCell.propTypes = {
  data : PropTypes.object.isRequired,
};
