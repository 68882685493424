import React, {useMemo} from 'react';
import Header, {headerItemsLabels} from '../../../Common/Header/header';
import {List} from 'immutable';
import {gridsNames} from '../../../../enums/gridsNames';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as externalNotificationsGridActions } from '../../../../actions/Administration/UserSettingsPage/externalNotificationsGridActions';
import { jsxActions as externalNotificationsViewActions } from '../../../../actions/Administration/UserSettingsPage/externalNotificationsViewActions';
import { jsxActions as createExternalNotificationDialogActions } from '../../../../actions/Administration/UserSettingsPage/createExternalNotificationDialogActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions';
import ExternalNotificationsGrid from './ExternalNotificationsGrid/externalNotificationsGrid';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import CreateExternalNotificationDialog from './Dialogs/CreateExternalNotificationDialog/createExternalNotificationDialog';
import {isAllItemsActive, isAllItemsDeactive} from '../../MatSettingsPage/utils/matSettingsHelper';

import'./externalNotificationsView.scss';

const getState = (state) => {
  let grid = state.grid?.get(gridsNames.ADMINISTRATION_USER_SETTINGS_EXTERNAL_NOTIFICATION) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
    sCreateExternalNotificationDialogData: state.administration.getIn(['userSettingsPage', 'createExternalNotificationDialog']),
  };
};

const allActions = {
  headerSettingsActions,
  externalNotificationsViewActions,
  externalNotificationsGridActions,
  createExternalNotificationDialogActions,
  fetchEntitiesActions,
};


export default function ExternalNotificationsView() {
  const labels = useMemo(() => createLabelHelper('mat.administration.userSettings.externalNotifications.view.'), []);
  const { state, actions } = useRedux(allActions, getState);
  const {sHasRows = false, sSelectedRowsData, sCreateExternalNotificationDialogData } = state;

  const getHeaderItems = () => {
    return {
      title: labels.get('title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.externalNotificationsViewActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.externalNotificationsViewActions.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.headerSettingsActions.onCreateExternalNotificationSettings,
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(sHasRows && sSelectedRowsData.size === 1),
          action: actions.headerSettingsActions.onEditExternalNotificationSettings,
          actionData: sSelectedRowsData.get(0)
        },
      ],
      kebab: {
        menuItems: [
          {
            id:'activate',
            label: headerItemsLabels.ACTIVATE,
            action: actions.headerSettingsActions.onActivateExternalNotificationsClick,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size !== 1 || !isAllItemsDeactive(sSelectedRowsData)),
            actionData: sSelectedRowsData
          },
          {
            id:'deactvate',
            label: headerItemsLabels.DEACTIVATE,
            action: actions.headerSettingsActions.onDeactivateExternalNotificationsClick,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size !== 1 || !isAllItemsActive(sSelectedRowsData)),
            actionData: sSelectedRowsData
          },
          {
            id: 'remove',
            label: headerItemsLabels.REMOVE,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            action: actions.externalNotificationsViewActions.removeExternalNotificationSettings,
            actionData: sSelectedRowsData,
          },
        ]
      },
    };
  }

  const reloadPage = () => {
    actions.externalNotificationsViewActions.reload();
  }

  return (
    <div className='external-notifications-view'>
      <Header {...getHeaderItems()}/>
      <ExternalNotificationsGrid gridName={gridsNames.ADMINISTRATION_USER_SETTINGS_EXTERNAL_NOTIFICATION}
                         actions={{...actions.externalNotificationsGridActions, ...actions.fetchEntitiesActions}}
      />

      <PL_DialogWrapper dialogComponent={CreateExternalNotificationDialog}
                        show={sCreateExternalNotificationDialogData.get('show')}
                        actions={{...actions.createExternalNotificationDialogActions, ...actions.fetchEntitiesActions}}
                        sData={sCreateExternalNotificationDialogData}
                        reloadParentComponent={reloadPage}
      />
    </div>
  )
}

ExternalNotificationsView.propTypes = {
};




