import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MenuItem from './menuItem';

export default function MenuItems({ actions={},
                                    filter,
                                    menuItems=[],
                                    expandedItem: expandedItemProp,
                                    defaultExpandedItem,
                                    isFavoritesFull }) {

  const { setExpandedItem, ...itemActions } = actions;

  // check if accordion is controlled externally. it should never change for the lifetime of the component.
  const { current: isControlled } = useRef(expandedItemProp !== undefined);
  const [_expandedItem, _setExpandedItem] = useState(defaultExpandedItem);
  const expandedItem = isControlled ? expandedItemProp : _expandedItem;

  const handleChange = (panel) => (event, isExpanded) => {
    if (isControlled) {
      setExpandedItem(isExpanded ? panel : '');
    } else {
      _setExpandedItem(isExpanded ? panel : '');
    }
  };

  return menuItems
    .filter((category) => {
      const headerFound = category.label.toLowerCase().includes(filter);
      const itemFound = category.items?.some((item) => item.label.toLowerCase().includes(filter));
      return category.isAllowed && (headerFound || itemFound);
    })
    .map((category) => {
      const { items, ...categoryData } = category;
      const filteredItems = items?.filter((item) => item.label.toLowerCase().includes(filter) && item.isAllowed);
      const isAccordionExpanded = categoryData.id === expandedItem || filteredItems?.some((item) => item.id === expandedItem) || !!filter;

      return (
        <MenuItem
          key={categoryData.id}
          itemData={{ ...categoryData, items: filteredItems }}
          expanded={isAccordionExpanded}
          isFavoritesFull={isFavoritesFull}
          onExpandChange={handleChange(categoryData.id)}
          actions={itemActions}
        />
      );
    });
}

MenuItems.propTypes = {
  actions: PropTypes.object,
  menuItems: PropTypes.array,
  expandedItem: PropTypes.string,
  isFavoritesFull: PropTypes.bool,
  defaultExpandedItem: PropTypes.string,
};
