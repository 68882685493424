import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import CommonAdditionalFieldsView from '../Components/CommonAdditionalFieldsView/commonAdditionalFieldsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';

export default class ResinAdditionalFieldsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.expirationdate.view.');
  }

  componentDidMount() {
   this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_ADDITIONAL_FIELDS_RESIN);

   this.props.actions.load(getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'));
  }

  render() {
    return (
        <CommonAdditionalFieldsView labels={this.labels}
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          entityType={getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')}
                          sLoggedInUser={this.props.sLoggedInUser}
        />
    );
  }
}

