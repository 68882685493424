import React from 'react';
import {navigationStates} from '../enums/navigationStates';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import {getEnumValue, enumTypes} from './enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import MaterialHelper from './materialHelper';
import ToolTypeHelper from './toolTypeHelper';



export function getAssetIconComponent(type){
  if(isPart(type)){
    icon = 'fa fa-light fa-layer-group';
    return <i className={`${icon}`}/>;
  }


  let icon = '';
  if(isRoll(type))  {
    icon = 'pl-roll-icon-blue';
  }
  else if(isKit(type))  {
    icon = 'pl-kit2-icon';
  }
  else if(isTool(type)){
    icon = 'pl pl-menu-active-header-tool';
  }
  else if(isGroup(type)){
    icon = 'pl pl-header-icon-container';
  }
  else if(isSpool(type)){
    icon = 'pl pl-header-icon-spool';
  }
  else if(isResin(type)){
    icon = 'pl pl-header-icon-resin';
  }
  return <span className={`pl ${icon}`}/>;
}

export function isRoll(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
}

export function isSpool(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));
}

export function isResin(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'));
}

export function isKit(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('KIT'));
}

export function isPart(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('PART'));
}

export function isTool(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'));
}

export function isGroup(assetType) {
  return (assetType && assetType.toUpperCase() === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
}

export function isAssetArchived(assetStatus) {
  if (assetStatus) {
    let status = assetStatus.toUpperCase();
    return (
      status === getEnumValue(enumTypes.ASSET_STATUS)('CONSUMED') ||
      status === getEnumValue(enumTypes.ASSET_STATUS)('DISQUALIFIED') ||
      status === getEnumValue(enumTypes.ASSET_STATUS)('SHIPPED') ||
      status === getEnumValue(enumTypes.ASSET_STATUS)('CANCELED')||
      status === getEnumValue(enumTypes.ASSET_STATUS)('RETIRED')  ||
      status === getEnumValue(enumTypes.ASSET_STATUS)('PHANTOM')
    );
  }
  return false;
}

export function isAssetInTransition(assetStatus) {
  if (assetStatus) {
    let status = assetStatus.toUpperCase();
    return (
      status === getEnumValue(enumTypes.ASSET_STATUS)('RECEIVED') ||
      status === getEnumValue(enumTypes.ASSET_STATUS)('MISSING') ||
      status === getEnumValue(enumTypes.ASSET_STATUS)('TRANSIENT')
    );
  }
  return false;
}

export function isAssetActivated(assetStatus) {
  if(!PermissionManager.hasEditAssetDetailsPermissions()){
    return false;
  }
  if (assetStatus) {
    let status = assetStatus.toUpperCase();
    return (status === getEnumValue(enumTypes.ASSET_STATUS)('ACTIVE') ||
            status === getEnumValue(enumTypes.ASSET_STATUS)('TOOL_MANUFACTURING')
    );
  }
  return false;
}

export function isAssetRestricted(asset) {
  if (asset && asset.inspectionStatus) {
    return (asset.inspectionStatus === getEnumValue(enumTypes.INSPECTION_STATUS)('IN_QUALITY_INSPECTION') ||
            asset.inspectionStatus === getEnumValue(enumTypes.INSPECTION_STATUS)('FAILED')
    );
  }
  return false;
}

export function isAssetAccessible(assetStatus) {
  return assetStatus ?
    (
      assetStatus !== getEnumValue(enumTypes.ASSET_STATUS)('LOCKED') &&
      assetStatus !== getEnumValue(enumTypes.ASSET_STATUS)('SHIPPED_TO_COLLABORATOR') &&
      assetStatus !== getEnumValue(enumTypes.ASSET_STATUS)('IN_TRANSIT')
    ) : true;
}

export function round(value, precision=2) {
  var multiplier = Math.pow(10, precision || 0);
  return (Math.round(value * multiplier) / multiplier); //.toFixed(precision);
}

export function getNavigationStateByEntityType(entityType) {
  switch (entityType.toUpperCase()) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return navigationStates.ROLL;
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return navigationStates.SPOOL;
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
      return navigationStates.RESIN;
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return navigationStates.KIT;
    case getEnumValue(enumTypes.OBJECT_TYPE)('PART'):
      return navigationStates.PART;
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return navigationStates.GROUP;
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return navigationStates.TOOL;
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
      return navigationStates.WORKORDER;
    case getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT'):
      return '';
    default:
      console.error('getNavigationStateByEntityType(): unknown entity type: ' + entityType);
      return '';
  }
}


export function getAssetEtl(asset, etlLabels) {
  if (asset) {
    if (PermissionManager.isEtlBondEnabled() && asset.exposureTimeLeftBond && asset.exposureTimeLeftBond.value !== null) {
      return {
        value: DateTimeHelper.ConvertMinutesToHoursMinutes(asset.exposureTimeLeftBond.value),
        state: asset.exposureTimeLeftBond.state,
        label: etlLabels ? etlLabels.get('bond') : 'Bond',
      }
    }
    if (asset.exposureTimeLeftCure && asset.exposureTimeLeftCure.value !== null) {
      return {
        value: DateTimeHelper.ConvertMinutesToHoursMinutes(asset.exposureTimeLeftCure.value),
        state: asset.exposureTimeLeftCure.state,
        label: etlLabels ? (!PermissionManager.isEtlBondEnabled() ? '' : etlLabels.get('cure')) : 'Cure',
      }
    }
    return null;

  }
}

export function getAssetExtraData(asset) {
  let entityType = asset?.objectType || '';

  switch (entityType.toUpperCase()) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
      return asset.material ? MaterialHelper.getMaterialAvailableLabel(asset.material.materialName, asset.material.businessId) : '';
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return asset.kitType?.businessId || '';
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return asset.groupType?.businessId || '';
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return asset.toolType ? ToolTypeHelper.getToolTypeFullLabel(asset.toolType.description, asset.toolType.businessId) : '';
    default:
      console.error('getAssetExtraData(): unknown entity type: ' + entityType);
      return '';
  }
}
