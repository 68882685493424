import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {reduxForm, initialize, formValueSelector} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import TimeField from 'infrastructure/js/components/controls/TimeField/timeField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {AddRemoveListExt} from '../../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import ToolTypeKitTypeListItem from '../../../Common/ToolTypeKitTypeListItem/toolTypeKitTypeListItem';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import {enumTypes, getEnumValue, getLookupOptions} from '../../../../../../../utils/enumHelper';

import './createToolTypeDialog.scss';


class CreateToolTypeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.matsettings.dialog.createtooltype.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.kitTypesIdsInUse = this.isEditMode ?
      this.itemToEdit.kitTypesWithCapacity?.map((item) => item.kitTypeId) : [];

    this.preSelectedItems = this.getPreselectedItems();

    this.kitTypeConfigurationOptions = getLookupOptions(enumTypes.TOOL_CONFIGURATION_TYPE);

    this.state = {
      totalTokenValue: 0,
      kitTypeConfiguration: this.itemToEdit?.configurationType ?? getEnumValue(enumTypes.TOOL_CONFIGURATION_TYPE)('INTERCHANGEABLE'),
    };
  }

  componentDidMount() {
    let initialValues = {
      active: true,
      kitTypeConfiguration: getEnumValue(enumTypes.TOOL_CONFIGURATION_TYPE)('INTERCHANGEABLE'),
    };

    if (this.isEditMode) {
      const usageTimeObj = DateTimeHelper.ConvertMinutesToDaysHoursMinutesObject(this.itemToEdit.maxManufactureUsageTime)
      const maintenanceTimeObj = DateTimeHelper.ConvertMinutesToHoursMinutesObject(this.itemToEdit.maintenanceDuration);
      initialValues = {
        active: this.itemToEdit.active,
        code: this.itemToEdit.businessId,
        name: {value: this.itemToEdit.id, label: this.itemToEdit.description || '', data: this.itemToEdit},
        maintenanceUsageTime_days: usageTimeObj.days || '',
        maintenanceUsageTime_hours: usageTimeObj.hours || '',
        maintenanceUsageTime_minutes: usageTimeObj.minutes || '',
        maintenanceDuration_hours: maintenanceTimeObj.hours,
        maintenanceDuration_minutes: maintenanceTimeObj.minutes,
        cycleCount: this.itemToEdit.maxNumOfCycles,
        kitTypeConfiguration: this.itemToEdit.configurationType,
      };
    }

    initialValues.toolCategory = this.isEditMode ?
      {value: this.itemToEdit.toolCategory?.id, label: this.itemToEdit.toolCategory?.businessId, data: this.itemToEdit.toolCategory}:
      this.getDefaultToolCategory();

    initialValues.kitTypes = this.isEditMode && this.itemToEdit.kitTypesWithCapacity?.length > 0 ?
      this.itemToEdit.kitTypesWithCapacity?.map((item) => {

        let tokensPerPart = this.getTokensByKitType(item.kitTypeId);
        return {
          kitTypeName: item.kitTypeId,
          capacity: item.capacity,
          tokenValue: (tokensPerPart && item.capacity) ? (tokensPerPart * item.capacity) : null,
        }}) :
      [{capacity: 1}];

    this.props.initialize(initialValues);

    setTimeout(() => {this.updateTotalTokenValue()}, 0);
  }

  getTokensByKitType = (kitTypeId) => {
    return this.getAllKitTypes()?.find((kt => kt.value === kitTypeId))?.data?.tokensPerPart || null;
  }

  getDefaultToolCategory = () => {
    let items = this.getCategories();

    let defaultCategory = items.find(item => item.data?.defaultCategory);
    return defaultCategory || null;
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id: 'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading'))
        }
      ]
    };
  }

  getTools() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.toolTypes ? dialogData.toolTypes : [];
  }
  getCategories() {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.toolCategories ? dialogData.toolCategories : [];
  }

  onSubmit = (data) => {
    const {
      name,
      toolCategory,
      maintenanceUsageTime_days,
      maintenanceUsageTime_hours,
      maintenanceUsageTime_minutes,
      code,
      active,
      cycleCount,
      kitTypeConfiguration,
    } = data;

    let description = name ? name.label : '';
    let maintenanceDuration;
    if (PermissionManager.getOrgPreferences().schedulerEnabled)
    {
      let { maintenanceDuration_hours, maintenanceDuration_minutes } = data;
      maintenanceDuration = DateTimeHelper.ConvertHoursMinutesToMinutes(maintenanceDuration_hours, maintenanceDuration_minutes);
    }
    else maintenanceDuration = 0;


    const maxManufactureUsageTime = DateTimeHelper.ConvertDaysHoursMinutesToMinutes(maintenanceUsageTime_days, maintenanceUsageTime_hours, maintenanceUsageTime_minutes);

    let newData = {
      businessId: code,
      toolCategoryId: toolCategory?.value || null,
      kitTypesWithCapacity: this.createKitTypesData(data.kitTypes),
      active,
      description: description || null,
      maxManufactureUsageTime,
      maintenanceDuration,
      maxNumOfCycles: cycleCount,
      configurationType: kitTypeConfiguration,
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    } else {
      newData.active = active;
    }

    this.props.actions.submit(newData, this.isEditMode, this.props.reloadParentComponent);
  };

  createKitTypesData = (kitTypes) => {
    return kitTypes.map((item) => {
      return {kitTypeId: item.kitTypeName, capacity: item.capacity}
    })
  }

  onHide = () => {
    this.props.actions.hide();
  };

  onKitTypeConfigurationChangeHandler = (newValue) => {
    this.setState({kitTypeConfiguration: newValue?.value },
      () => {
        this.updateTotalTokenValue()
      });
  }

  getAllKitTypes = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData && dialogData.kitTypes ? dialogData.kitTypes : [];
  };

  onKitTypeListItemChanged = () => {
    setTimeout(() => {this.updateTotalTokenValue()}, 0);
  };

  onItemRemoveCallback = () => {
    setTimeout(() => {this.updateTotalTokenValue()}, 0);
  };

  updateTotalTokenValue = () => {
    this.setState({totalTokenValue: this.getTotalTokenValue()});
  };

  getTotalTokenValue = () => {
    let allRows = this.props.fieldsValues;

    if (allRows) {
      if (this.state.kitTypeConfiguration === getEnumValue(enumTypes.TOOL_CONFIGURATION_TYPE)('PARALLEL')) {
        return allRows?.reduce((previous, current) => {
          //+(value) to convert string to number
          let value = current.tokenValue ?? 0;
          return previous + (+(value));
        }, 0);
      }
      else {
        let min = Math.min( ...allRows.map(item => item.tokenValue ?? 0));
        let max = Math.max( ...allRows.map(item => item.tokenValue ?? 0));
        return min === max ? min : `${min} - ${max}`;
      }
    }
    return null;
  }

  getPreselectedItems = () => {
    if (this.itemToEdit) {
      return this.itemToEdit.kitTypesWithCapacity?.map((item) => {
        return {...item, tokensPerPart: this.getTokensByKitType(item.kitTypeId)}
      }) || [];
    }
    return [];
  }

  getKitTypeListItem = (itemProps) => {
    return (
      <ToolTypeKitTypeListItem
        key={itemProps.id + '-' + itemProps.index}
        {...itemProps}
        change={this.props.change}
        labels={this.labels}
        onItemChangeCallback={this.onKitTypeListItemChanged}
      />
    );
  }

  renderToolCategory = () => {
    return (
      <InputSection label={this.labels.get('toolCategory') + '*'} htmlFor="toolCategory"
                    className="inline left-side">
        <Combobox id="toolCategory" name="toolCategory"
                  isClearable={false}
                  options={this.getCategories()}
                  validate={Validation.required}
        />
      </InputSection>
    );
  }

  renderTotalTokens = () => {
    return  (
      <div className="total-token-value-container">
        <label>{this.labels.get('list.totalTokenValue', undefined, { totalTokenValue: this.state.totalTokenValue } )}</label>
      </div>
    )
  }

  renderKitTypesList = () => {
    let allOptions = this.getAllKitTypes();
    return (
      <div className="kit-types-list-container input-section">
        <div className="kit-types-list-title input-section">
          <label className="column">{this.labels.get('list.kitType') + '*'}</label>
          <label className="column">{this.labels.get('list.capacity') + '*'}</label>
          <label className="column">{this.labels.get('list.tokenValue')}</label>
        </div>
        <AddRemoveListExt className="kit-types-list"
                          name='kitTypes'
                          id='kitTypes'
                          itemRendererComponent={this.getKitTypeListItem}
                          itemRendererOptions={allOptions}
                          preSelectedItemsIds={this.kitTypesIdsInUse}
                          preSelectedItems={this.preSelectedItems}
                          addButtonLabel={this.labels.get('list.addButton')}
                          onRemoveCallback={this.onItemRemoveCallback}
                          hideAddAtButton={true}
                          defaultItem={{capacity: 1}}
                          maxItemsToOverflow={4}
                          minFieldsCount={1}/>
        {this.renderTotalTokens()}
      </div>
    )
  }

  maxLength100 = Validation.maxLength(100);
  maxLengthDropDown = Validation.dropdown.maxLength(50);

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');

    return (
      <Dialog
        id="create-tool-type-dialog"
        className="create-tool-type-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active" label={this.labels.get('active')} disabled={this.isEditMode}/>
        </InputSection>

        <InputSection label={this.labels.get('code') + '*'} htmlFor="code" className="inline left-side">
          <TextField id="code" name="code" className="short-textfield"
                     validate={[Validation.required, this.maxLength100]}
          />
        </InputSection>

        <InputSection label={this.labels.get('name')} htmlFor="name" className="inline right-side">
          <Combobox id="name" name="name" options={this.getTools()}
                    allowNewOption={true}
                    validate={[this.maxLengthDropDown]}
          />
        </InputSection>

        { this.renderToolCategory() }

        <InputSection label={this.labels.get('maintenanceUsageTime')} htmlFor="maintenanceUsageTime" className="inline right-side">
          <TimeField id="maintenanceUsageTime" name="maintenanceUsageTime" className="inline right-side" isDays={true} hoursCount={true}/>
        </InputSection>

        <InputSection label={this.labels.get('cyclecount')} htmlFor="cycleCount" className="inline left-side">
          <TextField id="cycleCount" name="cycleCount" className="inline right-side" normalize={Normalize.number(true, 0, 9999)} />
        </InputSection>

        {
          PermissionManager.getOrgPreferences().schedulerEnabled ?
            <InputSection label={this.labels.get('maintenanceDuration')} htmlFor="maintenanceDuration" className="inline right-side">
              <TimeField id="maintenanceDuration" name="maintenanceDuration" className="inline left-side" hoursCount={true} maxHoursLength={3}/>
            </InputSection>
            : null
        }

        <InputSection label={this.labels.get('kitTypeConfiguration') + '*'} htmlFor="kitTypeConfiguration"
                      className={PermissionManager.getOrgPreferences().schedulerEnabled ? 'inline left-side' : 'inline right-side'}>
          <Combobox id="kitTypeConfiguration" name="kitTypeConfiguration"
                    isClearable={false}
                    options={this.kitTypeConfigurationOptions}
                    validate={Validation.required}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.kitTypeConfigurationOptions)}
                    onChangeCallback={this.onKitTypeConfigurationChangeHandler}
          />
        </InputSection>

        { this.renderKitTypesList() }
      </Dialog>
    );
  }
}

CreateToolTypeDialog = reduxForm({
    form: 'createToolTypeDialog',
  }
)(CreateToolTypeDialog);


const selector = formValueSelector('createToolTypeDialog');

export default CreateToolTypeDialog = connect( state => {
  return {  fieldsValues: selector(state, 'kitTypes') }
})( CreateToolTypeDialog );

CreateToolTypeDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};



