import React from 'react';

import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import CommonPdfLabelSettingsView from '../Components/CommonPdfLabelSettingsView/commonPdfLabelSettingsView';
import {SettingsMenuItemsIds} from '../../../../../enums/navigationMenuItemsIds';
import {getEnumValue, enumTypes} from '../../../../../utils/enumHelper';

import'./resinPdfLabelSettingsView.scss';

export default  class ResinPdfLabelSettingsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.expirationdate.view.');
  }

  componentDidMount() {
   this.props.actions.settingsPageActions.onSelectedMenuItemChanged(SettingsMenuItemsIds.NAV_ELEMENT_PDF_LABEL_RESIN);

   this.props.actions.load(getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'));
  }

  render() {
    return (
        <CommonPdfLabelSettingsView labels={this.labels}
                          actions={this.props.actions}
                          sData={this.props.sData}
                          isDirty={this.props.isDirty}
                          entityType={getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')}
        />
    );
  }
}

