import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { SettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import { jsxActions as commonAlertsViewActions} from '../../../../actions/Administration/SettingsPage/commonAlertsViewActions';
import { jsxActions as headerSettingsActions} from '../../../../actions/Header/headerSettingsActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions.js';
import CommonAlertsView from '../../../../components/Administration/SettingsPage/Alerts/Components/CommonAlertsView/commonAlertsView';
import {enumTypes, getEnumValue} from '../../../../utils/enumHelper';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonAlertsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch)
    }
  }
}

let ExposureTimeBondViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonAlertsView']),
      alertType: getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'),
      menuItemId:SettingsMenuItemsIds.NAV_ELEMENT_ALERTS_EXPOSURE_TIME_BOND,
      isDirty: isDirty('rollTabForm')(state) || isDirty('spoolTabForm')(state) || isDirty('resinTabForm')(state) || isDirty('kitTabForm')(state) || isDirty('groupTabForm')(state)
    };
  },

  mapDispatchToProps
)(CommonAlertsView);

export default ExposureTimeBondViewContainer;



