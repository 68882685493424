// @ts-check

import favorite from './favorite-star.svg';
import schedulerBig from './scheduler-big.svg';
import schedulerSmall from './scheduler-small.svg';
import location from './header-icon-location.svg';
import tasks from './tasks.svg';
import report from './report.svg';
import operationsManager from './operations-manager.svg';
import assetsManager from './assets-manager.svg';
import user from './user-outline-white.svg';
import circledUser from './circled-user.svg';
import managerReport from './manager-report.svg';
import inventory from './inventory.svg';
import workOrder from './work-order.svg';
import shipment from './shipment.svg';
import materialSmartSelection from './material-smart-selection.svg';
import pickList from './pick-list.svg';
import onHandInventory from './on-hand-inventory.svg';
import rollSmall from './roll-small.svg';
import kitSmall from './kit-small.svg';
import toolSmall from './tool-small.svg';
import groupSmall from './group-small.svg';
import scrapSmall from './scrap-small.svg';
import productionDashboardSmall from './production-dashboard-sm.svg';
import progressDashboardSmall from './progress-dashboard-sm.svg';
import toolMaintenanceSmall from './tool-maintenance-sm.svg';
import inventoryDashboardSmall from './inventory-dashboard-sm.svg';
import tpoMasterDataSmall from './tpo-master-data-sm.svg';
import userManagementSmall from './user-management-sm.svg';
import rfidSettingsSmall from './rfid-settings-sm.svg';
import billingSettingsSmall from './billing-settings-sm.svg';
import sandbox from './sandbox.svg';
import psManagementSmall from './ps-management-sm.svg';
import locationsAndStationsBig from './locations-stations-big.svg';
import stationSmall from './station-sm.svg';
import locationSmall from './location-sm.svg';
import matSettings from 'infrastructure/assets/svg/header-icon-mat-settings.svg';
import settings from 'infrastructure/assets/svg/header-settings-icon.svg';
import notification from './notification-line.svg';
import error from './header-error.svg';
import circledQuestionMark from './circled-question-mark.svg';
import spool from './header-icon-spool.svg';
import resin from './header-icon-resin.svg';
import part from './header-icon-part.svg';
import lot from './lot-icon.svg';
import schedulingCompleted from './scheduling-completed.svg';
import logs from './logs.svg';


// LiveMap icons
import liveMapSmall               from './livemap/livemap-small.svg';
import liveMapCameraTop           from './livemap/livemap-camera-Top.svg';
import liveMapCameraTopHover      from './livemap/livemap-camera-Top_hover.svg';
import liveMapCameraDefault       from './livemap/livemap-camera-Default.svg';
import liveMapCameraDefaultHover  from './livemap/livemap-camera-Default_hover.svg';

import liveMapFilter_All          from './livemap/livemap-filter--all.svg';
import liveMapFilter_Locations    from './livemap/livemap-filter--locations.svg';
import liveMapFilter_Materials    from './livemap/livemap-filter--materials.svg';
import liveMapFilter_Tools        from './livemap/livemap-filter--tools.svg';
import liveMapFilter_WO           from './livemap/livemap-filter--wo.svg';


import liveMapInput_Find            from './livemap/livemap-searchinput--find.svg';
import liveMapResult_Alert__Error   from './livemap/livemap-searchresult-alert--error.svg';
import liveMapResult_Alert__Warning from './livemap/livemap-searchresult-alert--warning.svg';
import liveMapResult_Item__Location from './livemap/livemap-searchresult-item--location.svg';
import liveMapResult_Item__Material from './livemap/livemap-searchresult-item--material.svg';
import liveMapResult_Item__Station  from './livemap/livemap-searchresult-item--station.svg';
import liveMapResult_Item__Section  from './livemap/livemap-searchresult-item--section.svg';
import liveMapResult_Item__Roll     from './livemap/livemap-searchresult-item--roll.svg';
import liveMapResult_Item__Tool     from './livemap/livemap-searchresult-item--tool.svg';
import liveMapResult_Item__Resin    from './livemap/livemap-searchresult-item--resin.svg';
import liveMapResult_Item__Spool    from './livemap/livemap-searchresult-item--spool.svg';
import liveMapResult_Item__Kit      from './livemap/livemap-searchresult-item--kit.svg';
import liveMapResult_Item__Group    from './livemap/livemap-searchresult-item--group.svg';
import liveMapResult_Item__Part     from './livemap/livemap-searchresult-item--part.svg';
import liveMapResult_Item__WO       from './livemap/livemap-searchresult-item--wo.svg';

// @ts-ignore
// import liveMapResult_Marker_Searchresult_Pin      from './livemap/livemap-marker-search-pin-bitmap.png';
import liveMapResult_Marker_Searchresult_Pin      from './livemap/livemap-marker-search-pin.svg';

// @ts-ignore
import liveMap_Marker_Dot_black_png       from './livemap/livemap_marker_Dot_black.png';

// @ts-ignore
import liveMap_Marker_Dot_white_png       from './livemap/livemap_marker_Dot_white.png';







//Activities icons
import activityLocationChange     from './activity-location-change.svg';
import activityCreateKit          from './activity-create-kit.svg';
import activityIssue              from './activity-issue.svg';
import activityShelfLife          from './activity-shelf-life.svg';
import activityStatusUpdate       from './activity-status-update.svg';
import activityCreateRoll         from './activity-create-roll.svg';
import activityCreateSpool        from './activity-create-spool.svg';
import activityCreateGroup        from './activity-create-group.svg';
import activityReportCompleted    from './activity-report-completed.svg';
import activityIssueResolved      from './activity-issue-resolved.svg';
import activityAssetsDetached     from './activity-asset-detached.svg';
import activityOperationCanceled  from './activity-operation-canceled.svg';
import activityAssetAdded         from './activity-asset-added.svg';
import activityAssetRemoved       from './activity-asset-removed.svg';
import activityCreateWo           from './activity-create-wo.svg';
import activityCreateTool         from './activity-create-tool.svg';
import activityReportMaintenance  from './activity-report-maintenance.svg';
//Activity NEW icons (from UX)
import activityShipmentCreation   from './activity-shipment-creation.svg';
import activityShipmentReadyToShip from './activity-shipment-ready-to-ship.svg';
import activityShipmentDeletion   from './activity-shipment-deletion.svg';
import activityShipmentIncoming   from './activity-shipment-incoming.svg';
import activityShipmentOutgoing   from './activity-shipment-outgoing.svg';

//Status icons
import statusCreated   from './status-created.svg';
import statusReadyToShip  from './status-ready-to-ship.svg';
import statusInTransit  from './status-in-transit.svg';
import statusReceived  from './status-received.svg';
import statusRejected  from './status-rejected.svg';
import statusActive  from './status-active.svg';
import statusLocked  from './status-locked.svg';

//Task status icons
import StartedTask from './started-task.svg';
import CompletedTask from './completed-task.svg';
import ScheduledTask from './scheduled-task.svg';
import UnscheduledTask from './unscheduled-task.svg';

export const icons = {
  favorite,
  schedulerBig,
  schedulerSmall,
  operationsManager,
  assetsManager,
  location,
  user,
  circledUser,
  report,
  managerReport,
  inventory,
  workOrder,
  tasks,
  shipment,
  materialSmartSelection,
  pickList,
  onHandInventory,
  rollSmall,
  kitSmall,
  toolSmall,
  groupSmall,
  scrapSmall,
  productionDashboardSmall,
  progressDashboardSmall,
  toolMaintenanceSmall,
  inventoryDashboardSmall,
  tpoMasterDataSmall,
  userManagementSmall,
  rfidSettingsSmall,
  billingSettingsSmall,
  sandbox,
  psManagementSmall,
  locationsAndStationsBig,
  stationSmall,
  locationSmall,
  matSettings,
  settings,
  notification,
  error,
  circledQuestionMark,
  spool,
  resin,
  part,
  lot,
  schedulingCompleted,
  logs,

};

export const activityIcons = {
  activityLocationChange,
  activityCreateKit,
  activityIssue,
  activityShelfLife,
  activityStatusUpdate,
  activityCreateRoll,
  activityCreateSpool,
  activityCreateGroup,
  activityReportCompleted,
  activityIssueResolved,
  activityAssetsDetached,
  activityOperationCanceled,
  activityAssetAdded,
  activityAssetRemoved,
  activityCreateWo,
  activityCreateTool,
  activityReportMaintenance,

  activityShipmentCreation,
  activityShipmentReadyToShip,
  activityShipmentDeletion,
  activityShipmentIncoming,
  activityShipmentOutgoing,
}

export const statusIcons = {
  statusCreated,
  statusReadyToShip,
  statusInTransit,
  statusReceived,
  statusRejected,
  statusActive,
  statusLocked,
};

export const taskStatusIcons = {
  StartedTask,
  CompletedTask,
  ScheduledTask,
  UnscheduledTask,
};


export const livemapIcons = {
  liveMapCameraDefault,
  liveMapCameraDefaultHover,
  liveMapCameraTop,
  liveMapCameraTopHover,
  liveMapSmall,

  liveMapFilter_All,
  liveMapFilter_Locations,
  liveMapFilter_Materials,
  liveMapFilter_Tools,
  liveMapFilter_WO,

  liveMapInput_Find,

  liveMapResult_Alert__Error,
  liveMapResult_Alert__Warning,
  liveMapResult_Item__Location,
  liveMapResult_Item__Material,
  liveMapResult_Item__Station,
  liveMapResult_Item__Section,
  liveMapResult_Item__Roll,
  liveMapResult_Item__Tool,
  liveMapResult_Item__Resin,
  liveMapResult_Item__Spool,
  liveMapResult_Item__Kit,
  liveMapResult_Item__Group,
  liveMapResult_Item__Part,
  liveMapResult_Item__WO,
  liveMapResult_Marker_Searchresult_Pin,

  liveMap_Marker_Dot_black_png,
  liveMap_Marker_Dot_white_png,
};


export default icons;
