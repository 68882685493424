import React from 'react';
import PropTypes from 'prop-types';
import * as ActivityTypeHelper from '../../../../utils/activityTypeHelper';
import Label from 'infrastructure/js/components/Label/label.js';

require('./activityTypeCell.scss');

export default function ActivityTypeCell(props) {

  const {activityType, activityTypeDisplayKey} = props.value;
  const ActivityIcon = ActivityTypeHelper.getActivityTypeIcon(activityType);

  let activityTypeIcon = (typeof(ActivityIcon) === 'string') ?
    <span className={'icon ' + ActivityIcon}> </span> :
    <ActivityIcon className='activity-type__icon'/>

  return (<div className="activity-type-cell">
    {activityTypeIcon}
    <Label className="activity-type__description" text={ActivityTypeHelper.getActivityTypeDescription(activityTypeDisplayKey)} />
  </div>);
}

ActivityTypeCell.propTypes = {
  value: PropTypes.object.isRequired,
};




