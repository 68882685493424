import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DashboardHeader from '../Components/DashboardHeader/dashboardHeader';
import ProgressChart from '../Components/ProgressChart/progressChart';
import CommonKPI from '../Components/CommonKPI/commonKPI';
import CorrelationsGrid from '../Components/CorrelationsGrid/correlationsGrid';
import {gridsNames} from '../../../../enums/gridsNames';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import { List } from 'immutable';
import * as DashboardHelper from '../../utils/dashboardHelper';

require('./progressDashboardPage.scss');

const defectCauseTypes = {
  TOOLS: 'TOOLS',
  LOCATIONS: 'LOCATIONS',
  OPERATIONS: 'OPERATIONS',
};

export default class ProgressDashboardPage extends Component {

  constructor (props) {
    super(props);

    this.labels = createLabelHelper('mat.dashboard.');

    this.state = {
      selectedTab: 'woProgressTab',
      defectCause: defectCauseTypes.TOOLS,
    };
  }

  componentDidMount() {
    this.props.actions.init(true);

    let params = this.props.match.params;
    let state = this.props.location.state;
  }

  componentWillUnmount() {
    this.props.actions.unmount();
  }

  getKPIsData = () => {
    let {sData} = this.props;
    let data = sData.get('kpiData');
    let kpiData = [
      {
        title: this.labels.get('kpi.productionDuration'),
        leftData: data.prodDuration + ' h',
        leftDataClassName: DashboardHelper.getKpiDataClassName(data.prodDuration, data.prodDurationPrevPeriod),
        icon: DashboardHelper.getKpiIcon(data.prodDuration, data.prodDurationPrevPeriod),
        iconClassName: data.prodDuration > data.prodDurationPrevPeriod ? 'red' : 'green',
        rightData: data.prodDurationPrevPeriod +' h',
        timeFrame: DashboardHelper.getKpiTimeFrame(data, this.labels),
      },
      {
        title: this.labels.get('kpi.defectsRate'),
        leftData: DashboardHelper.toPercentage(data.defectsRate),
        leftDataClassName: (data.defectsRate > data.defectsRatePrevPeriod ? 'red' : data.defectsRate < data.defectsRatePrevPeriod ? 'green' : ''),
        icon: DashboardHelper.getKpiIcon(data.defectsRate, data.defectsRatePrevPeriod),
        iconClassName: data.defectsRate > data.defectsRatePrevPeriod ? 'red' : 'green',
        rightData: DashboardHelper.toPercentage(data.defectsRatePrevPeriod),
        timeFrame: DashboardHelper.getKpiTimeFrame(data, this.labels),
      },
    ];
    return kpiData ? kpiData : [];
  };

  getChartData = () => {
    let {sData} = this.props;
    let chartData = sData.get('chartData');
    return chartData ? chartData : [];
  };

  getChartMetadata = () => {
    let {sData} = this.props;
    let chartMetadata = sData.get('chartMetadata');
    return chartMetadata ? chartMetadata : [];
  };

  renderTotalWOsKPI = () => {
    let {sData} = this.props;
    let wosCount = sData.get('wosCount');
    let item = {
      title: this.labels.get('kpi.totalWos'),
      leftData: wosCount,
    };

    return <CommonKPI  className='total-wos-kpi' data={item} />
  };

  renderKPIs = () => {

    let items = this.getKPIsData();

    let KPIs = items.map((item, index) => {
      return <CommonKPI key={index} data={item} />
    });

    return (
      <div className="kpis-container">
        {KPIs}
      </div>
    )
  };

  getTableTitle = (defectCause) => {
    if (defectCause === defectCauseTypes.TOOLS) {
      return this.labels.get('correlations.grid.tools.title');
    }
    if (defectCause === defectCauseTypes.OPERATIONS) {
      return this.labels.get('correlations.grid.operations.title');
    }
    if (defectCause === defectCauseTypes.LOCATIONS) {
      return this.labels.get('correlations.grid.locations.title');
    }
    return '';
  };

  getCorrelationsTable = (defectCause, rows) => {
    if (defectCause === defectCauseTypes.TOOLS) {
      return <CorrelationsGrid key="tools" gridName={gridsNames.DASHBOARD_CORRELATIONS_TOOLS} rows={rows} />;
    }
    if (defectCause === defectCauseTypes.OPERATIONS) {
      return <CorrelationsGrid key="operations" gridName={gridsNames.DASHBOARD_CORRELATIONS_OPERATIONS} rows={rows} />;
    }
    if (defectCause === defectCauseTypes.LOCATIONS) {
      return <CorrelationsGrid key="locations" gridName={gridsNames.DASHBOARD_CORRELATIONS_LOCATIONS} rows={rows} />;
    }
    return null;
  };

  renderCorrelationsTable = (defectCause) => {
    let {sData} = this.props;
    let rows = sData.get(defectCause) ? sData.get(defectCause) : null;
    if (!rows) {
      return null;
    }

    return (
      <div className="cause-table-section">
        <div className="title">{this.getTableTitle(defectCause)}</div>
        {this.getCorrelationsTable(defectCause, rows)}
      </div>
    );

  };

  renderNoRowsOverlay = (loading) => {
    let {sData} = this.props;
    let chartData = sData.get('chartData');

    if (!loading && chartData && chartData.length === 0) {
      return (
        <div className="progress-overlay">
          <Overlay.Label text={this.labels.get('woProgress.chart.noRows')} />
        </div>);
    }
    return null;
  };

  renderLoadingOverlay = (loading) => {
    if (!loading) {
      return null;
    }
    return <Overlay.Loading />;
  };

  onWoProgressSubmitCallback = (data) => {
    let newData = {
      category: data.category.value,
      categoryTypeId: data.categoryType.value,
      woStatus: data.woStatus.value,
    };

    this.props.actions.fetchData(newData);
  };

  onCorrelationsSubmitCallback = (data) => {
    let newData = {
      category: data.category.value,
      categoryTypeId: data.categoryType.value,
      timePeriod: data.timePeriod.value,
      comparisonPeriod: data.compareTo.value,
    };

    this.setState({defectCause: data.cause.value});

    this.props.actions.fetchCorrelationsData(newData);
  };

  onCauseChangeCallback = (value) => {
    this.setState({defectCause: value.value});
  };

  getTabTitles = () => {
    let woProgressTab = <TabTitle title={this.labels.get('tabs.woProgress')} />;
    let correlationsTab = <TabTitle title={this.labels.get('tabs.correlations')} />;

    return {woProgressTab, correlationsTab};
  };


  onTabClick = (key) => {
    this.setState({selectedTab:key});
    if (this.state.selectedTab !== key) {
      setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 0);
    }
  };

  renderComment = () => {
    let text = this.labels.get('bottom.comment');
    return (
      <div className="bottom-comment">{text}</div>
    )
  };

  render() {
    let {sData} = this.props;
    let headerData = sData.get('headerData');
    let chartData = this.getChartData();
    let chartMetadata = this.getChartMetadata();
    let kpiData = sData.get('kpiData');
    let loading = sData.get('loading');

    let tabTitles = this.getTabTitles();
    let selectedTab = this.state.selectedTab;

    let initialData = this.props.location.state;

    return (
      <div className="progress-dashboard-page">

        <Tabs activeKey={selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">
          <Tab eventKey={'woProgressTab'} animation={false} title={tabTitles.woProgressTab}>
            <div>
              {!!headerData && <DashboardHeader form="woProgressTabHeader"
                                              headerData={headerData}
                                              initialData={initialData}
                                              showTimePeriodFilter={false}
                                              showCompareToFilter={false}
                                              showCauseFilter={false}
                                              onSubmitCallback={this.onWoProgressSubmitCallback}
              />}
              {this.renderTotalWOsKPI()}
              {!!(chartData && chartData.length > 0) && <ProgressChart chartData={chartData} chartMetadata={chartMetadata} loading={loading}/>}
              {this.renderNoRowsOverlay(loading)}
            </div>
          </Tab>

          <Tab eventKey={'correlationsTab'} animation={false} title={tabTitles.correlationsTab}>
            <div>
              {!!headerData && <DashboardHeader form="correlationsTabHeader"
                                              headerData={headerData}
                                              initialData={initialData}
                                              showWoStatusFilter={false}
                                              onSubmitCallback={this.onCorrelationsSubmitCallback}
                                              onCauseChangeCallback={this.onCauseChangeCallback}

              />}
              {!!kpiData && this.renderKPIs()}
              {this.renderCorrelationsTable(this.state.defectCause)}
            </div>
          </Tab>
        </Tabs>

        {this.renderComment()}

        {this.renderLoadingOverlay(loading)}

      </div>
    )
  }
};






