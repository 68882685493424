import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from "infrastructure/js/components/Dialog/MessageDialog/messageDialog";
import React from "react";

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  MESSAGEDIALOG_SHOW: "MESSAGEDIALOG_SHOW",
  MESSAGEDIALOG_HIDE: "MESSAGEDIALOG_HIDE"
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.MESSAGEDIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.MESSAGEDIALOG_HIDE, payload: payload };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onHide = function() {
  return function(dispatch, getState) {
    api.close(dispatch, getState)();
  }
}

jsxActions.open = function(messageDialogDescriptor) {
  return function(dispatch, getState) {
    api.open(dispatch, getState)(messageDialogDescriptor);
  }
}


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

// Someone wants to close the dialog.
api.close = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

// Someone opens the dialog.
// options:
//  autoClose- will be closed after this time (milliseconds)
api.open = function(dispatch, getState) {
  return function(messageDialogDescriptor, options = {}) {
    dispatch(actions.show(messageDialogDescriptor));
    if (options.autoClose) {
      setTimeout(function() {
        dispatch(actions.hide());
      }, options.autoClose);
    }
  }
};

api.responseError = (dispatch, getState) => {
  return (response) => {
    let labels = createLabelHelper('');
    let message;
    let className = 'oneBackground';
    let defaultTitle = response?.applicationResponseStatus?.errorCode === 'SEC_10401' ? 'Session Timed Out' : 'Operation Failed';
    let title = labels.get('mat.dialog.messagedialog.operationFailed', defaultTitle);

    let mainError = response && response.applicationResponseStatus ? response.applicationResponseStatus.errors.mainError || [] : [];
    let errorParams = mainError.params || [];
    
    //Check if response valid for format message.
    if (!response || !response.applicationResponseStatus || !response.applicationResponseStatus.message) {
      message = 'Operation Failed';
    } else {
      message = response.applicationResponseStatus.errorCode;
    }

    title = labels.get(message, title, errorParams);

    let messageDialogDescriptor = { title, className, type: 'error' };
    dispatch(actions.show(messageDialogDescriptor));
  };
};

api.showError = (dispatch, getState) =>{
  return (errorLabelKey, errorLabelParams) => {
    let labels = createLabelHelper('');
    let className = 'oneBackground';
    let title = errorLabelKey ? labels.get(errorLabelKey , '', errorLabelParams) : labels.get('mat.dialog.messagedialog.operationFailed');

    let messageDialogDescriptor = {title, className, type:'error'};
    dispatch(actions.show(messageDialogDescriptor));
  };
};

api.showSuccess = (dispatch, getState) =>{
  return (labelKey, labelParams) => {
    let labels = createLabelHelper('');
    let className = 'oneBackground';
    let title = labelKey ? labels.get(labelKey , '', labelParams) : labels.get('mat.dialog.messagedialog.operationSucceeded');

    let messageDialogDescriptor = {title, className, type: 'success'};
    dispatch(actions.show(messageDialogDescriptor));
  };
};

api.showConfirmation = (dispatch, getState) =>{
  return (config) => {
    let dialogLabels = createLabelHelper('mat.dialog.');

    let className = config?.className || '';
    let title = config?.title || 'Confirmation';
    let message = config?.message || 'Are you sure?';
    // let children =  [<MessageDialog.DataRow key={'confirmMessageDataRow'} label={message} value={''}/>];
    let children =  [<MessageDialog.MessageRow key={'confirmMessageDataRow'} text={message} />];
    let buttons = [
      {id:'cancel',text: config?.cancelButtonLabel || dialogLabels.get('cancel'), action: config?.cancelHandler || api.close(dispatch, getState), bsStyle: 'default'},
      {id:'remove',text: config?.submitButtonLabel || dialogLabels.get('remove'), action: config?.submitHandler, bsStyle: 'primary'}
    ];

    let messageDialogDescriptor = {title, children, buttons, className, type: 'warning'};
    dispatch(actions.show(messageDialogDescriptor));
  };
};



