import { useMemo } from 'react';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import DetailsColumn from '../../Common/DetailsComponents/detailsColumn';
import RelatedAssets from '../../AssetPage/OverviewTab/RelatedAssets/relatedAssets';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import './overviewTab.scss';

export default function OverviewTab({ sData }) {
  const loading = sData.get('loading');
  const lotDetails = sData.get('lotDetails');

  const labels = useMemo(() => createLabelHelper('mat.assetpage.overview.details.'), [])

  const rows = [
    {
      label: labels.get('batchNo'),
      className: lotDetails.relatedAssets?.batchNumbers?.length ? 'batch-number-row' : '',
      body: (
        <>
          {lotDetails.relatedAssets?.batchNumbers?.map((batchNo) => (
            <div key={batchNo}>{batchNo}</div>
          ))}
        </>
      ),
    },
    {
      label: labels.get('createdat'),
      body: <span>{DateTimeHelper.FormatDateObjectToDayMonth(lotDetails.createdDate)}</span>,
    },
    {
      label: labels.get('createdby'),
      body: <span>{lotDetails.createdByUser}</span>,
    },
  ];

  return (
    <div className="lot-overview-tab">
      <div className="lot-details">
        <div className="lot-details-section">
          <DetailsColumn rows={rows} />
          <DetailsColumn rows={[]} />
        </div>
      </div>
      <RelatedAssets componentAssets={lotDetails?.relatedAssets?.assets} />
      {loading && <Overlay.Loading />}
    </div>
  );
}
