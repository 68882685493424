import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';

import {jsxActions} from '../../../actions/Login/loginActions.js';
import {jsxActions as messageDialogActions}  from '../../../actions/MessageDialog/messageDialogActions.js';
import { withRouter } from 'react-router-dom'

import MobileLoginPage from 'infrastructure/js/components/MobileLoginPage/mobileLoginPage.js';


function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(jsxActions, dispatch),
      messageDialogActions:   bindActionCreators(messageDialogActions, dispatch),
    },
  }
}

let MobileLoginPageContainer = reduxForm({
    form: 'loginPage'
  }
)(MobileLoginPage);

MobileLoginPageContainer = connect(
  (state)=>{
    return {
      formValues: getFormValues('loginPage')(state),
      sData: state.login
    }
  },
  mapDispatchToProps
)(MobileLoginPageContainer);

export default withRouter(MobileLoginPageContainer);
