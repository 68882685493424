import Network from 'infrastructure/js/modules/network';
import {ImportTypes} from '../../enums/importTypes';

const uploadFile = function (file) {
  return Network.postFile('upload/import/file', file);
};

const uploadAttachment = function (file, assetType, ids) {
  let data = {};
  data[assetType]= ids;
  return Network.postFile('attachments/upload', file, data);
};

const editAttachment = function (id, category) {
  let data = {category: category };
  return Network.put(`attachments/${id}`, data);
};

const downloadAttachment = function (id) {
  return Network.get(`attachments/download/${id}`, {}, {blobResponse:true});
};

const deleteAttachment = function (ids) {
  let data = {ids: ids};
  return Network.delete(`attachments/delete`, data);
};

const validateFile = function (data, assetType) {
  return Network.post(`validate/${normalizeAssetType(assetType)}`, data);
};

const validateFileAsync = function (data, assetType) {
  return Network.polling.post(`batch/validate/${normalizeAssetType(assetType)}`, data);
};

const getValidationInfo = function (data) {
  return Network.post('validation/import/kittypes/operationssequence', data);
}

const importFile = function (data, assetType) {
  const req = (assetType === ImportTypes.IMPORT_ASN_SHIPMENTS) ?
    'batchJobs/JOB_EXECUTION_ID/status' : 'batchJobs/JOB_EXECUTION_ID/status-with-printing';
  return Network.polling.post(`batch/import/${normalizeAssetType(assetType)}`, data, {pollingRequest: req});
};

const startImportFile = function (data, assetType) { //used by the batch operation with Progress bar
  return Network.post(`batch/import/${normalizeAssetType(assetType)}`, data);
};



function normalizeAssetType (assetType) {
  switch (assetType) {
    case ImportTypes.IMPORT_WO:
      return 'workorders';
    case ImportTypes.IMPORT_OPERATION_SEQUENCE:
      return 'kittypes/operationssequence';
    case ImportTypes.IMPORT_TOOL_TYPES:
      return 'toolTypes';
    case ImportTypes.IMPORT_TOOL_GROUPS:
      return 'toolTypes';                 //TODO: L TG - set correct value according to Server API
    case ImportTypes.IMPORT_GROUP_TYPES:
      return 'groupTypes';
    case ImportTypes.IMPORT_PART_TYPES:
      return 'partTypes';
    case ImportTypes.IMPORT_PROJECTS:
      return 'projects';
    case ImportTypes.IMPORT_TOOLS:
      return 'tools';
    case ImportTypes.IMPORT_ASN_SHIPMENTS:
      return 'asn-shipment';
    default:
      return assetType;
  }
}

const checkCutAndKitExistence = function () {
  return Network.post('stations/checkCutAndKitExistence');
};

export default {uploadFile , validateFile, validateFileAsync, getValidationInfo,
                importFile, startImportFile ,checkCutAndKitExistence,
                uploadAttachment, downloadAttachment, deleteAttachment, editAttachment};
