import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Page from '../Common/Page/page';
import {UserSettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import PageNotFoundPageContainer from '../../../containers/PageNotFoundPage/pageNotFoundPageContainer.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {getRoutesFor, getSideNavigatorMenuItems} from '../../../utils/routerHelper';
import UserManagementContainer from '../../../containers/Administration/UserManagementPage/userManagementContainer';
import ExternalNotificationsView from './ExternalNotificationsView/externalNotificationsView';
import'./userSettingsPage.scss';

export default class UserSettingsPage extends Component {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.userSettings.sidenavigator.');
    this.isAuthorizedUser = PermissionManager.hasUserSettingsTabPermissions();
    this.navigationItems = this.getNavigationItems();
  }

  componentDidMount() {
    this.props.actions.init(true);
  }

  getNavigationItems() {
    let items = [];
    if(this.isAuthorizedUser) {
      items = [
        {
          id: UserSettingsMenuItemsIds.NAV_ELEMENT_USER_MANAGEMENT,
          label: this.labels.get('menuitem.userManagement'),
          link: '/Administration/userSettings/userManagement',
          component: UserManagementContainer,
          isAllowed: true
        },
        {
          id: UserSettingsMenuItemsIds.NAV_ELEMENT_EXTERNAL_NOTIFICATIONS,
          label: this.labels.get('menuitem.externalNotifications'),
          link: '/Administration/userSettings/externalNotifications',
          component: ExternalNotificationsView,
          isAllowed: true
        },
      ];
    }
    return items;
  }

  getDefaultRouteConfig() {
    return {
      key: `${this.navigationItems?.[0]?.id}_default`,
      path: '/Administration/userSettings',
      redirectPath: this.navigationItems?.[0]?.items?.[0]?.link || this.navigationItems?.[0]?.link,
      routeNotFoundComponent: PageNotFoundPageContainer
    }
  }

  render() {
    return (<Page className="mat-settings-page"
                  selectedMenuItemId={this.props.sData.get('selectedMenuItemId')}
                  sideNavigatorMenuItems={getSideNavigatorMenuItems(this.navigationItems)}
                  actions={this.props.actions}
                  routes={getRoutesFor(this.navigationItems, this.getDefaultRouteConfig())}/>);
  }
}







