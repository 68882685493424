import { Map } from 'immutable';

import {default as moveAssetsDialogReducer} from './MoveAssetsDialog/moveAssetsDialogReducer';
import {default as editAssetAttributesDialogReducer} from './EditAssetAttributesDialog/editAssetAttributesDialogReducer.js';
import {default as editWoAttributesDialogReducer} from './EditWoAttributesDialog/editWoAttributesDialogReducer.js';
import {default as bringAssetsDialogReducer} from './BringAssetsDialog/bringAssetsDialogReducer';
import {default as activateAssetsDialogReducer} from './ActivateAssetsDialog/activateAssetsDialogReducer';
import {default as archiveAssetsDialogReducer} from './ArchiveAssetsDialog/archiveAssetsDialogReducer';
import {default as reportMaintenanceDialogReducer } from './ReportMaintenanceDialog/reportMaintenanceDialogReducer';
import {default as shelfLifeUpdateDialogReducer } from './ShelfLifeUpdateDialog/shelfLifeUpdateDialogReducer.js';
import {default as createWorkOrderDialogReducer } from './CreateWorkOrderDialog/createWorkOrderDialogReducer.js';
import {default as EditWorkOrdersStatusDialogReducer } from './EditWorkOrdersStatusDialog/editWorkOrdersStatusDialogReducer.js';
import {default as EditAssetsStatusDialogReducer } from './EditAssetsStatusDialog/editAssetsStatusDialogReducer.js';
import {default as userManagementDialogReducer } from './UserManagementDialog/userManagementDialogReducer';
import {default as printRfidTagsDialogReducer } from './PrintRfidTagsDialog/printRfidTagsDialogReducer';
import {default as printPdfLabelsDialogReducer } from './PrintPdfLabelsDialog/printPdfLabelsDialogReducer';
import {default as batchJobProgressDialogReducer } from './BatchJobProgressDialog/batchJobProgressDialogReducer';
import {default as securityMessageDialogReducer } from './SecurityMessageDialogReducer/securityMessageDialogReducer';
import {default as createAttachmentDialogReducer } from './CreateAttachmentDialog/createAttachmentDialogReducer';
import {default as editAttachmentDialogReducer } from './EditAttachmentDialog/editAttachmentDialogReducer';
import {default as editTaskAttributesDialogReducer } from './EditTaskAttributesDialog/editTaskAttributesDialogReducer';
import {default as aboutDialogReducer } from './AboutDialog/aboutDialogReducer';
import {default as createGroupDialogReducer } from './CreateGroupDialog/createGroupDialogReducer';
import {default as addToPickListDialogReducer } from './AddToPickListDialog/addToPickListDialogReducer';
import {default as addReplaceSpoolsDialogReducer } from './AddReplaceSpoolsDialog/addReplaceSpoolsDialogReducer';
import {default as reportTaskProgressDialogReducer } from './ReportTaskProgressDialog/reportTaskProgressDialogReducer';
import {default as misplacedMaterialAlertRuleDialogReducer } from './MisplacedMaterialAlertRuleDialog/misplacedMaterialAlertRuleDialogReducer';
import {default as processTemperatureLogDialogReducer } from './ProcessTemperatureLogDialog/processTemperatureLogDialogReducer';
import analyticModeSettingsDialogReducer from '../PredefinedList/SchedulerPage/analyticModeSettingsDialogReducer';

let defaultState = {

};

export default function(state = Map(defaultState), action) {

  state = state.set('moveAssetsDialog', moveAssetsDialogReducer(state.get('moveAssetsDialog'), action));
  state = state.set('editAssetAttributesDialog', editAssetAttributesDialogReducer(state.get('editAssetAttributesDialog'), action));
  state = state.set('editWoAttributesDialog', editWoAttributesDialogReducer(state.get('editWoAttributesDialog'), action));
  state = state.set('bringAssetsDialog', bringAssetsDialogReducer(state.get('bringAssetsDialog'), action));
  state = state.set('activateAssetsDialog', activateAssetsDialogReducer(state.get('activateAssetsDialog'), action));
  state = state.set('archiveAssetsDialog', archiveAssetsDialogReducer(state.get('archiveAssetsDialog'), action));
  state = state.set('reportMaintenanceDialog', reportMaintenanceDialogReducer(state.get('reportMaintenanceDialog'), action));
  state = state.set('shelfLifeUpdateDialog', shelfLifeUpdateDialogReducer(state.get('shelfLifeUpdateDialog'), action));
  state = state.set('createWorkOrderDialog', createWorkOrderDialogReducer(state.get('createWorkOrderDialog'), action));
  state = state.set('editWorkOrdersStatusDialog', EditWorkOrdersStatusDialogReducer(state.get('editWorkOrdersStatusDialog'), action));
  state = state.set('editAssetsStatusDialog', EditAssetsStatusDialogReducer(state.get('editAssetsStatusDialog'), action));
  state = state.set('userManagementDialog', userManagementDialogReducer(state.get('userManagementDialog'), action));
  state = state.set('printRfidTagsDialog', printRfidTagsDialogReducer(state.get('printRfidTagsDialog'), action));
  state = state.set('printPdfLabelsDialog', printPdfLabelsDialogReducer(state.get('printPdfLabelsDialog'), action));
  state = state.set('batchJobProgressDialog', batchJobProgressDialogReducer(state.get('batchJobProgressDialog'), action));
  state = state.set('securityMessageDialog', securityMessageDialogReducer(state.get('securityMessageDialog'), action));
  state = state.set('createAttachmentDialog', createAttachmentDialogReducer(state.get('createAttachmentDialog'), action));
  state = state.set('editAttachmentDialog', editAttachmentDialogReducer(state.get('editAttachmentDialog'), action));
  state = state.set('editTaskAttributesDialog', editTaskAttributesDialogReducer(state.get('editTaskAttributesDialog'), action));
  state = state.set('aboutDialog', aboutDialogReducer(state.get('aboutDialog'), action));
  state = state.set('createGroupDialog', createGroupDialogReducer(state.get('createGroupDialog'), action));
  state = state.set('addToPickListDialog', addToPickListDialogReducer(state.get('addToPickListDialog'), action));
  state = state.set('addReplaceSpoolsDialog', addReplaceSpoolsDialogReducer(state.get('addReplaceSpoolsDialog'), action));
  state = state.set('analyticModeSettingsDialog', analyticModeSettingsDialogReducer(state.get('analyticModeSettingsDialog'), action));
  state = state.set('reportTaskProgressDialog', reportTaskProgressDialogReducer(state.get('reportTaskProgressDialog'), action));
  state = state.set('misplacedMaterialAlertRuleDialog', misplacedMaterialAlertRuleDialogReducer(state.get('misplacedMaterialAlertRuleDialog'), action));
  state = state.set('processTemperatureLogDialog', processTemperatureLogDialogReducer(state.get('processTemperatureLogDialog'), action));
  return state;

}






