// @ts-check

import { navigationStates } from '../../enums/navigationStates';
import icons from '../../../assets/svg/index';
import { livemapIcons } from '../../../assets/svg/index';
import React from 'react';


/** @param {string} locationType */
export function getLocationIcon(locationType) {
  switch (locationType) {
    case navigationStates.LOCATION:
      return icons.locationSmall;
    case navigationStates.STATION:
      return icons.stationSmall;
    case navigationStates.NOTIFICATIONS:
      return icons.notification;
    case navigationStates.MATERIAL_SCRAP_VIEW:
      return icons.scrapSmall;
    case navigationStates.MANAGER_REPORT:
      return icons.managerReport;
    case navigationStates.LIVE_MAP:
      return livemapIcons.liveMapSmall;
    case navigationStates.PRODUCTION_DASHBOARD:
      return icons.productionDashboardSmall;
    case navigationStates.PROGRESS_DASHBOARD:
      return icons.progressDashboardSmall;
    case navigationStates.INVENTORY_DASHBOARD:
      return icons.inventoryDashboardSmall;
    case navigationStates.TOOL_MAINTENANCE_DASHBOARD:
      return icons.toolMaintenanceSmall;
    case navigationStates.RAW_MATERIALS:
    case navigationStates.ROLL:
    case navigationStates.ROLLS:
    case navigationStates.ACTIVE_ROLLS:
    case navigationStates.ALERTED_ROLLS:
    case navigationStates.ARCHIVED_ROLLS:
      return icons.rollSmall;
    case navigationStates.SPOOLS:
    case navigationStates.SPOOL:
    case navigationStates.ACTIVE_SPOOLS:
    case navigationStates.ALERTED_SPOOLS:
    case navigationStates.ARCHIVED_SPOOLS:
      return icons.spool;
    case navigationStates.RESINS:
    case navigationStates.RESIN:
    case navigationStates.ACTIVE_RESINS:
    case navigationStates.ALERTED_RESINS:
    case navigationStates.ARCHIVED_RESINS:
      return icons.resin;

    case navigationStates.WORKORDERS:
    case navigationStates.WORKORDER:
    case navigationStates.OPEN_WORKORDERS:
    case navigationStates.COMPLETED_WORKORDERS:
      return icons.workOrder;
    case navigationStates.KITS:
    case navigationStates.KIT:
    case navigationStates.ACTIVE_KITS:
    case navigationStates.ALERTED_KITS:
    case navigationStates.ARCHIVED_KITS:
      return icons.kitSmall;
    case navigationStates.PARTS:
    case navigationStates.PART:
    case navigationStates.ACTIVE_PARTS:
    case navigationStates.ARCHIVED_PARTS:
      return icons.part;
    case navigationStates.GROUP:
    case navigationStates.GROUPS:
    case navigationStates.ACTIVE_GROUPS:
    case navigationStates.ALERTED_GROUPS:
    case navigationStates.ARCHIVED_GROUPS:
      return icons.groupSmall;
    case navigationStates.LOADING:
      return Spinner;
    case navigationStates.USER_MANAGEMENT:
    case navigationStates.USER_SETTINGS:
      return icons.userManagementSmall;
    case navigationStates.MAT_SETTINGS:
      return icons.tpoMasterDataSmall;
    // case navigationStates.MASS_OPERATIONS:
    case navigationStates.ORG_MANAGEMENT:
    case navigationStates.DEMO_ORG_MANAGEMENT:
      return icons.matSettings;
    case navigationStates.PROMOTIONS_MANAGEMENT:
      return icons.circledUser;
    case navigationStates.SETTINGS:
      return icons.settings;
    case navigationStates.PS_MANAGEMENT:
      return icons.psManagementSmall;
    case navigationStates.BILLING:
      return icons.billingSettingsSmall;
    case navigationStates.SANDBOX:
      return icons.sandbox;
    case navigationStates.RFID_SETTINGS:
      return icons.rfidSettingsSmall;
    case navigationStates.PAGE_NOT_FOUND:
      return icons.error;
    case navigationStates.TOOLS:
    case navigationStates.TOOL:
    case navigationStates.ACTIVE_TOOLS:
    case navigationStates.ARCHIVED_TOOLS:
      return icons.toolSmall;
    case navigationStates.SMART_SELECTION:
      return icons.materialSmartSelection;
    case navigationStates.PICK_LISTS:
    case navigationStates.PICK_LIST:
      return icons.pickList;
    case navigationStates.SCHEDULER_GANTT:
    case navigationStates.SCHEDULER_TASKS:
      return icons.schedulerSmall;
    case navigationStates.ON_HAND_INVENTORY:
      return icons.onHandInventory;
    case navigationStates.LOT:
    case navigationStates.LOTS:
      return icons.lot;
    case navigationStates.LOGS:
      return icons.logs;
    case '':
      return;
    default:
      console.error('unknown location type: ' + locationType);
  }
}

/** @param {any} params */
function Spinner(params) {
  return <span className="location-icon fa fa-spinner fa-spin fa-fw" />;
}





