import {fromJS} from 'immutable';
import { actionTypes as organizationsPageActionTypes } from '../../../actions/Administration/OrganizationsPage/organizationsPageActions.js';
import { default as createOrganizationDialogReducer } from './Dialogs/createOrganizationDialogReducer';
import { default as createDemoOrganizationDialogReducer } from './Dialogs/createDemoOrganizationDialogReducer';
import { default as deleteOrganizationDialogReducer } from './Dialogs/deleteOrganizationDialogReducer';
import { default as deleteDemoOrganizationDialogReducer } from './Dialogs/deleteDemoOrganizationDialogReducer';
import { default as orgsDashboardViewReducer } from './orgsDashboardViewReducer';
import { default as demoOrganizationsPageReducer } from './demoOrganizationsPageReducer';

let defaultState = {
  data: {
    selectedTab: 'orgs',
  }
};

export default function (state = fromJS(defaultState), action) {
  state = state.set('createOrganizationDialog', createOrganizationDialogReducer(state.get('createOrganizationDialog'), action));
  state = state.set('createDemoOrganizationDialog', createDemoOrganizationDialogReducer(state.get('createDemoOrganizationDialog'), action));
  state = state.set('deleteOrganizationDialog', deleteOrganizationDialogReducer(state.get('deleteOrganizationDialog'), action));
  state = state.set('deleteDemoOrganizationDialog', deleteDemoOrganizationDialogReducer(state.get('deleteDemoOrganizationDialog'), action));
  state = state.set('orgsDashboardView', orgsDashboardViewReducer(state.get('orgsDashboardView'), action));
  state = state.set('demoOrganizationsPage', demoOrganizationsPageReducer(state.get('demoOrganizationsPage'), action));

  switch (action.type) {
    case organizationsPageActionTypes.ORGANIZATIONSPAGE_TAB_SELECTED:
      return state.setIn(['data', 'selectedTab'], action.payload);
    default:
      return state;
  }
}
