import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions';
import { deleteAlertRules } from '../../../services/Alerts/misplacedMaterialAlertsRulesService';
import { api as misplacedMaterialAlertsRulesGridApi } from './misplacedMaterialRulesGridActions';

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    return api.init(dispatch, getState)();
  };
};

jsxActions.deleteRules = function (selectedRules, alertSettingId) {
  return function (dispatch, getState) {
    return api.deleteRules(dispatch, getState)(selectedRules, alertSettingId);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.init = function (dispatch, getState) {
  return function () {
    misplacedMaterialAlertsRulesGridApi.reload(dispatch, getState)();
  };
};

api.deleteRules = function (dispatch, getState) {
  return function (selectedRules = [], alertSettingId) {
    misplacedMaterialAlertsRulesGridApi.setBusy(true)(dispatch, getState);
    const queryData = {
      ids: selectedRules.map(({ id }) => id),
    };

    return deleteAlertRules(queryData, alertSettingId).then((response) => {
      misplacedMaterialAlertsRulesGridApi.setBusy(false)(dispatch, getState);

      if (!Network.isResponseValid(response)) {
        console.error('Misplaced Material Alert - delete rule failed ', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return { success: false };
      }

      misplacedMaterialAlertsRulesGridApi.reload(dispatch, getState)();
    });
  };
};
