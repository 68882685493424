import React from 'react';
import {useParams} from 'react-router-dom';
import ActivityLogGrid from './ActivityLogGrid/activityLogGrid';

require('./activityLogTab.scss');

const TAB_ID = 'activityLog';

export default function ActivityLogTab(props) {

    let { tabId } = useParams();

    if (tabId !== TAB_ID ) {
      return null;
    }

    return (
      <div className="wo-activity-log-view">
        <ActivityLogGrid  actions={props.actions.activityLogGridActions} />
      </div>
    );
}
