import ProgressBreakdownCell from '../ProgressBreakdownCell/progressBreakdownCell';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper';

export default function TasksProgressCell({ value }) {
  const progressItems = getProgressItems(value);
  return <ProgressBreakdownCell value={{ items: progressItems, tooltip: <TasksProgressCellTooltip items={progressItems} /> }} />;
}

function getProgressItems(statuses) {
  if (!statuses) return [];

  const statusColors = getTaskStatusColorsMap();
  const total = Object.values(statuses).reduce((acc, value) => acc + value, 0);
  const order = ['COMPLETED', 'STARTED', 'SCHEDULED', 'UNASSIGNED'];

  return Object.keys(statuses)
    .map((_status) => {
      const status = _status.toUpperCase();
      const value = statuses[_status];
      return {
        status,
        color: statusColors[status],
        width: (value / total) * 100,
        value: value,
      };
    })
    .filter((item) => item.value > 0)
    .sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status));
}

function TasksProgressCellTooltip({ items }) {
  return (
    <div style={{ textTransform: 'capitalize', padding: '5px' }}>
      {items?.map((item) => (
        <div key={item.status} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
          <div style={{ backgroundColor: item.color, width: '10px', height: '10px', borderRadius: '10px', marginRight: '5px' }} />
          <span>
            {getOperationStatusLabel(item.status)}: {item.value}
          </span>
        </div>
      ))}
    </div>
  );
}

function getOperationStatusLabel(value) {
  if (!value) return '';

  const labels = createLabelHelper('enum.planneroperation.');
  switch (value) {
    case 'SCHEDULED':
      return labels.get('status.SCHEDULED');
    case 'UNASSIGNED':
      return labels.get('status.UNASSIGNED');
    case 'STARTED':
      return labels.get('status.STARTED');
    case 'COMPLETED':
      return labels.get('status.COMPLETED');
    default:
      return '';
  }
}

function getTaskStatusColorsMap() {
  const { completed, started, scheduled, unscheduled } = getSystemColors();

  return {
    SCHEDULED: scheduled,
    STARTED: started,
    COMPLETED: completed,
    UNASSIGNED: unscheduled,
  };
}
