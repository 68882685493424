import * as boHelper from '../batchOperationDialogActionsHelper';
import assetPrintTagsService from '../../../services/Assets/assetPrintTagsService';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('PRINT_RFID_TAGS_DIALOG'),
  PRINT_RFID_TAGS_DIALOG_SHOW: 'PRINT_RFID_TAGS_DIALOG_SHOW',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  ...boHelper.getActions('PRINT_RFID_TAGS_DIALOG'),
  show: function (payload) {
    return { type: actionTypes.PRINT_RFID_TAGS_DIALOG_SHOW, payload: payload };
  },
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
const config = {
  submitMethod: (data) => {
    if (data.isGeneric) {
      return assetPrintTagsService.printGenericRfidTags(data);
    } else {
      return assetPrintTagsService.printRfidTagsNew(data);
    }
  },
  submitWithProgressMethod: (data) => {
    if (data.isGeneric) {
      const { tagsCount, printerId} = data;
      return assetPrintTagsService.printGenericRfidTags({tagsCount, printerId});
    } else {
      const { tagsCount, printerId, assetIds } = data;
      return assetPrintTagsService.printRfidTagsNew({ tagsCount, printerId, assetIds });
    }
  },
  dialogReduxStorageName: 'printRfidTagsDialog',
};

export let api = {
  ...boHelper.getApiActions(actions, config),
  fetchPrintersReady: function (dispatch, getState) {
    return function (printers) {
      dispatch(actions.fetchPrintersReady(printers));
    };
  },
  show: function (dispatch, getState) {
    return function (assets, isGeneric, printers, data) {
      let payload = { assets, isGeneric, printers, data };
      dispatch(actions.show(payload));
    };
  },
  submitWithConfirmation: function (dispatch, getState) {
    return function (data, messageDialogBuilder, reloadParentComponent) {
      let labels = createLabelHelper('mat.locationpage.view.assets.printRfidTagsDialog.');

      let totalTagsCount = data.assetIds?.length ? data.tagsCount * data.assetIds?.length : data.tagsCount;

      let confirmationConfig = {
        message: labels.get('confirmationBefore.message.title', '', {count: totalTagsCount, printerName: data.printerName}),
        submitButtonLabel: labels.get('confirmationBefore.footer.ok'),
        cancelHandler: messageDialogApi.close(dispatch, getState),
        submitHandler: () => api.doSubmit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent),
      };

      messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
    }
  },
  doSubmit: function(dispatch, getState) {
    return function (data, messageDialogBuilder, reloadParentComponent) {
      messageDialogApi.close(dispatch, getState)();
      api.submitWithProgress(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
    }
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...boHelper.getJsxActions(api),
  submitWithConfirmation: function (data, messageDialogBuilder, reloadParentComponent) {
    return function (dispatch, getState) {
      api.submitWithConfirmation(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent)
    }
  },
};
