import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {headerItemsLabels} from '../../../Common/Header/header';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import CreatePrinterDialog from './Dialogs/CreatePrinterDialog/createPrinterDialog.js';
import PrintersViewGrid from './PrintersViewGrid/printersViewGrid';
import {RfidSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import {reduxForm} from 'redux-form';
import RfidHeader from '../Common/rfidHeader';

class PrintersView extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.administration.rfidsettings.printers.view.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
  }

  componentDidMount() {
    this.props.actions.rfidSettingsPageActions.onSelectedMenuItemChanged(RfidSettingsMenuItemsIds.NAV_ELEMENT_PRINTERS);
    this.props.actions.init(false);
  }

  handleRemovePrinters = () => {
    let selectedPrintersIds = this.props.sSelectedRowsData.map((item) => {
      return item.id;
    });

    this.props.actions.onRemovePrintersClick(this.getMessageDialogBuilder(), selectedPrintersIds);
  }


  getMessageDialogBuilder = () => {
    return (fnCloseDialog, callback) => {
      let title = this.labels.get('operation.removeprinters.confirmation.title');
      let type = 'warning';
      let message = this.labels.get('operation.removeprinters.confirmation.message');
      let children =  [<div key={'confirmMessageDataRow'}>{message}</div>];
      let buttons = [
        {id:'cancel',text: this.dialogLabels.get('cancel'), action: fnCloseDialog, bsStyle: 'default'},
        {id:'remove',text: this.labels.get('operation.removeprinters.confirmation.button.remove'), action: callback , bsStyle: 'primary'}
      ];
      let className = 'remove-printers-confirm-message';
      let messageDialogDescriptor = {title, buttons, children,  className, type};
      return messageDialogDescriptor;
    };
  };

  getHeaderItems() {
    let {actions} = this.props;
    let hasRows = this.props.sHasRows;
    let selectedRowsData = this.props.sSelectedRowsData;
    return {
      dropdown: {
        name : 'deviceAppDropDown',
        id : 'deviceAppDropDown',
        options: this.props.sData.get('deviceAppsOptions'),
        disabled: this.props.sData.get('deviceAppsOptions').length <= 1,
        changeCallback: this.props.actions.onDeviceAppChanged,
      },
      buttons: [
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.header.onCreatePrinterClick,
          disabled: this.props.sData.get('deviceAppsOptions').length === 0
        },
        {
          id:'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(hasRows && selectedRowsData.size === 1),
          action: actions.header.onEditPrinterClick,
          actionData: selectedRowsData.get(0)
        },
        {
          id:'remove',
          label: headerItemsLabels.REMOVE,
          icon: 'pl pl-delete-icon',
          disabled: !(hasRows && selectedRowsData.size >= 1),
          action: this.handleRemovePrinters
        },
      ],
    };
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  renderGrid = () => {
    if(!this.props.sData.get('selectedDeviceApp')) {
      return null;
    }
    return <PrintersViewGrid actions={this.props.actions.printersViewGridActions} />
  }

  render() {
    let title = this.labels.get('title');
    let headerItems = this.getHeaderItems();
    return (
      <div className="administration-view">
        <div className="title">{title}</div>

        <RfidHeader {...headerItems} change = {this.props.change} selectedDeviceApp={this.props.sData.get('selectedDeviceApp')}/>
        {this.renderGrid()}

        <PL_DialogWrapper dialogComponent={CreatePrinterDialog}
                          show={this.props.sCreatePrinterDialogData.get('show')}
                          actions={this.props.actions.createPrinterDialogActions}
                          sData={this.props.sCreatePrinterDialogData}
                          deviceApp={this.props.sData.get('selectedDeviceApp')}
                          reloadParentComponent={this.reloadPage}
        />

      </div>
    );
  }
}

export default reduxForm({
    form: 'PrintersForm'
  }
)(PrintersView);

PrintersView.propTypes = {
  sGrid: PropTypes.object,
};

