

export class DevLog {
  
  
  /**
   * @param {any} message
   * @param  {any[]} optionalParams
   */
  static log = (message, ...optionalParams) => {
    console.log(message, ...optionalParams);
  };


  
  
  
};