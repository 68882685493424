import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell';
import LastOperationCell from '../../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';

require('./partsGrid.scss');

class PartsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'partType.businessId', filterName: 'partTypeBusinessId', getOptions: true},
    {fieldName: 'partStatus', filterName: 'partStatus', getOptions: true},
    {fieldName: 'workOrder.businessId', filterName: 'woBusinessId', getOptions: true},
    { fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true },
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.allLabels = createLabelHelper('');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.partId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.PART_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
                id: params.data.id,
                label: params.data.businessId,
                type: navigationStates.PART
              };
          }
        }
      },
      {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.partType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'partTypeBusinessId',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'partStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'partStatus',
        // width: 100,
        columnOptions: {
          valueGetter: (params) => {
            return params.data.partStatus ? this.allLabels.get(params.data.partStatusDisplayKey) : ''
          },
        }
      },
      {
        fieldName: 'layersCounter',
        title: '',
        filterType: filterTypes.NONE,
        width: 70,
        columnOptions: {
          sortable: false,
          resizable: false,
          headerValueGetter: GridHelper.headerValueGetter,
          cellComponent: RelatedAssetsCell,
          valueGetter: (params) => {
            return {
              count: params.data.layersCounter,
              cellAction: this.props.cellAction,
              assetId: params.data.id,
              componentAssetsTitle: this.labels.get('columns.componentLayers.cell.title'),
              // resultingAssetsTitle: this.labels.get('columns.resultingassets.cell.title')
              resultingAssetsTitle: ''
            };
          },
          headerComponentParams: {
            headerIcon: 'component-assets',
            headerTooltip: this.labels.get('columns.componentLayers.cell.title'),
          }
        }
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woBusinessId',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ];
  }

  render() {
    return (
      <div className="parts-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_PARTS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: () => 84,
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'locationPartsGrid'
  }
)(PartsGrid);


