// @ts-check

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import LabelManager from "./LabelManager";



/**
 * @typedef {import("./../../../types/ServerTypes/Models/LiveSearch").LiveSearchResultItemType} LiveSearchResultItemType
 * 
 * 
*/

export default class MapLabelManager {

  static labelManager = LabelManager;

  
  /**
   * @param {LiveSearchResultItemType | string} itemType
   */
  static getTooltipMultiItemTypeLabel(itemType) {
    
    /** @type {string} */
    let subKey = itemType;
    subKey = subKey.toLowerCase();
    subKey = subKey + 's';
    
    
    if (itemType === "WO") {
      subKey = "workorders";
    }
    
   
    let result = this.labelManager.getByPrefixAndKey("mat.entity.type.", subKey);
    
    return result;
  }


  /**
   * @param {string} itemType
   */
  static getBreadcrumbTypeLabel(itemType) {
   
    let result = this.labelManager.getByPrefixAndKey("mat.livesearch.breadcrumb.", itemType);
    result = result && result.toUpperCase();
    
    return result;
  }

  
  

}



















