import Network from 'infrastructure/js/modules/network';
import * as externalNotificationService from '../../../services/Notifications/externalNotificationService';
import * as daHelper from '../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../enums/dialogsNames';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
      externalNotificationService.updateExternalNotificationSettings : externalNotificationService.createExternalNotificationSettings;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_EXTERNAL_NOTIFICATION_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_EXTERNAL_NOTIFICATION_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function() {
      let promise1 = externalNotificationService.getExternalNotificationTriggers();
      let promise2 = externalNotificationService.fetchTeamsWebHooks();
      let promise3 = externalNotificationService.fetchSlackWebHooks();

      return Promise.all([promise1, promise2, promise3]).then((allResults) => {
        let isResultValid = allResults.every((result) => {
          return Network.isResponseValid(result);
        });
        if (!isResultValid) {
          console.error('Fetching the External Notifications initial data failed.');
          return {success: false};
        }

        let labels = createLabelHelper('');

        let dialogData = {
          triggers: allResults[0].dataList?.map((obj) => {return {value: obj.name, label: labels.get(obj.label)}}) || [],
          teamsChannels: allResults[1].dataList?.map((obj) => {return {value: obj.id, label: obj.name}}) || [],
          slackChannels: allResults[2].dataList?.map((obj) => {return {value: obj.id, label: obj.name}}) || [],
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return {success: true};
      });
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};

