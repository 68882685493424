import React, { Component } from 'react';

import PL_TextField from 'infrastructure/js/components/controls/TextField/textField';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import  * as dialogHelper  from "infrastructure/js/components/Dialog/dialogHelper";

import { reduxForm } from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';

import { generateFormNames, toNumberOrNull } from 'infrastructure/js/utils/componentUtils.js';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import * as reportKitDialogHelper from "../../../../utils/reportKitDialogHelper";
import MaterialHelper from '../../../../utils/materialHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from "infrastructure/js/utils/uomHelper";

require('./reportKitDialog.scss');
const imgKitHalf = require("infrastructure/assets/images/kit-half.png");


class ReportKitDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.kitting.reportkitdialog.');
    this.confirmationLabels = createLabelHelper('mat.locationpage.view.kitting.kittingOperation.confirmation.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.validationsLabels = createLabelHelper('mat.validations.');

    let dialogData = this.props.sData.get('dialogData');
    let kits     = dialogData && dialogData.kits ? dialogData.kits : [];
    this.kitsList = reportKitDialogHelper.ConvertKitsDataToDialogItems(kits, this.props.currentCut);

  }

  createDialogFooterButtons() {

    let isSubmitDisabled = this.props.sData.get('loading') ||
                           this.props.sData.get('hasError') ||
                          (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'));

    let submitInProgress          = this.props.sData.get('submitInProgress');
    let submitAndCreateInProgress = this.props.sData.get('submitAndCreateInProgress');

    let buttonReport = {
      id: 'report',
      text: this.labels.get('footer.report'),
      bsStyle: "primary",
      disabled: isSubmitDisabled,
      loading: submitInProgress,
      action: this.props.handleSubmit(this.handleSubmitReport)
    };

    let buttonReportAndCreate = {
      id: 'reportCreate',
      text: this.labels.get('footer.reportandcreatemore'),
      bsStyle: "primary",
      disabled: isSubmitDisabled,
      loading: submitAndCreateInProgress,
      action: this.props.handleSubmit(this.handleSubmitReportAndCreate)
    };


    let buttonCancel = {
      id: 'cancel',
      text: this.dialogLabels.get('cancel'),
      action: this.props.actions.hide
    };

    return { left: [buttonCancel], right: [buttonReport, buttonReportAndCreate] };
  }

  prepareSubmitData(data) {
    // METHOD = PUT.
    let formItems = this.submitedData = data.dialogItems;
    let stateItems = this.kitsList;

    // Get selected Cut ID from Kitting page dropdown data.
    let selectedCut = this.props.currentCut;
    let selectedCutId = selectedCut.id;


    let submitData = {
      reportCutId: selectedCutId,
      kittedPlies: [],
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };


    const materialUnits = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
    formItems.forEach((formItem, index) => {

      let stateItem = stateItems[index];

      //if substitutableMaterial is selected - use it, if not - it is a main material
      submitData.kittedPlies.push({
        kitId:                stateItem.kitIdKey,
        mainMaterialId:       formItem.substitutableMaterials ? formItem.substitutableMaterials.data.mainMaterial.id : selectedCut.material.id,
        substituteMaterialId: formItem.substitutableMaterials ? formItem.substitutableMaterials.data.substituteMaterial.id : null,
        quantityKittedPlies:  toNumberOrNull(formItem.pliesToAdd),
        materialUsedAmount: UnitHelper.userValueToServerValue(materialUnits, formItem.materialUsedAmount),
        materialScrapAmount: UnitHelper.userValueToServerValue(materialUnits,formItem.materialScrapAmount),
      });
    });

    return submitData;
  }

  handleSubmitReport = (formData) => {
    let submitData = this.prepareSubmitData(formData);

    this.props.actions.onReportKittings(submitData, this.getMessageDialogBuilder());
  };

  handleSubmitReportAndCreate = (formData) => {
    let submitData = this.prepareSubmitData(formData);

    this.props.actions.onReportKittingsAndCreate(submitData);
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {
      return dialogHelper.BuildDialogDescriptorForKitOperations(data, this.confirmationLabels, fnCloseDialog, this.onCancelKitReportClick);
    };
  };

  onCancelKitReportClick = (kittedPlies, fnCloseDialog) => {
    this.props.actions.onCancelKitReportClick(kittedPlies.map((ply) => {return ply.kitId}));
    fnCloseDialog();
  };

  renderReportedKitsList() {
    const unitLabel =  PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
    let result = (
        <div className='dialog-content-wrapper'>
            <div className='content-header'>
                <div className='item-column column-1'>&nbsp;</div>
                <div className='item-column column-2'>&nbsp;</div>
                <div className='item-column column-3'>
                    {this.labels.get('headings.pilesneeded')}
                </div>
                <div className='item-column column-4'>
                    {this.labels.get('headings.pileskitted')}
                </div>
                <div className='item-column column-5'>
                    <label className="">{this.labels.get('headings.amount')}</label>
                    <label className="units">{`(${UnitHelper.getLabelForUnitType(unitLabel)})`}</label>
                </div>
                <div className='item-column column-6'>
                    <label className="">{this.labels.get('headings.scrap')}</label>
                    <label className="units">{`(${UnitHelper.getLabelForUnitType(unitLabel)})`}</label>
                </div>
            </div>

            <div className='content-items-wrapper'>
                {this.kitsList.map((item, index) => {
                    return (
                        <RenderSingleDialogContentItem
                            {...this.props}
                            key={index}
                            index={index}
                            item={item}
                        />
                    );
                })}
            </div>
        </div>
    );
    return result;
  }

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let show          = this.props.show;
    let currentCut    = this.props.currentCut;

    let dialogTitle = this.labels.get('header.title');
    let dialogSubTitle = this.labels.get("title.subtitle", undefined, { material: MaterialHelper.getMaterialFullLabel(currentCut.material.materialName, currentCut.material.businessId),
                                                                        rollId: currentCut.rollBusinessId});

    let footerCenterText = (
      <span>
        {this.labels.get('footer.status')}
        {this.kitsList.length}
      </span>
    );

    return (
      <div>
        <Dialog
          className = "report-kits-dialog"
          show = {show}
          onHide = {this.props.actions.hide}
          sData={this.props.sData}
          titleText = {dialogTitle}
          moreInfoTextHeader = {dialogSubTitle}
          footerCenterText = {footerCenterText}
          footerValidationCheckbox={this.getFooterValidationCheckBox()}
          footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
          footerButtons = {this.createDialogFooterButtons()}
          onEnterKeyPress={this.props.handleSubmit(this.handleSubmitReport)}
        >

          {this.renderReportedKitsList()}

        </Dialog>
      </div>
    )
  }

}



class RenderSingleDialogContentItem extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.kitting.reportkitdialog.');
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    let { index, item } = this.props;
    const materialUnits = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
    const amount = PermissionManager.isWeightSupported() ? this.props?.currentCut?.weightLeft : this.props?.currentCut?.lengthCut;
    
    this.props.initialize({
      dialogItems: [{ materialUsedAmount: UnitHelper.serverValueToUserValue(materialUnits, amount, 2) }]
    })
    //materialOptions contains only the materials that are relevant to the current cut material
    let materialOptions = item.materialOptions;
    if (!materialOptions) {
      return;
    }

    let substitutableMaterialsOptions = [];
    let mainMaterial = null;

    materialOptions.forEach((m) => {
      if (m.isSubstituteMaterial) {
        let option = {
          value: m.mainMaterial.id,
          label: MaterialHelper.getMaterialFullLabel(m.mainMaterial.materialName,  m.mainMaterial.businessId),
          data: m
        };
        substitutableMaterialsOptions.push(option);
      }
      else {
        mainMaterial = m;
      }

    });

    if (!!mainMaterial) {
      let expectedPliesReduxName = this.getReduxFieldName('expectedPlies', index);
      let pliesToAddReduxName = this.getReduxFieldName('pliesToAdd', index);
      this.props.change( expectedPliesReduxName, Math.max((mainMaterial.quantityExpectedPlies - mainMaterial.quantityKittedPlies),0));
      this.props.change( pliesToAddReduxName,Math.max((mainMaterial.quantityExpectedPlies - mainMaterial.quantityKittedPlies),0));
      return;
    }
    //set the first found substitutable material as a selected by default
    if (substitutableMaterialsOptions.length > 0) {
      this.updateReduxFormData(substitutableMaterialsOptions[0], index);
    }
  };

  getReduxFieldName = (fieldName, index) => {
    return 'dialogItems' + '[' + index + '].' + fieldName;
  };

  handleSubstitutableMaterialOnChange = (selectedOpion) => {

    if (!selectedOpion) {
      return this.initData();
    }

    let { index } = this.props;

    let pliesToAddReduxName = this.getReduxFieldName('pliesToAdd', index);
    let expectedPliesReduxName = this.getReduxFieldName('expectedPlies', index);

    this.props.change( expectedPliesReduxName, Math.max((selectedOpion.data.quantityExpectedPlies - selectedOpion.data.quantityKittedPlies),0));

    this.props.change( pliesToAddReduxName , null);
  };

  updateReduxFormData = (materialOption, index) => {
    let pliesToAddReduxName = this.getReduxFieldName('pliesToAdd', index);
    let expectedPliesReduxName = this.getReduxFieldName('expectedPlies', index);
    let substitutableMaterialsReduxName = this.getReduxFieldName('substitutableMaterials', index);

    this.props.change( expectedPliesReduxName, Math.max((materialOption.data.quantityExpectedPlies - materialOption.data.quantityKittedPlies),0));
    this.props.change( pliesToAddReduxName , null);
    this.props.change( substitutableMaterialsReduxName , materialOption);

  };

  //render substitutable materials section (when relevant)
  renderSubstitutableMaterials() {

    let { index, item } = this.props;
    let materialOptions = item.materialOptions;
    if (!materialOptions) {
      return null;
    }

    //materialOptions contains only the materials that are relevant to the current cut material
    let substitutableMaterialsOptions = [];
    let mainMaterialFound = false;

    materialOptions.forEach((m) => {
      if (m.isSubstituteMaterial) {
        let option = {
          value: m.mainMaterial.id,
          label: MaterialHelper.getMaterialFullLabel(m.mainMaterial.materialName,  m.mainMaterial.businessId),
          data: m
        };
        substitutableMaterialsOptions.push(option);
      }
      else {
        mainMaterialFound = true;
      }

    });
    if (substitutableMaterialsOptions.length < 1) {
      return null;
    }

    let name = this.getReduxFieldName('substitutableMaterials', index);

    return (
      <div className="substitute-material-section item-column column-2">

        <div className="substitute-material-label">
          <span className="pl pl-substitute-material-mark"></span>
          <span>{'substitute material for:'}</span>
        </div>
        <Combobox name={name} id={name}
                  options={substitutableMaterialsOptions}
                  onChangeCallback={this.handleSubstitutableMaterialOnChange}
                  validate={ !mainMaterialFound ? Validation.dropdown.required : undefined}
                  // autoBlur={true} //renamed blurInputOnSelect
                  // onBlurResetsInput={true} //removed
        />

      </div>
    );
  }

  normalizeAmount(){
    const materialUnits = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
    const amount = PermissionManager.isWeightSupported() ? this.props.currentCut.weightLeft : this.props.currentCut.lengthCut;

    return Normalize.normalizeFloat( 0, UnitHelper.serverValueToUserValue(materialUnits, amount, 2), 3)
  }

  render() {
    let { index, item } = this.props;

    // Create object with pre-fabricated "names" and "id"s for all possible controls of the current item.
    let formNames = generateFormNames(item, 'dialogItems', 'ReportKitDialog-', index);
    let expectedPliesReduxName = this.getReduxFieldName('expectedPlies', index);
    let amountReduxName = this.getReduxFieldName('materialUsedAmount', index);
    let scrapReduxName = this.getReduxFieldName('materialScrapAmount', index);

    return (

        <div className="dialog-item clearfix">

          <div className="item-column column-1">
            <div className="fill-icon-text">{item.totalExistingPlies + "/" + item.totalRequiredPlies }</div>
            <img className="fill-icon-image" src={imgKitHalf} />
          </div>

          <div className="item-column column-2">
            <div className="info-line">
              <span className="info-label">{this.labels.get('kittype')}:</span>
              <span className="info-data">{item.kitType}</span>
            </div>
            <div className="info-line">
              <span className="info-label">{this.labels.get('kitid')}:</span>
              <span className="info-data">{item.kitId}</span>
            </div>
            <div className="info-line">
              <span className="info-label">{this.labels.get('project')}:</span>
              <span className="info-data">{item.project}</span>
            </div>
            <div className="info-line">
              <span className="info-label">{this.labels.get('workorder')}:</span>
              <span className="info-data">{item.workOrder}</span>
            </div>
          </div>

          <div className="item-column column-3">
            <PL_TextField name={expectedPliesReduxName} id={expectedPliesReduxName} disabled
                          className="piles-needed-text"/>
          </div>

          <div className="item-column column-4">
            <PL_TextField name={formNames.pliesToAdd.name} id={formNames.pliesToAdd.id} placeholder="" className="plies-kitted"
               validate={Validation.required} normalize={Normalize.number(true, 0, 9999)} autoFocus={index === 0}/>
          </div>
          <div className="item-column column-5">
            <PL_TextField
              name={amountReduxName}
              id={amountReduxName}
              placeholder=""
              className="plies-kitted"
              normalize={this.normalizeAmount()}
            />
          </div>

          <div className="item-column column-6">
            <PL_TextField
              name={scrapReduxName}
              id={scrapReduxName}
              placeholder=""
              className="plies-kitted"
            />
          </div>

          {this.renderSubstitutableMaterials()}

        </div>

    );

  } // end render()

}



/*****************************************************/
// Add Redux-Form functionality to this component.
export default ReportKitDialog = reduxForm({
    form: 'reportKitDialogForm',
    onChange:  (values, dispatch, props, previousValues ) => {
      if (previousValues.dialogItems) { //do not call the onFormChangeHandler during the dialog creation/initialization
        dialogHelper.onFormChangeHandler(values, props, previousValues);
      }
  },
  }
)(ReportKitDialog);






