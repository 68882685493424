// @ts-check

import MapControlSettings from "../MapControl/MapControlSettings";


/**
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").CameraEvent} CameraEvent
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").MapZoomLevel} MapZoomLevel
 * 
 * 
 */


export default class ZoomProcessor {

  //////////////////////////////////
  // Glorious Global state. 
  // No React here.
  // 

  static previousZoom = 0;

  static previousZoomLevel = 0;



  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {CameraEvent} payload
   */
  static processCameraChanged(refContext, payload) {

    // console.log(`ZoomProcessor ===> processMapZoom ===> ${payload.zoom} ${this.previousZoom}`);

    if (payload.zoom !== this.previousZoom) {
      this.processZoomChanged(refContext, payload);
    }

    this.previousZoom = payload.zoom;

  }


  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {CameraEvent} payload
   */
  static processZoomChanged(refContext, payload) {
    // console.log(`ZoomChanged ===> ${payload.zoom} ${this.previousZoom}`);
    this.detectAndTriggerZoomLevelChange(refContext, payload);
    
    // add more handlers here if needed.
    // ...
    
  }


  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   * @param {CameraEvent} payload
   */
  static detectAndTriggerZoomLevelChange = (refContext, payload) => {

    let zoomLevel = getZoomLevelForValue(payload.zoom);
    
    //////////////////////////////////////////////////
    // Compare with previous zoom level from sData.
    //
    let sData = refContext.current.refSData.current;
    let previousZoomLevel = sData.normal.mapZoomLevel;
    // console.log('previousZoomLevel sData ===> ', previousZoomLevel);
    if (zoomLevel === previousZoomLevel) {
      return;
    }
    
    // console.log('NEW ZOOM LEVEL! ===> ', zoomLevel);
    refContext.current.actions.zoomLevelChanged(zoomLevel);

  }




}


/**
 * @param {number} cameraZoom
 */
let getZoomLevelForValue = (cameraZoom) => {

  let thresholds = MapControlSettings.ZOOM_THRESHOLDS;

  /** @type {MapZoomLevel} */
  let zoomLevel = "BIRDS_EYE";

  if (cameraZoom < thresholds.FULL_MAP) {
    zoomLevel = "FULL_MAP";
  }
  if (cameraZoom < thresholds.CLOSE_UP) {
    zoomLevel = "CLOSE_UP";
  }

  return zoomLevel;

}



