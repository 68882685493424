import Network from 'infrastructure/js/modules/network';

export function fetchItemsBySectionId(sectionId) {
  return Network.get(`reports/manager-view/${sectionId}`);
}
export function fetchWoCountBySectionId(sectionId) {
  return Network.get(`reports/manager-view/workorders-count/${sectionId}`);
}
export function fetchAlertsBySectionId(sectionId) {
  return Network.get(`reports/manager-view/alert-severity/${sectionId}`);
}
export function fetchKPIsBySectionId(sectionId) {
  return Network.get(`reports/manager-view/alerts-counters/${sectionId}`);
}
