import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

import { api as messageDialogApi } from '../MessageDialog/messageDialogActions.js';
import { api as schedulerTasksPageActions } from './schedulerTasksPageActions.js';
import { api as commonSchedulerApi } from './commonSchedulerActions';
import { api as activityLogDialogApi } from '../ActivityLogDialog/activityLogDialogActions.js';
import * as schedulerService from '../../services/Scheduler/schedulerService';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import * as daHelper from '../Dialogs/dialogActionsHelper';
import { dialogsNames } from '../../enums/dialogsNames';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.AUTO_SCHEDULER_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.AUTO_SCHEDULER_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  showActivityLog(dispatch, getState) {
    return function () {
      activityLogDialogApi.show(dispatch, getState)();
      messageDialogApi.close(dispatch, getState)();
    };
  },

  submit(dispatch, getState) {
    return function (data, schedulerEngineInstance) {
      dispatch(actions.setBusy(true));

      // attach selected goals in analytic mode panel.
      const goals = getState().form?.schedulerAnalyticModePanel?.values?.goals;
      if (PermissionManager.hasSchedulerAnalyticModePermissions()) {
        if (goals) {
          data.schedulingFactors = goals.map((goal) => ({ factorTypeId: goal.value }));
        }
      }

      return schedulerService.autoSchedule(data).then((response) => {
        dispatch(actions.setBusy(false));

        let validations = dialogHelper.getResponseValidationDetails(response);
        if (validations) {
          dispatch(actions.setValidationWarningsData(validations));
          return { success: false };
        }

        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Auto Schedule Failed');
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        // save selected goals in analytic mode panel
        if (PermissionManager.hasSchedulerAnalyticModePermissions()) {
          localStorage.setItem('analyticModeData', JSON.stringify({ goals }));
        }

        
        // if async scheduling is enabled then start polling scheduling status and dont procceed to refresh the page.
        const isAsyncSchedulingEnabled = PermissionManager.getOrgPreferences().asyncSchedulingEnabled || response?.data?.async;
        if (isAsyncSchedulingEnabled) {
          const schedulingCompletedCallback = schedulerTasksPageActions.refreshPageData(dispatch, getState)
          commonSchedulerApi.pollSchedulingStatus(dispatch, getState)(schedulingCompletedCallback);
          return;
        }
        
        
        let statistics = response?.data?.statistics;

        if (statistics) {
          const activityLogId = response.data?.schedulingInsightsJobId;
          let fnCloseDialog = null;

          if(activityLogId){
            const activityLogTitle = createLabelHelper('').get('mat.scheduler.common.insightsDialog.title');
            activityLogDialogApi.setConfigData(dispatch, getState)({ activityLogId, activityLogTitle });
            fnCloseDialog = api.showActivityLog(dispatch, getState);
          }

          let message = createLabelHelper('').get(statistics.labelKey, undefined, statistics.params || undefined);

          let desc = _getAutoScheduleMessageDialogBuilder(message, fnCloseDialog);
          messageDialogApi.open(dispatch, getState)(desc);
        }


        schedulerTasksPageActions.refreshPageData(dispatch, getState)()
      });
    };
  },
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit(data, schedulerEngineInstance) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(data, schedulerEngineInstance);
    };
  },
};

/////////////////////////////////////////////////////////////////////////

function _getAutoScheduleMessageDialogBuilder(message, fnCloseDialog) {
  let title = createLabelHelper('').get('mat.scheduler.operations.view.autoSchedule.confirmation.title');
  let children = [<MessageDialog.MessageArea key={'confirmMessageDataRow'} text={message} />];
  let buttons = [];
  if (fnCloseDialog) {
    buttons.push({ id: 'cancel', text: createLabelHelper('mat.dialog.').get('viewdetails'), action: fnCloseDialog, bsStyle: 'link' });
  }

  //Note: 'auto-schedule-message-dialog' was added to support '\n' in the labels for this specific dialog
  let className = 'scheduler-page-message-dialog auto-schedule-message-dialog';
  return { title, buttons, children, className, type: 'success'};
}
