import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import MultiEntitiesMultiSelect from '../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';

import'./bringAssetsDialog.scss';

class BringAssets extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.comboboxLabels = createLabelHelper('mat.controls.combobox.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.bringassetsdialog.');

    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };
  }

  getDialogButtons() {
      let cancelTokenEnabled = !!this.props.sData.get("cancelToken") ;
      return {
        left: [{
          id:'cancel',
          text: this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get("cancel"),
          action: this.props.sData.get('loading') ? this.onStop : this.onHide,
          enabledOnLoading: cancelTokenEnabled

        }],
        right: [
          {
            id:'submit',
            text: this.labels.get('footer.bringassets'),
            bsStyle: "primary",
            loading: this.props.sData.get('loading'),
            action: this.props.handleSubmit(this.onSubmit),
            disabled: this.props.sData.get('loading') ||
                      this.props.sData.get('hasError') ||
                      (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
          }
        ]
      };
  }

  onSubmit = (data) => {
    let allRows = data['entities-multi-select-ex'];
    let assetsIds = [];
    allRows.map((row) => {
      if (row && row.assetsValue) {
        row.assetsValue.map((asset) => {
          assetsIds.push(asset.value);
        });
      }
    });
    let newData = {
      assetIds : assetsIds,
      destination: this.getCurrentLocationId(),
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };

    this.props.actions.submit(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
    // this.props.actions.submitWithProgress(newData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get("confirmation.message.title", "", { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  getCurrentLocationId = () => {
    return this.props.currentLocation ? this.props.currentLocation.id : null;
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  noOptionsRenderer = (value) => {
    const { inputValue = '' } = value?.selectProps || {};

    return (
      <div className='no-result-for'>
        <span>{this.comboboxLabels.get(inputValue ? 'noResultsFor' : 'noResults')} </span>
        <span>{inputValue ? `'${inputValue}'` : ''}</span>
      </div>
    )
  }

  render() {
    let currentLocation = this.props.currentLocation ? this.props.currentLocation.name : this.labels.get('thislocation');

    return (
      <Dialog
        id="bring-asset-dialog"
        className="bring-asset-dialog"
        show={this.props.sData.get("show")}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <div className="select-assets">
          <InputSection label={this.labels.get("identifyassetslocation", undefined, {location: currentLocation})}  className="no-margin" />
          <MultiEntitiesMultiSelect name="entities-multi-select-ex"
                                 entitiesTypes={this.entitiesTypes}
                                 validate={Validation.required}
                                 fetchConfig={this.fetchConfig}
                                 autoFocus={true}
                                 noOptionsRenderer={this.noOptionsRenderer}
                                 { ...this.props }/>
        </div>

      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'bringAssets',
  onChange: (values, dispatch, props, previousValues ) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
  }
)(BringAssets);
