import { gridsNames } from '../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import useLabels from 'infrastructure/js/hooks/useLabels';
import './sandboxesGrid.scss';


const filterConfig = [
  { fieldName: 'businessId', filterName: 'orgBusinessId', getOptions: true },
  { fieldName: 'createdDate', filterName: 'orgCreatedDate', getOptions: true },
  { fieldName: 'createdByUser', filterName: 'orgCreatedByUser', getOptions: true },
  { fieldName: 'updatedOn', filterName: 'orgUpdatedOn', getOptions: true },
  { fieldName: 'updatedByUser', filterName: 'orgUpdatedByUser', getOptions: true },
];

export default function SandboxesGrid({ actions }) {
  const labels = useLabels('mat.administration.sandbox.grid.');

  const columnsConfig = [
    {
      fieldName: 'businessId',
      title: labels.get('columns.name.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'orgBusinessId',
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'createdDate',
      title: labels.get('columns.creationTime.title'),
      filterType: filterTypes.DATE_SELECT,
      filterName: 'orgCreatedDate',
      isTime: true,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: (params) => {
          return params.data ? DateTimeHelper.DateTimeFormat(params.data?.createdDate?.epochDateTime * 1000) : '';
        },
        headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
      },
    },
    {
      fieldName: 'createdByUser',
      title: labels.get('columns.creator.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'orgCreatedByUser',
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'updatedOn',
      title: labels.get('columns.updatedTime.title'),
      filterType: filterTypes.DATE_SELECT,
      filterName: 'orgUpdatedOn',
      isTime: true,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: (params) => {
          return params.data ? DateTimeHelper.DateTimeFormat(params.data?.updatedOn?.epochDateTime * 1000) : '';
        },
        headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
      },
    },
    {
      fieldName: 'updatedByUser',
      title: labels.get('columns.updatedBy.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'orgUpdatedByUser',
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
  ];

  return (
    <div className="sandbox-grid">
      <Grid gridName={gridsNames.ADMINISTRATION_SANDBOX} columnsConfig={columnsConfig} filterConfig={filterConfig} actions={actions} />
    </div>
  );
}
