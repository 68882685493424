// @ts-check


export default class LinkBuilder {

  /**
   * @param {string | number | null | undefined} locationId
   * @param {string | null | undefined} assetType
   * @param {string | number | null | undefined} assetId
   * @returns {string | null}
   */
  static buildLink(locationId, assetType, assetId) {

    if (locationId && !assetType && !assetId) {
      return `#/Location/${locationId}`;
    }

    if (locationId && assetType && !assetId) {
      return `#/Location/${locationId}/assets/${assetType.toLowerCase()}s`;
    }

    if (!locationId && assetType && assetId ) {
      return `#/Asset/${assetType.toLowerCase()}/${assetId}`;
    }  

    return null;

  }


}