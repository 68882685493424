// @ts-check

import { createLabelHelper } from "infrastructure/js/utils/labelHelper";


export default class LabelManager {

  /** @type { any | null} */
  static labelHelper = null;


  static initLabelHelper() {
    if (this.labelHelper) {
      return;
    }
    this.labelHelper = createLabelHelper();
  }

  static initLabelHelper_FORCE_RELOAD() {
    this.labelHelper = createLabelHelper();
  }


  /**
   * @param {string} fullKey 
   * @returns {string}
   */
  static getByKey(fullKey) {
    this.initLabelHelper();
    return this.labelHelper.get(fullKey);
  }

  /**
   * @param {string} prefix 
   * @param {string} subKey 
   * @returns {string}
   */
  static getByPrefixAndKey(prefix, subKey) {
    this.initLabelHelper();
    return this.labelHelper.get(prefix + subKey);
  }


}


