import { api as locationPageApi } from '../locationPageActions.js';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import * as kitsService from '../../../services/Kits/kitsService';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.LOCATION_ASSET_KITS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let locationType = locationPageApi.getLocationType(dispatch, getState)();

    if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ) {
      return kitsService.getShippedKitsByStationId(locationId, query);
    }
    else if (locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS') ) {
      return kitsService.getReceivingKitsByStationId(locationId, query);
    }

    return kitsService.getKitsDataByStationId(locationId, query);
  }
};

function _convertToRowData(rows, serverTimeSeconds){
  let kits = rows.map((obj, index) => {
    if(obj.expirationDate) {
      obj.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }

    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }

    obj.dueDateLeft = DateTimeHelper.GetDifferenceInDays(obj.workOrder && obj.workOrder.dueDate ? obj.workOrder.dueDate : 0, serverTimeSeconds);

    return obj;
  });

  return kits;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;








