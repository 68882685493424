import React from 'react';
import PropTypes from 'prop-types';
import Header, { headerItemsLabels } from '../../Common/Header/header';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import ShipmentsGrid from './ShipmentsGrid/shipmentsGrid.js';
import { ImportAcceptFileTypes, ImportTypes } from '../../../enums/importTypes';
import { isIncomingShipments, isOutgoingShipments, isShippingPreparation } from '../../../utils/locationHelper';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import ProcessTemperatureLogDialog from './ProcessTemperatureLogDialog/processTemperatureLogDialog';

const TAB_ID = 'shipments';

export default class ShipmentsView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.shipments.');
  }

  componentDidMount() {
    if (this.props.match.params.tabId === TAB_ID) {
      this.reload(false);
    }
  }

  componentDidUpdate(prevProps) {
    let tabId = this.props.match.params.tabId;
    if (tabId === prevProps.match.params.tabId || tabId !== TAB_ID) {
      return;
    }
    this.reload(false);
  }

  reload = (refreshAll = true) => {
    this.props.actions.reload(refreshAll);
    if (refreshAll) {
      this.props.actions.locationPageActions?.reload?.();
    }
  };

  getHeaderItems() {
    let { actions, sHasRows, sSelectedRowsData, sLocationDetails } = this.props;
    let loading = this.props.sGrid ? this.props.sGrid.get('loading') : true;

    return {
      title: this.labels.get('header.title'),
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reload,
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: loading,
        },
        ...this.getCustomHeaderButtons(),
      ],
      kebab: {
        menuItems: [
          ...( (isShippingPreparation(sLocationDetails) ||
               isOutgoingShipments(sLocationDetails) ||
               isIncomingShipments(sLocationDetails) ) ?
            [
              {
                id:'deleteShipment',
                label: headerItemsLabels.DELETE_SHIPMENT,
                action: actions.deleteShipment,
                actionData: {
                  selectedRowsData: sSelectedRowsData,
                  reloadParentComponent: this.reload
                },
                disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailableByStatus(sSelectedRowsData, ['CREATED', 'RECEIVED']),
              },
            ] :
            [
              {
                id:'importShipmentsDocument',
                label: headerItemsLabels.IMPORT_SHIPMENT_DOCUMENT,
                action: actions.headerActions.importItems,
                actionData: {
                  importTypes: [ImportTypes.IMPORT_SHIPMENTS],
                  acceptFileTypes: [ImportAcceptFileTypes.CSV],
                  parentComponent: this.reload,
                  shouldIncludeLocation: true, //import is done NOT from a location or station , the import file locationId column is mandatory.
                  showPrintLabels: false,
                }
              },
              {
                id:'confirmShipmentsStatus',
                label: headerItemsLabels.CONFIRM_SHIPMENTS_STATUS,
                action: actions.headerActions.confirmShipmentStatus,
                actionData: {
                  actionData: sSelectedRowsData,
                  parentComponent: this.reload
                },
                disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailable(sSelectedRowsData),
              },
              {
                id:'rejectShipment',
                label: headerItemsLabels.REJECT_SHIPMENT,
                action: actions.headerActions.rejectShipment,
                actionData: {
                  actionData: sSelectedRowsData,
                  parentComponent: this.reload
                },
                disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailable(sSelectedRowsData),
              },
              {
                id:'downloadShipmentsDocument',
                label: headerItemsLabels.DOWNLOAD_SHIPMENT_DOCUMENT,
                action: actions.headerActions.downloadShipmentDocument,
                disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1,
                actionData: sSelectedRowsData
              },
            ]),
          ]
      },
    };
  }

  getCustomHeaderButtons = () => {
    let { actions, sSelectedRowsData, sLocationDetails } = this.props;
    if (isShippingPreparation(sLocationDetails) ) {
      return [
        {
          id: 'importShipment',
          label: headerItemsLabels.IMPORT_SHIPMENT,
          icon: 'pl pl-bring-assets',
          action: actions.headerActions.importItems,
          actionData: {
            importTypes: [ImportTypes.IMPORT_ASN_SHIPMENTS],
            acceptFileTypes: [ImportAcceptFileTypes.CSV],
            parentComponent: this.reload,
            shouldIncludeLocation: true, //import is done NOT from a location or station , the import file locationId column is mandatory.
            showPrintLabels: false,
          },
        },
        {
          id: 'shipmentReadyToShip',
          label: headerItemsLabels.READY_TO_SHIP,
          icon: 'pl pl-ready-to-ship',
          action: actions.shipmentReadyToShip,
          actionData: {
            selectedRowsData: sSelectedRowsData,
            reloadParentComponent: this.reload,
          },
          disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailableByStatus(sSelectedRowsData, ['CREATED']),
        },
        {
          id: 'shipToCustomer',
          label: headerItemsLabels.SHIP_TO_CUSTOMER,
          className: 'wide-icon',
          icon: 'pl pl-ship-to-customer',
          action: actions.headerActions.onShipToCustomerDialogClick,
          actionData: {
            preselectedAssets: sSelectedRowsData,
          },
          disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailableByStatus(sSelectedRowsData, ['READY_TO_SHIP']),
        },
      ];
    }
    if (isIncomingShipments(sLocationDetails)) {
      return [
        {
          id:'markShipmentAsReceived',
          label: headerItemsLabels.MARK_AS_RECEIVED,
          icon: 'pl pl-mark-as-received',
          action: actions.markShipmentAsReceived,
          actionData: {
            selectedRowsData: sSelectedRowsData,
            reloadParentComponent: this.reload,
          },
          disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailableByStatus(sSelectedRowsData, ['IN_TRANSIT']),
        },
        {
          id: 'processTemperatureLog',
          label: headerItemsLabels.PROCESS_TEMPERATURE_LOG,
          className: 'no-icon',
          action: actions.headerActions.processTemperatureLog,
          actionData: {
            sSelectedRowsData: sSelectedRowsData,
            parentComponent: this.reload,
          },
          disabled: !sSelectedRowsData || sSelectedRowsData.size !== 1 || !this.isItemAvailableByStatus(sSelectedRowsData, ['RECEIVED']),
        },
      ];
    }
    return [];
  };

  isItemAvailable = (data) => {
    let status = data?.get?.(0)?.status;
    return status && status !== 'CLOSED';
  }

  isItemAvailableByStatus = (data, availableStatuses) => {
    let status = data?.get?.(0)?.status;
    return status && availableStatuses?.includes?.(status);
  }

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="location-cutting-view">
        <Header {...headerItems} />
        <ShipmentsGrid actions={this.props.actions} locationDetails={this.props.sLocationDetails} />

        <PL_DialogWrapper
          dialogComponent={ProcessTemperatureLogDialog}
          asset={this.props.sSelectedRowsData.first()}
          show={this.props.sProcessTemperatureLogDialogData.get('show')}
          actions={this.props.actions.processTemperatureLogDialogActions}
          sData={this.props.sProcessTemperatureLogDialogData}
          sLocationDetails={this.props.sLocationDetails}
          reloadParentComponent={this.reload}
        />
      </div>
    );
  }
}

ShipmentsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object.isRequired,
  sGrid: PropTypes.object,
  sLocationDetails: PropTypes.object.isRequired,
};
