import * as orgsService from '../../../services/Administration/organizationsService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_ORGS_DASHBOARD, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query.fromDate = getState().administration.getIn(['organizationsPage', 'orgsDashboardView', 'reportDateFrom']) || null;
    query.toDate = getState().administration.getIn(['organizationsPage', 'orgsDashboardView', 'reportDateTo']) || null;

    return orgsService.fetchOrganizationsStatistics(query);
  }
}

function _convertToRowData(rows, serverTimeSeconds) {

  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
