// @ts-check

import React, { useEffect, useRef, useState } from 'react';

import { jsxActions as liveMapJsxActions } from '../../actions/LiveMap/liveMapPageActions';
import useHandleDataChange from './Hooks/useHandleDataChange';
import useFetchMapSettings from './Hooks/useFetchMapSettings';
import useInitializeMap from './Hooks/useInitializeMap';
import LabelManager from './Utils/LabelManager';
// import useRenderFullStatus from './Hooks/useRenderFullStatus';



import './liveMapPage.scss';
import './Components/Tooltips/liveMapPage_Tooltips.scss';
import './Components/Markers/liveMapPage_Markers.scss';
import './mappedin.scss';
import MapSearchOverlay from './Components/SearchControls/mapSearchOverlay';
import MapCameraPresetsOverlay from './Components/CameraControls/mapCameraPresetsOverlay';
import MapLevelSelectorOverlay from './Components/LevelSelector/mapLevelSelectorOverlay';
import MapLabelManager from './Utils/MapLabelManager';
import RefContextHelper from './Utils/RefContextHelper';
// import './mappedin_old_1.scss';


// https://developer.mappedin.com/web-sdk/v5/using-react
/** 
 * 
 * Container
 * '../../containers/LiveMap/liveMapPageContainer.js'
 * 
 * Actions
 * '../../actions/LiveMap/liveMapPageActions.js'
 * 
 * Reducers
 * '../../reducers/LiveMap/liveMapPageReducer.js'
 * 
 * 
 */


/**
 * @typedef {import("./../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageProps} LiveMapPageProps
 * @typedef {import("./../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * @typedef {import("@mappedin/mappedin-js").TShowVenueOptions} TShowVenueOptions
 * @typedef {import("@mappedin/mappedin-js").TGetVenueOptions} TGetVenueOptions
 * 
*/



/**
 * @param {LiveMapPageProps} props
 */
const LiveMapPage = (props) => {
  
  // console.log(`=========== PAGE RENDER ===> `, props);
  
  /** @type {React.MutableRefObject<LiveMapPageRefContext>} */
  let refContext = useRef({
    
    actions: props.actions,
    refSData: useRef(props.sData),
    
    mapHtmlTargetRef: useRef(null),
    refMapView: useRef(null),
    
    refCurrentMapNode: useRef(null),
    refClickPayload: useRef(null),
    refCurrentLocationTooltip: useRef({
      tooltipObj: null,
      parentPolygon: null,
    }),
    
    refPolyData: useRef(null),
    
    cameraAnimatedForSearchId: null,
    cameraAnimatedForSelectionhId: null,
    
  });
  
  useEffect(() => {
    RefContextHelper.refContext = refContext;
  }, []);
  
  
  // @ts-ignore
  if (__DEV__) {
    // @ts-ignore
    window.zzMapRefContext = refContext;
    // @ts-ignore
    window.zzLabelManager = LabelManager;
    
    // @ts-ignore
    window.zzMapLabelManager = MapLabelManager;
  }
  
  
  
  // Refs refresher
  refContext.current.refSData.current = props.sData;


  
  //////////////////////////////////////////////
  // Trigger map settings load from server
  // each time the map id in the URL changes.
  //
  // Results in props.sData.activeMapSetting being set.
  //
  useFetchMapSettings(props, props.match.params.mapId);

  
  //////////////////////////////////////////////
  // Load venu and initialize mapView
  // each time the map settings are changed:
  //  - props.sData.activeMapSetting
  //
  // Results in mapView being set in refContext.
  // 
  useInitializeMap(refContext, props, {

    multiBufferRendering: true,
    outdoorView: {
      enabled: true
    },

    aoEnabled: true,
    aoQuality: "medium",
    // aoQuality: 'high',
    // aoResolution: 'half',
    aoResolution: 'full',
    // loadOptions: {
    //   zoomOverrides: {
    //     zoomMultiplier: 0.5,
    //   },
    // },

    // xRayPath: true,

  });
  

  
  /////////////////////////////////////////////////
  // Add a reducer data handler.
  //
  useHandleDataChange(refContext);
  


  let mapView = refContext.current.refMapView.current;

  return (
    
    <div className="livemap-page">

      <div className="live-map-wrap">

        <MapSearchOverlay mapView={mapView} sData={props.sData} actions={props.actions} refContext={refContext} />
        <MapCameraPresetsOverlay mapView={mapView} />
        <MapLevelSelectorOverlay mapView={mapView} sData={props.sData} actions={props.actions} refContext={refContext} />

        <div id="maptarget" ref={refContext.current.mapHtmlTargetRef} />

      </div>

    </div>
    
  );

};




export default LiveMapPage;


