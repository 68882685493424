import { useEffect, useState } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import PromotionsGrid from './PromotionsGrid/promotionsGrid';
import { jsxActions as promotionsGridActions } from '../../../../actions/Administration/LoginManagementPage/promotionsGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../../actions/FetchEntities/fetchEntitiesActions';
import { jsxActions as promotionsPageActions } from '../../../../actions/Administration/LoginManagementPage/promotionsPageActions';
import { jsxActions as createPromotionDialogActions } from '../../../../actions/Administration/LoginManagementPage/Dialogs/createPromotionDialogActions';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import { navigationStates } from '../../../../enums/navigationStates';
import useLabels from 'infrastructure/js/hooks/useLabels';
import { gridsNames } from '../../../../enums/gridsNames';
import {List} from 'immutable';
import Header, { headerItemsLabels } from '../../../Common/Header/header';
import {PL_DialogWrapper} from 'infrastructure/js/components/Dialog/dialog';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import CreatePromotionDialog from './Dialogs/CreatePromotionDialog/createPromotionDialog';
import PromotionsHeader from './PromotionsHeader/promotionsHeader';
import PromotionsPreview from './PromotionsPreview/promotionsPreview';

import './promotionsPage.scss';


const getState = (state) => {
  const pageData =  state.administration.getIn(['loginManagementPage', 'promotionsPage']);
  const grid = state.grid?.get(gridsNames.ADMINISTRATION_PROMOTIONS) || null;

  return {
    sHasRows: grid?.get('rows')?.size > 0 || false,
    sSelectedRowsData: grid?.get('selectedRowsData') || List(),
    sRowsNumber: grid?.get('rows')?.size ?? 0,
    loading: pageData.get('loading'),
    slideSpeed: pageData.get('slideSpeed'),
    sCreatePromotionDialogData: state.administration.getIn(['loginManagementPage', 'createPromotionDialog']),
  };
};

const allActions = {
  promotionsGridActions,
  fetchEntitiesActions,
  promotionsPageActions,
  createPromotionDialogActions,
};

export default function PromotionsPage() {
  const labels = useLabels('mat.administration.promotionsManagement.');
  const updateNavBar = useNavBarUpdate();
  const [showPreview, setShowPreview] = useState(false);
  const { state, actions } = useRedux(allActions, getState);
  const { sHasRows = false, sSelectedRowsData, sRowsNumber=0,
          sCreatePromotionDialogData, loading, slideSpeed } = state;

  useEffect(() => {
    actions.promotionsPageActions.getSettings();
  }, []);

  useEffect(() => {
    updateNavBar(navigationStates.PROMOTIONS_MANAGEMENT, labels.get('title'));
  }, [updateNavBar, labels]);


  const getHeaderItems = () => {
    return  {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.promotionsPageActions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: actions.promotionsPageActions.reload,
          tooltip: headerItemsLabels.REFRESH,
        },
        {
          id:'create',
          label: headerItemsLabels.CREATE,
          icon: 'pl pl-icon-add',
          action: actions.promotionsPageActions.openCreatePromotionDialog,
        },
        {
          id: 'edit',
          label: headerItemsLabels.EDIT,
          icon: 'pl pl-edit-icon',
          disabled: !(sHasRows && sSelectedRowsData.size === 1),
          action: actions.promotionsPageActions.openCreatePromotionDialog,
          actionData: sSelectedRowsData.get(0),
        },
        {
          id: 'preview',
          label: headerItemsLabels.PREVIEW,
          className: 'no-icon',
          action: ()=> {setShowPreview(true)},
        },
      ],
      kebab: {
        menuItems: [
          {
            id:'activate',
            label: headerItemsLabels.ACTIVATE,
            action: actions.promotionsPageActions.activatePromotions,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            actionData:  sSelectedRowsData,
          },
          {
            id:'deactvate',
            label: headerItemsLabels.DEACTIVATE,
            action: actions.promotionsPageActions.deactivatePromotions,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            actionData:  sSelectedRowsData,
          },
          {
            id:'remove',
            label: headerItemsLabels.DELETE,
            action: actions.promotionsPageActions.deletePromotions,
            disabled: (!sSelectedRowsData || sSelectedRowsData.size === 0),
            actionData:  sSelectedRowsData,
          },
        ]
      }
    };
  }

  const reloadPage = () => {
    actions.promotionsPageActions.reload();
  }

  return (
    <>
    <div className="promotions-view">

      <PromotionsHeader  actions={actions.promotionsPageActions}  slideSpeed={slideSpeed}/>

      <Header {...getHeaderItems()}/>

      <PromotionsGrid actions={{ ...actions.promotionsGridActions, ...actions.fetchEntitiesActions }} />

      {loading ? <Overlay.Loading /> : null}

      <PL_DialogWrapper dialogComponent={CreatePromotionDialog}
                        show={sCreatePromotionDialogData.get('show')}
                        actions={actions.createPromotionDialogActions}
                        sData={sCreatePromotionDialogData}
                        reloadParentComponent={reloadPage}
      />
    </div>
      {showPreview ?
        <PromotionsPreview promotions={sSelectedRowsData?.toJS() ?? []}
                           slideSpeed={slideSpeed}
                           onCloseHandler={()=> {setShowPreview(false)}}/>
        : null
      }
    </>
  );
}
