import React from 'react';

import { Field } from 'redux-form';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getDateFormatPlaceholder } from '../controlsUtils';

import './datepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

// # https://hacker0x01.github.io/react-datepicker/

export default class PL_DatePickerForm extends React.PureComponent {
  render() {
    return <Field {...this.props} component={PL_DatePicker} />;
  }
}

export class PL_DatePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { input, selected, value, initialDate } = nextProps;

    //If value prop was passed - override Redux Form and initialDate prop (component is controlled).
    if (value) {
      return {
        selectedDate: value,
      };
    }

    //If selected prop was passed - override Redux Form and initialDate prop (component is controlled with custom prop 'selected').
    if (selected || selected === null) {
      return {
        selectedDate: selected,
      };
    }

    //If connected to redux form - override initialDate prop.
    if (input?.value) {
      return {
        selectedDate: input?.value,
      };
    }

    //user resets the datepickers value:
    if (prevState.selectedDate === null) {
      return {
        selectedDate: null,
      };
    }

    //Set the selected date to be the same as Initial Date once (component is uncontrolled)
    if (initialDate && !prevState.selectedDate) {
      return {
        selectedDate: initialDate,
      };
    }

    return prevState;
  }

  componentDidMount() {
    if (this.props.setRef) {
      this.props.setRef(this);
    }
  }

  errorClass() {
    if (this.props.meta) {
      let { touched, error } = this.props.meta;
      return touched && error ? ' invalid' : '';
    }
    return '';
  }

  onChangeRaw = (e) => {
    let value = e.target.value ? this.convertToMomentObject(e.target.value) : null;
    this.setState({ selectedDate: value });
    if (this.props.input) {
      this.props.input.onChange(value);
    }
    if (this.props.onInputChangeCallback) {
      this.props.onInputChangeCallback(value);
    }
  };

  onChange = (d) => {
    let dFormat = this.props.dateFormat || DateTimeHelper.getDateFormat();
    let date = d ? moment(d, dFormat, DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true) : null;

    this.setState({
      selectedDate: date,
    });
    if (this.props.input) {
      this.props.input.onChange(date);
    }
    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(date);
    }
  };

  //workaround for the known issue: 'react-datepicker interferes with react select'
  setPickerRef = (r) => {
    this.pickerRef = r || this.pickerRef;
    this.props.setInnerRef && this.props.setInnerRef(r);
  };
  clickOutside = () => {
    this.pickerRef.cancelFocusInput();
    this.pickerRef.setOpen(false);
  };

  convertToMomentObject = (date) => {
    let dFormat = this.props.dateFormat ? this.props.dateFormat : DateTimeHelper.getDateFormat();
    return moment(date, dFormat, DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true);
  };

  handleSelect = () => {
    const date = this.state.selectedDate;
    const momentDate = this.convertToMomentObject(date);
    return momentDate.isValid() ? momentDate : null;
  };

  filterDateWrapper = (filterDateFromProps) => {
    return filterDateFromProps
      ? (date) => {
          let dFormat = this.props.dateFormat || DateTimeHelper.getDateFormat();
          //offset local midnight to GMT midnight
          let transformedDate = moment(date, dFormat, DateTimeHelper.getUserOrgLocale(), true).utcOffset(0, true);
          return filterDateFromProps(transformedDate);
        }
      : undefined;
  };

  render() {
    let { showTimeSelect, dateFormat, timeFormat, size, popperClassName, containerClassName, filterDate, ...otherProps } = this.props;

    let dFormat = dateFormat ? dateFormat : DateTimeHelper.getDateFormat();
    let tFormat = timeFormat ? timeFormat : DateTimeHelper.getTimeFormat();

    const sizeClass = size ? `size--${size}` : '';

    popperClassName = popperClassName ? popperClassName + ' pl-datepicker-popper' : 'pl-datepicker-popper';

    return (
      <div id={this.props.id} className={`pl-datepicker ${containerClassName} ${sizeClass} ${this.errorClass()}`}>
        <DatePicker
          autoComplete="off"
          showTimeSelect={showTimeSelect}
          fixedHeight
          dateFormat={dFormat}
          timeFormat={tFormat}
          showMonthDropdown
          showYearDropdown
          {...otherProps}
          locale={DateTimeHelper.getUserOrgLocale()}
          ref={this.setPickerRef}
          onClickOutside={this.clickOutside}
          dropdownMode="select"
          selected={this.handleSelect()}
          onChangeRaw={this.onChangeRaw}
          onChange={this.onChange}
          placeholderText={this.props.placeholderText ? this.props.placeholderText : getDateFormatPlaceholder()}
          popperClassName={popperClassName}
          filterDate={this.filterDateWrapper(filterDate)}
        />
        <ValidationComponent className="validation-component" {...this.props.meta} />
      </div>
    );
  }
}

PL_DatePickerForm.propTypes = {
  id: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  setRef: PropTypes.func,
  setInnerRef: PropTypes.func,
  placeholderText: PropTypes.string,
  onChangeCallback: PropTypes.func,
  selected: PropTypes.object, //control the value externally, listen to onChangeCallback to register user changes
  initialDate: PropTypes.object, //only affects the selected date, but is overridden by user changes,
  filterDate: PropTypes.func, // (date: Moment) => Boolean; whether to allow user to pick given date.
};
