export const schedulingPreferencesKeys = {
  START_OF_DAY: 'generalsetting.orgpreferences.scheduler.start.of.the.day',
  START_DAY_OF_WEEK: 'generalsetting.orgpreferences.start.day.of.week',
  HUMAN_CAPACITY_ENABLED: 'generalsetting.orgpreferences.scheduler.human.capacity.enabled',
  MINIMUM_TOOL_CAPACITY: 'generalsetting.orgpreferences.scheduler.minimum.applicable.tool.capacity.percentage',
  MAXIMUM_TOOL_CAPACITY: 'generalsetting.orgpreferences.scheduler.maximum.applicable.tool.capacity.percentage',
  COMPLETED_COLOR: 'generalsetting.orgpreferences.scheduler.task.status.color.completed',
  MAINTENANCE_COLOR: 'generalsetting.orgpreferences.scheduler.task.status.color.maintenance',
  SCHEDULED_COLOR: 'generalsetting.orgpreferences.scheduler.task.status.color.scheduled',
  STARTED_COLOR: 'generalsetting.orgpreferences.scheduler.task.status.color.started',
  WEEK_HORIZON_START: 'generalsetting.orgpreferences.scheduler.week.horizon.start',
  TASKS_COMPLETION_NOTIFICATION_THRESHOLD: 'generalsetting.orgpreferences.scheduler.notificationThresholdPercentage',
  SHOW_INCOMPLETE_TASKS_RATIO: 'generalsetting.orgpreferences.scheduler.showIncompleteTaskRatio',
  TILE_NAME: 'generalsetting.orgpreferences.scheduler.tile.name.option',
};
