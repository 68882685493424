import React from 'react';
import {reduxForm}        from 'redux-form';
import Dialog               from 'infrastructure/js/components/Dialog/dialog';
import {createLabelHelper}  from 'infrastructure/js/utils/labelHelper';
import InputSection         from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Checkbox             from 'infrastructure/js/components/controls/Checkbox/checkbox';
import TextField            from 'infrastructure/js/components/controls/TextField/textField';
import Validation           from 'infrastructure/js/components/controls/controlsValidations';
import Combobox             from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse                from 'infrastructure/js/components/controls/controlsParse';
import Format               from 'infrastructure/js/components/controls/controlsFormat';
import NatureListItem       from '../../../../../Common/Controls/AddRemoveList/AddRemoveListItem/NatureListItem/natureListItem';
import List from 'infrastructure/js/components/List/list';

require('./createAssetTypeStepOneDialog.scss');


export class CreateAssetTypeStepOneDialog extends React.PureComponent {

  constructor(props) {
    super(props);
    this.labels       = createLabelHelper('mat.administration.matsettings.wizard.createAssetType.');
    this.dialogLabels = createLabelHelper('mat.dialog.');

    this.itemToEdit = this.getItemToEdit();
    this.isEditMode = !!this.itemToEdit;

    this.allNatures = this.getNatures();

    let idsInUse = [];

    this.state = {
      curSelectedParent: null,
      parentNaturesIds: [],
      optionsInUse: idsInUse,
    };
  }

  componentDidMount() {
    if (this.isEditMode) {
      let initialValues = {
        nameSingular: this.itemToEdit.name,
        namePlural: this.itemToEdit.pluralName,
        parent: this.itemToEdit.parent ? this.itemToEdit.parent.id : null,
        icon: this.itemToEdit.icon,
        category: this.itemToEdit.category,
      };

      let parentNaturesIds = [];

      if (this.itemToEdit.parent && this.itemToEdit.parent.natureDefs) {
          let parentNatures = this.itemToEdit.parent.natureDefs.map ((item) => {return {nature: item.id}});
          initialValues.parentNatures = parentNatures ? parentNatures : [];
          parentNaturesIds = this.itemToEdit.parent.natureDefs.map ((item) => {return item.id});

          this.setState({
            curSelectedParent: this.itemToEdit.parent,
            optionsInUse: parentNaturesIds,
          });
      }

      if (this.itemToEdit.natures) {
        let natures = this.itemToEdit.natures
          .filter((item) => {return parentNaturesIds.indexOf(item.natureDef.id) < 0})
          .map ((item) => {return {nature: item.natureDef.id}});

        initialValues.natures = natures ? natures : [];
      }

      this.props.initialize(initialValues);
    }
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text:this.dialogLabels.get('cancel'),
        action: this.handleCancel
      }],

      right: [{
        id:'handleNextButton',
        text: this.dialogLabels.get('next'),
        bsStyle: 'primary',
        action: this.props.handleSubmit(this.handleNext),
        disabled: this.props.sData.get('loading') ||
        this.props.sData.get('hasError'),
      }]
    };
  }

  handleCancel = () => {
    this.props.actions.onCancel();
  };

  handleNext = (formData) => {
    if(this.isEditMode) {
      formData.assetTypeId = this.itemToEdit.id;
    }
    this.props.actions.saveStepOneData(formData, this.props.wizardActions.next) ;
  };

  getParents = () => {
    let initialData = this.props.sData.get('initialData');
    let parents = initialData.parents;
    //exclude the current asset type from the parents list
    if (this.isEditMode && parents) {
      parents = parents.filter((item) => { return item.value !== this.itemToEdit.id } )
    }
    return parents ? parents : [];
  };

  getNatures = () => {
    let initialData = this.props.sData.get('initialData');
    return initialData && initialData.natures ? initialData.natures : [];
  };

  getIcons = () => {
    let initialData = this.props.sData.get('initialData');
    return initialData && initialData.icons ? initialData.icons : [];
  };

  getItemToEdit = () => {
    let initialData = this.props.sData.get('initialData');
    return initialData && initialData.itemToEdit ? initialData.itemToEdit : null;
  };

  //----------------------


  getOptionsInUse = (itemIndex) => {

    let idsInUse = [...this.state.optionsInUse];

    //exclude the current item's selected option form this list
    if (idsInUse.length > itemIndex) {
      let itemId = idsInUse[itemIndex];
      if (itemId) {
        idsInUse = idsInUse.filter((id) => {
          return id !== itemId;
        });
      }
    }
    return idsInUse;
  };

  getAvailableOptions = (itemIndex) => {
    let allOptions = this.allNatures.filter((item) => {return this.state.parentNaturesIds.indexOf(item.value) < 0;});
    let optionsInUse = this.getOptionsInUse(itemIndex);

    return allOptions.filter((option) => {return optionsInUse.indexOf(option.value) < 0;});
  };

  onChangeCallback = (value, oldValue, index) => {
    let idsInUse = [...this.state.optionsInUse];

    if (oldValue) { //no oldValue on first change
      idsInUse[index] = null;
    }

    if (value) { //no value when clear the selection
      idsInUse[index] = value.value;
    }

    this.setState({optionsInUse: idsInUse});
  };

  onRemoveCallback = (index) => {
    let idsInUse = [...this.state.optionsInUse];

    if (index > -1) {
      idsInUse.splice(index, 1);
    }

    this.setState({optionsInUse: idsInUse});
  };

  onAddCallback = () => {
    let idsInUse = [...this.state.optionsInUse];

    idsInUse.push(null); //hold index in optionsInUse for the new added item

    this.setState({optionsInUse: idsInUse});
  };


  //-----------------------

  onParentChange = (newValue, oldValue ) => {
    //prevent clearing the 'Natures' list when there was no change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    if (newValue === null) {
      this.props.change('parentNatures', []);
      this.props.change('natures', []);
      this.setState(
        { curSelectedParent: null,
          parentNaturesIds: [],
          optionsInUse: [],
        });
      return;
    }

    let parentNatures = newValue.data.natureDefs.map((item) => {return {nature: item.id}});
    let parentNaturesIds = newValue.data.natureDefs.map((item) => {return item.id});

    this.props.change('parentNatures', parentNatures);
    this.props.change('natures', []);

    this.setState({
      curSelectedParent: newValue.data,
      parentNaturesIds: parentNaturesIds,
      optionsInUse: []
    });
  };

  getComponentToRender = (props) => {
    if (!props || !props.data ) {
      return null;
    }
    return (<span className={props.data.label}/>);
  };

  listItemRenderer = (data) => {
    return (
      <NatureListItem
        {...data}
        onChangeCallback={this.onChangeCallback}
        options={this.getAvailableOptions(data.index)}
        {...this.props}
      />
    )
  };

  renderParentNatures = () => {
    if (!this.state.curSelectedParent || !this.state.curSelectedParent.natureDefs) {
      return null;
    }

    let parentNatureDefs = this.state.curSelectedParent.natureDefs;
    let parentNatures = parentNatureDefs.map((item, index)=> {
      return (
        <NatureListItem name={'parentNatures.[' + index + ']'}
                        id={'parentNatures.[' + index + ']'}
                        key={'parentNatures'+ index}
                        index={index}
                        options={this.getNatures()}
                        disabled={true}
        /> );
    } );

    return <div className="parent-natures-list">{parentNatures}</div>
  };

  renderNaturesSections = () => {
    return (
      <React.Fragment>

        <div className="list-natures-title input-section">
          <label>{this.labels.get('natures')}</label>
        </div>

        {this.renderParentNatures()}

        <List name='natures'
                       id='natures'
                       itemRenderer={this.listItemRenderer}
                       onRemoveCallback={this.onRemoveCallback}
                       onAddCallback={this.onAddCallback}
                       addButtonLabel={this.labels.get('button.add')}
        />
      </React.Fragment>
    )
  };

  maxLength30 = Validation.maxLength(30);

  render() {
    return (
      <Dialog
        id="create-asset-type-step-one-dialog"
        className="create-asset-type-step-one-dialog"
        show={true}
        onHide={this.handleCancel}
        sData={this.props.sData}
        titleText={this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create')}
        footerButtons={this.getDialogButtons()}
        style={this.props.style}
      >

        <InputSection label={this.labels.get('name.singular')+'*'} htmlFor="nameSingular" className="inline left-side no-margin">
          <TextField id="nameSingular" name="nameSingular" className="short-textfield"
                     validate={[Validation.required, this.maxLength30]}
          />
        </InputSection>

        <InputSection label={this.labels.get('name.plural')+'*'} htmlFor="namePlural" className="inline right-side no-margin">
          <TextField id="namePlural" name="namePlural" className="short-textfield"
                     validate={[Validation.required, this.maxLength30]}
          />
        </InputSection>

        <InputSection label={this.labels.get('parent')} htmlFor="parent" className="inline left-side">
          <Combobox id="parent" name="parent" options={this.getParents()}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.getParents())}
                    onChangeCallback={this.onParentChange}
          />
        </InputSection>

        <div className="inline right-side multiple-items">
          <InputSection label={this.labels.get('icon')} htmlFor="icon" className="inline left-side no-margin half-width">
            <Combobox id="icon" name="icon" options={this.getIcons()}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(this.getIcons())}
                      singleValueRenderer={this.getComponentToRender}
                      optionRenderer={this.getComponentToRender}

            />
          </InputSection>

          <InputSection htmlFor="category" className="inline right-side no-margin half-width" >
            <Checkbox name="category" id="category"  label={this.labels.get('category')}/>
          </InputSection>
        </div>

        {this.renderNaturesSections()}

      </Dialog>
    );
  }
}

export default reduxForm({
    forceUnregisterOnUnmount: true, // When this page isn't rendered, don't validate its fields.
    destroyOnUnmount: false,        // When this page isn't rendered, don't destroy the form.
  }
)(CreateAssetTypeStepOneDialog);


