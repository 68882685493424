import {gridsNames} from '../../enums/gridsNames';
import * as tasksService from '../../services/Tasks/tasksService';
import Network from 'infrastructure/js/modules/network';
import { api as gridApi} from './tasksAssignmentsGridActions';
import { api as tasksGridApi} from '../LocationPage/TasksView/tasksGridActions';
import { api as schedulerGanttPageApi} from '../SchedulerPage/schedulerGanttPageActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ACTIVITYLOG_DIALOG_SHOW: 'ACTIVITYLOG_DIALOG_SHOW',
  ACTIVITYLOG_DIALOG_HIDE: 'ACTIVITYLOG_DIALOG_HIDE',
  ACTIVITYLOG_DIALOG_SET_BUSY: 'ACTIVITYLOG_DIALOG_SET_BUSY',
  ACTIVITYLOG_DIALOG_SET_CONFIG_DATA: 'ACTIVITYLOG_DIALOG_SET_CONFIG_DATA'
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.ACTIVITYLOG_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.ACTIVITYLOG_DIALOG_HIDE, payload: payload };
  },
  setBusy(payload) {
    return { type: actionTypes.ACTIVITYLOG_DIALOG_SET_BUSY, payload: payload };
  },
  setConfigData: function(payload) {
    return {type: actionTypes.ACTIVITYLOG_DIALOG_SET_CONFIG_DATA, payload: payload };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.hide = function() {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)();
  }
};

jsxActions.show = function() {
  return function(dispatch, getState) {
    api.show(dispatch, getState)();
  }
};
jsxActions.updateCompletedTasks = function() {
  return function(dispatch, getState) {
    api.updateCompletedTasks(dispatch, getState)();
  }
};
jsxActions.setConfigData = function(data) {
  return function(dispatch, getState) {
    api.setConfigData(dispatch, getState)(data);
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

// Someone wants to close the dialog.
api.hide = function(dispatch, getState) {
  return function() {
    dispatch(actions.hide());
  }
};

// Someone opens the dialog.
api.show = function(dispatch, getState) {
  return function() {
    dispatch(actions.show());
  }
};
api.setConfigData = function(dispatch, getState) {
  return function(data) {
    dispatch(actions.setConfigData(data));
  }
};

api.updateCompletedTasks = function(dispatch, getState) {
  return function() {

    dispatch(actions.setBusy(true));

    let rows = getState().grid.get(gridsNames.TASKS_ASSIGNMENTS).get('rows');
    let data = rows?.map(item => {
      return {
        taskAssignmentId: item?.id,
        completedQuantity: item?.task?.completedQuantity,
      }
    });

    tasksService.updateCompletedTasks({tasksAssignments: data?.toJS()}).then(response => {

      dispatch(actions.setBusy(false));
      if (!Network.isResponseValid(response)) {
        console.error('updating Completed Tasks failed', response);
        return {success: false};
      }

      gridApi.reload(dispatch, getState)();

      tasksGridApi.reload(dispatch, getState)();

      schedulerGanttPageApi.refreshPageData(dispatch, getState)();
    })
  }
};



