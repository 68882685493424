import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm, formValueSelector} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import List from 'infrastructure/js/components/List/list';
import EmailListItem from '../../../../Common/Form/EmailListItem/emailListItem';
import PhoneListItem from '../../Common/PhoneListItem/phoneListItem';
import {AddRemoveListExt} from '../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import ChannelListItem from '../../ChannelListItem/channelListItem';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';

import'./createExternalNotificationDialog.scss';

const TabKeys = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  TEAMS: 'TEAMS',
  SLACK: 'SLACK',
};


const hasSomeValue = (values) => {
  const emails = values.emails?.filter(item => !!item.emailInput?.data?.email);
  const phoneNumbers = values.phoneNumbers?.filter(item => !!item.phoneNumber?.data?.phone)
  const teamsChannels = values.teamsChannels?.filter(item => !!item.teamsChannel)
  const slackChannels = values.slackChannels?.filter(item => !!item.slackChannel)

  return !(!emails?.length && !phoneNumbers?.length && !teamsChannels?.length && !slackChannels?.length);
}

class CreateExternalNotificationDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.administration.userSettings.dialog.createExternalNotification.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
    this.isEditMode = !!this.itemToEdit;

    this.state = {
      selectedTab: TabKeys.EMAIL,
    };
  }

  componentDidMount() {
    let initialValues = {
      active: true,
      emails: [{}],
      phoneNumbers: [{}],
      teamsChannels: [{}],
      slackChannels: [{}],
    };

    if (this.isEditMode) {
      let {active, type, emailSettings, smsSettings, teamsSettings, slackSettings} = this.itemToEdit
      initialValues = {
        active,
        trigger: type,
        emails:        emailSettings?.length ? emailSettings?.map(item => {return {emailInput: this.getInitialEmailValue(item)}}) : [{}],
        phoneNumbers:  smsSettings?.length ? smsSettings?.map(item => {return {phoneNumber: this.getInitialPhoneValue(item)}}) : [{}],
        teamsChannels: teamsSettings?.length ? teamsSettings?.map(item => {return {teamsChannel: item.webhookId}})  : [],
        slackChannels: slackSettings?.length ? slackSettings?.map(item => {return {slackChannel: item.webhookId}}) : [{}],
      };
    }

    this.props.initialize(initialValues);
  }

  getInitialEmailValue(item) {
    return {
      label: item,
      value: item,
      data: {
        fullName: '',
        email: item,
      }
    }
  }

  getInitialPhoneValue(item) {
    return {
      label: item,
      value: item,
      data: {
        fullName: '',
        phone: item,
      }
    }
  }

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.isEditMode ? this.dialogLabels.get('edit') : this.dialogLabels.get('create'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: (this.isEditMode && this.props.pristine || this.props.sData.get('loading') || !hasSomeValue(this.props.fieldsValues))
        }
      ]
    };
  }

  getTriggers = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.triggers || [];
  }

  getTeamsChannels = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.teamsChannels || [];
  }

  getSlackChannels = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.slackChannels || [];
  }

  onSubmit = (data) => {
    let newData = {
      active: data.active,
      type: data.trigger,
      emailSettings: data.emails?.filter(item => !!item.emailInput?.data?.email)?.map(item => item.emailInput?.data?.email) || [],
      smsSettings: data.phoneNumbers?.filter(item => !!item.phoneNumber?.data?.phone)?.map(item => item.phoneNumber?.data?.phone) || [],
      teamsSettings: data.teamsChannels?.filter(item => !!item.teamsChannel)?.map(item => {return {webhookId: item.teamsChannel}}) || [],
      slackSettings: data.slackChannels?.filter(item => !!item.slackChannel)?.map(item => {return {webhookId: item.slackChannel}}) || [],
    };

    if (this.isEditMode) {
      newData.id = this.itemToEdit.id;
    }
    this.props.actions.submit(newData, null, this.props.reloadParentComponent, {isEditMode: this.isEditMode});
  };

  onHide = () => {
    this.props.actions.hide();
  };

  onTabClick = (selectedTab) => {
    this.setState({selectedTab})
  }

  getEmailItemRenderer = (data) => {
    return (
      <EmailListItem
        {...data}
        change={this.props.change}
        touch={this.props.touch}
        actions={this.props.actions}
      />
    )
  };

  getPhoneNumberItemRenderer = (data) => {
    return (
      <PhoneListItem
        {...data}
        change={this.props.change}
        touch={this.props.touch}
        actions={this.props.actions}
      />
    )
  };

  getTeamsChannelItemRenderer = (data) => {
    return (
      <ChannelListItem
        {...data}
        fieldName='teamsChannel'
      />
    )
  };

  getSlackChannelItemRenderer = (data) => {
    return (
      <ChannelListItem
        {...data}
        fieldName='slackChannel'
      />
    )
  };

  render() {
    let titleText = this.isEditMode ? this.labels.get('header.title.edit') : this.labels.get('header.title.create');
    let selectedTab = this.state.selectedTab;

    let preselectedTeamsChannelsIds = this.isEditMode ? this.itemToEdit.teamsSettings?.map(item => item.webhookId) : [];
    let preselectedSlackChannelsIds = this.isEditMode ? this.itemToEdit.slackSettings?.map(item => item.webhookId) : [];

    return (
      <Dialog
        id="create-external-notification-dialog"
        className="create-external-notification-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        // onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <InputSection htmlFor="active" className="inline two-columns no-margin">
          <Checkbox name="active" id="active"  label={this.labels.get('active')} />
        </InputSection>


        <InputSection label={this.labels.get('trigger')+'*'} htmlFor="trigger" className="trigger-section">
          <Combobox id="trigger" name="trigger"
                    options={this.getTriggers()}
                    parse={Parse.comboValueOnly()}
                    format={Format.findOptionByValue(this.getTriggers())}
                    validate={Validation.dropdown.required}
          />
        </InputSection>

        <Tabs id="content-tabs" activeKey={selectedTab} tabType="default"
              onSelect={this.onTabClick}
              >
          <Tabs.Tab className="email-tab" eventKey={TabKeys.EMAIL} title={this.labels.get('tabs.email')} animation={true}>
              <List className="emails-list"
                    name="emails"
                    itemRenderer={this.getEmailItemRenderer}
                    maxItemsToOverflow={7}
                    addButtonLabel={this.labels.get('button.addEmail')}
              />
          </Tabs.Tab>

          <Tabs.Tab className="sms-tab" eventKey={TabKeys.SMS} title={this.labels.get('tabs.sms')} animation={false}>
            <List className="phoneNumbers-list"
                  name="phoneNumbers"
                  itemRenderer={this.getPhoneNumberItemRenderer}
                  maxItemsToOverflow={7}
                  addButtonLabel={this.labels.get('button.addPhoneNumber')}
            />
          </Tabs.Tab>

          <Tabs.Tab className="teams-tab" eventKey={TabKeys.TEAMS} title={this.labels.get('tabs.teams')} animation={false}>
            <AddRemoveListExt className="teamsChannels-list"
                              name="teamsChannels"
                              itemRendererComponent={this.getTeamsChannelItemRenderer}
                              itemRendererOptions={this.getTeamsChannels()}
                              preSelectedItemsIds={preselectedTeamsChannelsIds}
                              maxItemsToOverflow={7}
                              hideAddAtButton={true}
                              addButtonLabel={this.labels.get('button.addTeamsChannel')}
            />
          </Tabs.Tab>

          <Tabs.Tab className="slack-tab" eventKey={TabKeys.SLACK} title={this.labels.get('tabs.slack')} animation={false}>
            <AddRemoveListExt className="slackChannels-list"
                              name="slackChannels"
                              itemRendererComponent={this.getSlackChannelItemRenderer}
                              itemRendererOptions={this.getSlackChannels()}
                              preSelectedItemsIds={preselectedSlackChannelsIds}
                              maxItemsToOverflow={7}
                              hideAddAtButton={true}
                              addButtonLabel={this.labels.get('button.addSlackChannel')}
            />
          </Tabs.Tab>
        </Tabs>
      </Dialog>
    );
  }
}

CreateExternalNotificationDialog = reduxForm({
    form: 'createExternalNotificationDialog',
  }
)(CreateExternalNotificationDialog);

const selector = formValueSelector('createExternalNotificationDialog');

export default CreateExternalNotificationDialog = connect( state => {
  return {  fieldsValues: selector(state, 'emails', 'phoneNumbers', 'teamsChannels', 'slackChannels' ) }
})( CreateExternalNotificationDialog );

CreateExternalNotificationDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};



