import PropTypes from 'prop-types';
import DroppableWrapper from '../droppableWrapper';
import { filterTypes } from '../../../../../enums/shiftSchedulerEnums';

const GridRow = ({ rowData={},
                   actions={},
                   children='',
                   isTemplate=false,
                   checkIsDroppable=()=>true,
                   setHighlighted=()=>{},
                   highlighted=null
                 }) => {

  const onDragEnter = (e) => {
    setHighlighted({ direction: 'row', headerId: rowData.id, target: e.target });
  };

  const onDragLeave = (e) => {
    if (highlighted && e.target !== highlighted.target) {
      return;
    }
    setHighlighted(null);
  };

  const dropAction = (assignmentData) => {
    if (assignmentData?.shiftData?.id === 'eraser') {
      const filterData = {
        filterBy: filterTypes.RESOURCE,
        resourceId: rowData.id,
      };
      actions.removeShifts({ filterData });
      return;
    }

    actions.assignShift(assignmentData);
  };

  return (
    <div className="grid-row-wrapper">
      <DroppableWrapper
        className="grid-item grid-row-header-cell"
        action={dropAction}
        assignmentData={{ resourceData: rowData }}
        isHeader={true}
        isTemplate={isTemplate}
        checkIsDroppable={checkIsDroppable}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        setHighlighted={setHighlighted}
      >
        {rowData.name}
      </DroppableWrapper>
      {children}
    </div>
  );
};

GridRow.propTypes = {
  rowData: PropTypes.object,
  actions: PropTypes.object,
  children: PropTypes.node,
  isTemplate: PropTypes.bool,
  checkIsDroppable: PropTypes.func,
  setHighlighted: PropTypes.func,
  highlighted: PropTypes.object,
};

export default GridRow;
