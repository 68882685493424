// @ts-check

import { MapView, Mappedin, MappedinMap, getVenue } from "@mappedin/mappedin-js";
import { useEffect, useState } from "react";
import MapControl from "../MapControl/MapControl";


/**
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").Pl_Map} Pl_Map
 * 
*/





/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
export default function useSwitchMapToDefaultMode(refContext) {
  
  let mapView = refContext.current.refMapView.current;
  
  useEffect(() => {
    
    if (!mapView) {
      return;
    }
    
    let venue = mapView.venue;
    if (!venue) {
      return;
    }
    
    // console.log('useSwitchMapToDefaultMode => ');
    
    MapControl.init__PolyData(refContext);
    MapControl.recolorMap__MODE_NORMAL(refContext);
    MapControl.setInteractivity__MODE_NORMAL(refContext);
    MapControl.setupCamera__MODE_NORMAL(refContext);
    MapControl.setupCamera__GO_DEFAULT(refContext);


  }, [mapView]);

  return [
    
  ];
}












