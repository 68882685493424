import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header, {headerItemsLabels} from '../../Common/Header/header';
import ArchivedToolsGrid  from './ArchivedToolsGrid/archivedToolsGrid';
import {gridsNames} from '../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import ActivateAssetsDialog from  '../../Dialogs/ActivateAssetsDialog/activateAssetsDialog';
import MoveAssetsDialog     from  '../../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
require('./archivedToolsPage.scss');

export default class ArchivedToolsPage extends Component {

  getDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ARCHIVED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ARCHIVED],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')],
      preselectedAssets: this.props.sSelectedRowsData
    };
  };

  getHeaderItems() {
    let {actions, sHasRows, sSelectedRowsData} = this.props;
    let dialogConfig = this.getDialogConfig();

    return {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id:'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        ...(PermissionManager.hasActivateAssetsPermissions() ? [{
          id:'activate',
          label: headerItemsLabels.ACTIVATE,
          icon: 'pl pl-activate',
          counter: sSelectedRowsData.size,
          disabled: !sHasRows,
          action: actions.header.onActivateClick,
          actionData: dialogConfig
        }] : []),
        {
          id:'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: {gridName: gridsNames.PREDEFINED_LIST_ARCHIVED_TOOLS}
        },
      ],

      kebab: {
        disabled: !sHasRows,
        menuItems: [
          {
            id:'moveTo',
            label: headerItemsLabels.MOVE_TO,
            action: actions.header.onMenuItemMoveToClick,
            actionData: this.getMoveAssetsDialogConfig()
          },
        ]
      }
    };
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderActivateAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={ActivateAssetsDialog}
                        show={this.props.sActivateAssetsDialogData.get('show')}
                        sData={this.props.sActivateAssetsDialogData}
                        actions={this.props.actions.activateAssetsDialogActions}
                        reloadParentComponent={this.reloadPage} />
    );
  };

  renderMoveAssetsDialog = () => {
    return (
      <PL_DialogWrapper dialogComponent={MoveAssetsDialog}
                        show={this.props.sMoveAssetsData.get('show')}
                        actions={this.props.actions.moveAssetsActions}
                        sData={this.props.sMoveAssetsData}
                        reloadParentComponent={this.reloadPage}/>
    );
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (<div className="archived-tools-page">
        <Header {...headerItems}/>
        <ArchivedToolsGrid actions={{...this.props.actions.archivedToolsGridActions, ...this.props.actions.fetchEntitiesActions}} />

        { this.renderActivateAssetsDialog() }
        { this.renderMoveAssetsDialog() }
      </div>
    );
  }
};

ArchivedToolsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sGrid: PropTypes.object,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};
