import * as kitsService from '../../services/Kits/kitsService';
import {gridsNames} from "../../enums/gridsNames";
import * as gridActionsHelper from "infrastructure/js/components/Grid/Utils/gridActionsHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ACTIVE_KITS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return kitsService.fetchActiveKits(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds){
  let kits = rows.map((obj, index) => {
    if(obj.expirationDate) {
      obj.m_ExpirationDaysLeft = DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds);
    }

    obj.dueDateLeft = DateTimeHelper.GetDifferenceInDays(obj.workOrder && obj.workOrder.dueDate ? obj.workOrder.dueDate : 0, serverTimeSeconds);

    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }
    
    return obj;
  });

  return kits;
};


export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
