import React from 'react';
import { connect } from 'react-redux';
import {reduxForm, formValueSelector  } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect.js';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import WoAttributes from './WoAttributes/woAttributes.js';
import * as additionalFieldsHelper from '../../Common/Helpers/AdditionalFieldsHelper';
import isEmpty from 'lodash-es/isEmpty';
require('./editWoAttributesDialog.scss');

class EditWoAttributes extends React.PureComponent {

  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialogs.editwoattributes.');
    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();
    this.preselectedAssets = this.props.sData.get('preselectedAssets');

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: this.props.sData.get('boEntityFilter')
    };
  }

  getDialogButtons() {
    let cancelTokenEnabled = !!this.props.sData.get("cancelToken") ;
    return {
      left: [{
        id:'cancel',
        text: this.props.sData.get('loading') ? this.dialogLabels.get('stop') : this.dialogLabels.get("cancel"),
        action: this.props.sData.get('loading') ? this.onStop : this.onHide,
        enabledOnLoading: cancelTokenEnabled

      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.updatewos'),
          bsStyle: "primary",
          loading: this.props.sData.get('loading'),
          action:  this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))

        }
      ]
    };
  }

  onSubmit = (data) => {

    let row = data['select-wos'];
    let workOrderIds = (row && row.assetsValue) ? row.assetsValue.map((asset) => {return asset.value}) : [];

    let updateData = this.getUpdatedData(workOrderIds, data);

    updateData.ignoreValidationWarnings = (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
      data.footerValidationCheckbox : false;

    this.props.actions.submit(
      {updateData, assetsType: this.woType},
      this.getMessageDialogBuilder(),
      this.props.reloadParentComponent
    );
  };

  getUpdatedData(workOrderIds, data) {
    let updateData = { workOrderIds };
    if (data.project) {
      updateData.projectId = data.project.value;
    }
    if (data.part) {
      updateData.partType = { id: data.part.value};
    }
    if (data.quantity) {
      updateData.quantity = data.quantity;
    }
    if (data.customerOrder) {
      updateData.customerOrder = this.convertNewCustomerOrder(data.customerOrder);
    }
    if (data.dueDate) {
      updateData.dueDate = DateTimeHelper.ConvertFromDate(data.dueDate);
    }
    if (data.pickDate) {
      updateData.pickDate = DateTimeHelper.ConvertFromDate(data.pickDate);
    }
    if (data.priority) {
      updateData.priority = data.priority ? data.priority.value : null;
    }

    if (data.startAfter?.assetsValue?.length) { // send null for the empty field, not []
      updateData.startAfterWorkOrders = data.startAfter.assetsValue.map(item => item.value); //set null, not []
    }

    if(!isEmpty(this.props.sData.get('additionalFields'))){
      let fields =  additionalFieldsHelper.convertToAdditionalFields(data['additionalFields'], this.props.sData.get('additionalFields'));
      if (fields && fields.length > 0) {
        updateData.additionalFieldsValues = fields;
      }
    }

    return updateData;
  }

  convertNewCustomerOrder = (data) => {
    if (!data || data.label == null) {
      return null;
    }

    return {
      id: (data.value !== data.label) ? data.value : null,
      businessId: data.label
    }
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.labels.get("confirmation.message.title", "", { count: response.totalJobItems});
      return dialogHelper.BuildDialogDescriptorForBO(response, fnCloseDialog, title);
    };
  };

  onHide =() => {
    this.props.actions.hide();
  };

  onStop =() => {
    this.props.actions.stop();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  render() {
    let bodyClassName = isEmpty(this.props.sData.get('additionalFields')) ? '' : 'modal-body-overflow';
    return (
      <Dialog
        id="edit-wo-attributes-dialog"
        className="edit-wo-attributes-dialog"
        bodyClassName={bodyClassName}
        show={!!this.props.sData.get("show")}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get("header.title")}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <InputSection label={this.labels.get("selectwos") +'*'} className="no-margin" />
          <EntitiesMultiSelect
            id="select-wos"
            name="select-wos"
            entitiesTypes={this.entitiesTypes}
            validate={Validation.required}
            preSelectedEntities={this.preselectedAssets}
            fetchConfig={this.fetchConfig}
            autoFocus={true}
            { ...this.props }
          />

        <WoAttributes {...this.props}/>

      </Dialog>
    );
  }
}

EditWoAttributes = reduxForm({
    form: 'editWoAttributes',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(EditWoAttributes);

const selector = formValueSelector('editWoAttributes')

export default EditWoAttributes = connect( state => {
  return {  fieldsValues: selector(state, 'project' , 'part', 'quantity', 'customerOrder', 'dueDate', 'pickDate') }
})( EditWoAttributes );

