import React from 'react';
import Network from 'infrastructure/js/modules/network';
import { createLabelHelper } from "infrastructure/js/utils/labelHelper";
import MessageDialog from "infrastructure/js/components/Dialog/MessageDialog/messageDialog.js";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import { change } from 'redux-form';

function _getLocalizedValidations(validations, type) {
  if (validations && validations.length > 0) {
    let validationsLabels = createLabelHelper('mat.validations.');
    return validations.map((m) => {
      //Note: params should be 'undefined' (or {} but not null) when there is no params (for react-intl)
      let message = validationsLabels.get(m.errorKey, undefined, m.params ? m.params : undefined);
      switch (type) {
        case 'error':
          return {icon: 'pl-error-icon error-color', message: message}
        case 'severityMedium':
          return {icon: 'pl-warning-icon warning-color', message: message}
        case 'severityHigh':
          return {icon: 'pl-warning-icon error-color', message: message}
      }
    });
  }
  return null;
}

export function getMessageDialogDescriptorByErrorCode(errorCode){
  let labels = createLabelHelper('');
  let title = labels.get(errorCode);
  let type = 'error';
  let className = 'oneBackground';

  return {title, type, className};
}

export function getValidationArea(validations) {
  if (validations) {
    let errors = _getLocalizedValidations(validations.errors, 'error');
    let highSeverityWarnings = validations.warnings ? validations.warnings.filter(w => w.severity === 'HIGH') : [];
    let mediumSeverityWarnings =  validations.warnings ? validations.warnings.filter(w => w.severity !== 'HIGH') : [];
    let warnings = [];

    if(highSeverityWarnings && highSeverityWarnings.length){
      warnings = warnings.concat(_getLocalizedValidations(highSeverityWarnings, 'severityHigh'));
    }
    if(mediumSeverityWarnings && mediumSeverityWarnings.length) {
      warnings = warnings.concat(_getLocalizedValidations(mediumSeverityWarnings, 'severityMedium'));
    }

    let result = [];

    let dialogLabels = createLabelHelper('mat.dialog.');
    if (errors) {
      result.push({ title: dialogLabels.get('error'),
                    messages: errors
                  });
    }
    if (warnings && warnings.length) {
      result.push({ title: dialogLabels.get('warning'),
                    messages: warnings
                  });
    }
    return result.length > 0 ? result : null;
  }
  return null;
}

export function getResponseValidationDetails(response) {
  let validations = Network.hasValidationWarnings(response);
  if (!validations) {
    return null;
  }

  let hasHighSeverityWarning = validations.warnings ? !!validations.warnings.find((w) => w.severity === 'HIGH') : false;
  let hasMediumSeverityWarning = validations.warnings ? !!validations.warnings.find((w) => w.severity !== 'HIGH') : false;
  let canIgnoreValidation = !validations.errors && PermissionManager.hasIgnoreValidationsPermissions(hasMediumSeverityWarning, hasHighSeverityWarning);
  return {errors: validations.errors, warnings: validations.warnings, canIgnoreValidation, hasHighSeverityWarning, hasMediumSeverityWarning};
}

export function onFormChangeHandler(values, props, previousValues) {
  if ( props && props.sData && (props.sData.get('showIgnoreValidationCheckbox') || props.sData.get('hasError')) ) {
    if (values.footerValidationCheckbox === previousValues.footerValidationCheckbox) {
      props.dispatch(change(props.form, 'footerValidationCheckbox', false));
      props.actions.hideIgnoreValidationWarningsCheckbox();
      props.actions.toggleIgnoreValidationWarningsCheckbox(false);
    }
    else {
      props.actions.toggleIgnoreValidationWarningsCheckbox(values.footerValidationCheckbox);
    }
  }
}


export function handleSingleResponse(response, operationName, messageDialogBuilder, messageDialogApi, dispatch, getState) {
  if(!Network.isResponseValid(response)) {
    console.error(operationName + " Failed");
    messageDialogApi.responseError(dispatch, getState)(response);
    return false;
  }
  let messageDialogDescriptor = messageDialogBuilder(
    response.data,
    messageDialogApi.close(dispatch, getState)
  );

  messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);

  return true;
}

export function BuildDialogDescriptorForMassOps(response, fnCloseDialog, title) {
  let counters = {
    succeeded: response.printedCounter,
    failed: response.failedCounter + response.pendingCounter,
  };

  return _doBuildDialogDescriptor(counters, title);
}

export function BuildDialogDescriptorForImportFile(response, fnCloseDialog, title, assetType, showButtons=false) {

  let counters = {
    assetType:  assetType,
    succeeded:  response.numberOfSucceededItems,
    failed:     response.numberOfFailedItems,
    warning:    response.numberOfItemsWithWarnings,
    //isCutReported will have 'null' value if is irrelevant
    reportedCutsSuccess: response?.stepDtoMap?.createReportedCutStep?.numberOfSucceededItems ?? 0,
    reportedCutsFailure: response?.stepDtoMap?.createReportedCutStep?.numberOfFailedItems ?? 0,
    reportedCutsWithRollSuccess: response?.stepDtoMap?.createRollsForCutPlanWithRollStep?.numberOfSucceededItems ?? 0,
    reportedCutsWithRollFailure: response?.stepDtoMap?.createRollsForCutPlanWithRollStep?.numberOfFailedItems ?? 0,
    reportedCutsMultiRollSuccess: response?.stepDtoMap?.createMultiCutsStep?.numberOfSucceededItems ?? 0,
    reportedCutsMultiRollFailure: response?.stepDtoMap?.createMultiCutsStep?.numberOfFailedItems ?? 0,
    reportedCutsAnyRollSuccess: response?.stepDtoMap?.createMultiRollsRobustCuttingStep?.numberOfSucceededItems ?? 0,
    reportedCutsAnyRollFailure: response?.stepDtoMap?.createMultiRollsRobustCuttingStep?.numberOfFailedItems ?? 0
  };

  let dialogLabels = createLabelHelper('mat.dialog.');
  let buttons = showButtons ? [{id: 'viewDetails', text: dialogLabels.get('viewdetails'), action: fnCloseDialog, bsStyle: 'link' }] : null;
  let status = _getStatus(counters);
  let children = _createImportFileChildren(counters, response.actionType);

  return {title, children, buttons, type: status, showTitleIcon: false};
}

export function BuildDialogDescriptorForPrintTag(response ,fnCloseDialog , title) {
  let status = response.statusType;
  let children = <MessageDialog.DataRow key={`confirmMessageDataRow`} label={response.message} value=''/>;
  return {title, children, type: status};
}

export function BuildDialogDescriptorForKitOperations(data, labels, fnCloseDialog, onCancelKitReportClick, onPrintRfidTagsClick=null ) {
  let title;
  let buttons = [];
  let children = [];
  let reportedKits = [];

  let dialogLabels = createLabelHelper('mat.dialog.');

  if (data) {
    title = labels.get('title');

    if (data.updatedKits && data.updatedKits.length > 0) {
      reportedKits = reportedKits.concat(data.updatedKits);
      children.push(<MessageDialog.MessageRow key={'row1'}
                                              icon='succeeded'
                                              text={labels.get('updated', '', {count: data.updatedKits.length})} />);
    }

    if (data.createdKits && data.createdKits.length > 0) {
      reportedKits = reportedKits.concat(data.createdKits);
      children.push(<MessageDialog.MessageRow key={'row2'}
                                              icon='succeeded'
                                              text={labels.get('created', '', {count: data.createdKits.length})}/>);
    }

    if (data.failedKits && data.failedKits.length > 0) {
      children.push(<MessageDialog.MessageRow key={'row3'}
                                              icon='failed'
                                              text={labels.get('failed', '', {count: data.failedKits.length})} />);

      let messages = data.failedKits.map((item, index) => {
        if (item.applicationResponseStatus && item.applicationResponseStatus.statusType === 'ERROR') {
          return <div key={index}>{item.applicationResponseStatus.message}</div>
        }
        return null;
      });
      children.push(<MessageDialog.MessageArea key="row4"
                                               text={messages}/>);
    }

    //Add 'Cancel Report' button when there is something to cancel
    if (reportedKits.length > 0) {
      let reportedKitsIds = reportedKits?.map(item => item.id);
      buttons.push({id:'cancel', text: labels.get('cancelreport'), action: () => {onCancelKitReportClick(reportedKitsIds, fnCloseDialog)}})
      //Add 'Print RFID Tag' button when relevant
      if (onPrintRfidTagsClick) {
        buttons.push({
          id: 'printRfidTag',
          text: labels.get('printRfidTag', '', {count: reportedKits.length}),
          action: () => {
            onPrintRfidTagsClick(reportedKits, fnCloseDialog)
          }
        })
      }
    }
    buttons.push({id:'close', text: dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'});
  }
  return {title, children, className: '', buttons, showTitleIcon: false};
}

export function BuildDialogDescriptorForBO(response, fnCloseDialog, title, showButtons=true) {
  let counters = {
    succeeded: response.numberOfSucceededItems,
    failed: response.numberOfFailedItems,
    canceled: response.numberOfCanceledItems,
    warning: response.numberOfItemsWithWarnings,
  };

  let dialogLabels = createLabelHelper('mat.dialog.');
  let labelKey = (response.jobName === 'updateTasksProgressJob_completed') ? 'viewEditDetails' : 'viewdetails';
  let buttons = showButtons ? [{id: 'viewDetails', text: dialogLabels.get(labelKey), action: fnCloseDialog, bsStyle: 'link' }] : null;

  return _doBuildDialogDescriptor(counters, title, buttons);
}

//returns an I icon with the correct tooltip if there is an error in the dialog but no permission to ignore errors
//this I icon is given to the Dialog component in the footerInformationIcon prop and if present is displayed by the confirm button
//sData: dialog data
//dialogLabels: labelhelper with "mat.dialog." namespace
export function getFooterInformationIcon(sData, dialogLabels) {
  if (sData.get('showIgnoreValidationCheckbox')) {
    return null;
  }
  let hasHighSeverity = sData.get('hasHighSeverityWarning'), hasMediumSeverity = sData.get('hasMediumSeverityWarning');
  return hasHighSeverity || hasMediumSeverity ?
    {
      type: 'info-icon-blue',
      tooltip: dialogLabels.get(hasHighSeverity ? 'requiresAdminPermissionHigh' : 'requiresAdminPermissionMedium')
    }
    : null;
}

function _doBuildDialogDescriptor( counters, title, buttons = null) {

  let status = _getStatus(counters);

  if (_isSingleType(counters)) {
    let statusLabels = createLabelHelper('mat.dialog.messagedialog.statusrow.label.');
    let titleStatus = _getTitleStatus(counters, null, true);

    if (title && typeof title === 'object' && title.constructor === Object)
      title.props.children[title.props.children.length-1] = ' ' + statusLabels.get(titleStatus).toLowerCase();
    else
      title = `${title} ${statusLabels.get(titleStatus).toLowerCase()}`;

    return {title,  buttons, className: 'oneBackground', type: status};
  }
  //for multiple type result
  let children = _createChildren(counters);

  return {title , children, buttons, type: status, showTitleIcon: false};
}


// actionType is for passing different label in some cases (bug 3626)
// options: "create","createOrUpdate"
function _createImportFileChildren(counters, actionType = "create") {
  let children = [];
  _addImportFileRow(counters.reportedCutsSuccess, 'reportedCutsSuccess', 'succeeded', children ,'material', actionType);
  _addImportFileRow(counters.reportedCutsFailure, 'reportedCutsFailure', 'failed', children ,'material', actionType);
  _addImportFileRow(counters.reportedCutsMultiRollSuccess, 'reportedCutsSuccess', 'succeeded', children ,'material', actionType);
  _addImportFileRow(counters.reportedCutsMultiRollFailure, 'reportedCutsFailure', 'failed', children ,'material', actionType);
  _addImportFileRow(counters.reportedCutsAnyRollSuccess, 'reportedCutsSuccess', 'succeeded', children ,'rolls', actionType);
  _addImportFileRow(counters.reportedCutsAnyRollFailure, 'reportedCutsFailure', 'failed', children ,'rolls', actionType);

  _addImportFileRow(counters.reportedCutsWithRollSuccess, 'reportedCutsWithRollSuccess', 'succeeded', children , actionType === 'create' ? 'importCutPlanWithRoll' : 'importCutPlanWithRollUpdate', actionType);
  _addImportFileRow(counters.reportedCutsWithRollFailure, 'reportedCutsWithRollFailure', 'failed', children ,actionType === 'create' ? 'importCutPlanWithRoll' : 'importCutPlanWithRollUpdate', actionType);
  _addImportFileRow(counters.succeeded, 'succeeded', 'succeeded', children ,counters.assetType, actionType);
  _addImportFileRow(counters.failed, 'failed', 'failed', children, counters.assetType, actionType);
  _addImportFileRow(counters.warning, 'warning', 'warning', children, counters.assetType, actionType);
  return children;
}

function _createChildren(counters) {
  let children = [];
  _addRow(counters.succeeded, "succeeded", children);
  _addRow(counters.failed, "failed", children);
  if (counters.warning) {
    _addRow(counters.warning, "warning", children);
  }
  if (counters.canceled) {
    _addRow(counters.canceled, "canceled", children);
  }

  return children;
}

function _isSingleType(counters) {
  let typesCount = 0;
  if (counters.failed > 0) {
    ++typesCount
  }
  if (counters.warning > 0) {
    ++typesCount
  }
  if (counters.canceled > 0) {
    ++typesCount
  }
  if (counters.succeeded > 0) {
    ++typesCount
  }

  return (typesCount === 1);
}

function _getStatus(counters) {
  if (counters.failed > 0) {
    return "error";
  }
  if (counters.warning > 0) {
    return "warning";
  }
  if (counters.canceled > 0) {
    return "canceled";
  }
  return "success";
}


function _addRow(count, type, children) {
  if (count == 0) {
    return;
  }

  children.push(<MessageDialog.StatusRow key={`confirmMessageStatusRow${type}`} type={type} count={count} />);
}

function _addImportFileRow(count, type, status, children , assetType, actionType) {
  if (count == 0) {
    return;
  }
  children.push(<MessageDialog.StatusImportFileRow key={`confirmMessageStatusRow${type}`} type={type} status={status} count={count} assetType={assetType} actionType={actionType} />);
}

function _getTitleStatus(counters, dialogLabels, isSingleStatus) {
  if (!isSingleStatus) {
    return 'multiple';
  }
  if (counters.failed > 0) { return "failed" }
  if (counters.warning > 0) { return "warning" }
  if (counters.succeeded > 0) { return "succeeded" }
  if (counters.canceled > 0) { return "canceled" }
  return "";
}


