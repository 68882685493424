import React, { Component } from 'react';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import { createLabelHelper } from '../../../utils/labelHelper';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Overlay, Popover } from 'react-bootstrap';

import './DateRangePopover.scss';

export default class DateRangePopover extends Component {
    constructor(props) {
        super(props);
        this.labels = createLabelHelper('mat.pickers.daterange.');
        this.validationLabels = createLabelHelper('mat.validation.messages.');
        this.customInputRef = null;
        this.state = {
            startDate: null,
            endDate: null,
            isStartDateValid: false,
            isEndDateValid: false,
            show: false,
            startDummyKey:0,
            endDummyKey:0,
        };
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date?.utcOffset(0, true) ?? null });
        this.props.onStartDateChange && this.props.onStartDateChange(date);
    };

    handleEndDateChange = (date) => {
        this.setState({ endDate: date?.utcOffset(0, true) ?? null });
        this.props.onEndDateChange && this.props.onEndDateChange(date);
    };

    // on change clear corresponding input/picker - so validation function will be aware on which input are we working on.
    // handleStartDateInputChange = (date) => {
    //   date && this.rangeStartPickerRef.clear();
    //   this.handleStartDateChange(date);
    // };

    handleStartDatePickerChange = (date) => {
        // date && this.rangeStartInputRef.clear();
        this.handleStartDateChange(date);
    };

    // handleEndDateInputChange = (date) => {
    //     date && this.rangeEndPickerRef.clear();
    //     this.handleEndDateChange(date);
    // };

    handleEndDatePickerChange = (date) => {
        // date && this.rangeEndInputRef.clear();
        this.handleEndDateChange(date);
    };

  handleStartDateInputClear = (date) => {
    if (!date ) {
      this.rangeStartPickerRef.clear();
      this.setState({startDummyKey: 1 - this.state.startDummyKey})
    }
  };

  handleEndDateInputClear = (date) => {
    if (!date ) {
      this.rangeEndPickerRef.clear();
      this.setState({endDummyKey: 1 - this.state.endDummyKey})
    }
  };

    validateStartDate = (value, allValues) => {
        const { rangeStartInput, rangeStartPicker, rangeEndInput, rangeEndPicker } = allValues;

        const rangeStartValue = rangeStartInput ?? rangeStartPicker;
        const rangeEndValue = rangeEndInput ?? rangeEndPicker;

        if (!rangeStartValue) {
            this.setState({ isStartDateValid: false });
            return this.labels.get('validation.error.bothFieldsRequired');
        }

        const isStartDateValid = rangeStartValue?._isValid;
        const isEndDateValid = rangeEndValue?._isValid;

        if (isStartDateValid && !rangeStartValue.isUTC()) {
            rangeStartValue.utcOffset(0, true);
        }

        if (isEndDateValid && !rangeEndValue.isUTC()) {
            rangeEndValue.utcOffset(0, true);
        }

        if (!isStartDateValid) {
            this.setState({ isStartDateValid: false });
            const invalidDateValue = rangeStartValue?.creationData()?.input;
            if (moment(invalidDateValue, 'DD/MM/YYYY', true).isValid()) {
                return this.validationLabels.get('requiredTime');
            }
            return this.validationLabels.get('invaliddate');
        }

        if (isEndDateValid && rangeStartValue > rangeEndValue) {
            return this.labels.get('validation.error.startTimeLaterThandEndTime');
        }

        this.setState({ isStartDateValid: true });
        return undefined;
    };

    validateEndDate = (value, allValues) => {
        const { rangeStartInput, rangeStartPicker, rangeEndInput, rangeEndPicker } = allValues;

        const rangeStartValue = rangeStartInput ?? rangeStartPicker;
        const rangeEndValue = rangeEndInput ?? rangeEndPicker;

        if (!rangeEndValue) {
            this.setState({ isEndDateValid: false });
            return this.labels.get('validation.error.bothFieldsRequired');
        }

        const isStartDateValid = rangeStartValue?._isValid;
        const isEndDateValid = rangeEndValue?._isValid;

        if (isStartDateValid && !rangeStartValue.isUTC()) {
            rangeStartValue.utcOffset(0, true);
        }

        if (isEndDateValid && !rangeEndValue.isUTC()) {
            rangeEndValue.utcOffset(0, true);
        }

        if (!isEndDateValid) {
            this.setState({ isEndDateValid: false });
            const invalidDateValue = rangeEndValue?.creationData()?.input;
            if (moment(invalidDateValue, 'DD/MM/YYYY', true).isValid()) {
                return this.validationLabels.get('requiredTime');
            }
            return this.validationLabels.get('invaliddate');
        }

        if (isStartDateValid && rangeStartValue > rangeEndValue) {
            this.setState({ isEndDateValid: false });
            return this.labels.get('validation.error.endTimeLaterThanStartTime');
        }

        this.setState({ isEndDateValid: true });

        return undefined;
    };

    getDateRangePopoverComponent = () => {
        const disabled = this.isButtonDisabled();
        return (
            <div className='date-range-popover-content'>
                <div className='date-pickers-wrapper'>
                    <div className='picker-container'>
                        <div className='datepicker-input-container'>
                            <span>{this.props.rangeStartLabel}</span>
                            <DatePicker
                                id='rangeStartInput'
                                name='rangeStartInput'
                                setInnerRef={(ref) => (this.rangeStartInputRef = ref)}
                                popperClassName='d-none'
                                selected={this.state.startDate}
                                //currently, the input is not editable
                                disabled={true}
                                // onInputChangeCallback={this.handleStartDateInputChange}
                                onChangeCallback={this.handleStartDateInputClear}
                                validate={[this.validateStartDate]}
                                preventOpenOnFocus={true}
                                {...this.props.rangeStartInputProps}
                            />
                        </div>
                        <DatePicker
                            key={this.state.startDummyKey}
                            id='rangeStartPicker'
                            name='rangeStartPicker'
                            setInnerRef={(ref) => (this.rangeStartPickerRef = ref)}
                            inline
                            onChangeCallback={this.handleStartDatePickerChange}
                            {...this.props.rangeStartPickerProps}
                        />
                    </div>
                    <div className='picker-container'>
                        <div className='datepicker-input-container'>
                            <span>{this.props.rangeEndLabel}</span>
                            <DatePicker
                                id='rangeEndInput'
                                name='rangeEndInput'
                                setInnerRef={(ref) => (this.rangeEndInputRef = ref)}
                                popperClassName='d-none'
                                selected={this.state.endDate}
                                //currently, the input is not editable
                                disabled={true}
                                // onInputChangeCallback={this.handleEndDateInputChange}
                                onChangeCallback={this.handleEndDateInputClear}
                                validate={[this.validateEndDate]}
                                preventOpenOnFocus={true}
                                {...this.props.rangeEndInputProps}
                            />
                        </div>
                        <DatePicker
                            key={this.state.endDummyKey}
                            id='rangeEndPicker'
                            name='rangeEndPicker'
                            setInnerRef={(ref) => (this.rangeEndPickerRef = ref)}
                            inline
                            onChangeCallback={this.handleEndDatePickerChange}
                            {...this.props.rangeEndPickerProps}
                        />
                    </div>
                </div>
                <div className='date-range-popover-buttons'>
                    <button
                        id='submit'
                        type='button'
                        className='btn btn-primary'
                        onClick={this.doneBtnClick}
                        disabled={disabled}
                    >
                        {this.labels.get('buttons.done')}
                    </button>
                </div>
            </div>
        );
    };

    isButtonDisabled = () => {
        const { startDate, endDate } = this.state;
        return !startDate || !endDate;
    };

    doneBtnClick = () => {
        const { isStartDateValid, isEndDateValid, startDate, endDate } = this.state;
        this.props.onDoneClick && this.props.onDoneClick(startDate, endDate);
        if (isStartDateValid && isEndDateValid) {
            this.togglePopover();
        }
    };

    togglePopover = (event) => {
        event?.stopPropagation();
        this.setState({ show: !this.state.show });
    };

    onHide = () => {
        this.setState({ show: false });
    };

    render() {
        return (
            <div className='date-range-popover-container'>
                <div
                    className='popover-target'
                    ref={(t) => (this.target = t)}
                    onClick={this.togglePopover}
                />
                <Overlay
                    target={this.target}
                    show={this.state.show}
                    // placement='top'
                    placement={this.props.placement || 'top'}
                    rootClose={true}
                    onHide={this.onHide}
                >
                    <Popover className={'pl-popover pl-date-range-popover'} placement='top'>
                      <div className='popover-content'>
                          <div className='popover-component'>
                              {this.getDateRangePopoverComponent()}
                          </div>
                      </div>
                    </Popover>
                </Overlay>
            </div>
        );
    }
}

DateRangePopover.defaultProps = {
    rangeStartLabel: createLabelHelper('mat.pickers.daterange.from'),
    rangeEndLabel: createLabelHelper('mat.pickers.daterange.to'),
};

DateRangePopover.propTypes = {
    rangeStartLabel: PropTypes.string, //label that goes above range start input
    rangeEndLabel: PropTypes.string, //label that goes above range end input
    rangeStartInputProps: PropTypes.object, // range start input datepicker props
    rangeEndInputProps: PropTypes.object, // range start picker datepicker props
    rangeStartPickerProps: PropTypes.object, // range end input datepicker props
    rangeEndPickerProps: PropTypes.object, // range end picker datepicker props
    onExit: PropTypes.func, //(state : Object) => void; callback that is called on popover close with current state.
};
