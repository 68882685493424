import PropTypes from 'prop-types';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';

import './editKitMaterialListItem.scss';

const EditKitMaterialListItem = ({name, options=[]}) => {

  return (
    <div className="kit-material-list-item">
      <Combobox id={ name + '.[materialName]'}
                name={ name + '.[materialName]'}
                validate={Validation.dropdown.required}
                options={options}
                isDisabled={true}
      />
      <TextField id={ name + '.[quantity]'}
                 name={ name + '.[quantity]'}
                 className="short-textfield" normalize={Normalize.number(true, 1, 999)}
                 disabled={true}
      />
      <TextField id={ name + '.[kitted]'}
                 name={ name + '.[kitted]'}
                 className="short-textfield"
                 normalize={Normalize.number(true, 0, 999)}
                 validate={Validation.number.required }
      />
    </div>);
}

EditKitMaterialListItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};

export default EditKitMaterialListItem;


