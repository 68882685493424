import * as spoolsService from '../../services/Spools/spoolsService';
import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from '../../../../infrastructure/js/components/Grid/Utils/gridActionsHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import * as AlertHelper from '../../utils/alertHelper';
const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ARCHIVE_SPOOLS, _fetchDataAction, _convertToRowData);
import {getEnumLabel, enumTypes} from '../../utils/enumHelper.js';

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    return spoolsService.fetchArchivedSpools(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    obj.m_ExpirationDaysLeft = obj.expirationDate ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : null;
    obj.m_archivingDate = DateTimeHelper.FormatDateObjectToDayMonth(obj.lastStatusChangeDate);
    obj.assetStatusLabel = getEnumLabel(enumTypes.ASSET_STATUS)(obj.assetStatus);
    obj.alerts = obj.alerts.map((alert)=> {
      let expirationDaysLeft = (obj.expirationDate && obj.expirationDate.value ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : '');
      let alertDetails = {
        expirationDate: obj.expirationDate,
        expirationDaysLeft: expirationDaysLeft,
        exposureTimeLeftBond: obj.exposureTimeLeftBond,
        exposureTimeLeftCure: obj.exposureTimeLeftCure,
        dueDateMinutesLeft: obj.dueDateMinutesLeft
      };
      alert.m_value = AlertHelper.GetAlertTypeMessageTitle(alert, alertDetails);
      return alert;
    });

    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }
    
    return obj;
  });
  return items;
};

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
