import Network from 'infrastructure/js/modules/network';

export function bulkUpdateTasksProgress(data) {
  return Network.polling.post('planner/tasks/progress', data);
}

export function bulkRollbackTasksProgress(data) {
  return Network.polling.post('planner/tasks/progress/rollback', data);
}

export function updateSingleTaskProgress(taskAssignmentId, data) {
    return Network.post(`planner/tasks/progress/${taskAssignmentId}`, data);
}

export function rollbackTaskProgress(taskAssignmentId, data) {
    return Network.post(`planner/tasks/progress/rollback/${taskAssignmentId}`, data);
}

export function fetchTasksAssignments(query={}) {
    return Network.post('planner/planner-operations/items', query);
}

export function updateCompletedTasks(data) {
    return Network.polling.put('planner/tasks/incomplete/update', data);
}
