import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Popover, OverlayTrigger } from 'react-bootstrap';

require('./popover.scss');


export default class PL_PopoverNew extends React.PureComponent{

  constructor(props) {
    super(props);

    this.state = {isInsidePopover: false, isInsideTarget: false, triggers: ['hover']};

    if (props.setRef)
      props.setRef(this);
  }

  componentDidMount() {
    if (this.props.setRef)
      this.props.setRef(this);
  }

  componentWillUnmount() {
    window.removeEventListener('mousewheel', this.onMouseWheelHandler);

    if (this.props.setRef)
      this.props.setRef(null);
  }

  hide = () => {
    if (this.overlayTriggerRef) {
      this.overlayTriggerRef.hide();
    }
  }

  //-------- Popover  handlers ------
  setOverlayTriggerRef = (r) => {
    this.overlayTriggerRef = r || this.overlayTriggerRef;
  };

  onEnterHandler = () => {
    window.addEventListener('mousewheel', this.onMouseWheelHandler, false);
    if (this.props.holdOnHover) {
      this.setState({triggers: ['focus']});
    }

    if (this.props.onEnter) {
      this.props.onEnter();
    }
  };

  // check if top or bottom of popover element is outside of viewport and adjust top/bottom offset accordingly
  onEnteredHandler = () => {
    if(this.props.popoverId){
      const popoverElement = document.getElementById(this.props.popoverId)
      const bounds = popoverElement?.getBoundingClientRect()
      if(bounds?.top < 0){
        popoverElement.style.top = '10px'
      } else if (bounds?.bottom > window.innerHeight){
        popoverElement.style.bottom = '10px'
        popoverElement.style.top = 'unset'
      }
    }
  }

  onExitHandler = () => {
    window.removeEventListener('mousewheel', this.onMouseWheelHandler);
    if (this.props.holdOnHover) {
      this.setState({isInsidePopover: false, triggers: ['hover']});
    }

    if (this.props.onExit) {
      this.props.onExit();
    }
  };

  onMouseWheelHandler = (e) => {

    if (!this.state.isInsidePopover ) {
      if (this.overlayTriggerRef) {
        this.overlayTriggerRef.hide();
      }
      if (this.props.onExit) {
        this.props.onExit();
      }
     }
  };

  onPopoverMouseEnterHandler = (e) => {
    this.setState({isInsidePopover: true});

  };
  onPopoverMouseLeaveHandler = (e) => {
    this.setState({isInsidePopover: false});

    if (this.props.holdOnHover) {
      setTimeout(() => { this.handlePopoverLeave() }, 500);
    }
  };

  handlePopoverLeave = (e) => {
    if (!this.state.isInsideTarget) {
      if (this.targetRef) {
        this.targetRef.click();
      }
    }
  };


//-------- Target  handlers ------

  setTargetRef = (r) => {
    this.targetRef = r || this.targetRef;
  };

  onTargetMouseEnterHandler = (e) => {
    this.setState({isInsideTarget: true});
  };

  onTargetMouseLeaveHandler = (e) => {
    this.setState({isInsideTarget: false});
    setTimeout(() => {this.handleTargetLeave()}, 500);
  };

  handleTargetLeave = (e) => {
    if (!this.state.isInsidePopover) {
      if (this.targetRef) {
        this.targetRef.click()
      }
    }
  };

  getPopoverComponent() {
    let { className, popoverId, holdOnHover, popoverTitle, popoverComponent, defaultOverlayShown, onEnter, onExit, setRef, hideOnClickOutside, ...otherProps } = this.props;
    return (
      <Popover {...otherProps}
        id={popoverId}
        className={cn('pl-popover', className)}
        onMouseEnter={this.onPopoverMouseEnterHandler}
        onMouseLeave={this.onPopoverMouseLeaveHandler}
      >
        <div className='popover-content'>
          {popoverTitle && <div className="popover-title">{popoverTitle}</div>}
          <div className="popover-component">{popoverComponent}</div>
        </div>
      </Popover>
    );
  }

  render (){
    let {trigger, placement, holdOnHover, defaultOverlayShown, hideOnClickOutside = true} = this.props;
    placement = placement || 'top';
    trigger = holdOnHover ? this.state.triggers : (trigger || ['hover', 'focus']);

    return (
      <OverlayTrigger rootClose={hideOnClickOutside}
                      ref={this.setOverlayTriggerRef}
                      onEnter={this.onEnterHandler}
                      onExit={this.onExitHandler}
                      trigger={trigger}
                      placement={placement}
                      overlay={this.getPopoverComponent()}
                      delay={{show: 500}}
                      defaultOverlayShown={defaultOverlayShown}
                      onEntered={this.onEnteredHandler}
      >
        <div className="popover-target"
             ref={this.setTargetRef}
             onMouseEnter={holdOnHover ? this.onTargetMouseEnterHandler : undefined}
             onMouseLeave={holdOnHover ? this.onTargetMouseLeaveHandler : undefined}
             onClick={event => !holdOnHover && event.stopPropagation()} // fixes a bug introduced by upgrade to react 17. upgrade to react-bootstrap should solve this issue.
        >
          {this.props.children}
        </div>
      </OverlayTrigger>
    )
  }
}

PL_PopoverNew.defaultProps = {
  holdOnHover: false,
  defaultOverlayShown: false,
};

PL_PopoverNew.propTypes = {
  popoverComponent: PropTypes.node.isRequired, //anything that is renderable
  trigger: PropTypes.any,
  placement: PropTypes.string,
  popoverId: PropTypes.string.isRequired,
  popoverTitle: PropTypes.any,
  holdOnHover: PropTypes.bool,
  defaultOverlayShown: PropTypes.bool,
  onEnter: PropTypes.func, //() => void; callback that is called on popover open
  onExit: PropTypes.func, //() => void; callback that is called on popover close
  setRef: PropTypes.func //(popover : Object) => void; callback to get reference to this popover object
};






