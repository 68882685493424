// @ts-check

/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").PolySettings} PolySettings
 * 
 */


export default class MapControlSettings {

  /** @type {PolySettings} */
  static POLY_SETTINGS = {

    layers: {

      "PL_Stations": {
        color: "#8796A4",
        interactive: true,
      },

      "PL_Freezers": {
        color: "#93C9F0",
        interactive: true,
      },

      "PL_Sections": {
        color: "#D2E8F6",
        interactive: true,
      },


      "Furniture": {
        color: "#E0D021",
        interactive: true,
      },

      "Room": {
        color: "#D2E8F6",
        interactive: true,
      },

      "Wall": {
        color: "#D9DCE3",
        interactive: false,
      },

      "Floor": {
        color: "#FFFFFF",
        interactive: false,
      },

      "Non Public": {
        color: "#F3EEE8",
        interactive: false,
      },

      "Elevator": {
        color: "#569CFF",
        interactive: false,
      },

      "Stairs": {
        color: "#555555",
        interactive: false,
      },

    },


  };


  // static ZOOM_THRESHOLDS = {
  //     // BIRDS_EYE: 5000, // 5000 -> 10000
  //     FULL_MAP: 5000,     // 1200 ->  5000
  //     CLOSE_UP: 1200,     //    0 ->  1200
  // };

  static ZOOM_THRESHOLDS = {
      // BIRDS_EYE: 5000, // 5000 -> 10000
      FULL_MAP: 7000,     // 1200 ->  5000
      CLOSE_UP: 2000,     //    0 ->  1200
  };



}








