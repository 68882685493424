import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { autoSchedulingStatuses } from '../../../../../../mat/js/enums/autoSchedulingStatuses';
import PL_Panel from 'infrastructure/js/components/Panel/panel';
import SchedulingIndicatorPanel from './schedulingIndicatorPanel';
import icons from '../../../../../../mat/assets/svg/index';

import './schedulerProgressIndicator.scss';
export default function SchedulerProgressIndicator({ statusData, actions }) {
  const labels = useMemo(() => createLabelHelper('mat.scheduler.gantt.header.progressIndicator.'), []);
  const [panelExpanded, setPanelExpanded] = useState(false);
  const { BUSY, COMPLETED } = autoSchedulingStatuses;
  const isInProgress = statusData.get('status') === BUSY;
  const isCompleted = statusData.get('status') === COMPLETED;

  useEffect(() => {
    if (isCompleted) {
      setPanelExpanded(true);
    }
  }, [isCompleted]);

  const statusText = isInProgress ? labels.get('status.inProgress') : labels.get('status.completed');

  const statusIcon = isInProgress ? (
    <i className="fa fa-spinner fa-spin fa-fw in-progress" aria-hidden="true" />
  ) : (
    <icons.schedulingCompleted className="completed" />
  );

  const togglePanel = () => setPanelExpanded(!panelExpanded);

  const panelActions = {
    hide: () => setPanelExpanded(false),
    abort: actions.abortAsyncScheduling,
    showMoreDetails: actions.showAsyncSchedulingActivityLog,
  };

  return (
    <div className="scheduler-progress-indicator">
      <div className="indicator-box" onClick={togglePanel}>
        <div className="labels">
          <span className="title">{labels.get('title')}</span>
          <span className={cn('status', { 'in-progress': isInProgress, completed: isCompleted })}>{statusText}</span>
        </div>
        <div className="icon">{statusIcon}</div>
      </div>

      <div className="panel-container">
        <PL_Panel
          config={{
            data: <SchedulingIndicatorPanel actions={panelActions} statusData={statusData} />,
            loading: statusData.get('isAborting'),
            expanded: panelExpanded,
            panelClass: 'indicator-panel',
          }}
        />
      </div>
    </div>
  );
}
