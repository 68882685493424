import * as systemService from 'infrastructure/js/services/systemService';
import Network from 'infrastructure/js/modules/network';
import {navigationStates} from '../../enums/navigationStates';
import ServerDateTimeHandler from '../../modules/datetimeActionHandler';
import ScheduleAction from "../../modules/scheduleAction";
import PermissionManager from 'infrastructure/js/utils/permissionManager';
let IniParser = require('config-ini-parser').ConfigIniParser;
let inProgressScheduler = null;
let inBuildingScheduler = null;
let myState = function(getState) {
  return getState().system;
};

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SYSTEM_SET_SERVER_DATETIME:   'SYSTEM_SET_SERVER_DATETIME',
  SYSTEM_CLEAR_ALL:             'SYSTEM_CLEAR_ALL',
  SYSTEM_SET_SERVER_VERSION:    'SYSTEM_SET_SERVER_VERSION',
  SYSTEM_SET_CLIENT_VERSION:    'SYSTEM_SET_CLIENT_VERSION',
  SYSTEM_LOADING_MODE:          'SYSTEM_LOADING_MODE',
  SYSTEM_SET_FEATURE_FLAGS:     'SYSTEM_SET_FEATURE_FLAGS',
  SYSTEM_SET_CURRENT_PAGE_NAME: 'SYSTEM_SET_CURRENT_PAGE_NAME',
  SYSTEM_VERSION_MISMATCH_MODE_ON: 'SYSTEM_VERSION_MISMATCH_MODE_ON',
  SYSTEM_PREVIEW_MODE_ON:       'SYSTEM_PREVIEW_MODE_ON',
  SYSTEM_MAINTENANCE_MODE: 'SYSTEM_MAINTENANCE_MODE',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  setServerDatetime: function (datetime) {
    return {type: actionTypes.SYSTEM_SET_SERVER_DATETIME, datetime};
  },
  setServerVersion: function (version) {
    return {type: actionTypes.SYSTEM_SET_SERVER_VERSION, version};
  },
  setClientVersion: function (version) {
    return {type: actionTypes.SYSTEM_SET_CLIENT_VERSION, version};
  },
  clearAllSystemData: function () {
    return {type: actionTypes.SYSTEM_CLEAR_ALL};
  },
  setFeatureFlags: function (payload) {
    return {type: actionTypes.SYSTEM_SET_FEATURE_FLAGS, payload};
  },
  setLoading: function (loading) {
    return {type: actionTypes.SYSTEM_LOADING_MODE, payload: loading};
  },
  setCurrentPageName: function (payload) {
    return {type: actionTypes.SYSTEM_SET_CURRENT_PAGE_NAME, payload: payload};
  },
  setVersionMismatchModeOn: function () {
    return {type: actionTypes.SYSTEM_VERSION_MISMATCH_MODE_ON};
  },
  setPreviewModeOn: function () {
    return {type: actionTypes.SYSTEM_PREVIEW_MODE_ON};
  },
  setMaintenanceMode: function (payload) {
    return {type: actionTypes.SYSTEM_MAINTENANCE_MODE, payload: payload};
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.setFeatureFlags = function () {
  return function (dispatch, getState) {
    let featureFlags = {ALL: true, MASS_OPERATION: false, ORGANIZATIONS: false};
    let loggedInUser = getState().login.get('loggedInUser');

    if (loggedInUser === undefined || (Object.keys(loggedInUser).length === 0 && loggedInUser.constructor === Object)) {
      return;
    }

    if(PermissionManager.hasPlataineOrgFeatureFlagsPermissions()) {
      featureFlags.ALL = loggedInUser.loggedInOrg.name.toLowerCase() === 'plataine' ? false : true;
      featureFlags.ORGANIZATIONS = loggedInUser.loggedInOrg.name.toLowerCase() === 'plataine' ? true : false;
    }

    if (PermissionManager.hasHookUserPermissions()) {
      featureFlags.ALL = false;
      featureFlags.MASS_OPERATION = true;
    }

    dispatch(actions.setFeatureFlags(featureFlags));
  };
};

jsxActions.onMaintenanceCheck = function () {
  return function (dispatch, getState) {
    api.onMaintenanceCheck()(dispatch, getState);
  }
};

jsxActions.getBuildStatus = function () {
  return function (dispatch, getState) {
    api.getBuildStatus()(dispatch, getState);
  }
};

jsxActions.onRefreshAppByMaintenance = function () {
  return function (dispatch, getState) {
    api.onRefreshAppByMaintenance()(dispatch, getState);
  }
};

jsxActions.setPreviewModeOn = function () {
  return function (dispatch, getState) {
    dispatch(actions.setPreviewModeOn());
  }
};

jsxActions.initServerDatetime = function () {
  return function (dispatch, getState) {
    api.initServerDatetime()(dispatch, getState);
  }
};


/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.clearAllData = function () {
  return function (dispatch, getState) {
    dispatch(actions.clearAllSystemData());
  }
};

api.initServerDatetime = function () {
  return function (dispatch, getState) {
      ServerDateTimeHandler.init(dispatch, getState);
  }
};

api.setVersionMismatchModeOn = function () {
  return function (dispatch, getState) {
    dispatch(actions.setVersionMismatchModeOn());
  }
};

api.setServerDatetime = function (datetime) {
  return function (dispatch, getState) {
    dispatch(actions.setServerDatetime(datetime));
  }
};
api.getBuildStatus = function () {
  return function (dispatch, getState) {
    return getState().system.getIn(['indicatorBarData', 'maintenanceMode']);
  }
};


api.setServerVersion = function () {
  return function (dispatch, getState) {
    return systemService.getServerVersion().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('can\'t log server version');
        return;
      }
      dispatch(actions.setServerVersion(response.data.version));
      return response.data.version;
    });
  }
};

api.setClientVersion = function () {
  return function (dispatch, getState) {
    return systemService.getClientVersion()
                        .then(response =>  response.text())
                        .then(text =>  {
                           let parser = new IniParser(); // -> https://www.npmjs.com/package/config-ini-parser
                           parser.parse(text);
                           let version = parser.get(null, 'service.version');
                           dispatch(actions.setClientVersion(version));
                           return version;
                          }
                        )
  }
};

api.getClientVersion = function () {
  return function (dispatch, getState) {
    return myState(getState).get('clientVersion');
  }
};



api.setLoading = function (isLoading) {
  return function (dispatch, getState) {
    dispatch(actions.setLoading(isLoading));
  }
};



api.pageManager = function (dispatch, getState) {
  return {

    setCurrentPageName: function (pageName) {
      dispatch(actions.setCurrentPageName(pageName));
    },
    getCurrentPageName: function (dispatch, getState) {
      return myState(getState).get('currentPageName');
    },

    /////////////////////////////////////////////////////
    // LOCATION_PAGE - locationPage.js
    isOn_LOCATION_PAGE: function () {
      return (this.getCurrentPageName(dispatch, getState)() === navigationStates.LOCATION);
    }


  };
};

api.onRefreshAppByMaintenance = function () {
  return function (dispatch, getState) {
    if(inBuildingScheduler){
      inBuildingScheduler.kill();
    }
    if(inProgressScheduler){
      inProgressScheduler.kill();
    }
    dispatch(actions.setMaintenanceMode({mode: null}));
    window.location.reload()
  }
};

api.onMaintenanceCheck = function () {
  return function (dispatch, getState) {
    if(__DEV__){
      return;
    }
    if (inBuildingScheduler) {
      return;
    }
    //returns buildNumber, buildStatus, isBuilding
    systemService.getApplicationStatus((statusResponse) => {
      if (!statusResponse) {
        console.error('fetch status failed');
        return;
      }

      if (_isBuildRunning(statusResponse.status) ){
        inBuildingScheduler = ScheduleAction('build start Scheduler', () => _handleBuildStart(dispatch, getState), 2);
        return;
      }

      if(statusResponse.isBuilding){
        inBuildingScheduler = ScheduleAction('building Scheduler', () => _handleWhileBuilding(dispatch, getState), 2);
      }
    });
  }
};

function _handleWhileBuilding(dispatch, getState) {
  systemService.getServerHealthCheck((response) => {
    if(inProgressScheduler){
      if(inBuildingScheduler){
        inBuildingScheduler.kill();
      }
      return
    }
    if (response === 'OK') {
      dispatch(actions.setMaintenanceMode({mode: 'prepareForUpgradeMode'}));
    }
    else {
      dispatch(actions.setMaintenanceMode({mode: 'upgradeInProgressMode'}));

      if (!inProgressScheduler) {
        inProgressScheduler = ScheduleAction('In Progress Scheduler', () => _onInProgressCheck(dispatch, getState), 2);
      }
    }
  });
}

function _onInProgressCheck(dispatch, getState) {
  systemService.getApplicationStatus((statusResponse) => {
    if (!statusResponse) {
      console.error('fetch status failed');
      return;
    }
    if(statusResponse.isBuilding){
      //keep the in progress
      return dispatch(actions.setMaintenanceMode({mode: 'upgradeInProgressMode'}));
    }
    if (getState().system.getIn(['indicatorBarData', 'maintenanceMode']) === 'upgradeInProgressMode') {
      return dispatch(actions.setMaintenanceMode({mode: 'upgradeCompletedMode'}));
    }
  });
}

function _handleBuildStart(dispatch, getState) {

  systemService.getApplicationStatus((statusResponse) => {
    if (!statusResponse) {
      console.error('fetch status failed');
      return;
    }

    if (statusResponse.status === 'prepare_to_build') {
      dispatch(actions.setMaintenanceMode({mode: 'prepareForUpgradeMode'}));
    }
    else if (statusResponse.status === 'build') {
      dispatch(actions.setMaintenanceMode({mode: 'upgradeInProgressMode'}));
    }
    else if (statusResponse.status === 'no_build') {
      let mode = getState().system.getIn(['indicatorBarData', 'maintenanceMode']);
      if (mode === 'prepareForUpgradeMode' || mode === 'upgradeInProgressMode' ) {
        if(inBuildingScheduler){
          inBuildingScheduler.kill();
        }
        if (!inProgressScheduler) {
          inProgressScheduler = ScheduleAction('build complete Scheduler', () => _handleBuildComplete(dispatch, getState), 5);
        }
      }
    }
  });
}

function _handleBuildComplete(dispatch, getState) {
  systemService.getServerHealthCheck((response) => {
    if (response === 'OK') {
      let mode = getState().system.getIn(['indicatorBarData', 'maintenanceMode']);
      if (mode === 'prepareForUpgradeMode' || mode === 'upgradeInProgressMode') {
        dispatch(actions.setMaintenanceMode({mode: 'upgradeCompletedMode'}));
      }
    }
  });
}

/////////////////////////////////////////////
function _isBuildRunning(status) {
  return _isBuildStatusSupported() && _inBuildStatus(status);
}
function _isBuildStatusSupported() {
  return (location.href.includes('iot-staging') || location.href.includes('iot.plataine') );
}

function _inBuildStatus(status) {
  return (status === 'prepare_to_build' || status === 'build');
}
