import * as daHelper               from '../../Dialogs/dialogActionsHelper';
import {dialogsNames}              from '../../../enums/dialogsNames';
import  * as shipmentsService      from '../../../services/Shipments/shipmentsService';
import * as locationsService from '../../../services/Locations/locationsService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';

const config = {
  getSubmitMethod: () => shipmentsService.shipToCustomer,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.SHIP_TO_CUSTOMER_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.SHIP_TO_CUSTOMER_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function() {
      return locationsService.fetchLocationsByLocationType('OUTGOING_SHIPMENTS').then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Outgoing shipments locations failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return { success: false };
        }

        let dialogData = {
          locations: response.dataList?.map((obj) => { return { value: obj.id, label: obj.businessId, data: obj }; }) || []
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return { success: true };
      });
    };
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit(data, messageDialogBuilder, reloadParentComponent) {
    return function (dispatch, getState) {
      return api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
    };
  },
};




