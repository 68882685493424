import Network from 'infrastructure/js/modules/network';

export function fetchtAlertRules(query, alertSettingId) {
    return Network.post(`asset-alert-setting/${alertSettingId}/misplaced-alert-rules/items`, query);
}

export function createAlertRule(query, alertSettingId) {
    return Network.post(`asset-alert-setting/${alertSettingId}/misplaced-alert-rules`, query);
}

export function updateAlertRule({ruleId, ...query}, alertSettingId) {
    return Network.put(`asset-alert-setting/${alertSettingId}/misplaced-alert-rules/${ruleId}`, query);
}

export function deleteAlertRules(query, alertSettingId) {
    return Network.delete(`asset-alert-setting/${alertSettingId}/misplaced-alert-rules`, query);
}
