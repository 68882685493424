// @ts-check

import useVenue from "./Mappedin/useVenue";
import useMapView from "./Mappedin/useMapView";
import useSwitchMapToDefaultMode from "./useSwitchMapToDefaultMode";
import useHandleMapClick from "./useHandleMapClick";
import useHandleCameraChanged from "./useHandleCameraChanged";
import { useSubscribeTomapReady } from "./Mappedin/useSubscribeTomapReady";



/**
 * @typedef {import("@mappedin/mappedin-js").TShowVenueOptions} TShowVenueOptions
 * @typedef {import("@mappedin/mappedin-js").TGetVenueOptions} TGetVenueOptions
 * @typedef {import("@mappedin/mappedin-js").TMapViewOptions} TMapViewOptions
 * 
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageProps} LiveMapPageProps
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").Pl_Map} Pl_Map
 * 
 */



/////////////////////////////////////////////
// Demo options:
//

// /** 
//  * @type {TGetVenueOptions}
//  */
// const options = {

//   // See Trial API key Terms and Conditions
//   // https://developer.mappedin.com/guides/api-keys

//   // venue: "mappedin-demo-mall",
//   venue: "warehouse-demo",
//   clientId: "5eab30aa91b055001a68e996",
//   clientSecret: "RJyRXKcryCMy4erZqqCbuB1NbR66QTGNXVE0x3Pg6oCIlUR1",


// };



// * @returns {[Mappedin | undefined, MapView | undefined]}

/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * @param {LiveMapPageProps} props
 * @param {TMapViewOptions} mapViewOptions
 * 
 */
export default function useInitializeMap(refContext, props, mapViewOptions) {

  let sActiveMapSetting = props.sData.activeMapSetting;

  const venue = useVenue(sActiveMapSetting);
  const mapView = useMapView(refContext, venue, mapViewOptions);

  
  //////////////////////////////////////////////
  // Switches the map to normal mode.
  // 
  // Runs once per mapView initialization.
  //
  useSwitchMapToDefaultMode(refContext);
  
  
  ////////////////////////////////////////////////
  // Add a memoized click handler to the map
  // that will auto update if mapView changes.
  // 
  useHandleMapClick(refContext);
  
  ////////////////////////////////////////////////
  // Add a memoized camera/zoom handler to the map
  // that will auto update if mapView changes.
  // 
  useHandleCameraChanged(refContext);
  
  
  ////////////////////////////////////////////////
  // Add a memoized camera/zoom handler to the map
  // that will auto update if mapView changes.
  // 
  useSubscribeTomapReady(refContext.current.refMapView.current, refContext);
  
  


  return [
    mapView
  ];
}





