import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../actions/AppTopbar/lastTriggersActions';

let defaultState = {
  loading: false,
  triggersData: null,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.LAST_TRIGGERS_PANEL_SET_BUSY:
      return state.set('loading', action.payload);

    case actionTypes.LAST_TRIGGERS_PANEL_FETCH_DATA_FINISHED:
      return state.set('triggersData', action.payload);

    case actionTypes.LAST_TRIGGERS_PANEL_UNMOUNT:
      return Map(defaultState);

    default:
      return state;
  }
}
