import React, {Component} from 'react';

import cn from 'classnames';
import {navigationStates} from '../../../../enums/navigationStates';
import Label from 'infrastructure/js/components/Label/label';
import EllipsisWithTooltip from 'infrastructure/js/components/EllipsisWithTooltip/ellipsisWithTooltip.js';
import PropTypes from 'prop-types'

//Style
require('./link.scss');

export default class Link extends React.PureComponent {
  render() {
    let {id, label, type, className} = this.props;

    if (label) {
      let path = type ? getPathByType(type, id) : '';

      if (path && id) {
        return (
          <EllipsisWithTooltip className={cn('pl-link', className)} tooltip={label}>
            <a  href={`#${path}`}>{label}</a>
          </EllipsisWithTooltip>);
      }
      return <Label text={label} className={className}/>
    }

    return null;
  }
}

export function convertPlaceholderToLinkComponent  (text , data) {
  if (!data) {
    return text;
  }
  return (<label onClick={data.action}>
    { text.split(data.placeholder)
      .reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }
        return prev.concat(<Link key={data.data.name + data.data.id} label={data.data.name} id={data.data.id} type={data.data.type} className={data.data.className}/>, current);
      }, [])
    }
  </label>);
}


export function getPathByType(type, id) {
  switch (type) {
    case navigationStates.ROLL:
      return `/Asset/roll/${id}`;
    case navigationStates.SPOOL:
      return `/Asset/spool/${id}`;
    case navigationStates.RESIN:
      return `/Asset/resin/${id}`;
    case navigationStates.KIT:
      return `/Asset/kit/${id}`;
    case navigationStates.PART:
      return `/Asset/part/${id}`;
    case navigationStates.TOOL:
      return `/Asset/tool/${id}`;
    case navigationStates.GROUP:
      return `/Asset/group/${id}`;
    case navigationStates.WORKORDER:
      return `/WorkOrder/${id}`;
    case navigationStates.LOT:
      return `/lot/${id}`;

    case navigationStates.LOCATION:
    case navigationStates.STATION:
      return `/Location/${id}`;

    case navigationStates.LOCATION_TASKS:
      return `/Location/${id}/tasks`;
    case navigationStates.SETTINGS_CSV_WORKORDERS:
      return '/Administration/settings/csv/workOrder';

    case navigationStates.ACTIVE_SPOOLS:
      return '/PredefinedViews/activeSpools';
    case navigationStates.ALERTED_SPOOLS:
      return '/PredefinedViews/alertedSpools';
    case navigationStates.ARCHIVED_SPOOLS:
      return '/PredefinedViews/archivedSpools';

    case navigationStates.ACTIVE_PARTS:
      return '/PredefinedViews/activeParts';
    case navigationStates.ARCHIVED_PARTS:
      return '/PredefinedViews/archivedParts';

    case navigationStates.ALERTED_ROLLS:
      return '/PredefinedViews/alertedRolls';
    case navigationStates.ALERTED_KITS:
      return '/PredefinedViews/alertedKits';
    case navigationStates.ALERTED_GROUPS:
      return '/PredefinedViews/alertedGroups';
    case navigationStates.ARCHIVED_ROLLS:
      return '/PredefinedViews/archivedRolls';
    case navigationStates.ARCHIVED_KITS:
      return '/PredefinedViews/archivedKits';
    case navigationStates.ARCHIVED_GROUPS:
      return '/PredefinedViews/archivedGroups';
    case navigationStates.OPEN_WORKORDERS:
      return '/PredefinedViews/openWorkOrders';
    case navigationStates.COMPLETED_WORKORDERS:
      return '/PredefinedViews/completedWorkOrders';
    case navigationStates.ACTIVE_TOOLS:
      return '/PredefinedViews/activeTools';
    case navigationStates.ARCHIVED_TOOLS:
      return '/PredefinedViews/archivedTools';
    case navigationStates.ACTIVE_ROLLS:
      return '/PredefinedViews/activeRolls';
    case navigationStates.ACTIVE_KITS:
      return '/PredefinedViews/activeKits';
    case navigationStates.ACTIVE_GROUPS:
      return '/PredefinedViews/activeGroups';
    case navigationStates.ON_HAND_INVENTORY:
      return '/PredefinedViews/onHandInventory';
    case navigationStates.MATERIAL_SCRAP_VIEW:
      return '/PredefinedViews/materialScrap';
    case navigationStates.MANAGER_REPORT:
      return '/ManagerView';
    case navigationStates.PRODUCTION_DASHBOARD:
      return '/ProductionDashboard';
    case navigationStates.PROGRESS_DASHBOARD:
      return '/ProgressDashboard';
    case navigationStates.INVENTORY_DASHBOARD:
      return '/InventoryDashboard';
    case navigationStates.TOOL_MAINTENANCE_DASHBOARD:
      return '/ToolMaintenanceDashboard';
    case navigationStates.NOTIFICATIONS:
      return '/Notifications';

    case navigationStates.MAT_SETTINGS:
      return '/Administration/matSettings';
    case navigationStates.MASS_OPERATIONS:
      return '/Administration/massOperations';
    case navigationStates.USER_MANAGEMENT:
      return '/Administration/userManagementPage';
    case navigationStates.USER_SETTINGS:
      return '/Administration/userSettings';
    case navigationStates.ORG_MANAGEMENT:
      return '/Administration/organizationsPage';
    case navigationStates.RFID_SETTINGS:
      return '/Administration/rfidSettings';
    case navigationStates.BILLING:
      return '/Administration/billing';
    case navigationStates.SANDBOX:
      return '/Administration/sandbox';
    case navigationStates.SETTINGS:
      return '/Administration/settings';
    case navigationStates.PS_MANAGEMENT:
      return '/Administration/psManagement';
    case navigationStates.SMART_SELECTION:
      return '/PredefinedViews/smartSelection';
    case navigationStates.PICK_LISTS:
      return '/PredefinedViews/pickLists';
    case navigationStates.PICK_LIST:
      return `/PickList/${id}`;

      //Merged Pages:
    case navigationStates.KITS:
      return '/PredefinedViews/kits';
    case navigationStates.GROUPS:
      return '/PredefinedViews/groups';
    case navigationStates.WORKORDERS:
        return '/PredefinedViews/WorkOrders';
    case navigationStates.RAW_MATERIALS:
        return '/PredefinedViews/rawMaterials';
    case navigationStates.PARTS:
        return '/PredefinedViews/parts';
    case navigationStates.TOOLS:
        return '/PredefinedViews/tools';

    default:
      console.error('getPathByType(): unknown type ' + type);
      return null;
  }
}

Link.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.number,
  className: PropTypes.string,
};

Link.defaultProps = {
  label: '',
  className: '',
};

