import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import {jsxActions as operationsViewActions} from '../../../actions/Administration/MatSettingsPage/operationsViewActions';
import { jsxActions as operationsViewGridActions} from '../../../actions/Administration/MatSettingsPage/operationsViewGridActions';
import { jsxActions as createOperationDialogActions} from '../../../actions/Administration/MatSettingsPage/Dialogs/createOperationDialogActions';
import { jsxActions as matSettingsPageActions} from '../../../actions/Administration/MatSettingsPage/matSettingsPageActions';
import { jsxActions as headerSettingsActions} from '../../../actions/Header/headerSettingsActions';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions.js';
import { gridsNames} from '../../../enums/gridsNames';
import OperationsView from '../../../components/Administration/MatSettingsPage/OperationsView/operationsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(operationsViewActions, dispatch),
      header: bindActionCreators(headerSettingsActions, dispatch),
      fetchEntitiesActions:    bindActionCreators(fetchEntitiesActions, dispatch),
      operationsViewGridActions: bindActionCreators(operationsViewGridActions, dispatch),
      createOperationDialogActions : bindActionCreators(createOperationDialogActions, dispatch),
      matSettingsPageActions : bindActionCreators(matSettingsPageActions, dispatch)
    }
  }
}

let OperationsViewContainer = connect(
  (state) => {
    let grid = state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_OPERATIONS) ? state.grid.get(gridsNames.ADMINISTRATION_MAT_SETTINGS_OPERATIONS) : null;
    return {
      sGrid:  grid,
      sHasRows: grid && grid.get('rows') ? grid.get('rows').size > 0 : false,
      sSelectedRowsData: grid ? grid.get('selectedRowsData') : List(),
      sCreateOperationDialogData: state.administration.getIn(['matSettingsPage', 'createOperationDialog'])
    };
  },

  mapDispatchToProps
)(OperationsView);

export default OperationsViewContainer;



