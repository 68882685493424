import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
import cn from 'classnames';
import Button from '../controls/Button/button.js';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import * as dialogHelper from './dialogHelper';

import './dialog.scss';

//unmounts dialog immediately on close
export class PL_DialogWrapper extends React.PureComponent {
  render() {
    if (this.props.show) {
      return <this.props.dialogComponent {...this.props} />;
    }
    return null;
  }
}

//dialog wrapper but waits until the modal exits to unmount it
export class PL_DialogWrapperWithExit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.show && !this.props.show)
      this.setState({ show: true });
  }

  onExited = () => {
    this.setState({ show: false });
    this.props.onExited?.(); //call original onExited()
  };

  render() {
    let { dialogComponent, ...otherProps } = this.props;
    if (this.state.show) {
      return <this.props.dialogComponent {...otherProps} onExited={this.onExited}/>;
    }
    else {
      return null;
    }
  }
}

PL_DialogWrapperWithExit.propTypes = {
  dialogComponent: PropTypes.elementType
};

export default class PL_Dialog extends React.PureComponent {

  componentWillUnmount() {
    if (this.props.handleHashChange) {
      window.removeEventListener("hashchange", this.handleHashChange);
      if (this.props.onHide) {
        this.props.onHide(true);
      }
    }

    if (this.props.onEnterKeyPress) {
      document.removeEventListener("keypress", this.onKeyPress);
    }
  }

  getValidationErrors = (errors, i) => {

    if (!errors) {
      return null;
    }
    let messages = errors.messages.map((m, index) => {
      return <div key={index}>
        <span className={`icon pl ${m.icon}`}/>
        <span className={"error-message"}>{m.message}</span>
      </div>;
    });
    return (
      <div key={i}>
        <div className="validation-area-title">
          <span>{errors.title}</span>
        </div>
        <div className="validation-area-content">
          {messages}
        </div>
      </div>
    );
  };

  // getValidationArea = () => {
  //   if (this.props.validationArea) {
  //     let validations = this.props.validationArea.map((item, index) => {return this.getValidationErrors(item, index)})
  //     return (
  //       <div className="validation-area">
  //         {validations}
  //       </div>
  //     )
  //   }
  //   return null;
  // };
  getValidationArea = () => {
    let validationArea = this.props.sData ? dialogHelper.getValidationArea(this.props.sData.get("validationMessage")) : null;

    if (validationArea) {
      let validations = validationArea.map((item, index) => {return this.getValidationErrors(item, index);});
      return (
        <div className="validation-area">
          {validations}
        </div>
      );
    }
    return null;
  };

  //Check if footer relevant, if no footer props sent, don't show footer component/text.
  getFooter() {
    let {
      footerValidationCheckbox,
      footerInformationIcon,
      footerButtons,
      footerCenterText,
      footerCheckbox,
      footerDialogMessage,
      sData
    } = this.props;
    let disableClick = sData && sData.get("loading");
    if (footerButtons) {
      return <Modal.Footer>
        <DialogFooter footerButtons={footerButtons}
                      footerCenterText={footerCenterText}
                      footerCheckbox={footerCheckbox}
                      footerDialogMessage={footerDialogMessage}
                      footerValidationCheckbox={footerValidationCheckbox}
                      footerInformationIcon={footerInformationIcon}
                      disableClick={disableClick}/>
      </Modal.Footer>;
    }

    return "";
  }

  getTitle() {
    let { titleComponent, moreInfoTextHeader, titleText, additionalTextHeader } = this.props;
    if (titleComponent) {
      return titleComponent;
    }
    else {
      return <DialogTitle titleText={titleText} moreInfoTextHeader={moreInfoTextHeader}
                          additionalTextHeader={additionalTextHeader}/>;
    }
  }

  handleHashChange = (e) => {
    window.removeEventListener("hashchange", this.handleHashChange);
    if (this.props.onHide) {
      this.props.onHide(true);
    }
    else {
      console.error("NO onHide() found " + this.props.id);
    }
  };

  onEntered = () => {
    if (this.props.handleHashChange) {
      window.addEventListener("hashchange", this.handleHashChange);
    }

    if (this.props.onEnterKeyPress) {
      document.addEventListener("keypress", this.onKeyPress);
    }

    if (this.props.onEntered) {
      this.props.onEntered();
    }
  };

  onExited = () => {
    if (this.props.handleHashChange) {
      window.removeEventListener("hashchange", this.handleHashChange);
    }

    if (this.props.onEnterKeyPress) {
      document.removeEventListener("keypress", this.onKeyPress);
    }

    if (this.props.onExited) {
      this.props.onExited();
    }
  };

  onExit = () => {
    if (this.props.onExit) {
      this.props.onExit();
    }
  };

  onHide = () => {
    if (this.props.sData && this.props.sData.get("loading")) {
      return;
    }

    if (this.props.onHide) {
      this.props.onHide(false);
    }
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      if (!e.target.id.includes('react-select-')) {  //temp workaround for SAAS-17253: prevent submit on Enter from multi-select
        this.props.onEnterKeyPress?.();
      }
    }
  };

  render() {
    let { id, children, className, bodyClassName, show, animation, sData, style, enforceFocus } = this.props;
    animation = (animation === undefined) ? true : animation;

    return (
      <Modal style={style}
             animation={animation}
             id={id}
             className={cn("dialog", className)}
             show={show}
             onHide={this.onHide}
             onEntered={this.onEntered}
             onExited={this.onExited}
             onExit={this.onExit}
             backdrop="static"
             enforceFocus={enforceFocus}
      >
        <Modal.Header>
          <Modal.Title>
            {this.getTitle()}
          </Modal.Title>
          <span className='pl pl-x-input-close close' onClick={this.onHide}/>
        </Modal.Header>

        <Modal.Body className={cn(bodyClassName)}>
          {children}
          <div className={cn("modal-dialog-overlay", { "active": (sData && sData.get("loading")) })}/>
        </Modal.Body>
        {this.getValidationArea()}
        {this.getFooter()}
      </Modal>
    );
  }
}

PL_Dialog.defaultProps = {
  footerDialogMessage: null,
  handleHashChange: true
};

PL_Dialog.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired,
  animation: PropTypes.bool,
  titleText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  titleComponent: PropTypes.object,
  moreInfoTextHeader: PropTypes.string,
  footerButtons: PropTypes.object,
  footerCheckbox: PropTypes.object,
  footerDialogMessage: PropTypes.object,
  footerCenterText: PropTypes.any,
  onHide: PropTypes.func, //(isUnmounting: bool) => void;
  onEntered: PropTypes.func,
  onExited: PropTypes.func,
  disableReset: PropTypes.bool,
  isLocationBound: PropTypes.bool,
  handleHashChange: PropTypes.bool,
  footerInformationIcon: PropTypes.shape({ type: PropTypes.string, tooltip: PropTypes.string })
};


export class DialogTitle extends React.PureComponent {

  createAdditionalTextHeader() {
    if (this.props.additionalTextHeader) {
      return (<label className="additional-text-header">{this.props.additionalTextHeader}</label>);
    }
    return "";
  }

  createMoreInfoTextHeader() {
    if (this.props.moreInfoTextHeader) {
      return <label className="more-info-text">{this.props.moreInfoTextHeader}</label>;
    }
    return "";
  }

  render() {
    return (
      <div>
        <label className="title">{this.props.titleText}{this.createMoreInfoTextHeader()}</label>
        {this.createAdditionalTextHeader()}
      </div>
    );
  }
}


export class DialogFooter extends React.PureComponent {

  onClick = (buttonObj) => {
    if (this.props.disableClick && !buttonObj.enabledOnLoading) {
      return;
    }
    buttonObj.action();
  };


  onChange = () => {
    return (function(value) {
      this.props.footerCheckbox.action(value.target.checked);
    }).bind(this);
  };


  createFooterButtons(buttonsArr) {
    let arr = [];

    if (buttonsArr) {
      buttonsArr.map((buttonObj) => {
        arr.push(<Button id={buttonObj.id}
                         key={"id_" + buttonObj.text} variant={buttonObj.bsStyle || 'secondary'}
                         onClick={() => this.onClick(buttonObj)}
                         loading={buttonObj.loading} style={buttonObj.style} disabled={buttonObj.disabled}
                         className={cn('label-only', buttonObj.className)}
        >{buttonObj.text}</Button>);
      });
    }

    return arr;
  }

  createFooterCenterText() {
    let text = this.props.footerCenterText;
    let disabledClassName = this.props.disableClick ? "disabled" : "";
    if (text) {
      return <div className={`inline-block floating-center-text ${disabledClassName}`}>
        <label>
          {text}
        </label>
      </div>;
    }
    return "";
  }

  getIconPopOver = (data) => {
    if (data) {
      return (<AlertPopover popoverType={data.severity}
                            popoverComponent={<div>{data.message}</div>}
      />);
    }
    return null;
  };

  getFooterCheckbox = () => {
    let checkboxObj = this.props.footerCheckbox;

    if (checkboxObj) {
      return (
        <div className="checkbox-right-section">
          {this.getIconPopOver(checkboxObj.icon)}
          <label htmlFor="checkboxRight">
            <Checkbox name="checkboxRight" id="checkboxRight"
                      disabled={checkboxObj.disabled}
                      onChange={this.onChange()}/>
            <span>{checkboxObj.text}</span>
          </label>
        </div>
      );
    }
    else {
      return null;
    }
  };

  getFooterDialogMessage = () => {
    let data = this.props.footerDialogMessage;

    return data ? this.getIconPopOver(data) : null;
  };

  createFooterValidationCheckBox = () => {
    let checkboxObj = this.props.footerValidationCheckbox;
    if (checkboxObj) {
      return (
        <div className="footer-validation-checkbox">
          <Checkbox name="footerValidationCheckbox" id="validationCheckbox"
                    label={checkboxObj.label}
                    onChangeCallback={checkboxObj.onChange}
                    disabled={this.props.disableClick}
          />
        </div>
      );
    }
    return null;
  };

  createFooterInformationIcon = () => {
    let iconObj = this.props.footerInformationIcon;
    if (iconObj) {
      return (
        <Tooltip placement="top" value={iconObj.tooltip}>
          <div className={`footer-validation-checkbox pl pl-${iconObj.type}`}/>
        </Tooltip>
      );
    }
    return null;
  };

  render() {
    let { left, right } = this.props.footerButtons;

    return (
      <div className='model-footer-content'>
        <div className="left-section">{this.createFooterButtons(left)}</div>
        <div className="right-section">
          {this.createFooterValidationCheckBox()}
          {this.createFooterCenterText()}
          {this.getFooterCheckbox()}
          {this.createFooterInformationIcon()}
          {this.getFooterDialogMessage()}
          {this.createFooterButtons(right)}
        </div>

      </div>
    );
  }
}
