// @ts-check

import PermissionManager from '../../../../../infrastructure/js/utils/permissionManager';


/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").Pl_Map} Pl_Map
 * 
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LayerSettings} PolySetting
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LayerSettingRecord} PolySettingRecord
 * 
 * 
 */



export default class MapTimers {

  // /** @type {NodeJS.Timeout | null} */
  /** @type {NodeJS.Timer | null} */
  static fullStatusFetchTimer = null;



  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static map_OnReady = (refContext) => {
    // console.log('MapTimers ===> creating timers',);

    this.createTimer_FetchFullStatus(refContext);


  }

  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static map_OnDestroy = (refContext) => {
    // console.log('MapTimers ===> killing all timers');

    this.fullStatusFetchTimer && clearInterval(this.fullStatusFetchTimer);


  }




  ////////////////////////////////////////////////////////////
  // Timers
  //

  /** 
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
   */
  static createTimer_FetchFullStatus = (refContext) => {
    // console.log('MapTimers ===> starting FetchFullStatus timer',);


    /////////////////////////////////////////////////////////////////////////////
    // The map refresh is currently tied to "Manager View" refresh setting.
    // So when that is set to 0, we don't refresh the map.
    // That setting is in seconds, and cannot be less than 60.
    // 
    let refreshRateSetting = PermissionManager.getOrgPreferences().refreshRate;
    if (refreshRateSetting === 0) {
      return;
    }
    
    let INTERVAL_MSEC = refreshRateSetting * 1000;
    // let INTERVAL_MSEC = 10_000;
    
    // Fetch once immediately.
    refContext.current.actions.fetchFullStatus();
    
    // Fetch every INTERVAL_MSEC.
    this.fullStatusFetchTimer = setInterval(() => {
      // console.log('FULLSTATUS LOAD ===> ');
      refContext.current.actions.fetchFullStatus();
      // console.log(' XXXXXXXXXXXXXXXXXX MapTimers ===> fullStatusFetchTimer', (new Date()).getTime(), refContext.current.refSData.current.activeMapSetting.venue);
    }, INTERVAL_MSEC);


  }



}






