import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

import'./orgsDashboardViewGrid.scss';

class OrgsDashboardViewGrid extends React.PureComponent {
  filterConfig = [
    { fieldName: 'orgName', filterName: 'orgBusinessId', getOptions: false },
    { fieldName: 'activitiesTotal.count', filterName: 'activitiesTotal', getOptions: false },
    { fieldName: 'activitiesBusinessDayAverage.count', filterName: 'activitiesBusinessDayAverage', getOptions: false },
    { fieldName: 'activitiesLastBusinessDay.count', filterName: 'activitiesLastBusinessDay', getOptions: false },
    { fieldName: 'assetsActive.count', filterName: 'assetsActive', getOptions: false },
    { fieldName: 'assetsTotal.count', filterName: 'assetsTotal', getOptions: false },
    { fieldName: 'rfidErrorsLastBusinessDay.count', filterName: 'rfidErrorsLastBusinessDay', getOptions: false },
    { fieldName: 'loginsBusinessDayAverage.count', filterName: 'loginsBusinessDayAverage', getOptions: false },
    { fieldName: 'loginsForPeriod.count', filterName: 'loginsForPeriod', getOptions: false },
    { fieldName: 'jwtLoginsForPeriod.count', filterName: 'jwtLoginsForPeriod', getOptions: false },
    { fieldName: 'schedulerExecutionsForPeriod.count', filterName: 'schedulerExecutionsForPeriod', getOptions: false },
    { fieldName: 'activitiesForPeriod.count', filterName: 'activitiesForPeriod', getOptions: false },
    { fieldName: 'workOrdersTotal.count', filterName: 'workOrdersTotal', getOptions: false },
    { fieldName: 'workOrdersForPeriod.count', filterName: 'workOrdersForPeriod', getOptions: false },
    { fieldName: 'assetsForPeriod.count', filterName: 'assetsForPeriod', getOptions: false },
    { fieldName: 'deviceAppRunningVersion', filterName: 'deviceAppRunningVersion', getOptions: false },
  ];

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.organizations.orgsDashboard.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'orgName',
        title: this.labels.get('columns.orgName.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'orgBusinessId',
        filterAlignment: filterAlignmentTypes.LEFT,
        fetchConfig: {
          entityType: EntityPropertyTypes.ORGS,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          sort: 'asc',
        },
      },
      {
        fieldName: 'activitiesTotal.count',
        title: this.labels.get('columns.activitiesTotal.title'),
        filterType: filterTypes.NONE,
        filterName: 'activitiesTotal',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.activitiesTotal.tooltip'),
          },
        },
      },
      {
        fieldName: 'activitiesBusinessDayAverage.count',
        title: this.labels.get('columns.dailyAvgActivities.title'),
        filterType: filterTypes.NONE,
        filterName: 'activitiesBusinessDayAverage',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.dailyAvgActivities.tooltip'),
          },
        },
      },
      {
        fieldName: 'activitiesLastBusinessDay.count',
        title: this.labels.get('columns.lbdActivities.title'),
        filterType: filterTypes.NONE,
        filterName: 'activitiesLastBusinessDay',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.lbdActivities.tooltip'),
          },
        },
      },
      {
        fieldName: 'assetsActive.count',
        title: this.labels.get('columns.assetsActive.title'),
        filterType: filterTypes.NONE,
        filterName: 'assetsActive',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.assetsActive.tooltip'),
          },
        },
      },
      {
        fieldName: 'assetsTotal.count',
        title: this.labels.get('columns.assetsTotal.title'),
        filterType: filterTypes.NONE,
        filterName: 'assetsTotal',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.assetsTotal.tooltip'),
          },
        },
      },
      {
        fieldName: 'rfidErrorsLastBusinessDay.count',
        title: this.labels.get('columns.lbdRfidErrors.title'),
        filterType: filterTypes.NONE,
        filterName: 'rfidErrorsLastBusinessDay',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.lbdRfidErrors.tooltip'),
          },
        },
      },
      {
        fieldName: 'loginsBusinessDayAverage.count',
        title: this.labels.get('columns.avgDailyLogins.title'),
        filterType: filterTypes.NONE,
        filterName: 'loginsBusinessDayAverage',
        columnOptions: {
          headerComponentParams: {
            headerTooltip: this.labels.get('columns.avgDailyLogins.tooltip'),
          },
        },
      },
      {
        fieldName: 'loginsForPeriod.count',
        title: this.labels.get('columns.loginsForPeriod.title'),
        filterType: filterTypes.NONE,
        filterName: 'loginsForPeriod',
      },
      {
        fieldName: 'jwtLoginsForPeriod.count',
        title: this.labels.get('columns.jwtLoginsForPeriod.title'),
        filterType: filterTypes.NONE,
        filterName: 'jwtLoginsForPeriod',
      },
      {
        fieldName: 'schedulerExecutionsForPeriod.count',
        title: this.labels.get('columns.schedulerExecutionsForPeriod.title'),
        filterType: filterTypes.NONE,
        filterName: 'schedulerExecutionsForPeriod',
      },
      {
        fieldName: 'activitiesForPeriod.count',
        title: this.labels.get('columns.activitiesForPeriod.title'),
        filterType: filterTypes.NONE,
        filterName: 'activitiesForPeriod',
      },
      {
        fieldName: 'workOrdersTotal.count',
        title: this.labels.get('columns.workOrdersTotal.title'),
        filterType: filterTypes.NONE,
        filterName: 'workOrdersTotal',
      },
      {
        fieldName: 'workOrdersForPeriod.count',
        title: this.labels.get('columns.workOrdersForPeriod.title'),
        filterType: filterTypes.NONE,
        filterName: 'workOrdersForPeriod',
      },
      {
        fieldName: 'assetsForPeriod.count',
        title: this.labels.get('columns.assetsForPeriod.title'),
        filterType: filterTypes.NONE,
        filterName: 'assetsForPeriod',
      },
      {
        fieldName: 'deviceAppRunningVersion',
        title: this.labels.get('columns.deviceAppRunningVersion.title'),
        filterType: filterTypes.NONE,
        filterName: 'deviceAppRunningVersion',
      },
    ];
  };

  render() {
    return (
      <div className="dashboard-grid">
        <Grid
          gridName={gridsNames.ADMINISTRATION_ORGS_DASHBOARD}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
          gridProps={{
            checkboxSelection: false,
          }}
        />
      </div>
    );
  }
}

OrgsDashboardViewGrid.propTypes = {
  actions: PropTypes.object,
};

OrgsDashboardViewGrid.defaultProps = {
  actions: {},
};

export default reduxForm({
  form: 'orgsDashboardGrid',
})(OrgsDashboardViewGrid);
