import importFileService from '../../../services/ImportFiles/importFilesService';
import Network from 'infrastructure/js/modules/network';
import {api as messageDialogApi} from "../../MessageDialog/messageDialogActions";

export const actionTypes = {
  CREATE_ATTACHMENT_SHOW: "CREATE_ATTACHMENT_SHOW",
  CREATE_ATTACHMENT_HIDE: "CREATE_ATTACHMENT_HIDE",
  CREATE_ATTACHMENT_IN_PROCESS: "CREATE_ATTACHMENT_IN_PROCESS",
  CREATE_ATTACHMENT_FINISHED: "CREATE_ATTACHMENT_FINISHED"
};

const actions = {
  show: function (payload) {
    return {type: actionTypes.CREATE_ATTACHMENT_SHOW, payload};
  },
  hide: function () {
    return {type: actionTypes.CREATE_ATTACHMENT_HIDE};
  },
  submitInProcess: function () {
    return {type: actionTypes.CREATE_ATTACHMENT_IN_PROCESS};
  },
  submitFinished: function () {
    return {type: actionTypes.CREATE_ATTACHMENT_FINISHED};
  }
};


export let jsxActions = {};

jsxActions.submit = function (file, asset, reloadParentComponent) {
  return function (dispatch, getState) {
    dispatch(actions.submitInProcess());

    importFileService.uploadAttachment(file, asset.type.toUpperCase(), asset.ids).then((response) => {
      dispatch(actions.submitFinished());
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Upload Attachment failed', response);
        return messageDialogApi.responseError(dispatch, getState)(response);
      }
      if(response.dataList && response.dataList.length && (!Network.isResponseValid(response.dataList[0]))){
        messageDialogApi.responseError(dispatch, getState)(response.dataList[0]);
      }
      if (reloadParentComponent) {
        reloadParentComponent()
      }
    });
  }
};

jsxActions.hide = function () {
  return function (dispatch, getState) {
    dispatch(actions.hide());
  }
};

export let api = {};

api.downloadFile = function (dispatch, getState) {
  return function (data) {
    let ids = data.selectedRowsData.map((selected) => { return selected.id });
    let type = data.selectedRowsData.get(0).originalFileName.split('.').pop();

    importFileService.downloadAttachment(ids.get(0)).then((response) => {
      _handleBlobResponse(response, dispatch, getState, type);
    });
  }
};

api.deleteFile = function (dispatch, getState) {
  return function (data) {
    let ids = data.selectedRowsData.map((selected) => {
      return selected.id
    });

    importFileService.deleteAttachment(ids).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('Download attachment failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
      }
      if (data.reloadParentComponent) {
        data.reloadParentComponent()
      }
    });
  }
};

api.show = function (dispatch, getState) {
  return function (data) {
    dispatch(actions.show(data));
  }
};

function _handleBlobResponse(response, dispatch, getState, fileType) {
  if (!response.ok) {
    console.error('Download attachment failed', response);
    messageDialogApi.responseError(dispatch, getState)();
    return;
  }
  Network.loadBlobResponse(response, fileType).then((loadResponse) => {
    if (!Network.isResponseValid(loadResponse)) {
      console.error('Download attachment failed', loadResponse);
      messageDialogApi.responseError(dispatch, getState)(loadResponse);
    }
  });
}
