import { useState } from 'react';
import ProptTypes from 'prop-types';
import ButtonSelect from 'infrastructure/js/components/controls/ButtonSelect/buttonSelect';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { enumTypes, getEnumValue } from '../../../../utils/enumHelper';
import { getSystemColors } from 'infrastructure/js/utils/colorHelper';
import TaskStarted from 'infrastructure/js/components/DynamicIcons/TaskStarted';
import TaskCompleted from 'infrastructure/js/components/DynamicIcons/TaskCompleted';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import ReportTaskProgressDialog from '../../../Dialogs/ReportProgressDialog/reportTaskProgressDialog'
import './tasksPanel.scss';

function TasksPanel({ selectedRows, actions: { bulkProgressReport, reportTaskProgressDialogActions }, sReportTaskProgressDialogData }) {
  const [labels] = useState(() => createLabelHelper('mat.locationpage.view.tasks.panel.'));

  const { started, completed } = getSystemColors();

  const MarkAsStartedButtonIcon = <TaskStarted color={started} />;
  const MarkAsCompletedButtonIcon = <TaskCompleted color={completed} />;

  const startedOptions = [
    {
      value: 2,
      label: labels.get('buttons.startedAsPlanned'),
      status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
      isReportAsPlanned: true,
    },
    {
      value: 3,
      label: labels.get('buttons.startedAt'),
      status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
      isReportAsPlanned: false,
      reportAtSpecificTime: true
    },
  ];

  const completedOptions = [
    {
      value: 2,
      label: labels.get('buttons.completedAsPlanned'),
      status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
      isReportAsPlanned: true,
    },
    {
      value: 3,
      label: labels.get('buttons.completedAt'),
      status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
      isReportAsPlanned: false,
      reportAtSpecificTime: true
    },
  ];

  const taskCounterText = labels.get('numOfSelectedTasks', '', {
    count: selectedRows?.size || 0,
  });

  const onButtonClick = (data) => {
    const queryData = {
      ...data,
      selectedRows,
    };

    if(data.reportAtSpecificTime){
      reportTaskProgressDialogActions.show(queryData)
      return;
    }

    bulkProgressReport(queryData);
  };

  return (
    <div className="tasks-view-panel">
      <div className="action-buttons">
        <ButtonSelect
          className={'mark-started-select-button'}
          options={startedOptions}
          buttonData={{
            label: labels.get('buttons.markAsStarted'),
            status: getEnumValue(enumTypes.TASK_STATUS)('STARTED'),
            isReportAsPlanned: false,
          }}
          icon={MarkAsStartedButtonIcon}
          onButtonClick={onButtonClick}
          onSelect={onButtonClick}
          disabled={!selectedRows?.size}
        />
        <ButtonSelect
          className={'mark-completed-select-button'}
          options={completedOptions}
          buttonData={{
            label: labels.get('buttons.markAsCompleted'),
            status: getEnumValue(enumTypes.TASK_STATUS)('COMPLETED'),
            isReportAsPlanned: false,
          }}
          icon={MarkAsCompletedButtonIcon}
          onButtonClick={onButtonClick}
          onSelect={onButtonClick}
          disabled={!selectedRows?.size}
        />
      </div>
      <div className="task-counter">{taskCounterText}</div>

      <PL_DialogWrapper
          dialogComponent={ReportTaskProgressDialog}
          show={sReportTaskProgressDialogData.get('show')}
          actions={reportTaskProgressDialogActions}
          sData={sReportTaskProgressDialogData}
        />
    </div>
  );
}

TasksPanel.propTypes = {
  sReportTaskProgressDialogData: ProptTypes.object,
  selectedRows: ProptTypes.object,
  actions: ProptTypes.object,
};

export default TasksPanel;
