import React from 'react';
import EntitiesMultiSelectFieldAsync from '../../../../Common/Controls/EntitiesMultiSelectFieldAsync/entitiesMultiSelectFieldAsync';
import { func, object, string } from 'prop-types';

export default class EntitiesSelect extends React.PureComponent {
  render() {
    return <EntitiesMultiSelectFieldAsync {...this.props} key={this.props.reloadKey} entitiesType={this.props.entityType} isMulti={false}  />
  }
}

EntitiesSelect.propTypes = {
  fetchConfig: object.isRequired,
  entityType: string.isRequired,
  change: func.isRequired
}
