import {List, Map} from 'immutable';
import {dialogsNames} from '../../../enums/dialogsNames';
import defaultDialogReducer from '../../defaultDialogReducer';
import {actionTypes} from '../../../actions/LocationPage/ShipmentsView/shipToCustomerActions';

let defaultState = {
  show: false,
  loading: false,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case actionTypes.SHIP_TO_CUSTOMER_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.SHIP_TO_CUSTOMER_DIALOG, defaultState);
  }
}


