import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';
import { reduxForm } from 'redux-form';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelectWithChildren.js';
import { enumTypes, getEnumValue } from '../../../utils/enumHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Button from 'infrastructure/js/components/controls/Button/button';
import { FetchEntitiesFilters } from '../../../enums/fetchEntitiesFilters';
import { generatePickListId } from '../../../services/PickLists/pickListsService';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';

require('./addToPickListDialog.scss');

class AddToPickListDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.dialog.addToPickListDialog.');

    this.fetchConfig = {
      action: this.props.actions.fetchEntities,
      filter: [FetchEntitiesFilters.ACTIVE],
    };

    this.state = {
      isNewPickList: true,
    };
  }

  getDialogButtons() {
    return {
      left: [
        {
          id: 'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide,
        },
      ],
      right: [
        {
          id: 'submit',
          text: this.dialogLabels.get('add'),
          bsStyle: 'primary',
          action: this.props.handleSubmit(this.onSubmit),
          disabled:
            this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings')),
        },
      ],
    };
  }
  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {
      let title = this.labels.get('confirmation.header');
      let className = 'oneBackground activate-workorder-confirm-message';
      let children = <MessageDialog.DataRow label={this.labels.get('confirmation.message')} value="" />;

      let messageDialogDescriptor = { title, children, className };
      return messageDialogDescriptor;
    };
  };

  getPickListAssets = () => {
    let pickListAssets = this.props.sData.get('pickListAssets');
    // console.dir(pickListAssets);
    return pickListAssets || [];
  };

  getDestinations = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.destinations || [];
  };

  onValueChangeCallback = (newValue, oldValue) => {
    //prevent clearing the 'Natures' list when there was no change
    if ((newValue && oldValue && newValue.value === oldValue.value) || newValue === null) {
      this.setState({ isNewPickList: true });
      return;
    }
    if (newValue.label !== newValue.value) {
      //existing pick list
      this.props.change('destination', null);
      this.props.change('dueDate', null);
    }
    this.setState({ isNewPickList: newValue.label === newValue.value });
  };

  onSubmit = (data) => {
    let newData = {
      rawMaterialIds: this.getPickListAssets(),
    };

    //Note: 2 modes:
    // 1. Edit Mode (when existing pick list was selected) - add assets to the existing Pick list
    // 2. Create Mode (when new pick list was set) - create pick list
    let isEditMode = !this.state.isNewPickList;
    if (isEditMode) {
      newData.pickListId = data?.pickListSelect?.assetsValue?.value;
    } else {
      newData.destinationId = data?.destination;
      newData.dueDate = DateTimeHelper.ConvertFromDate(data.dueDate);
    }
    newData.businessId = isEditMode ? data?.pickListSelect?.assetsValue?.data?.businessId : data?.pickListSelect?.assetsValue?.value;

    this.props.actions.submit(newData, isEditMode, null, this.props.reloadParentComponent);
  };

  onHide = () => {
    this.props.actions.hide();
  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox')
      ? { label: this.dialogLabels.get('ignorewarnings'), onChange: this.onFooterValidationChange }
      : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  generateIdResponseHandler = (response) => {
    if (response?.data) {
      //TODO: L PL check it
      const newOption = {
        value: response.data.data,
        label: response.data.data,
        data: {
          id: response.data.data,
          objectType: getEnumValue(enumTypes.OBJECT_TYPE)('PICK_LIST'),
        },
      };
      this.props.change('pickListSelect.assetsValue', newOption);
      this.setState({ isNewPickList: true });
    }
  };

  generateId = () => generatePickListId().then(this.generateIdResponseHandler);

  maxLengthDropDown = Validation.dropdown.maxLength(50);

  render() {
    return (
      <Dialog
        id="addToPickListDialog"
        className="add-to-pick-list-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        // onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >
        <EntitiesMultiSelect
          id="pickListSelect"
          name="pickListSelect"
          entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('PICK_LIST')]}
          validate={[Validation.dropdown.required, this.maxLengthDropDown]}
          fetchConfig={this.fetchConfig}
          isMulti={false}
          allowNewOption={true}
          change={this.props.change}
          getNewOptionData={this.getNewOptionDataHandler}
          onValueChangeCallback={this.onValueChangeCallback}
        >
          <div className="input-with-generate-button">
            <Button id={'-button'} onClick={this.generateId} className={' generateButton border-btn btn'}>
              Generate
            </Button>
          </div>
        </EntitiesMultiSelect>

        {this.state.isNewPickList && (
          <InputSection label={this.labels.get('destination') + '*'} htmlFor="destination" className="inline left-side">
            <Combobox
              id="destination"
              name="destination"
              options={this.getDestinations()}
              validate={this.state.isNewPickList ? Validation.required : undefined}
              isDisabled={!this.state.isNewPickList}
              parse={Parse.comboValueOnly()}
              format={Format.findOptionByValue(this.getDestinations())}
            />
          </InputSection>
        )}

        {this.state.isNewPickList && (
          <InputSection label={this.labels.get('dueDate') + '*'} htmlFor="date" className="inline right-side">
            <DatePicker
              id="due-date-date-picker"
              name="dueDate"
              initialDate={null}
              popperPlacement="auto"
              validate={this.state.isNewPickList ? [Validation.required, Validation.date] : undefined}
              disabled={!this.state.isNewPickList}
              minDate={moment()}
            />
          </InputSection>
        )}
      </Dialog>
    );
  }
}

export default reduxForm({
  form: 'addToPickListDialog',
  onChange: (values, dispatch, props, previousValues) => {
    dialogHelper.onFormChangeHandler(values, props, previousValues);
  },
})(AddToPickListDialog);

AddToPickListDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};
