import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useLabels from 'infrastructure/js/hooks/useLabels';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Button from 'infrastructure/js/components/controls/Button/button';
import { reduxForm } from 'redux-form';
import DateRangePicker from 'infrastructure/js/components/controls/DateRangePicker/dateRangePicker.js';

import './orgsDashboardViewHeader.scss';


function OrgsDashboardViewHeader({actions,
                                   pristine=true,
                                   handleSubmit=()=>{ console.error('handleSubmit is missing!'); },
                                   initialize=()=>{ console.error('initialize is missing!'); }
                                 }) {

  const [selectedReportDateFrom, setSelectedReportDateFrom] = useState(null);
  const [selectedReportDateTo, setSelectedReportDateTo] = useState(null);

  const labels = useLabels('mat.organizations.orgsDashboard.view.header.');

  useEffect(() => {
    initFormData();
  }, []);


  const initFormData = () => {
    let initialValue = {
      reportDateFrom: selectedReportDateFrom,
      reportDateTo: selectedReportDateTo,
    }
    initialize(initialValue);
  }

  const onDateChange = (startDate, endDate) => {
    setSelectedReportDateFrom(startDate && startDate._isValid ? startDate : null);
    setSelectedReportDateTo(endDate && endDate._isValid ? endDate : null);
  }

  const onSubmit = (data) => {
    actions.fetchGridData(data);

    initFormData();
  };

  return (
    <div className="orgs-dashboard-header">
      <InputSection label={labels.get('reportDates')} htmlFor="woPickDate" className="inline-section">
          <DateRangePicker
            onDateChange={onDateChange}
            selectedStartDate={selectedReportDateFrom}
            selectedEndDate={selectedReportDateTo}
            fromName="reportDateFrom"
            toName="reportDateTo"
            renderAsPopover
            renderAsPopoverWithButtons={false}
          />
      </InputSection>

      <Button id="generateReport" bsStyle="primary"
              disabled={pristine}
              onClick={handleSubmit(onSubmit)}>
        {labels.get('button.generateReport')}
      </Button>
    </div>
    );
}

export default reduxForm({
  form: 'orgsDashboardHeader',
})(OrgsDashboardViewHeader);

OrgsDashboardViewHeader.propTypes = {
  actions: PropTypes.shape({
    fetchGridData: PropTypes.func.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  pristine: PropTypes.bool,
}
