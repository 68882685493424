import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import useLabels from 'infrastructure/js/hooks/useLabels';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import PL_MultiSelectField from 'infrastructure/js/components/controls/MultiSelectField/multiSelectField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import List from 'infrastructure/js/components/List/list';
import EmailListItem from '../../../../Common/Form/EmailListItem/emailListItem';

import './misplacedMaterialAlertRuleDialog.scss';

const getInitialEmailValue = (item) => {
  return {
    label: item,
    value: item,
    data: {
      fullName: '',
      email: item,
    },
  };
};

const hasSomeValue = (values) => {
  const emails = values.emails?.filter(item => !!item.emailInput?.data?.email);
  return !!emails?.length;
}

function MisplacedMaterialAlertRuleDialog({
  sData,
  show,
  actions,
  handleSubmit,
  initialize,
  change,
  reloadParentComponent,
  pristine,
  touch,
  fieldsValues,
}) {
  const labels = useLabels('mat.administration.settings.misplacedMaterial.dialog.alertRule.');
  const dialogLabels = useLabels('mat.dialog.');
  const { submit, hide } = actions;
  const { selectedRules, usedOrigins, usedDestinations } = sData.get('itemToEdit');
  const locations = sData.get('dialogData')?.locations;
  const selectedRule = selectedRules?.first();
  const isEditMode = !!selectedRule?.id;

  const [selectedOrigin, setSelectedOrigin] = useState(selectedRule?.origin?.id);

  const preselectedDestinations = selectedRule?.destinations?.map((dest) => ({ value: dest.id, label: dest.name, data: dest })) ?? [];

  useEffect(() => {
    if (selectedRule) {
      const originOption = {
        value: selectedRule?.origin?.id,
        label: selectedRule?.origin?.name,
        data: selectedRule?.origin,
      };

      const initialFormData = {
        origin: originOption,
        destinations: preselectedDestinations,
        timer: selectedRule?.timer,
        emails: selectedRule?.recipients?.map((item) => {
          return { emailInput: getInitialEmailValue(item) };
        }) || [{}],
      };

      initialize(initialFormData);
      setSelectedOrigin(selectedRule?.origin?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doSubmit = (formData) => {
    const submitData = {
      originId: formData.origin.value,
      destinationIds: formData.destinations.map(({ value }) => value),
      recipients:
        formData.emails
          ?.filter((item) => !!item.emailInput?.data?.email)
          ?.map((item) => item.emailInput?.data?.email)
          .join() || [],
      timer: formData.timer,
    };

    if (isEditMode) {
      submitData.ruleId = selectedRule.id;
    }

    submit(submitData, isEditMode, reloadParentComponent);
  };

  const dialogButtons = {
    left: [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: hide,
      },
    ],
    right: [
      {
        id: 'submit',
        text: isEditMode ? dialogLabels.get('edit') : dialogLabels.get('create'),
        bsStyle: 'primary',
        action: handleSubmit(doSubmit),
        disabled: pristine || sData.get('loading') || !hasSomeValue(fieldsValues),
      },
    ],
  };

  const filteredOriginOptions =
    locations?.filter((location) => !usedOrigins?.some((origin) => origin.id === location.value) || selectedRule?.origin?.id === location.value) ??
    [];

  const filteredDestinationsOptions =
    locations
      ?.filter((location) => {
        const isUsedDestInLocations = usedDestinations?.some((destination) => destination.id === location.value);
        const isLocationInSelectedOrigin = location.value === selectedOrigin;
        const isRuleOrigin = selectedRule?.origin?.id === location.value;
        return (!isUsedDestInLocations && !isLocationInSelectedOrigin) || isRuleOrigin;
      })
      ?.concat(preselectedDestinations) ?? [];

  const handleOriginSelect = ({ value }) => setSelectedOrigin({ value });

  const getEmailItemRenderer = (data) => {
    return <EmailListItem {...data} change={change} touch={touch} actions={actions} />;
  };

  return (
    <Dialog
      id="misplaced-material-alert-rule-dialog"
      className="misplaced-material-alert-rule-dialog"
      titleText={isEditMode ? labels.get('edit.title') : labels.get('create.title')}
      show={show}
      onHide={hide}
      footerButtons={dialogButtons}
      sData={sData}
    >
      <InputSection label={labels.get('origin') + '*'} htmlFor="origin" className="inline left-side">
        <Combobox
          id={'origin'}
          name={'origin'}
          options={filteredOriginOptions}
          onChangeCallback={handleOriginSelect}
          validate={Validation.required}
        />
      </InputSection>
      <InputSection label={labels.get('destination') + '*'} htmlFor="destination" className="inline right-side">
        <PL_MultiSelectField
          id={'destinations'}
          name={'destinations'}
          options={filteredDestinationsOptions}
          closeMenuOnSelect={false}
          validate={Validation.required}
        />
      </InputSection>
      <InputSection label={labels.get('timer') + '*'} htmlFor="timer" className="inline left-side">
        <TextField id="timer" name="timer" normalize={Normalize.number(true, 0, 9999)} validate={Validation.required} />
      </InputSection>
      <InputSection label={labels.get('recipients') + '*'} htmlFor="recipients">
        <List
          className="emails-list"
          name="emails"
          itemRenderer={getEmailItemRenderer}
          maxItemsToOverflow={7}
          addButtonLabel={labels.get('button.addEmail')}
        />
      </InputSection>
    </Dialog>
  );
}

MisplacedMaterialAlertRuleDialog = reduxForm({
  form: 'misplacedMaterialAlertRuleDialog',
})(MisplacedMaterialAlertRuleDialog);

const selector = formValueSelector('misplacedMaterialAlertRuleDialog');

export default MisplacedMaterialAlertRuleDialog = connect((state) => {
  return {
    fieldsValues: {
      emails: selector(state, 'emails'),
    },
  };
})(MisplacedMaterialAlertRuleDialog);
