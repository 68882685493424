import PropTypes from 'prop-types';
import cn from 'classnames';
import ClearIcon from 'infrastructure/assets/svg/clear.svg';
import SearchIcon from 'infrastructure/assets/svg/search-icon-sm.svg';
import './search.scss';

const Search = ({ value='',
                  onChange=()=>{},
                  onClear=()=>{},
                  className=''}) => {

  return (
    <div className={cn('search-container', className)}>
    <div className="search-input">
      <input type="text" placeholder="SEARCH" onChange={onChange} value={value} />
      {value ? <ClearIcon className="clear-icon" onClick={onClear} /> : <SearchIcon />}
    </div>
    </div>
  )
}

Search.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

export default Search;
