import React, { useState, useEffect, useRef } from 'react';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import TasksGrid from './TasksGrid/tasksGrid';
import { gridsNames } from '../../../enums/gridsNames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Header, { headerItemsLabels } from '../../Common/Header/header';
import TasksPanel from './TasksPanel/tasksPanel';
import Button from 'infrastructure/js/components/controls/Button/button';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import ScheduleAction from '../../../modules/scheduleAction';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import DateRangePopover from 'infrastructure/js/components/popover/DateRangePopover/DateRangePopover';
import DateRangeView from './DateRangeView/dateRangeView';
import {isTouchApp} from 'infrastructure/js/utils/appHelper';

import './tasksView.scss';

function TasksView({
  sHasRows,
  actions,
  sTasksGridData,
  sSelectedRowsData,
  sReportTaskProgressDialogData,
  sLocationDetails,
  sTasksViewHistory,
  match,
  touch
}) {
  const [labels] = useState(() => createLabelHelper('mat.locationpage.view.tasks.'));
  const [viewDate, setViewDate] = useState(moment());
  const [viewEndDate, setViewEndDate] = useState(null);
  const refreshTimerRef = useRef();

  useEffect(() => {
    const isTabActive = match.params.tabId === 'tasks';
    const refreshRate = PermissionManager.getOrgPreferences().refreshRateStationTaskListView;
    if(isTabActive && refreshRate){
      refreshTimerRef.current = ScheduleAction('tasks view refresh timer', actions?.tasksGridActions?.reload, refreshRate);
    }

    return () => {
      refreshTimerRef.current?.kill()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.tabId])


  const getHeaderItems = () => {
    const hasRows = sHasRows;
    return {
      title: '',
      buttons: [
        ...(!isTouchApp() ? [
          {
            id: 'clearFilters',
            label: '',
            icon: 'pl pl-filter-clear',
            action: actions?.clearFilters,
            tooltip: headerItemsLabels.CLEAR_FILTERS,
            disabled: isLoading(),
          }] : []),
        {
          id: 'refreshAll',
          label: '',
          icon: 'pl pl-refresh',
          action: () => actions?.locationPageActions?.reload?.(),
          tooltip: headerItemsLabels.REFRESH_ALL,
          disabled: isLoading(),
        },
        {
          id: 'undoButton',
          tooltip: headerItemsLabels.UNDO,
          icon: 'fa fa-undo',
          disabled: !sTasksViewHistory?.commandStack?.length,
          action: actions.undo,
        },
        {
          id: 'redoButton',
          tooltip: headerItemsLabels.REDO,
          icon: 'fa fa-repeat',
          disabled: !sTasksViewHistory?.redoStack?.length,
          action: actions.redo,
        },
      ],
      kebab: {
        menuItems: [
          {
            id: 'exportTasksList',
            label: headerItemsLabels.EXPORT_TASKS_LIST,
            action: actions.exportActions.exportTasksProgressGrid,
            disabled: !hasRows,
            actionData: { gridName: gridsNames.LOCATION_TASKS },
          },
        ],
      },
      leftContent: getDateNavigation(),
    };
  };

  const getDateNavigation = () => {
    return (
      <div className="tasks-date-nav-container">
        <DateRangeView startDate={viewDate} endDate={viewEndDate} />
        <Button
          id="tasksPrevDate"
          name="previous_day"
          className={'border-btn only-icon'}
          icon={'pl pl-arrow-left'}
          onClick={onDateChange}
          // disabled={}
        />
        <Button
          id="tasksToday"
          name="today"
          className={'border-btn no-icon'}
          onClick={onDateChange}
          // disabled={}
        >
          {labels.get('dateNavigation.today')}
        </Button>

        <DateRangePopover
          placement='bottom'
          rangeStartLabel={'From:'}
          rangeEndLabel={'To:'}
          rangeStartInputProps={{
            dateFormat: DateTimeHelper.getDateFormat(),
            placeholderText: DateTimeHelper.getDateFormat(),
            isClearable: true,
          }}
          rangeStartPickerProps={{
          }}
          rangeEndInputProps={{
            dateFormat: DateTimeHelper.getDateFormat(),
            placeholderText: DateTimeHelper.getDateFormat(),
            isClearable: true,

          }}
          rangeEndPickerProps={{
          }}
          onDoneClick={(startDate, endDate) => onDatePick(startDate, endDate)}
          hideOnClickOutside={true}
        />

        <Button
          id="tasksNextDate"
          name="next_day"
          className={'border-btn only-icon'}
          icon={'pl pl-arrow-right'}
          onClick={onDateChange}
          // disabled={}
        />
      </div>
    );
  };

  const onDateChange = (e) => {
    switch (e.target.name) {
      case 'previous_day':
        {
          const prevDateObj = viewDate.subtract(1, 'd');
          const prevEndDateObj = viewEndDate?.subtract(1, 'd') ?? null;
          setViewDate(prevDateObj);
          setViewEndDate(prevEndDateObj);
          actions.changeViewDate(prevDateObj.format('DD-MM-YYYY').toString(), prevEndDateObj?.format('DD-MM-YYYY').toString() ?? null);
        }
        break;
      case 'today': {
        const dateObj = moment();
        setViewDate(dateObj);
        setViewEndDate(null);
        actions.changeViewDate(dateObj.format('DD-MM-YYYY').toString(), null);
        break;
      }
      case 'next_day':
        {
          const nextDateObj = viewDate.add(1, 'd');
          const nextEndDateObj = viewEndDate?.add(1, 'd') ?? null;
          setViewDate(nextDateObj);
          setViewEndDate(nextEndDateObj);
          actions.changeViewDate(nextDateObj.format('DD-MM-YYYY').toString(), nextEndDateObj?.format('DD-MM-YYYY').toString() ?? null);
        }
        break;
      default:
        break;
    }
  };

  const onDatePick = (startDateObj, endDateObj) => {
    touch('rangeStartInput');
    touch('rangeEndInput');

    if (startDateObj?.isValid() && endDateObj?.isValid()) {
      const duration = moment.duration(endDateObj.diff(startDateObj));
      const days = duration.asDays();
      if (days < 0) {
        return;
      }

      setViewDate(startDateObj);
      setViewEndDate(endDateObj);
      actions.changeViewDate(startDateObj.format('DD-MM-YYYY').toString(), endDateObj.format('DD-MM-YYYY').toString());
    }
  };

  const isLoading = () => {
    return sTasksGridData ? sTasksGridData.get('loading') : false;
  };

  const headerItems = getHeaderItems();

  const { tasksGridActions, fetchEntitiesActions } = actions;

  return (
    <div className="location-tasks-view">
      <Header className="tasks-view-header" {...headerItems} />
      <TasksPanel selectedRows={sSelectedRowsData} actions={{ ...actions }} sReportTaskProgressDialogData={sReportTaskProgressDialogData}/>
      <TasksGrid  locationDetails={sLocationDetails}
        actions={{
          ...tasksGridActions,
          fetchEntitiesActions,
        }}
      />
    </div>
  );
}

TasksView.propTypes = {
  sHasRows: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  sTasksGridData: PropTypes.object,
  sSelectedRowsData: PropTypes.object,
  sLocationDetails: PropTypes.object,
  sReportTaskProgressDialogData: PropTypes.object,
  sTasksViewHistory: PropTypes.object,
  match: PropTypes.object
};

export default reduxForm({
  form: 'tasksView'
})(TasksView);
