import React from 'react';
import AssetAlertPopover from '../../../../Common/Layout/AssetAlertPopover/assetAlertPopover.js';
import {isAssetActivated} from '../../../../../utils/assetHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import TextFieldEditor from 'infrastructure/js/components/editors/TextFieldEditor/textFieldEditor';
import NumericFieldEditor from 'infrastructure/js/components/editors/NumericFieldEditor/numericFieldEditor';
import DatepickerOverlayEditor from 'infrastructure/js/components/popover/DatepickerOverlayEditor/datepickerOverlayEditor';
import TextAreaFieldEditor from 'infrastructure/js/components/editors/TextAreaFieldEditor/textAreaFieldEditor';
import * as additionalFieldsHelper from '../../../../Common/Helpers/AdditionalFieldsHelper';
import {enumTypes, getEnumValue} from '../../../../../utils/enumHelper';
import AlertPopover, {AlertPopoverType} from 'infrastructure/js/components/popover/AlertPopover/alertPopover';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

export function getAlertPopover(asset, alertType) {
  return <AssetAlertPopover value={asset} type={alertType}/>;
}

export function getIcon(asset, type) {

  if (!asset || !asset.alerts || asset.alerts.length === 0) {
    return null;
  }
  let alert = asset.alerts.find((alert) => {
    return alert.alertType === type;
  });

  if (alert) {
    let icon = alert?.severityType === 'ERROR' ? 'pl alert-icon pl-error-icon error' :
               alert?.severityType === 'WARNING' ?'pl alert-icon pl-warning-icon warning' :
               alert?.severityType === 'INFO' ?'pl alert-icon pl-warning-icon info' : '';

    return <span className={icon}/>;
  }
  return null;
}

export function getAdditionalFieldValue(type, id, editorSubmitData){
  let fieldValue = additionalFieldsHelper.getAdditionalFieldValue(type, editorSubmitData[`additionalField-${id}`]);
  let newData = {
    additionalFieldsValues: [
      {
        fieldId: id,
        fieldType: type,
        value: fieldValue
      }
    ]
  };
  return newData;
}

export function addAdditionalFields(columns, asset, submitAction, additionalFieldsDefinitions) {
  if(!additionalFieldsDefinitions){
    return;
  }
  for (let index = 0; index < additionalFieldsDefinitions.length; index++) {
    let matchField = asset.additionalFieldsValues ? asset.additionalFieldsValues.find((field)=>{ return field.field.id === additionalFieldsDefinitions[index].id }) : null;
    let additionalField  = _convertToAdditionalField(asset,submitAction, additionalFieldsDefinitions[index], matchField);
    if(!additionalField){
      continue
    }
    columns[index%3].push(additionalField);
  }
}

export function alignColumns(columns) {
  let maxRows = Math.max(columns[0].length, columns[1].length, columns[2].length);

  columns.forEach(column => {
    for (let i = 0; i < maxRows; i++) {
      if (!column[i]) {
        column.push({label: '', body: null });
      }
    }
    column.push({ type: 'hr', body: <hr /> });
  })
}

function _convertToAdditionalField(asset, submitAction, additionalFieldsDefinitions, fieldValue) {

  switch (additionalFieldsDefinitions.type.type) {
    case 'TEXT':
      return renderTextFieldEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue);
    case 'NUMERIC':
      return renderNumericFieldEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue);
    case 'DATE_PICKER':
      return renderDateFieldEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue);
    case 'LARGE_TEXT':
      return renderTextAreaEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue);
    default:
      console.log(`Additional fields - unsupported type: ${additionalFieldsDefinitions.field.type.type}`);
      return null;
  }
}

const maxLength40 = Validation.maxLength(40), maxLength250 = Validation.maxLength(250);

function renderTextFieldEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue) {
  let isEditable = fieldValue ? !fieldValue.isReadOnly : true;
  return {
    label: additionalFieldsDefinitions.displayName + ':',
    body: (
      <TextFieldEditor
        id={`additionalField-${additionalFieldsDefinitions.id}`}
        name={`additionalField-${additionalFieldsDefinitions.id}`}
        value={fieldValue ? fieldValue.value : null}
        handleSubmit={(value) => submitAction(value, additionalFieldsDefinitions.type.type, additionalFieldsDefinitions.id)}
        editable={isEditable && isAssetActivated(asset.assetStatus || asset.workOrderStatus)}
        validate={maxLength40}
        maxLength={40}
      />
    )
  }
}

function renderNumericFieldEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue) {
  let isEditable = fieldValue ? !fieldValue.isReadOnly : true;
  return {
    label: additionalFieldsDefinitions.displayName + ':',
    body: (
      <NumericFieldEditor
        id={`additionalField-${additionalFieldsDefinitions.id}`}
        name={`additionalField-${additionalFieldsDefinitions.id}`}
        value={fieldValue ? parseFloat(fieldValue.value) : null}
        min={0} max={99999.99}
        handleSubmit={(value) => submitAction(value, additionalFieldsDefinitions.type.type, additionalFieldsDefinitions.id)}
        editable={isEditable && isAssetActivated(asset.assetStatus || asset.workOrderStatus)}
      />

    )
  }
}

function renderDateFieldEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue) {
  let isEditable = fieldValue ? !fieldValue.isReadOnly : true;
  return {
    label: additionalFieldsDefinitions.displayName + ':',
    body: (
      <DatepickerOverlayEditor
        id={`additionalField-${additionalFieldsDefinitions.id}`}
        name={`additionalField-${additionalFieldsDefinitions.id}`}
        // placement="top"
        value={fieldValue ? fieldValue.value : null}
        handleSubmit={(value) => submitAction(value, additionalFieldsDefinitions.type.type, additionalFieldsDefinitions.id)}
        editable={isEditable && isAssetActivated(asset.assetStatus || asset.workOrderStatus)}
      />
   )
  }
}

function renderTextAreaEditor(asset, submitAction, additionalFieldsDefinitions, fieldValue) {
  let isEditable = fieldValue ? !fieldValue.isReadOnly : true;
  return {
    label: additionalFieldsDefinitions.displayName + ':',
    body: (
      <TextAreaFieldEditor
        id={`additionalField-${additionalFieldsDefinitions.id}`}
        name={`additionalField-${additionalFieldsDefinitions.id}`}
        value={fieldValue ? fieldValue.value : null}
        handleSubmit={(value) => submitAction(value, additionalFieldsDefinitions.type.type, additionalFieldsDefinitions.id)}
        editable={isEditable && isAssetActivated(asset.assetStatus || asset.workOrderStatus)}
        validate={maxLength250}
        maxLength={250}
      />
    )
  }
}

export function renderAssetLocationAlerts (asset, labels) {
  const locationAlerts = asset?.alerts?.filter(alert => {
    return alert.alertType === getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL') ||
           alert.alertType === getEnumValue(enumTypes.ALERT_TYPE)('RESERVED')
  });

  if (locationAlerts.length > 0) {
    let locationAlertsComps = locationAlerts.map((alert, index) => {

      let popoverType = alert.alertType === getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL') ?
        AlertPopoverType.WARNING : AlertPopoverType.INFO;
      let popoverComponentLabel = alert.alertType === getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL') ?
        labels.get('misplacedAsset.warning', '', {location: asset.locationName}) :
        labels.get('reserved.warning', '', {details: asset.pickListBusinessId})

        return (
          <AlertPopover key={index} popoverType={popoverType} popoverComponent={<span>{popoverComponentLabel}</span>}>
            {getIcon(asset, alert.alertType)}
          </AlertPopover>
        )
    })
    return (
      <>
        {locationAlertsComps}
      </>
    )
  }
  return null;
}

export function renderDefrostingStatus(asset) {
  if (asset.defrostingTimeLeft === null) {
    return renderUnavailableLabel();
  }
  let time = DateTimeHelper.ConvertMinutesToHoursMinutes(asset.defrostingTimeLeft);
  let allLabels = createLabelHelper('');
  let defrostingStatus = asset.defrostingStatusDisplayKey ? allLabels.get(asset.defrostingStatusDisplayKey) : '';
  let timeLabel = '';
  if (asset.defrostingStatus === 'BEING_DEFROSTED') {
    timeLabel = asset.defrostingTimeLeft
      ? createLabelHelper('mat.grid.defrostingTime').get(
        '',
        undefined,
        { time }
      )
      : '';
  }
  return (
    <TextFieldEditor
      id="defrostingStatus"
      name="defrostingStatus"
      value={`${defrostingStatus} ${timeLabel}`}
      editable={false}
    />
  );
}

function getInFreezer(asset) {
  const labels = createLabelHelper('mat.assetpage.overview.details.');
  return asset?.location?.isFreezer ? <span className="in-freezer">{labels.get('infreezer')}</span> : null;
}

export function renderUnavailableLabel() {
  const labels = createLabelHelper('mat.assetpage.overview.details.');
  return <span>{labels.get('notavailable')}</span>;
}

export function renderTimeLeft(asset, value, alertType) {
  if (value === null) {
    return renderUnavailableLabel();
  }

  let timeLeft = DateTimeHelper.ConvertMinutesToHoursMinutes(value);
  return (
    <div>
      <span>{timeLeft}</span>
      {getIcon(asset, alertType)}
      {getInFreezer(asset)}
    </div>
  );
}
