import {api as gridApi} from './orgsDashboardViewGridActions';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ORGS_DASHBOARD_VIEW_UPDATE_HEADER_DATA: 'ORGS_DASHBOARD_VIEW_UPDATE_HEADER_DATA'

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  updateHeaderData:function (payload) {
    return {type: actionTypes.ORGS_DASHBOARD_VIEW_UPDATE_HEADER_DATA, payload};
  },

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};


jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

jsxActions.fetchGridData = function (data) {
  return function (dispatch, getState) {
    dispatch(actions.updateHeaderData(data));
    gridApi.reload(dispatch, getState)();
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};


api.reload = function(dispatch, getState) {
  return function() {
    gridApi.reload(dispatch, getState)();
  }
};


/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS
