import React from 'react';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import * as AppHelper from 'infrastructure/js/utils/appHelper';
const errorIcon = require('infrastructure/assets/images/error.png');

import'./validationComponent.scss';


export default class PL_ValidationComponent extends React.PureComponent{

  render() {
    let {touched, error} =  this.props;
    if (touched && error) {

      if (AppHelper.isTouchApp()) {
        return (
          <>
            <span className="invalid-icon">
               <img src={errorIcon} alt="Validation Icon"/>
            </span>
            <span className="invalid-message">{error}</span>
          </>
        )
      }

      return (<span className="invalid-icon">
        <AlertPopover
          popoverType={'error'}
          popoverComponent={<div> {error} </div>}
        >
          <img src={errorIcon} alt="Validation Icon"/>
        </AlertPopover>
      </span>);
    }

    return null;
  }
}
