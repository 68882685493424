import {getPathByType} from '../../Common/Layout/Link/link';
import {navigationStates} from '../../../enums/navigationStates';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';

export function getLabelKeyByType(type) {
  switch (type) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('STATION'):
      return 'result.title.stations';
    case getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION'):
      return 'result.title.locations';
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return 'result.title.rolls';
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return 'result.title.spools';
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
      return 'result.title.resins';
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return 'result.title.kits';
    case getEnumValue(enumTypes.OBJECT_TYPE)('PART'):
      return 'result.title.parts';
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
      return 'result.title.workorders';
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return 'result.title.tools';
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return 'result.title.groups';
  }
  return null;
}

export function getLinkByType(type, value) {
  switch (type) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('STATION'):
      return getPathByType(navigationStates.STATION, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION'):
      return getPathByType(navigationStates.LOCATION, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return getPathByType(navigationStates.ROLL, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return getPathByType(navigationStates.SPOOL, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
      return getPathByType(navigationStates.RESIN, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return getPathByType(navigationStates.KIT, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('PART'):
      return getPathByType(navigationStates.PART, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('WO'):
      return getPathByType(navigationStates.WORKORDER, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return getPathByType(navigationStates.TOOL, value);
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return getPathByType(navigationStates.GROUP, value);

  }
  return null;
}
