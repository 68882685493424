import React from 'react';
import { getEditorDataConverter } from 'infrastructure/js/utils/editorHelper.js';

import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import DetailsColumn from '../../../Common/DetailsComponents/detailsColumn.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { isWorkOrderActivated } from '../../utils/workOrderHelper';
import NumericFieldEditor from 'infrastructure/js/components/editors/NumericFieldEditor/numericFieldEditor';
import DatepickerOverlayEditor from 'infrastructure/js/components/popover/DatepickerOverlayEditor/datepickerOverlayEditor';
import ComboBoxEditor from 'infrastructure/js/components/editors/ComboBoxEditor/comboBoxEditor';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';
import { DefaultWorkOrderTypes } from '../../../../utils/workOrderHelper';
import * as AssetDetailsHelper from '../../../AssetPage/OverviewTab/AssetDetails/utils/assetDetailsHelper';
import PermissionManager from '../../../../../../infrastructure/js/utils/permissionManager';
import { enumTypes, getLookupOptions } from '../../../../utils/enumHelper';
import TextFieldEditor from 'infrastructure/js/components/editors/TextFieldEditor/textFieldEditor';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import WorkOrdersAsyncEditor from '../../../AssetPage/Common/WorkOrdersAsyncEditor/workOrdersAsyncEditor';

let moment = require('moment');
require('./workOrderDetails.scss');

export default class WorkOrderDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.workorderpage.overview.details.');
  }

  componentDidUpdate(prevProps) {
    let workOrder = this.props.workOrder;
    let prevWorkOrder = prevProps.workOrder;

    if (workOrder && workOrder.id && prevWorkOrder && workOrder.id !== prevWorkOrder.id) {
      this.props.actions.workOrderPageEditorActions.fetchAllDataForEditor();
    }
  }

  convertEditorDataToServerData = getEditorDataConverter('workOrder');

  /*  convertEditorDataToServerData = (data) => {
    let name = data.fieldName;
    let obj = data[data.fieldName];
    let value = {};

    switch (name) {
      case 'description' :
        value = obj || null;
        break;
      case 'quantity' :
        value = obj;
        break;
      case 'dueDate' :
      case 'pickDate' :
        value = obj;
        break;
      case 'projectId' :
        if (obj === null || Array.isArray(obj)) {
          value = null;
        }
        else {
          value = obj.value;
        }
        break;
      case 'partType' :
        if (obj === null) {
          value = null;
        }
        else {
          value['id'] = obj.value;
        }
        break;
      case 'kitType' :
        if (obj === null) {
          value = null;
        }
        else {
          value['id'] = obj.value;
        }
        break;
      case 'customerOrder' :
        if (obj === null || Array.isArray(obj)) {
          value = null;
        }
        else if (obj.value === obj.label) {
          value.businessId = obj.label;
          value.id = null;
        }
        else {
          value.id = obj.value;
        }
        break;
      case 'priority' :
        value = obj ? obj.value : null;
        break;
      default:
        console.error('WorkOrderDetails: Unknown field name: ' + name);
        return undefined;
    }
    return value;
  }; */

  handleAdditionalFieldSubmit = (editorSubmitData, valueType, id) => {
    if (!editorSubmitData || !this.props.workOrderPageEditorData.additionalFields) {
      return;
    }

    let newData = AssetDetailsHelper.getAdditionalFieldValue(valueType, id, editorSubmitData);
    this.props.actions.workOrderPageEditorActions.updateWorkOrder(this.props.workOrder.id, newData);
  };

  handleEditSubmit = (editorSubmitData) => {
    if (!editorSubmitData) {
      return;
    }
    let serverData = this.convertEditorDataToServerData(editorSubmitData);
    if (serverData === undefined) {
      return;
    }
    let newData = {};
    newData[editorSubmitData.fieldName] = serverData;

    this.props.actions.workOrderPageEditorActions.updateWorkOrder(this.props.workOrder.id, newData);
  };

  getDueDatePopoverComponent = (wo) => {
    if (wo.dueDateValidationMessages && wo.dueDateValidationMessages.length) {
      return (
        <div>
          <div>{this.labels.get('duedate.alert.warning')}</div>
          {wo.dueDateValidationMessages.map((alert, index) => {
            return (
              <div key={index}>
                {createLabelHelper('').get(alert.id, undefined, alert.params || undefined)}
              </div>
            );
          })}
        </div>
      );
    }
    return <div>{this.labels.get('duedate.alert.warning')}</div>;
  };

  getAlert = (wo) => {
    if (!wo || !wo.dueDateExpired) {
      return null;
    }
    return (
      <AlertPopover
        popoverType={'warning'}
        popoverComponent={this.getDueDatePopoverComponent(wo)}
      />
    );
  };

  getPriorityPopoverComponent = (wo) => {
    return (
      <div>
        {wo.priorityValidationMessages.map((alert, index) => {
          return (
            <div key={index}>
              {createLabelHelper('').get(alert.id, undefined, alert.params || undefined)}
            </div>
          );
        })}
      </div>
    );
  };

  getPriorityAlert = (wo) => {
    if (!wo || !wo.priorityValidationMessages || !wo.priorityValidationMessages.length) {
      return null;
    }
    return (
      <AlertPopover
        popoverType={'warning'}
        popoverComponent={this.getPriorityPopoverComponent(wo)}
      />
    );
  };

  renderDescriptionEditor = (wo) => {
    return (
      <TextFieldEditor
        id="description"
        name="description"
        value={wo.description}
        handleSubmit={this.handleEditSubmit}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
        validate={Validation.maxLength(1000)}
      />
    );
  };

  renderQuantityEditor = (wo) => {
    return (
      <NumericFieldEditor
        id="quantity"
        name="quantity"
        value={wo.quantity}
        isInteger={true}
        min={0}
        max={99999}
        handleSubmit={this.handleEditSubmit}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
      />
    );
  };
  renderDueDateEditor = (wo) => {
    return (
      <DatepickerOverlayEditor
        id="dueDate"
        name="dueDate"
        value={wo.dueDate}
        placement="bottom"
        handleSubmit={this.handleEditSubmit}
        alert={this.getAlert(wo)}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
      />
    );
  };

  renderPickDateEditor = (wo) => {
    return (
      <DatepickerOverlayEditor
        id="pickDate"
        name="pickDate"
        value={wo.pickDate}
        placement="bottom"
        handleSubmit={this.handleEditSubmit}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
        minDate={moment()}
      />
    );
  };
  renderProjectEditor = (wo) => {
    return (
      <ComboBoxEditor
        id="projectId"
        name="projectId"
        value={wo.project ? wo.project.id : null}
        displayValue={wo.project ? wo.project.businessId : null}
        handleSubmit={this.handleEditSubmit}
        parse={Parse.comboValueOnly()}
        format={Format.findOptionByValue(
          this.props.workOrderPageEditorData.optionsWorkOrderProjects
        )}
        options={this.props.workOrderPageEditorData.optionsWorkOrderProjects}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
      />
    );
  };
  renderPartEditor = (wo) => {
    return (
      <ComboBoxEditor
        id="partType"
        name="partType"
        value={wo.partType ? wo.partType.id : null}
        displayValue={wo.partType ? wo.partType.businessId : null}
        handleSubmit={this.handleEditSubmit}
        options={this.props.workOrderPageEditorData.optionsWorkOrderParts}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
      />
    );
  };

  renderOrderEditor = (wo) => {
    return (
      <ComboBoxEditor
        id="customerOrder"
        name="customerOrder"
        value={wo.customerOrder ? wo.customerOrder.id : null}
        displayValue={wo.customerOrder ? wo.customerOrder.businessId : null}
        handleSubmit={this.handleEditSubmit}
        options={this.props.workOrderPageEditorData.optionsWorkOrderOrders}
        allowNewOption={true}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
      />
    );
  };

  renderPriorityEditor = (wo) => {
    let match = getLookupOptions(enumTypes.PRIORITY_TYPE).find((x) => x.value == wo.priority);
    return (
      <ComboBoxEditor
        id="priority"
        name="priority"
        value={
          wo.priority
            ? getLookupOptions(enumTypes.PRIORITY_TYPE).find((x) => x.value == wo.priority)
            : null
        }
        displayValue={match ? match.label : null}
        handleSubmit={this.handleEditSubmit}
        options={getLookupOptions(enumTypes.PRIORITY_TYPE)}
        alert={this.getPriorityAlert(wo)}
        editable={isWorkOrderActivated(wo.workOrderStatus)}
      />
    );
  };

  renderFieldByDefaultWorkOrderTypes = (wo, labels) => {
    if (wo.workOrderType === DefaultWorkOrderTypes.PARTTYPE_ORIENTED) {
      return {
        label: labels.get('part'),
        body: this.renderPartEditor(wo),
      };
    } else if (wo.workOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED) {
      return {
        label: labels.get('kittype'),
        body: <span>{wo?.kitType?.businessId ?? ''}</span>,
      };
    } else {
      console.error(
        'error in renderFieldByDefaultWorkOrderTypes: invalid type: ' + wo.workOrderType
      );
      return null;
    }
  };

  renderStartAfterEditor = (wo) => {
    return (
      <WorkOrdersAsyncEditor
        id="startAfterWorkOrders"
        name="startAfterWorkOrders"
        asset={wo}
        onSubmit={this.handleEditSubmit}
        disabled={!isWorkOrderActivated(wo.workOrderStatus)}
        parse={(data, name) => ({
          fieldName: name,
          startAfterWorkOrders: data.map(({ value }) => value),
        })}
      />
    );
  };

  getWorkOrderDetails(wo, labels) {
    let index0 = [
      {
        label: labels.get('project'),
        body: this.renderProjectEditor(wo),
      },
      {
        label: labels.get('createdDate'),
        body: (
          <span id="create-date-input">
            {DateTimeHelper.FormatDateObjectToDayMonth(wo.createdDate)}
          </span>
        ),
      },
      {
        label: labels.get('createdby'),
        body: <span id="create-by-input">{wo.createdByUser}</span>,
      },
      {
        label: labels.get('description'),
        body: this.renderDescriptionEditor(wo),
      },
      {
        label: labels.get('startAfter'),
        body: this.renderStartAfterEditor(wo),
      },
    ];

    let index1 = [
      this.renderFieldByDefaultWorkOrderTypes(wo, labels),
      {
        label: labels.get('quantity'),
        body: this.renderQuantityEditor(wo),
      },
      {
        label: labels.get('operationscompleted'),
        body: <span id="operations-completed-input">{wo.operationsCompleted}</span>,
      },
      {
        label: labels.get('priority'),
        body: this.renderPriorityEditor(wo),
      },
    ];

    let index2 = [
      {
        label: labels.get('customerorder'),
        body: this.renderOrderEditor(wo),
      },
      {
        label: labels.get('duedate'),
        body: this.renderDueDateEditor(wo),
      },

      ...(PermissionManager.getOrgPreferences().woPickDateSupported
        ? [
            {
              label: labels.get('pickDate'),
              body: this.renderPickDateEditor(wo),
            },
          ]
        : []),
      {
        label: labels.get('lastoperation'),
        body: (
          <span id="last-operation-input">
            {wo.lastProductionOperation ? wo.lastProductionOperation.operationReported : ''}
          </span>
        ),
      },
      //to keep proper layout when 'pickDate' is not displayed (not supported)
      ...(!PermissionManager.getOrgPreferences().woPickDateSupported
        ? [
            {
              label: '',
              body: null,
            },
          ]
        : []),
    ];

    let result = [index0, index1, index2];

    let additionalFields = this.props.workOrderPageEditorData.additionalFields
      ? this.props.workOrderPageEditorData.additionalFields
      : [];
    if (additionalFields && additionalFields.length > 0) {
      index0.push({ type: 'hr', body: <hr /> });
      index1.push({ type: 'hr', body: <hr /> });
      index2.push({ type: 'hr', body: <hr /> });
    }
    AssetDetailsHelper.addAdditionalFields(
      result,
      wo,
      this.handleAdditionalFieldSubmit,
      additionalFields
    );
    return result;
  }

  render() {
    let { workOrder } = this.props;
    if (!workOrder || !workOrder.id) {
      return null;
    }

    let columnsData = this.getWorkOrderDetails(workOrder, this.labels);

    let columns = columnsData.map((column, index) => {
      return <DetailsColumn key={'wo' + index} rows={column} />;
    });

    return (
      <div className="workorder-details">
        <div className="workorder-details-section">{columns}</div>
      </div>
    );
  }
}
