import { Map } from 'immutable';
import defaultDialogReducer from '../../defaultDialogReducer';
import {dialogsNames} from '../../../enums/dialogsNames';
import { actionTypes as actionTypes } from '../../../actions/Administration/SettingsPage/Dialogs/misplacedMaterialAlertRuleDialogActions';

let defaultState = {
  show: false,
  loading: false,
  itemToEdit: null,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case actionTypes.MISPLACED_MATERIAL_ALERT_RULE_DIALOG_SHOW:
      state = state.set('itemToEdit', action.payload);
      return state.set('show',true);
    default:
      return defaultDialogReducer(state, action, dialogsNames.MISPLACED_MATERIAL_ALERT_RULE_DIALOG, defaultState);
  }
}