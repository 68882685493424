// @ts-check

import { useEffect } from "react";

/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageProps} LiveMapPageProps
 * 
 * @typedef {import("@mappedin/mappedin-js").TShowVenueOptions} TShowVenueOptions
 * @typedef {import("@mappedin/mappedin-js").TGetVenueOptions} TGetVenueOptions
 * 
*/



/**
 * @param {LiveMapPageProps} props
 * @param {string} mapId
 * 
 */
export default function useFetchMapSettings(props, mapId) {

  let actions = props.actions;

  useEffect(() => {
    // console.log(`Loading Map settings for map ${mapId}.`);
    actions.fetchMapSettings(mapId);

  }, [mapId]);

}





