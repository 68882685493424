import Network from 'infrastructure/js/modules/network';
import * as toolsService from '../../../../services/Tools/toolsService';
import * as daHelper from '../../../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../../../enums/dialogsNames';
import * as kitTypesService from '../../../../services/KitTypes/kitTypesService';
import uniqBy from 'lodash-es/uniqBy';

const config = {
  getSubmitMethod: (submitConfigData) => {
    return submitConfigData && submitConfigData.isEditMode ?
      toolsService.updateToolGroup : toolsService.createToolGroup;
  }
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_TOOL_GROUP_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_TOOL_GROUP_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function () {

      let promise1 = toolsService.fetchActiveToolTypes();
      let promise2 = toolsService.getActiveToolCategories();
      let promise3 = kitTypesService.fetchActiveKitTypes();
      let promise4 = toolsService.fetchToolGroupsNames();

      return Promise.all([promise1, promise2, promise3, promise4]).then((allResults) => {
        let isResultValid = allResults.every((result) => {
          return Network.isResponseValid(result);
        });
        if (!isResultValid) {
          console.error('Get Tool Groups initial data failed.');
          return {success: false};
        }

        let toolTypes = allResults[0].dataList || [];
        let toolCategories = allResults[1].dataList || [];
        let kitTypes = allResults[2].dataList || [];
        let toolGroupsNames = allResults[3].data?.names || [];


        let dialogData = {
          toolTypes: toolTypes.map((obj) => {
            return {value: obj.id, label: obj.businessId, data: obj}
          }),
          toolCategories: toolCategories.map((obj) => {
            return {value: obj.id, label: obj.businessId, data: obj}
          }),
          kitTypes: kitTypes.map((obj) => {
            return {value: obj.id, label: obj.businessId, data: obj}
          }),
          toolGroupsNames: toolGroupsNames?.map((obj, index) => {
            return {value: obj + index, label: obj}
          })
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
        return dialogData;
      });
    }
  }
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),
};


