import { actionTypes as actionTypes } from '../../../actions/Reports/managerViewPageActions';
import {Map} from 'immutable';

let defaultState = {
  treeData: null,
  viewData: null,
  loading: false,
};

export default function(state = Map(defaultState), action) {
  switch(action.type){
    case actionTypes.MANAGER_VIEW_FETCH_TREE_DATA_FINISHED:
      return state.set('treeData', action.payload);

    case actionTypes.MANAGER_VIEW_FETCH_LOCATIONS_DATA_FINISHED:
      return state.set('viewData', action.payload);

    case actionTypes.MANAGER_VIEW_SET_LOADING:
      return state.set('loading', action.payload);

    case actionTypes.MANAGER_VIEW_UNMOUNT:
      return Map(defaultState)

    default:
      return state;
  }
}


