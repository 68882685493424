import React from 'react';
import PropTypes from 'prop-types';

import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import EditKitMaterialListItem from '../../../Common/Controls/AddRemoveList/AddRemoveListItem/EditKitMaterialListItem/editKitMaterialListItem';
import MaterialHelper from '../../../../utils/materialHelper';
import List from 'infrastructure/js/components/List/list';

import './editKitMaterialsDialog.scss';

class EditKitMaterialsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.assetpage.updatekitmaterialsdialog.');

    this.itemToEdit = this.props.sData.get('itemToEdit');
  }

  componentDidMount() {
    let initialValues = {materials: this.getInitialMaterials(this.itemToEdit.kittedMaterials)};

    this.props.initialize(initialValues);
  }

  getInitialMaterials = (materials) => {  //TODO: L LIST move it into EditKitMaterialListItem as a static function?
    if (materials) {
      return materials.map((item) => {

        let currentMaterial = item.isSubstituteMaterial ? item.substituteMaterial : item.mainMaterial;
        let material =
          { value: currentMaterial.id ? currentMaterial.id : '',
            label: (currentMaterial.materialName || currentMaterial.businessId ) ? `${MaterialHelper.getMaterialFullLabel(currentMaterial.materialName, currentMaterial.businessId)}` : '',
            kitMaterialId: item.id
          };
        return (
          {
            materialName: material,
            quantity: item.quantityExpectedPlies,
            kitted: item.quantityKittedPlies
          }
        );
      });
    }
    return [];
  };

  getDialogButtons() {
    return {
      left: [
        {
          id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.props.actions.hide
        }
      ],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.update'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading')
        }
      ]
    };
  }

  getRequiredMaterials = (materials) => {
    if (materials) {
      return materials.map((item, index) => {
        let res = {
          kittedMaterialId: item.materialName.kitMaterialId,
          quantityKittedPlies: item.kitted,
        };

        return res;
      })
    }
    return [];
  };

  onSubmit = (data) => {

    let newData = {
      kitId: this.itemToEdit.kitId,
      kittedMaterials: this.getRequiredMaterials(data.materials),
    };

    this.props.actions.submit(newData, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  };

  getAvailableOptions() {
    let dialogData = this.props.sData.get('dialogData');
    if (!dialogData || !dialogData.materials) {
      return [];
    }
    return dialogData.materials;
  }

  itemRenderer = (data) => {
    return (
      <EditKitMaterialListItem
        {...data}
        options={this.getAvailableOptions()}
        {...this.props}
        />
    )
  };

  renderMaterialsList = () => {
    return(
      <div>
        <div className="list-materials-title input-section">
          <label className="column">{this.labels.get('requiredmaterials')}</label>
          <label className="column">{this.labels.get('expectedplies')}</label>
          <label className="column">{this.labels.get('kittedplies')+'*'}</label>
        </div>

        <List name='materials'
                       isViewOnly={true}
                       itemRenderer={this.itemRenderer}
                       preSelectedItems={this.itemToEdit?.kittedMaterials || []}
        />
      </div>
    )
  };


  render() {
    let titleText = this.labels.get('header.title');

    return (
      <Dialog
        id="edit-kit-materials-dialog"
        className="edit-kit-materials-dialog"
        titleText={titleText}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.onHide}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        {this.renderMaterialsList()}

      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'editKitMaterialsDialog',
  }
)(EditKitMaterialsDialog);

EditKitMaterialsDialog.defaultProps = {
};
EditKitMaterialsDialog.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
};
