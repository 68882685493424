import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper.js';
import { jsxActions as viewActions } from '../../../../actions/Administration/MatSettingsPage/digitalTwinViewActions.js';
import { jsxActions as matSettingsPageActions } from '../../../../actions/Administration/MatSettingsPage/matSettingsPageActions.js';
import {MatSettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import Search from 'infrastructure/js/components/Search/search';
import DigitalTwinViewTree from './DigitalTwinViewTree/digitalTwinViewTree';
import StationSettingsForm from './StationSettingsForm/stationSettingsForm';
import SectionSettingsForm from './SectionSettingsForm/sectionSettingsForm';
import LocationSettingsForm from './LocationSettingsForm/locationSettingsForm';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import SplitPanel from 'infrastructure/js/components/SplitPanel/splitPanel';

import './digitalTwinView.scss';

const getState = (state) => {
  const viewState =  state.administration.getIn(['matSettingsPage', 'digitalTwinView']);

  return {
    treeData: viewState.get('treeData') ?? [],
    viewData: viewState.get('viewData') ?? [],
    loading: viewState.get('loading') ?? false,
  };
};

const allActions = {
  matSettingsPageActions: matSettingsPageActions,
  viewActions: viewActions,
};

const filterBy = (array, searchText) => {
  return searchText ? array?.reduce((acc, item) => {
    if( item.children?.length ) {
      const filtered = filterBy(item.children, searchText);

      if( filtered?.length ) {
        return [...acc, {...item, children: filtered}]
      }
    }

    const {children, ...itemWithoutChildren} = item;
    return item.name?.toLowerCase().includes(searchText.toLowerCase()) ? [...acc, itemWithoutChildren] : acc
  },[]) : array;
}

const convertOperations = (operations) => {
  return operations?.map((op) => {
    return {
      operationId: op.id,
      isPrimary: op.primary,
      position: op.position,
      displayName: op.displayName,
    }
  }) || [];
}

const convertTreeItem = (item) => {
  if (item?.isSection) {
    let {id, name} = item;
    return {id, name};
  }
  if (item?.isStation) {  //station
    let {id, name, locationType, automationType, isOutsourced, capacity, isResource, jointStartTime, isOvertimeEnabled, maxOvertimeValue, operations} = item;
    return {id, name, locationType, automationType, isOutsourced, capacity, isResource, jointStartTime, maxOvertimeValue,
      overtimeEnabled: isOvertimeEnabled,
      stationOperations: convertOperations(operations) };
  }
  if (!item?.isStation) { //location
    let {id, name, locationType, etlDecayRate, isUsedForSmartSelection} = item;
    return {id, name, locationType, etlDecayRate, isUsedForSmartSelection};
  }
  return {};
}


const DigitalTwinView = () => {
  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.digitalTwin.view.'), []);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [dummyKey, setDummyKey] = useState(new Date().getTime());

  const {state, actions} = useRedux(allActions, getState);
  const {treeData = null, viewData = null, loading} = state;

  useEffect(() => {
    actions.viewActions.init();
    actions.matSettingsPageActions.onSelectedMenuItemChanged(MatSettingsMenuItemsIds.NAV_ELEMENT_DIGITAL_TWIN);
    return () => actions.viewActions.unmount();
  }, []);

  useEffect(() => {
    setDummyKey(new Date().getTime());
  }, [searchTerm]);

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const onSearchClear = () => {
    setSearchTerm('');
  };

  const onTreeItemSelect = (item) => {
    setCurrentItem(item || null);
  }

  const onTreeItemDrop = (e, item, dropData) => {
    setCurrentItem(item || null);

    let data = {...convertTreeItem(item), ...dropData};

    // console.log('---DropData: id parentId prevId nextId ', item.id, dropData.parentId, dropData.prevSiblingId, dropData.nextSiblingId );

    actions?.viewActions?.move(data, true, item.isStation, item.isSection)
      .then((response) => {
        if (response && response.success) {
          actions.viewActions.reload();
        }
      });
  }

  const onTreeItemSave = (data) => {
    actions.viewActions.reload();
    setCurrentItem(data || null);
  }

  const onTreeItemDelete = () => {
    actions.viewActions.reload();
    setCurrentItem( null);
  }
  const onTreeItemCreate = (data) => {
    setCurrentItem( data);
  }

  const renderDigitalTwinItemForm = () => {
    let ItemForm = currentItem?.isSection ? SectionSettingsForm : currentItem?.isStation ? StationSettingsForm : LocationSettingsForm;
    if (currentItem) {
      return <ItemForm itemToEdit={currentItem} actions={actions}
                                 sData={viewData}
                                 onSave={onTreeItemSave}
                                 onDelete={onTreeItemDelete}/>
    }
    if (!currentItem && !loading) {
      return <Overlay.Label text={labels.get('noItem')} />
    }
  }

  const getFirstPaneOverflowSize = () => {
    return 340;
  };

  const filteredTreeData = filterBy(treeData, searchTerm);

  return (
    <div className="administration-view">
      <div className="title">{labels.get('title')}</div>
      <div className="digital-twin-view">

        <SplitPanel className='scheduler-panel' direction={'horizontal'} firstPaneMaxOverflowSize={getFirstPaneOverflowSize()} firstPaneInitialSize={340}>
          <div className="digital-twin-view__tree">
            <Search value={searchTerm} onChange={onSearchChange} onClear={onSearchClear}/>
            <DigitalTwinViewTree  key={dummyKey}
                   labels={labels}
                   treeData={filteredTreeData}
                   onSelect={onTreeItemSelect}
                   onDrop={onTreeItemDrop}
                   selectedItem={currentItem}
                   defaultExpandAll={true}
                   itemActions={{createItem: onTreeItemCreate}}
            />
          </div>

          <div className="digital-twin-view__details">
            {renderDigitalTwinItemForm()}
          </div>
        </SplitPanel>
        {loading ? <Overlay.Loading /> : null}
      </div>

    </div>
  );
}

DigitalTwinView.propTypes = {
};

export default DigitalTwinView;
