import {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import useLabels from 'infrastructure/js/hooks/useLabels';
import DemoOrganizationsGrid from './DemoOrganizationsGrid/demoOrganizationsGrid';
import CreateOrganizationDialog from './Dialogs/CreateOrganizationDialog/createOrganizationDialog';
import CreateDemoOrganizationDialog from './Dialogs/CreateDemoOrganizationDialog/createDemoOrganizationDialog';
import deleteDemoOrganizationDialog from './Dialogs/DeleteDemoOrganizationDialog/deleteDemoOrganizationDialog';
import Header, { headerItemsLabels } from '../../Common/Header/header';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';

import './demoOrganizationsPage.scss';

export default function DemoOrganizationsPage({
  sData,
  actions,
  sHasRows,
  sSelectedRowsData={},
  sCreateOrganizationDialogData,
  sCreateDemoOrganizationDialogData,
  sLoggedInUser,
  sDeleteDemoOrganizationDialogData,
}) {

  const labels = useLabels('mat.organizations.demoOrgs.view.');

  useEffect(() => {
    actions.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTitle = useCallback(() => {
    return (
      <span className='title'>
        <span className='title-label'>{labels.get('header.baselineOrg')}</span>
        <span >{sData?.get('baselineOrgName') || labels.get('header.notFound')}</span>
        <span className='title-label'>{labels.get('header.zeroTime')}</span>
        <span >{sData?.get('zeroTime') || labels.get('header.notAvailable')}</span>
      </span>
    )
  }, [sData]);

  const headerItems = {
    title: getTitle(),
    buttons: [
      {
        id: 'delete',
        label: headerItemsLabels.DELETE,
        icon: 'pl pl-delete-icon',
        disabled: !sHasRows || !sSelectedRowsData || sSelectedRowsData.size === 0,
        action: actions.header.onDeleteDemoOrganizationClick,
        actionData: sSelectedRowsData,
      },
      {
        id: 'edit',
        label: headerItemsLabels.EDIT_ORG,
        icon: 'pl pl-edit-icon',
        disabled: !(sHasRows && sSelectedRowsData.size === 1),
        action: actions.header.onEditOrganizationClick,
        actionData: sSelectedRowsData.get(0),
      },
      {
        id: 'create',
        label: headerItemsLabels.CREATE_ORG,
        icon: 'pl pl-icon-add',
        action: actions.header.onCreateDemoOrganizationClick,
      },
    ],
  };

  const reloadPage = () => {
    actions.reload();
  };

  return (
    <div className="demo-org-management-page">
      <Header {...headerItems} />
      <DemoOrganizationsGrid actions={actions} />

      <PL_DialogWrapper
        dialogComponent={CreateDemoOrganizationDialog}
        show={sCreateDemoOrganizationDialogData.get('show')}
        actions={actions.createDemoOrganizationDialogActions}
        sData={sCreateDemoOrganizationDialogData}
        reloadParentComponent={reloadPage}
        sLoggedInUser={sLoggedInUser}
      />

      <PL_DialogWrapper
        dialogComponent={CreateOrganizationDialog}
        show={sCreateOrganizationDialogData.get('show')}
        actions={actions.editDemoOrganizationDialogActions}
        sData={sCreateOrganizationDialogData}
        reloadParentComponent={reloadPage}
        sLoggedInUser={sLoggedInUser}
      />

      <PL_DialogWrapper
        dialogComponent={deleteDemoOrganizationDialog}
        show={sDeleteDemoOrganizationDialogData.get('show')}
        actions={actions.deleteDemoOrganizationDialogActions}
        reloadParentComponent={reloadPage}
        sData={sDeleteDemoOrganizationDialogData}
      />
    </div>
  );
}

DemoOrganizationsPage.propTypes = {
  sData: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
  sCreateOrganizationDialogData: PropTypes.object.isRequired,
  sCreateDemoOrganizationDialogData: PropTypes.object.isRequired,
  sLoggedInUser: PropTypes.object.isRequired,
  sDeleteDemoOrganizationDialogData: PropTypes.object.isRequired,
};
