import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import GridRow from './GridRow/gridRow';
import GridHeadersRow from './GridHeadersRow/GridHeadersRow';
import GridColumnHeaderCell from './GridColumnHeaderCell/gridColumnHeaderCell';
import { useElementSelectionContext } from 'infrastructure/js/contexts/elementSelectionContext';
import useDeleteKeyPress from 'infrastructure/js/hooks/useDeleteKeyPress';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { filterTypes } from '../../../../enums/shiftSchedulerEnums';

import './shiftsScheduler.scss';

const checkIsDroppable = (dateDroppedOn, shiftStartTime) => {
  if (!dateDroppedOn || !shiftStartTime) {
    return true;
  }

  const [hours, minutes] = shiftStartTime.split(':');

  const shiftDate = moment(dateDroppedOn).hour(Number(hours)).minute(Number(minutes));
  const now = moment();
  return shiftDate.isAfter(now);
};

export default function ShiftsScheduler({ columnsConfig=[],
                                          data=[],
                                          actions={},
                                          isTemplate=false,
                                        }) {
  const labels = useMemo(() => createLabelHelper('mat.administration.matsettings.shiftsScheduler.'), []);

  const [highlighted, setHighlighted] = useState(null);

  const { selected, setSelected } = useElementSelectionContext();

  const deletKeyPressHandler = () => {
    if (selected.length) {
      setSelected([]);
      const filterData = {
        filterBy: filterTypes.SELECTED,
      };

      actions.removeShifts({
        filterData,
        assignments: selected.map((item) => item.data),
      });
    }
  };

  useDeleteKeyPress(deletKeyPressHandler);

  const gridRows = useMemo(
    () =>
      data?.map((rowData) => {
        return (
          <GridRow
            key={rowData.id}
            rowData={rowData}
            actions={actions}
            isTemplate={isTemplate}
            checkIsDroppable={checkIsDroppable}
            highlighted={highlighted}
            setHighlighted={setHighlighted}
          >
            {columnsConfig.map((column, i) => {
              return (
                <column.cellComponent
                  key={column.id + '-' + i}
                  data={column.valueGetter(rowData)}
                  isTemplate={isTemplate}
                  actions={actions}
                  checkIsDroppable={checkIsDroppable}
                  highlighted={highlighted}
                  setHighlighted={setHighlighted}
                />
              );
            })}
          </GridRow>
        );
      }),
    [data, isTemplate, actions, columnsConfig, highlighted]
  );

  return (
    <div className="shift-scheduler-grid-container">
      <GridHeadersRow
        actions={actions}
        isTemplate={isTemplate}
        cornerLabel={labels.get('cornerTitle')}
        highlighted={highlighted}
        setHighlighted={setHighlighted}
      >
        {columnsConfig.map((column, i) => {
          return (
            <GridColumnHeaderCell
              key={column.id + '-' + i}
              actions={actions}
              headerData={column.headerData}
              isTemplate={isTemplate}
              checkIsDroppable={checkIsDroppable}
              highlighted={highlighted}
              setHighlighted={setHighlighted}
            >
              {column.title}
            </GridColumnHeaderCell>
          );
        })}
      </GridHeadersRow>
      {gridRows}
    </div>
  );
}

ShiftsScheduler.propTypes = {
  data: PropTypes.array,
  columnsConfig: PropTypes.array,
  actions: PropTypes.object,
  isTemplate: PropTypes.bool,
};
