import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {activityIcons} from '../../assets/svg/index';

export function GetActivityTypeDetails(activityType, activityTypeDisplayKey='') {
  if (!activityType) {
    return {icon: null, description: ''};
  }
  const labels = createLabelHelper('mat.locationpage.view.activities.grid.columns.type.filter.');

  let description = activityTypeDisplayKey ? labels.get(activityTypeDisplayKey) : 'X_NOT_FOUND';
  let icon = _getActivityTypeIcon(activityType);

  return {icon, description};
}

export function getActivityTypeDescription(activityTypeDisplayKey='') {
  const labels = createLabelHelper('mat.locationpage.view.activities.grid.columns.type.filter.');
  return activityTypeDisplayKey ? labels.get(activityTypeDisplayKey) : 'X_NOT_FOUND';
}

export function getActivityTypeIcon(activityType) {
  switch(activityType) {
    case 'Location Change':
      // return 'pl pl-move-arrow-right';
      return activityIcons.activityLocationChange;
    case 'Kit Creation':
      return  'pl pl-kit2-icon';
      // return  activityIcons.activityCreateKit;
    case 'Issue':
      // return 'pl pl-error-icon';
      return activityIcons.activityIssue;
    case 'Shelf Life Update':
      return 'pl pl-shelf-life';
      // return activityIcons.activityShelfLife;
    case 'Status Update':
    case 'Details Update':
    case 'Failed Details Update':
      // return 'pl pl-update-icon';
      return activityIcons.activityStatusUpdate;

    case 'Roll Creation':
    case 'Sub Rolls Creation':
      return 'pl pl-roll-icon-blue';
      // return activityIcons.activityCreateRoll;

    case 'Spool Creation':
      return 'pl pl-header-icon-spool';
      // return activityIcons.activityCreateSpool;

    case 'Resin Creation':
      return 'pl pl-header-icon-resin';

    case 'Group Creation':
      return 'pl pl-header-icon-container';
      // return activityIcons.activityCreateGroup

    case 'Kit Reported Complete':
    case 'WO Reported Complete':
      return 'pl pl-check-circle-icon-blue';
      // return activityIcons.activityReportCompleted;

    case 'Resolved Issue':
      return 'pl pl-issue-resolved';
      // return activityIcons.activityIssueResolved;
    case 'Operation Reported':
    case 'Component Asset Added':
    case 'Resulting Asset Added':
    case 'Asset Attached':
    case 'Asset Detached':
      return 'pl pl-note-icon-blue';
      // return activityIcons.activityAssetsDetached;
    case 'Operation Canceled':
      return 'pl pl-operation-canceled';
      // return activityIcons.activityOperationCanceled;
    case 'Asset Added':
    case 'WO Added':
      // return 'pl pl-added';
      return activityIcons.activityAssetAdded;
    case 'Asset Removed':
      return 'pl pl-removed';
      // return activityIcons.activityAssetRemoved;

    case 'WO Creation':
      return 'pl pl-header-icon-work_order';
      // return activityIcons.activityCreateWo;
    case 'Tool Creation':
      return 'pl pl-menu-active-header-tool';
      // return activityIcons.activityCreateTool;
    case 'Maintenance Reporting':
      return 'pl pl-report-maintenance';
      // return activityIcons.activityReportMaintenance;
    case 'Shipment Creation':
      return activityIcons.activityShipmentCreation;
    case 'Ready To Ship':
      return activityIcons.activityShipmentReadyToShip;
    case 'Shipment Deletion':
      return activityIcons.activityShipmentDeletion;
    case 'Shipment Outgoing':
      return activityIcons.activityShipmentOutgoing;
    case 'Shipment Incoming':
      return activityIcons.activityShipmentIncoming;
    case 'Shipment Temperature Log Processed':
      return 'pl pl-shelf-life';  //TODO: L ASN temp icon
    case 'Shipment Accepted By ERP':
      return 'pl pl-shelf-life';  //TODO: L ASN temp icon

    default:
      return 'pl ';
  }
}

function _getActivityTypeIcon(activityType) {
  switch(activityType) {
    case 'Location Change':
      return 'pl pl-move-arrow-right';
    case 'Kit Creation':
      return  'pl pl-kit2-icon';
    case 'Issue':
      return 'pl pl-error-icon';
    case 'Shelf Life Update':
      return 'pl pl-shelf-life';

    case 'Status Update':
    case 'Details Update':
    case 'Failed Details Update':
      return 'pl pl-update-icon';

    case 'Roll Creation':
    case 'Sub Rolls Creation':
      return 'pl pl-roll-icon-blue';

    case 'Spool Creation':
      return 'pl pl-header-icon-spool';
    case 'Resin Creation':
      return 'pl pl-header-icon-resin';

    case 'Group Creation':
      return 'pl pl-header-icon-container';

    case 'Kit Reported Complete':
    case 'WO Reported Complete':
      return 'pl pl-check-circle-icon-blue';

    case 'Resolved Issue':
      return 'pl pl-issue-resolved';
    case 'Operation Reported':
    case 'Component Asset Added':
    case 'Resulting Asset Added':
    case 'Asset Attached':
    case 'Asset Detached':
      return 'pl pl-note-icon-blue';
    case 'Operation Canceled':
      return 'pl pl-operation-canceled';
    case 'Asset Added':
    case 'WO Added':
      return 'pl pl-added';
    case 'Asset Removed':
      return 'pl pl-removed';


    case 'WO Creation':
      return 'pl pl-header-icon-work_order';
    case 'Tool Creation':
      return 'pl pl-menu-active-header-tool';
    case 'Maintenance Reporting':
      return 'pl pl-report-maintenance';
    case 'Shipment Creation':
    case 'Ready To Ship':
    case 'Shipment Deletion':
    case 'Shipment Outgoing':
    case 'Shipment Incoming':
      // return 'pl pl-shipment';   //TODO: L ASN - add icons for shipments activities
      return '';

    default:
      return 'pl ';
  }
}

export function ActivityJobNameToTitle(activityJobName, numberOfItems, date) {
  let result = '';

  const labels = createLabelHelper('mat.activitylogdialog.header.');

  switch(activityJobName) {
    case 'moveAssetsJob':
      return labels.get('moveAssetsJob.title', '', {count: numberOfItems, date});

    case 'updateAssetsStatusJob_active':
    case 'updateAssetsStatusJob_completed':
    case 'updateAssetsStatusJob_in_progress':
      return labels.get('updateAssetsStatusJob.activate.title', '', {count: numberOfItems, date});

    case 'updateAssetsStatusJob_consumed':
    case 'updateAssetsStatusJob_disqualified':
    case 'updateAssetsStatusJob_shipped':
    case 'updateAssetsStatusJob_canceled':
    case 'updateAssetsStatusJob_retired':
      return labels.get('updateAssetsStatusJob.archive.title', '', {count: numberOfItems, date});

    case 'updateWorkOrdersStatusJob_completed':
      return labels.get('updateWorkOrdersStatusJob.completed.title', '', {count: numberOfItems, date});

    case 'updateWorkOrdersStatusJob_disqualified':
    case 'updateWorkOrdersStatusJob_canceled':
      return labels.get('updateWorkOrdersStatusJob.archive.title', '', {count: numberOfItems, date});

    case 'updateWorkOrdersStatusJob_active':
    case 'updateWorkOrdersStatusJob_in_progress':
      return labels.get('updateWorkOrdersStatusJob.activate.title', '', {count: numberOfItems, date});

    case 'updateTasksProgressJob_completed':
    case 'updateTasksProgressJob_started':
      return labels.get('updateTasksProgressJob.title', '', {count: numberOfItems, date});
    case 'updateRollsJob':
    case 'updateKitsJob':
    case 'updateToolsJob':
      return labels.get('updateAssetJob.title', '', {count: numberOfItems, date});
    case 'updateWorkOrdersJob':
      return labels.get('updateWorkOrdersJob.title', '', {count: numberOfItems, date});
    case 'updateShelfLifeJob':
      return labels.get('updateShelfLifeJob.title', '', {count: numberOfItems, date});

    case 'createHooksJob':
      return labels.get('createHooksJob.title', '', {count: numberOfItems, date});

  }

  return result;
}


//-----------------------------------------------
//     move-arrow-right'            activity-arrow-right
//     kit2-icon'                   activity-create-kit
//     error-icon'                  activity-issue
//     shelf-life'                  activity-shelf-life
//     update-icon'                 activity-status-update'
//     roll-icon-blue'              activity-create-roll
//     header-icon-spool'           activity-create-spool             V
//     header-icon-container'       activity-create-group
//     check-circle-icon-blue'      activity-report-wo-completed
//     issue-resolved'              activity-issue-resolved
//     note-icon-blue'              activity-asset-detached
//     operation-canceled'          activity-operation-canceled
//     added'                       activity-asset-added
//     removed'                     activity-asset-removed
//     header-icon-work_order'      activity-create-wo
//     menu-active-header-tool'     activity-create-tool
//     report-maintenance'          activity-report-maintenance
//-----------------------------------------------

//TODO: L - this method must be used when all new activity icons are ready

// export function getActivityTypeIconNEW(activityType) {
//   switch(activityType) {
//     case 'Location Change':
//       // return 'pl pl-move-arrow-right';
//       return activityIcons.activityLocationChange;
//     case 'Kit Creation':
//       // return  'pl pl-kit2-icon';
//       return  activityIcons.activityCreateKit;
//     case 'Issue':
//       // return 'pl pl-error-icon';
//       return activityIcons.activityIssue;
//     case 'Shelf Life Update':
//       // return 'pl pl-shelf-life';
//       return activityIcons.activityShelfLife;
//     case 'Status Update':
//     case 'Details Update':
//     case 'Failed Details Update':
//       // return 'pl pl-update-icon';
//       return activityIcons.activityStatusUpdate;
//
//     case 'Roll Creation':
//     case 'Sub Rolls Creation':
//       return activityIcons.activityCreateRoll;
//
//     case 'Spool Creation':
//       // return 'pl pl-header-icon-spool';
//       return activityIcons.activityCreateSpool;
//
//     case 'Group Creation':
//       // return 'pl pl-header-icon-container';
//       return activityIcons.activityCreateGroup
//
//     case 'Kit Reported Complete':
//     case 'WO Reported Complete':
//       // return 'pl pl-check-circle-icon-blue';
//       return activityIcons.activityReportCompleted;
//
//     case 'Resolved Issue':
//       // return 'pl pl-issue-resolved';
//       return activityIcons.activityIssueResolved;
//     case 'Operation Reported':
//     case 'Component Asset Added':
//     case 'Resulting Asset Added':
//     case 'Asset Attached':
//     case 'Asset Detached':
//       // return 'pl pl-note-icon-blue';
//       return activityIcons.activityAssetsDetached;
//     case 'Operation Canceled':
//       // return 'pl pl-operation-canceled';
//       return activityIcons.activityOperationCanceled;
//     case 'Asset Added':
//     case 'WO Added':
//       // return 'pl pl-added';
//       return activityIcons.activityAssetAdded;
//     case 'Asset Removed':
//       // return 'pl pl-removed';
//       return activityIcons.activityAssetRemoved;
//
//
//     case 'WO Creation':
//       // return 'pl pl-header-icon-work_order';
//       return activityIcons.activityCreateWo;
//     case 'Tool Creation':
//       // return 'pl pl-menu-active-header-tool';
//       return activityIcons.activityCreateTool;
//     case 'Maintenance Reporting':
//       // return 'pl pl-report-maintenance';
//       return activityIcons.activityReportMaintenance;
//
//     case 'Shipment Creation':
//       return activityIcons.activityShipmentCreation;
//     case 'Shipment Confirmed':
//       return activityIcons.activityShipmentConfirmed;
//     case 'Shipment Deletion':
//       return activityIcons.activityShipmentDeletion;
//     case 'Shipment Outgoing':
//       return activityIcons.activityShipmentOutgoing;
//     case 'Shipment Incoming':
//       return activityIcons.activityShipmentIncoming;
//
//     default:
//       return 'pl ';
//   }
// }
