import React from 'react';
import PropTypes from 'prop-types';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';
import EntitiesSelect from '../../../../Wizards/CutAndKit/Steps/KitToWorkOrderSubcomponents/entitiesSelect';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import Label from 'infrastructure/js/components/Label/label';
import './emailListItem.scss';

const ComboboxPortal = withPortal(EntitiesSelect);

export default class EmailListItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.emailFetchConfig = {
      entityType: EntityPropertyTypes.USER_NAME,
      filter: [FetchEntitiesFilters.ALL, FetchEntitiesFilters.SEARCH_BY_EMAIL],
      action: this.props.actions.fetchEntities
    };
  }

  validateEmail = (value, allValues) => {
    return Validation.email(value?.data?.email || '');
  };

  validateSingleValue = (value, allValues) => {
    if (!value) {
      return undefined;
    }
    if (this.isItemAlreadySelected(value, allValues)) {
      this.props.touch(this.props.name + '.[emailInput]');
      return 'Duplicated item selection';
    }
    return undefined;
  };

  isItemAlreadySelected = (value, allValues) => {
      let res = allValues.emails.some((item, index) => {
        return (
          value?.data?.email === item?.emailInput?.data?.email && this.props.index !== index
        );
      });
      return res;
  };

  getNewOptionDataHandler = (inputValue, optionLabel) => {
    return {
      label: optionLabel,
      value: inputValue,
      __isNew__: true,
      data: {
        fullName: '',
        email: inputValue,
      }
    };
  };

  getComponentToRender = (props) => {
    return <Label text={props?.data?.data?.email || ''} />;
  };

  getOptionComponentToRender = (props) => {
    if (props?.data) {
      let compLabel = `${props.data?.data?.fullName || ''} ${props.data?.data?.email || ''}`;
      return (props.data.__isNew__ ? props.data.label : <Label text={compLabel}/>);
    }
    return null;
  };


  render() {
    let {  name, change, index } = this.props;
    return (
      <>
          <span className='item-index'>{`${index + 1}.`}</span>
          <ComboboxPortal
            id={name + '.[emailInput]'}
            name={name + '.[emailInput]'}
            entityType={'USER_NAME'}
            fetchConfig={this.emailFetchConfig}
            placeholder={'Enter e-mail'}
            validate={[this.validateEmail, this.validateSingleValue]}
            change={change}
            allowNewOption
            isClearable
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getOptionComponentToRender}
            getNewOptionData={this.getNewOptionDataHandler}
          />
          </>
        )
    }
}

EmailListItem.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    change: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
};
