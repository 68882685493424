import React from 'react';
import FilterFooter from '../Common/FilterFooter/filterFooter';
import FilterLayout from '../Common/FilterLayout/filterLayout';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';

require('./searchFilter.scss');

export default class PL_SearchFilter extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.filter.');

    this.state = {searchText: ''};

    this.currentText = '';
  }

  componentDidMount() {
    this.filterName = this.props.filterName;
    this.setModel();
  }

  setModel() {
    let {values} = GridHelper.getActivatedFilter(this.props.filterName, this.props.context?.componentParent?.props?.filterState?.toJS());
    if (values && values.length > 0) {
      let value = values[0];
      this.currentText = value;
      this.setState({currentText: value});
    }
  }

  // called by agGrid
  afterGuiAttached(params) {
    this.setState({searchText: this.currentText || ''});
    this.hidePopup = params.hidePopup;

    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  // called by agGrid
  doesFilterPass(params) {
    return true;
  }

  // called by agGrid
  isFilterActive() {
    return (this.currentText !== '');
  }

  // called by agGrid
  onNewRowsLoaded() {
    this.handleFilterState();
  }

  handleFilterState = () => {
    let filterState = this.props.context?.componentParent?.props?.filterState;

    let isFilterStateActive = filterState?.some((item) => {
      return item.filterName === this.filterName && item.values;
    });

    if (!isFilterStateActive && this.isFilterActive()) {
      this.deactivateFilter();
    }
  }

  deactivateFilter = () => {
    let newState = { searchText: ''};
    this.setState(newState);
    this.currentText = '';
  }

  apply(){
    this.currentText = this.state.searchText;

    this.props.colDef?.filterParams?.onFilterChanged?.(this.getModel());
    this.hidePopup();
  }

  cancel() {
    this.hidePopup();
  }

  getModel() {
    if (!this.currentText) {
      return [{filterName: this.filterName, values: []}];
    }

    return [{filterName: this.filterName, values: [this.currentText]}];
  }

  //not in use
  getApi() {
    return {
      getModel: this.getModel.bind(this)
    }
  }

  onChangeHandler = (e) => {
    this.setState({searchText: e.target.value})
  };

  onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.apply();
    }
  };

  render() {
    return (
      <FilterLayout className="search-filter"
                    columnWidth={this.props.column.actualWidth}
                    filterWidth={350}
                    filterAlignment={this.props.filterAlignment}
      >

        <span className="PL-textField">
          <input type="text" id="text"
                 ref={r => this.inputRef = r}
                 className="text-field form-control"
                 onChange={this.onChangeHandler}
                 onKeyDown={this.onKeyDownHandler}
                 value={this.state.searchText}
                 placeholder={this.labels.get('inputhint') || 'Type Here'}
                 autoComplete="off"
          />
        </span>

        <FilterFooter
          okText={this.labels.get('submit') || 'FILTER'}
          cancelText={this.labels.get('cancel') || 'CANCEL'}
          onCancel={this.cancel.bind(this)}
          onOk={this.apply.bind(this)}
        />

      </FilterLayout>);
  }
}
