import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import ValueWithStateCell from '../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell';
import LabelWithTooltipCell from '../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import { navigationStates } from '../../../enums/navigationStates';
import { filterTypes } from 'infrastructure/js/enums/filterTypes.js';
import { gridsNames } from '../../../enums/gridsNames';
import MaterialHelper from '../../../utils/materialHelper';
import EntityIdCell from '../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import dateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import * as AssetHelper from '../../../utils/assetHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./pickListGrid.scss');

class PickListGrid extends React.PureComponent {

  filterConfig = [
    { fieldName: 'businessId', filterName: 'businessId', getOptions: true },
    { fieldName: 'material.businessId', filterName: 'material', getOptions: true },
    { fieldName: 'location', filterName: 'assetLocationName', getOptions: true },
    { fieldName: 'alertStatus', filterName: 'assetAlert' },
    { fieldName: 'expirationDate', filterName: 'rollExpirationDateFilter', getOptions: false },
    { fieldName: 'exposureTimeLeft', filterName: 'rollEtlDateFilter', getOptions: true },
    { fieldName: 'pickDateFrom', filterName: 'pickDate', getOptions: false},
  ]

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.filterAlertslabels = createLabelHelper('mat.filter.alerts.');
    this.pickDateRangeEnabled = PermissionManager.getOrgPreferences().smartSelectionPickDatesRangeEnabled;

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: this.getAlertColumnFilterSections(),
        width: 120,
        columnOptions: {
          sort: 'desc',
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '', alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          },
        },
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'material',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.assetId.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'businessId',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            let type = params.data.objectType;
            return {
              id: params.data.id,
              type: AssetHelper.isRoll(type) ? navigationStates.ROLL : AssetHelper.isSpool(type) ? navigationStates.SPOOL : '',
              label: params.data.businessId
            };
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.exposureTimeLeftCure && params.data.exposureTimeLeftCure.value !== null) {
              let minutes = params.data.exposureTimeLeftCure.value;
              return {
                value: DateTimeHelper.ConvertMinutesToHoursMinutes(minutes),
                state: params.data.exposureTimeLeftCure.state
              };
            }
            return {value: '', state: ''};
          },
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      },
      {
        fieldName: 'subLocation',
        title: this.labels.get('columns.subLocation.title'),
        filterType: filterTypes.NONE,
        // filterName: 'assetLocationName',
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.subLocation;
          },
        }
      },
      {
        fieldName: 'pickDateFrom',
        title: this.labels.get('columns.pickDate.title'),
        filterType: filterTypes.HORIZON,
        filterName: 'pickDate',
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterLabel: '',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return  this.pickDateRangeEnabled ? dateTimeHelper.FormatDateObjectsToDayMonthRange(params.data.pickDateFrom, params.data.pickDateTo) : dateTimeHelper.FormatDateObjectToDayMonth(params.data.pickDateFrom)
          },
        }
      },
    ]
  }

  getFormattedDate = (data) => {
    return ((data) ?  DateTimeHelper.DateFormat(data * 1000) : '')
  }


  getAlertColumnFilterSections() {
    let strings = this.filterAlertslabels.createSubset('');
    return [
      {
        header: (strings['alertstatus']),
        properties: [
          {name: 'info', label: (strings['alertstatus.info']), value: false},
          {name: 'noAlert', label: (strings['alertstatus.noalert']), value: false},
          {name: 'warning', label: (strings['alertstatus.warning']), value: false},
          {name: 'error', label: (strings['alertstatus.error']), value: false}
        ]
      },
      {
        header: (strings['alerttype']),
        properties: [
          {name: 'etl', label: (strings['alerttype.etl']), value: false},
          {name: 'expiration', label: (strings['alerttype.expiration']), value: false},
          {name: 'reserved', label: (strings['alerttype.reserved']), value: false}
        ]
      },
    ];
  }

  render() {
    return (
      <div className="pick-list-grid">
        <Grid gridName={gridsNames.PICK_LIST_PAGE}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={{ getRowHeight: () => 100 }}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'PickListGridForm'
  }
)(PickListGrid);


