import PropTypes from 'prop-types';
import Tree from 'infrastructure/js/components/Tree/tree';
import './managerViewTree.scss';

const ManagerViewTree = ({ treeData=[],
                           onSelect=()=>{},
                           selectedItem,
                           defaultExpandAll=true}) => {

  const getItemIconsRenderer = (item) => {
    if (item.isSection) {
      return item.expanded ? <div className='pl pl-arrow-down branch-icon'/> : <div className='pl pl-arrow-right branch-icon'/>;
    }
    return item.isStation ? <div className='pl pl-station-sm leaf-icon'/> : <div className='pl pl-location-sm leaf-icon'/>
  }

  return (
      <Tree  className="manager-view-tree"
             treeData={treeData}
             onSelect={onSelect}
             selectedItem={selectedItem}
             defaultExpandAll={defaultExpandAll}
             itemIconsRenderer={getItemIconsRenderer}
      />
  );
}

ManagerViewTree.propTypes = {
  treeData: PropTypes.array,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.object,
  defaultExpandAll: PropTypes.bool,
}

export default ManagerViewTree;
