import React from 'react';
import PropTypes from 'prop-types';


import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import OrganizationsTabContainer from '../../../containers/Administration/OrganizationsPage/organizationsTabContainer';
import AdminTabContainer from '../../../containers/Administration/OrganizationsPage/adminTabContainer';
import OrgsDashboardViewContainer from '../../../containers/Administration/OrganizationsPage/orgsDashboardViewContainer';
import CreateOrganizationDialog from './Dialogs/CreateOrganizationDialog/createOrganizationDialog';
import DeleteOrganizationDialog from './Dialogs/DeleteOrganizationDialog/deleteOrganizationDialog';
import {PL_DialogWrapper}  from 'infrastructure/js/components/Dialog/dialog';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./organizationsPage.scss');

export default class OrganizationsPage extends React.PureComponent{
  constructor (props){
    super(props);

    this.labels = createLabelHelper('mat.administration.orgsmanagement.');
  }

  componentDidMount() {
    this.props.actions.init();
    if(window.location.href.indexOf('organizationsPage') < 0){
      window.location.href = '#/Administration/organizationsPage';
    }
  }

  reloadPage = () => {
    this.props.actions.init(true);
  };

  onTabClick = (tabKey) => {
    if (tabKey !== this.props.selectedTab) {
      this.props.actions.onTabClick(tabKey);
      setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 0);
    }
  };

  renderTabs = () => {
    let selectedTab = this.props.selectedTab;
    return (
      <Tabs id='topbar-tabs' activeKey={selectedTab} tabType='page' onSelect={this.onTabClick}>
        {this.renderOrgsTab()}
        {this.renderAdminTab()}
        {this.renderOrgsDashboardView()}
      </Tabs>
    );
  };

  renderOrgsTab = () => {
    if (PermissionManager.hasOrgTabInOrgPagePermissions()) {
      return (
        <Tabs.Tab eventKey={'orgs'} title={this.labels.get('tabs.orgs')} animation={false}>
          <OrganizationsTabContainer/>
        </Tabs.Tab>
      )
    }
    return null;
  };

  renderAdminTab = () => {
    if (PermissionManager.hasAdminTabInOrgPagePermissions()) {
      return (
        <Tabs.Tab eventKey={'admin'} title={this.labels.get('tabs.admin')} animation={false}>
          <AdminTabContainer/>
        </Tabs.Tab>
      )
    }
    return null;
  };

  renderOrgsDashboardView = () => {
    if (PermissionManager.hasOrgTabInOrgPagePermissions()) {
      return (
        <Tabs.Tab eventKey={'dashboard'} title={'Orgs Dashboard'} animation={false} tabClassName="wide">
          <OrgsDashboardViewContainer/>
        </Tabs.Tab>
      )
    }
    return null;
  };

  render() {
    return (
      <div className="org-management-page">
        <FullScreenTabsSeparator/>

        {this.renderTabs()}

        <PL_DialogWrapper  dialogComponent={CreateOrganizationDialog}
                           show={this.props.sCreateOrganizationDialogData.get('show')}
                           actions={this.props.actions.createOrganizationDialogActions}
                           sData={this.props.sCreateOrganizationDialogData}
                           sLoggedInUser={this.props.sLoggedInUser}
                           reloadParentComponent={this.reloadPage}
        />
        <PL_DialogWrapper  dialogComponent={DeleteOrganizationDialog}
                           show={this.props.sDeleteOrganizationDialogData.get('show')}
                           actions={this.props.actions.deleteOrganizationDialogActions}
                           sData={this.props.sDeleteOrganizationDialogData}
        />

      </div>
    );
  }
};


OrganizationsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};
