import PropTypes from 'prop-types';
import MarketingLayout from 'infrastructure/js/components/LoginLayout/marketingLayout';
import logoImg from 'infrastructure/assets/images/login-logo.png';
import Button from 'infrastructure/js/components/controls/Button/button';

import './promotionsPreview.scss'

export default function PromotionsPreview({ promotions=[],
                            slideSpeed=0,
                            onCloseHandler
                          }) {

  return (
    <div className="promotions-preview">
      <MarketingLayout className="login-page" promotions={promotions} slideSpeed={slideSpeed}>
        <div className="logo" tabIndex="1000">
          <img src={logoImg} alt="login logo"/>
        </div>
        <div className="title">{'Login promotions preview'}</div>

        <Button id="close" bsStyle="primary" onClick={onCloseHandler}>{'Close Preview'}</Button>
      </MarketingLayout>
    </div>
  )
}

PromotionsPreview.propTypes = {
  promotions: PropTypes.array,
  slideSpeed: PropTypes.number,
  onCloseHandler: PropTypes.func.isRequired,
}
