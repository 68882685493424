import React from 'react';
import PropTypes from 'prop-types';
import FilterFooter from '../Common/FilterFooter/filterFooter';
import Dropdown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import FilterLayout from '../Common/FilterLayout/filterLayout';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';

require('./multiPropertiesFilter.scss');

export default class PL_MultiPropertiesFilter extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.filter.');

    this.currentState = {
      dropdown: null,
      text: '',
    }
  }

  componentDidMount() {
    this.validateFilterParameters();
    this.filterNames = this.props.properties?.map(item => item.value);
    this.setModel();
  }

  setModel() {

    let filterState = this.props.context?.componentParent?.props?.filterState;

    let found = filterState?.find((item) => {
      return this.filterNames.includes(item.filterName) && item.values?.length > 0;
    });

    if (found) {
      this.currentState = {
        dropdown: this.props.properties.find(item => item.value === found.filterName),
        text: found.values[0]
      }
    }
  }


  // called by agGrid
  afterGuiAttached(params) {
    this.props.change('dropdown', this.currentState?.dropdown || this.props.properties?.[0]);
    this.props.change('text', this.currentState?.text || '');

    this.hidePopup = params.hidePopup;
  }

  // called by agGrid
  doesFilterPass(params) {
    return true;
  }

  // called by agGrid
  isFilterActive() {
    return this.currentState?.dropdown && this.currentState?.text;
  }

  // called by agGrid
  onNewRowsLoaded() {
    this.handleFilterState();
  }

  handleFilterState = () => {
    let filterState = this.props.context?.componentParent?.props?.filterState;

    let isFilterStateActive = filterState?.some((item) => {
      return this.filterNames.includes(item.filterName) && item.values?.length > 0;
    });

    if (!isFilterStateActive && this.isFilterActive()) {
      this.deactivateFilter();
    }
  }

  deactivateFilter = () => {
    this.props.change('dropdown', null);
    this.props.change('text', '');
    this.currentState = {dropdown: null, text: ''};
  }

  apply(data){
    this.currentState = {
      dropdown: data.dropdown,
      text : data.text
    };
    this.props.colDef?.filterParams?.onFilterChanged?.(this.getModel());
    this.hidePopup();
  }

  cancel(){
    this.hidePopup();
  }

  getModel() {
    let filters = this.getAllFilters();
    if (!this.currentState || !this.currentState.dropdown || !this.currentState.text) {
      return filters;
    }

    for (let i = 0; i < filters.length; i++) {
      if (filters[i].filterName === this.currentState.dropdown.value) {
        filters[i].values = [this.currentState.text];
      }
    }
    return filters;
  }

  //not in use
  getApi() {
    return {
      getModel: this.getModel.bind(this)
    }
  }

  getAllFilters = () => {
    let filters = this.props.properties.map((property) => {
      return { filterName: property.value, values: []}
    })

    return filters;
  }

  validateFilterParameters() {
    if (!this.props.properties || this.props.properties.length === 0  || !this.props.handleSubmit || !this.props.change )
      throw 'filter parameters are not valid';
  }


  render() {
    return (
      <FilterLayout className="multi-properties-filter"
                    columnWidth={this.props.column.actualWidth}
                    filterWidth={350}
                    filterAlignment={this.props.filterAlignment}
      >

        <Dropdown className="properties-dropdown"
          name="dropdown"
          id="dropdown"
          options={this.props.properties}
          placeholder={this.labels.get('selecthint') || 'Select'}
          validate={Validation.required}>
        </Dropdown>

        <TextField id="text"
                   className="text-field"
                   name="text"
                   placeholder={this.labels.get('inputhint') || "Type Here"}>
        </TextField>

        <FilterFooter
          okText={this.labels.get('submit') || 'FILTER'}
          cancelText={this.labels.get('cancel') || 'CANCEL'}
          onCancel={this.cancel.bind(this)}
          onOk={this.props.handleSubmit(this.apply.bind(this))}
          selectedCount={0}
        />

      </FilterLayout>);
  }
}

//Note: properties must be an array with length > 0
PL_MultiPropertiesFilter.propTypes = {
  properties: PropTypes.array.isRequired,
}
