import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActivityLogDialog from '../../components/ActivityLogDialog/activityLogDialog';
import { jsxActions as activityLogDialogActions } from '../../actions/ActivityLogDialog/activityLogDialogActions';
import { jsxActions as activityLogGridActions } from '../../actions/ActivityLogDialog/activityLogDialogGridActions';
import { jsxActions as tasksAssignmentsGridActions } from '../../actions/ActivityLogDialog/tasksAssignmentsGridActions';
import {gridsNames} from '../../enums/gridsNames.js';

function mapDispatchToProps(dispatch) {
  return {
    actions:         bindActionCreators(activityLogDialogActions, dispatch),
    activityLogGridActions:     bindActionCreators(activityLogGridActions, dispatch),
    tasksAssignmentsGridActions:     bindActionCreators(tasksAssignmentsGridActions, dispatch),
  }
}

export default connect(
  (state)=>{
    let grid = state.grid.get(gridsNames.TASKS_ASSIGNMENTS) || null;
    return {
      show : state.activityLogDialog.get( 'show'),
      loading: state.activityLogDialog.get( 'loading'),
      data : state.activityLogDialog.get( 'data'),
      sHasRows: grid?.get('rows')?.size > 0 || false,
    }
  },
  mapDispatchToProps
)(ActivityLogDialog);

