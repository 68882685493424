import { Map } from 'immutable';
import { actionTypes as overviewTabActionTypes } from '../../actions/LotPage/overviewTabActions';

let defaultState = {
  lotDetails: {},
  loading: false,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case overviewTabActionTypes.LOT_PAGE_OVERVIEW_TAB_FETCHDATA_STARTED:
      return state.set('loading', true);

    case overviewTabActionTypes.LOT_PAGE_OVERVIEW_TAB_FETCHDATA_FINISHED:
      state = state.set('lotDetails', action.payload).set('loading', false);
      return state;

    default:
      return state;
  }
}
