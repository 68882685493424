import * as assetService from '../../../services/Assets/assetService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_PS_MANAGEMENT_ASSETS, _fetchDataAction);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query.pageSize = 50;
    return assetService.fetchAllAssets(query);
  }
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
