import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import Header, {headerItemsLabels} from '../../../../../Common/Header/header';
import AdditionalFieldListItem from '../AdditionalFieldListItem/additionalFieldListItem';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import List from 'infrastructure/js/components/List/list';

require('./commonAdditionalFieldsForm.scss');

const CommonAdditionalFieldsForm = ({
                                      labels,
                                      fields=null,
                                      fieldTypes=null,
                                      initialize,
                                      pristine,
                                      handleSubmit,
                                      actions,
                                      entityType

                                    }) => {

  useEffect(() => {
    initFormValues();
  }, [fields, entityType]);

  const initFormValues = () => {
    let initialValues = getInitialValues();
    initialize(initialValues);
  };

  const getInitialValues = () => {
    if (!fields) {
      return null;
    }

    let initialValues = {};
    initialValues.additionalFields = fields;
    return initialValues;
  };

  const getHeaderItems = () => {
    return {
      buttons: [
        {
          id: 'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: pristine,
          action: handleSubmit(onSubmit),
        },
      ],
    };
  }

  const onSubmit = (data) => {
    actions.submit(data, entityType)
  };

  const listItemRenderer = (data) => {
    return (
      <AdditionalFieldListItem
        {...data}
        labels={labels}
        options={fieldTypes}
      />
    )
  };

    if (!fieldTypes) {
      return null;
    }

    let headerItems = getHeaderItems();
    let defaultMaxFields = 18;
    return (
      <div className='common-additional-fields-form'>
        <Header {...headerItems}/>

        <div className="additional-fields-columns-header">
          <label className="column">{labels.get('list.column.fieldName')}</label>
          <label className="column">{labels.get('list.column.type')}</label>
        </div>

        <List name='additionalFields'
             className='additional-fields-add-remove-list'
             itemRenderer={listItemRenderer}
             preSelectedItems={fields || []}
             maxItemsToRender={PermissionManager.getOrgPreferences().additionalFieldsNum ? PermissionManager.getOrgPreferences().additionalFieldsNum : defaultMaxFields}
             defaultItem={{id: null, type: {type: 'TEXT'}}}
             addButtonLabel={labels.get('list.button.add')}
             maxItemsToOverflow={12}
        />
      </div>
    );
}

export default reduxForm({
    form: 'commonAdditionalFieldsForm'
  }
)(CommonAdditionalFieldsForm);

CommonAdditionalFieldsForm.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  fields: PropTypes.array,
  fieldTypes: PropTypes.array,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
};
