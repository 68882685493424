// @ts-check

import { MapView } from "@mappedin/mappedin-js";

import React from "react";


import './searchResults.scss';
import SearchResultItem from "./SearchResultItem/searchResultItem";
import LabelManager from "../../../Utils/LabelManager";
import MapLabelManager from "../../../Utils/MapLabelManager";
import SearchResultsTransformer from "../../../Logic/SearchResultsTransformer";
import RefContextHelper from "../../../Utils/RefContextHelper";
import { fn } from "moment";
import LiveSearchUtils from "../../../Utils/LiveSearchUtils";


/**
 * @typedef {import("../../../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("mat/js/types/ServerTypes/Models/LiveSearch").LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * @typedef {import("mat/js/types/ServerTypes/Models/LiveSearch").LiveSearchResultBlock} LiveSearchResultBlock
 * @typedef {import("mat/js/types/ServerTypes/Models/LiveSearch").LiveSearchResultItem} LiveSearchResultItem
 * @typedef {import("mat/js/types/ServerTypes/Models/LiveSearch").BlockGroup} BlockGroup
 * 
 * @typedef {import("../../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageActions} LiveMapPageActions
 * 
 * 
 * @typedef {{
 *    mapView:  MapView | undefined, 
 *    sData:    PL_LiveMapPageState, 
 *    actions:  LiveMapPageActions
 *    refContext: React.MutableRefObject<LiveMapPageRefContext>
 * }} SearchResultsProps
 * 
 */




/**
 * @param {SearchResultsProps} props
 * 
 */
const SearchResults = (props) => {

  // console.log('=========== SearchResults RENDER ===> ', props);

  let sData = props.sData;

  // let data = sData.toJS();
  // console.log('=========== SearchResults RENDER ===> ', data);
  if (!sData.search.searchResults) {
    return null;
  }


  return (
    <>
      <div className='search-results'>
        <div className='search-results-scroll-container' id="search-results-target">
          {renderSearchResults(sData.search.searchResults, props)}
        </div>
      </div>
    </>
  );

};

export default SearchResults;






/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 * @param {SearchResultsProps} props
 * 
 */
let renderSearchResults = (searchResults, props) => {
  
  // console.log('searchResults ===> ', searchResults);
  
  let sData = props.sData;
  if (!sData) {
    return null;
  }
  
  let searchResultsState = sData.search.searchResultsState;
  if (searchResultsState === "LOADING") {
    return renderSearchResults_LOADING();
  }
  

  let blocks = searchResults.blocks;
  if (!blocks || blocks.length === 0) {
    return renderSearchResults_NO_RESULTS(props);
  }

  if (searchResults.totalResults === 0) {
    // console.log('NO RESULTS');
    return renderSearchResults_NO_RESULTS(props);
  }




  let currentSearchFilter = props.sData.search.searchFilter;
  let isAllView = currentSearchFilter === 'ALL';
  if (isAllView) {
    return renderSearchResults_ALL(searchResults, props);
  }

  let isLocationsView = currentSearchFilter === 'LOCATIONS';
  if (isLocationsView) {
    return renderSearchResults_LOCATIONS_BY_FLOOR(searchResults, props);
  }



  return renderSearchResults_SPLIT_BY_LOCATION_AND_FLOOR(searchResults, props);



};


/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 * @param {SearchResultsProps} props
 * 
 */
let renderSearchResults_ALL = (searchResults, props) => {

  if (!searchResults) {
    return null;
  }

  let blocks = searchResults.blocks;
  
  
  // let byFloorGroups = SearchResultsTransformer.transformBlocks_regroupByFloor(blocks);
  // console.log('byFloorBlocks ===> ', byFloorGroups);
  
  
  // ////////////////////////////////////////////////////////////////////
  // // There are more than 1 group, so rendering them makes sense.
  // //
  // let shouldRenderGroups = byFloorGroups.length > 1;
  // if (shouldRenderGroups) {

  //   let renderedGroups = byFloorGroups.map((group, index) => {
  //     return renderSearchResults__Group(group, index, props, renderSearchResults_ALL__Block);
  //   });
  
  //   return (
  //     <>
  //       {renderedGroups}
  //     </>
  //   );
    
  // }
  
  
  ////////////////////////////////////////////////////////////////////
  // Just render the blocks.
  //
  
  let renderedBlocks = blocks.map((b, index) => {
    return renderSearchResults_ALL__Block(b, index, props);
  });  
  
  return (
    <>
      {renderedBlocks}
    </>
  );
  

};


/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 * @param {SearchResultsProps} props
 * 
 */
let renderSearchResults_SPLIT_BY_LOCATION_AND_FLOOR = (searchResults, props) => {
  // console.log('props ===> ', props);

  if (!searchResults) {
    return null;
  }

  let blocks = searchResults.blocks;
  // console.log('blocks ===> ', blocks);

  // Transform data from single block to multiple blocks split by Location.
  let byLocationBlocks = SearchResultsTransformer.transformBlocks_SplitByLocation(blocks);
  // console.log('byLocationBlocks ===> ', byLocationBlocks);
  
  let byFloorGroups = SearchResultsTransformer.transformBlocks_regroupByFloor(byLocationBlocks);
  // console.log('byFloorBlocks ===> ', byFloorGroups);
  
  
  ////////////////////////////////////////////////////////////////////
  // There are more than 1 group, so rendering them makes sense.
  //
  let shouldRenderGroups = byFloorGroups.length > 1;
  if (shouldRenderGroups) {

    let renderedGroups = byFloorGroups.map((group, index) => {
      return renderSearchResults__Group(group, index, props, renderSearchResults_BY_LOCATION__Block);
    });
  
    return (
      <>
        {renderedGroups}
      </>
    );
    
  }
  
  
  ////////////////////////////////////////////////////////////////////
  // Just render the blocks.
  //
  
  let renderedBlocks = byLocationBlocks.map((b, index) => {
    return renderSearchResults_BY_LOCATION__Block(b, index, props);
  });  
  
  return (
    <>
      {renderedBlocks}
    </>
  );
  



};



/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 * @param {SearchResultsProps} props
 * 
 */
let renderSearchResults_LOCATIONS_BY_FLOOR = (searchResults, props) => {
  // console.log('props ===> ', props);

  if (!searchResults) {
    return null;
  }

  let blocks = searchResults.blocks;
  // console.log('blocks ===> ', blocks);

  let byFloorGroups = SearchResultsTransformer.transformBlocks_regroupByFloor(blocks);
  // console.log('byFloorBlocks ===> ', byFloorGroups);
  
  
  ////////////////////////////////////////////////////////////////////
  // There are more than 1 group, so rendering them makes sense.
  //
  let shouldRenderGroups = byFloorGroups.length > 1;
  if (shouldRenderGroups) {

    let renderedGroups = byFloorGroups.map((group, index) => {
      // return renderSearchResults__Group(group, index, props, renderSearchResults_BY_LOCATION__Block);
      return renderSearchResults__Group(group, index, props, renderSearchResults_LOCATION__Block);
    });
  
    return (
      <>
        {renderedGroups}
      </>
    );
    
  }
  
  
  ////////////////////////////////////////////////////////////////////
  // Just render the blocks.
  //
  
  let renderedBlocks = blocks.map((b, index) => {
    return renderSearchResults_LOCATION__Block(b, index, props);
  });  
  
  return (
    <>
      {renderedBlocks}
    </>
  );
  



};





/** 
 * @param {LiveSearchResultBlock} block_data 
 * @param {number} blockIndex
 * @param {SearchResultsProps} props
 */
let renderSearchResults_ALL__Block = (block_data, blockIndex, props) => {

  if (!block_data) {
    return null;
  }


  let show_more_clicked = () => {

    let typeToFilterMap = {
      "ALL": "ALL",
      "TOOL": "TOOLS",
      "MATERIAL": "MATERIALS",
      "LOCATION": "LOCATIONS",
      "WO": "WO"
    };


    props.actions.filterClicked(typeToFilterMap[block_data.type]);
  }

  let block_title_clicked = () => {
    // console.log('block_title_clicked ===> ', block_data.type);
    props.actions.resultBlockTitleClicked(block_data);
  }


  let currentSearchFilter = props.sData.search.searchFilter;
  let isAllView = currentSearchFilter === 'ALL';


  let items = block_data.items;
  let title = MapLabelManager.getBreadcrumbTypeLabel(block_data.type);
  let totalCount = block_data.totalCount;

  if (!items || items.length === 0) {
    return null;
  }


  // @ts-ignore
  let renderedItems = items.map((item, index) => {
    return (
      <SearchResultItem key={index} itemData={item} actions={props.actions} sData={props.sData} />
    );
  });



  let itemsCount = block_data.items.length;
  let thereIsMore = totalCount > itemsCount;


  let isMoreButtonAvailable = thereIsMore && isAllView;
  let showMoreButton = isMoreButtonAvailable ? (
    <div className='search-result-block__show-more unselectable'>
      <span onClick={show_more_clicked}>{LabelManager.getByKey('mat.livesearch.show_more')}</span>
    </div>
  ) : null;



  let isTheresEvenMoreMesssageAvailable = thereIsMore && !isAllView;
  let theresEvenMoreMessage = isTheresEvenMoreMesssageAvailable ? (
    <div className='search-result-block__theres-more unselectable'>
      <span>{LabelManager.getByKey('mat.livesearch.there_is_more')}</span>
    </div>
  ) : null;
  // console.log(`isMoreAvailable ===> itemsCount ${itemsCount}, totalCount ${totalCount}, isMoreAvailable ${isMoreAvailable}`);





  return (
    <React.Fragment key={blockIndex}>

      <div className='search-result-by-type-block'>

        <div onClick={block_title_clicked} className='search-result-block__title'>
          <div>
            <span className="title-text">{title}</span>
            <span className="title-separator">&nbsp;</span>
            <span className="title-count">({totalCount})</span>
          </div>
        </div>

        <div className='search-result-block__items'>
          {renderedItems}
        </div>

        {showMoreButton}
        {theresEvenMoreMessage}

      </div>
    </React.Fragment>
  );
};



/** 
 * @param {LiveSearchResultBlock} block_data 
 * @param {number} blockIndex
 * @param {SearchResultsProps} props
 */
let renderSearchResults_LOCATION__Block = (block_data, blockIndex, props) => {

  if (!block_data) {
    return null;
  }


  // let show_more_clicked = () => {

  //   let typeToFilterMap = {
  //     "ALL": "ALL",
  //     "TOOL": "TOOLS",
  //     "MATERIAL": "MATERIALS",
  //     "LOCATION": "LOCATIONS",
  //     "WO": "WO"
  //   };


  //   props.actions.filterClicked(typeToFilterMap[block_data.type]);
  // }

  let block_title_clicked = () => {
    // console.log('block_title_clicked ===> ', block_data.type);
    props.actions.resultBlockTitleClicked(block_data);
  }


  // let currentSearchFilter = props.sData.search.searchFilter;
  // let isAllView = currentSearchFilter === 'ALL';


  let items = block_data.items;
  let title = MapLabelManager.getBreadcrumbTypeLabel(block_data.type);
  let totalCount = block_data.totalCount;

  if (!items || items.length === 0) {
    return null;
  }


  // @ts-ignore
  let renderedItems = items.map((item, index) => {
    return (
      <SearchResultItem key={index} itemData={item} actions={props.actions} sData={props.sData} />
    );
  });



  let itemsCount = block_data.items.length;
  let thereIsMore = totalCount > itemsCount;


  // let isMoreButtonAvailable = thereIsMore && isAllView;
  // let showMoreButton = isMoreButtonAvailable ? (
  //   <div className='search-result-block__show-more unselectable'>
  //     <span onClick={show_more_clicked}>{LabelManager.getByKey('mat.livesearch.show_more')}</span>
  //   </div>
  // ) : null;



  let isTheresEvenMoreMesssageAvailable = thereIsMore;
  let theresEvenMoreMessage = isTheresEvenMoreMesssageAvailable ? (
    <div className='search-result-block__theres-more unselectable'>
      <span>{LabelManager.getByKey('mat.livesearch.there_is_more')}</span>
    </div>
  ) : null;
  // console.log(`isMoreAvailable ===> itemsCount ${itemsCount}, totalCount ${totalCount}, isMoreAvailable ${isMoreAvailable}`);





  return (
    <React.Fragment key={blockIndex}>

      <div className='search-result-by-type-block'>

        <div onClick={block_title_clicked} className='search-result-block__title'>
          <div>
            <span className="title-text">{title}</span>
            <span className="title-separator">&nbsp;</span>
            <span className="title-count">({totalCount})</span>
          </div>
        </div>

        <div className='search-result-block__items'>
          {renderedItems}
        </div>

        {/* {showMoreButton} */}
        {theresEvenMoreMessage}

      </div>
    </React.Fragment>
  );
};




/** 
 * @param {LiveSearchResultBlock} block_data 
 * @param {number} blockIndex
 * @param {SearchResultsProps} props
 */
let renderSearchResults_BY_LOCATION__Block = (block_data, blockIndex, props) => {

  if (!block_data) {
    return null;
  }


  let block_title_clicked = () => {
    // console.log('block_title_clicked ===> ', block_data.type);
    props.actions.resultBlockLocationTitleClicked(block_data);
  }

  let load_more_clicked = () => {
    // console.log('load_more_clicked ===> ', block_data);
    let locationId = block_data.totalPerLocationCounts[0].locationId;
    props.actions.searchMap_LoadMoreForLocation(locationId);
  }



  let items = block_data.items;
  // let title = MapLabelManager.getBreadcrumbTypeLabel(block_data.type);
  
  if (!items || items.length === 0) {
    if (!block_data.totalPerLocationCounts || block_data.totalPerLocationCounts.length === 0) {
      return null;
    }
  }
  
  // let title = block_data.items[0].location.locationName;
  let title = block_data.totalPerLocationCounts[0].locationName;
  // let totalCount = block_data.totalPerLocationCounts[0].count;
  let totalCount = block_data.totalCount;


  let renderedItems = items.map((item, index) => {
    return (
      <SearchResultItem key={index} itemData={item} actions={props.actions} sData={props.sData} />
    );
  });



  let itemsCount = block_data.items.length;
  let thereIsMore = totalCount > itemsCount;

  
  let isMoreButtonAvailable = thereIsMore && (itemsCount < LiveSearchUtils.ABSOLUTE_MAX_ITEMS_PER_BLOCK);
  let showMoreButton = isMoreButtonAvailable ? (
    <div className='search-result-block__show-more unselectable'>
      <span onClick={load_more_clicked}>{LabelManager.getByKey('mat.livesearch.show_more')}</span>
    </div>
  ) : null;
  

  let isTheresEvenMoreMesssageAvailable = thereIsMore && !isMoreButtonAvailable;
  let theresEvenMoreMessage = isTheresEvenMoreMesssageAvailable ? (
    <div className='search-result-block__theres-more unselectable'>
      <span>{LabelManager.getByKey('mat.livesearch.there_is_more')}</span>
    </div>
  ) : null;
  // console.log(`isMoreAvailable ===> itemsCount ${itemsCount}, totalCount ${totalCount}, isMoreAvailable ${isMoreAvailable}`);





  return (
    <React.Fragment key={blockIndex}>

      <div className='search-result-by-type-block'>

        <div onClick={block_title_clicked} className='search-result-block__title'>
          <div>
            <span className="title-text">{title}</span>
            <span className="title-separator">&nbsp;</span>
            <span className="title-count">({totalCount})</span>
          </div>
        </div>

        <div className='search-result-block__items'>
          {renderedItems}
        </div>

        {showMoreButton}
        {theresEvenMoreMessage}

      </div>
    </React.Fragment>
  );
};


/** 
 * @param {BlockGroup} group 
 * @param {number} blockIndex
 * @param {SearchResultsProps} props
 * @param {any} fnBlockRenderer
 */
let renderSearchResults__Group = (group, blockIndex, props, fnBlockRenderer) => {

  if (!group) {
    return null;
  }
  
  let sData = props.sData;
  if (!sData) {
    return null;
  }


  let group_title_clicked = () => {
    // console.log('group_title_clicked ===> ', group);
    props.actions.resultGroupTitleClicked(group);
  }



  let blocks = group.blocks;
  // let title = MapLabelManager.getBreadcrumbTypeLabel(block_data.type);
  
  if (!blocks || blocks.length === 0) {
    return null;
  }
  
  // let title = block_data.items[0].location.locationName;
  let title = group.groupName;
  // let totalCount = block_data.totalPerLocationCounts[0].count;
  let totalCount = group.blocks.reduce((acc, b) => acc + b.totalCount, 0);


  let renderedItems = blocks.map((item, index) => {
    // return renderSearchResults_BY_LOCATION__Block(item, index, props);
    return fnBlockRenderer(item, index, props);
  });
  
  
  // props.sData.search

  
  // let currentFloorName = RefContextHelper.getCurrentFloorName();
  let currentFloorName = sData.floorName;
  let isCurrentFloor = currentFloorName === group.groupName;
  let collapsedClass = isCurrentFloor ? 'expanded' : 'collapsed';



  return (
    <React.Fragment key={blockIndex}>

      <div className={`search-result-group ${collapsedClass}`}>

        <div onClick={group_title_clicked} className='search-result-group__title'>
          <div>
            <span className="title-text">{title}</span>
            <span className="title-separator">&nbsp;</span>
            <span className="title-count">({totalCount})</span>
          </div>
        </div>

        <div className={`search-result-group__items`}>
          {renderedItems}
        </div>

      </div>
    </React.Fragment>
  );
};




/** 
 * @param {SearchResultsProps} props
 * 
 */
let renderSearchResults_NO_RESULTS = (props) => {
  
  let currentFilter = props.sData.search.searchFilter;
  
  let message = LabelManager.getByKey('mat.livesearch.no_results');
  
  if (currentFilter === "TOOLS") {
    message = LabelManager.getByKey('mat.livesearch.no_results_tools');
  }
  if (currentFilter === "MATERIALS") {
    message = LabelManager.getByKey('mat.livesearch.no_results_materials');
  }
  if (currentFilter === "LOCATIONS") {
    message = LabelManager.getByKey('mat.livesearch.no_results_locations');
  }
  
  return (
    <>
      <div className='search-result-by-type-block'>
        <div className='search-result-block__no-results'>
          {message}
        </div>
      </div>
    </>
  );
};


let renderSearchResults_LOADING = () => {
  return (
    <>
      <div className='search-result-by-type-block'>
        <div className='search-result-block__loading'>
          {/* {LabelManager.getByKey('mat.livesearch.no_results')} */}
          ...
        </div>
      </div>
    </>
  );
};










