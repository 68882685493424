import React from 'react';
import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {gridsNames} from '../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import * as AssetHelper from '../../../../../utils/assetHelper';
import {enumTypes, getEnumLabel} from '../../../../../utils/enumHelper';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {navigationStates} from '../../../../../enums/navigationStates';

require('./assetsManagementGrid.scss');

class AssetsManagementGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'objectType', filterName: 'objectType', getOptions: true},
    {fieldName: 'businessId', filterName: 'businessId', getOptions: true},
    {fieldName: 'objectType', filterName: 'assetLocationName', getOptions: true},
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},

  ];
  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }


  createColumnsConfig = () => {
    return [
      {
        fieldName: 'objectType',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'objectType',
        columnOptions: {
          valueGetter: (params) => {
            return params.data.objectType ? getEnumLabel(enumTypes.OBJECT_TYPE)(params.data.objectType) : '';
          },
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.assetId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ASSETS_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: AssetHelper.getNavigationStateByEntityType(params.data.objectType),
            };
          }
        }
      },
      {
        fieldName: 'locationName',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        columnOptions: {
          valueGetter: (params) => {
            return params.data.assetStatus ? getEnumLabel(enumTypes.ASSET_STATUS)(params.data.assetStatus)  : '';
          },
        }
      },
    ]
  }

  render() {
    return (
      <div className="assets-management-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_PS_MANAGEMENT_ASSETS}
              columnsConfig = {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }

}

export default reduxForm({
    form: 'assetsManagementGrid'
  }
)(AssetsManagementGrid);
