import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Async from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import cn from 'classnames';
import { Field } from 'redux-form';
import ValidationComponent from 'infrastructure/js/components/controls/ValidationComponent/validationComponent.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { components } from 'react-select';
import useLabels from 'infrastructure/js/hooks/useLabels';

import './combobox.scss';

export default class PL_ComboBoxForm extends React.PureComponent {
  render() {
    return <Field {...this.props} component={PL_ComboBox}></Field>;
  }
}

export class PL_ComboBox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.controls.combobox.');
  }

  onChange = (data) => {
    let oldValue = this.props.input.value;
    //Note: SingleValue combobox returns [] for empty value. Replace [] with null
    if (data && Array.isArray(data) && data.length === 0 && !this.props.isMulti) {
      data = null;
    }
    this.props.input.onChange(data);
    if (this.props.onChangeCallback) {
      //Create new instance, changes will not effect the source state.
      this.props.onChangeCallback(JSON.parse(JSON.stringify(data)), oldValue);
    }
  };

  errorClass() {
    let { touched, error } = this.props.meta;
    return touched && error ? ' invalid' : '';
  }

  indicatorSeparator = (props) => {
    return null;
  };

  //NOTE: Temp workaround for the react-select bug in Creatable.js
  //Must be removed when the react-select is fixed

  isValidNewOptionTemp = (inputValue, selectValue, options) => {
    if (!inputValue) {
      return false;
    }
    let returnValue = true;
    options.forEach((option) => {
      if (inputValue.toLowerCase() === option.label.toLowerCase()) {
        returnValue = false;
      }
    });
    return returnValue;
  };

  isOptionDisabled = (option) => {
    return !!option.isDisabled;
  };

  optionRenderer = (props) => {
    return <components.Option {...props}>{this.props.optionRenderer(props)}</components.Option>;
  };

  singleValueRenderer = (props) => {
    return <components.SingleValue {...props}>{this.props.singleValueRenderer(props)}</components.SingleValue>;
  };

  multiValueRenderer = (props) => {
    return <components.MultiValueLabel {...props}>{this.props.multiValueRenderer(props)}</components.MultiValueLabel>;
  };

  noOptionsRenderer = (props) => {
    return <components.NoOptionsMessage {...props}>{this.props.noOptionsRenderer(props)}</components.NoOptionsMessage>;
  };

  multiValueOverride = (props) => {
    return this.props.multiValueOverride(props);
  };

  multiValueRemoveRenderer = (props) => {
    return null;
  };

  createComboboxComponents = () => {
    let components = { IndicatorSeparator: this.indicatorSeparator };

    if (this.props.optionRenderer) {
      components.Option = this.optionRenderer;
    }

    if (this.props.singleValueRenderer) {
      components.SingleValue = this.singleValueRenderer;
    }
    if (this.props.multiValueRenderer) {
      components.MultiValueLabel = this.multiValueRenderer;
    }

    if (this.props.multiValueOverride) {
      components.MultiValue = this.multiValueOverride;
    }

    if (this.props.dropdownIndicatorRenderer) {
      components.DropdownIndicator = this.props.dropdownIndicatorRenderer;
    }

    if (!this.props.isMultiValueClearable) {
      components.MultiValueRemove = this.multiValueRemoveRenderer;
    }

    if (this.props.menuListRenderer) {
      components.MenuList = this.props.menuListRenderer;
    }

    if (this.props.noOptionsRenderer) {
      components.NoOptionsMessage  = this.noOptionsRenderer;
    }

    return components;
  };

  //Note: must be here to work on Tablet
  onBlur = (e) => {
  }

  renderInnerComponent() {
    let { className, options, input, meta, allowNewOption, isClearable, ...props } = this.props;
    let InnerComponent = allowNewOption ? Creatable : Select;

    if (this.props.asyncMode) {
      InnerComponent = allowNewOption ? AsyncCreatableSelect : Async;
    }

    let components = this.createComboboxComponents();

    return (
      <InnerComponent
        classNamePrefix={this.props.componentsClassNamePrefix || 'pl-select'}
        isClearable={isClearable}
        components={components}
        placeholder={this.props.placeholder || this.labels.get('placeholder')}
        options={options}
        isOptionDisabled={this.isOptionDisabled}
        getOptionValue={(option) => option['label']}
        isValidNewOption={this.isValidNewOptionTemp}
        {...input}
        {...props}
        onChange={this.onChange}
        onBlur={this.onBlur}
        formatCreateLabel={(inputValue) => {
          return `${this.labels.get('create')} "${inputValue}"`;
        }}
        noOptionsMessage={() => {
          return this.labels.get('noOptions');
        }}
        disabled={this.props.disabled}
      />
    );
  }

  render() {
    let {hideClearIndicator, className} = this.props;
    return (
      <span id={this.props.id} className={cn('pl-combobox', this.errorClass(), {'hide-clear-indicator': hideClearIndicator}, className)}>
        {this.renderInnerComponent()}
        <ValidationComponent {...this.props.meta} />
      </span>
    );
  }
}

PL_ComboBox.defaultProps = {
  allowNewOption: false,
  isMultiValueClearable: true,
  isClearable: true,
  className: '',
  hideClearIndicator: true,
};
PL_ComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  allowNewOption: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMultiValueClearable: PropTypes.bool,
  optionRenderer: PropTypes.func,
  singleValueRenderer: PropTypes.func,
  multiValueRenderer: PropTypes.func,
  multiValueOverride: PropTypes.func,
  dropdownIndicatorRenderer: PropTypes.func,
  menuListRenderer: PropTypes.func,
  noOptionsRenderer: PropTypes.func,
  componentsClassNamePrefix: PropTypes.string,
  className: PropTypes.string,
  hideClearIndicator: PropTypes.bool,
};

/**
 * PL_NoFormComboBox is a functional component that renders a combo box with various customization options.
 * It is a wrapper around the react-select component and stripped of any form library logic.
 */
export function PL_NoFormComboBox({
  id,
  options,
  onChangeCallback,
  allowNewOption=false,
  isClearable=true,
  isMultiValueClearable=true,
  optionRenderer: _optionRenderer,
  singleValueRenderer: _singleValueRenderer,
  multiValueRenderer: _multiValueRenderer,
  multiValueOverride: _multiValueOverride,
  dropdownIndicatorRenderer,
  menuListRenderer,
  noOptionsRenderer,
  componentsClassNamePrefix,
  className='',
  hideClearIndicator=true,
  disabled,
  asyncMode,
  placeholder,
  ...rest
}) {
  const labels = useLabels('mat.controls.combobox.');

  const onChange = (data) => {
    if (onChangeCallback) {
      onChangeCallback(JSON.parse(JSON.stringify(data)));
    }
  };

  const indicatorSeparator = (props) => {
    return null;
  };

  const isValidNewOptionTemp = (inputValue, selectValue, options) => {
    if (!inputValue) {
      return false;
    }
    let returnValue = true;
    options.forEach((option) => {
      if (inputValue.toLowerCase() === option.label.toLowerCase()) {
        returnValue = false;
      }
    });
    return returnValue;
  };

  const isOptionDisabled = (option) => {
    return !!option.isDisabled;
  };

  const optionRenderer = (props) => {
    return <components.Option {...props}>{_optionRenderer(props)}</components.Option>;
  };

  const singleValueRenderer = (props) => {
    return <components.SingleValue {...props}>{_singleValueRenderer(props)}</components.SingleValue>;
  };

  const multiValueRenderer = (props) => {
    return <components.MultiValueLabel {...props}>{_multiValueRenderer(props)}</components.MultiValueLabel>;
  };

  const multiValueOverride = (props) => {
    return _multiValueOverride(props);
  };

  const multiValueRemoveRenderer = (props) => {
    return null;
  };

  const createComboboxComponents = () => {
    let components = { IndicatorSeparator: indicatorSeparator };

    if (_optionRenderer) {
      components.Option = optionRenderer;
    }

    if (_singleValueRenderer) {
      components.SingleValue = singleValueRenderer;
    }
    if (_multiValueRenderer) {
      components.MultiValueLabel = multiValueRenderer;
    }

    if (_multiValueOverride) {
      components.MultiValue = multiValueOverride;
    }

    if (dropdownIndicatorRenderer) {
      components.DropdownIndicator = dropdownIndicatorRenderer;
    }

    if (!isMultiValueClearable) {
      components.MultiValueRemove = multiValueRemoveRenderer;
    }

    if (menuListRenderer) {
      components.MenuList = menuListRenderer;
    }

    if (noOptionsRenderer) {
      components.NoOptionsMessage = noOptionsRenderer;
    }

    return components;
  };

  const renderInnerComponent = () => {
    let InnerComponent = allowNewOption ? Creatable : Select;

    if (asyncMode) {
      InnerComponent = allowNewOption ? AsyncCreatableSelect : Async;
    }

    let components = createComboboxComponents();

    return (
      <InnerComponent
        classNamePrefix={componentsClassNamePrefix || 'pl-select'}
        isClearable={isClearable}
        components={components}
        placeholder={placeholder || labels.get('placeholder')}
        options={options}
        isOptionDisabled={isOptionDisabled}
        getOptionValue={(option) => option['label']}
        isValidNewOption={isValidNewOptionTemp}
        {...rest}
        onChange={onChange}
        formatCreateLabel={(inputValue) => {
          return `${labels.get('create')} "${inputValue}"`;
        }}
        noOptionsMessage={() => {
          return labels.get('noOptions');
        }}
        disabled={disabled}
      />
    );
  };

  return (
    <span id={id} className={cn('pl-combobox', {'hide-clear-indicator': hideClearIndicator}, className)}>
      {renderInnerComponent()}
    </span>
  );
}

PL_NoFormComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  allowNewOption: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMultiValueClearable: PropTypes.bool,
  optionRenderer: PropTypes.func,
  singleValueRenderer: PropTypes.func,
  multiValueRenderer: PropTypes.func,
  multiValueOverride: PropTypes.func,
  dropdownIndicatorRenderer: PropTypes.func,
  menuListRenderer: PropTypes.func,
  noOptionsRenderer: PropTypes.func,
  componentsClassNamePrefix: PropTypes.string,
  className: PropTypes.string,
  hideClearIndicator: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  disabled: PropTypes.bool,
  asyncMode: PropTypes.bool,
  placeholder: PropTypes.string,
};
