// @ts-check

import React, { useState } from 'react';
import { CAMERA_EASING_MODE, Mappedin, MapView } from '@mappedin/mappedin-js';
import { livemapIcons } from '../../../../../assets/svg/index';


import './mapCameraPresetsOverlay.scss';
import LabelManager from '../../Utils/LabelManager';


/**
 * @typedef {import("./../../../../actions/LiveMap/types/liveMapPageTypes").Pl_Map} Pl_Map
 * 
 */




/**
 * @param {any} props
 */
const MapCameraPresetsOverlay = (props) => {

  // console.log('=========== OVERLAY RENDER ===> ', props);

  /** @type {MapView | undefined} */
  let mapView = props.mapView;
  if (!mapView) {
    return null;
  }


  const goCameraIsometric = () => {
    if (!mapView) {
      return;
    }

    // /** @type {Pl_Map} */
    // let map = mapView.currentMap;
    // let coordinate = map.createCoordinateByXY(1723, 2246);


    // mapView.Camera.animate({
    //   rotation: -0.3,
    //   tilt: 0.9,
    //   zoom: 2500,
    //   position: coordinate
    // }, {
    //   duration: 500,
    //   easing: CAMERA_EASING_MODE.EASE_OUT
    //   // easing: CAMERA_EASING_MODE.EASE_IN
    // });

    /** @type {Pl_Map} */
    let map = mapView.currentMap;
    let camera_Default = map.pl_Metadata?.mapCalculations?.camera_Default;
    if (!camera_Default) {
      return;
    }


    mapView.Camera.animate(camera_Default, {
      duration: 500,
      easing: CAMERA_EASING_MODE.EASE_OUT
      // easing: CAMERA_EASING_MODE.EASE_IN
    });

  };


  const goCameraTopDown = () => {
    if (!mapView) {
      return;
    }

    // let map = mapView.currentMap;
    // let coordinate = map.createCoordinateByXY(1820, 1550);

    // mapView.Camera.animate({
    //   rotation: 0,
    //   tilt: 0,
    //   zoom: 4000,
    //   position: coordinate
    // }, {
    //   duration: 500,
    //   easing: CAMERA_EASING_MODE.EASE_OUT
    //   // easing: CAMERA_EASING_MODE.EASE_IN
    // });
    
    /** @type {Pl_Map} */
    let map = mapView.currentMap;
    let camera_Top = map.pl_Metadata?.mapCalculations?.camera_Top;
    if (!camera_Top) {
      return;
    }


    mapView.Camera.animate(camera_Top, {
      duration: 500,
      easing: CAMERA_EASING_MODE.EASE_OUT
      // easing: CAMERA_EASING_MODE.EASE_IN
    });
    
    

  };


  const goCameraInfo = () => {
    if (!mapView) {
      return;
    }

    let position = mapView.Camera.position;
    let map = mapView.currentMap;

    let positionDataMessage = `mapView.Camera.position ===>
      (x:${position.x}, y:${position.y}), 
      ((lat, ln): (${position.lat}, ${position.lon}),
      (rotation: ${mapView.Camera.rotation}, tilt: ${mapView.Camera.tilt}, zoom: ${mapView.Camera.zoom}),
      (map width: ${map.width}, map height: ${map.height})
    `;


    console.log(positionDataMessage);


  };




  return (
    <div className="live-map-overlay-camera-presets">


      <div className="live-map-camera-presets-controls">

        {/* <button id='btnCameraInfo' onClick={goCameraInfo}>INFO</button> */}
        <button id='btnCameraIsometric' className='first' onClick={goCameraTopDown}>

          <div className='camera-button-content-wrap'>
            <span className="camera-button-icon">{livemapIcons.liveMapCameraTop()}</span>
            <span className="camera-button-text-1">{LabelManager.getByKey('mat.livemap.controls.view.top')}</span>
            <span className="camera-button-text-2">{LabelManager.getByKey('mat.livemap.controls.view.view')}</span>
          </div>
          
        </button>



        <button id='btnCameraTopDown' className='last' onClick={goCameraIsometric}>
          
          <div className='camera-button-content-wrap'>
            <span className="camera-button-icon">{livemapIcons.liveMapCameraDefault()}</span>
            <span className="camera-button-text-1">{LabelManager.getByKey('mat.livemap.controls.view.default')}</span>
            <span className="camera-button-text-2">{LabelManager.getByKey('mat.livemap.controls.view.view')}</span>
          </div>
          
        </button>

      </div>

    </div>
  );

};


export default MapCameraPresetsOverlay;




