// @ts-check

import { useMemo } from "react";
import { useMapClick } from "./Mappedin/useMapClick";
import React from "react";
import ClickProcessor from "../Logic/ClickProcessor";

// import { jsxActions as actions } from './../../../actions/LiveMap/liveMapPageActions';


/**
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").MapNodeRef} MapNodeRef
 * @typedef {import("./../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 * 
 */


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * 
 */
export default function useHandleMapClick(refContext) {

  let mapView = refContext.current.refMapView.current;

  /////////////////////////////////////////////////////
  // Memoize handler that closes over refContext.
  //
  let handler = useMemo(() => {
    let h = getMapClickHandler(refContext);
    return h;
  }, [mapView]);


  /////////////////////////////////////////////////////
  // Whenever handler function or mapView changes,
  // this hook will destroy the previous event listener
  // and create a new one.
  //
  // useMapClick(mapView, (p) => { console.log('p ===> ', p); });
  //
  useMapClick(mapView, handler);


  return [

  ];
}



/////////////////////////////////////////////////////


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 * 
 * @returns {(event: import("@mappedin/mappedin-js").TMapClickEvent) => void}
 */
export let getMapClickHandler = (refContext) => {

  // console.log('getMapClickHandler ===> ');

  /**
   * @param {import("@mappedin/mappedin-js").TMapClickEvent} payload
   */
  let mapClickHandler = (payload) => {
    // console.log('mapClickHandler ===> ', payload);
    ClickProcessor.processMapClick(refContext, payload);
  };

  return mapClickHandler;

};







