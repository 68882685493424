import Network from 'infrastructure/js/modules/network';
import { dialogsNames } from '../../../enums/dialogsNames';
import * as daHelper from '../../Dialogs/dialogActionsHelper';
import * as ImportResponseHelper from '../../Wizards/Common/ImportResponseHelper';
import { api as messageDialogApi } from '../../MessageDialog/messageDialogActions.js';
import { uploadTemperatureLogFile, reduceShipmentAssetsEtls } from '../../../services/Shipments/shipmentsService';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

const config = {
  getSubmitMethod: () => null,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.PROCESS_TEMPERATURE_LOG_DIALOG),
  PROCESS_TEMPERATURE_LOG_DIALOG_FILE_HAS_ERRORS: 'PROCESS_TEMPERATURE_LOG_DIALOG_FILE_HAS_ERRORS',
  PROCESS_TEMPERATURE_LOG_DIALOG_FILE_PROCESSED: 'PROCESS_TEMPERATURE_LOG_DIALOG_FILE_PROCESSED',
  PROCESS_TEMPERATURE_LOG_DIALOG_FILE_CLEAR: 'PROCESS_TEMPERATURE_LOG_DIALOG_FILE_CLEAR',
};

const actions = {
  ...daHelper.getActions(dialogsNames.PROCESS_TEMPERATURE_LOG_DIALOG),

  temperatureFileProcessed(payload) {
    return { type: actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_FILE_PROCESSED, payload };
  },
  showErrors: function (payload) {
    return { type: actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_FILE_HAS_ERRORS, payload };
  },
  clearProcessedData: function () {
    return { type: actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_FILE_CLEAR };
  },
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  processTemperatureLogFile(dispatch, getState) {
    return function (id, file) {
      dispatch(actions.clearProcessedData());
      dispatch(actions.setBusy(true));

      return uploadTemperatureLogFile(id, file)
        .then((response) => {
          dispatch(actions.setBusy(false));

          if (!Network.isResponseValid(response)) {
            console.error('Failed to process temperature log file.', response);
            dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
            return null;
          }

          const { message, minutesToReduceFromEtl, attachmentId } = response.data;
          const processData = {
            message: createLabelHelper('').get(message.labelKey, undefined, { timeToReduce: message.params.timeToReduce }),
            minutesToReduceFromEtl,
            attachmentId,
          };

          dispatch(actions.temperatureFileProcessed(processData));

          return response.data;
        })
        .catch((err) => {
          console.error('Failed to process temperature log file.', err);
          messageDialogApi.responseError(dispatch, getState)();
          dispatch(actions.setBusy(false));
        });
    };
  },

  submit(dispatch, getState) {
    return function (data, reloadParentComponent) {
      dispatch(actions.setBusy(true));

      reduceShipmentAssetsEtls(data)
        .then((response) => {
          dispatch(actions.setBusy(false));
          dispatch(actions.hide());

          if (!Network.isResponseValid(response)) {
            console.error('Failed to update shipment assets etls.', response);
            messageDialogApi.responseError(dispatch, getState)(response);
            return null;
          }

          reloadParentComponent?.();
        })
        .catch((err) => {
          dispatch(actions.hide());
          console.error('Failed to update shipment assets etls.', err);
          messageDialogApi.responseError(dispatch, getState)();
          dispatch(actions.setBusy(false));
        });
    };
  },
};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  processTemperatureLogFile(id, file) {
    return function (dispatch, getState) {
      api.processTemperatureLogFile(dispatch, getState)(id, file);
    };
  },
};
