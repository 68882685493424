import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions';
import { api as gridApi} from './pickListsGridActions';
import {navigationStates} from '../../enums/navigationStates';
import {api as messageDialogApi} from '../MessageDialog/messageDialogActions';
import Network from 'infrastructure/js/modules/network';
import * as rollService from '../../services/Rolls/rollsService';
import * as pickListsService from '../../services/PickLists/pickListsService';
import {api as locationPageApi} from '../LocationPage/locationPageActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    api.init(dispatch, getState)();
  }
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    api.reload(dispatch, getState)();
  }
};

jsxActions.clearFilters = function () {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  }
};

jsxActions.deletePickLists = function(data) {
  return function(dispatch, getState) {
    api.deletePickListsConfirmationDialog(dispatch, getState)(data);
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.init = function(dispatch, getState) {
  return function() {
    const id = -1;
    const type = navigationStates.PICK_LISTS;
    const name = createLabelHelper('mat.header.navigator.predefinedlists.').get('pickLists');

    navigatorApi.setData(dispatch, getState)({id, type, name});
  }
}

api.removeFromPickList = function (dispatch, getState) {
  return function (rows) {

    let rollIds = rows.map((roll) => { return roll.id });
    rollService.removeFromPickList(rollIds).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('pick list - remove rolls from pick list failed');
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }
    });

    if (PermissionManager.hasLocationPickListTabPermissions()) {
      return locationPageApi.reload(dispatch, getState)();
    }
    gridApi.reload(dispatch, getState)();
  }
};

api.reload = function(dispatch, getState) {
  return function() {
    return gridApi.reload(dispatch, getState)();
  }
};

api.deletePickListsConfirmationDialog = function(dispatch, getState) {
  return function( data) {
    let labels = createLabelHelper('mat.pickLists.page.deletePickLists.confirmation.');
    let dialogLabels = createLabelHelper('mat.dialog.');

    let confirmationConfig = {
      title: labels.get('title'),
      message: labels.get('message', '', {count: data.length}),
      submitButtonLabel: dialogLabels.get('remove'),
      cancelHandler: messageDialogApi.close(dispatch, getState),
      submitHandler: () => api.deletePickLists(dispatch, getState)(data)
    }
    messageDialogApi.showConfirmation(dispatch, getState)(confirmationConfig);
  }
};

api.deletePickLists = function(dispatch, getState) {
  return function(data) {

    let ids = data?.map((item) => item.id) ?? [];

    messageDialogApi.close(dispatch, getState)();

    pickListsService.deletePickLists({ids}).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('pick lists removing failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return {success: false};
      }

      if (response.dataList?.length) {
        let title = createLabelHelper('mat.pickLists.page.deletePickLists.confirmation.').get('result', null, { count: response.dataList.length});
        let fnCloseDialog = messageDialogApi.close(dispatch, getState);
        let errors = response.dataList.filter(data => data.applicationResponseStatus.statusType === 'ERROR');
        let successes = response.dataList.filter(data => data.applicationResponseStatus.statusType === 'OK');
        let data = {
          numberOfSucceededItems: successes?.length,
          numberOfFailedItems: errors?.length,
        };
        let messageDialogDescriptor = dialogHelper.BuildDialogDescriptorForBO(data, fnCloseDialog, title, false);
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }

      gridApi.reload(dispatch, getState)();
    });
  }
};
