import Network from '../../../../infrastructure/js/modules/network';

export function fetchLots() {
  return Network.get('lots');
}

export function fetchLotsGrid(query = {}) {
  return Network.post('lots/items?active=true', query);
}

export function fetchLotById(id) {
  return Network.get(`lots/${id}`);
}