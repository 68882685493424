import PropTypes from 'prop-types';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import'./additionalFieldListItem.scss';


const ComboboxPortal = withPortal(Combobox);

const AdditionalFieldListItem = ({options, name}) => {

  return (<div className="additional-field-list-item">

    <TextField id={name + '.[displayName]'}
               name={name + '.[displayName]'}
               className="short-textfield"
               validate={Validation.required}
               maxLength={40}/>

    <ComboboxPortal id={ name + '.[type].[type]'}
              name={ name + '.[type].[type]'}
              options={options}
              parse={Parse.comboValueOnly()}
              format={Format.findOptionByValue(options)}
              validate={Validation.dropdown.required}
              menuPlacement="auto"
    />
  </div>);
}

AdditionalFieldListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
};

export default AdditionalFieldListItem;
