import Network from 'infrastructure/js/modules/network';


export function fetchAllLocations() {
  return Network.get('locations');
}

export function fetchOrderedLocations() {
  return Network.get('locations/ordered');
}

export function fetchActiveLocations(excludedLocationTypes = null) {
  const excludedLocationTypesQueryParam = excludedLocationTypes ? `&excludeLocationTypes=${excludedLocationTypes.join(',')}` : '';
  return Network.get(`locations?isActive=true${excludedLocationTypesQueryParam}`);
}

export function fetchLocationDetails(locationId) {
  return Network.get(`locations/${locationId}`);
}

export function fetchLocationAssetsCounts(locationId) {
  return Network.get(`locations/${locationId}/assetscount`);
}

//TODO - replace with a server call to get only the locations count
export function fetchLocationCount() {
  return Network.get("locations/count");
}

export function fetchLocationAlertsCounts(locationId) {
  return Network.get(`locations/${locationId}/alertscount`);
}

export function getActivityData(stationID, query = {}){
  return Network.post(`locations/${stationID}/activities`, query);
}

export function fetchActivityCharts(stationID, query = {}){
  return Network.get(`locations/${stationID}/activitiescount`, query);
}

export function fetchLocations(query={}) {
  return Network.post('locations/items', query);
}

export function createLocation(data) {
  return Network.post('locations', data);
}

export function updateLocations(data) {
  return Network.put('locations/update' , data);
}

export function createSection(data) {
  return Network.post('sections', data);
}

export function updateSection(data) {
  return Network.put('sections' , data);
}


export function validateMovement(data) {
  return Network.post('locations/validate-movement', data);
}

export function moveAssets(data, callback) {
  //data.assetIds = data.assetIds.concat(_createDummyIds(100));
  let destination = data.destination;
  delete data.destination;
  return Network.polling.post(`locations/multiMove/${destination}`, data, {callback});
}

export function startMoveAssets(data) { //used by the batch operation with Progress bar
  let destination = data.destination;
  delete data.destination;
  return Network.post(`locations/multiMove/${destination}`, data);
}

export function bringAssets(data, callback) {
  //data.assetIds = data.assetIds.concat(_createDummyIds(200));
  let destination = data.destination;
  delete data.destination;
  return Network.polling.post(`locations/multiMove/${destination}`, data, {callback});
}

export function startBringAssets(data) { //used by the batch operation with Progress bar
  let destination = data.destination;
  delete data.destination;
  return Network.post(`locations/multiMove/${destination}`, data );
}

export function updateAssetsStatus(data, callback) {
  //data.assetsIds = data.assetsIds.concat(_createDummyIds(200));
  return Network.polling.post('locations/updateAssetsStatus' , data, {callback});
}
export function removeLocations(ids) {
  return Network.delete('locations/', {locationIds: ids.ids});
}

export function fetchLocationsOrStations(data) {

  let isStation = data.isStation;
  delete data.isStation;

  let url = `locations/searchByDataQuery?isActive=true&isStation=${isStation}`;
  return Network.post(url, data);
}

export function fetchPlant() {
  return Network.get('plant');
}

export function fetchLocationsByLocationType(locationType) {
  return Network.get(`locations?includeLocationTypes=${locationType}`);
}
