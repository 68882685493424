import React from 'react';
import { reduxForm } from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';
import { navigationStates } from '../../../../../enums/navigationStates';
import LastOperationCell from '../../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import {
  alertStatus,
  getAlertColumnFilterSections,
} from '../../../../../utils/alertFilterSectionHelper';
import ToolMoreInfoCell from '../../../../Common/CustomGridCells/ToolMoreInfoCell/toolMoreInfoCell';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell';
import { filterAlignmentTypes } from 'infrastructure/js/enums/filterAlignmentTypes';
import ToolTypeHelper from '../../../../../utils/toolTypeHelper';
import {enumTypes, getEnumLabel} from '../../../../../utils/enumHelper';

require('./toolsGrid.scss');

class ToolsGrid extends React.PureComponent {
  filterConfig = [
    { fieldName: 'assetAlert', filterName: 'assetAlert' },
    { fieldName: 'maxNumOfCycles', filterName: '' },
    { fieldName: 'currentCyclesNumber', filterName: '' },
    { fieldName: 'toolStatus', filterName: 'toolStatus', getOptions: true },
    { fieldName: 'toolType.businessId', filterName: 'toolType', getOptions: true },
    { fieldName: 'businessId', filterName: 'businessId', getOptions: false },
    { fieldName: 'toolType.toolCategory.businessId', filterName: 'toolCategoryBusinessId', getOptions: true },
    // { fieldName: 'location', filterName: 'assetLocationName', getOptions: true },
    { fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true },
    /* {fieldName: 'm_maintenanceUsageTime', filterName: 'maintenanceUsageTime', getOptions: true}*/
    { fieldName: 'toolType.configurationType', filterName: 'configurationType', getOptions: true },
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.toolStatusLabels = createLabelHelper('enum.tool.status.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'assetAlert',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([
          alertStatus.NO_ALERT,
          alertStatus.WARNING,
          alertStatus.ERROR,
        ]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              cyclesCount: params.data.currentCyclesNumber,
              toolUsageTime: params.data.m_maintenanceUsageTime,
            };
          },
          sort: 'desc',
        },
      },
      {
        fieldName: 'toolType.businessId',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return ToolTypeHelper.getToolTypeFullLabel(
              data.toolType.description,
              data.toolType.businessId
            );
          },
        },
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.toolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.TOOL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.TOOL,
            };
          },
        },
      },
      {
        fieldName: 'toolType.toolCategory.businessId',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolCategoryBusinessId',
        columnOptions: {
          valueGetter: (params) => {
            return params.data.toolType?.toolCategory?.businessId || ''
          },
        },
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        width: 215,
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
          valueGetter: (params) => {
            return params.data.lastOperation || ''
          },
        },
      },
      {
        fieldName: 'moreInfo',
        title: this.labels.get('columns.nextMaintenance.title'),
        filterType: filterTypes.NONE,
        width: 200,
        columnOptions: {
          cellComponent: ToolMoreInfoCell,
          sortable: false,
          valueGetter: (params) => {
            return {
              utl: params.data.m_maintenanceUsageTime,
              cyclesCount: params.data.currentCyclesNumber,
            };
          },
        },
      },
      {
        fieldName: 'toolStatus',
        title: this.labels.get('columns.progressStatus.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterName: 'toolStatus',
        width: 210,
        columnOptions: {
          valueGetter: (params) => {
            return params.data.toolStatus ? this.toolStatusLabels.get(params.data.toolStatus) : '';
          },
        },
      },
      {
        fieldName: 'toolType.configurationType',
        title: this.labels.get('columns.kitTypesConfiguration.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'configurationType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.TOOL_CONFIGURATION_TYPE)(params.data.toolType?.configurationType);
          },
        }
      },
    ];
  };

  getRowHeight() {
    return 100;
  }

  render() {
    return (
      <div className="tools-grid">
        <Grid
          gridName={gridsNames.LOCATION_ASSET_TOOLS}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
          gridProps={{
            getRowHeight: this.getRowHeight,
          }}
        />
      </div>
    );
  }
}

export default reduxForm({
  form: 'assetsViewToolsGrid',
})(ToolsGrid);
