import { Map } from 'immutable';
import { actionTypes as actionTypes } from '../../../actions/Administration/LoginManagementPage/promotionsPageActions';


let defaultState = {
  loading: false,
  slideSpeed: 0,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case actionTypes.PROMOTIONS_PAGE_SET_BUSY:
      return state.set('loading', action.payload);
    case actionTypes.PROMOTIONS_PAGE_FETCH_SETTINGS_FINISHED:
      return state.set('slideSpeed', action.payload);

    default:
      return state;
  }
}



