import Network from 'infrastructure/js/modules/network';
import {List} from 'immutable';
import * as tagsService from '../../../services/Tags/tagsService';
import * as projectsService from '../../../services/Projects/projectsService';
import * as partTypesService from '../../../services/PartTypes/partTypesService';
import * as groupsService from '../../../services/Groups/groupsService';
import * as kitTypesService from '../../../services/KitTypes/kitTypesService';
import * as additionalFieldsService from '../../../services/Administration/additionalFieldsService';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi } from '../../LocationPage/locationPageActions.js';
import { api as printRfidTagsApi} from '../../../actions/PrintRfidActions/printRfidActions';
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
import * as daHelper                from '../dialogActionsHelper';
import {dialogsNames}             from '../../../enums/dialogsNames';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';

const config = {
  getSubmitMethod: () => groupsService.createGroup,
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.CREATE_GROUP_DIALOG),
  CREATE_GROUP_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED:     'CREATE_GROUP_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED',
  CREATE_GROUP_DIALOG_RESET_ASSETS_FOR_WO:              'CREATE_GROUP_DIALOG_RESET_ASSETS_FOR_WO',
};

const actions = {
  ...daHelper.getActions(dialogsNames.CREATE_GROUP_DIALOG),
  fetchAssetsForWoFinished: function(payload) {
    return {type: actionTypes.CREATE_GROUP_DIALOG_FETCH_ASSETS_FOR_WO_FINISHED, payload: payload };
  },
  resetAssetsForWo: function(payload) {
    return {type: actionTypes.CREATE_GROUP_DIALOG_RESET_ASSETS_FOR_WO, payload: payload };
  },
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  fetchDialogData(dispatch, getState) {
    return function () {

      let fetchProjectsPromise = projectsService.fetchActiveProjects();
      let fetchPartsPromise = partTypesService.fetchAllParts();
      let fetchTagsPromise = tagsService.fetchTags();
      let fetchKitTypesPromise = kitTypesService.fetchActiveKitTypes();   //TODO: L CONT what to fetch? should be used for KITTYPE_ORIENTED orgs only
      let fetchGroupTypesPromise = groupsService.getActiveGroupTypes();
      let fetchAdditionalFieldsPromise = additionalFieldsService.fetchAdditionalFields({entityType: getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')});

      return Promise.all([fetchProjectsPromise, fetchPartsPromise, fetchTagsPromise, fetchKitTypesPromise, fetchGroupTypesPromise, fetchAdditionalFieldsPromise]).then((allResults) => {

        const invalidResponse = allResults.find(response => {
          return !Network.isResponseValid(response);
        });

        if(invalidResponse){
          console.error('Fetch Container asset data failed');
          messageDialogApi.responseError(dispatch, getState)(null);
          return {success: false};
        }

        let projects = allResults[0].dataList ? allResults[0].dataList.map((obj) => {
          return {value: obj.id, label: obj.businessId}
        }) : [];
        let parts = allResults[1].dataList ? allResults[1].dataList.map((obj) => {
          return {value: obj.id, label: obj.businessId}
        }) : [];
        let tags = allResults[2].dataList ? allResults[2].dataList.map((obj) => {
          return {value: obj.id, label: obj.tagKey}
        }) : [];
        let kitTypes = allResults[3].dataList ? allResults[3].dataList : [];

        let groupTypes = allResults[4]?.dataList?.map((obj) => {
          return {value: obj.id, label: obj.businessId}
        }) || [];

        let additionalFields = allResults[5].dataList ? allResults[5].dataList : [];

        let dialogData = {projects, parts, tags, kitTypes, groupTypes, additionalFields};

        dispatch(actions.fetchDialogDataReady(dialogData));
        return dialogData;
      });
    }
  },

  generateAssetId: function(dispatch, getState) {
    return function() {
      return groupsService.generateGroupId()
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error('Generate Container Id Failed');
            return {success: false};
          }
          return response.data;
        });
    };
  },

  fetchAssetsForWo: function(dispatch, getState) {
    return function(woId, fetchEntitiesFilters) {
      dispatch(actions.setBusy(true));

      workOrdersService.fetchWoRelatedAssets(woId, fetchEntitiesFilters)
        .then((response) => {
          if(!Network.isResponseValid(response)) {
            console.error('Fetch assets for WorkOrder Failed');
            return {success: false};
          }

          // let assets = response.dataList ? response.dataList.filter((item) => {return item.objectType.toUpperCase() === 'KIT'}) : List()
          let assets = response.dataList || List();

          dispatch(actions.fetchAssetsForWoFinished({woId : woId, assets: List(assets)}));
        });
    };
  },

  resetAssetsForWo: function(dispatch, getState) {
    return function() {
      dispatch(actions.resetAssetsForWo());
    };
  },

  printRfidTag: function(dispatch, getState) {
    return function (response, locationId) {
      messageDialogApi.close(dispatch, getState)();

      if (response && response.data && response.data.id) {
        let data = {
          boEntityFilter: [FetchEntitiesFilters.ACTIVE],
          entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')],
          preselectedAssets: List([response.data]),
          disableOnPreselect: true,
        }
        printRfidTagsApi.printRfidTags(dispatch, getState)(data);
      }
    }
  },

  getMessageDialogBuilderData(dispatch, getState) {
    return function (response) {
      let locationId = locationPageApi.getLocationId(dispatch, getState)();
      return [
        response.data,
        messageDialogApi.close(dispatch, getState),
        () => api.printRfidTag(dispatch, getState)(response, locationId)

      ];
    }
  }

};

/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit: function (data, messageDialogBuilder, reloadParentComponent) {
    return function (dispatch, getState) {
      data.locationId = locationPageApi.getLocationId(dispatch, getState)();
      return api.submit(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
    };
  },

  generateAssetId: function () {
    return function (dispatch, getState) {
      return api.generateAssetId(dispatch, getState)();
    };
  },

  fetchAssetsForWo: function( woId, fetchEntitiesFilters) {
    return function(dispatch, getState) {
      api.fetchAssetsForWo(dispatch, getState)(woId, fetchEntitiesFilters);
    };
  },

  resetAssetsForWo: function() {
    return function(dispatch, getState) {
      api.resetAssetsForWo(dispatch, getState)();
    };
  },

  fetchAdditionalFields: function() {
    return function(dispatch, getState) {
      return api.fetchAdditionalFields(dispatch, getState)();
    };
  },
};




