import PropTypes from 'prop-types';
import {useEffect} from 'react';
import { reduxForm } from 'redux-form';
import useLabels from 'infrastructure/js/hooks/useLabels';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';

import './createPromotionDialog.scss';

function CreatePromotionDialog({
                               sData,
                               show,
                               actions,
                               handleSubmit,
                               initialize,
                               reloadParentComponent=null,
                             }) {

  const dialogLabels = useLabels('mat.dialog.');
  const labels = useLabels('mat.administration.dialog.createPromotion.');
  const { submit, hide } = actions;
  const itemToEdit = sData.get('itemToEdit');
  const isEditMode = !!itemToEdit;


  useEffect(() => {
    const initialData = {};

    if (isEditMode) {
      initialData.promotionName = itemToEdit.businessId;
      initialData.promotionHeader = itemToEdit.header;
      initialData.promotionDescription = itemToEdit.description;
      initialData.pictureUrl = itemToEdit.pictureUrl;
      initialData.actionLabel = itemToEdit.callForActionLabel;
      initialData.actionUrl = itemToEdit.callForActionUrl;
    }

    initialize(initialData);
  }, []);

  const onSubmit = (data) => {
    const submitData = {
      businessId: data.promotionName,
      header: data.promotionHeader,
      description: data.promotionDescription,
      pictureUrl: data.pictureUrl,
      callForActionLabel: data.actionLabel,
      callForActionUrl: data.actionUrl,
    }

    if (itemToEdit) {
      submitData.id = itemToEdit.id;
    }

    submit(submitData, null, reloadParentComponent, {isEditMode: isEditMode});
  };

  const dialogButtons = {
    left: [
      {
        id: 'cancel',
        text: dialogLabels.get('cancel'),
        action: hide,
      },
    ],
    right: [
      {
        id: 'submit',
        text: isEditMode ? dialogLabels.get('edit') : dialogLabels.get('create'),
        bsStyle: 'primary',
        loading: sData.get('loading'),
        action: handleSubmit(onSubmit),
        disabled: sData.get('loading'),
      },
    ],
  };

  return (
    <Dialog
      id="create-promotion-dialog"
      className="create-promotion-dialog"
      titleText={isEditMode ? labels.get('header.title.edit') : labels.get('header.title.create')}
      show={show}
      onHide={hide}
      sData={sData}
      footerButtons={dialogButtons}
    >
      <InputSection label={labels.get('name') + '*'} htmlFor="promotionName" className="inline left-side no-margin">
        <TextField
          id="promotionName"
          name="promotionName"
          className="short-textfield"
          validate={Validation.required}
        />
      </InputSection>

      <InputSection label={labels.get('header') + '*'} htmlFor="promotionHeader" className="full-width">
        <TextField
          id="promotionHeader"
          name="promotionHeader"
          className="short-textfield"
          validate={Validation.required}
        />
      </InputSection>

      <InputSection label={labels.get('description') + '*'} htmlFor="promotionDescription" className="full-width">
        <TextAreaField id="promotionDescription" name="promotionDescription"
                       validate={Validation.required}
        />
      </InputSection>

      <InputSection label={labels.get('pictureUrl') + '*'} htmlFor="pictureUrl" className="full-width">
        <TextField
          id="pictureUrl"
          name="pictureUrl"
          className="short-textfield"
          validate={Validation.required}
        />
      </InputSection>

      <InputSection label={labels.get('actionLabel') + '*'} htmlFor="actionLabel" className="full-width">
        <TextField
          id="actionLabel"
          name="actionLabel"
          className="short-textfield"
          validate={Validation.required}
        />
      </InputSection>

      <InputSection label={labels.get('actionUrl') + '*'} htmlFor="actionUrl" className="full-width">
        <TextField
          id="actionUrl"
          name="actionUrl"
          className="short-textfield"
          validate={Validation.required}
        />
      </InputSection>

    </Dialog>
  );
}

export default reduxForm({
  form: 'createPromotionDialog',
})(CreatePromotionDialog);

CreatePromotionDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  reloadParentComponent: PropTypes.any,
};
