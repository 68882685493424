import React from 'react';
import PropTypes from 'prop-types';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Parse from 'infrastructure/js/components/controls/controlsParse';
import Format from 'infrastructure/js/components/controls/controlsFormat';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Label from 'infrastructure/js/components/Label/label.js';
import cn from 'classnames';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import './toolTypeListItem.scss';

const ComboboxPortal = withPortal(Combobox);

export default class ToolTypeListItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      quantity: props.preSelectedItem?.quantity || 1,
    }
  }

  onItemChangeCallback = (value, oldValue) => {
    this.props.onChangeCallback?.(value, oldValue, this.props.index);
  };

  getComponentToRender = (props) => {
    return props && props.data ? <Label text={props.data.label} /> : null;
  };

  render() {
    const { options, name  } = this.props;

    return (
      <div className={cn('tool-type-list-item')}>
        <div className="column">
          <ComboboxPortal
            id={name + '.[toolTypeName]'}
            name={name + '.[toolTypeName]'}
            options={options}
            parse={Parse.comboValueOnly()}
            format={Format.findOptionByValue(options)}
            onChangeCallback={this.onItemChangeCallback}
            validate={Validation.required}
            singleValueRenderer={this.getComponentToRender}
            optionRenderer={this.getComponentToRender}
          />
        </div>

        <div className="column">
          <TextField id={ name + '.[quantity]'}
                     name={ name + '.[quantity]'}
                     normalize={Normalize.number(true, 1, 99999)}
                     validate={Validation.required}
          />
        </div>
      </div>
    );
  }
}

ToolTypeListItem.defaultProps = {
  options: [],
};

ToolTypeListItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
};
