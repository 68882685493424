import Network from 'infrastructure/js/modules/network';
import * as schedulerService from '../../services/Scheduler/schedulerService';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions';
import { navigationStates } from '../../enums/navigationStates';
import { api as systemApi } from '../System/systemActions.js';
import { api as gridApi } from './schedulerOperationsGridActions';
import { api as scheduleTaskDialogApi } from './scheduleTaskDialogActions';
import { api as autoSchedulerDialogApi } from './autoSchedulerDialogActions';
import { api as commonSchedulerApi } from './commonSchedulerActions';
import { api as messageDialogApi } from '../MessageDialog/messageDialogActions';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED: 'SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED',
  SCHEDULER_TASKS_GENERATED: 'SCHEDULER_TASKS_GENERATED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
export const actions = {
  fetchSchedulerCountersFinished: function (payload) {
    return { type: actionTypes.SCHEDULER_FETCH_SCHEDULER_COUNTERS_FINISHED, payload: payload };
  },
  schedulerTasksGenerated: function () {
    return { type: actionTypes.SCHEDULER_TASKS_GENERATED };
  }
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.init = function () {
  return function (dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.SCHEDULER_TASKS);

    commonSchedulerApi.pollSchedulingStatus(dispatch, getState)(api.refreshPageData(dispatch, getState));

    api.init(dispatch, getState)();
  };
};

jsxActions.unmount = function () {
  return function (dispatch, getState) {
    const statusPollingInterval = getState().predefinedList.getIn(['scheduler', 'asyncScheduling', 'statusPollingInterval']);

    if (statusPollingInterval) {
      clearInterval(statusPollingInterval);
    }

    commonSchedulerApi.resetState(dispatch, getState)();
  };
};

jsxActions.autoSchedule = function (data) {
  return function (dispatch, getState) {
    autoSchedulerDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.assignSchedulerTask = function (data) {
  return function (dispatch, getState) {
    scheduleTaskDialogApi.show(dispatch, getState)(data);
  };
};

jsxActions.unAssign = function (tasks) {
  return function (dispatch, getState) {
    commonSchedulerApi.setLoading(dispatch)(true);

    const tasksAssignmentsIds = getTaskAssignmentsIds(tasks);

    commonSchedulerApi
      .unAssign(
        dispatch,
        getState
      )(tasksAssignmentsIds)
      .then(() => {
        commonSchedulerApi.setLoading(dispatch)(false);
        api.refreshPageData(dispatch, getState)();
      });
  };
};

jsxActions.lockSchedulerTasks = function (data) {
  return function (dispatch, getState) {
    api.lockSchedulerTasks(dispatch, getState)(data);
  };
};

jsxActions.unlockSchedulerTasks = function (data) {
  return function (dispatch, getState) {
    api.unlockSchedulerTasks(dispatch, getState)(data);
  };
};

jsxActions.refreshPageData = function (query, runSideEffect) {
  return function (dispatch, getState) {
    api.refreshPageData(dispatch, getState)(query, runSideEffect);
  };
};

jsxActions.clearFilters = function (query, runSideEffect) {
  return function (dispatch, getState) {
    gridApi.clearFilters(dispatch, getState)();
  };
};

jsxActions.abortAsyncScheduling = function () {
  return function (dispatch, getState) {
    commonSchedulerApi.abortAsyncScheduling(dispatch, getState)();
  };
};

jsxActions.showAsyncSchedulingActivityLog = function () {
  return function (dispatch, getState) {
    commonSchedulerApi.showAsyncSchedulingActivityLog(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.refreshPageData = function (dispatch, getState) {
  return function (query, runSideEffect) {
    api.fetchCounters(dispatch, getState)();
    return gridApi.reload(dispatch, getState)(runSideEffect);
  };
};

api.fetchCounters = function (dispatch) {
  return function () {
    return schedulerService
      .fetchSchedulerCounters()
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Fetch Scheduler counters Failed');
          return;
        }
        dispatch(actions.fetchSchedulerCountersFinished(response.data));
      })
      .catch((err) => {
        console.error('Fetch Scheduler counters failed ', err);
        return { success: false };
      });
  };
};

api.assign = function (dispatch, getState) {
  return function (taskId, data, handleValidationWarnings = true) {
    const query = { ...data, originalTimestamp: null };
    return schedulerService.assignTask(taskId, query).then((response) => {
      if (!Network.isResponseValid(response)) {
        return response;
      }

      if (handleValidationWarnings) {
        let warnings = _hasValidationWarnings(response.applicationResponseStatus);
        if (warnings) {
          let message = _getLocalizedValidations(warnings);
          let desc = _getAssignMessageDialogBuilder(message);
          messageDialogApi.open(dispatch, getState)(desc);
        }
      }

      return response;
    });
  };
};

api.init = function (dispatch, getState) {
  return function () {
    let label = createLabelHelper('mat.header.navigator.predefinedlists.').get('scheduler');

    let topNavigatorData = {
      name: label,
      type: navigationStates.SCHEDULER_TASKS,
      id: -1,
      components: [],
    };

    navigatorApi.setData(dispatch, getState)(topNavigatorData);

    return api.fetchCounters(dispatch, getState)();
  };
};

api.lockSchedulerTasks = function (dispatch, getState) {
  return function (data) {
    commonSchedulerApi.setLoading(dispatch)(true);

    let query = {
      tasksAssignmentsIds: getTaskAssignmentsIds(data),
      originalTimestamp: null,
    };

    return schedulerService
      .lock(query)
      .then((response) => {
        commonSchedulerApi.setLoading(dispatch)(false);

        if (!Network.isResponseValid(response)) {
          console.error('scheduler - lock failed', response);

          return response;
        }

        api.refreshPageData(dispatch, getState)();
        return response;
      })
      .catch((err) => {
        console.error('scheduler - lock failed ', err);
        return { success: false };
      });
  };
};

api.unlockSchedulerTasks = function (dispatch, getState) {
  return function (data) {
    const tasksAssignmentsIds = getTaskAssignmentsIds(data);

    return commonSchedulerApi
      .unlock(
        dispatch,
        getState
      )(tasksAssignmentsIds)
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('scheduler - lock failed', response);
          return;
        }

        api.refreshPageData(dispatch, getState)();
      });
  };
};

/////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function getTaskAssignmentsIds(tasks) {
  return tasks
    ? tasks.map((op) => {
        return op.id;
      })
    : [];
}
