import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import * as ActivityTypeHelper from '../../../../utils/activityTypeHelper';
import Link from '../../../Common/Layout/Link/link.js';
import {navigationStates} from '../../../../enums/navigationStates.js';
import Label from 'infrastructure/js/components/Label/label'
import  * as assetHelper  from '../../../../utils/assetHelper';

import('./managerViewItem.scss');

export default class ManagerViewItem extends React.PureComponent {
  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.reports.managerView.');
    this.assetTypeLabels = createLabelHelper('');
  }

  renderWoCounter = (item, wos, isLoading) => {
    if (isLoading) {
      return <span className="counter">{<i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"/>}</span>
    }

    let value = 0;
    if (wos && wos.length) {
      let wo = wos.find(wo => wo.locationId === item.locationId);
      value = wo ? wo.numberOfWorkOrders : 0;
    }

    return <span className="counter">{value}</span>
  };

  getWoCounterRow = (item, wos, isLoading) => {
    if (!item.station) {
      return <div className="counter-row"/>;
    }


    return <div className="counter-row">
      <i className='pl pl-header-icon-work_order' aria-hidden="true"/>
      {this.renderWoCounter(item, wos, isLoading)}
      <span className="counter-description">{this.labels.get('workorders')}</span>
    </div>
  }


  getAssetAndIcon = (assetType) => {
    if (!assetType) {
      return null;
    }
    let assetIcon = assetType ? assetHelper.getAssetIconComponent(assetType) : '';
    return (
      <div className="asset-with-icon">
        {assetIcon}
        <span className={'asset-type'}>{assetType}</span>
      </div>)

  }

  renderAlertSeverity = (alertIcon, isLoading) => {
    return isLoading ? (<i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"/>) : (
      <i className={'pl alert-icon ' + alertIcon}/>);
  }
  render() {
    let {item} = this.props;
    if (!item) {
      return null;
    }

    let alertIcon = '';
    let alert = this.props.alerts ? this.props.alerts.find(a => a.locationId === item.locationId) : null;
    if (alert) {
      switch (alert.alertSeverity) {
        case 'ERROR':
          alertIcon = 'pl-error-icon';
          break;
        case 'WARNING':
          alertIcon = 'pl-warning-icon';
          break;
      }
    }


    let locationIcon = '';
    let locationType = navigationStates.LOCATION;
    if (item.station) {
      locationIcon = 'pl pl-station-icon';
      locationType = navigationStates.STATION;
    } else {
      locationIcon = 'pl-location-icon-blue';
    }

    let activityData = ActivityTypeHelper.GetActivityTypeDetails(item.activityType, item.activityTypeDisplayKey);
    let assetType = item.assetTypeDisplayKey ? this.assetTypeLabels.get(item.assetTypeDisplayKey) : '';

    return (<div className={cn('manager-view-item', {'inactive' : !item.active})}>

      <div className="title-section">
        <i className={'pl location-icon ' + locationIcon}/>
        <Link className="location-title" label={item.locationName} id={item.locationId} type={locationType}/>
        {this.renderAlertSeverity(alertIcon, this.props.alertsLoading)}

      </div>

      <hr className="top-line"/>

      <div className="counters-section">
        <div className="counter-row">
          <i className='pl pl-assets' aria-hidden="true"/>
          <span className="counter">{item.numberOfAsstes}</span>
          <span className="counter-description">{this.labels.get('assets')}</span>
        </div>
        <div className="counter-row">
          <i className='pl pl-resources' aria-hidden="true"/>
          <span className="counter">{item.numberOfResources}</span>
          <span className="counter-description">{this.labels.get('resources')}</span>
        </div>
        {this.getWoCounterRow(item, this.props.wos, this.props.woLoading)}
      </div>

      <hr/>

      <div className="footer-section">

        <div className="activity-type">
          <span className={'icon ' + activityData.icon}> </span>
          <Label className="activity-inline-left" text={activityData.description} />
        </div>
          <div className="second-row">
            <Label  className="activity-inline-left" text={item.m_ActivityTime} />
            {this.getAssetAndIcon(assetType)}
          </div>
      </div>
    </div>);
  }
}

ManagerViewItem.propTypes = {
  item : PropTypes.object.isRequired
};




