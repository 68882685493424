import { useEffect } from 'react';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import FullScreenTabsSeparator from '../../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import RollsPage from '../RollsPage/rollsPage';
import SpoolsPage from '../SpoolsPage/spoolsPage';
import ResinsPage from '../ResinsPage/resinsPage';
import { navigationStates } from '../../../enums/navigationStates';

import './rawMaterialsPage.scss';

const labels = createLabelHelper('mat.rawmaterials.page.');
const tabsLabels = createLabelHelper('mat.rawmaterials.page.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ROLLS: 'rolls',
  SPOOLS: 'spools',
  RESINS: 'resins',
};

const RawMaterialsPage = () => {
  const updateNavBar = useNavBarUpdate();
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl('PredefinedViews/rawMaterials', Object.values(tabKeys), 'rolls', 'materialName');

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  useEffect(() => {
    updateNavBar(navigationStates.RAW_MATERIALS, labels.get('title'));
  }, [updateNavBar, setTabKeyInURL]);

  return (
    <div className="raw-materials-page">
      <FullScreenTabsSeparator />
      <Tabs id="raw-materials-tabs" tabType="page" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('rolls')} eventKey={tabKeys.ROLLS}>
          <RollsPage />
        </Tab>
        <Tab title={tabsLabels.get('spools')} eventKey={tabKeys.SPOOLS}>
          <SpoolsPage />
        </Tab>
        {PermissionManager.isResinsEnabled() ?
          <Tab title={tabsLabels.get('resins')} eventKey={tabKeys.RESINS}>
            <ResinsPage/>
          </Tab> : null
        }

      </Tabs>
    </div>
  );
};

export default RawMaterialsPage;
