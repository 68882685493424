// @ts-check

import { E_SDK_EVENT, MapView } from "@mappedin/mappedin-js";
import { useCallback, useEffect } from "react";

/**
 * @typedef {import("@mappedin/mappedin-js").E_SDK_EVENT_PAYLOAD} E_SDK_EVENT_PAYLOAD
 * 
 */


/**
 * @param {MapView | undefined | null} mapView
 * @param {(payload: E_SDK_EVENT_PAYLOAD[E_SDK_EVENT.CLICK]) => void} onClick
 */
export function useMapClick(mapView, onClick) {


  const handleClick = useCallback(
    
    /**
    * @param {E_SDK_EVENT_PAYLOAD[E_SDK_EVENT.CLICK]} payload
    */
    (payload) => {
      onClick(payload);
    },

    [onClick]
  );


  // Subscribe to E_SDK_EVENT.CLICK
  useEffect(() => {

    if (mapView == null) {
      return;
    }
    // console.log('initializing map click handler');

    mapView.on(E_SDK_EVENT.CLICK, handleClick);

    // Cleanup
    return () => {
      // console.log('cleaning up map click handler');
      mapView.off(E_SDK_EVENT.CLICK, handleClick);
    };

  }, [mapView, handleClick]);

}

