import { Map } from 'immutable';
import { actionTypes as commonCsvSettingsViewActions } from '../../../actions/Administration/SettingsPage/commonCsvSettingsViewActions';

let defaultState = {
  loading : false,
  importData : null,
  exportData: null,
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case commonCsvSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_IN_PROGRESS:
      return state.set('loading', true);

    case commonCsvSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FINISHED:
      return state.set('loading', false);

    case commonCsvSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_SUBMIT_FAILED:
      return state.set('loading', false);

    case commonCsvSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_IN_PROGRESS:
      return state.set('loading', true);

    case commonCsvSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_FETCH_DATA_FINISHED:
      // state = state.set('loading', false);
      state = state.set('importData', action?.payload?.importData);
      state = state.set('exportData', action?.payload?.exportData);
      state = state.set('loading', false);
      return state;

    case commonCsvSettingsViewActions.ADMINISTRATION_SETTINGS_COMMON_CSV_SETTINGS_VIEW_RESET_DATA:
      return Map(defaultState);

    default:
      return state;
  }
}












