import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import WoAlertPopoverCell from '../../../Common/CustomGridCells/WoAlertPopoverCell/woAlertPopoverCell';
import RelatedAssetCell from './CustomCells/RelatedAssetCell/relatedAssetCell.js';
import CheckMarkCell from '../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import LastOperationCell from '../../CustomGridCells/LastOperationCell/lastOperationCell';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { reduxForm } from 'redux-form';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { DefaultWorkOrderTypes } from '../../../../utils/workOrderHelper';
import { EntityPropertyTypes } from '../../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../../enums/fetchEntitiesFilters';
import EntityIdCell from '../../CustomGridCells/EntityIdCell/entityIdCell';
import { navigationStates } from '../../../../enums/navigationStates';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import TasksProgressCell from '../../CustomGridCells/TasksProgressCell/tasksProgressCell';

require('./commonWorkOrdersGrid.scss');

class CommonWorkOrdersGrid extends React.PureComponent {
  filterConfig = [
    // {fieldName: 'partType', filterName: 'woPartType', getOptions: true},
    { fieldName: 'partType.businessId', filterName: 'woPartType', getOptions: true },
    { fieldName: 'kitType.businessId', filterName: 'woKitType', getOptions: true },
    { fieldName: 'lastOperation', filterName: 'woLastOperationType', getOptions: true },
    { fieldName: 'dueDate.epochDateTime', filterName: 'woDueDate' },
    { fieldName: 'hasAssets', filterName: 'woHasAssets' },
    { fieldName: 'businessId', filterName: 'woBusinessId' },
    { fieldName: 'project.businessId', filterName: 'woProject', getOptions: true },
    { fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'woLastStatusChangeDate' },
    { fieldName: 'quantity', filterName: 'quantity' },
    { fieldName: 'lastTaskCompletedQuantity', filterName: 'lastTaskCompletedQuantity' },
    { fieldName: 'workOrderStatus', filterName: 'workOrderStatus', getOptions: true },
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.openworkorders.grid.');
    this.filterAlertslabels = createLabelHelper('mat.filter.alerts.');

    this.columnsConfig = this.createColumnsConfig();
  }

  renderColumnByDefaultWorkOrderTypes = () => {
    let { defaultWorkOrderType } = this.props;

    if (defaultWorkOrderType === DefaultWorkOrderTypes.PARTTYPE_ORIENTED) {
      return {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.part.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woPartType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      };
    } else if (defaultWorkOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED) {
      return {
        fieldName: 'kitType.businessId',
        title: this.labels.get('columns.kittype.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woKitType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      };
    } else {
      console.error('error in renderColumnByDefaultWorkOrderTypes: invalid type: ' + defaultWorkOrderType);
      return null;
    }
  };

  createColumnsConfig = () => {
    let columnsConfig = [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'woBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.WO_BUSINESS_ID,
          filter: this.props.fetchEntityFilter,
          action: this.props.actions.fetchEntities,
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.WORKORDER,
            };
          },
        },
      },
      ...(this.props.showTasksStatus ? [
        {
          fieldName: 'taskStatusCounts',
          title: this.labels.get('columns.tasksStatus.title'),
          filterType: filterTypes.NONE,
          width: 135,
          columnOptions: {
            cellComponent: TasksProgressCell,
            sortable: false,
          },
        }] : []),
      ...(this.props.showStatusColumn ? [
        {
          fieldName: 'workOrderStatus',
          title: this.labels.get('columns.status.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'workOrderStatus',
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
          },
        },] : []),
      {
        fieldName: 'project.businessId',
        title: this.labels.get('columns.project.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woProject',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      this.renderColumnByDefaultWorkOrderTypes(),
      {
        fieldName: 'quantity',
        title: this.labels.get('columns.quantity.title'),
        filterName: 'quantity',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'lastTaskCompletedQuantity',
        title: this.labels.get('columns.completedQuantity.title'),
        filterName: 'lastTaskCompletedQuantity',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastoperation.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'woLastOperationType',
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
      {
        fieldName: 'relatedAssets',
        title: this.labels.get('columns.relatedassets.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.relatedAssets,
              componentType: RelatedAssetCell,
            };
          },
          sortable: false,
        },
      },
      ...(PermissionManager.getOrgPreferences().schedulerEnabled && this.props.showStartAfterColumn ? [
        {
          fieldName: 'startAfterWorkOrdersItems',
          title: this.labels.get('columns.startAfter.title'),
          filterType: filterTypes.NONE,
          columnOptions: {
            cellComponent: MultiRowsCell,
            valueGetter: (params) => {
              return {
                dataList: params.data?.startAfterWorkOrdersItems?.map(item => {return {id:item.id, type: navigationStates.WORKORDER, label: item.businessId}}),
                componentType: EntityIdCell,
              };
            },
            sortable: false,
          },
        }] : []),
    ];

    if (this.props.showAlertsColumn) {
      columnsConfig.unshift({
        fieldName: 'mostSevereAlert',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.NONE,
        width: 80,
        columnOptions: {
          sortable: false,
          cellComponent: WoAlertPopoverCell,
          valueGetter: (params) => {
            return {
              mostSevereAlert: params.data.mostSevereAlert,
              alerts: params.data.alerts,
              warningKitCount: params.data.warningKitCount,
              errorKitCount: params.data.errorKitCount,
            };
          },
        },
      });
    }

    if (this.props.showDueDateColumn) {
      columnsConfig.push({
        fieldName: 'dueDate.epochDateTime',
        title: this.labels.get('columns.dueDate.title'),
        filterType: filterTypes.HORIZON,
        filterAlignment: filterAlignmentTypes.RIGHT,
        filterName: 'woDueDate',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.dueDate ? DateTimeHelper.FormatDateObjectToDayMonth(params.data.dueDate) : '';
          },
        }
      })
    }

    if (this.props.showHasAssetsColumn) {
      columnsConfig.push({
        fieldName: 'hasAssets',
        title: '',
        filterType: filterTypes.NONE,
        width: 70,
        columnOptions: {
          resizable: false,
          headerValueGetter: GridHelper.headerValueGetter,
          cellComponent: CheckMarkCell,
          headerComponentParams: {
            headerIcon: 'table-assets-location',
            headerTooltip: this.labels.get('columns.hasAssets.tooltip'),
          },
        },
      });
    }

    if (this.props.showCompletionDateColumn) {
      columnsConfig.push({
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.completiondate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return params.data && params.data.m_CompletionDate ? params.data.m_CompletionDate : '';
        },
        width: 220,
        columnOptions: {
          sort: 'desc',
          headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
        },
      });
    }

    return columnsConfig;
  };

  getRowHeight(params) {
    let rowHeight = 100;
    let maxLength = Math.max(params.data?.relatedAssets?.length ?? 0, params.data?.startAfterWorkOrdersItems?.length ?? 0)
    if (maxLength > 2) {
      rowHeight = maxLength * 30 + 40;
    }
    return rowHeight;
  }

  getAlertColumnFilterSections() {
    let strings = this.filterAlertslabels.createSubset('');
    return [
      {
        header: strings['alertstatus'],
        properties: [
          { name: 'noAlert', label: strings['alertstatus.noalert'], value: false },
          { name: 'warning', label: strings['alertstatus.warning'], value: false },
          { name: 'error', label: strings['alertstatus.error'], value: false },
        ],
      },
      {
        header: strings['alerttype'],
        properties: [
          { name: 'etl', label: strings['alerttype.etl'], value: false },
          { name: 'expiration', label: strings['alerttype.expiration'], value: false },
          { name: 'dueDate', label: strings['alerttype.dueDate'], value: false },
        ],
      },
    ];
  }

  render() {
    return (
      <div className="common-work-orders-grid">
        <Grid
          gridName={this.props.gridName}
          columnsConfig={this.columnsConfig}
          actions={this.props.actions}
          filterConfig={this.filterConfig}
          gridProps={{
            getRowHeight: this.getRowHeight,
          }}
        />
      </div>
    );
  }
}

export default reduxForm({
  form: 'CommonWorkOrdersGrid',
})(CommonWorkOrdersGrid);

CommonWorkOrdersGrid.defaultProps = {
  fetchEntityFilter: [FetchEntitiesFilters.ACTIVE],
  showAlertsColumn: true,
  showDueDateColumn: true,
  showHasAssetsColumn: true,
  showStartAfterColumn: true,
  showCompletionDateColumn: false,
  showStatusColumn: false,
  showTasksStatus: false
};

CommonWorkOrdersGrid.propTypes = {
  fetchEntityFilter: PropTypes.arrayOf(PropTypes.string),
  showAlertsColumn: PropTypes.bool,
  showDueDateColumn: PropTypes.bool,
  showHasAssetsColumn: PropTypes.bool,
  showStartAfterColumn: PropTypes.bool,
  showCompletionDateColumn: PropTypes.bool,
  showStatusColumn: PropTypes.bool,
  showTasksStatus: PropTypes.bool,
};
