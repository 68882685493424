import * as resinsService from '../../services/Resins/resinsService';
import * as AlertHelper from '../../utils/alertHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {gridsNames} from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import assetAlertFilterHelper from '../../utils/assetAlertFilterHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.PREDEFINED_LIST_ALERTED_RESINS, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query = assetAlertFilterHelper.getAssetAlertFilteredQuery(query);
    return resinsService.fetchResins(query);
  }
};

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    obj.alerts = obj.alerts.map((alert)=> {
      let expirationDaysLeft = (obj.expirationDate && obj.expirationDate.value ? DateTimeHelper.GetDifferenceInDays(obj.expirationDate.value, serverTimeSeconds) : '');
      let alertDetails = {
        expirationDate: obj.expirationDate,
        expirationDaysLeft: expirationDaysLeft,
        exposureTimeLeftBond: obj.exposureTimeLeftBond,
        exposureTimeLeftCure: obj.exposureTimeLeftCure,
        defrostingTimeLeft: obj.defrostingTimeLeft,
        dueDateMinutesLeft: obj.dueDateMinutesLeft,
        locationName: obj.locationName,
        pickListBusinessId: obj.pickListBusinessId,
      };
      alert.m_value = AlertHelper.GetAlertTypeMessageTitle(alert, alertDetails);
      return alert;
    })

    if(obj.lastOperation){
      obj.lastOperation.m_time = DateTimeHelper.FormatDateObjectToRelativeTime(obj.lastOperation?.createdDate, serverTimeSeconds);
    }
    
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
