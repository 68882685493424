import Network from 'infrastructure/js/modules/network';
import * as projectsService from '../../../services/Projects/projectsService';
import * as partTypesService from '../../../services/PartTypes/partTypesService';
import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import * as customerOrdersService from '../../../services/CustomerOrders/customerOrdersService';
import * as kitTypesService from '../../../services/KitTypes/kitTypesService';
import { api as messageDialogApi }  from '../../MessageDialog/messageDialogActions.js';
import { api as locationPageApi } from '../../LocationPage/locationPageActions.js';
import  * as dialogHelper  from "infrastructure/js/components/Dialog/dialogHelper";
import DateTimeHelper from "infrastructure/js/utils/dateTimeHelper";
import * as additionalFieldsService from "../../../services/Administration/additionalFieldsService";
import * as additionalFieldsHelper from "../../../components/Common/Helpers/AdditionalFieldsHelper";
import {getEnumValue, enumTypes} from '../../../utils/enumHelper';
/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  CREATE_WORK_ORDER_DIALOG_SHOW: "CREATE_WORK_ORDER_DIALOG_SHOW",
  CREATE_WORK_ORDER_DIALOG_HIDE: "CREATE_WORK_ORDER_DIALOG_HIDE",
  CREATE_WORK_ORDER_FETCH_INITIAL_DATA_READY: "CREATE_WORK_ORDER_FETCH_INITIAL_DATA_READY",
  CREATE_WORK_ORDER_GENERATED_ID: "CREATE_WORK_ORDER_GENERATED_ID",
  CREATE_WORK_ORDER_SUBMIT_IN_PROGRESS: "CREATE_WORK_ORDER_SUBMIT_IN_PROGRESS",
  CREATE_WORK_ORDER_SUBMIT_FINISHED: "CREATE_WORK_ORDER_SUBMIT_FINISHED",
  CREATE_WORK_ORDER_FETCH_PARTS_READY: "CREATE_WORK_ORDER_FETCH_PARTS_READY",
  CREATE_WORK_ORDER_FETCH_PROJECTS_READY: "CREATE_WORK_ORDER_FETCH_PROJECTS_READY"
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_DIALOG_SHOW, payload: payload };
  },
  hide: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_DIALOG_HIDE, payload: payload };
  },
  fetchInitialDataReady: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_FETCH_INITIAL_DATA_READY, payload: payload };
  },

  generatedWorkOrderId: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_GENERATED_ID, payload: payload };
  },
  createWorkOrderInProgress: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_SUBMIT_IN_PROGRESS, payload: payload };
  },
  createWorkOrderFinished: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_SUBMIT_FINISHED, payload: payload };
  },
  fetchPartsReady: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_FETCH_PARTS_READY, payload: payload };
  },
  fetchProjectsReady: function(payload) {
    return {type: actionTypes.CREATE_WORK_ORDER_FETCH_PROJECTS_READY, payload: payload };
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.onCancel = function() {
  return function(dispatch, getState) {
    api.closeDialog(dispatch, getState)();
  };
};

jsxActions.generateWorkOrderId = function() {
  return function(dispatch, getState) {
    return api.generateWorkOrderId(dispatch, getState)();
  };
};
jsxActions.onProjectChanged = function(project, part) {
  return function(dispatch, getState) {
    return api.fetchPartByProjectId(dispatch, getState)(project, part);
  };
};
jsxActions.onPartChanged = function(part, project) {
  return function(dispatch, getState) {
    return api.fetchProjectsByPartId(dispatch, getState)(part, project);
  };
};

jsxActions.createWorkOrder = function(data, messageDialogBuilder, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.createWorkOrder(dispatch, getState)(data, messageDialogBuilder, reloadParentComponent);
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.closeDialog = function(dispatch, getState) {
  return function() {

    dispatch(actions.hide());

  }
};

api.openDialog = function(dispatch, getState) {
  return function() {
    dispatch(actions.show());
    api.fetchDialogData(dispatch, getState)();
  }
};

api.fetchDialogData = function(dispatch, getState) {
  return function() {

       fetchInitialDialogData()
      .then((result) => {
        dispatch(actions.fetchInitialDataReady(result));
      });

  }
};

function fetchInitialDialogData() {
  let promiseFetchProjects = projectsService.fetchActiveProjects();
  let promiseFetchParts = partTypesService.fetchAllParts();
  let promiseFetchCustomerOrders = customerOrdersService.fetchCustomerOrders();
  let promiseFetchKitTypes = kitTypesService.fetchActiveKitTypes();
  let promiseAdditionalFields = additionalFieldsService.fetchAdditionalFields({entityType: getEnumValue(enumTypes.OBJECT_TYPE)('WO')});

  let resultPromise = Promise.all([promiseFetchProjects, promiseFetchParts, promiseFetchCustomerOrders , promiseFetchKitTypes, promiseAdditionalFields])
    .then(values => {

      let projectOptions  = values[0].dataList.map((item) => { return {label: item.businessId, value: item.id}; });
      let partsOptions  = values[1].dataList.map((item) => { return {label: item.businessId, value: item.id}; });
      let customerOrdersOptions  = values[2].dataList.map((item) => { return {label: item.businessId, value: item.id}; });
      let kitTypesOptions = values[3].dataList.map((item) => { return {label: item.businessId, value: item.id , relatedProject : item.project}; });
      let additionalFields = values[4] ? values[4].dataList : [];

      let dialogAdditionalData = {
        projectOptions,
        partsOptions,
        customerOrdersOptions,
        kitTypesOptions,
        additionalFields
      };
      return dialogAdditionalData;
    });

  return resultPromise;
};


api.fetchProjectsByPartId = function (dispatch, getState) {
  return function (part, project) {
    return projectsService.fetchProjectsByParts(part ? part.value : '').then((response) => {
      let projectOptions = response.dataList.map((item) => { return {label: item.businessId, value: item.id, isRelated: item.relatedToPartType}; });
      let selectedProject = project ? projectOptions.find((p) => {
        return p.value === project.value
      }) : null;

      let data = {projectOptions, selectedProject};
      dispatch(actions.fetchProjectsReady(data));
      return data;
    });
  };
};


api.fetchPartByProjectId = function (dispatch, getState) {
  return function (project, part) {
    return partTypesService.fetchPartsByProject(project ? project.value : '').then((response) => {
      let partsOptions = response.dataList.map((item) => {
        return {label: item.businessId, value: item.id, isRelated: item.relatedToProject};
      });
      let selectedPart = part ? partsOptions.find((p) => {
        return p.value === part.value
      }) : null;

      let data = {partsOptions, selectedPart}

      dispatch(actions.fetchPartsReady(data));
      return data;
    });
  };
};

api.generateWorkOrderId = function(dispatch, getState) {
  return function() {
    return workOrdersService.generateWorkOrderId()
      .then((response) => {
        if(!Network.isResponseValid(response)) {
          console.error("Generate Work Order Id Failed");
          return {success: false};
        }
        return response.data;
      });
  };
};

api.createWorkOrder = function(dispatch, getState) {
  return function(data, messageDialogBuilder, reloadParentComponent) {
    dispatch(actions.createWorkOrderInProgress());
    let locationId = locationPageApi.getLocationId(dispatch, getState)();
    let additionalFields = additionalFieldsHelper.convertToAdditionalFields(data['additionalFields'], data.additionalFieldsDefinitions);

    let workOrderData =  {
      project : data.project ?  {id: data.project.value }: null,
      quantity: data.quantity,
      workOrderType: data.workOrderType ? data.workOrderType : null,
      partType: data.part ? {id: data.part.value} : null,
      kitTypeId : data.kitType ? data.kitType.value : null,
      customerOrder: data.customerOrder ?
        {
          id: (data.customerOrder.value !== data.customerOrder.label) ? data.customerOrder.value : null,
          businessId: data.customerOrder.label
        } :
        null,
      businessId: data.workOrderId,
      dueDate: DateTimeHelper.ConvertFromDate(data.date),
      pickDate: data.pickDate ? DateTimeHelper.ConvertFromDate(data.pickDate) : null,
      createdOnLocationId: locationId,
      additionalFieldsValues: additionalFields,
      priority: data.priority ? data.priority.value : null,
      startAfterWorkOrders: data.startAfter?.assetsValue?.length ? data.startAfter.assetsValue.map(item => item.value) : null,  //set null, not []
      description: data.description || null,
    };

    workOrdersService.createWorkOrder(workOrderData)
      .then((response) => {
        dispatch(actions.createWorkOrderFinished(response));
        dispatch(actions.hide(response));

        if (!dialogHelper.handleSingleResponse(response, "createWorkOrder", messageDialogBuilder, messageDialogApi, dispatch, getState)){
          return {success: false};
        }

        // locationPageApi.reload(dispatch, getState)();

        if (reloadParentComponent) {
          reloadParentComponent();
        }
      });
  };
};
