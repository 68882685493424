import PropTypes from 'prop-types';
import Button from 'infrastructure/js/components/controls/Button/button';

import './promotion.scss';

const Promotion = ({ item= {},
                  }) => {

  const onActionClick = () => {
    window.open(item.callForActionUrl, '_blank');
  };

  return (
    <div className="promotion">
      <img className="promotion-bg" src={item.pictureUrl} alt='backgroung img'/>
      <div className="promotion-content">
        <div className="promotion-header"><span className="highlight">{item.header}</span></div>
        <div className="promotion-description"><span className="highlight">{item.description}</span></div>

        <div id="openActionUrl" className="btn promotion-action" onClick={onActionClick}>
          <span>{item.callForActionLabel}</span>
        </div>
      </div>
    </div>
  )
};

Promotion.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Promotion;

