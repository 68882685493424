// @ts-check

import RefContextHelper from '../../components/LiveMap/Utils/RefContextHelper';
import { actionTypes as liveMapPageActionsTypes } from '../../actions/LiveMap/liveMapPageActions';


/** 
 * @typedef {import("../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../types/ServerTypes/Models/LiveMap").LivemapLocationDetailsResponseModel} LivemapLocationDetailsResponseModel
 * @typedef {import("../../types/ServerTypes/Models/LiveMap").LivemapFullStatusResponseModel} LivemapFullStatusResponseModel
 * 
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchResultSelection} LiveSearchResultSelection
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").LiveSearchResultResponseModel} LiveSearchResultResponseModel
 * @typedef {import("../../types/ServerTypes/Models/LiveSearch").PL_SearchState} PL_SearchState
 * 
 */


let getDefaultSearchResultSelection = () => {

  /** @type {LiveSearchResultSelection} */
  let defaultSearchResultSelection = {
    floor: null,
    locationName: null,
    category: null,
    item: null,
    selectionId: null,
  };

  return defaultSearchResultSelection;
};


let getDefaultSearchState = () => {

  /** @type {PL_SearchState} */
  let defaultSearchState = {
    searchResultsState: 'HIDDEN',
    searchResults: null,
    searchResultsId: null,
    searchFilter: "ALL",
    searchRequestModel: null,
    resultSelection: getDefaultSearchResultSelection(),
  };

  return defaultSearchState;

};


/** @type {PL_LiveMapPageState} */
let defaultState = {
  loading: false,

  activeMapSetting: null,

  mapMode: 'NORMAL',

  floorName: null,

  search: getDefaultSearchState(),

  normal: {
    mapZoomLevel: 'FULL_MAP',
    locationTooltipState: 'HIDDEN',
    locationDetails: null,
  },

  plant: {
    fullStatus: null,
    allLocationsByName: new Map(),
  },

};



/**
 * @param {PL_LiveMapPageState} state
 * @param {{ type: string; payload: any; }} action
 */
// export default function (state = Map(defaultState), action) {
export default function (state = defaultState, action) {

  // console.log('REDUCER => LIVEMAP_PAGE');

  let s = { ...state };

  switch (action.type) {



    case liveMapPageActionsTypes.LIVEMAP_PAGE__RESET_MAP_DATA:
      // console.log('REDUCER => LIVEMAP_PAGE__RESET_MAP_DATA ===> ');

      s.normal.locationTooltipState = 'HIDDEN';
      s.normal.locationDetails = null;
      s.plant.fullStatus = null;
      s.plant.allLocationsByName = new Map();

      return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__ZOOMLEVEL_CHANGED:
      // console.log('REDUCER => LIVEMAP_PAGE__ZOOMLEVEL_CHANGED ===> ', action.payload);
      s.normal.mapZoomLevel = action.payload;
      return s;

    case liveMapPageActionsTypes.LIVEMAP_PAGE__SET_FLOOR:
      // console.log('REDUCER => LIVEMAP_PAGE__SET_FLOOR ===> ', action.payload);
      s.floorName = action.payload;


      return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__FETCHSETTINGS_STARTED:
      // console.log('REDUCER => LIVEMAP_PAGE__FETCHSETTINGS_STARTED ===> ');
      // let s = { ...state };
      s.loading = true;
      return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__FETCHSETTINGS_FINISHED:
      // console.log('REDUCER => LIVEMAP_PAGE__FETCHSETTINGS_FINISHED ===> ');

      let activeMapSetting = action.payload;

      s.mapMode = 'NORMAL';
      s.search = getDefaultSearchState();

      s.loading = false;
      s.activeMapSetting = activeMapSetting;

      return s;



    case liveMapPageActionsTypes.LIVEMAP_PAGE__TOOLTIP_LOCATION_HIDE:
      // console.log('REDUCER => LIVEMAP_PAGE__TOOLTIP_LOCATION_HIDE ===> ');
      s.normal.locationTooltipState = 'HIDDEN';
      return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_STARTED:
      // console.log('REDUCER => LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_STARTED ===> ');
      s.normal.locationTooltipState = 'LOADING';
      return s;

    case liveMapPageActionsTypes.LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_FINISHED:
      // console.log('REDUCER => LIVEMAP_PAGE__TOOLTIP_LOCATION_FETCHDATA_FINISHED ===> ', action.payload);
      s.normal.locationTooltipState = 'VISIBLE';
      s.normal.locationDetails = action.payload;
      return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__FULLSTATUS_FETCHDATA_FINISHED:
      // console.log('REDUCER => LIVEMAP_PAGE__FULLSTATUS_FETCHDATA_FINISHED ===> ', action.payload);
      s.plant.fullStatus = action.payload;
      s.plant.allLocationsByName = buildAllLocationsByNameMap(s.plant.fullStatus);

      return s;



    case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCH_FETCHDATA_STARTED:
      // console.log('REDUCER => LIVEMAP_PAGE__SEARCH_FETCHDATA_STARTED ===> ');
      s.search.searchResultsState = 'LOADING';
      s.search.searchRequestModel = action.payload;
      return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCH_FETCHDATA_FINISHED:
      // console.log('REDUCER => LIVEMAP_PAGE__SEARCH_FETCHDATA_FINISHED ===> ', action.payload);

      /** @type {LiveSearchResultResponseModel} */
      let searchResults = action.payload;

      enhanceSearchResults(searchResults);


      s.mapMode = 'SEARCH';
      s.search.searchResultsState = 'VISIBLE';
      s.search.searchResults = searchResults;
      s.search.searchResultsId = generateSearchResultId();

      s.search.resultSelection = getDefaultSearchResultSelection();

      return s;

    // case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCHRESULTS_HIDE:
    //   // console.log('REDUCER => LIVEMAP_PAGE__SEARCHRESULTS_HIDE ===> ');
    //   s.mapMode = 'NORMAL';
    //   s.search.searchResultsState = 'HIDDEN';

    //   return s;



    case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_FINISHED:
      {

        /** @type {LiveSearchResultResponseModel} */
        let moreResultsForLocation = action.payload;

        // console.log('LIVEMAP_PAGE__SEARCH_MORE_FETCHDATA_FINISHED ===> ', moreResultsForLocation);

        enhanceSearchResults(moreResultsForLocation);
        

        let currentResults = s.search.searchResults;
        if (!currentResults) {
          return state;
        }

        mergeAdditionalResultsToCurrent(currentResults, moreResultsForLocation);


        return s;

      }



    case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCH_CLEAR:
      // console.log('REDUCER => LIVEMAP_PAGE__SEARCH_CLEAR ===> ');
      s.mapMode = 'NORMAL';

      s.search = getDefaultSearchState();

      // Do not reset the active search filter tab.
      s.search.searchFilter = state.search.searchFilter;

      return s;


    // case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCHRESULTS_CLEAR:
    //   // console.log('REDUCER => LIVEMAP_PAGE__SEARCHRESULTS_CLEAR ===> ');
      
    //   // s.search.searchResultsState = 'HIDDEN';
    //   // s.search.searchResults = null;


    //   return s;


    case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCH_FILTER_CHANGED:
      // console.log('REDUCER => LIVEMAP_PAGE__SEARCH_FILTER_CHANGED ===> ', action.payload);
      s.search.searchFilter = action.payload;
      s.search.resultSelection = getDefaultSearchResultSelection();

      return s;

    case liveMapPageActionsTypes.LIVEMAP_PAGE__SEARCH_RESULT_SELECTION_CHANGED:
      // console.log('REDUCER => LIVEMAP_PAGE__SEARCH_RESULT_SELECTION_CHANGED ===> ', action.payload);
      s.search.resultSelection = action.payload;
      s.search.resultSelection.selectionId = generateRandomString(8);
      return s;



    default:
      return state;
  }
}






////////////////////////////////////
// Helper functions
// 

/** 
 * @param {LivemapFullStatusResponseModel | null} fullStatus 
 */
let buildAllLocationsByNameMap = (fullStatus) => {

  /** @type {Map<string, LivemapLocationDetailsResponseModel>} */
  let allLookupByName = new Map();

  if (!fullStatus) {
    return allLookupByName;
  }


  fullStatus.sections.forEach(section => {
    allLookupByName.set(section.name, section);
  });

  fullStatus.stations.forEach(station => {
    allLookupByName.set(station.name, station);
  });

  return allLookupByName;

};



function generateSearchResultId() {
  let result = generateRandomString(8);
  return `search-${result}`;
}


function generateRandomString(length = 8) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}



/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 */
function enhanceSearchResults(searchResults) {

  generatePerLocationCountsForLocationsBlock(searchResults);
  addFloorNamesToItemsLocation(searchResults);

  return searchResults;
}

/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 */
function generatePerLocationCountsForLocationsBlock(searchResults) {
  let locationsBlock = searchResults.blocks.find(b => b.type === 'LOCATION');
  if (!locationsBlock) {
    return;
  }

  locationsBlock.items.forEach(item => {
    locationsBlock.totalPerLocationCounts.push({
      locationId: item.id,
      locationName: item.displayName,
      floorName: item.location.floorName,
      objectType: item.type,
      count: 1,
    });
  });

}


/** 
 * @param {LiveSearchResultResponseModel} searchResults 
 */
function addFloorNamesToItemsLocation(searchResults) {

  // console.log('RefContextHelper.refContext ===> ', RefContextHelper.refContext);

  let refContext = RefContextHelper.refContext;
  if (!RefContextHelper.refContext) {
    return;
  }

  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }


  let blocks = searchResults.blocks;

  let allResultItems = blocks.flatMap((b) => b.items);
  allResultItems.forEach(item => {
    let floorName = polyData.getFloorNameByLocationName(item.location.locationName);
    item.location.floorName = floorName || null;
  });

  let allPerLocationCounts = blocks.flatMap((b) => b.totalPerLocationCounts);
  allPerLocationCounts.forEach(c => {
    c.floorName = polyData.getFloorNameByLocationName(c.locationName);
  });

}


/** 
 * @param {LiveSearchResultResponseModel} currentResults 
 * @param {LiveSearchResultResponseModel} moreResultsForLocation 
 */
let mergeAdditionalResultsToCurrent = (currentResults, moreResultsForLocation) => {

  if (moreResultsForLocation.blocks.length < 1) {
    return;
  }


  let newBlock = moreResultsForLocation.blocks[0];
  let locationId = newBlock.totalPerLocationCounts[0].locationId;

  // console.log('newBlock ===> ', locationId, newBlock);
  // console.log('currentResults ===> ', currentResults);


  let matchingExistingBlock = currentResults.blocks.find(b => {
    return b.totalPerLocationCounts.some(c => c.locationId === locationId);
  });
  // console.log(' matchingExistingBlock ! ===> ', matchingExistingBlock);

  if (!matchingExistingBlock) {
    return;
  }


  // Add new items to the existing block.
  newBlock.items.forEach(item => {
    if (matchingExistingBlock.items.some(i => i.id === item.id)) {
      return;
    }
    matchingExistingBlock.items.push(item);
  });

  newBlock.items.sort((a, b) => a.displayName.localeCompare(b.displayName));

  // console.log(' UPDATED ! ===> ', matchingExistingBlock);


}





