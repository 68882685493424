import { useEffect } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import Scheduler from 'infrastructure/js/components/Scheduler/schedulerDynamicImport';
import ErrorBoundary from '../../../ErrorBoundary/errorBoundary';
import { jsxActions as schedulerGanttPageActions } from '../../../../actions/SchedulerPage/schedulerGanttPageActions';
import { jsxActions as reportGanttTaskProgressDialogActions } from '../../../../actions/SchedulerPage/reportGanttTaskProgressDialogActions';

import './schedulerGanttPage.scss';

const allActions = {
  schedulerGanttPageActions,
  reportGanttTaskProgressDialogActions,
};

const getState = (state) => {
  return {
    sData: state.predefinedList.get('scheduler'),
    sReportTaskProgressDialogData: state.dialogs.get('reportTaskProgressDialog'),
  };
};

export default function SchedulerGanttPage() {
  const { state, actions } = useRedux(allActions, getState);

  useEffect(() => {
    actions.schedulerGanttPageActions.init(true);

    return () => {
      actions.schedulerGanttPageActions.unmount();
    };
    // eslint-disable-next-line
  }, []);

  const renderLoadingOverlay = () => {
    if (!state?.sData?.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  return (
    <div className="scheduler-gantt-page">
      <div className="scheduler-gantt-wrapper">
        <ErrorBoundary>
          <Scheduler
            data={state?.sData?.get('schedulerData')}
            asyncSchedulingData={state?.sData?.get('asyncScheduling')}
            actions={actions.schedulerGanttPageActions}
            refreshPage={actions.schedulerGanttPageActions.refreshPageData}
            loading={state?.sData?.get('loading')}
            onEventChange={actions.schedulerGanttPageActions.onEventChange}
            onTaskMove={actions.schedulerGanttPageActions.onTaskMove}
            onTaskDelete={actions.schedulerGanttPageActions.onTaskDelete}
            onTaskDrop={actions.schedulerGanttPageActions.onTaskDrop}
            onTaskLock={actions.schedulerGanttPageActions.onTaskLock}
            onTaskUnlock={actions.schedulerGanttPageActions.onTaskUnlock}
            reportTaskProgressDialogActions={actions.reportGanttTaskProgressDialogActions}
            sReportTaskProgressDialogData={state?.sReportTaskProgressDialogData}
            backtrackList={state?.sData?.get('backtrackList')}
          />
        </ErrorBoundary>
        {renderLoadingOverlay()}
      </div>
    </div>
  );
}
