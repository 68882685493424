import Network from 'infrastructure/js/modules/network';
import * as bulkUpdateService from '../../../services/BulkUpdate/bulkUpdateService';
import * as ImportResponseHelper from '../Common/ImportResponseHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import {api as messageDialogApi} from '../../MessageDialog/messageDialogActions';
import * as csvSettingsService from '../../../services/Administration/csvSettingsService';
import {enumTypes, getEnumValue} from '../../../utils/enumHelper';

export const actionTypes = {
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_SHOW:               'IMPORT_ATTRIBUTES_UPDATE_WIZARD_SHOW',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_HIDE:               'IMPORT_ATTRIBUTES_UPDATE_WIZARD_HIDE',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_SET_BUSY:           'IMPORT_ATTRIBUTES_UPDATE_WIZARD_SET_BUSY',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_SCV_SETTINGS_FINISHED:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_SCV_SETTINGS_FINISHED',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FINISHED:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FINISHED',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_SELECTED_FILE_CHANGED:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_SELECTED_FILE_CHANGED',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_VALIDATE_FILE_FINISHED:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_VALIDATE_FILE_FINISHED',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_HAS_ERRORS:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_HAS_ERRORS',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FAILED:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FAILED',
  IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_ATTRIBUTES_PREVIEW_FINISHED:   'IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_ATTRIBUTES_PREVIEW_FINISHED',
};


////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  show(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_SHOW, payload: payload};
  },
  hide(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_HIDE, payload: payload };
  },
  setBusy(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_SET_BUSY, payload: payload };
  },
  uploadFileFinished(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FINISHED, payload: payload};
  },
  fetchCsvSettingsReady(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_SCV_SETTINGS_FINISHED, payload: payload };
  },
  selectedFileChanged(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_SELECTED_FILE_CHANGED, payload: payload};
  },
  validateFileFinished(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_VALIDATE_FILE_FINISHED, payload: payload};
  },
  getAttributesPreviewFinished(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_GET_ATTRIBUTES_PREVIEW_FINISHED, payload: payload};
  },

  showErrors(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_HAS_ERRORS, payload: payload};
  },
  uploadFileFailed(payload) {
    return {type: actionTypes.IMPORT_ATTRIBUTES_UPDATE_WIZARD_UPLOAD_FILE_FAILED, payload: payload};
  },
};


/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};
jsxActions.show = function(data) {
  return function(dispatch, getState) {
    api.show(dispatch, getState)(data);
  }
};

jsxActions.hide = function(importType) {
  return function(dispatch, getState) {
    api.hide(dispatch, getState)(importType);
  };
};

jsxActions.uploadFile = function (callback, importType) {
  return function (dispatch, getState) {
    let file = getState().wizards.getIn(['importAttributesUpdateWizard', 'selectedFile']);
    api.uploadFile(dispatch, getState)(file, callback, importType);
  }
};

jsxActions.saveBulkCsvSettings = function (data, callback, inmportType) {
  return function (dispatch, getState) {
    api.saveBulkCsvSettings(dispatch, getState)(data, callback, inmportType);
  }
};

jsxActions.getAttributesPreview = function (data, callback, importType) {
  return function (dispatch, getState) {
    api.getAttributesPreview(dispatch, getState)(data, callback, importType);
  }
};

jsxActions.submit = function(data, importType, messageDialogBuilder, reloadParentComponent) {
  return function(dispatch, getState) {
    return api.submit(dispatch, getState)(data, importType, messageDialogBuilder, reloadParentComponent);
  };
};

jsxActions.hasErrors = function (data) {
  return function (dispatch, getState) {
    api.showErrors(dispatch, getState)(data);
  }
};

jsxActions.onSelectedFileChanged = function (file) {
  return function (dispatch, getState) {
    dispatch(actions.selectedFileChanged(file));
  }
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {};

api.hide = function(dispatch, getState) {
  return function(updateType) {

    bulkUpdateService.deleteBulkCsvSettings({updateType})
      .then((response) => {
        if (!Network.isResponseValid(response)) {
          console.error('Failed to delete csvSettings', response);
          return null;
        }
      });

    dispatch(actions.hide());
  }
};

api.show = function(dispatch, getState) {
  return function(data = null) {
    dispatch(actions.show(data));
  }
};

api.uploadFile = function (dispatch, getState) {
  return function (file, callback, importType) {
    dispatch(actions.setBusy(true));

    let promiseUploadFile = bulkUpdateService.uploadFile(file)
      .then((response) => {
        dispatch(actions.setBusy(false));

        if (!Network.isResponseValid(response)) {
          dispatch(actions.showErrors(ImportResponseHelper.NormalizeResponseError(response)));
          console.error('Upload File Failed' +  ' ' + response);
          return null;
        }

        dispatch(actions.uploadFileFinished({token: response.data?.data}));

        return response.data;
      });

    return promiseUploadFile.then((res) => {
      return res ? api.fetchBulkCsvSettingsData(dispatch, getState)(callback, importType) : null;
    })
  }
};

api.fetchBulkCsvSettingsData = function (dispatch, getState) {
  return function(callback, importType) {
    dispatch(actions.setBusy(true));

    let fileName = getState().wizards.getIn(['importAttributesUpdateWizard',  'fileToken']);

    let promise1 = csvSettingsService.fetchImportPropertiesOptions({importType});
    let promise2 = bulkUpdateService.getBulkCsvSettings({fileName, updateType: importType });

    return Promise.all([promise1, promise2]).then((allResults) => {

      dispatch(actions.setBusy(false));

      if (!Network.isResponseValid(allResults[0]) || !Network.isResponseValid(allResults[1]) ) {
        messageDialogApi.responseError(dispatch, getState)(null);
        console.error('Failed to fetch CSV Settings');
        return {success: false};
      }

      let labels = createLabelHelper('');
      let importCsvOptions = allResults[0].dataList.map((item) => {
        return {
          value: item.id,
          label: item.isAdditionalField ? item.name : labels.get(item.labelKey),
          data: item}
      });
      let importCsvData = {
        settings: allResults[1].data.attributes,
      };

      let data = {
        csvOptions: importCsvOptions,
        csvData: importCsvData,
      };

      dispatch(actions.fetchCsvSettingsReady(data));

      if (callback) {
        callback();
      }

      return {success: true};
    });
  }
}

api.saveBulkCsvSettings = function (dispatch, getState) {
  return function (data, callback, importType) {
    dispatch(actions.setBusy(true));

    let promiseSaveCsvSettings = csvSettingsService.setImportSettings(data).then((response) => {

      dispatch(actions.setBusy(false));

      if (!Network.isResponseValid(response)) {
        console.error('Failed to save CSV Settings data', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return null;
      }

      return response.data;
    });

      return promiseSaveCsvSettings.then((res) => {
        return res ? api.validateFile(dispatch, getState)(callback, importType) : null;
      })
  }
}


api.validateFile = function (dispatch, getState) {
  return function (callback, importType) {

    let fileToken = getState().wizards.getIn(['importAttributesUpdateWizard',  'fileToken']);

    dispatch(actions.setBusy(true));

    bulkUpdateService.validateFile({fileName : fileToken}, getApiPathByImportType(importType))
      .then((response) => {

        dispatch(actions.setBusy(false));

        if (!Network.isResponseValid(response)) {
          messageDialogApi.responseError(dispatch, getState)(response);
          console.error('File validation failed', response);
          return null;
        }

        dispatch(actions.validateFileFinished({data: response.data, fileToken}));

        if (callback) {
          callback();
        }
      });
  }
};

api.getAttributesPreview = function (dispatch, getState) {
  return function(data, callback, importType) {
    dispatch(actions.setBusy(true));

    let previewData = {
      importType,
      validAssetsBusinessIds: data.validAssetsBusinessIds,
    }
    previewData.fileName = getState().wizards.getIn(['importAttributesUpdateWizard', 'fileToken']);

    return bulkUpdateService.getUpdateAttributesPreview(previewData).then((response) => {
      dispatch(actions.setBusy(false));

      if (!Network.isResponseValid(response)) {
        messageDialogApi.responseError(dispatch, getState)(response);
        console.error('Get attributes preview failed', response);
        return null;
      }

      dispatch(actions.getAttributesPreviewFinished(response.dataList));

      if (callback) {
        callback();
      }
    });
  }
}

api.submit = function (dispatch, getState) {
  return function(data, importType, messageDialogBuilder, reloadParentComponent) {
    dispatch(actions.setBusy(true));

    data.fileName = getState().wizards.getIn(['importAttributesUpdateWizard', 'fileToken']);

    return bulkUpdateService.updateAttributes(data, getApiPathByImportType(importType)).then((response) => {

      dispatch(actions.setBusy(false));
      dispatch(actions.hide());

      if (!Network.isResponseValid(response)) {
        console.error('Update attributes failed', response);
        messageDialogApi.responseError(dispatch, getState)(response);
        return null;
      }

      if (reloadParentComponent) {
        reloadParentComponent();
      }

      if (messageDialogBuilder) {
        let messageDialogDescriptor = messageDialogBuilder(
          response.data,
        );
        messageDialogApi.open(dispatch, getState)(messageDialogDescriptor);
      }
    });
  }
}


//////////////////////////////////////////////////////////////////////////////
// PRIVATE HELPERS

function getApiPathByImportType(importType){
  switch(importType){
    case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_ROLL'):
      return 'rolls';
    case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_SPOOL'):
      return 'spools';
    case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_RESIN'):
      return 'resins';
    case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_PART_TYPE'):
    case getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_WORK_ORDER_KIT_TYPE'):
      return 'workOrders';
    default:
      console.error('getApiPathByImportType(): Unknown import type: ', importType);
      return '';
  }
}
