import React from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import DatePicker from 'infrastructure/js/components/controls/DatePicker/datepicker';
import GenerateButton from 'infrastructure/js/components/controls/GenerateButton/generateButton';
import TextAreaField from 'infrastructure/js/components/controls/TextAreaField/textAreaField';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import {DefaultWorkOrderTypes} from '../../../utils/workOrderHelper';
import AdditionalField from '../../Common/Layout/AdditionalField/AdditionalField';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {enumTypes, getEnumValue, getLookupOptions} from '../../../utils/enumHelper';
import {connect} from 'react-redux';
import EntitiesMultiSelect from '../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import {EntityPropertyTypes} from '../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../enums/fetchEntitiesFilters';
require('./createWorkOrderDialog.scss');

class CreateWorkOrderDialog extends React.PureComponent {

  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.workorder.createworkorderdialog.');
    this.state = { selectedProject: null, selectedPartType: null, partsOptions:[], projectOptions:[]};

    this.woFetchConfig = {
      entityType: EntityPropertyTypes.WO_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE],
      action: this.props.actions.fetchEntities,
    };

  }

  componentDidMount() {
      let initialValues = {
        priority: getLookupOptions(enumTypes.PRIORITY_TYPE).find((x)=>{return x.value == 'REGULAR'}),
      };
      this.props.initialize(initialValues);
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
        text: this.dialogLabels.get('cancel'),
        action: this.props.actions.onCancel
      }],
      right: [
        {
          id:'create',
          text: this.dialogLabels.get('create'),
          bsStyle: 'primary',
          //TODO
          //loading: this.props.sData.get('bringAssetsLoading'),
          action: this.props.handleSubmit(this.onSubmit)
        }
      ]
    };
  }

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog) => {
      let buttons = [{id:'close',text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: "primary"}];
      let className = "create-workorder-confirm-message";

      let title = this.labels.get('confirmation.header');
      let children = [];
      let messageDialogDescriptor;

      if (data) {
        let rows = [];

        if(data.businessId) {
          rows.push({label: this.labels.get('confirmation.message.workorderid'), value: data.businessId});
        }
        if(data.partType) {
          rows.push({label: this.labels.get('confirmation.message.part'), value: (data.partType ? data.partType.businessId : '')});
        }
        if(data.kitType) {
          rows.push({label: this.labels.get('confirmation.message.kittype'), value: (data.kitType ? data.kitType.businessId : '')});
        }
        if(data.project) {
          rows.push({label: this.labels.get('confirmation.message.project'), value: (data.project ? data.project.businessId : '')});
        }

        children = rows.map((item, index) => {
          return <MessageDialog.DataRow key={`confirmMessageDataRow${index}`} label={item.label} value={item.value}/>
        });
      }
      if (children.length > 0){
        messageDialogDescriptor = {title, children, buttons, className};
      } else {
        messageDialogDescriptor = {title, className: "oneBackground"};
      }

      return messageDialogDescriptor;
    };
  };

  renderPickDate = ()=>{
    if(!PermissionManager.getOrgPreferences().woPickDateSupported){
      return null;
    }
    return <InputSection label={this.labels.get('pickDate')} htmlFor="pickDate" className="inline right-side">
      <DatePicker id="pick-date-date-picker" name="pickDate" initialDate={null}
                  popperPlacement="auto"
                  validate={Validation.date}
                  minDate={DateTimeHelper.ConvertToDate({epochDateTime: this.props.orgTime})}/>
    </InputSection>
  };

  renderStartAfter = () => {
    if(PermissionManager.getOrgPreferences().schedulerEnabled) {
      return (
        <InputSection label={this.labels.get('startAfter')} htmlFor="startAfter" className="full-width">
          <EntitiesMultiSelect
            id="startAfter"
            name="startAfter"
            entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('WO')]}
            fetchConfig={this.woFetchConfig}
            change={this.props.change}
            showEntitiesTypes={false}
          />
        </InputSection>
      )
    }
    return null;
  }

  onSubmit = (data) => {
    data.additionalFieldsDefinitions = this.props.sData.get("dialogData") ? this.props.sData.get("dialogData").additionalFields:[];
    data.workOrderType = this.props.defaultWorkOrderType;
    this.props.actions.createWorkOrder(data, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  }

  onEntered =() => {
    this.initData();
  }

  initData =() => {
  }

  generateWorkOrderId = () =>{
    this.props.actions.generateWorkOrderId().then(
      (response) => {
        this.props.change( "workOrderId", response.data);
      }
    );
  }

  handleKitTypeOnChange = (selectedOption) => {
    let relatedProjectData = {
      label: selectedOption.relatedProject.businessId,
      value : selectedOption.relatedProject.id
    };
    this.props.change('project', relatedProjectData);
  }

  renderDefaultWorkOrderField = (type) => {
    if(type === DefaultWorkOrderTypes.PARTTYPE_ORIENTED) {
      return (
        <InputSection label={this.labels.get('part')} htmlFor="part" className="inline left-side no-margin">
          <Combobox id="parts-combobox"  name="part" options={this.props.sData.get("partsOptions")}  onChangeCallback={this.onPartOnChangeHandler}  optionRenderer={this.getComponentToRender} isClearable={true}/>
        </InputSection>
      );
    } else if (type === DefaultWorkOrderTypes.KITTYPE_ORIENTED) {
      return (
        <InputSection label={this.labels.get('kittype')} htmlFor="kitType" className="inline left-side no-margin">
          <Combobox  id="kit-type-combobox" name="kitType" options={this.props.sData.get('dialogData').kitTypesOptions} onChangeCallback={this.handleKitTypeOnChange} isClearable={true}/>
        </InputSection>
      );
    } else {
      console.error('error in renderFirstFieldByDefaultWorkOrderTypes: invalid type: ' + type);
      return null;
    }
  }

  renderAdditionalFields = ()=>{
    if(!this.props.sData.get('dialogData') || !this.props.sData.get("dialogData").additionalFields){
      return null;
    }
    return this.props.sData.get("dialogData").additionalFields.map((field, index)=> {
      return (<AdditionalField key={index} field={field} index={index}/>)
    });
  };


  getComponentToRender(props) {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;
    return <span className={option && option.isRelated ? 'mark-as-related-option' : '' }>{option.label}</span>

  };

  onPartOnChangeHandler = (newValue, oldValue) => {
    this.setState({selectedPartType: newValue});
    if (!newValue && !oldValue) {
      return;
    }
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    this.props.actions.onPartChanged(newValue, this.state.selectedProject).then((response) => {
      let match = null;
      if (this.state.selectedProject){
        match = response.projectOptions.find((p) => {return p.value === this.state.selectedProject.value});
      }
      this.props.change('project', match);
    });
  };

  onProjectOnChangeHandler = (newValue, oldValue) => {
    if(this.props.defaultWorkOrderType === DefaultWorkOrderTypes.KITTYPE_ORIENTED){
      return;
    }
    this.setState({selectedProject: newValue});
    if (!newValue && !oldValue) {
      return;
    }
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }

    this.props.actions.onProjectChanged(newValue, this.state.selectedPartType).then((response) => {
      let match = null;
      if (this.state.selectedPartType){
        match = response.partsOptions.find((p) => {return p.value === this.state.selectedPartType.value});
    }
      this.props.change('part', match);
    });
  };

  render() {
    return (
      <Dialog
        id="create-work-order-dialog"
        className="create-work-order-dialog"
        titleText={this.labels.get('header.title')}
        show={this.props.sData.get('show')}
        onEntered={this.onEntered}
        onHide={this.props.actions.onCancel}
        sData={this.props.sData}
        footerButtons={this.getDialogButtons()}  >


        {this.renderDefaultWorkOrderField(this.props.defaultWorkOrderType)}

        <InputSection label={this.labels.get('quantity')} htmlFor="quantity" className="inline right-side no-margin">
          <TextField id="quantity-textField" name="quantity" normalize={Normalize.number(true, 0, 99999)} />
        </InputSection>

        <InputSection label={this.labels.get('project')} htmlFor="project" className="inline left-side">
          <Combobox id="project-combobox"
                    name="project"
                    options={this.props.sData.get("projectOptions")}
                    optionRenderer={this.getComponentToRender}
                    isClearable={true}
                    onChangeCallback={this.onProjectOnChangeHandler}

          />
        </InputSection>

        <InputSection label={this.labels.get('customerorder')} htmlFor="customerOrder" className="inline right-side">
            <Combobox id="customer-order-combobox" name="customerOrder" options={this.props.sData.get("dialogData").customerOrdersOptions} allowNewOption={true}/>
        </InputSection>

        <InputSection label={this.labels.get('workorderid')+'*'} htmlFor="workOrderId" className="inline left-side">
          <GenerateButton id='workOrderId' name="workOrderId" className="work-order-id"
                          placeholder={this.labels.get('workorderid.inputhint')}
                          buttonText={this.labels.get('workorderid.generate')}
                          validate={Validation.required} onClick={this.generateWorkOrderId} />
        </InputSection>

        <InputSection label={this.labels.get('duedate')} htmlFor="date" className="inline right-side">
            <DatePicker id="due-date-date-picker" name="date" initialDate={null}
                        popperPlacement="auto"
                        validate={Validation.date}/>
        </InputSection>


        <InputSection label={this.labels.get('priority')} htmlFor="priority" className="inline left-side">
          <Combobox id="priority-combobox" name="priority" options={getLookupOptions(enumTypes.PRIORITY_TYPE)}/>
        </InputSection>

        {this.renderPickDate()}

        {this.renderStartAfter()}

        <InputSection label={this.labels.get('description')} htmlFor="description" className="full-width description-section">
          <TextAreaField id="description" name="description" maxLength='1000'/>
        </InputSection>

        <div>
          {this.renderAdditionalFields()}
        </div>
      </Dialog>
    );
  }
}

let CreateWorkOrderDialogContainer = connect( state => {
  return { orgTime : state.system.get("serverDatetime")}
})(CreateWorkOrderDialog);

export default reduxForm({
    form: 'createWorkOrderDialog',
  }
)(CreateWorkOrderDialogContainer);
