import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import Tab from 'react-bootstrap/Tab';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import MisplacedMaterialForm from '../MisplacedMaterialForm/misplacedMaterialForm';
import TaskTabForm from '../TaskTabForm/taskTabForm';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import CommonAlertsForm from '../CommonAlertsForm/commonAlertsForm';

import'./commonAlertsView.scss';

const TabKeys = {
  ROLL: 'roll',
  SPOOL: 'spool',
  RESIN: 'resin',
  KIT:   'kit',
  PART: 'part',
  GROUP: 'group',
  TOOL: 'tool',
  TASK: 'task',
  MATERIAL: 'misplacedMaterial',
};

export default class CommonAlertsView extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.settings.alerts.form.');
    this.entityLabels = createLabelHelper('mat.entity.type.');
    this.navigationLabels = createLabelHelper('mat.navigation.confirmation.');
    this.state = {
      selectedTab: this.getSelectedTabByAlertType(props.alertType)
    };
  }

  componentDidMount () {
    const {alertType, menuItemId} = this.props;
    this.props.actions.settingsPageActions.onSelectedMenuItemChanged(menuItemId);
    this.props.actions.load(alertType);
  }

  getSelectedTabByAlertType(alertType){
      switch (alertType) {
        case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
        case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
        case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
        case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
        case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
          return TabKeys.ROLL;
        case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
          return TabKeys.KIT;
        case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
        case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
          return TabKeys.TOOL;
        case getEnumValue(enumTypes.ALERT_TYPE)('PASSED_SCHEDULE'):
          return TabKeys.TASK;
        case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
          return TabKeys.MATERIAL;
      }
  }

  onTabClick = (key) => {
    this.setState({selectedTab: key});
  };


  getTabTitles = () => {
    let roll = <TabTitle  title={this.entityLabels.get('roll')} className ="pl-header-icon-roll"/>;
    let spool = <TabTitle title={this.entityLabels.get('spool')} className ="pl-header-icon-spool"/>;
    let resin = <TabTitle title={this.entityLabels.get('resin')} className ="pl-header-icon-resin"/>;
    let kit = <TabTitle   title={this.entityLabels.get('kit')} className ="pl-header-icon-kit"/>;
    let group = <TabTitle title={this.entityLabels.get('group')} className ="pl-header-icon-container"/>;
    let tool = <TabTitle  title={this.entityLabels.get('tool')} className ="pl-menu-active-tool"/>;
    let task = <TabTitle  title={this.entityLabels.get('task')} className ="pl-scheduled"/>;   //TODO: L replace icon

    return {
      roll,
      spool,
      resin,
      kit,
      group,
      tool,
      task
    };
  };

  getLoadingOverlay() {
    if (this.props.sData.get('loading')) {
      return <Overlay.Loading />;
    }
    return null;
  }

  renderHeader() {
    let {alertType} = this.props;
    let alertLabel = this.labels.get(alertType);
    let title = this.labels.get('title', '', {alertType: alertLabel});
    return (
      <div className="common-alerts-view-header">
        <label className='common-alerts-view-title'>{title}</label>
      </div>
    );
  }

  renderForm = (data, entityType, alertType) => {
    if(data) {
      return (
        <CommonAlertsForm actions={this.props.actions}
                          form={entityType + 'TabForm'}
                          sData={data}
                          labels={this.labels}
                          alertType={alertType}
                          entityType={entityType}
        />
      );
    }
    return null;
  };

  renderTaskForm = (data) => {
    if(data) {
      return (
        <TaskTabForm actions={this.props.actions}
                     sData={data}
                     labels={this.labels}
                     alertType={this.props.alertType}
                     alertTypes={this.alertTypes}
        />
      );
    }
    return null;
  };

  renderMisplacedMaterialForm = (data) => {
    if(!data) {
      return null;
    }

    const { sMisplacedMaterialAlertRulesGridData, sMisplacedMaterialAlertRuleDialogData, actions, alertType} = this.props;

    return (
      <MisplacedMaterialForm actions={actions}
                  sData={data}
                  sMisplacedMaterialAlertRulesGridData={sMisplacedMaterialAlertRulesGridData}
                  sMisplacedMaterialAlertRuleDialogData={sMisplacedMaterialAlertRuleDialogData}
                  alertType={alertType}
                  alertTypes={this.alertTypes}
      />
    );
  };

  renderPrompt = () => {
    return (
      <Prompt
        when={this.props.isDirty}
        message={location =>
          this.navigationLabels.get('datanotsaved.text')
        }
      />
    );
  };

  renderTab = (entityType, alertType, tabTitles) => {
    return (
      <Tab key={entityType + 'Tab'} eventKey={entityType} animation={false} title={tabTitles[entityType]}>
        {this.renderForm(this.props.sData.get(entityType + 'Data'), entityType, alertType)}
      </Tab>
    );
  };

  renderTaskTab = (alertType, tabTitles) => {
    return (
      <Tab key='taskTab' eventKey={'task'} animation={false} title={tabTitles.task}>
        {this.renderTaskForm(this.props.sData.get('taskData'))}
      </Tab>
    );
  };

  renderMisplacedMaterialTab = (alertType, tabTitles) => {
    return (
      <Tab key='misplacedMaterialTab' eventKey={'misplacedMaterial'} animation={false} >
        {this.renderMisplacedMaterialForm(this.props.sData.get('misplacedMaterialData'))}
      </Tab>
    );
  };

  renderTabs = (alertType, tabTitles) => {
    let tabsArr = [];
    switch (alertType) {
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPIRATION_DATE'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_BOND'):
      case getEnumValue(enumTypes.ALERT_TYPE)('EXPOSURE_TIME_CURE'):
         tabsArr = [
          this.renderTab(TabKeys.ROLL, alertType, tabTitles),
          this.renderTab(TabKeys.SPOOL, alertType, tabTitles),
          this.renderTab(TabKeys.RESIN, alertType, tabTitles),
          this.renderTab(TabKeys.KIT, alertType, tabTitles),
        ];

        if(PermissionManager.hasGroupAssetPermissions() && PermissionManager.getOrgPreferences().groupsEnabled){
          tabsArr.push(this.renderTab(TabKeys.GROUP, alertType, tabTitles))
        }
        return tabsArr;
      case getEnumValue(enumTypes.ALERT_TYPE)('RESERVED'):
        return [this.renderTab(TabKeys.ROLL, alertType, tabTitles),
                this.renderTab(TabKeys.SPOOL, alertType, tabTitles),
                this.renderTab(TabKeys.RESIN, alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('WO_DUE_DATE'):
        return [this.renderTab(TabKeys.KIT, alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('CYCLES_COUNT'):
      case getEnumValue(enumTypes.ALERT_TYPE)('MAINTAINABLE_TIME'):
        return [this.renderTab(TabKeys.TOOL, alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('DEFROSTING_TIME'):
        return [this.renderTab(TabKeys.ROLL, alertType, tabTitles),
                this.renderTab(TabKeys.SPOOL, alertType, tabTitles),
                this.renderTab(TabKeys.RESIN, alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('PASSED_SCHEDULE'):
        return [this.renderTaskTab(alertType, tabTitles)];
      case getEnumValue(enumTypes.ALERT_TYPE)('MISPLACED_MATERIAL'):
        return [this.renderMisplacedMaterialTab(alertType, tabTitles)];
      default:
        console.error('Unknown alert type: '  +  alertType);
        return null;
    }
  };

  render() {
    let alertType = this.props.alertType;
    let tabTitles = this.getTabTitles();

    return (
      <div className='common-alerts-view-container'>
          {this.renderHeader()}
          <div className="top-common-alerts-view-tabs-section">
            <Tabs activeKey={this.state.selectedTab} tabType="content" onSelect={this.onTabClick} id="content-tabs">
              {this.renderTabs(alertType, tabTitles)}
            </Tabs>
          </div>

          {this.renderPrompt()}
          {this.getLoadingOverlay()}
        </div>
    );
  }
}

CommonAlertsView.propTypes = {
  actions : PropTypes.object.isRequired,
  sData : PropTypes.object.isRequired,
  isDirty : PropTypes.bool.isRequired,
  alertType: PropTypes.string.isRequired,
  menuItemId: PropTypes.string.isRequired,
};




