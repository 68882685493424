import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell.js';
import ValueWithStateCell from '../../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import LengthCell from '../../../../Common/CustomGridCells/LengthCell/lengthCell.js';
import WeightCell from '../../../../Common/CustomGridCells/WeightCell/weightCell.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import MaterialHelper from '../../../../../utils/materialHelper';
import {getAssetEtl} from '../../../../../utils/assetHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import DefrostingLabelCell from '../../../../Common/CustomGridCells/DefrostingLabelCell/defrostingLabelCell';
import LastOperationCell from '../../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {alertStatus,alertType,getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import {enumTypes, getEnumLabel, getEnumValue} from '../../../../../utils/enumHelper';
import {
  isIncomingShipments,
  isOutgoingShipments,
  isOutgoingOrIncomingLocation,
  isShippingPreparation,
} from '../../../../../utils/locationHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import IconWithTooltipCell from '../../../../Common/CustomGridCells/IconWithTooltipCell/iconWithTooltipCell';
import {getStatusIcon} from '../../../../../utils/statusHelper';

require('./rollsGrid.scss');

class RollsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'material.businessId', filterName: 'material', getOptions: false},
    {fieldName: 'shipmentInfo.businessId', filterName: 'shipmentBusinessId', getOptions: false},
    {fieldName: 'lot', filterName: 'lot', getOptions: false},
    {fieldName: 'lengthLeft', filterName: 'length'},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'defrostingStatus', filterName: 'defrostingStatus', getOptions: true},
    {fieldName: 'weight', filterName: 'weight'},
    {fieldName: 'shipmentInfo.supplierName', filterName: 'shipmentSupplierName', getOptions: true},
    {fieldName: 'shipmentInfo.customerName', filterName: 'shipmentCustomerName', getOptions: true},
    {fieldName: 'batchNum', filterName: 'batchNum', getOptions: true},
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},
    {fieldName: 'shipmentInfo.status', filterName: 'shipmentStatus', getOptions: true},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.allLabels = createLabelHelper('');
    this.filterDefrostinglabels = createLabelHelper('mat.filter.defrosting.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let lengthOrWeightColumn = this.getLengthOrWeight();
    let customColumn = this.getColumnByLocationType();

    let columnsConfig = [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '',
              alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              defrostingTimeLeft: params.data.defrostingTimeLeft,
              locationName: params.data.locationName,
              pickListBusinessId: params.data.pickListBusinessId,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.rollId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
          filter: this.getRollIdFilterByLocationType(),
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
                id: params.data.id,
                label: params.data.businessId,
                type: navigationStates.ROLL,
              };
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'material',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: this.getFilterByLocationType(),
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {...customColumn},
      {
        fieldName: 'lot',
        title: this.labels.get('columns.lot.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'lot',
        fetchConfig: {
          entityType: EntityPropertyTypes.LOT_NAME,
          filter: this.getFilterByLocationType(),
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => ({
            id: params.data.lot?.id,
            label: params.data.lot?.name,
            type: params.data.lot ? navigationStates.LOT : '',
          }),
        },
      },
      ...(this.getBatchNoColumn()),
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {...lengthOrWeightColumn},
      ...(this.getCustomerSupplierColumn()),
      ...(this.getStatusColumn()),
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      }
    ];
    return columnsConfig;
  }

  getRollIdFilterByLocationType = () => {
    let {locationDetails} = this.props;

    if (isShippingPreparation(locationDetails)) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IS_LOCKED]
    }
    if (isIncomingShipments(locationDetails)) {
      return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PASSPORT_STATUS]
    }
    if (isOutgoingShipments(locationDetails)) {
      return [FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.IN_PASSPORT_STATUS]
    }

    return [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION]
  }

  getFilterByLocationType = () => {
    let {locationDetails} = this.props;

    let filters = [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_TYPE_ROLL, FetchEntitiesFilters.ON_LOCATION];

    if (isShippingPreparation(locationDetails)) {
      return [...filters, FetchEntitiesFilters.ASSET_LOCKED]
    }
    if (isOutgoingOrIncomingLocation(locationDetails)) {
      return [...filters, FetchEntitiesFilters.ASSET_IN_PASSPORT_STATUS]
    }
    return filters;
  }

  getColumnByLocationType = () => {

    switch (this.props.locationDetails?.locationType) {
      case getEnumValue(enumTypes.LOCATION_TYPE)('DEFROSTING_AREA'):
        return ({
          fieldName: 'defrostingStatus',
          title: this.labels.get('columns.defrosting.title'),
          filterType: filterTypes.MULTI_SECTIONS,
          filterSections: this.getDefrostingColumnFilterSections(),
          filterName: 'defrostingStatus',
          width: 170,
          columnOptions: {
            cellComponent: DefrostingLabelCell,
            valueGetter: (params) => {
              return {
                status: params.data.defrostingStatus,
                statusLabel: params.data.defrostingStatusDisplayKey ? this.allLabels.get(params.data.defrostingStatusDisplayKey) : '',
                defrostingTimeLeft: params.data.defrostingTimeLeft
              }
            }
          }
        });
      case getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION'):
      case getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS'):
      case getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS'):
        return {
          fieldName: 'shipmentInfo.businessId',
          title: this.labels.get('columns.shipmentId.title'),
          filterType: filterTypes.MULTI_SELECT_ASYNC,
          filterName: 'shipmentBusinessId',
          fetchConfig: {
            entityType:  EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
            filter: [FetchEntitiesFilters.ASSET_ON_LOCATION],
            action: this.props.actions.fetchEntities
          },
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.shipmentInfo?.businessId || ''
            }
          }
        }

      default:
        return {
          fieldName: 'relatedAssetData',
          title: '',
          filterType: filterTypes.NONE,
          width: 70,
          columnOptions: {
            sortable: false,
            resizable: false,
            headerValueGetter: GridHelper.headerValueGetter,
            cellComponent: RelatedAssetsCell,
            valueGetter: (params) => {
              return {
                count: params.data.relatedAssetsCounter,
                cellAction: this.props.cellAction,
                assetId: params.data.id,
                componentAssetsTitle: this.labels.get('columns.componentAssets.cell.title'),
                resultingAssetsTitle: this.labels.get('columns.resultingAssets.cell.title')
              };
            },
            headerComponentParams: {
              headerIcon: 'resulting-assets',
              headerTooltip: this.labels.get('columns.resultingAssets.cell.title'),
            }
          }
        };
    }
  }

  getLengthOrWeight = () => {

    if(PermissionManager.isWeightSupported()){
      return {
        fieldName: 'weight',
        title: this.labels.get('columns.weight.title'),
        filterType: filterTypes.NONE,
        width: 110,
        columnOptions: {
          cellComponent: WeightCell,
          valueGetter: (params) => {
            return {
              weight: params.data.weight
            };
          },
        }
      }
    }

    return {
      fieldName: 'lengthLeft',
      title: this.labels.get('columns.length.title'),
      filterType: filterTypes.NONE,
      width: 110,
      columnOptions: {
        cellComponent: LengthCell,
        valueGetter: (params) => {
          return {
            length: params.data.lengthLeft
          };
        },
      }
    }
  };

  getBatchNoColumn = () => {
    const {locationDetails} = this.props;
    if (isShippingPreparation(locationDetails) ||
        isOutgoingShipments(locationDetails) ||
        isIncomingShipments(locationDetails) ) {
      return [
        {
          fieldName: 'batchNum',
          title: this.labels.get('columns.batchNo.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'batchNum',
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.batchNum || ''
            }
          }
        },
      ]
    }
    return [];
  }

  getCustomerSupplierColumn = () => {
    const {locationDetails} = this.props;
    if (isShippingPreparation(locationDetails) ||
        isOutgoingShipments(locationDetails) ) {
      return [
        {
          fieldName: 'shipmentInfo.customerName',
          title: this.labels.get('columns.customerName.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'shipmentCustomerName',
          filterAlignment: isOutgoingShipments(locationDetails) ? filterAlignmentTypes.RIGHT : undefined,
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.shipmentInfo?.customerName || ''
            }
          }
        },
      ]
    }
    else if (isIncomingShipments(locationDetails) ) {
      return [
        {
          fieldName: 'shipmentInfo.supplierName',
          title: this.labels.get('columns.supplier.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'shipmentSupplierName',
          columnOptions: {
            cellComponent: LabelWithTooltipCell,
            valueGetter: (params) => {
              return params.data.shipmentInfo?.supplierName || ''
            }
          }
        },
      ]
    }

    return [];
  }

  getStatusColumn = () => {
    const {locationDetails} = this.props;
    if (isShippingPreparation(locationDetails) || isIncomingShipments(locationDetails)) {
      return [
        {
          fieldName: 'assetStatus',
          title: this.labels.get('columns.status.title'),
          filterType: filterTypes.MULTI_SELECT,
          filterName: 'assetStatus',
          filterAlignment: filterAlignmentTypes.RIGHT,
          columnOptions: {
            // cellComponent: LabelWithTooltipCell,
            cellComponent: IconWithTooltipCell,
            valueGetter: (params) => {
              return {
                tooltip: params.data.assetStatus ? (getEnumLabel(enumTypes.ASSET_STATUS)(params.data.assetStatus) || params.data.assetStatus) : '',
                icon: getStatusIcon(params.data.assetStatus),
              };
            },
            // valueGetter: (params) => {
            //   return params.data.assetStatus ? getEnumLabel(enumTypes.ASSET_STATUS)(params.data.assetStatus) : '';
            // },
          }
        },
      ]
    }

    return [];
  }

  getDefrostingColumnFilterSections() {
    return [
      {
        properties: [
          {name: 'BEING_DEFROSTED', label: this.filterDefrostinglabels.get('beingDefrosted'), value: false},
          {name: 'DEFROSTED', label:  this.filterDefrostinglabels.get('defrosted'), value: false}
        ]
      },
    ];
  }

  render() {
    return (
      <div className="rolls-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_ROLLS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  gridNameSuffix: this.props.locationDetails?.locationType,
                  getRowHeight: () => 84
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'AssetsView_RollsGrid'
  }
)(RollsGrid);

RollsGrid.propTypes = {
  actions: PropTypes.object.isRequired,
  locationDetails: PropTypes.object.isRequired,
  cellAction: PropTypes.func.isRequired,
};
