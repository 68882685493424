import React, { Component } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { getHomePagePathByType, getPathByType } from '../../utils/navigationHelper';
import { navigationStates } from '../../enums/navigationStates';
import { Link } from 'react-router-dom';

const illustrationImg = require("images/404-illustration.png");

require('./pageNotFoundPage.scss');


export default class PageNotFoundPage extends Component {
  constructor(props){
    super(props);

    this.labels = createLabelHelper('mat.pagenotfoundpage.');
  }

  componentDidMount() {
    this.props.actions.pageNotFoundPageActions.init();
  }

  getHomePagePath() {
    const defaultHomePage = getPathByType(navigationStates.MANAGER_REPORT);
    let {defaultEntityId, defaultEntityType} = this.props.sData.userPreferences;

    if(!defaultEntityType){
      return defaultHomePage;
    }

    return getHomePagePathByType(defaultEntityType, defaultEntityId) || defaultHomePage;
  }

  render() {
    return (
      <div className='page-not-found-page'>

        <div className='page-not-found-content'>
          <div className='icons-section'>
            <img src={illustrationImg} />
          </div>

          <div className='message-section'>
            <span className='first-line'>{this.labels.get('messages.firstline')}</span>
            <span>{this.labels.get('messages.secondline')}</span>
          </div>

          <Link to={this.getHomePagePath()} >
            <div className="btn btn-primary pointer">
            {this.labels.get('button.tryhomepage')}
            </div>
          </Link>
        </div>
      </div>
    );
  }
}
