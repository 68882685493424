import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'infrastructure/js/components/controls/Button/button.js';
import { LoginInput, LoginLayout } from 'infrastructure/js/components/LoginLayout/loginLayout';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import cn from 'classnames';

require('./mobileLoginPage.scss');

export default class MobileLoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { forceOrgFieldDisplay: false, errorMessage: '' };

    let initialData = __DEV__
      ? { email: 'pmuser', password: 'aaa123', orgID: this.getOrgIDinStorage(), rememberMe: false }
      : { orgID: this.getOrgIDinStorage() };
    props.initialize(initialData);
  }

  componentDidMount() {
    const ssoLogin = this.props.location.search.includes('sso');
    if (ssoLogin) {
      this.props.actions.loginSSO();
    }
  }

  getOrgIDinStorage = () => {
    return localStorage.getItem('orgID');
  };

  _login = (formData) => {
    this.props.actions.login(formData);
  };

  navigateToResetPassword = () => {
    this.props.history.push({
      pathname: '/ResetPassword',
      state: {
        org: this.props.formValues.orgID,
        user: this.props.formValues.email,
      },
    });
  };

  redirectToSSO = () => {
    this.props.actions.redirectToSSO(this.props.formValues.orgID);
  };

  getFooter() {
    const ssoError = this.props.sData.get('ssoErrorMessage');
    return (
      <div className="footer">
        <div className="links-section">
          <a target="_self" className="pointer" onClick={this.navigateToResetPassword}>
            Reset Password
          </a>
          <a target="_blank" className="pointer" href="http://jira.plataine.com:8080/servicedesk/customer/portal/1">
            Support
          </a>
        </div>
        <div className="sso-section">
          <div className="or-divider">
            <span>OR</span>
          </div>
          <Button
            id="login"
            bsStyle="default"
            className="btn btn-default border-btn no-icon"
            onClick={this.redirectToSSO}
            loading={this.props.sData.get('ssoLoading')}
            onlyLoading={true}
            tabIndex="10"
          >
            Sign In Using SSO
          </Button>
          <div className="sso-error">{ssoError}</div>
        </div>
      </div>
    );
  }

  hasError = () => this.props.sData.get('hasError');
  hasSSOError = () => this.props.sData.get('hasSSOError');

  setAutoFocus = () => {
    let autoFocus = 'autoFocus';

    if (this.getOrgIDinStorage()) {
      return autoFocus;
    }

    return null;
  };
  secretKeyPressHandler = (e) => {
    if (e.key === '`') {
      this.setState({ forceOrgFieldDisplay: true });
    }
  };
  render() {
    return (
      <LoginLayout
        secretKey={this.secretKeyPressHandler}
        formClass={cn('login-page', { 'has-error': this.hasError(), 'has-sso-error': this.hasSSOError() })}
        actionButtonText="Login"
        actionButtonOnclick={this.props.handleSubmit(this._login)}
        footerComponent={this.getFooter()}
        loading={this.props.sData.get('loading')}
      >
        <LoginInput
          label="organization"
          name="orgID"
          id="OrgIDInput"
          placeholder="Organization"
          validate={Validation.required}
          tabIndex="1"
          autoFocus={!this.setAutoFocus()}
        />

        <LoginInput
          label="user name"
          name="email"
          id="UserNameInput"
          placeholder="Username"
          validate={Validation.required}
          tabIndex="2"
          autoFocus={this.setAutoFocus()}
        />

        <LoginInput
          label="password"
          name="password"
          id="PasswordInput"
          placeholder="Password"
          type="password"
          validate={Validation.required}
          tabIndex="3"
        />

        <div className="login-error">{this.props.sData.get('errorMessage')}</div>
      </LoginLayout>
    );
  }
}

MobileLoginPage.propTypes = {
  initialize: PropTypes.func.isRequired,
  loginPath: PropTypes.string,
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};
