import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import {navigationStates} from '../../../../enums/navigationStates';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import LastOperationCell from '../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import ToolTypeHelper from '../../../../utils/toolTypeHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';
import {enumTypes, getEnumLabel} from '../../../../utils/enumHelper';

require('./archivedToolsGrid.scss');

class ArchivedToolsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'assetStatus', filterName: 'assetStatus', getOptions: true},
    {fieldName: 'toolType.businessId', filterName: 'toolType', getOptions: true},
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'toolType.toolCategory.businessId', filterName: 'toolCategoryBusinessId', getOptions: true },
    {fieldName: 'lastStatusChangeDate.epochDateTime', filterName: 'lastStatusChangeDate'},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
    {fieldName: 'toolType.configurationType', filterName: 'configurationType', getOptions: true},
  ];

  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetStatus',
        valueFormatter: (params) => {
          return (params.data.assetStatusLabel || '');
        },
        columnOptions: {
          // sort: 'asc',
          valueGetter: (params) => {
            return (params.data.assetStatus || '');
          },
        }
      },
      {
        fieldName: 'lastStatusChangeDate.epochDateTime',
        title: this.labels.get('columns.archivingDate.title'),
        filterType: filterTypes.NONE,
        valueFormatter: (params) => {
          return (params.data && params.data.m_archivingDate ? params.data.m_archivingDate : '');
        },
      },
      {
        fieldName: 'toolType.businessId',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({data}) => {
            return ToolTypeHelper.getToolTypeFullLabel(data.toolType.description, data.toolType.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.toolId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        filterAlignment: filterAlignmentTypes.RIGHT,
        fetchConfig: {
          entityType: EntityPropertyTypes.TOOL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ARCHIVED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.TOOL
            };
          }
        }
      },
      {
        fieldName: 'toolType.toolCategory.businessId',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolCategoryBusinessId',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions:{
          valueGetter: (params) => {
            return params.data.toolType?.toolCategory?.businessId || '';
          },
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
      {
        fieldName: 'toolType.configurationType',
        title: this.labels.get('columns.kitTypesConfiguration.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'configurationType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.TOOL_CONFIGURATION_TYPE)(params.data.toolType?.configurationType);
          },
        }
      },
    ]
  }

  render() {
      return (
        <div className="archived-tools-grid">
          <Grid gridName={gridsNames.PREDEFINED_LIST_ARCHIVED_TOOLS}
                columnsConfig={this.columnsConfig}
                actions={this.props.actions}
                filterConfig={this.filterConfig}
                gridProps={
                  {
                    filterPersistence: true,
                    getRowHeight: () => 84
                  }
                }
          >
          </Grid>
        </div>
      );
    }
 }

export default reduxForm({
    form: 'archivedToolsGrid'
  }
)(ArchivedToolsGrid);
