import Network from 'infrastructure/js/modules/network';
import { api as messageDialogApi }  from '../MessageDialog/messageDialogActions.js';
import * as schedulerService from '../../services/Scheduler/schedulerService';
import * as toolsService from '../../services/Tools/toolsService';
import {api as schedulerTasksPageApi} from './schedulerTasksPageActions' ;
import { isToolRequired } from '../../utils/toolRequiredHelper';
import * as daHelper from '../Dialogs/dialogActionsHelper';
import {dialogsNames} from '../../enums/dialogsNames';
import * as dialogHelper from 'infrastructure/js/components/Dialog/dialogHelper';

const config = {
  getSubmitMethod: () => null, //set null when override the dialogActionsHelper's submit() method
};

export const actionTypes = {
  ...daHelper.getActionTypes(dialogsNames.SCHEDULE_TASK_DIALOG),
};

const actions = {
  ...daHelper.getActions(dialogsNames.SCHEDULE_TASK_DIALOG),
};

export let api = {
  ...daHelper.getApiActions(actions, config),

  show(dispatch, getState) {
    return function(item = null) {
      if(item && item.taskType === 'NON_OPERATIONAL'){
        dispatch(actions.show(item));
        return;
      }
      if (!item || !item.operation) {
        console.error('missing item data for SCHEDULE_TASK_DIALOG_SHOW')
        return;
      }

      api.fetchDialogData(dispatch, getState)(item);

      dispatch(actions.show(item));
    }
  },

  fetchDialogData(dispatch, getState) {
    return function(item) {

      let toolRequirement = isToolRequired(item);

      let promises = [schedulerService.fetchResources(item?.operation?.id)];
      if (toolRequirement) {
        let data = {
          kitTypeId : item?.kitType?.id,
          taskAssignmentId : item?.id,
        }
        promises.push(toolsService.getToolsByKitType(data));
      }

      return Promise.all(promises).then((allResults) => {
        const invalidResponse = allResults.find(response => {
          return !Network.isResponseValid(response);
        });
        if(invalidResponse){
          console.error('Failed to get the Schedule Task initial data', invalidResponse);
          messageDialogApi.responseError(dispatch, getState)(invalidResponse);
          dispatch(actions.fetchDialogDataReady());
          return {success: false};
        }

        let dialogData = {
          stations: allResults[0].dataList?.map((obj) => {return {value: obj.id, label: obj.name, data: obj}}),
          tools: toolRequirement ? allResults[1].dataList?.map((obj) => {return {value: obj.id, label: obj.businessId, data: obj}}) : [],
        };

        dispatch(actions.fetchDialogDataReady(dialogData));
      })
        .catch(err => {
          console.error('Failed to get the Schedule Task initial data: catch ', err);
          messageDialogApi.responseError(dispatch, getState)();
          return {success: false};
        })
    }
  },

  submit(dispatch, getState) {
    return function(taskId, data, schedulerEngineInstance) {
      dispatch(actions.setBusy(true));

      schedulerTasksPageApi.assign(dispatch, getState)(taskId, data, null, false).then((response) => {
        dispatch(actions.setBusy(false));

        let validations = dialogHelper.getResponseValidationDetails(response);
        if (validations) {
          dispatch(actions.setValidationWarningsData(validations));
          return response;
        }

        dispatch(actions.hide());

        if (!Network.isResponseValid(response)) {
          console.error('Assign task failed', response);
          messageDialogApi.responseError(dispatch, getState)(response);
          return;
        }

        schedulerTasksPageApi.refreshPageData(dispatch, getState)()
      });
    };
  },

};

export let jsxActions = {
  ...daHelper.getJsxActions(api),

  submit(taskId, data, schedulerEngineInstance) {
    return function(dispatch, getState) {
      return api.submit(dispatch, getState)(taskId, data, schedulerEngineInstance);
    };
  }

};
