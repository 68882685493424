// @ts-check

import { MapView, Mappedin, MappedinNode, getVenue } from "@mappedin/mappedin-js";
import { useEffect, useState } from "react";
import { MapLocationTooltipLogic } from "../Logic/MapLocationTooltipLogicz";
import { MapSearchResultsLogic } from "../Logic/MapSearchResultsLogicz";
import { MapNormalViewLogic } from "../Logic/MapNormalViewLogicz";
import { MapFloorSelectorLogic } from "../Logic/MapFloorSelectorLogicz";


/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LocationTooltipState} LocationTooltipState
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 */


/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
export default function useHandleDataChange(refContext) {

  let sData = refContext.current.refSData.current;
  let mapView = refContext.current.refMapView.current;
  

  useEffect(() => {
    
    if (!mapView) {
      return;
    }
    
    let venue = mapView.venue;
    if (!venue) {
      return;
    }
  
    
    
    MapFloorSelectorLogic.process(refContext);
    MapLocationTooltipLogic.process(refContext);
    // MapNormalViewLogic.process(sData, mapViewRef, refCurrentMapNode, () => { }, "CLOSE_UP");
    MapNormalViewLogic.process(refContext);
    MapSearchResultsLogic.process(refContext);
    


  }, [sData]);


  return [

  ];
}




