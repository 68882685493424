import React from 'react';

import {reduxForm} from 'redux-form';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import AlertIconCell from '../../../Common/CustomGridCells/AlertIconCell/alertIconCell';
import MultiRowsCell from 'infrastructure/js/components/Grid/MulitRowsCell/multiRowsCell';
import {navigationStates} from '../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../../enums/gridsNames';
import MaterialHelper from '../../../../utils/materialHelper';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import LastOperationCell from '../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import * as AlertHelper from '../../../../utils/alertHelper';
import {filterAlignmentTypes} from 'infrastructure/js/enums/filterAlignmentTypes';

require('./alertedRollsGrid.scss');

class AlertedRollsGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'material.businessId', filterName: 'material', getOptions: false},
    {fieldName: 'businessId', filterName: 'businessId' , getOptions: false},
    {fieldName: 'location', filterName: 'assetLocationName', getOptions: true},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ]


  constructor(props) {
    super(props);
    this.labels = createLabelHelper('mat.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.severity.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterSections: getAlertColumnFilterSections([alertStatus.INFO, alertStatus.WARNING,alertStatus.ERROR],
          [alertType.ETL_BOND,alertType.ETL_CURE,alertType.EXPIRATION, alertType.RESERVED, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 150,
        filterName: 'assetAlert',
        columnOptions: {
          sort: 'desc',
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.alerts,
              componentType: AlertIconCell
            };
          }
        },
      },
      {
        fieldName: 'assetStatus',
        title: this.labels.get('columns.alert.type.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.alerts.map(alert => (AlertHelper.getAlertTypeDisplayName(alert.alertType))),
              componentType: LabelWithTooltipCell
            };
          }
        },
      },
      {
        fieldName: 'value',
        title: this.labels.get('columns.value.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: MultiRowsCell,
          valueGetter: (params) => {
            return {
              dataList: params.data.alerts.map(alert =>  (alert.m_value)),
              componentType: LabelWithTooltipCell
            };
          }
        }
      },
      {
        fieldName: 'material.businessId',
        title: this.labels.get('columns.material.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'material',
        fetchConfig: {
          entityType: EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ASSET_ACTIVE, FetchEntitiesFilters.ASSET_ALERTED, FetchEntitiesFilters.ASSET_TYPE_ROLL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            if (!params.data.material) {
              return { text: '', description: ''};
            }
            return MaterialHelper.getMaterialFullLabel(params.data.material.materialName, params.data.material.businessId);
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.rollId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.ROLL_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ALERTED],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              type: navigationStates.ROLL,
              label: params.data.businessId
            };
          }
        }
      },
      {
        fieldName: 'location',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        filterAlignment: filterAlignmentTypes.RIGHT,
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ]
  }

  getRowHeight(params) {
    let rowHeight =  84;
    if (params.data.alerts.length > 1) {
      rowHeight = params.data.alerts.length* 30 + 40;
    }
    return rowHeight;
  }

  render() {
    return (
      <div className="alerted-rolls-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ALERTED_ROLLS}
              columnsConfig=   {this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: this.getRowHeight,
                  filterPersistence: true,
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'AlertedRollsGrid'
  }
)(AlertedRollsGrid);






