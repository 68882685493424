import { gridsNames } from '../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import * as assetService from '../../services/Assets/assetService.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ASSET_PAGE_ETL_LOG, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    let assetData = getState().assetPage.getIn(['data', 'asset']);
    return assetService.getAssetEtlLog(assetData?.get('id'), query);
  };
}

function _convertToRowData(rows, serverTimeSeconds) {
  let convertedRows = rows.map((row) => {
    row.m_Time = DateTimeHelper.FormatDateObjectToRelativeTime(row.time, serverTimeSeconds);
    return row;
  });

  return convertedRows;
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;
