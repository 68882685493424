import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {isMobileApp, isSandboxMode} from 'infrastructure/js/utils/appHelper';
import MarketingLayout from '../LoginLayout/marketingLayout';
import TextField from '../controls/TextField/textField.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Button from '../controls/Button/button.js';
import logoImg from 'images/login-logo.png';

import './loginPage.scss';

export default function LoginPage({ actions, location, history, formValues, sData, handleSubmit, initialize }) {
  const orgIDinLocalStorage = getOrgIDinStorage();
  const [showOrgField, setShowOrgField] = useState(!orgIDinLocalStorage || isMobileApp() || isSandboxMode());

  const autoFocus = getOrgIDinStorage() ? 'autoFocus' : null;

  useEffect(() => {
    actions.fetchPromotions()

    const ssoLogin = location.search.includes('sso');
    if (ssoLogin) {
      actions.loginSSO();
    }

    let initialData = __DEV__
      ? { email: 'pmuser', password: 'aaa123', orgID: orgIDinLocalStorage, rememberMe: false }
      : { orgID: orgIDinLocalStorage };

    initialize(initialData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const secretKeyPressHandler = (e) => {
    e.preventDefault();

    if (e.key === '`') {
      setShowOrgField(true);
    }
  };

  const redirectToSSO = () => {
    actions.redirectToSSO(formValues.orgID);
  };

  const navigateToResetPassword = () => {
    const { orgID, email } = formValues;

    history.push({
      pathname: '/ResetPassword',
      state: {
        orgID,
        email,
      },
    });
  };

  const loginCallBack = (formData) => {
    actions.login(formData);
  };

  return (
    <MarketingLayout className="login-page" promotions={sData?.get('promotions')} slideSpeed={sData?.get('slideSpeed') ?? 5}>
      <div className="logo" onKeyPress={secretKeyPressHandler} tabIndex="-1">
        <img src={logoImg} alt="login logo" />
      </div>

      <form
        className={cn('login-form', { 'has-error': sData.get('hasError'), 'has-sso-error': sData.get('hasSSOError') })}
        onSubmit={handleSubmit(loginCallBack)}
      >
        {showOrgField && (
          <>
            <div className="login-row">
              <label className="textfield-label">{'organization'}</label>
              <TextField name="orgID" id="OrgIDInput" placeholder="Organization" validate={Validation.required} tabIndex="1" autoFocus={autoFocus} />
            </div>
          </>
        )}

        <div className="login-row">
          <label className="textfield-label">{'user name'}</label>
          <TextField name="email" id="UserNameInput" placeholder="Username" validate={Validation.required} tabIndex="2" autoFocus={autoFocus} />
        </div>

        <div className="login-row">
          <label className="textfield-label">{'password'}</label>
          <TextField name="password" id="PasswordInput" placeholder="Password" type="password" validate={Validation.required} tabIndex="2" />
        </div>

        <Button id="login" bsStyle="primary" type="submit" loading={sData.get('loading')} onlyLoading={true} tabIndex="10">
          Login
        </Button>

        <div className="login-error">{sData.get('errorMessage')}</div>

        <div className="footer">
          <div className="links-section">
            <a target="_self" className="pointer" onClick={navigateToResetPassword}>
              Reset Password
            </a>
            <a target="_blank" className="pointer" href="https://plataine.zendesk.com/">
              Support
            </a>
          </div>
          <div className="sso-section">
            <Button
              id="login"
              bsStyle="default"
              className="btn no-icon sso-button"
              onClick={redirectToSSO}
              loading={sData.get('ssoLoading')}
              onlyLoading={true}
              tabIndex="10"
            >
              Sign In Using SSO
            </Button>
            <div className="sso-error">{sData.get('ssoErrorMessage')}</div>
          </div>
        </div>
      </form>
    </MarketingLayout>
  );
}

LoginPage.propTypes = {
  actions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  formValues: PropTypes.object,
  sData: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
};

function getOrgIDinStorage() {

  let sandboxMode =  sessionStorage.getItem('sandboxMode');
  let orgName =  sessionStorage.getItem('orgName');

  if (sandboxMode && orgName) {
    return orgName;
  }

  return localStorage.getItem('orgID');
}
