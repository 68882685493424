import { Map} from 'immutable';
import { actionTypes as actionTypes }  from '../../../actions/Administration/OrganizationsPage/orgsDashboardViewActions';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';

let defaultState = {
  reportDateFrom: null,
  reportDateTo: null,
};

export default function(state = Map(defaultState), action) {

  switch (action.type) {
    case actionTypes.ORGS_DASHBOARD_VIEW_UPDATE_HEADER_DATA:
      return state.set('reportDateFrom', DateTimeHelper.ConvertFromDate(action.payload.reportDateFrom)?.epochDateTime || null)
                  .set('reportDateTo', DateTimeHelper.ConvertFromDate(action.payload.reportDateTo)?.epochDateTime || null);

    default:
      return state;
  }
}
