import PropTypes from 'prop-types';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip';

import './progressBreakdownCell.scss';

export default function ProgressBreakdownCell({ value = { items: [], tooltip: '' } }) {
  const { items, tooltip } = value;

  return (
    <div className="progress-breakdown-cell">
      <Tooltip placement="top" value={tooltip} delayShow={200}>
        <div className="progress-breakdown-bar">
          {items?.map((item, index) => (
            <div
              key={index}
              className="progress-item"
              style={{
                width: `${item.width}%`,
                height: '100%',
                backgroundColor: item.color,
              }}
            />
          ))}
        </div>
      </Tooltip>
    </div>
  );
}

ProgressBreakdownCell.propTypes = {
  value: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
      })
    ),
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
};
