import {List, Map} from 'immutable';
import {dialogsNames} from '../../../enums/dialogsNames';
import defaultDialogReducer from '../../defaultDialogReducer';
import { actionTypes as actionTypes } from '../../../actions/LocationPage/AssetsView/addToShipmentActions.js';

let defaultState = {
  show: false,
  loading: false,
  dialogData: null,
};

export default function(state = Map(defaultState), action) {

  switch(action.type) {

    case actionTypes.ADD_TO_SHIPMENT_DIALOG_SHOW:
      return state.set('preselectedAssets', action.payload && action.payload.preselectedAssets ? List(action.payload.preselectedAssets) : List())
        .set('entitiesTypes', action.payload && action.payload.entitiesTypes ? List(action.payload.entitiesTypes) : '')
        .set('boEntityFilter', action.payload && action.payload.boEntityFilter ? action.payload.boEntityFilter : '')
        .set('preselectedShipment', action.payload && action.payload.preselectedShipment ? List(action.payload.preselectedShipment) : List())
        .set('show', true);

    default:
      return defaultDialogReducer(state, action, dialogsNames.ADD_TO_SHIPMENT_DIALOG, defaultState);
  }
}


