import React from 'react';
import PropTypes from 'prop-types';

import {Field} from 'redux-form';
import {ToggleButton, ToggleButtonGroup} from 'react-bootstrap';

require('./toggleButtonGroup.scss');

export default class PL_ToggleButtonGroupForm extends React.PureComponent {
  render() {
    return (
      <Field {...this.props} component={PL_ToggleButtonGroup}></Field>
    );
  }
};

export class PL_ToggleButtonGroup extends React.PureComponent {

  renderChildren = () => {
    return this.props.children.map(child => {
      return (
        <ToggleButton className="toggle-button" key={child.key} id={child.key} value={child.value}>{child.label}{child.hasError &&
        <span className="pl pl-error-icon"/>}</ToggleButton>
      )
    });
  };

  handleChange = (data) => {
    this.props.input.onChange(data);
  };

  render() {
    return (
      <ToggleButtonGroup className="pl-toggle-button-group" type="radio" name="toggleOptions"
                         defaultValue={this.props.defaultValue} onChange={this.handleChange}>
        {this.renderChildren()}
      </ToggleButtonGroup>
    );
  }
}

PL_ToggleButtonGroup.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};
