import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper.js';
import Network from 'infrastructure/js/modules/network';
import { List } from 'immutable';
import { api as messageDialogApi } from '../../../../../mat/js/actions/MessageDialog/messageDialogActions';

export const actionTypes = {
  GRID_FETCH_DATA_ERROR: 'GRID_FETCH_DATA_ERROR',
  GRID_FETCH_DATA_IN_PROGRESS: 'GRID_FETCH_DATA_IN_PROGRESS',
  GRID_FETCH_DATA_FINISHED: 'GRID_FETCH_DATA_FINISHED',
  GRID_SELECTED_ROWS_CHANGED: 'GRID_SELECTED_ROWS_CHANGED',
  GRID_INIT: 'GRID_INIT',
  GRID_CLEAR_FILTERS: 'GRID_CLEAR_FILTERS',
  GRID_UNMOUNT: 'GRID_UNMOUNT',
  GRID_UPDATE_ROW_DATA: 'GRID_UPDATE_ROW_DATA',
  GRID_SET_FILTER_STATE: 'GRID_SET_FILTER_STATE',
  GRID_SET_BUSY: 'GRID_SET_BUSY',
};

export function getActions(gridName) {
  return {
    fetchDataInProgress: function (gridName, abortController) {
      return {
        type: actionTypes.GRID_FETCH_DATA_IN_PROGRESS,
        payload: { gridName, abortController },
      };
    },
    fetchDataError: function (gridName) {
      return { type: actionTypes.GRID_FETCH_DATA_ERROR, payload: { gridName } };
    },
    fetchDataFinished: function (payload) {
      return { type: actionTypes.GRID_FETCH_DATA_FINISHED, payload: payload };
    },
    selectedRowsChanged: function (payload) {
      return { type: actionTypes.GRID_SELECTED_ROWS_CHANGED, payload: payload };
    },
    init: function (payload) {
      return { type: actionTypes.GRID_INIT, payload: payload };
    },
    clearFilters: function (payload) {
      return { type: actionTypes.GRID_CLEAR_FILTERS, payload: payload };
    },
    unmount: function (payload) {
      return { type: actionTypes.GRID_UNMOUNT, payload: payload };
    },
    updateGridData: function (payload) {
      return {
        type: actionTypes.GRID_UPDATE_ROW_DATA,
        payload: { gridName: payload.gridName, rows: payload.rows },
      };
    },
    setFilters: function (gridName, filterState) {
      return { type: actionTypes.GRID_SET_FILTER_STATE, payload: { gridName, filterState } };
    },
    setBusy: function (payload) {
      return { type: actionTypes.GRID_SET_BUSY, payload };
    },
  };
}
/////////////////////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export function getJsxActions(gridName, actions, api) {
  return {
    fetchData: function (gridName, query, runSideEffect) {
      return function (dispatch, getState) {
        return api.fetchData(gridName, query, runSideEffect)(dispatch, getState);
      };
    },

    onSelectedRowsChanged: function (gridName, selectedRows) {
      return function (dispatch, getState) {
        dispatch(actions.selectedRowsChanged({ gridName, selectedRows }));
      };
    },

    init: function (gridName, columnsConfig, filterConfig, rows, gridNameSuffix='', runSideEffect) {
      return function (dispatch, getState) {

        dispatch(actions.init({gridName, columnsConfig, filterConfig, rows, gridNameSuffix}));

        const storageKey = gridName + (gridNameSuffix ? '_' + gridNameSuffix : '');
        const filterState = GridHelper.getFiltersStateFromStorage(storageKey);
        if (filterState) {
          dispatch(actions.setFilters(gridName, filterState));
        }

        api.reload(dispatch, getState)(runSideEffect);
      };
    },

    unmount: function (gridName) {
      return function (dispatch, getState) {
        api.unmount(gridName)(dispatch, getState);
      };
    },
  };
}

export function getApiActions(gridName, actions, fetchDataAction, convertToRowData) {
  let api = {};

  api.updateGridRowData = function (rows, gridName) {
    return function (dispatch, getState) {
      let cloneRows = List();
      cloneRows = cloneRows.push(...rows);
      dispatch(actions.updateGridData({ rows: cloneRows, gridName: gridName }));
    };
  };

  api.reload = function (dispatch, getState) {
    return (runSideEffect) => {
      if (!getState().grid.get(gridName)) {
        return;
      }
      let { filterConfig, filterState, sortState, rows } = {
        ...getState().grid.get(gridName).toObject(),
      };
      let query = GridHelper.createGetDataQuery(
        {
          filterModel: filterState,
          sortModel: sortState,
        },
        filterConfig,
        100
      );

      if (!fetchDataAction) {
        let payload = {
          gridName,
          filteredRowsCount: rows ? rows.size : 0,
          filterModel: List(),
          sortModel: {},
          rows: convertToRowData ? convertToRowData(rows) : rows,
          filtersData: null,
          queryResultInfo: null,
        };

        dispatch(actions.fetchDataFinished(payload));
        return;
      }
      api.fetchData(gridName, query, runSideEffect)(dispatch, getState);
    };
  };

  api.clearFilters = function (dispatch, getState) {
    return () => {
      let gridState = getState().grid.get(gridName);
      if (!gridState) return;
      let filterState = gridState.get('filterState');
      if (!filterState) return;

      filterState.forEach((filter) => {
        if (filter.values) {
          delete filter.values;
        }
        if (filter.extraData) {
          delete filter.extraData;
        }
      })

      let gridNameSuffix = gridState.get('gridNameSuffix');
      let storageKey = gridName + (gridNameSuffix ? '_' + gridNameSuffix : '');
      GridHelper.removeFiltersStateFromStorage(storageKey);

      dispatch(actions.clearFilters({ gridName, filterState }));

      api.reload(dispatch, getState)(gridName);

    };
  };

  api.fetchData = function (gridName, query, runSideEffect) {
    return function (dispatch, getState) {
      const abortController = getState().grid.getIn([gridName, 'abortController']);
      if (abortController) {
        abortController.abort();
      }
      const newAbortController = new AbortController();
      dispatch(actions.fetchDataInProgress(gridName, newAbortController));

      if (!fetchDataAction) {
        return [];
      }

      let filterState = [...query?.filters] ?? [];
      let copiedFilterState = JSON.parse(JSON.stringify(filterState));
      query?.filters?.forEach(item => delete item.extraData);

      return fetchDataAction(
        query,
        newAbortController,
        runSideEffect
      )(dispatch, getState).then(function (result) {
        if (!Network.isResponseValid(result)) {
          if (result?.name === 'AbortError') {
            return [];
          }
          console.error(gridName + ' Get Rows Data error');
          dispatch(actions.fetchDataError(gridName));
          return [];
        }

        let serverTimeSeconds = getState().system.get('serverDatetime');

        let payload = {
          gridName,
          filteredRowsCount: result.queryResultInfo ? result.queryResultInfo.totalElements : null,
          filterModel: copiedFilterState,
          sortModel: { filterName: query.sortByProperty, direction: query.sortDirection },
          rows: convertToRowData ? convertToRowData(result.dataList, serverTimeSeconds) : result.dataList,
          filtersData: result.queryResultInfo ? result.queryResultInfo.filters : null,
          queryResultInfo: result.queryResultInfo,
        };

        // add timestamp to rows to make sure the grid is refreshed (including filters!) on each data fetch
        payload.rows.map((row) => {
          row.timeStamp = Date.now();
        });
        dispatch(actions.fetchDataFinished(payload));
        return result;
      });
    };
  };

  api.changeFilter = function (dispatch, getState) {
    return function (changedFilter) {
      let gridState = getState().grid.get(gridName);
      if (!gridState) return;
      let filterState = gridState.get('filterState');
      if (!filterState) return;

      let changedFilterIndex = filterState.findIndex((filter) => {
        return filter.filterName === changedFilter.filterName;
      });
      if (changedFilterIndex !== -1) {
        filterState = filterState.update(changedFilterIndex, (filter) => {
          filter.values = changedFilter.values;
          return filter;
        });
      }
      dispatch(actions.setFilters(gridName, filterState));
    };
  };

  api.unmount = function (gridName) {
    return function (dispatch, getState) {
      const abortController = getState().grid.getIn([gridName, 'abortController']);
      if (abortController) {
        abortController.abort();
      }

      dispatch(actions.unmount({ gridName }));
    };
  };

  api.setBusy = function (isBusy) {
    return function (dispatch, getState) {
      dispatch(actions.setBusy({ gridName, isBusy }));
    };
  };

  return api;
}

export function getGridActions(gridName, fetchDataAction, convertRowData) {
  let actions = getActions(gridName);
  let apiActions = getApiActions(gridName, actions, fetchDataAction, convertRowData);
  return {
    actionTypes,
    jsxActions: getJsxActions(gridName, actions, apiActions),
    apiActions,
  };
}
