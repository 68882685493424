import Tabs from 'infrastructure/js/components/Tabs/tabs';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';
import ActiveResinsContainer from '../../../containers/ActiveResinsPage/activeResinsContainer.js';
import AlertedResinsContainer from '../../../containers/AlertedResinsPage/alertedResinsContainer';
import ArchivedResinsContainer from '../../../containers/ArchivedResinsPage/archivedResinsContainer';

import './resinsPage.scss';


const tabsLabels = createLabelHelper('mat.common.tabs.');

const { Tab } = Tabs;

const tabKeys = {
  ACTIVE: 'active',
  ALERTED: 'alerted',
  ARCHIVED: 'archived',
};

const ResinsPage = () => {
  const { getTabKeyFromURL, setTabKeyInURL } = useTabsWithUrl(
    'PredefinedViews/rawMaterials/resins',
    Object.values(tabKeys),
    'active',
    'materialStatus'
  );

  const handleTabClick = (tabKey) => {
    setTabKeyInURL(tabKey);
  };

  return (
    <div className="resins-page">
      <Tabs id="resins-tabs" tabType="content" activeKey={getTabKeyFromURL()} onSelect={handleTabClick} mountOnlyActiveTab={true}>
        <Tab title={tabsLabels.get('active')} eventKey={tabKeys.ACTIVE}>
          <ActiveResinsContainer />
        </Tab>
        <Tab title={tabsLabels.get('alerted')} eventKey={tabKeys.ALERTED}>
          <AlertedResinsContainer />
        </Tab>
        <Tab title={tabsLabels.get('archived')} eventKey={tabKeys.ARCHIVED}>
          <ArchivedResinsContainer />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ResinsPage;
