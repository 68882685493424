import React from 'react';
import PropTypes from 'prop-types';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize        from 'infrastructure/js/components/controls/controlsNormalizations';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import AlertPopover from 'infrastructure/js/components/popover/AlertPopover/alertPopover.js';

require('./antennaListItem.scss');

export default class AntennaListItem extends React.PureComponent {

  renderAlert = (alert) => {
    if (alert) {
      return (<AlertPopover popoverType={'warning'}
                            popoverComponent={<div>{alert.text}</div>}/>);
    }
    return null;
  };

  render() {
    let {options, name , alert} = this.props;

    return (
      <div className="antenna-list-item">

        <TextField  maxLength={30} id={ name + '.[name]'}
                   name={ name + '.[name]'}
                   className="cell"
                   validate={Validation.required}
        />

        <Combobox
          id={ name + '.[location]'}
          name={ name + '.[locationId]'}
          className="cell"
          options={options}
          parse={Parse.comboValueOnly()}
          format={Format.findOptionByValue(options)}
          validate={Validation.dropdown.required}
        />

        <TextField id={ name + '.[port]'}
                   name={ name + '.[port]'}
                   normalize={Normalize.number(true, 1, 99)}
                   className="cell short-cell"
                   validate={Validation.required}
        />

        <TextField id={ name + '.[tx]'}
                   name={ name + '.[tx]'}
                   normalize={Normalize.normalizeFloat(0,99)}
                   className="cell short-cell"
                   validate={Validation.required}
        />

        <TextField id={ name + '.[rx]'}
                   name={ name + '.[rx]'}
                   normalize={Normalize.number(true, -99, 0)}
                   className="cell short-cell"
                   validate={Validation.required}
        />

        {this.renderAlert(alert)}

      </div>
    );
  }
}

AntennaListItem.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};




