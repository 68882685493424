import Network from 'infrastructure/js/modules/network';

export function getExternalNotificationTriggers() {
  return Network.get('notification-triggers');
}

export function fetchExternalNotificationSettings(query = {}) {
  return Network.post('notification-settings/items', query);
}

export function createExternalNotificationSettings(data) {
  return Network.post('notification-settings', data);
}

export function updateExternalNotificationSettings({id, ...rest}) {
  return Network.put(`notification-settings/${id}`, rest);
}

export function removeExternalNotificationSettings(data) {
  return Network.delete('notification-settings', data);
}

export function fetchAllWebHooks() {
  return Network.get('notificationwebhooks/');
}

export function fetchTeamsWebHooks() {
  let data = {
    name: '',
    target: 'MS_TEAMS',
    maxPageSize: 100,
  }
  return Network.post('notificationwebhooks/search/items', data);
}

export function fetchSlackWebHooks() {
  let data = {
    name: '',
    target: 'SLACK',
    maxPageSize: 100,
  }
  return Network.post('notificationwebhooks/search/items', data);
}

export function fetchExternalNotificationLogs(query) {
  return Network.post('external-notification-logs/items', query);
}

export function activateExternalNotifications(ids) {
  return Network.post('notification-settings/activate', {ids});
}
export function deactivateExternalNotifications(ids) {
  return Network.post('notification-settings/deactivate', {ids});
}



