import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import'./button.scss';

export default class PL_Button_New extends React.PureComponent{
  getLoading(){
    if(this.props.loading ){
      return (<div className="pre-button"><i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"/></div>);
    }
    return '';
  }
  getButtonText(){
    if(this.props.loading && this.props.onlyLoading){
      return '';
    }
    return this.props.children;
  }
  getIcon() {
    //Show counter instead of icon image.
    if( Number.isInteger(this.props.counter) && this.props.counter > 0){
      return <span className="counter">{this.props.counter}</span>;
    }

    if(this.props.loading){
      return this.getLoading();
    }

    //Show icon image.
    if(React.isValidElement(this.props.icon)){
      return <div className="pre-button svg-icon">{this.props.icon}</div>
    }

    return (this.props.icon ? <div className="pre-button"><span className={this.props.icon}/></div> : '');
  }

  render() {
    let {loading, onlyLoading, icon, iconAlt, className="", counter, onlyIcon, bsStyle, ...props} = this.props;
    className += (this.props.onlyIcon? " only-icon": "");
    return (
      <Button className={className} variant={bsStyle || 'default'} {...props}>{this.getIcon()}{this.getButtonText()}</Button>
    );
  }
}
PL_Button_New.propTypes = {
  id:PropTypes.string.isRequired,
  loading : PropTypes.bool,
  onlyLoading : PropTypes.bool,
  bsStyle : PropTypes.string,
  //children : PropTypes.string,
  counter : PropTypes.number,
  onlyIcon : PropTypes.bool,
  icon : PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  iconAlt : PropTypes.string,
  className : PropTypes.string
};
