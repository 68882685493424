// @ts-check

import { LiveMapIcons_STATIC as LiveMapAssetsIcons } from '../liveMapAssets';
import { livemapIcons } from '../../../../assets/svg/index';
import { COLLISION_RANKING_TIERS, MARKER_ANCHOR, MapView, Mappedin, MappedinNode, getVenue } from "@mappedin/mappedin-js";
import PermissionManager from '../../../../../infrastructure/js/utils/permissionManager';
import { PolygonInfo } from '../types/PolygonInfo';
import PolyData from '../types/PolyData';
import MapControl from '../MapControl/MapControl';

/**
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").PL_LiveMapPageState} PL_LiveMapPageState
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapMode} MapMode
 * @typedef {import("../../../types/ServerTypes/Models/LiveMap").LivemapLocationDetailsResponseModel} LivemapLocationDetailsResponseModel
 * @typedef {import("../../../actions/LiveMap/types/liveMapPageTypes").MapZoomLevel} MapZoomLevel
 * @typedef {import("@mappedin/mappedin-js/renderer/internal").SmartTooltip} SmartTooltip
 *
 */




export class MapNormalViewLogic {


  /**
   * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
   */
  static process = (refContext) => {
    renderMarkers(refContext);
  }

}




////////////////////////////////////////
// Helper functions.
//

/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
function renderMarkers(refContext) {
  // console.log('renderMarkers ===> ');

  let mapView = refContext.current.refMapView.current;
  if (!mapView) {
    return;
  }

  let venue = mapView.venue;
  if (!venue) {
    return;
  }

  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }

  let zoomLevel = refContext.current.refSData.current.normal.mapZoomLevel;



  //////////////////////////////////////////////////////////
  // Remove all normal markers if not in NORMAL mode.
  //
  let mapMode = refContext.current.refSData.current.mapMode;
  if (mapMode !== 'NORMAL') {
    // console.log('----- REMOVING NORMAL MARKERS ===> ');

    polyData.polygons.forEach((polygonInfo) => {
      removeNormalMarker(polygonInfo, mapView);
      return;
    });

    return;
  }


  MapControl.setInteractivity__MODE_NORMAL(refContext);



  let excludeList = PermissionManager.getOrgPreferences().livemapExcludeList;
  let arrExcludeList = (excludeList) ? excludeList.split(',').map((/** @type {string} */ x) => x.trim()) : [];

  let collideResult = updatePolyDataWithLocationsResponse(refContext);


  ////////////////////////////////////
  // Render markers
  //
  polyData.polygons.forEach((polygonInfo) => {

    let location = polygonInfo.locationData;

    // Remove marker if no location data present.
    if (!location) {
      removeNormalMarker(polygonInfo, mapView);
      return;
    }

    // Remove marker if wrong zoom level.
    if (polygonInfo.normalMarkerId) {
      if (polygonInfo.normalMarkerZoomLevel !== zoomLevel) {
        removeNormalMarker(polygonInfo, mapView);
      }
    }


    // if (zoomLevel === "BIRDS_EYE") {
    //   if (polygonInfo.markerId) {
    //     mapView.Markers.remove(polygonInfo.markerId);
    //     polygonInfo.markerId = null;
    //   }
    //   return;
    // }



    ////////////////////////////////////////////////////////
    // In FULL_MAP mode, we only render top level nodes.
    // We render next level also if it has a direct parent in the exclude list.
    //
    if (zoomLevel === "FULL_MAP") {
      if (location.treeDepth > 1) {

        if (location.treePath && location.treePath.length > 0) {
          let directParent = location.treePath[0];
          if (!arrExcludeList.includes(directParent)) {
            return;
          }
        }

      }
    }


    let node = polygonInfo.entranceNode;
    if (!node) {
      return;
    }


    let currentlySelectedNode = refContext.current.refCurrentMapNode.current;
    if (currentlySelectedNode?.id === node.id) {
      // console.log(' ================== TOOLTIP DETECTED ! ===> ', polygonInfo.markerId);
      removeNormalMarker(polygonInfo, mapView);
      return;
    }


    // console.log('------ Rendering marker ===> ', location.name);


    // Marker already exists, and its not in updated locations list.
    if (polygonInfo.normalMarkerId) {
      let isForUpdate = collideResult?.polysToUpdate.find(x => x.polygonExternalId === polygonInfo.polygonExternalId);
      if (!isForUpdate) {
        return;
      }
    }



    let markerHtml = null;

    if (zoomLevel === 'FULL_MAP') {
      markerHtml = getMarkerHtml_FULL_MAP(location);
      // markerHtml = getMarkerHtml_CLOSE_UP(location);
      // markerHtml = getMarkerHtml_CLOSE_UP__section(location);
    }

    if (zoomLevel === 'CLOSE_UP') {
      if (!(location.section)) {
        markerHtml = getMarkerHtml_CLOSE_UP(location);
      }
      if (location.section) {
        // console.log('SSSSSSSSSECTION!!! ===> ', location.name);
        markerHtml = getMarkerHtml_CLOSE_UP__section(location);
      }
    }


    if (!markerHtml) {
      return;
    }




    // let rank = COLLISION_RANKING_TIERS.ALWAYS_VISIBLE;
    // if (currentlySelectedNode !== null) {
    //   rank = COLLISION_RANKING_TIERS.OPTIONAL_MAXIMUM;
    // }

    // // Sectons higher priority.
    // let rank = COLLISION_RANKING_TIERS.OPTIONAL_MAXIMUM;
    // if (polygonInfo.layerName === 'PL_Sections') {
    //   rank = COLLISION_RANKING_TIERS.HIGH;
    // }

    // Sectons lower priority.
    let rank = COLLISION_RANKING_TIERS.HIGH;
    if (polygonInfo.layerName === 'PL_Sections') {
      rank = COLLISION_RANKING_TIERS.OPTIONAL_MAXIMUM;
    }



    removeNormalMarker(polygonInfo, mapView);
    
    
    // let isInteractiveMarker = true;

    let marker = mapView.Markers.add(node, markerHtml, {
      anchor: MARKER_ANCHOR.RIGHT,
      // rank: COLLISION_RANKING_TIERS.ALWAYS_VISIBLE
      rank: rank,
      // interactive: isInteractiveMarker,
    });
    polygonInfo.normalMarkerId = marker.id;
    polygonInfo.normalMarkerZoomLevel = zoomLevel;


  });




}



/**
 * @param {PolygonInfo} polygonInfo
 * @param {MapView} mapView
 */
function removeNormalMarker(polygonInfo, mapView) {
  if (polygonInfo.normalMarkerId) {
    mapView.Markers.remove(polygonInfo.normalMarkerId);
    polygonInfo.normalMarkerId = null;
    polygonInfo.normalMarkerZoomLevel = null;
    // polygonInfo.normalMarkerHTML = null;
  }
}






/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext
 */
function updatePolyDataWithLocationsResponse(refContext) {

  let sData = refContext.current.refSData.current;
  let sFullStatus = sData.plant.fullStatus;
  if (!sFullStatus) {
    return;
  }

  let allLocationsByName = sData.plant.allLocationsByName;
  if (!allLocationsByName) {
    return;
  }

  let polyData = refContext.current.refPolyData.current;
  if (!polyData) {
    return;
  }



  let allFreshLocations = Array.from(allLocationsByName.values());
  let collideResult = collideWithFreshLocations(polyData, allFreshLocations);

  if (collideResult.polysToUpdate.length > 0) {
    // console.log('collideResult ===> ', collideResult);
  }


  collideResult.polysToUpdate.forEach((polygonInfo) => {
    if (!polygonInfo.polygonExternalId) {
      return;
    }

    let location = allLocationsByName.get(polygonInfo.polygonExternalId);
    if (location) {
      polygonInfo.locationData = location;
    }

  });


  collideResult.polysToDelete.forEach((polygonInfo) => {
    polygonInfo.locationData = null;
  });


  return collideResult;
}


/**
 * @param {PolyData} polyData
 * @param {LivemapLocationDetailsResponseModel[]} allFreshLocations
 */
function collideWithFreshLocations(polyData, allFreshLocations) {

  /** @type {PolygonInfo[]} */
  let polysToDelete = [];

  /** @type {PolygonInfo[]} */
  let polysToUpdate = [];

  // /** @type {LivemapLocationDetailsResponseModel[]} */
  // let locationsToAdd = []; // Not gonna happen.


  let allPolys = polyData.polygons;
  allPolys.forEach((poly) => {

    let freshLocation = allFreshLocations.find(x => x.name === poly.polygonExternalId);
    if (!freshLocation) {
      if (poly.locationData !== null) {
        polysToDelete.push(poly);
      }
      return;
    }

    if (locationDataDiffers(freshLocation, poly.locationData)) {
      polysToUpdate.push(poly);
    }

  });

  // allFreshLocations.forEach((freshLocation) => {
  //   let poly = allPolys.find(x => x.polygonExternalId === freshLocation.name);
  //   if (!poly) {
  //     locationsToAdd.push(freshLocation);
  //   }
  // });

  return {
    polysToDelete,
    polysToUpdate,
    // polysToAdd: locationsToAdd
  };

}


/**
 * @param {LivemapLocationDetailsResponseModel | null} a
 * @param {LivemapLocationDetailsResponseModel | null} b
 * @returns {boolean}
 */
function locationDataDiffers(a, b) {

  if (!a && !b) {
    return false;
  }

  if (!a || !b) {
    return true;
  }

  if (a.id !== b.id) {
    return true;
  }

  if (a.alertsCounters.errors !== b.alertsCounters.errors) {
    return true;
  }
  if (a.alertsCounters.warnings !== b.alertsCounters.warnings) {
    return true;
  }

  if (a.numberOfAsstes !== b.numberOfAsstes) {
    return true;
  }
  if (a.numberOfResources !== b.numberOfResources) {
    return true;
  }
  if (a.numberOfWorkOrders !== b.numberOfWorkOrders) {
    return true;
  }


  return false;

}





/**
 * @param {LivemapLocationDetailsResponseModel} location
 */
function getMarkerHtml_FULL_MAP(location) {

  if (location.alertsCounters.errors === 0 && location.alertsCounters.warnings === 0) {
    return null;
  }


  let renderErrorsHtml = () => {
    if (location.alertsCounters.errors === 0) {
      return '';
    }
    return `
    <div class="marker-errors">
      <div class="marker-errors__icon">${LiveMapAssetsIcons.liveMapMarkerErrorLarge}</div>
      <div class="marker-errors__count">${location.alertsCounters.errors}</div>
      </div>
    `;
  };

  let renderWarningsHtml = () => {
    if (location.alertsCounters.warnings === 0) {
      return '';
    }

    return `
    <div class="marker-warnings">
      <div class="marker-warnings__icon">${LiveMapAssetsIcons.liveMapMarkerWarningLarge}</div>
      <div class="marker-warnings__count">${location.alertsCounters.warnings}</div>
      </div>
    `;
  }

  let renderSpacerHtml = () => {
    if (location.alertsCounters.errors === 0) {
      return '';
    }
    if (location.alertsCounters.warnings === 0) {
      return '';
    }

    return `
    <div class="marker-error-warnings-spacer">
      &nbsp;
    </div>
    `;
  }


  return `
  <div class="marker-full-map">
    <div class="marker-white-dot">
      <img src="${livemapIcons.liveMap_Marker_Dot_white_png}" alt="marker dot" />
    </div>
    ${renderErrorsHtml()}
    ${renderSpacerHtml()}
    ${renderWarningsHtml()}
  </div>
  `;

  // return `
  // <div class="marker-full-map">
  //   <div class="marker-white-dot">
  //     ${LiveMapAssetsIcons.liveMap_Marker_Dot_White}
  //   </div>
  //   ${renderErrorsHtml()}
  //   ${renderSpacerHtml()}
  //   ${renderWarningsHtml()}
  // </div>
  // `;


}


/**
 * @param {LivemapLocationDetailsResponseModel} location
 */
function getMarkerHtml_CLOSE_UP(location) {


  let renderErrorsHtml = () => {
    if (location.alertsCounters.errors === 0) {
      return '';
    }

    return `
    <div class="marker-errors">
      <div class="marker-errors__icon">${LiveMapAssetsIcons.liveMapMarkerErrorSmall}</div>
      <div class="marker-errors__count">${location.alertsCounters.errors}</div>
    </div>
    `;
  };

  let renderWarningsHtml = () => {
    // if (location.alertsCounters.errors > 0) {
    //   return '';
    // }
    if (location.alertsCounters.warnings === 0) {
      return '';
    }

    
    return `
    <div class="marker-warnings">
      <div class="marker-warnings__icon">${LiveMapAssetsIcons.liveMapMarkerWarningSmall}</div>
      <div class="marker-warnings__count">${location.alertsCounters.warnings}</div>
    </div>
    `;
  }
  

  let renderSpacerHtml = () => {
    if (location.alertsCounters.errors === 0) {
      return '';
    }
    if (location.alertsCounters.warnings === 0) {
      return '';
    }

    return `
    <div class="marker-error-warnings-spacer">
      &nbsp;
    </div>
    `;
  }
  

  let renderTitleHtml = () => {
    let freezerClass = location.freezer ? 'freezer' : '';
    return `
    <div class="marker-title ${freezerClass}">
      ${location.name}
    </div>
    `;
  }


  return `
  <div class="marker-close-up-wrap">
    
    <div class="marker-close-up-layout">
    
      <div class="marker-close-up">
        <div class="marker-white-dot">
          <img src="${livemapIcons.liveMap_Marker_Dot_white_png}" alt="marker dot" />
        </div>
        ${renderTitleHtml()}
        ${renderErrorsHtml()}
        ${renderSpacerHtml()}
        ${renderWarningsHtml()}
      </div>
      
    </div>
    
    <div class="marker-close-up-v-spacer">&nbsp;</div>
    
  </div>
  `;


  // return `
  // <div class="marker-close-up">
  //   <div class="marker-white-dot">
  //     ${LiveMapAssetsIcons.liveMap_Marker_Dot_White}
  //   </div>
  //   ${renderTitleHtml()}
  //   ${renderErrorsHtml()}
  //   ${renderSpacerHtml()}
  //   ${renderWarningsHtml()}
  // </div>
  // `;



  // return `
  // <div class="marker-close-up">
  //   <div class="marker-title">
  //     ${location.name}
  //   </div>
  //   <div class="marker-errors">
  //     <div class="marker-errors__icon">${LiveMapIcons.liveMapMarkerErrorSmall}</div>
  //     <div class="marker-errors__count">12</div>
  //   </div>
  //   <div class="marker-warnings">
  //     <div class="marker-warnings__icon">${LiveMapIcons.liveMapMarkerWarningSmall}</div>
  //     <div class="marker-warnings__count">17</div>
  //   </div>
  // </div>
  // `;


}

/**
 * @param {LivemapLocationDetailsResponseModel} location
 */
function getMarkerHtml_CLOSE_UP__section(location) {

  // ${LiveMapAssetsIcons.liveMap_Marker_Dot_Black}
  // ${livemapIcons.liveMap_Marker_Dot_black_png}
  
  // PNG
  return `
  <div class="marker-close-up-section">
    <div class="marker-black-dot">
      <img src="${livemapIcons.liveMap_Marker_Dot_black_png}" alt="marker dot" />
    </div>
    <div class="marker-title">
      ${location.name}
    </div>
  </div>
  `;
  
  
  // SVG
  // return `
  // <div class="marker-close-up-section">
  //   <div class="marker-black-dot">
  //     ${LiveMapAssetsIcons.liveMap_Marker_Dot_Black}
  //   </div>
  //   <div class="marker-title">
  //     ${location.name}
  //   </div>
  // </div>
  // `;


}














