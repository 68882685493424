import { navigationStates } from '../enums/navigationStates';
import { enumTypes, getEnumValue } from './enumHelper';

const navigationStateToPath = {
  [navigationStates.ROLL]: (id) => `/Asset/roll/${id}`,
  [navigationStates.SPOOL]: (id) => `/Asset/spool/${id}`,
  [navigationStates.RESIN]: (id) => `/Asset/resin/${id}`,
  [navigationStates.KIT]: (id) => `/Asset/kit/${id}`,
  [navigationStates.PART]: (id) => `/Asset/part/${id}`,
  [navigationStates.TOOL]: (id) => `/Asset/tool/${id}`,
  [navigationStates.GROUP]: (id) => `/Asset/group/${id}`,
  [navigationStates.WORKORDER]: (id) => `/WorkOrder/${id}`,
  [navigationStates.LOCATION]: (id) => `/Location/${id}`,
  [navigationStates.STATION]: (id) => `/Location/${id}`,
  [navigationStates.LOCATION_TASKS]: (id) => `/Location/${id}/tasks`,
  [navigationStates.PICK_LIST]: (id) => `/pickList/${id}`,
  [navigationStates.SETTINGS_CSV_WORKORDERS]: '/Administration/settings/csv/workorder',
  [navigationStates.ACTIVE_SPOOLS]: '/PredefinedViews/activeSpools',
  [navigationStates.ALERTED_SPOOLS]: '/PredefinedViews/alertedSpools',
  [navigationStates.ARCHIVED_SPOOLS]: '/PredefinedViews/archivedSpools',
  [navigationStates.ACTIVE_PARTS]: '/PredefinedViews/activeParts',
  [navigationStates.ARCHIVED_PARTS]: '/PredefinedViews/archivedParts',
  [navigationStates.ACTIVE_ROLLS]: '/PredefinedViews/activeRolls',
  [navigationStates.ALERTED_ROLLS]: '/PredefinedViews/alertedRolls',
  [navigationStates.ARCHIVED_ROLLS]: '/PredefinedViews/archivedRolls',
  [navigationStates.ACTIVE_KITS]: '/PredefinedViews/activeKits',
  [navigationStates.ALERTED_KITS]: '/PredefinedViews/alertedKits',
  [navigationStates.ARCHIVED_KITS]: '/PredefinedViews/archivedKits',
  [navigationStates.ACTIVE_GROUPS]: '/PredefinedViews/activeGroups',
  [navigationStates.ALERTED_GROUPS]: '/PredefinedViews/alertedGroups',
  [navigationStates.ARCHIVED_GROUPS]: '/PredefinedViews/archivedGroups',
  [navigationStates.OPEN_WORKORDERS]: '/PredefinedViews/openWorkOrders',
  [navigationStates.COMPLETED_WORKORDERS]: '/PredefinedViews/completedWorkOrders',
  [navigationStates.ACTIVE_TOOLS]: '/PredefinedViews/activeTools',
  [navigationStates.ARCHIVED_TOOLS]: '/PredefinedViews/archivedTools',
  [navigationStates.WORK_ORDERS]: '/PredefinedViews/workOrders',
  [navigationStates.GROUPS]: '/PredefinedViews/groups',
  [navigationStates.RAW_MATERIALS]: '/PredefinedViews/rawMaterials',
  [navigationStates.TOOLS]: '/PredefinedViews/tools',
  [navigationStates.KITS]: '/PredefinedViews/kits',
  [navigationStates.PARTS]: '/PredefinedViews/parts',
  [navigationStates.ON_HAND_INVENTORY]: '/PredefinedViews/onHandInventory',
  [navigationStates.MATERIAL_SCRAP]: '/PredefinedViews/materialScrap',
  [navigationStates.PICK_LISTS]: '/PredefinedViews/pickLists',
  [navigationStates.SMART_SELECTION]: '/PredefinedViews/smartSelection',
  [navigationStates.MANAGER_REPORT]: '/ManagerView',
  [navigationStates.PRODUCTION_DASHBOARD]: '/ProductionDashboard',
  [navigationStates.PROGRESS_DASHBOARD]: '/ProgressDashboard',
  [navigationStates.INVENTORY_DASHBOARD]: '/InventoryDashboard',
  [navigationStates.TOOL_MAINTENANCE_DASHBOARD]: '/ToolMaintenanceDashboard',
  [navigationStates.NOTIFICATIONS]: '/Notifications',
  [navigationStates.MAT_SETTINGS]: '/Administration/matSettings',
  [navigationStates.USER_MANAGEMENT]: '/Administration/userManagementPage',
  [navigationStates.USER_SETTINGS]: '/Administration/userSettings',
  [navigationStates.ORG_MANAGEMENT]: '/Administration/organizationsPage',
  [navigationStates.DEMO_ORG_MANAGEMENT]: '/Administration/demoOrganizations',
  [navigationStates.PROMOTIONS_MANAGEMENT]: '/Administration/promotions',
  [navigationStates.RFID_SETTINGS]: '/Administration/rfidSettings',
  [navigationStates.BILLING]: '/Administration/billing',
  [navigationStates.SANDBOX]: '/Administration/sandbox',
  [navigationStates.LIVE_MAP]: (id) => `/LiveMap/${id}`,
  [navigationStates.SETTINGS]: '/Administration/settings',
  [navigationStates.PS_MANAGEMENT]: '/Administration/psManagement',
  [navigationStates.LOGS]: '/Administration/logs',
  [navigationStates.SCHEDULER_GANTT]: '/PredefinedViews/scheduler/gantt',
  [navigationStates.SCHEDULER_TASKS]: '/PredefinedViews/scheduler/tasks',
  [navigationStates.LOTS]: '/PredefinedViews/lots',
};

function getHomePageTypeToNavigationStateMap() {
  return {
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('LOCATION')]: navigationStates.LOCATION,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('STATION')]: navigationStates.STATION,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('BILLING')]: navigationStates.BILLING,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('SANDBOX')]: navigationStates.SANDBOX,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('GROUPS')]: navigationStates.GROUPS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('INVENTORY_DASHBOARD')]: navigationStates.INVENTORY_DASHBOARD,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('KITS')]: navigationStates.KITS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('MANAGER_REPORT')]: navigationStates.MANAGER_REPORT,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('MAT_SETTINGS')]: navigationStates.MAT_SETTINGS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('MATERIAL_SCRAP')]: navigationStates.MATERIAL_SCRAP,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ON_HAND_INVENTORY')]: navigationStates.ON_HAND_INVENTORY,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('PARTS')]: navigationStates.PARTS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('PICK_LIST')]: navigationStates.PICK_LIST,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('PICK_LISTS')]: navigationStates.PICK_LISTS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('PRODUCTION_DASHBOARD')]: navigationStates.PRODUCTION_DASHBOARD,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('PROGRESS_DASHBOARD')]: navigationStates.PROGRESS_DASHBOARD,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('PS_MANAGEMENT')]: navigationStates.PS_MANAGEMENT,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('RAW_MATERIALS')]: navigationStates.RAW_MATERIALS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('RFID_SETTINGS')]: navigationStates.RFID_SETTINGS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('SCHEDULER_GANTT')]: navigationStates.SCHEDULER_GANTT,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('SCHEDULER_TASKS')]: navigationStates.SCHEDULER_TASKS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('SETTINGS')]: navigationStates.SETTINGS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('SMART_SELECTION')]: navigationStates.SMART_SELECTION,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('TOOLS')]: navigationStates.TOOLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('TOOL_MAINTENANCE_DASHBOARD')]: navigationStates.TOOL_MAINTENANCE_DASHBOARD,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('USER_MANAGEMENT')]: navigationStates.USER_MANAGEMENT,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('USER_SETTINGS')]: navigationStates.USER_SETTINGS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('WORK_ORDERS')]: navigationStates.WORK_ORDERS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('OPEN_WORK_ORDERS')]: navigationStates.OPEN_WORK_ORDERS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('COMPLETED_WORK_ORDERS')]: navigationStates.COMPLETED_WORK_ORDERS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ACTIVE_ROLLS')]: navigationStates.ACTIVE_ROLLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ALERTED_ROLLS')]: navigationStates.ALERTED_ROLLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ARCHIVED_ROLLS')]: navigationStates.ARCHIVED_ROLLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ACTIVE_SPOOLS')]: navigationStates.ACTIVE_SPOOLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ALERTED_SPOOLS')]: navigationStates.ALERTED_SPOOLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ARCHIVED_SPOOLS')]: navigationStates.ARCHIVED_SPOOLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ACTIVE_PARTS')]: navigationStates.ACTIVE_PARTS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ARCHIVED_PARTS')]: navigationStates.ARCHIVED_PARTS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ACTIVE_KITS')]: navigationStates.ACTIVE_KITS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ALERTED_KITS')]: navigationStates.ALERTED_KITS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ARCHIVED_KITS')]: navigationStates.ARCHIVED_KITS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ACTIVE_TOOLS')]: navigationStates.ACTIVE_TOOLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ARCHIVED_TOOLS')]: navigationStates.ARCHIVED_TOOLS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ACTIVE_GROUPS')]: navigationStates.ACTIVE_GROUPS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ALERTED_GROUPS')]: navigationStates.ALERTED_GROUPS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('ARCHIVED_GROUPS')]: navigationStates.ARCHIVED_GROUPS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('LOTS')]: navigationStates.LOTS,
    [getEnumValue(enumTypes.HOME_PAGE_TYPE)('LOGS')]: navigationStates.LOGS,
  };
}

export function getPathByType(type, id) {
  const path = navigationStateToPath[type];
  if (!path) {
    console.error('getPathByType(): unknown type ' + type);
    return null;
  }

  const newPath = typeof path === 'function' ? path(id) : path;
  return newPath;
}

export function getNavigationStateByHomePageType(type) {
  const homePageTypeToNvigationState = getHomePageTypeToNavigationStateMap();
  return homePageTypeToNvigationState[type];
}

export function getHomePageTypeByNavigationState(navigationState) {
  const homePageTypeToNvigationState = getHomePageTypeToNavigationStateMap();
  return Object.keys(homePageTypeToNvigationState).find((key) => homePageTypeToNvigationState[key] === navigationState);
}

export function getHomePagePathByType(homePageType, id) {
  const navigationStateType = getNavigationStateByHomePageType(homePageType);

  if (!navigationStateType) {
    console.error('getHomePagePathByType(): unknown type ' + homePageType);
    return null;
  }

  return getPathByType(navigationStateType, id);
}
