import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DropDown from 'infrastructure/js/components/controls/Dropdown/dropdown';
import EntitiesMultiSelectFieldAsync from '../EntitiesMultiSelectFieldAsync/entitiesMultiSelectFieldAsync';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
require('./entitiesMultiSelect.scss');

export default class EntitiesMultiSelect extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.entity.type.');

    this.dropDownName = props.name + '.assetsType';
    this.assetsValueName = props.name + '.assetsValue';

    this.dropdownOptions = this.createDropdownOptions(props.entitiesTypes);
    this.dropDownDisabled = this.dropdownOptions.length === 1;
    //initial selected entities type
    let selectedType = props.selectedEntitiesType ? this.typeToOption(props.selectedEntitiesType) : this.dropdownOptions[0];
    this.state = {selectedEntitiesType: selectedType};
    this.props.change(this.dropDownName, selectedType);
  }

  componentDidMount() {
    this.initData(this.props.preSelectedEntities);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.entitiesTypes !== this.props.entitiesTypes) {
      this.dropdownOptions = this.createDropdownOptions(nextProps.entitiesTypes);
      this.dropDownDisabled = this.dropdownOptions.length === 1 ;

      //workaround to set selected entities type
      let selectedType = this.props.selectedEntitiesType ? this.typeToOption(this.props.selectedEntitiesType) : this.dropdownOptions[0];
      this.setState({selectedEntitiesType: selectedType});
      this.props.change(this.dropDownName, selectedType);
    }
    if (nextProps.preSelectedEntities !== this.props.preSelectedEntities) {
      this.initData(nextProps.preSelectedEntities);
    }
  }

  createDropdownOptions = (types) => {
    let res = types.map( type => {
      return this.typeToOption(type);
    });
    return res;
  };

  typeToOption(type) {
    if (type === getEnumValue(enumTypes.OBJECT_TYPE)('WO')) {
      return {value: type, label: this.labels.get('workorders')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')) {
      return {value: type, label: this.labels.get('rolls')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL')) {
      return {value: type, label: this.labels.get('spools')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')) {
      return {value: type, label: this.labels.get('resins')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('KIT')) {
      return {value: type, label: this.labels.get('kits')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('PART')) {
      return {value: type, label: this.labels.get('parts')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('KIT_TYPE')) {
      return {value: type, label: this.labels.get('kitTypes')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')) {
      return {value: type, label: this.labels.get('groups')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('TOOL')) {
      return {value: type, label: this.labels.get('tools')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('LOCATION')) {
      return {value: type, label: this.labels.get('locations')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('STATION')) {
      return {value: type, label: this.labels.get('stations')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('TAG')) {
      return {value: type, label: this.labels.get('tags')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('TASK')) {
      return {value: type, label: this.labels.get('tasks')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('PICK_LIST')) {
      return {value: type, label: this.labels.get('pickLists')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('REPORTED_CUT')) {
      return {value: type, label: this.labels.get('reportedCuts')}
    }
    else if (type === getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT')) {
      return {value: type, label: this.labels.get('shipments')}
    }
    console.error('Unknown entity type: ' + type);
    return {value: '', label: ''};
  }

  initData =(preSelectedEntities) => {
    let hasPreSelectedEntities = preSelectedEntities && (preSelectedEntities.size > 0);
    this.dropDownDisabled = this.dropdownOptions.length === 1 ||
                            (hasPreSelectedEntities && this.props.disableOnPreselect);

    if (hasPreSelectedEntities) {
      let preSelectedEntitiesType = preSelectedEntities.get(0).objectType;
      let dropdownOption = this.dropdownOptions.find((item) => {return item.value === preSelectedEntitiesType})

      if (dropdownOption ) {
        this.setState({selectedEntitiesType: dropdownOption});
        this.props.change(this.dropDownName, dropdownOption);
      }
      else {
        this.props.change( this.assetsValueName, null );
      }

    }

  };

  onTypeChangeCallback = (newValue) => {
    //prevent clearing the value
    if (!newValue) {
      this.props.change(this.dropDownName, this.state.selectedEntitiesType);
      return;
    }
    let oldValue = this.state.selectedEntitiesType;
    //prevent clearing the EntitiesMultiSelectFieldAsync when there was no type change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    setTimeout(() => { this.setState({selectedEntitiesType: newValue}); }, 0);
    this.props.change( this.assetsValueName, null ); //clean EntitiesMultiSelectFieldAsync

    this.props.onTypeChangeCallback?.(newValue.value, oldValue.value);
  };

  onValueChangeCallback = (value, oldValue) => {
    this.props.onValueChangeCallback?.(value, oldValue);
  };

  onInputChangeHandler = (value) => {
    this.props.onInputChangeCallback?.(value);
    // Please note: When you want to use onInputChange only to listen to the input updates,
    // you still have to return the unchanged value!
    return value;
  };

  render() {

    return (
      <div className={cn('entities-multi-select', {'hide-entities-types': !this.props.showEntitiesTypes})}>
        <DropDown
          id={this.dropDownName}
          name={this.dropDownName}
          className="entity-type-select"
          options={this.dropdownOptions}
          onChangeCallback={this.onTypeChangeCallback}
          isDisabled={this.dropDownDisabled}
        />
        <EntitiesMultiSelectFieldAsync
          { ...this.props }
          id={this.assetsValueName}
          name={this.assetsValueName}
          className={cn('entity-value-select', this.props.className)}
          onChangeCallback={this.onValueChangeCallback}
          entitiesType={this.state.selectedEntitiesType ? this.state.selectedEntitiesType.value : null}
          onInputChange={this.onInputChangeHandler}
        />
      </div>
    )
  }
}

EntitiesMultiSelect.defaultProps = {
  disableOnPreselect : false,
  entitiesTypes: [{value:'', label: ''}],
  showEntitiesTypes: true,
};
EntitiesMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  entitiesTypes: PropTypes.array,
  disableOnPreselect : PropTypes.bool,
  showEntitiesTypes: PropTypes.bool,
};
