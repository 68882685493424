import Network from 'infrastructure/js/modules/network';

export function fetchPromotions(query) {
  return Network.post('promotions/items', query );
}

export function createPromotion(data) {
  return Network.post('promotions', data);
}

export function editPromotion(data) {
  const {id, ...rest} = data;
  return Network.put(`promotions/${id}`, rest);
}

export function activatePromotions(data) {
  return Network.post('promotions/activate', data);
}

export function deactivatePromotions(data) {
  return Network.post('promotions/deactivate', data);
}

export function deletePromotions(data) {
  return Network.delete('promotions', data);
}

export function getPromotionsSettings() {
  return Network.get('promotions-carousel/slide-speed');
}

export function savePromotionsSettings(data) {
  return Network.put('promotions-carousel/slide-speed', data);
}





