import { Map } from 'immutable';
import { LogsMenuItemsIds } from '../../../enums/navigationMenuItemsIds';
import { actionTypes as LogsPageActionTypes } from '../../../actions/Administration/LogsPage/logsPageActions';

let defaultState = {
  selectedMenuItemId: LogsMenuItemsIds.NAV_ELEMENT_SCHEDULER_LOGS,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case LogsPageActionTypes.LOGS_PAGE_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);

    default:
      return state;
  }
}
