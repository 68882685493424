import { gridsNames } from '../../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import useLabels from 'infrastructure/js/hooks/useLabels';
import CheckMarkCell from '../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';

import './promotionsGrid.scss';


const filterConfig = [
  { fieldName: 'businessId', filterName: 'businessId', getOptions: true },
  { fieldName: 'active', filterName: 'active', getOptions: true },
];

export default function PromotionsGrid({ actions }) {
  const labels = useLabels('mat.grid.');

  const columnsConfig = [
    {
      fieldName: 'businessId',
      title: labels.get('columns.name.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'businessId',
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'active',
      title: labels.get('columns.active.title'),
      filterType: filterTypes.NONE,
      filterName: 'active',
      columnOptions: {
        cellComponent: CheckMarkCell,
      }
    },
    {
      fieldName: 'header',
      title: labels.get('columns.header.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        sortable: false,
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'description',
      title: labels.get('columns.description.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        sortable: false,
        cellComponent: LabelWithTooltipCell,
      },
    },

  ];

  return (
    <div className="promotions-grid">
      <Grid gridName={gridsNames.ADMINISTRATION_PROMOTIONS} columnsConfig={columnsConfig} filterConfig={filterConfig} actions={actions} />
    </div>
  );
}
