import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import RelatedAssetsCell from '../../../../Common/CustomGridCells/RelatedAssetsCell/relatedAssetsCell.js';
import ValueWithStateCell from '../../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell.js';
import LastOperationCell from '../../../../Common/CustomGridCells/LastOperationCell/lastOperationCell';
import {navigationStates} from '../../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {getAssetEtl} from '../../../../../utils/assetHelper';
import EntityIdCell from '../../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../../utils/alertFilterSectionHelper';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import {enumTypes, getEnumLabel, getEnumValue} from '../../../../../utils/enumHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';

import './kitsGrid.scss';

class KitsGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'status', filterName: 'kitStatus'},
    {fieldName: 'kitType.businessId', filterName: 'kitType', getOptions: true},
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'partType.businessId', filterName: 'kitPart', getOptions: true},
    {fieldName: 'workOrder.businessId', filterName: 'kitWorkOrder', getOptions: true},
    {fieldName: 'project.businessId', filterName: 'kitProject', getOptions: true},
    {fieldName: 'lastOperation', filterName: 'assetLastOperationDate', getOptions: true},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let customColumn = this.getColumnByLocationType();

    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.DUE_DATE, alertType.MISPLACED, alertType.DEFROSTING]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '', alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              dueDateLeft: params.data.dueDateLeft,
              dueDateMinutesLeft: params.data.dueDateMinutesLeft
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'kitType.businessId',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.kitType?.businessId || ''
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.kitId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.KIT_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.KIT
            };
          }
        }
      },
      {...customColumn},
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.partType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitPart',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitWorkOrder',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.workOrder) {
              return {
                id: params.data.workOrder.id,
                label: params.data.workOrder.businessId,
                type: navigationStates.WORKORDER
              };
            }
            return {};
          }
        }
      },
      {
        fieldName: 'project.businessId',
        title: this.labels.get('columns.project.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'kitProject',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'lastOperation',
        title: this.labels.get('columns.lastOperation.title'),
        filterType: filterTypes.NONE,
        width: 205,
        columnOptions: {
          cellComponent: LastOperationCell,
          headerComponentParams: { headerTextStyle: { marginLeft: '0px' } },
        },
      },
    ]
  }

  getColumnByLocationType = () => {

    let {locationDetails} = this.props;

    if (locationDetails) {
      if (locationDetails.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')) {
        return {
          fieldName: 'assetStatus',
          title: this.labels.get('columns.status.title'),
          filterType: filterTypes.NONE,
          width: 100,
          columnOptions: {
            sortable: false,
            valueGetter: (params) => {
              return params.data.assetStatus ? getEnumLabel(enumTypes.ASSET_STATUS)(params.data.assetStatus)  : '';
            },
          }
        }
      }
    }

    return ( {
      fieldName: 'relatedAssetData',
      title: '',
      filterType: filterTypes.NONE,
      width: 70,
      columnOptions: {
        sortable: false,
        resizable: false,
        headerValueGetter: GridHelper.headerValueGetter,
        cellComponent: RelatedAssetsCell,
        valueGetter: (params) => {
          return {
            count: params.data.relatedAssetsCounter,
            cellAction: this.props.cellAction,
            assetId: params.data.id,
            componentAssetsTitle: this.labels.get('columns.componentAssets.cell.title'),
            resultingAssetsTitle: this.labels.get('columns.resultingAssets.cell.title')
          };
        },
        headerComponentParams : {
          headerIcon: 'component-assets',
          headerTooltip: this.labels.get('columns.componentAssets.cell.title'),
        }
      }
    } );
  }

  render() {
    return (
      <div className="kits-grid">
        <Grid gridName={gridsNames.LOCATION_ASSET_KITS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  getRowHeight: () => 84
                }
              }
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'AssetsView_KitsGrid'
  }
)(KitsGrid);

KitsGrid.propTypes = {
  actions: PropTypes.object.isRequired,
  locationDetails: PropTypes.object.isRequired,
  cellAction: PropTypes.func.isRequired,
};
