import PropTypes from 'prop-types';

import './withPortal.scss';

const customStyles = {
  menuList: (provided, state) => ({
    ...provided,
    padding: '4px 5px',
    maxHeight: 130,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#efefef' : '' ,
    color: '#707070',
    padding: '5px 10px',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 50000 }) ,
  menu: (base) => ({ ...base, margin:0 })
};

const withPortal = (WrappedComponent) => {
  const WithPortal = (props) => {

    return (
      <WrappedComponent className="with-portal"
                        menuPlacement={'auto'}
                        // closeMenuOnScroll={true}
                        styles={{...customStyles}}
                        menuPortalTarget={document.body}
                        maxMenuHeight={150}
                        menuShouldBlockScroll={true}
                        {...props}
      />
    )
  }

  WithPortal.propTypes = {
    id: PropTypes.string.isRequired,
  };
  return WithPortal;
}

export default withPortal;
