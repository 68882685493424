import {enumTypes, getEnumValue} from './enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

export function isOutgoingOrIncomingLocation(location) {
  return ( location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS') ||
           location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS')
         );
}

export function isShippingPreparation(location) {
  return ( location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('SHIPPING_PREPARATION'));
}

export function isOutgoingShipments(location) {
  return ( location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('OUTGOING_SHIPMENTS'));
}

export function isIncomingShipments(location) {
  return ( location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('INCOMING_SHIPMENTS'));
}

export function isTransitArea(location) {
  return ( location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('TRANSIT_AREA'));
}

export function isAFPStation(location) {
  return ( getEnumValue(enumTypes.LOCATION_TYPE)('AFP') === location?.locationType );
}

export function isFreezerLocation(location) {
  return ( location?.locationType === getEnumValue(enumTypes.LOCATION_TYPE)('FREEZER') );
}

export function isCuringStation(location) {
  return ( getEnumValue(enumTypes.LOCATION_TYPE)('CURING') === location?.locationType );
}


export function getLocationAssetTypes(location, firstAssetType) {
  let types = [];

  if (!isTransitArea(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
  }
  if (!isOutgoingOrIncomingLocation(location) && !isShippingPreparation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));
  }

  if (PermissionManager.isResinsEnabled() &&
      !isTransitArea(location) && !isAFPStation(location) &&
      !isOutgoingOrIncomingLocation(location) && !isShippingPreparation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'));
  }

  if (!isTransitArea(location) && !isAFPStation(location) &&
      !isOutgoingOrIncomingLocation(location) && !isShippingPreparation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('KIT'));
  }
  if (isAFPStation(location) ) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('PART'));
  }
  if (PermissionManager.hasGroupAssetPermissions() &&
      PermissionManager.getOrgPreferences().groupsEnabled &&
      !isOutgoingOrIncomingLocation(location) && !isShippingPreparation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
  }
  if (!isTransitArea(location) && !isOutgoingOrIncomingLocation(location) && !isShippingPreparation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'));
  }

  if (firstAssetType) {
    let index = types.indexOf(firstAssetType);
    if (index < 0) {
      console.error('getLocationAssetTypes(): invalid firstAssetType: ' + firstAssetType);
      return types;
    }

    let firstType = types.splice(index, 1);
    return [...firstType, ...types];
  }
  return types;
}

export function getLocationAssetTypesForPrintPdfLabels(location, firstAssetType) {
  let types = [];

  if (!isTransitArea(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'));
  }
  if (!isOutgoingOrIncomingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'));
  }

  if (PermissionManager.isResinsEnabled() &&
      !isTransitArea(location) && !isAFPStation(location) &&
      !isShippingPreparation(location) &&
      !isOutgoingOrIncomingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'));
  }

  if (!isTransitArea(location) && !isAFPStation(location) && !isOutgoingOrIncomingLocation(location) ) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('KIT'));
  }
  if (PermissionManager.hasGroupAssetPermissions() &&
    PermissionManager.getOrgPreferences().groupsEnabled &&
    !isOutgoingOrIncomingLocation(location)) {
    types.push(getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'));
  }

  if (firstAssetType) {
    let index = types.indexOf(firstAssetType);
    if (index < 0) {
      console.error('getLocationAssetTypesForPrintPdfLabels(): invalid firstAssetType: ' + firstAssetType);
      return types;
    }

    let firstType = types.splice(index, 1);
    return [...firstType, ...types];
  }
  return types;
}

export function openLocationInNewTab(locationId) {
  let url = window.location.origin + window.location.pathname + '#/Location/' + locationId;
  window.open(url, '_blank');
}
