import React, {Component} from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {enumTypes, getEnumValue} from '../../../../../utils/enumHelper';
import EntitiesMultiSelect from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import Label from 'infrastructure/js/components/Label/label';
import MultiEntitiesMultiSelect from '../../../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect';
import {getAssetExtraData} from '../../../../../utils/assetHelper';

import'./addToGroupDialog.scss';

class AddToGroupDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.addtogroupdialog.');
    this.loggedInUser = PermissionManager.getLoggedInUser();

    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.groupsFetchConfig = {
      entityType: EntityPropertyTypes.GROUP_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      action: this.props.actions.fetchEntities,
    };
    this.assetsFetchConfig = {
      // entityType: EntityPropertyTypes.KIT_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.NOT_CONTAINED, FetchEntitiesFilters.NOT_RELATED, FetchEntitiesFilters.NO_ATTACHED_ASSET],
      action: this.props.actions.fetchEntities,
    };
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
         text: this.dialogLabels.get('cancel'),

      action: this.props.actions.hide
      }],
      right: [
        {
          id:'submit',
          text: this.labels.get('footer.add'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  // getContainerId = (formData) => {
  //   let allAssets = formData['to-asset-selection'];
  //   if (allAssets && allAssets.assetsValue ) {
  //     return allAssets.assetsValue.value;
  //   }
  // }

  getComponentAssetsIds = (data) => {
    // let selectedAssets = data['add-asset-selection'] && data['add-asset-selection'].assetsValue ? data['add-asset-selection'].assetsValue : null;
    //
    // return selectedAssets ? selectedAssets.map((asset) => {return asset.value}) : [];
    let allRows = data['add-asset-selection'];
    let assetsIds = [];
    allRows.map((row) => {
      if (row && row.assetsValue) {
        row.assetsValue.map((asset) => {
          assetsIds.push(asset.value);
        });
      }
    });
    return assetsIds;
  };

  getGroup = (data) => {
    let group = (data['to-asset-selection'] && data['to-asset-selection'].assetsValue) ? data['to-asset-selection'].assetsValue : null ;
    return (group ? { id: (group.value === group.label ? null : group.value), businessId: group.label} : null);
  }

  onSubmit = (data) => {
    let group = (data['to-asset-selection'] && data['to-asset-selection'].assetsValue) ? data['to-asset-selection'].assetsValue : null ;

    let submitData = {
      assetIds : this.getComponentAssetsIds(data),
      // group:   group ? { id: (group.value === group.label ? null : group.value), businessId: group.label} : null,
      group:  this.getGroup(data),
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };
    this.props.actions.submit(submitData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog, callback) => {

      let title = '';
      let buttons = [{id:'close',text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'}];
      if (callback) {
        buttons.unshift({id:'printRfidTag', text: this.labels.get('footer.printRfidTag'), action: callback},)
      }
      let children = [];

      if (data && data.assignedAssets) {
        title = this.labels.get('confirmation.message.title', '', { count: data.assignedAssets.length});
        children = (<div  key="operation-confirmation">
          <div className="message-item">
            <div className="message-item-line">
              <MessageDialog.DataRow label={this.labels.get('confirmation.message.groupId')}
                                  value={data.groupBusinessId}/>
            </div>
            <div className="message-item-line">
              <MessageDialog.DataRow label={this.labels.get('confirmation.message.assets')}
                                     value={this.getConfirmationAssetsRows(data.assignedAssets)}/>
            </div>
          </div>
        </div>);
      }

      let className = 'add-to-cont-confirmation-dialog';
      return {title, children, buttons, className};
    };
  };

  getConfirmationAssetsRows = (assets) => {
    if (assets) {
      return (<div>
        {assets.map((asset, index) => {
          let extraData = getAssetExtraData(asset);
          let assetType = asset ? this.labels.get(`confirmation.message.entity.${asset.objectType.toLowerCase()}`) : '';
          return (
            <div className="related-asset-data" key={'related-asset-' + index}>
            <span className="message-item-value">
              <Label className="related-asset-type" text={assetType}/>
              <Label className="related-asset-id" text={asset.businessId}/>
              <Label className="related-asset-metadata" text={(extraData ? '(' + extraData + ')' : '')}/>
            </span>
            </div>
          )
        })}
      </div>);
    }
    return '';
  }

  onHide =() => {
    this.props.actions.hide();
  }

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {

    let preselectedAssets = this.props.sData.get('preselectedAssets');
    let preselectedGroup = this.props.sData.get('preselectedGroup');

    return (
      <Dialog
        id="add-to-group-dialog"
        className="add-to-group-dialog"
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <div className="add-to-asset-container">
          <div className="add-to-asset-section">
            <label className="add-to-asset-label">{this.labels.get('add')}</label>

            {/*<EntitiesMultiSelect*/}
            {/*  id="add-asset-selection"*/}
            {/*  name="add-asset-selection"*/}
            {/*  className="add-to-asset-selection"*/}
            {/*  entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('KIT')]}*/}
            {/*  fetchConfig={this.assetsFetchConfig}*/}
            {/*  preSelectedEntities={preselectedAssets}*/}
            {/*  validate={Validation.required}*/}
            {/*  dropdownMenuClassName='multi-select-field-workorder-dialog'*/}
            {/*  change={this.props.change}*/}
            {/*/>*/}

            <MultiEntitiesMultiSelect
              id="add-asset-selection"
              name='add-asset-selection'
              className="add-to-asset-selection"
              entitiesTypes={this.entitiesTypes}
              validate={Validation.required}
              preSelectedEntities={preselectedAssets}
              fetchConfig={this.assetsFetchConfig}
              autoFocus={true}
              {...this.props}
            />
          </div>

          <label className="add-to-asset-arrow">
            <i className="pl pl-move-arrow-right"/>
          </label>

          <div className="add-to-asset-section">
            <label className="add-to-asset-label">{this.labels.get('to')}</label>
            <EntitiesMultiSelect
              id="to-asset-selection"
              name="to-asset-selection"
              className="assets-to-asset-selection"
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('GROUP')]}
              fetchConfig={this.groupsFetchConfig}
              validate={Validation.required}
              preSelectedEntities={preselectedGroup}
              isMulti={false}
              change={this.props.change}
            />

          </div>
        </div>
      </Dialog>
    );
  }

}

export default reduxForm({
    form: 'addToGroupDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(AddToGroupDialog);
