// @ts-check

import { Mappedin, getVenue } from "@mappedin/mappedin-js";
import { useEffect, useState } from "react";
import { createMapCalculations } from "../../mapCalculations";


/**
 * @typedef {import("@mappedin/mappedin-js").TGetVenueOptions} TGetVenueOptions
 * 
 * @typedef {import("./../../../../actions/LiveMap/types/liveMapPageTypes").Pl_Map} Pl_Map
 * 
 */


// /**
//  * @param {TGetVenueOptions | null} options
//  */
/**
 * @param {any | null} sActiveMapSetting
 */
export default function useVenue(sActiveMapSetting) {

  
  /** 
   * @type {[Mappedin | undefined, Function]}
   */
  const [venue, setVenue] = useState();


  useEffect(() => {

    let ignore = false;

    async function fetchData() {
      
      let options = convertToMapOptions(sActiveMapSetting);
      if (!options) {
        return;
      }

      
      try {
        // console.log('+++++++++ Get Venue options ===> ', options);
        const data = await getVenue(options);
        
        /////////////////////////////////////////////////////////////
        // This adds map calculations metadata to the maps objects
        // to be used elsewhere in the app.
        //
        enrichMapsWithCustomMetadata(data);
        
        if (!ignore) {
          setVenue(data);
        }
      } catch (e) {
        setVenue(undefined);
      }

    }

    fetchData();

    return () => {
      ignore = true;
    };

  }, [sActiveMapSetting]);


  return venue;
}






////////////////////////////////////////////////
// Helpers:



// /**
//  * @param {any} sActiveMapSetting
//  * @param {TGetVenueOptions | null} mapOptions
//  * @param {any} setMapOptions
//  */
// function processServerMapSettings(sActiveMapSetting, mapOptions, setMapOptions) {

//   let mapOptionsFromServer = convertToMapOptions(sActiveMapSetting);
//   let currentSetting = mapOptions;

//   if (settingsAreEqual(mapOptionsFromServer, currentSetting)) {
//     return;
//   }

//   setMapOptions(mapOptionsFromServer);
// }




/**
 * @param {any} sMapSettingItem
 * 
 * @returns {TGetVenueOptions | null}
 */
function convertToMapOptions(sMapSettingItem) {

  if (!sMapSettingItem) {
    return null;
  }

  let s = {
    clientId: sMapSettingItem.clientId,
    clientSecret: sMapSettingItem.clientSecret,
    venue: sMapSettingItem.venue,
  };

  /** @type {TGetVenueOptions} */
  return s;
}



// /**
//  * @param {TGetVenueOptions | null} a
//  * @param {TGetVenueOptions | null} b
//  */
// function settingsAreEqual(a, b) {

//   if (a === b) {
//     return true;
//   }

//   if (!a) {
//     return false;
//   }

//   if (!b) {
//     return false;
//   }


//   if (a.clientId !== b.clientId) {
//     return false;
//   }

//   if (a.clientSecret !== b.clientSecret) {
//     return false;
//   }

//   if (a.venue !== b.venue) {
//     return false;
//   }


//   return true;
// }


/**
 * @param {Mappedin | undefined} venue
 */
function enrichMapsWithCustomMetadata(venue) {
  
  // console.log('enrichMapsWithCustomMetadata ===> ');

  if (!venue) {
    return;
  }


  let maps = venue.maps;
  maps.forEach((map) => {

    /** @type {Pl_Map} */
    let m = map;

    let calculations = createMapCalculations(m);
    if (!calculations) {
      return;
    }

    if (!m.pl_Metadata) {
      m.pl_Metadata = {};
    }
    m.pl_Metadata.mapCalculations = calculations;

  });

}




