import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDirty } from 'redux-form';
import { SettingsMenuItemsIds} from '../../../../enums/navigationMenuItemsIds';
import { jsxActions as commonCsvSettingsViewActions} from '../../../../actions/Administration/SettingsPage/commonCsvSettingsViewActions';
import { jsxActions as settingsPageActions} from '../../../../actions/Administration/SettingsPage/settingsPageActions';
import { jsxActions as exportActions} from '../../../../actions/Export/exportActions';
import {enumTypes, getEnumValue} from '../../../../utils/enumHelper';
import CommonCsvSettingsView from '../../../../components/Administration/SettingsPage/CsvSettings/Components/CommonCsvSettingsView/commonCsvSettingsView';

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(commonCsvSettingsViewActions, dispatch),
      settingsPageActions : bindActionCreators(settingsPageActions, dispatch),
      exportActions: bindActionCreators(exportActions, dispatch),
    }
  }
}

let MaterialTypeCsvSettingsViewContainer = connect(
  (state) => {
    return {
      sData: state.administration.getIn(['settingsPage', 'commonCsvSettingsView']),
      isDirty: isDirty('cssSettingsImportForm')(state) || isDirty('cssSettingsExportForm')(state),
      entityType: getEnumValue(enumTypes.OBJECT_TYPE)('MATERIAL_TYPE'),
      menuItemId:SettingsMenuItemsIds.NAV_ELEMENT_CSV_MATERIAL_TYPE,
    };
  },

  mapDispatchToProps
)(CommonCsvSettingsView);

export default MaterialTypeCsvSettingsViewContainer;



