import React from 'react';
import PropTypes from 'prop-types';

import EntitiesMultiSelectHidden from '../../../../../Common/Controls/EntitiesMultiSelectHidden/entitiesMultiSelectHidden';
import {getEnumValue, enumTypes} from '../../../../../../utils/enumHelper';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import UnitHelper, { unitTypes } from '../../../../../../../../infrastructure/js/utils/uomHelper';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

require('./mergeOperationListItem.scss');

const EntitiesMultiSelectHiddenPortal = withPortal(EntitiesMultiSelectHidden);

export default class MergeOperationListItem extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      assetQuantityLeft: 0,
      currentValue: ''
    };
  }


  onSelectedAssetChanged = (newValue, oldValue) => {
    //prevent clearing the 'length' field when there was no change
    if (newValue && oldValue && newValue.value === oldValue.value) {
      return;
    }
    let assetQuantityLeft = (newValue && newValue.data && newValue.data.lengthLeft) ? newValue.data.lengthLeft : 0;
    if (PermissionManager.isWeightSupported()) {
      assetQuantityLeft = (newValue && newValue.data && newValue.data.weight) ? newValue.data.weight : 0;
    }
    this.setState({
      assetQuantityLeft: assetQuantityLeft,
      currentValue: newValue.label
    });
    if (PermissionManager.isWeightSupported()) {
      this.props.change(this.props.name + '.[weight]', '');
    } else {
      this.props.change(this.props.name + '.[cutLength]', '');
    }
  };

  renderLengthOrWeight = (name) => {
    let maxLength = this.state.assetQuantityLeft ? UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, this.state.assetQuantityLeft, 2) : 0;
    if (PermissionManager.isWeightSupported()) {
      return (<TextField id={name + '.[weight]'}
                         name={name + '.[weight]'}
                         className="short-textfield"
                         normalize={Normalize.number(false, 0, maxLength)}
      />)
    }
    maxLength = maxLength ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH,maxLength, 2) : 0;
    return (<TextField id={name + '.[cutLength]'}
                       name={name + '.[cutLength]'}
                       className="short-textfield"
                       normalize={Normalize.number(false, 0, maxLength)}
    />)
  };

  render() {
    let {name, index, fetchConfig, ...otherProps} = this.props;
    return (
      <div className="merge-operation-list-item">
        <EntitiesMultiSelectHiddenPortal
          key={name + '.[asset-id]'}
          id={name + '.[asset-id]'}
          name={name + '.assetId'}
          {...otherProps}
          className="assets-to-asset-selection"
          entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('ROLL')]}
          fetchConfig={fetchConfig}
          onValueChangeCallback={this.onSelectedAssetChanged}
          dropdownMenuClassName='multi-select-field-workorder-dialog'
          isMulti={false}
          editable={true}
        />
        {this.renderLengthOrWeight(name)}
      </div>
    );
  }
}


MergeOperationListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};




