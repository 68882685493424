import { useState } from 'react';
import { reduxForm } from 'redux-form';
import { useSessionStorage } from 'infrastructure/js/hooks/useSessionStorage';
import moment from 'moment';
import useLabels from 'infrastructure/js/hooks/useLabels';
import { gridsNames } from '../../../../../enums/gridsNames';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import MaterialHelper from '../../../../../utils/materialHelper';
import { scheduleAlertStatus, scheduleAlertType, getAlertColumnFilterSections } from '../../../../../utils/alertFilterSectionHelper';
import { getQuantityInfo } from '../../../../Common/Helpers/SchedulerHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import SchedulerOperationDetail from './schedulerOperationDetail';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { EntityPropertyTypes } from '../../../../../enums/entityPropertyTypes';
import { FetchEntitiesFilters } from '../../../../../enums/fetchEntitiesFilters';
import { filterAlignmentTypes } from 'infrastructure/js/enums/filterAlignmentTypes';
import IconCell from '../../../../Common/CustomGridCells/IconCell/iconCell';
import LabelWithTooltipCell from '../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import TaskAlertPopoverCell from '../../../../Common/CustomGridCells/TaskAlertPopoverCell/taskAlertPopoverCell';
import TaskStatusCell from '../../../../Common/CustomGridCells/TaskStatusCell/taskStatusCell';
import TasksProgressCell from '../../../../Common/CustomGridCells/TasksProgressCell/tasksProgressCell';
import IconWithTooltipCell from '../../../../Common/CustomGridCells/IconWithTooltipCell/iconWithTooltipCell';
import LabelWithStyleCell from '../../../../Common/CustomGridCells/LabelWithStyleCell/labelWithStyleCell';
import regularPriorityIcon from 'infrastructure/assets/svg/regular-priority.svg';
import highPriorityIcon from 'infrastructure/assets/svg/high-priority-task-icon.svg';

import './schedulerOperationsGrid.scss';

const priorityIconsMap = {
  REGULAR: regularPriorityIcon,
  HIGH: highPriorityIcon,
};

const filterConfig = [
  { fieldName: 'valid', filterName: 'scheduleAlert' },
  { fieldName: 'operationStatus', filterName: 'operationStatus', getOptions: true },
  { fieldName: 'plannedStartTime.epochDateTime', filterName: 'plannedStartTime', getOptions: false },
  { fieldName: 'workOrder', filterName: 'operationWorkOrder', getOptions: false },
  { fieldName: 'project.name', filterName: 'operationProject', getOptions: true },
  { fieldName: 'operation.name', filterName: 'operationType', getOptions: true },
  { fieldName: 'dueDate.epochDateTime', filterName: 'operationDueDate', getOptions: true },
  { fieldName: 'm_priority', filterName: 'priority', getOptions: true },
  { fieldName: 'kitType.businessId', filterName: 'kitTypeBusinessId', getOptions: false },
  { fieldName: 'material.businessId', filterName: 'operationMaterial', getOptions: true },
  { fieldName: 'quantity', filterName: 'quantity' },
  { fieldName: 'workOrderDescription', filterName: 'operationWorkOrderDescription' },
  { fieldName: 'resource', filterName: 'operationResource', getOptions: false },
  { fieldName: 'locked', filterName: 'operationLocked', getOptions: true },
  { fieldName: 'productionLine', filterName: 'productionLine', getOptions: true },
];

function SchedulerOperationsGrid({ actions, sSchedulerData, highlightedTaskId, onGridReadyCallback }) {
  const labels = useLabels('mat.scheduler.operations.grid.');
  const filterLabels = useLabels('mat.filter.');
  const [expandedItemsInSession, setExpandedSession] = useSessionStorage('schedulerOperationsGridExpandedItems', []);

  const [columnsConfig] = useState([
    {
      fieldName: 'locked',
      title: '',
      filterType: filterTypes.NONE,
      width: 60,
      columnOptions: {
        resizable: false,
        headerValueGetter: GridHelper.headerValueGetter,
        cellComponent: IconCell,
        aggFunc: lockedAggFunc,
        valueGetter: (params) => {
          return { iconClass: 'pl-lock', showIcon: !!params?.data?.locked };
        },
        headerComponentParams: {
          headerIcon: 'lock',
          headerTooltip: labels.get('columns.locked.tooltip'),
        },
      },
    },
    {
      fieldName: 'split',
      title: '',
      filterType: filterTypes.NONE,
      width: 60,
      columnOptions: {
        sortable: false,
        headerValueGetter: GridHelper.headerValueGetter,
        cellComponent: IconCell,
        aggFunc: splitAggFunc,
        valueGetter: (params) => {
          return { iconClass: 'pl-split-task-icon', showIcon: !!params?.data?.split };
        },
        headerComponentParams: {
          headerIcon: 'split-task-icon',
          headerTooltip: labels.get('columns.split.tooltip'),
        },
      },
    },
    {
      fieldName: 'workOrder',
      title: 'Work Order / Task',
      filterType: filterTypes.MULTI_SELECT_ASYNC,
      filterName: 'operationWorkOrder',
      fetchConfig: {
        entityType: EntityPropertyTypes.SCHEDULER_TASK_WO_BUSINESS_ID,
        filter: [FetchEntitiesFilters.ALL_TASKS],
        action: actions.fetchEntities,
        maxPageSize: 200,
      },
      columnOptions: {
        suppressMovable: true,
        showRowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        minWidth: 220,
        cellClassRules: {
          'child-indent': (params) => {
            return !params.node.group;
          },
        },
        valueGetter: (params) => {
          return params?.data?.name;
        },
      },
    },
    {
      fieldName: 'kitType.businessId',
      title: labels.get('columns.kitType.title'),
      filterType: filterTypes.MULTI_SELECT_ASYNC,
      filterName: 'kitTypeBusinessId',
      width: 150,
      fetchConfig: {
        entityType: EntityPropertyTypes.SCHEDULER_TASK_KIT_TYPE_BUSINESS_ID,
        filter: [FetchEntitiesFilters.ALL],
        action: actions.fetchEntities,
      },
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: ({ node, data }) => {
          if (node.group) {
            return node.allLeafChildren[0]?.data?.kitType?.businessId;
          }
          return data?.kitType?.businessId;
        },
      },
    },
    {
      fieldName: 'project.name',
      title: labels.get('columns.project.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'operationProject',
      width: 150,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: ({ node }) => {
          if (node.group) {
            return node.allLeafChildren[0]?.data?.project?.name;
          }
          return '';
        },
      },
    },
    {
      fieldName: 'material.businessId',
      title: labels.get('columns.material.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'operationMaterial',
      width: 120,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: (params) => {
          return MaterialHelper.getMaterialFullLabel(params?.data?.material?.name, params?.data?.material?.businessId);
        },
      },
    },
    {
      fieldName: 'valid',
      title: labels.get('columns.alert.title'),
      filterType: filterTypes.MULTI_SECTIONS,
      filterName: 'scheduleAlert',
      filterWidth: 290,
      filterSections: getAlertColumnFilterSections(
        [scheduleAlertStatus.NO_ALERT, scheduleAlertStatus.LOW_LEVEL_WARNING, scheduleAlertStatus.HIGH_LEVEL_WARNING],
        [
          scheduleAlertType.DUE_DATE_CONFLICT,
          scheduleAlertType.DEPENDENCY_CONFLICT,
          scheduleAlertType.STATION_AVAILABILITY_CONFLICT,
          scheduleAlertType.TOOL_AVAILABILITY_CONFLICT,
          scheduleAlertType.PASSED_SCHEDULE,
          scheduleAlertType.MISCONFIGURATION,
          scheduleAlertType.MINIMUM_COMPLETION_NOTIFICATION,
        ]
      ),
      width: 150,
      columnOptions: {
        aggFunc: taskAlertAggFunc,
        cellComponent: TaskAlertPopoverCell,
        valueGetter: (params) => {
          return {
            dataList: params?.data?.validationMessages,
          };
        },
      },
    },
    {
      fieldName: 'resource',
      title: labels.get('columns.station.title'),
      filterType: filterTypes.MULTI_SELECT_ASYNC,
      filterName: 'operationResource',
      fetchConfig: {
        entityType: EntityPropertyTypes.STATION_NAME,
        filter: [FetchEntitiesFilters.ALL],
        action: actions.fetchEntities,
        maxPageSize: 200,
      },
      width: 150,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: (params) => {
          return params?.data?.plannedResource?.name;
        },
      },
    },
    {
      fieldName: 'operationStatus',
      title: labels.get('columns.status.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'operationStatus',
      filterWidth: 300,
      width: 135,
      columnOptions: {
        aggFunc: operationStatusAggFunc,
        cellRendererSelector: ({ node }) => {
          return {
            component: node.group ? TasksProgressCell : TaskStatusCell,
          };
        },
      },
    },
    {
      fieldName: 'quantity',
      title: labels.get('columns.quantity.title'),
      filterType: filterTypes.NONE,
      // filterName: 'kitTypeBusinessId',      //TODO: L TOOL CAPACITY - handle with Server API
      width: 140,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: ({ node, data }) => {
          if (node.group) {
            return node.allLeafChildren[0]?.data?.workOrderQuantity;
          }
          return getQuantityInfo(data?.quantity, data?.workOrderQuantity);
        },
      },
    },
    {
      fieldName: 'm_priority',
      title: labels.get('columns.priority.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'priority',
      width: 150,
      columnOptions: {
        cellComponent: IconWithTooltipCell,
        valueGetter: ({ data, node }) => {
          const tooltip = node.group ? node.allLeafChildren[0]?.data?.m_priority?.label : data?.m_priority?.label;
          const icon = priorityIconsMap[node.group ? node.allLeafChildren[0]?.data?.m_priority.value : data?.m_priority.value];

          return {
            tooltip,
            icon,
            className: 'priority-icon',
          };
        },
      },
    },
    {
      fieldName: 'dueDate.epochDateTime',
      title: labels.get('columns.dueDate.title'),
      filterType: filterTypes.HORIZON,
      filterName: 'operationDueDate',
      filterLabel: filterLabels.get('date.before'),
      valueFormatter: (params) => {
        return getFormattedDate(params?.data?.dueDate?.epochDateTime);
      },
      width: 150,
      columnOptions: {
        cellComponent: LabelWithStyleCell,
        valueGetter: ({ data, node }) => {
          const dueDate = (node.group ? node.allLeafChildren[0]?.data?.dueDate?.epochDateTime : data?.dueDate?.epochDateTime) || 0;
          const endTime = (node.group ? node.allLeafChildren[0]?.data?.endTime?.epochDateTime : data?.endTime?.epochDateTime) || 0;
          return {
            label: getFormattedDate(dueDate),
            style: dueDate > 0 && endTime > 0 && endTime > dueDate ? { color: '#d9385c' } : null,
          };
        },
        headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
      },
    },
    {
      fieldName: 'operation.name',
      title: labels.get('columns.type.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'operationType',
      width: 115,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'plannedStartTime.epochDateTime',
      title: labels.get('columns.startTime.title'),
      filterType: filterTypes.DATE_SELECT,
      filterAlignment: filterAlignmentTypes.RIGHT,
      filterName: 'plannedStartTime',
      filterLabel: filterLabels.get('date.from'),
      isTime: true,
      valueFormatter: (params) => {
        return getFormattedDate(params?.data?.plannedStartTime?.epochDateTime);
      },
      width: 150,
      columnOptions: {
        cellComponent: LabelWithStyleCell,
        valueGetter: (params) => {
          const plannedStartTime = moment.unix(params?.data?.plannedStartTime?.epochDateTime);
          const horizonStartDate = moment(sSchedulerData?.timeDefinition?.startDate);
          const isTaskOutOfRange = plannedStartTime.isBefore(horizonStartDate);
          const isTaskScheduled = params?.data?.operationStatus === 'SCHEDULED';
          return {
            label: DateTimeHelper.DateTimeFormat(params?.data?.plannedStartTime?.epochDateTime * 1000),
            style: isTaskOutOfRange && isTaskScheduled ? { color: '#d9385c' } : null,
          };
        },
        headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
      },
    },
    ...(PermissionManager.getOrgPreferences().schedulerHumanCapacityEnabled
      ? [
          {
            fieldName: 'humanCapacity',
            title: labels.get('columns.plannedHumanCapacity.title'),
            columnOptions: {
              sortable: false,
              cellComponent: LabelWithTooltipCell,
              valueGetter: ({ data }) => {
                if (data?.splitAssignments?.length) {
                  const humanCapacity = data?.splitAssignments[0]?.humanCapacity;
                  const shiftHumanCapacity = data?.splitAssignments[0]?.shiftHumanCapacity ?? '';

                  return `${humanCapacity}(${shiftHumanCapacity})${data?.splitAssignments?.length > 1 ? '...' : ''}`;
                } else if (data?.operationStatus === 'SCHEDULED') {
                  return 'N/A';
                }

                return '';
              },
            },
          },
        ]
      : []),
    {
      fieldName: 'workOrderDescription',
      title: labels.get('columns.woDescription.title'),
      filterType: filterTypes.NONE,
      filterName: 'operationWorkOrderDescription',
      width: 115,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: ({ data, node }) => {
          return node.group ? node.allLeafChildren[0]?.data?.workOrderDescription : null;
        },
      },
    },
    {
      fieldName: 'productionLine',
      title: labels.get('columns.slot.title'),
      filterType: filterTypes.MULTI_SELECT,
      filterName: 'productionLine',
      width: 120,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    // The following columns are hidden and used for grouping purposes
    {
      fieldName: 'workOrder.name',
      columnOptions: {
        suppressColumnsToolPanel: true,
        rowGroup: true,
        hide: true,
        sortable: false,
      },
    },
    {
      fieldName: 'name',
      columnOptions: {
        suppressColumnsToolPanel: true,
        hide: true,
      },
    },
  ]);

  const onFirstDataRendered = ({ api }) => {
    expandedItemsInSession.forEach((id) => {
      const rowNode = api.getRowNode(id);
      if (rowNode) {
        rowNode.setExpanded(true);
      }
    });

    if (highlightedTaskId) {
      const highlightedRowNode = api.getRowNode(highlightedTaskId);
      if (highlightedRowNode) {
        api.setRowNodeExpanded(highlightedRowNode.parent, true);
        highlightedRowNode.setSelected(true);
      }
    }
  };

  const updateExpandStateInSessionStorage = (id, expanded) => {
    const index = expandedItemsInSession.indexOf(id);
    if (expanded) {
      index === -1 && setExpandedSession([...expandedItemsInSession, id]);
      return;
    }

    if (index > -1) {
      setExpandedSession(expandedItemsInSession.toSpliced(index, 1));
    }
  };

  const onRowGroupOpened = ({ node, api, expanded }) => {
    updateExpandStateInSessionStorage(node.id, expanded);
    const rowNodeIndex = node.rowIndex;
    // factor in child nodes so we can scroll to correct position
    const childCount = node.childrenAfterSort ? node.childrenAfterSort.length : 0;
    const newIndex = rowNodeIndex + childCount;
    api.ensureIndexVisible(newIndex);
  };

  return (
    <div className="scheduler-operations-grid">
      <Grid
        gridName={gridsNames.PREDEFINED_LIST_SCHEDULER_OPERATIONS}
        columnsConfig={columnsConfig}
        actions={actions}
        filterConfig={filterConfig}
        gridProps={{
          onFirstDataRendered: onFirstDataRendered,
          onRowGroupOpened: onRowGroupOpened,
          getRowHeight: getRowHeight,
          masterDetailRowComponent: SchedulerOperationDetail,
          isMasterDetail: true,
          groupDisplayType: 'custom',
          groupSelectsChildren: true,
          getRowId: getRowId,
          resetRowDataOnUpdate: true,
          onGridReadyCallback,
          filterPersistence: true,
        }}
      />
    </div>
  );
}

export default reduxForm({
  form: 'SchedulerOperationsGrid',
})(SchedulerOperationsGrid);

/////////////////////////////////////////////////////////////////////////////////////////////
// Helper functions
//

function getRowId(data) {
  return data?.data?.id;
}

function getRowHeight(params) {
  let rowHeight = 64;
  if (params?.node?.detail && params?.data?.extendedDetails) {
    let rowCount = Math.ceil(params.data.extendedDetails.rows.length / 4);
    return rowCount * 55;
  }
  return rowHeight;
}

function getFormattedDate(data) {
  return data ? DateTimeHelper.DateFormat(data * 1000) : '';
}

function lockedAggFunc({ values }) {
  if (!values || values.length === 0) return [];

  const lockedTasks = values.filter(({ showIcon }) => showIcon);

  if (lockedTasks.length === values.length) {
    return { iconClass: 'pl-lock', showIcon: true };
  } else if (lockedTasks.length > 0) {
    return { iconClass: 'pl-lock partial-lock', showIcon: true };
  }

  return { iconClass: '', showIcon: false };
}

function splitAggFunc({ values }) {
  if (!values || values.length === 0) return [];

  const splitTasks = values.filter(({ showIcon }) => showIcon);

  if (splitTasks.length === values.length) {
    return { iconClass: 'pl-split-task-icon', showIcon: true };
  } else if (splitTasks.length > 0) {
    return { iconClass: 'pl-split-task-icon partial-split', showIcon: true };
  }

  return { iconClass: '', showIcon: false };
}

function taskAlertAggFunc({ values }) {
  if (!values || values.length === 0) return [];

  const aggregatedAlerts = values.reduce((_aggregatedAlerts, currentAlerts) => {
    if (currentAlerts?.dataList?.length > 0) {
      _aggregatedAlerts.push(...currentAlerts?.dataList);
    }
    return _aggregatedAlerts;
  }, []);

  if (aggregatedAlerts.length === 0) return [];

  const highestSeverityAlert = getHighestSeverityAlert(aggregatedAlerts);
  return {
    dataList: [highestSeverityAlert],
  };
}

function getHighestSeverityAlert(alerts) {
  return alerts.sort((a, b) => {
    const severityOrder = { HIGH: 3, MEDIUM: 2 };
    return severityOrder[b.severity] - severityOrder[a.severity];
  })[0];
}

function operationStatusAggFunc({ values }) {
  if (!values || values.length === 0) return [];

  return values.reduce(function (acc, curr) {
    acc[curr] = acc[curr] ? acc[curr] + 1 : (acc[curr] = 1);
    return acc;
  }, {});
}
