import {Map} from 'immutable';
import { actionTypes as SettingsPageActionsType } from '../../../actions/Administration/SettingsPage/settingsPageActions';
import {SettingsMenuItemsIds} from '../../../enums/navigationMenuItemsIds';
import { default as commonCsvSettingsViewReducer } from './commonCsvSettingsViewReducer';
import { default as commonPdfLabelSettingsViewReducer } from './commonPdfLabelSettingsViewReducer';
import { default as sensorsViewReducer } from './preferencesViewReducer';
import { default as preferencesViewReducer } from './preferencesViewReducer';
import { default as createSensorDialogReducer } from './createSensorDialogReducer';
import { default as editPreferenceDialogReducer } from './editPreferenceDialogReducer';
import { default as commonAdditionalFieldsViewReducer } from './commonAdditionalFieldsViewReducer';
import { default as terminologyViewReducer } from './terminologyViewReducer';
import { default as editTerminologyDialogReducer } from './editTerminologyDialogReducer';
import { default as commonAlertsViewReducer } from './commonAlertsViewReducer';
import { default as ipWhitelistViewReducer } from './ipWhitelistViewReducer';
import { default as schedulingPreferencesViewReducer } from './schedulingPreferencesReducer';
import { default as createSchedulingTriggerDialogReducer } from './createSchedulingTriggerDialogReducer';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import commonBarcodeSettingsReducer from './commonBarcodeSettingsReducer';

let defaultState = {
  selectedMenuItemId: PermissionManager.hasPreferencesSettingsTabPermissions() ?
    SettingsMenuItemsIds.NAV_ELEMENT_SYSTEM_PREFERENCES :
    SettingsMenuItemsIds.NAV_ELEMENT_SCHEDULING_PREFERENCES
};

export default function(state = Map(defaultState), action) {
  state = state.set('commonCsvSettingsView', commonCsvSettingsViewReducer(state.get('commonCsvSettingsView'), action));
  state = state.set('commonBarcodeSettingsView', commonBarcodeSettingsReducer(state.get('commonBarcodeSettingsView'), action));
  state = state.set('commonPdfLabelSettingsView', commonPdfLabelSettingsViewReducer(state.get('commonPdfLabelSettingsView'), action));
  state = state.set('preferencesView', preferencesViewReducer(state.get('preferencesView'), action));
  state = state.set('editPreferenceDialog', editPreferenceDialogReducer(state.get('editPreferenceDialog'), action));
  state = state.set('commonAdditionalFieldsView', commonAdditionalFieldsViewReducer(state.get('commonAdditionalFieldsView'), action));
  state = state.set('terminologyView', terminologyViewReducer(state.get('terminologyView'), action));
  state = state.set('editTerminologyDialog', editTerminologyDialogReducer(state.get('editTerminologyDialog'), action));
  state = state.set('commonAlertsView', commonAlertsViewReducer(state.get('commonAlertsView'), action));
  state = state.set('sensorsView', sensorsViewReducer(state.get('sensorsView'), action));
  state = state.set('createSensorDialog', createSensorDialogReducer(state.get('createSensorDialog'), action))
    .set('ipWhitelistView', ipWhitelistViewReducer(state.get('ipWhitelistView'), action));
  state = state.set('schedulingPreferencesView', schedulingPreferencesViewReducer(state.get('schedulingPreferencesView'), action));
  state = state.set('createSchedulingTriggerDialog', createSchedulingTriggerDialogReducer(state.get('createSchedulingTriggerDialog'), action));

  switch(action.type){
    case SettingsPageActionsType.ADMINISTRATION_SETTINGS_SELECTED_MENU_ITEM_CHANGED:
      return state.set('selectedMenuItemId', action.payload);

    default:
      return state;
  }
}
