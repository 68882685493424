import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as userSettingsPageActions }  from '../../../actions/Administration/UserSettingsPage/userSettingsPageActions.js';
import UserSettingsPage from '../../../components/Administration/UserSettingsPage/userSettingsPage';

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userSettingsPageActions, dispatch)
  }
}

let UserSettingsPageContainer = connect(
  (state) => {
    return {
      sData: state.administration.get('userSettingsPage'),
      sLoggedInUser : state.login.get('loggedInUser'),
    };
  },
  mapDispatchToProps
)(UserSettingsPage);

export default UserSettingsPageContainer;



