import { useParams } from 'react-router-dom';
import EtlLogGrid from './EtlLogGrid/etlLogGrid';
import useRedux from 'infrastructure/js/hooks/useRedux';
import { jsxActions as etlLogGridActions } from '../../../actions/AssetPage/etlLogTabGridActions';
import { gridsNames } from '../../../enums/gridsNames';

import './etlLogTab.scss';

const TAB_ID = 'etlLog';

const stateSelector = (state) => {
  let etlLogTabGrid = state.grid.get(gridsNames.ASSET_PAGE_ETL_LOG);
  return {
    sEtlLogTabGrid: etlLogTabGrid,
  };
};

export default function EtlLogTab() {
  const { actions } = useRedux(etlLogGridActions, stateSelector);

  let { tabId } = useParams();
  if (tabId !== TAB_ID) {
    return null;
  }

  return (
    <div className="etl-log-view">
      <EtlLogGrid actions={actions} />
    </div>
  );
}
