import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import {round} from '../../../../utils/assetHelper';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import Label from 'infrastructure/js/components/Label/label.js';
import { reduxForm, formValueSelector } from 'redux-form';
import {getEnumValue, enumTypes} from '../../../../utils/enumHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import SubRollListItem from '../../../Common/Controls/AddRemoveList/AddRemoveListItem/SubRollListItem/subRollListItem';
import UnitHelper, { unitTypes } from 'infrastructure/js/utils/uomHelper';
import MaterialHelper from '../../../../utils/materialHelper';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import EntitiesMultiSelect from '../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import List from 'infrastructure/js/components/List/list';

require('./createSubRollsDialog.scss');

class CreateSubRollsDialog extends React.PureComponent {
  constructor (props) {
    super(props);
    this.activeStatuses = [getEnumValue(enumTypes.ASSET_STATUS)('ACTIVE'), getEnumValue(enumTypes.ASSET_STATUS)('TOOL_MANUFACTURING')];
    this.archiveStatuses = [getEnumValue(enumTypes.ASSET_STATUS)('CONSUMED'), getEnumValue(enumTypes.ASSET_STATUS)('DISQUALIFIED'), getEnumValue(enumTypes.ASSET_STATUS)('SHIPPED')];
    this.labels = createLabelHelper('mat.locationpage.view.cutting.createsubrollsdialog.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.archiveStatuslabels = createLabelHelper('mat.archive.status.');
    this.activeStatuslabels = createLabelHelper('mat.active.status.');

    let preselectedAssets = this.props.sData.get('preselectedAssets');
    this.currentAsset =  (preselectedAssets.size === 1) ? preselectedAssets.get(0) : null;
    this.parentRollQuantity = this.currentAsset && this.currentAsset.lengthLeft ? UnitHelper.serverValueToUserValue(unitTypes.LENGTH, this.currentAsset.lengthLeft, 2) : 0;
    if(PermissionManager.isWeightSupported()){
      this.parentRollQuantity =  this.currentAsset && this.currentAsset.weight ? UnitHelper.serverValueToUserValue(unitTypes.WEIGHT, this.currentAsset.weight, 2) : 0;
    }

    this.subRollsStatuses = this.getStatuses();

    //add the first empty list item on opening
    let initialList = [];
    initialList.push(null);

    this.state = {
      subRolls: initialList,
      remainQuantity: this.parentRollQuantity,
      errorMessage: '',
      printPDFLabels: false,
    };

    this.fetchConfig = {
      entityType: EntityPropertyTypes.WO_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE],
      action: this.props.fetchEntitiesActions.fetchEntities,
    };

    if (this.currentAsset && this.currentAsset.projectId) {
      this.fetchConfig.searchBy = {
        wo: [{
          objectName: 'projectId',
          values: [this.currentAsset.projectId]
        }]
      };
    }

    this.footerCheckbox = {
      text: this.labels.get('subrollsstatus.printPDFLabels'),
      action: (value) => { this.setState({ printPDFLabels: value }); }
    };
  }

  componentDidMount() {
    let initialValues = {subRolls: [{}] };

    this.props.initialize(initialValues);
  }

  getStatuses = () => {
    let options = [];
    options.push({value: 0, label: this.labels.get('subrollsstatus.active'), isDisabled: true});
    this.activeStatuses.forEach((status) => {
      options.push({value: status, label: this.activeStatuslabels.get(status.toLowerCase())} );
    });

    options.push({value: 0, label: this.labels.get('subrollsstatus.archive'), isDisabled: true});
    this.archiveStatuses.forEach((status) => {
      options.push({value: status, label: this.archiveStatuslabels.get(status.toLowerCase())} );
    });

    return options;
  };


  getComponentToRender(props) {
    if (!props || !props.data) {
      return null;
    }
    let option = props.data;

    return <span className={option.isDisabled ? 'sub-rolls-status-title' : 'sub-rolls-status'}>{option.label}</span>

  };

  getDialogButtons() {
    let isLoading = this.props.sData.get('loading');

    let buttonOK = {
      id:'submit',
      text: this.dialogLabels.get('create'),
      bsStyle: 'primary',
      loading: isLoading,
      action:  this.props.handleSubmit(this.onSubmit),
      disabled: (this.state.subRolls.length < 1) ||
                isLoading ||
                this.props.sData.get('hasError') ||
                (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
    };

    let buttonCancel = {
      id:'cancel',
      text: this.dialogLabels.get('cancel'),
      action: this.onHide
    };

    return { left: [buttonCancel], right: [buttonOK] };
  }

  getValidationError = () => {
    if (this.state.errorMessage) {
      return (
        <span className="error-message">
        <span className="pl pl-warning-icon error error-icon"/>
          {this.state.errorMessage}
        </span>
      )
    }
  };

  onHide = () => {
    this.props.actions.hide();
  };

  validateOnSubmit = (data) => {
    let quantitySum = data.subRolls.reduce((previous, current) => {
      //+(current.cutLength) to convert string to number
      let quantity = previous + (+(current.cutLength));
      if(PermissionManager.isWeightSupported()){
        quantity = previous + (+(current.weight));
      }
      return quantity;
    }, 0);

    return (quantitySum > this.parentRollQuantity) ? this.labels.get('footer.errormessage') : '';
  };

  onSubmit = (data) => {

    let errorMessage = this.validateOnSubmit(data);
    this.setState({errorMessage: errorMessage});
    if (errorMessage) {
      return;
    }

    let newData = {};
    if (data.workOrder) {
      newData.workOrder = data.workOrder.label;
    }

    if (data.subRollsStatus) {
      newData.subRollsStatus = data.subRollsStatus.value;
    }

    if (data.subRolls) {
     let subRolls = data.subRolls.map((item) => {
       //+(item.cutLength) to convert string (ex: '1.') to number
       if(PermissionManager.isWeightSupported()){
         let weightAsNumber = +(item.weight);
         return {businessId: item.subRoll, weightUsed: UnitHelper.userValueToServerValue(unitTypes.WEIGHT, weightAsNumber, 2)}
       }
       let lengthAsNumber = +(item.cutLength);
       return {businessId: item.subRoll, cutLength: UnitHelper.userValueToServerValue(unitTypes.LENGTH, lengthAsNumber, 2)}
      });

      newData.subRolls = subRolls;
    }

    newData.ignoreValidationWarnings = (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
                                        data.footerValidationCheckbox : false;

    newData.parentRollId = this.currentAsset.id;

    this.props.actions.submit(newData,  null, this.props.reloadParentComponent, this.state.printPDFLabels);

  };

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  onGenerateClickCallback = (index) => {
    this.props.actions.generateSubRollId(this.currentAsset.id).then(
      (response) => {
        let name = 'subRolls[' + index + '].subRoll';
        this.props.change( name, response.data);
      }
    );
  };

  getRemainQuantity = () => {
    let allRows = this.props.fieldsValues;

    if (allRows) {

      let fieldName = PermissionManager.isWeightSupported() ? 'weight' : 'cutLength';

      let sum = allRows.reduce((previous, current) => {
        //+(value) to convert string to number
        let cutQuantity = current[fieldName] ?? 0;

        return previous + (+(cutQuantity) );
      }, 0);

      return round(this.parentRollQuantity - sum);
    }
  }

  updateRemainQuantity = () => {
    let remainQuantity = this.getRemainQuantity();
    this.setState({remainQuantity: remainQuantity});
  };

  getMaxCutQuantityByIndex = (index) => {
    let allRows = this.props.fieldsValues;

    if (allRows && allRows.length > index) {
      let fieldName = PermissionManager.isWeightSupported() ? 'weight' : 'cutLength';
      let quantity = +(allRows[index]?.[fieldName]) || 0;
      return quantity + this.getRemainQuantity();
    }
    return this.state.remainQuantity;
  };

  onQuantityChangedCallback = (value, index) => {
    setTimeout(() => {this.updateRemainQuantity(index)}, 0);
  };

  onRemoveCallback = (index) => {
    let subRolls = [...this.state.subRolls];

    if (index > -1) {
      subRolls.splice(index, 1);
    }

    this.setState({subRolls: subRolls});
    setTimeout(() => {this.updateRemainQuantity()}, 0);
  };

  onAddCallback = () => {
    let subRolls = [...this.state.subRolls];

    subRolls.push(null); //hold index in subRolls for the new added item

    this.setState({subRolls: subRolls});
    setTimeout(() => {this.updateRemainQuantity()}, 0);
  };


  renderCurrentAssetDetails = () => {
    let parentRollQuantity = (this.parentRollQuantity === 0) ? '0' : this.parentRollQuantity;
    let rollDetails = this.labels.get('rolldetails', undefined, {businessId: this.currentAsset.businessId});
    let rollQuantityDisplay = PermissionManager.isWeightSupported() ?
      this.labels.get('rollWeight', undefined, {rollWeight: parentRollQuantity, unit: UnitHelper.getLabelForUnitType(unitTypes.WEIGHT)}) :
      this.labels.get('rolllength', undefined, {rollLength: parentRollQuantity, unit: UnitHelper.getLabelForUnitType(unitTypes.LENGTH)});

    let materialLabel = MaterialHelper.getMaterialFullLabel(this.currentAsset.materialName, this.currentAsset.materialBusinessId);
    return (
      <div>
        <div className="title">
          <Label text={materialLabel} />
          <Label id="rollDetails" text={rollDetails} tooltip={this.currentAsset.businessId}/>
        </div>
        <div id='roll-length' className="title">{rollQuantityDisplay}</div>
      </div>
    );
  };

  listItemRenderer = (data) => {
    let maxQuantity = this.getMaxCutQuantityByIndex(data.index);
    return (
      <SubRollListItem
        {...data}
        onGenerateClickCallback={this.onGenerateClickCallback}
        onQuantityChangedCallback={this.onQuantityChangedCallback}
        labels={this.labels}
        quantityLeft={maxQuantity}

        {...this.props}
      />
    )
  };

  renderRemainLengthOrWeight=()=>{
   let units = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
   return  (
      <div className="label-with-units-container">
        <label>{this.labels.get('subrollslist.remainQuantity', undefined, { remainQuantity: this.state.remainQuantity } )}</label>
        <label className="units">{`(${UnitHelper.getLabelForUnitType(units)})`}</label>
      </div>
    )
  };

  renderSubRollCutLengthOrWeightUsed=()=>{
    let units = PermissionManager.isWeightSupported() ? unitTypes.WEIGHT : unitTypes.LENGTH;
    let labelKey = PermissionManager.isWeightSupported() ? 'subrollslist.weightUsed' : 'subrollslist.cutlength';

    return (
      <div className="column">
        <label className="">{this.labels.get(labelKey)}</label>
        <label className="units">{`(${UnitHelper.getLabelForUnitType(units)})`}</label>
      </div>
    )
  };

  renderSubRollsList = () => {
    return(
      <div className="sub-rolls-list-container input-section no-margin" >
        <div className="sub-rolls-list-title">
          <label className="column">{this.labels.get('subrollslist.title', '', {subRollsNumber: this.state.subRolls.length})}</label>
          {this.renderSubRollCutLengthOrWeightUsed()}

        </div>
        <List name='subRolls'
                       itemRenderer={this.listItemRenderer}
                       preSelectedItems={this.state.subRolls}
                       onRemoveCallback={this.onRemoveCallback}
                       onAddCallback={this.onAddCallback}
                       maxItemsToOverflow={7}
                       addButtonLabel={this.labels.get('subrollslist.button.add')}
        />
        {this.renderRemainLengthOrWeight()}

      </div>
    )
  };


  render() {
    if (!this.currentAsset ) {
      return null;
    }

    let {sData, ...others} = this.props;

    return (
      <div>
        <Dialog
          id='create-sub-rolls-dialog'
          className='create-sub-rolls-dialog'
          sData={this.props.sData}
          show={this.props.sData.get('show')}
          onHide={this.onHide}
          titleText={this.labels.get('header.title') }
          footerCenterText = {this.getValidationError()}
          footerCheckbox={this.footerCheckbox}
          footerValidationCheckbox={this.getFooterValidationCheckBox()}
          footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
          footerButtons ={this.getDialogButtons()}
          onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
        >

          {this.renderCurrentAssetDetails()}

          <InputSection label={this.labels.get('workorder')} htmlFor="workOrder" className="inline left-side">
            <EntitiesMultiSelect
              id="workOrder"
              name="workOrder"
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('WO')]}
              fetchConfig={this.fetchConfig}
              {...others}
              isMulti={false}
              showEntitiesTypes={false}
              autoFocus={true}
            />

          </InputSection>

          <InputSection label={this.labels.get('subrollsstatus')} htmlFor="subRollsStatus" className="inline right-side">
            <Combobox
              id="subRollsStatus"
              name="subRollsStatus"
              options={this.subRollsStatuses}
              optionRenderer={this.getComponentToRender}
            />
          </InputSection>

          {this.renderSubRollsList()}

        </Dialog>
      </div>
    );
  }

}

CreateSubRollsDialog = reduxForm({
    form: 'createSubRollsDialogForm',
    onChange: (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(CreateSubRollsDialog);

const selector = formValueSelector('createSubRollsDialogForm');

export default CreateSubRollsDialog = connect( state => {
  return {  fieldsValues: selector(state, 'subRolls') }
})( CreateSubRollsDialog );
