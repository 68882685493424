import { useEffect, useMemo } from 'react';
import { List } from 'immutable';
import useRedux from 'infrastructure/js/hooks/useRedux';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import useTabsWithUrl from 'infrastructure/js/hooks/useTabsWithUrl';
import { navigationStates } from '../../enums/navigationStates';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { gridsNames } from '../../enums/gridsNames';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import { jsxActions as headerActions } from '../../actions/Header/headerActions';
import { jsxActions as lotPageActions } from '../../actions/LotPage/lotPageActions';
import { jsxActions as createAttachmentDialogActions } from '../../actions/Dialogs/createAttachmentDialog/createAttachmentDialogActions';
import { jsxActions as editAttachmentDialogActions } from '../../actions/Dialogs/editAttachmentDialog/editAttachmentDialogActions';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import OverviewTab from './OverviewTab/overviewTab';
import AttachmentTabContainer from '../../containers/AssetPage/attachmentTabContainer.js';
import FullScreenTabsSeparator from '../Common/FullScreenTabsSeparator/fullScreenTabsSeparator';
import CreateAttachmentDialog from '../Dialogs/CreateAttachmentDialog/createAttachmentDialog';
import EditAttachmentDialog from '../Dialogs/EditAttachmentDialog/editAttachmentDialog';
import Header, { headerItemsLabels } from '../Common/Header/header';

import './lotPage.scss';
import { useCallback } from 'react';

const allActions = {
  headerActions,
  createAttachmentDialogActions,
  editAttachmentDialogActions,
  lotPageActions,
};

const stateSelector = (state) => {
  return {
    lot: state.lotPage.getIn(['data', 'lot']),
    overviewTabData: state.lotPage.get('overviewTab') || {},
    attachmentTabGrid: state.grid.get(gridsNames.ASSET_PAGE_ATTACHMENT) || null,
    createAttachmentDialogData: state.dialogs.get('createAttachmentDialog'),
    editAttachmentDialogData: state.dialogs.get('editAttachmentDialog'),
  };
};

const tabKeys = {
  OVERVIEW: 'overview',
  ATTACHMENT: 'attachment',
};

export default function LotPage({ match }) {
  const lotId = match.params.lotId;

  const tabsLabels = useMemo(() => createLabelHelper('mat.assetpage.topbar.tabs.'), []);
  const { state, actions } = useRedux(allActions, stateSelector);
  const updateNavBar = useNavBarUpdate();
  const { params, setTabKeyInURL } = useTabsWithUrl(`#/lot/${lotId}`, Object.values(tabKeys), tabKeys.OVERVIEW);

  useEffect(() => {
    updateNavBar(navigationStates.LOT, lotId);
    actions.lotPageActions.init(lotId);

    return () => {
      actions.lotPageActions.unmount();
    };
  }, [updateNavBar, lotId, actions.lotPageActions]);

  const reloadLotPage = useCallback(() => actions.lotPageActions.init(lotId), [lotId, actions.lotPageActions]);

  const headerItems = useMemo(() => {
    const items = {
      kebab: {},
    };

    if (params.tabId === tabKeys.ATTACHMENT) {
      let attachmentTabGrid = state.attachmentTabGrid;
      let hasRows = attachmentTabGrid && attachmentTabGrid.get('rows') ? attachmentTabGrid.get('rows').size > 0 : false;
      let selectedRowsData = attachmentTabGrid ? attachmentTabGrid.get('selectedRowsData') : List();
      let isDeleted = selectedRowsData && selectedRowsData.size && selectedRowsData.get(0).isDeleted;

      items.kebab.menuItems = [
        {
          id: 'downloadAttachment',
          label: headerItemsLabels.DOWLOAD,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.headerActions.onMenuItemDownloadAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
          },
        },
        {
          id: 'editAttachment',
          label: headerItemsLabels.EDIT_CATEGORY,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.headerActions.onMenuItemEditAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
            reloadParentComponent: reloadLotPage,
          },
        },
        {
          id: 'deleteAttachment',
          label: headerItemsLabels.DELETE,
          disabled: !hasRows || !selectedRowsData || selectedRowsData.size !== 1 || isDeleted,
          action: actions.headerActions.onMenuItemDeleteAttachmentClick,
          actionData: {
            selectedRowsData: selectedRowsData,
            reloadParentComponent: reloadLotPage,
          },
        },
      ];

      items.buttons = [
        {
          id: 'upload',
          label: headerItemsLabels.UPLOAD,
          action: actions.headerActions.onUploadFileClicked,
          className: 'no-icon',
          actionData: {
            assetId: lotId,
            assetType: 'LOT',
            reloadParentComponent: reloadLotPage,
          },
        },
      ];
      return items;
    }

    return items;
  }, [params.tabId, lotId, actions.headerActions, state.attachmentTabGrid, reloadLotPage]);

  return (
    <div className="lot-page">
      <Header {...headerItems} />
      <FullScreenTabsSeparator />

      <Tabs id="lot-page-tabs" activeKey={params.tabId} tabType="page" onSelect={setTabKeyInURL}>
        <Tabs.Tab className="overview-tab" eventKey={tabKeys.OVERVIEW} title={tabsLabels.get('overview')} animation={false}>
          <OverviewTab sData={state.overviewTabData} />
        </Tabs.Tab>
        <Tabs.Tab className="attachments-tab" eventKey={tabKeys.ATTACHMENT} title={tabsLabels.get('attachments')} animation={false}>
          {state?.lot?.get('id') && <AttachmentTabContainer match={match} />}
        </Tabs.Tab>
      </Tabs>

      <PL_DialogWrapper
        dialogComponent={CreateAttachmentDialog}
        asset={state.lot}
        show={state.createAttachmentDialogData.get('show')}
        actions={actions.createAttachmentDialogActions}
        sData={state.createAttachmentDialogData}
        reloadParentComponent={reloadLotPage}
      />

      <PL_DialogWrapper
        dialogComponent={EditAttachmentDialog}
        asset={state.lot}
        show={state.editAttachmentDialogData.get('show')}
        actions={actions.editAttachmentDialogActions}
        sData={state.editAttachmentDialogData}
        preselectedData={state.attachmentTabGrid}
        reloadParentComponent={reloadLotPage}
      />
    </div>
  );
}
