import React from 'react';
import {reduxForm} from 'redux-form';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import CheckMarkCell from '../../../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import {gridsNames} from '../../../../../../enums/gridsNames';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import LabelWithTooltipCell from '../../../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import {FetchEntitiesFilters} from '../../../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../../../enums/entityPropertyTypes';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import {enumTypes, getEnumLabel} from '../../../../../../utils/enumHelper';

require('./toolTypesViewGrid.scss');

class ToolTypesViewGrid extends React.PureComponent {

  filterConfig = [
    {fieldName: 'businessId', filterName: 'toolTypeBusinessId', getOptions: false},
    {fieldName: 'active', filterName: 'toolTypeStatus', getOptions: true},
    {fieldName: 'description', filterName: 'toolTypeDescription', getOptions: true},
    {fieldName: 'toolCategory.businessId', filterName: 'toolTypeCategory', getOptions: true},
    {fieldName: 'kitTypesWithCapacity', filterName: 'toolTypeKitType', getOptions: false},
    {fieldName: 'configurationType', filterName: 'configurationType', getOptions: true},
  ];


  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.administration.matsettings.tooltypes.grid.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.tooltype.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'toolTypeBusinessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.TOOL_TYPE_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ALL],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data.businessId;
          }
        }
      },
      {
        fieldName: 'active',
        title: this.labels.get('columns.active.title'),
        filterType: filterTypes.NONE,
        filterName: 'toolTypeStatus',
        width: 130,
        columnOptions: {
          sort: 'desc',
          cellComponent: CheckMarkCell,
          valueGetter: (params) => {
            return params.data?.active;
          },
        }
      },
      {
        fieldName: 'toolCategory.businessId',
        title: this.labels.get('columns.toolCategory.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolTypeCategory',
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.toolCategory?.businessId || '';
          },
        }
      },
      {
        fieldName: 'kitTypesWithCapacity',
        title: this.labels.get('columns.kitType.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'toolTypeKitType',
        fetchConfig: {
          entityType: EntityPropertyTypes.KIT_TYPE_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data?.kitTypesWithCapacity?.map(item => item.kitTypeBusinessId)?.join(', ') || [];
          },
        }
      },
      {
        fieldName: 'description',
        title: this.labels.get('columns.toolTypeName.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'toolTypeDescription',
        columnOptions: {
          valueGetter: (params) => {
            return params.data?.description;
          },
        }
      },
      {
        fieldName: 'maxManufactureUsageTime',
        title: this.labels.get('columns.usageTime.title'),
        filterType: filterTypes.NONE,
        width: 190,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          headerComponentParams: {
            headerIcon: 'info-icon-blue',
            headerTooltip: this.labels.get('columns.usageTime.tooltip'),
          },
          valueGetter: (params) => {
            const timeString = DateTimeHelper.ConvertMinutesToDaysHoursMinutes(params.data.maxManufactureUsageTime)
            return timeString;
          },
        }
      },
      {
        fieldName: 'maxNumOfCycles',
        title: this.labels.get('columns.cycles.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          headerComponentParams: {
            headerIcon: 'info-icon-blue',
            headerTooltip: this.labels.get('columns.cycles.tooltip'),
          },
          valueGetter: ({data: {maxNumOfCycles}}) => {
            return maxNumOfCycles == null ? '' : `${maxNumOfCycles} ${this.labels.get('columns.cycles.title')}`;
          }
        }
      },
      {
        fieldName: 'configurationType',
        title: this.labels.get('columns.kitTypesConfiguration.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'configurationType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.TOOL_CONFIGURATION_TYPE)(params.data.configurationType);
          },
        }
      },
    ]
  };

  render() {
    return (
      <div className="administration-view-grid">
        <Grid gridName={gridsNames.ADMINISTRATION_MAT_SETTINGS_TOOL_TYPES}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'toolTypesViewGrid'
  }
)(ToolTypesViewGrid);
