import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';

import './dropdownButton.scss';

export default class PL_DropdownButton extends React.PureComponent{
   getLoading = (loading) => {
     return loading ? <div className="pre-dropdown-button"><i className='fa fa-spinner fa-spin fa-fw' aria-hidden="true"/></div> : '';
   }

  render() {
    let { className="", loading, ...props} = this.props;
    className += ' only-icon btn-default';
    return (
      <Dropdown>
        <Dropdown.Toggle title={this.getLoading(loading)} className={className} {...props}>
          <span className='fa fa-ellipsis-h'/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
                {this.props.children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

PL_DropdownButton.propTypes = {
  id:PropTypes.string.isRequired,
};



