import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {enumTypes, getEnumValue} from '../../../../../utils/enumHelper';
import EntitiesMultiSelect from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import PermissionManager from 'infrastructure/js/utils/permissionManager';

require('./shipToCustomerDialog.scss');

class ShipToCustomerDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.shipments.shipToCustomer.dialog.');

    this.shipmentFetchConfig = {
      entityType: EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION],
      action: this.props.actions.fetchEntities,
    };
  }

  componentDidMount() {
    let locations = this.getLocations();

    if (locations.length === 1) {
      this.props.change('outgoingLocation', locations[0]);
    }
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide
      }],
      right: [
        {
          id:'submit',
          text: this.dialogLabels.get('confirm'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  getShipmentId = (data) => {
    return data?.['add-asset-selection']?.assetsValue?.value || null ;
  }

  getLocationId = (data) => {
    return data?.outgoingLocation?.value || null ;
  }

  onSubmit = (data) => {
    let submitData = {
      shipmentId:  this.getShipmentId(data),
      locationId: this.getLocationId(data),
      ignoreValidationWarnings: !PermissionManager.getOrgPreferences().asnShipmentLotAttachmentsRequired ? true :
        (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,

    };
    this.props.actions.submit(submitData, /*this.getMessageDialogBuilder()*/null, this.props.reloadParentComponent);
  };

  onHide =() => {
    this.props.actions.hide();
  }

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      { label: this.dialogLabels.get('ignorewarnings'),
        onChange: this.onFooterValidationChange
      } : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };

  getLocations = () => {
    let dialogData = this.props.sData.get('dialogData');
    return dialogData?.locations || [];
  }

  render() {

    let preselectedAssets = this.props.sData.get('preselectedAssets');

    return (
      <Dialog
        id="ship-to-customer-dialog"
        className="ship-to-customer-dialog"
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >

        <div className="add-to-asset-container">
          <div className="add-to-asset-section input-section no-margin">
            <label className="add-to-asset-label">{this.labels.get('add')}</label>

            <EntitiesMultiSelect
              id="add-asset-selection"
              name='add-asset-selection'
              className="add-to-asset-selection"
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT')]}
              validate={Validation.required}
              preSelectedEntities={preselectedAssets}
              fetchConfig={this.shipmentFetchConfig}
              autoFocus={true}
              isMulti={false}
              disableOnPreselect={true}
              {...this.props}
            />
          </div>

          <label className="add-to-asset-arrow">
            <i className="pl pl-move-arrow-right"/>
          </label>

          <div className="add-to-asset-section input-section no-margin">
            <label className="add-to-asset-label">{this.labels.get('to')}</label>
            <Combobox id="outgoingLocation"
                      name="outgoingLocation"
                      options={this.getLocations()}
                      validate={Validation.required}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default reduxForm({
    form: 'shipToCustomerDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(ShipToCustomerDialog);


ShipToCustomerDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};
