import { Map } from 'immutable';
import { actionTypes as assetsViewActionTypes } from '../../actions/LocationPage/AssetsView/assetsViewActions.js';
import { actionTypes as locationPageActionTypes } from '../../actions/LocationPage/locationPageActions';

let defaultState = {
  deleteme: 'ASSETSVIEW_DATA',
  counters: Map({
    allAssets: 0,
    rolls: 0,
    spools: 0,
    resins: 0,
    kits: 0,
    tools: 0,
    containers: 0,
    parts: 0,
    others: 0
  })
};


export default function(state = Map(defaultState), action) {

  switch (action.type) {

    case locationPageActionTypes.LOCATIONPAGE_LOCATION_ASSETS_COUNT_FETCH_READY:
      state = state.setIn(['counters', 'allAssets'], action.payload.all);
      state = state.setIn(['counters', 'rolls'], action.payload.rolls);
      state = state.setIn(['counters', 'spools'], action.payload.spools);
      state = state.setIn(['counters', 'resins'], action.payload.resins);
      state = state.setIn(['counters', 'kits'], action.payload.kits);
      state = state.setIn(['counters', 'tools'], action.payload.tools);
      state = state.setIn(['counters', 'groups'], action.payload.groups);
      state = state.setIn(['counters', 'parts'], action.payload.parts);
      state = state.setIn(['counters', 'others'], action.payload.others);
      return state;

    default:
      return state;
  }
}












