import React from 'react';
import PropTypes from 'prop-types';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize        from 'infrastructure/js/components/controls/controlsNormalizations';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox';
import withPortal from 'infrastructure/js/components/HOCs/withPortal/withPortal';

import'./ruleListItem.scss';


const ComboboxPortal = withPortal(Combobox)

export default class RuleListItem extends React.PureComponent {

  getSignOptions = (ruleName) => {
    let ruleFound = this.props.options.find((op) => {return (op.value === ruleName)});
    return ruleFound && ruleFound.data && ruleFound.data.signs ? ruleFound.data.signs : [];
  };

  getRuleType = (ruleName) => {
    let ruleFound = this.props.options.find((op) => {return (op.value === ruleName)});
    return ruleFound && ruleFound.data && ruleFound.data.type ? ruleFound.data.type : null;
  };

  getEnumOptions = (ruleName) => {
    let ruleFound = this.props.options.find((op) => {return (op.value === ruleName)});
    return ruleFound && ruleFound.data && ruleFound.data.enumValues ? ruleFound.data.enumValues : [];
  };

  onChangeCallback = (value, oldValue) => {
    if (value && oldValue && value.value === oldValue.value) {
      return;
    }

    this.props.change( this.props.name + '.[ruleSign]', null);
    this.props.change( this.props.name + '.[ruleValue]', null);

    if (this.props.onChangeCallback) {
      this.props.onChangeCallback(value, oldValue, this.props.index);
    }
  };

  maxLength40 = Validation.maxLength(40);

  renderRuleValuesComponent = (ruleName) => {
    let {name} = this.props;

    let ruleType = this.getRuleType(ruleName);

    if (ruleType === 'Enum') {
      return (
        <ComboboxPortal
          id={ name + '.[ruleValue]'}
          name={ name + '.[ruleValue]'}
          options={this.getEnumOptions(ruleName)}
          className="small-textfield fix-margin-left"
          validate={Validation.dropdown.required}
        />
      );
    }
    return (
      <TextField
        id={ name + '.[ruleValue]'}
        name={ name + '.[ruleValue]'}
        className="medium-textfield fix-margin-middle"
        validate={ruleType === 'Integer' ? Validation.number.required : [Validation.required, this.maxLength40]}
        normalize={ruleType === 'Integer' ? Normalize.number(true, -99999, 99999) : undefined}
      />
    )
  };


  render() {
    let {options, name, ruleName} = this.props;

    return (
      <div className="rule-list-item">

        <ComboboxPortal
          id={ name + '.[ruleProperty]'}
          name={ name + '.[ruleProperty]'}
          options={options}
          validate={Validation.dropdown.required}
          onChangeCallback={this.onChangeCallback}
        />

        <ComboboxPortal
          id={ name + '.[ruleSign]'}
          name={ name + '.[ruleSign]'}
          options={this.getSignOptions(ruleName)}
          className="small-textfield fix-margin-left"
          validate={Validation.dropdown.required}
        />

        {this.renderRuleValuesComponent(ruleName)}

      </div>
    );
  }
}

RuleListItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
};


