import Network from 'infrastructure/js/modules/network';
import { api as locationPageApi } from '../locationPageActions.js';
import { api as tasksGridApi } from './tasksGridActions';
import { bulkRollbackTasksProgress, bulkUpdateTasksProgress } from '../../../services/Tasks/tasksService';
import { fetchTasksCounters } from '../../../services/Scheduler/schedulerService';
import { api as messageDialogApi } from '../../../actions/MessageDialog/messageDialogActions';
import * as boHelper from '../../Dialogs/batchOperationActionsHelper';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { BuildDialogDescriptorForBO } from 'infrastructure/js/components/Dialog/dialogHelper';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  ...boHelper.getActionTypes('LOCATIONPAGE_TASKSVIEW'),
  LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED: 'LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED',
  LOCATIONPAGE_TASKSVIEW_GETOPERATIONS_FINISHED: 'LOCATIONPAGE_TASKSVIEW_GETOPERATIONS_FINISHED',
  LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE: 'LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE',
  LOCATIONPAGE_TASKSVIEW_HISTORY_ADD_COMMAND: 'LOCATIONPAGE_TASKSVIEW_HISTORY_ADD_COMMAND',
  LOCATIONPAGE_TASKSVIEW_HISTORY_UNDO: 'LOCATIONPAGE_TASKSVIEW_HISTORY_UNDO',
  LOCATIONPAGE_TASKSVIEW_HISTORY_REDO: 'LOCATIONPAGE_TASKSVIEW_HISTORY_REDO',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  ...boHelper.getActions('LOCATIONPAGE_TASKSVIEW'),
  fetchChartsFinished: function (payload) {
    return { type: actionTypes.LOCATIONPAGE_TASKSVIEW_FETCHCHARTS_FINISHED, payload: payload };
  },
  getOperationsFinished: function (payload) {
    return {
      type: actionTypes.LOCATIONPAGE_TASKSVIEW_GETOPERATIONS_FINISHED,
      payload: payload,
    };
  },
  changeViewDate: function (payload) {
    return {
      type: actionTypes.LOCATIONPAGE_TASKSVIEW_CHANGE_VIEW_DATE,
      payload: payload,
    };
  },
  addCommand: function (payload) {
    return { type: actionTypes.LOCATIONPAGE_TASKSVIEW_HISTORY_ADD_COMMAND, payload };
  },
  undo: function () {
    return { type: actionTypes.LOCATIONPAGE_TASKSVIEW_HISTORY_UNDO };
  },
  redo: function () {
    return { type: actionTypes.LOCATIONPAGE_TASKSVIEW_HISTORY_REDO };
  },
};

const config = {
  submitMethod: ({ isRollback, ...data }) => (isRollback ? bulkRollbackTasksProgress(data) : bulkUpdateTasksProgress(data)),
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)

export let api = {
  ...boHelper.getApiActions(actions, config),

  init(dispatch, getState) {
    return function (shouldRefreshGrid) {
      if (shouldRefreshGrid) {
        tasksGridApi.reload(dispatch, getState)();
      }

      return api.fetchCharts(dispatch, getState)();
    };
  },

  fetchCharts(dispatch, getState) {
    return function () {
      const locationId = locationPageApi.getLocationId(dispatch, getState)();
      const viewDate = getState().locationPage.getIn(['tasksViewData', 'viewDate']);
      const payload = {
        viewDate,
      };
      return fetchTasksCounters({ locationId, payload }).then((response) => {
        let success = true;

        if (!Network.isResponseValid(response)) {
          console.error('WorkOrders fetch charts failed');
          success = false;
          return;
        }

        dispatch(
          actions.fetchChartsFinished({
            completed: response?.data?.completedTasksCount,
            total: response?.data?.totalTasksCount,
            success,
          })
        );
      });
    };
  },

  bulkProgressReport(dispatch, getState) {
    return function ({ selectedRows, status, startTime = null, endTime = null, isReportAsPlanned, isRollback }, isUndoRedoCommand = false) {
      tasksGridApi.setBusy(true)(dispatch, getState);
      const locationId = getState().appTopbar.getIn(['topNavigatorData', 'currentLocation', 'id']);
      const performedBy = getState().login.get('loggedInUser')?.id;

      let query;
      if (isRollback) {
        query = {
          taskAssignmentIds: selectedRows.toArray()?.map((row) => row.id),
          isRollback
        };
      } else {
        query = {
          tasksProgressList: _convertRowsToTasksList(selectedRows),
          locationId,
          status,
          isReportAsPlanned,
          performedBy,
          startTime,
          endTime,
          isRollback
        };
      }

      return api
        .submit(dispatch, getState)(query, _bulkProgressMessageDialogBuilder)
        .then((response) => {
          api.fetchCharts(dispatch, getState)();
          tasksGridApi.reload(dispatch, getState)();

          if (!isUndoRedoCommand) {
            const params = {
              selectedRows,
              status,
              startTime,
              endTime,
              isReportAsPlanned,
            };
            api.addCommand(dispatch, getState)('bulkProgressReport', params);
          }

          return { success: true };
        })
        .catch((err) => {
          console.error('Bulk update tasks progress failed.', err);
          messageDialogApi.responseError(dispatch, getState)();
          return { success: false };
        });
    };
  },

  addCommand(dispatch, getState) {
    return function (actionType, params) {
      const command = {
        actionType,
        params,
      };
      dispatch(actions.addCommand(command));
    };
  },

  undo(dispatch, getState) {
    return function () {
      const commandStack = getState().locationPage.getIn(['tasksViewData', 'history']).commandStack;
      if (commandStack.length === 0) return;

      const lastCommand = commandStack[commandStack.length - 1];

      api
        .bulkProgressReport(dispatch, getState)({...lastCommand.params, isRollback: true }, true)
        .then(({ success }) => {
          if (success) {
            dispatch(actions.undo());
          }
        });
    };
  },

  redo(dispatch, getState) {
    return function () {
      const redoStack = getState().locationPage.getIn(['tasksViewData', 'history']).redoStack;
      if (redoStack.length === 0) return;

      const lastRedoCommand = redoStack[redoStack.length - 1];

      api
      .bulkProgressReport(dispatch, getState)(lastRedoCommand.params, true)
      .then(({ success }) => {
        if (success) {
          dispatch(actions.redo());
        }
      });
    };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE

export let jsxActions = {
  ...boHelper.getJsxActions(api),

  changeViewDate(viewDate, viewEndDate = null) {
    return function (dispatch, getState) {
      dispatch(actions.changeViewDate({viewDate, viewEndDate}));
      api.fetchCharts(dispatch, getState)();
      tasksGridApi.reload(dispatch, getState)();
    };
  },

  bulkProgressReport(data) {
    return function (dispatch, getState) {
      api.bulkProgressReport(dispatch, getState)(data);
    };
  },

  clearFilters() {
    return function (dispatch, getState) {
      tasksGridApi.clearFilters(dispatch, getState)();
    };
  },

  addCommand(actionType, params) {
    return function (dispatch, getState) {
      api.addCommand(dispatch, getState)(actionType, params);
    };
  },

  undo() {
    return function (dispatch, getState) {
      api.undo(dispatch, getState)();
    };
  },

  redo() {
    return function (dispatch, getState) {
      api.redo(dispatch, getState)();
    };
  },
};
/////////////////////////////////////////////////////////////////

function _convertRowsToTasksList(rows) {
  return (
    rows.toArray()?.map((row) => ({
      taskAssignmentId: row?.id,
      toolId: row?.selectedToolId,
      // estimatedLeftDuration: 120,
    })) || []
  );
}

function _bulkProgressMessageDialogBuilder(response, fnCloseDialog) {
  const labels = createLabelHelper('mat.locationpage.view.tasks.panel.');
  let title = labels.get('updateStatus.confirmation.message.title', '', { count: response.totalJobItems });
  return BuildDialogDescriptorForBO(response, fnCloseDialog, title);
}
