import React from 'react';

import ImportFilesCommonGrid from '../ImportFilesCommonGrid/importFilesCommonGrid';
import {ImportTypes} from '../../../../../enums/importTypes';
import {gridsNames} from '../../../../../enums/gridsNames';

export default class ImportToolGroupsGrid extends React.PureComponent {

  render() {
    return  <ImportFilesCommonGrid  rows={this.props.rows} assetType={ImportTypes.IMPORT_TOOL_GROUPS} gridName={gridsNames.IMPORT_TOOL_GROUPS}/>;
  }
}
