import React from 'react';
import {reduxForm} from 'redux-form';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import {filterTypes} from 'infrastructure/js/enums/filterTypes';
import {gridsNames} from '../../../enums/gridsNames.js';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import LabelWithTooltipCell from '../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import ComponentWithErrorCell from '../../Common/CustomGridCells/ComponentWithErrorCell/componentWithErrorCell';
import editableViewCell from '../../Wizards/Common/Cells/InputCell/editableViewCell';

require('./tasksAssignmentsGrid.scss');

class TasksAssignmentsGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'name', filterName: 'taskAssignmentId'},
    {fieldName: 'operationStatus', filterName: 'operationStatus', getOptions: false},
  ]

  constructor (props){
    super(props);
    // this.labels = createLabelHelper('mat.grid.');  ??TODO: L what labels to take?
    this.labels = createLabelHelper('mat.locationpage.view.tasks.grid.');
    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    let columnsConfig = [
      {
        fieldName: 'task.name',
        title: this.labels.get('columns.name.title'),
        filterType: filterTypes.NONE,
        filterName: 'taskAssignmentId',
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'task.workOrder.businessId',
        title: this.labels.get('columns.wo.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'startTime',
        title: this.labels.get('columns.startTime.title'),
        filterType: filterTypes.NONE,
        width: 160,
        isTime: true,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data ? DateTimeHelper.DateTimeFormat(params.data?.startTime?.epochDateTime * 1000) : '';
          },
          headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
        },
      },
      {
        fieldName: 'endTime',
        title: this.labels.get('columns.endTime.title'),
        filterType: filterTypes.NONE,
        width: 160,
        isTime: true,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return params.data ? DateTimeHelper.DateTimeFormat(params.data?.endTime?.epochDateTime * 1000) : '';
          },
          headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
        },
      },
      {
        fieldName: 'task.completedQuantity',
        title: this.labels.get('columns.completedQuantity.title'),
        filterType: filterTypes.NONE,
        width: 220,
        columnOptions: {
          sortable: false,
          cellComponent: ComponentWithErrorCell,
          valueGetter: (params) => {
            return {
              errorMessage: '',
              value: params.data?.task?.completedQuantity,
              params: {
                enabled: true,
                value: params.data?.task?.completedQuantity,
                rowIndex: params.node.rowIndex
              },
              componentType: editableViewCell
            };
          },
          editable: true,
          cellEditor:'editableCell',
          cellEditorParams: (params) => ({
            inputType: 'numeric',
            isInt: true,
            maxLength: params.data?.task?.quantity,
          }),
          // onCellValueChanged: function (params) {
          //   params.newValue.onRowsChanged()
          // },
        }
      },
      {
        fieldName: 'task.quantity',
        title: this.labels.get('columns.quantity.title'),
        filterType: filterTypes.NONE,
        columnOptions: {
          sortable: false,
          cellComponent: LabelWithTooltipCell,
        },
      },
    ];
    return columnsConfig;
  };




  render() {
    return (
      <div className="tasks-assignments-grid">
        <Grid gridName={gridsNames.TASKS_ASSIGNMENTS}
              columnsConfig={this.columnsConfig}
              filterConfig={this.filterConfig}
              actions={this.props.actions}

              gridProps={
                {
                  checkboxSelection: false
                }
              }
        >
        </Grid>
      </div>
    );
  }
}

export default reduxForm({
    form: 'tasksAssignmentsGrid'
  }
)(TasksAssignmentsGrid);
