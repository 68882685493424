import { List, Map } from 'immutable';
import { dialogsNames } from '../../../enums/dialogsNames';
import defaultDialogReducer from '../../defaultDialogReducer';
import { actionTypes as actionTypes } from '../../../actions/Dialogs/ProcessTemperatureLogDialog/processTemperatureLogDialogActions';

let defaultState = {
  show: false,
  loading: false,
  errors: null,
  dialogData: null,
  logFileData: null,
};

export default function (state = Map(defaultState), action) {
  switch (action.type) {
    case actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_SHOW:
      return state.set('preselectedAssets', List(action?.payload?.preselectedAssets)).set('show', true);

    case actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_FILE_PROCESSED:
      return state.set('logFileData', action?.payload);

    case actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_FILE_HAS_ERRORS:
      return state.set('errors', action?.payload);

    case actionTypes.PROCESS_TEMPERATURE_LOG_DIALOG_FILE_CLEAR:
      state = state.set('logFileData', null);
      state = state.set('errors', null);
      return state;

    default:
      return defaultDialogReducer(state, action, dialogsNames.PROCESS_TEMPERATURE_LOG_DIALOG, defaultState);
  }
}
