import Network from 'infrastructure/js/modules/network';
import * as navigationService from '../../services/Navigation/navigationService';
import * as locationsService from '../../services/Locations/locationsService';
import { navigationStates } from '../../enums/navigationStates';
import { getPathByType } from '../../components/Common/Layout/Link/link';
import { api as systemApi } from '../System/systemActions';

/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  TOPNAVIGATOR_SETDATA: 'TOPNAVIGATOR_SETDATA',
  TOPNAVIGATOR_SET_HOME_PAGE: 'TOPNAVIGATOR_SET_HOME_PAGE',
  TOPNAVIGATOR_FETCH_HOME_PAGE_FINISHED: 'TOPNAVIGATOR_FETCH_HOME_PAGE_FINISHED',
  TOPNAVIGATOR_FETCH_LOCATIONS_FINISHED: 'TOPNAVIGATOR_FETCH_LOCATIONS_FINISHED',
  TOPNAVIGATOR_LOADING_MODE_CHANGED: 'TOPNAVIGATOR_LOADING_MODE_CHANGED',
};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  setData: function (payload) {
    return { type: actionTypes.TOPNAVIGATOR_SETDATA, payload };
  },
  setHomePage: function (payload) {
    return { type: actionTypes.TOPNAVIGATOR_SET_HOME_PAGE, payload };
  },
  fetchLocationsFinished: function (payload) {
    return { type: actionTypes.TOPNAVIGATOR_FETCH_LOCATIONS_FINISHED, payload };
  },
  setLoading: function (loading) {
    return { type: actionTypes.TOPNAVIGATOR_LOADING_MODE_CHANGED, payload: loading };
  },
};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.fetchLocations = function () {
  return function (dispatch, getState) {
    api.fetchLocations(dispatch, getState)();
  };
};

jsxActions.fetchHomePage = function () {
  return function (dispatch, getState) {
    api.fetchHomePage(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.setLoading = function (isLoading) {
  return function (dispatch, getState) {
    dispatch(actions.setLoading(isLoading));
    systemApi.setLoading(isLoading)(dispatch, getState);
  };
};

api.setData = function (dispatch, getState) {
  return function (combinedLocationInitialData) {
    dispatch(actions.setData(combinedLocationInitialData));
  };
};

api.setHomePage = function (dispatch, getState) {
  return function (type, id) {
    dispatch(actions.setHomePage({ type, id }));
  };
};

api.setHomePageWithServerUpdate = function (dispatch, getState) {
  return function (type, id) {
    dispatch(actions.setHomePage({ type, id }));

    const requestBody = {
      defaultEntityType: type,
      defaultEntityId: id,
    }

    return navigationService.setHomePage(requestBody).then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('set home page on server failed');
        return { success: false };
      }
    });
  };
};

api.fetchHomePage = function (dispatch, getState) {
  return function () {
    return navigationService.getHomePage().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetch home page failed');
        return { success: false };
      }

      const { defaultEntityType, defaultEntityId } = response.data;
      api.setHomePage(dispatch, getState)(defaultEntityType, defaultEntityId);
    });
  };
};

api.fetchLocations = function (dispatch, getState) {
  return function () {
    let method = utility.isHideInactiveLocations(getState) ? locationsService.fetchActiveLocations : locationsService.fetchAllLocations;
    return method().then((response) => {
      if (!Network.isResponseValid(response)) {
        console.error('fetch location failed');
        return { success: false };
      }

      let searchResultsData = utility.ConvertSearchResponseToMenuItems(response.dataList);

      dispatch(actions.fetchLocationsFinished(searchResultsData));
    });
  };
};

let utility = {};

utility.ConvertSearchResponseToMenuItems = function (originalDataItems) {
  return originalDataItems.map((obj) => {
    let { name, id } = obj;
    let itemName = obj.isActive === false ? obj.name + ' (Inactive)' : obj.name; //TODO: L where add the localized '(Inactive)' (navigatorMenuItem)?
    return {
      text: itemName,
      icon: obj.isStation ? 'station-icon' : 'location-icon-blue',
      id,
      link: getPathByType(navigationStates.LOCATION, id),
      isActive: obj.isActive,
    };
  });
};

utility.getIconByType = function (type) {
  switch (type.toUpperCase()) {
    case navigationStates.ROLL:
    case navigationStates.ALERTED_ROLLS:
    case navigationStates.ARCHIVED_ROLLS:
    case navigationStates.ACTIVE_ROLLS:
    case navigationStates.RAW_MATERIALS:
      return 'header-icon-roll';
    case navigationStates.SPOOL:
    case navigationStates.ACTIVE_SPOOLS:
    case navigationStates.ALERTED_SPOOLS:
    case navigationStates.ARCHIVED_SPOOLS:
      return 'header-icon-spool';
    case navigationStates.KIT:
    case navigationStates.KITS:
    case navigationStates.ALERTED_KITS:
    case navigationStates.ARCHIVED_KITS:
    case navigationStates.ACTIVE_KITS:
      return 'header-icon-kit';
    case navigationStates.PART:
    case navigationStates.PARTS:
    case navigationStates.ALERTED_PARTS:
    case navigationStates.ARCHIVED_PARTS:
    case navigationStates.ACTIVE_PARTS:
      return 'header-icon-part';
    case navigationStates.GROUP:
    case navigationStates.GROUPS:
    case navigationStates.ACTIVE_GROUPS:
    case navigationStates.ALERTED_GROUPS:
    case navigationStates.ARCHIVED_GROUPS:
      return 'header-icon-container';
    case navigationStates.TOOL:
    case navigationStates.TOOLS:
    case navigationStates.ACTIVE_TOOLS:
    case navigationStates.ARCHIVED_TOOLS:
      return 'menu-active-header-tool';
    case navigationStates.WORKORDER:
    case navigationStates.WORKORDERS:
    case navigationStates.OPEN_WORKORDERS:
    case navigationStates.COMPLETED_WORKORDERS:
      return 'header-icon-work_order';
    case navigationStates.STATION:
      return 'station-icon';
    case navigationStates.LOCATION:
      return 'location-icon-blue';
    case navigationStates.USER_MANAGEMENT:
      return 'settings-icon-blue';
    case navigationStates.NOTIFICATIONS:
      return 'notifications';
    case navigationStates.PRODUCTION_DASHBOARD:
    case navigationStates.PROGRESS_DASHBOARD:
    case navigationStates.INVENTORY_DASHBOARD:
    case navigationStates.TOOL_MAINTENANCE_DASHBOARD:
    case navigationStates.MATERIAL_SCRAP_VIEW:
      return 'chart';
    case navigationStates.SMART_SELECTION:
    case navigationStates.PICK_LISTS:
    case navigationStates.PICK_LIST:
      return 'smart-selection-header';
    case navigationStates.SCHEDULER_GANTT:
    case navigationStates.SCHEDULER_TASKS:
      return 'header-icon-scheduler';
    case navigationStates.ON_HAND_INVENTORY:
      return 'inventory';
    default:
      return '';
  }
};

utility.isHideInactiveLocations = function (getState) {
  let loggedInUser = getState().login.get('loggedInUser');
  return loggedInUser ? loggedInUser?.loggedInOrg?.orgPreferences?.hideInactiveLocations : false;
};
