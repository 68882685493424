import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jsxActions as messageDialogActions } from '../../actions/MessageDialog/messageDialogActions';
import {createLabelHelper} from "infrastructure/js/utils/labelHelper";

require('./errorBoundary.scss');
class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.renderDialog();
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }
  renderDialog = ()=>{
    this.props.actions.messageDialogActions.open(this.getErrorMessage());
  };

  getErrorMessage =() => {
    let title =  this.dialogLabels.get('error');
    let type = 'error';
    let children = (<label className='error-boundary-error-message'>{this.dialogLabels.get('messagedialog.unexpectedError')} <a target="_blank" href="https://plataine.zendesk.com/">{this.dialogLabels.get('messagedialog.supportPortal')}</a></label>);
    let buttons = [
      {id:'close',text:  this.dialogLabels.get('close'), action: this.props.actions.messageDialogActions.onHide, bsStyle: 'default'},
    ];
    let messageDialogDescriptor = {title, buttons, children,  className: '', type};
    return messageDialogDescriptor;
  };

  render() {
    if (this.state.errorInfo) {
      return null;
    }
    // Normally, just render children
    return this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      messageDialogActions: bindActionCreators(messageDialogActions, dispatch),
    }
  }
}

let ErrorBoundaryContainer = connect(null, mapDispatchToProps)(ErrorBoundary);
export default ErrorBoundaryContainer;
