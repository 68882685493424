import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './doubleRangeSlider.scss';
import {Field} from 'redux-form';

const PL_DoubleRangeSlider = (props) => {
  return <Field {...props} component={DoubleRangeSlider} />;
};


export const DoubleRangeSlider = ({ min, max, onChangeCallback, input }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);
  const leftValRef = useRef(null);
  const rightValRef = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (input?.value) {
      minValRef.current = input?.value?.min;
      setMinVal(input.value.min);

      maxValRef.current = input?.value?.max;
      setMaxVal(input.value.max);
    }
  }, [input.value]);


  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
      if (leftValRef.current) {
        leftValRef.current.style.left = `${minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }

    if (rightValRef.current) {
      rightValRef.current.style.right = `${100 - maxPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChangeCallback?.({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChangeCallback]);

  return (
    <div className="double-range-slider">
      <input
        type="range"
        min={min}
        max={max}
        // value={minVal}
        value={input?.value?.min}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
          input.onChange({min: value, max: maxVal});
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        // value={maxVal}
        value={input?.value?.max}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
          input.onChange({min: minVal, max: value});
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__start-value">{min}</div>
        <div className="slider__end-value">{max}</div>
        <div ref={leftValRef} className="slider__left-value">{minVal}</div>
        <div ref={rightValRef} className="slider__right-value">{maxVal}</div>
      </div>
    </div>
  );
};

DoubleRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PL_DoubleRangeSlider;
