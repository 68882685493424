import React from 'react';

import DetailsRow from './detailsRow.js';

require('./detailsColumn.scss');

export default class DetailsColumn extends React.PureComponent {

  render() {
    if (!this.props.rows) {
      return null;
    }

    return (<div className="column">
      {this.props.rows.map((object, index) => {
        return <DetailsRow className={object.className} label={object.label} tooltip={object.tooltip} key={"details-row" + index} type={object.type} button={object.button}>
          {object.body}
        </DetailsRow>
      })}
    </div>)
  }
}



