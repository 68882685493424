// @ts-check

import Network from '../../../../infrastructure/js/modules/network';

import * as liveSearchService from '../LiveSearch/liveSearchService';

/**
 * @typedef {import('mat/js/types/ServerTypes/Models/LiveSearch').LiveSearchRequestModel} LiveSearchRequestModel
 * 
 * @typedef {import('mat/js/types/ServerTypes/Models/LiveMap').R_LivemapSettingResponseModel} R_LivemapSettingResponseModel
 * @typedef {import('mat/js/types/ServerTypes/Models/LiveMap').R_LivemapFullStatusResponseModel} R_LivemapFullStatusResponseModel
 * 
 * 
 * 
 */



/**
 * @param {{ externalId: string; }} data
 */
export function fetchLocationDetails(data) {
  
  let externalId = data.externalId;
  if (!externalId) {
    return;
  }
  
  
  let requestModel = {
    locationName: externalId
  };
  
  return Network.post(`livemap/location-details`, requestModel);
}


/**
 * @param {any} data
 */
export function fetchMapMenuItems(data) {
  return Network.get(`livemap/settings/menu-items`);
}

/**
 * @param {number | string} mapId
 * 
 * @returns {Promise<R_LivemapSettingResponseModel> | undefined}
 */
export function fetchMapSetting(mapId) {
  return Network.get(`livemap/settings/${mapId}`);
}


/**
 * 
 * @returns {Promise<R_LivemapFullStatusResponseModel> | undefined}
 */
export function fetchFullStatus() {
  return Network.post(`livemap/full-status`);
}


/**
 * @param {LiveSearchRequestModel} requestModel
 */
export function searchMap(requestModel) {
  return liveSearchService.liveSearch(requestModel);
}






