import React from 'react';
import cn from 'classnames';
import Popover from 'infrastructure/js/components/popover/Popover/popover';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import Label from 'infrastructure/js/components/Label/label.js';
import {isKit, isResin, isRoll, isSpool} from '../../../../utils/assetHelper.js';
import MaterialHelper from '../../../../utils/materialHelper';

require('./relatedAssetsCell.scss');

export default class RelatedAssetsCell extends React.PureComponent {

  constructor (props){
    super(props);

    this.state = {showIcon: false, relatedAssets: null, triggers: [null]};
  }

  onEnterHandler = () => {
    this.setState({showIcon: true});
  };

  onExitHandler = () => {
    this.setState({showIcon: false});
  };


  getAssetDetails = (asset) => {
    if (isRoll(asset.objectType) || isSpool(asset.objectType) || isResin(asset.objectType)) {
      return MaterialHelper.getMaterialFullLabel(asset.material?.materialName, asset.material?.businessId);
    }
    if (isKit(asset.objectType) && asset.kitType) {
      return asset.kitType.businessId;
    }
    return '';
  };

  renderAssetDetails = (asset) => {
    if (asset) {
      let assetDetails = this.getAssetDetails(asset);
      return (<span className="asset-metadata">
              <Label text={assetDetails} />
            </span>);
    }
    return null;
  }

  getAssets = (assets, title) => {
    if (!assets || assets.length < 1 ) {
      return null;
    }

    let assetsList = assets.map((asset, index) => {
              let hasDetails = !!this.getAssetDetails(asset);
              return(
                <div className={cn('asset-data', {'no-details': !hasDetails})} key={'assetData' + index}>
                  <div className="related-asset-header">
                    <Label className="asset-id" text={asset.businessId}/>
                    {hasDetails && <span className="separator">-</span>}
                    {hasDetails && this.renderAssetDetails(asset)}
                  </div>
                  {this.renderOperationDates(asset)}
                </div>
              );
      });
    return <>
      <div className="related-asset-title">{title}</div>
      {assetsList}
    </>
  }

  getPopoverComponent = (value) => {

    return <>
      {this.state.relatedAssets && this.getAssets(this.state.relatedAssets.componentAssets, value.componentAssetsTitle)}
      {this.state.relatedAssets && this.getAssets(this.state.relatedAssets.resultingAssets, value.resultingAssetsTitle)}
    </>
  };

  renderOperationDates = (assetData) => {
    if(assetData && assetData.relationCreationDate) {
      return (
        <span key={'date' + assetData.businessId} className="operation-date">
          {`on: ${DateTimeHelper.DateTimeFormat(assetData.relationCreationDate.epochDateTime * 1000)}`}
        </span>
      );
    }
    return null;
  };

  onIconClickHandler = () => {
    document.body.click()

    let {value} = this.props;

    if (!this.state.relatedAssets && value.cellAction && value.assetId) {
      value.cellAction(value.assetId, this.cellActionCallback);
    }
  };

  cellActionCallback = (data) => {
    if (data) {
      this.setState({ relatedAssets: data, triggers: ['click']}, () => {
        if (this.spanRef) {
          this.spanRef.click()
        }
      });
    }
  };

  setSpanRef = (r) => {
    this.spanRef = r || this.spanRef;
  };

  getIconPopOver = (value) => {
    if (value.count === 0) {
      return null;
    }

    let iconClassName = this.state.showIcon ? 'pl pl-info-icon-blue visible' : 'pl pl-info-icon-blue';
    return (<Popover
      className="common-related-assets-popover"
      trigger={this.state.triggers}
      popoverId={"alertPopover"}
      popoverTitle={''}
      popoverComponent={this.getPopoverComponent(value)}
      onEnter={this.onEnterHandler}
      onExit={this.onExitHandler}
    >
      <span className={iconClassName} onClick={this.onIconClickHandler} ref={this.setSpanRef}/>
    </Popover>);
  }

  getCount(value){
    //if there is no value, display none.
    return value.count === 0? null : value.count;
  }

  render() {
    let {value} = this.props;

    return (<div className="common-related-assets-cell ">
      <span className="assets-count">{this.getCount(value)}</span>
      {this.getIconPopOver(value)}
    </div>);
  }
}
