import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header, { headerItemsLabels } from '../../Common/Header/header';
import { gridsNames } from '../../../enums/gridsNames';
import { FetchEntitiesFilters } from '../../../enums/fetchEntitiesFilters';
import { ImportTypes, ImportAcceptFileTypes } from '../../../enums/importTypes';
import { PL_DialogWrapper } from 'infrastructure/js/components/Dialog/dialog';
import ShelfLifeUpdateDialog from '../../Dialogs/ShelfLifeUpdateDialog/shelfLifeUpdateDialog';
import ArchiveAssetsDialog from '../../Dialogs/ArchiveAssetsDialog/archiveAssetsDialog';
import MoveAssetsDialog from '../../Dialogs/MoveAssetsDialog/moveAssetsDialog';
import EditAssetAttributesDialog from '../../Dialogs/EditAssetAttributesDialog/editAssetAttributesDialog';
import PrintRfidTagsDialog from '../../Dialogs/PrintRfidTagsDialog/printRfidTagsDialog';
import PrintPdfLabelsDialog from '../../Dialogs/PrintPdfLabelsDialog/printPdfLabelsDialog';
import { getEnumValue, enumTypes } from '../../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import ImportAttributesUpdateWizard from '../../Wizards/ImportAttributesUpdate/importAttributesUpdateWizard';
import ActiveResinsGrid from './ActiveResinsGrid/activeResinsGrid';

require('./activeResinsPage.scss');

export default class ActiveResinsPage extends Component {
  constructor(props) {
    super(props);
  }


  getDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ACTIVE,
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getMoveAssetsDialogConfig = () => {
    return {
      boEntityFilter: [FetchEntitiesFilters.ACTIVE],
      entitiesTypes: [getEnumValue(enumTypes.OBJECT_TYPE)('RESIN')],
      preselectedAssets: this.props.sSelectedRowsData,
    };
  };

  getImportConfig = () => {
    let config = {
      importTypes: [ImportTypes.IMPORT_RESINS],
      acceptFileTypes: [ImportAcceptFileTypes.CSV],
      parentComponent: this.reloadPage,
      shouldIncludeLocation: true, //import is done NOT from a location or station , the import file locationId column is mandatory.
      showPrintLabels: true,
    };
    return config;
  };

  getPrintPdfLabelsDialogConfig = () => {
    return {
      // boEntityFilter: BO_EntityFilters.ALL,
      boEntityFilter: [FetchEntitiesFilters.ALL],
      preselectedAssets: this.props.sSelectedRowsData,
      entitiesTypes: [
        getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'),
        getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'),
      ],
    };
  };

  getHeaderItems() {
    let { actions, sHasRows, sSelectedRowsData } = this.props;
    let dialogConfig = this.getDialogConfig();
    let importConfig = this.getImportConfig();

    let items = {
      buttons: [
        {
          id: 'clearFilters',
          label: '',
          icon: 'pl pl-filter-clear',
          action: actions.clearFilters,
          tooltip: headerItemsLabels.CLEAR_FILTERS,
        },
        {
          id: 'refresh',
          label: '',
          icon: 'pl pl-refresh',
          action: this.reloadPage,
          tooltip: headerItemsLabels.REFRESH_ALL,
        },
        ...(PermissionManager.hasEditAttributesPermissions()
          ? [
              {
                id: 'editAttributes',
                label: headerItemsLabels.EDIT_ATTRIBUTES,
                icon: 'pl pl-edit-icon',
                counter: sSelectedRowsData.size,
                disabled: !sHasRows,
                action: actions.header.onMenuItemEditAssetAttributesClick,
                actionData: dialogConfig,
              },
            ]
          : []),
        {
          id: 'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT,
          disabled: !sHasRows,
          action: actions.exportActions.export,
          actionData: { gridName: gridsNames.PREDEFINED_LIST_ACTIVE_RESINS },
        },
      ],

      kebab: {
        menuItems: [
          {
            id: 'moveTo',
            label: headerItemsLabels.MOVE_TO,
            action: actions.header.onMenuItemMoveToClick,
            actionData: this.getMoveAssetsDialogConfig(),
            disabled: !sHasRows,
          },
          ...(PermissionManager.hasArchiveAssetsPermissions()
            ? [
                {
                  id: 'archive',
                  label: headerItemsLabels.ARCHIVE,
                  action: actions.header.onMenuItemArchiveClick,
                  actionData: dialogConfig,
                  disabled: !sHasRows,
                },
              ]
            : []),
          ...(PermissionManager.hasImportAssetsPermissions()
            ? [
                {
                  id: 'importResins',
                  label: headerItemsLabels.IMPORT_RESINS,
                  action: actions.header.importItems,
                  actionData: importConfig,
                },
              ]
            : []),
          ...(PermissionManager.hasEditAttributesPermissions()
            ? [
                {
                  id: 'importAttributesUpdate',
                  label: headerItemsLabels.IMPORT_ATTRIBUTES_UPDATE,
                  action: actions.header.onMenuItemImportAttributesUpdateClick,
                  actionData: { importType: getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_RESIN') },
                },
              ]
            : []),
          {
            id: 'printLabels',
            label: headerItemsLabels.PRINT_LABEL,
            action: actions.header.onMenuItemPrintLabelClick,
            actionData: this.getPrintPdfLabelsDialogConfig(),
            disabled: !sHasRows,
          },
          {
            id: 'printRfidTags',
            label: headerItemsLabels.PRINT_RFID_TAGS,
            action: actions.header.onMenuItemPrintRfidTagsClick,
            actionData: dialogConfig,
            disabled: !sHasRows,
          },
        ],
      },
    };

    if (PermissionManager.hasUpdateShelfLifePermissions()) {
      items.kebab.menuItems.splice(1, 0, {
        id: 'upadteShelfLife',
        label: headerItemsLabels.UPDATE_SELF_LIFE,
        action: actions.header.onUpdateShelfLifeClick,
        actionData: dialogConfig,
        disabled: !sHasRows,
      });
    }

    return items;
  }

  reloadPage = () => {
    this.props.actions.reload();
  };

  renderEditAssetAttributesDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={EditAssetAttributesDialog}
        show={this.props.sEditAssetAttributesData.get('show')}
        actions={this.props.actions.editAssetAttributesActions}
        sData={this.props.sEditAssetAttributesData}
        reloadParentComponent={this.reloadPage}
      />
    );
  };

  renderMoveAssetsDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={MoveAssetsDialog}
        show={this.props.sMoveAssetsData.get('show')}
        actions={this.props.actions.moveAssetsActions}
        sData={this.props.sMoveAssetsData}
        reloadParentComponent={this.reloadPage}
      />
    );
  };

  renderShelfLifeUpdateDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={ShelfLifeUpdateDialog}
        show={this.props.sShelfLifeUpdateData.get('show')}
        actions={this.props.actions.shelfLifeUpdateActions}
        sData={this.props.sShelfLifeUpdateData}
        reloadParentComponent={this.reloadPage}
      />
    );
  };

  renderArchiveAssetsDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={ArchiveAssetsDialog}
        show={this.props.sArchiveAssetsData.get('show')}
        sData={this.props.sArchiveAssetsData}
        actions={this.props.actions.archiveAssetsActions}
        reloadParentComponent={this.reloadPage}
      />
    );
  };

  renderPrintRfidTagsDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={PrintRfidTagsDialog}
        show={this.props.sPrintRfidTagsDialogData.get('show')}
        actions={{...this.props.actions.printRfidTagsActions, ...this.props.actions.fetchEntitiesActions}}
        sData={this.props.sPrintRfidTagsDialogData}
        currentAsset={this.props.assetDetails}
        reloadParentComponent={this.reloadPage}
      />
    );
  };

  renderPrintPdfLabelsDialog = () => {
    return (
      <PL_DialogWrapper
        dialogComponent={PrintPdfLabelsDialog}
        show={this.props.sPrintPdfLabelsData.get('show')}
        actions={this.props.actions.printPdfLabelsActions}
        sData={this.props.sPrintPdfLabelsData}
        reloadParentComponent={this.reloadPage}
      />
    );
  };
  renderImportAttributesUpdateWizardDialog = () => {
    let sData = this.props.sImportAttributesUpdateWizardData;

    if (sData && sData.get('show')) {
      return (
        <ImportAttributesUpdateWizard
          // show={sData.get('show')}
          importType={getEnumValue(enumTypes.IMPORT_TYPE)('UPDATE_RESIN')}
          actions={this.props.actions.importAttributesUpdateWizardActions}
          sData={sData}
          reloadParentComponent={this.reloadPage}
        />
      );
    }
    return null;
  };

  render() {
    let headerItems = this.getHeaderItems();
    return (
      <div className="active-resins-page">
        <Header {...headerItems} />

        <ActiveResinsGrid
          actions={{
            ...this.props.actions.activeResinsGridActions,
            ...this.props.actions.fetchEntitiesActions,
          }}
        />
        {this.renderEditAssetAttributesDialog()}
        {this.renderMoveAssetsDialog()}
        {this.renderShelfLifeUpdateDialog()}
        {this.renderArchiveAssetsDialog()}
        {this.renderPrintRfidTagsDialog()}
        {this.renderPrintPdfLabelsDialog()}
        {this.renderImportAttributesUpdateWizardDialog()}
      </div>
    );
  }
}

ActiveResinsPage.propTypes = {
  actions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool.isRequired,
  sSelectedRowsData: PropTypes.object,
};
