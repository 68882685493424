import React from 'react';
import PropTypes from 'prop-types';
import Button from 'infrastructure/js/components/controls/Button/button';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import ActivityLogDialogGrid from './ActivityLogDialogGrid/activityLogDialogGrid';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import TasksAssignmentsGrid from './TasksAssignmentsGrid/tasksAssignmentsGrid';

import'./activityLogDialog.scss';


export default class ActivityLogDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.labels = createLabelHelper('mat.dialogs.activityLog.');
    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.state = {isEditMode: false, editable: false}
  }

  getDialogButtons() {
    return {
      left: [],
      right: [
        ...(this.state.isEditMode ? [
          {
            id:'saveBtn',
            text: this.dialogLabels.get('save'),
            bsStyle: 'primary',
            disabled: this.props.loading || !this.props.sHasRows,
            loading: this.props.loading,
            action: this.onSaveHandler
          }] : []),
          {
            id:'cancelBtn',
            text: this.dialogLabels.get('close'),
            bsStyle: 'primary',
            action: this.onHide
          },
      ]
    };
  }

  onEntered = () => {
    let jobName = this.props.data.get('jobName');
    this.setState({editable: (jobName === 'updateTasksProgressJob_completed')});
  };
  onHide = () => {
    this.setState({isEditMode: false, editable: false});
    this.props.actions.hide();
  };

  onSaveHandler = () => {
    this.props.actions.updateCompletedTasks();
  }

  renderHeader = () => {
    if (this.state.editable) {
      let {isEditMode} = this.state;

      let editStr = this.labels.get('editCompletedTasks');
      let viewStr = this.labels.get('viewCompletedTasks');

      return (
        <div className={'activity-log-header'}>
          <label className="activity-log-header__label">{isEditMode ? editStr : viewStr}</label>

          <Button id="viewModeBtn" className="link"
                  disabled={isEditMode ? this.props.loading : false}
                  onClick={ () => {this.setState((prevState) => ({ isEditMode: !prevState.isEditMode }))} }
          >
            {isEditMode ? viewStr : editStr}
          </Button>
        </div>
      );
    }
    return null;
  }

  render() {
    let { show, data, activityLogGridActions, tasksAssignmentsGridActions} = this.props;
    if (!show) {
      return null;
    }
    let title = data.get('activityLogTitle');

    return (
      <Dialog
        id="activity-log-dialog"
        className="activity-log-dialog"
        show={show}
        onEntered={this.onEntered}
        onHide={this.onHide}
        titleText={title}
        footerButtons={this.getDialogButtons()}
      >
        {this.renderHeader()}
        { !this.state.isEditMode &&<ActivityLogDialogGrid actions={activityLogGridActions} />}
        { this.state.isEditMode && <TasksAssignmentsGrid actions={tasksAssignmentsGridActions} />}

      </Dialog>
    )
  }
}

ActivityLogDialog.defaultProps = {
  loading: false,
  sHasRows: false,
}

ActivityLogDialog.propTypes = {
  loading: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  activityLogGridActions: PropTypes.object.isRequired,
  tasksAssignmentsGridActions: PropTypes.object.isRequired,
  sHasRows: PropTypes.bool,
};




