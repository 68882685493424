import { useMemo } from 'react';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import { gridsNames } from '../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import { EntityPropertyTypes } from '../../../../enums/entityPropertyTypes';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import CheckMarkCell from '../../../Common/CustomGridCells/CheckMarkCell/checkMarkCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';
import { navigationStates } from '../../../../enums/navigationStates';

import './lotsGrid.scss';

const filterConfig = [
  { fieldName: 'name', filterName: 'lotName' },
  { fieldName: 'createdDate', filterName: 'createdDate', getOptions: false },
  { fieldName: 'createdByUser', filterName: 'createdByUser', getOptions: false },
];

export default function LotsGrid({ actions }) {
  const lables = useMemo(() => createLabelHelper('mat.grid.'), []);

  const columnsConfig = [
    {
      fieldName: 'name',
      title: lables.get('columns.lotId.title'),
      filterType: filterTypes.MULTI_SELECT_ASYNC,
      filterName: 'lotName',
      fetchConfig: {
        entityType: EntityPropertyTypes.LOT_NAME,
        action: actions.fetchEntities,
      },
      columnOptions: {
        cellComponent: EntityIdCell,
        valueGetter: (params) => ({
          id: params.data.id,
          label: params.data.name,
          type: navigationStates.LOT,
        }),
      },
    },
    {
      fieldName: 'createdDate',
      title: lables.get('columns.creationDate.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
        valueGetter: (params) => {
          return params.data.createdDate ? DateTimeHelper.FormatDateObjectToDayMonth(params.data.createdDate) : '';
        },
        headerComponentParams: GridHelper.getDateFormatHeaderIcon(),
      },
    },
    {
      fieldName: 'createdByUser',
      title: lables.get('columns.createdBy.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        cellComponent: LabelWithTooltipCell,
      },
    },
    {
      fieldName: 'hasAttachments',
      title: lables.get('columns.attachments.title'),
      filterType: filterTypes.NONE,
      columnOptions: {
        sortable: false,
        cellComponent: CheckMarkCell,
      },
    },
  ];

  return (
    <div className="lots-grid">
      <Grid gridName={gridsNames.PREDEFINED_LIST_LOTS} columnsConfig={columnsConfig} filterConfig={filterConfig} actions={actions} />
    </div>
  );
}
