import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import InputSection from 'infrastructure/js/components/Dialog/InputSection/inputSection';
import TextField from 'infrastructure/js/components/controls/TextField/textField';
import Checkbox from 'infrastructure/js/components/controls/Checkbox/checkbox.js';
import Combobox from 'infrastructure/js/components/controls/Combobox/combobox.js';
import Validation from 'infrastructure/js/components/controls/controlsValidations';
import Normalize from 'infrastructure/js/components/controls/controlsNormalizations';
import AddRemoveListExt from '../../../../../Common/Controls/AddRemoveListExt/addRemoveListExt';
import CsvPropertyListItem from '../CsvPropertyListItem/csvPropertyListItem';
import Header, {headerItemsLabels} from '../../../../../Common/Header/header';
import {numberToLetters} from '../../../utils/csvSettingsHelper';
import Parse      from 'infrastructure/js/components/controls/controlsParse';
import Format     from 'infrastructure/js/components/controls/controlsFormat';
import {LabelKeys} from '../../../utils/csvSettingsHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';


require('./commonCsvSettingsForm.scss');

class CommonCsvSettingsForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.labels = this.props.labels;
    this.entityLabels = createLabelHelper('mat.entity.type.');

    this.mergingColumnsOptions = this.initMergingColumnsOptions();

    this.state = { isMergeColumns: false};
  }

  initMergingColumnsOptions = () => {
    let res = [];
    for (let i=1; i<27; i++) {
      res.push({value: i, label: numberToLetters(i)});
    }
    return res;
  };

  componentDidMount() {
    this.initFormValues();
  }

  initFormValues = () => {
    let initialValues = this.getInitialValues(this.props.formData);

    this.props.initialize(initialValues);

    this.setState({isMergeColumns: !!initialValues.mergeColumns});
  };

  getInitialValues = (items) => {
    if (items && items.csvData) {

      let initialValues = {
        mergeColumns: false,
        columnOne: {value: 1, label: numberToLetters(1)},
        columnTwo: {value: 2, label: numberToLetters(2)},
      };

      if (items.csvData.settings) {
        let csvPropertiesList = items.csvData.settings.map((item) => {
          return {propertyName: item.isAdditionalField ? item.additionalField.id : item.property.id,}
        });
        initialValues.csvPropertiesList = csvPropertiesList;
      }

      initialValues.startingRowNum = items.csvData.startingRowNum;

      if (this.props.formType === 'IMPORT') {
        let isMergeColumns = (items.csvData.mergedPropertiesIndexes && items.csvData.mergedPropertiesIndexes.length === 2);

        initialValues.mergeColumns = isMergeColumns;
        let columnOneIndex = isMergeColumns ? items.csvData.mergedPropertiesIndexes[0] : 1;
        let columnTwoIndex = isMergeColumns ? items.csvData.mergedPropertiesIndexes[1] : 2;
        initialValues.columnOne = columnOneIndex;
        initialValues.columnTwo = columnTwoIndex;
      }

      if (this.props.formType === 'BARCODE')
        initialValues.delimiter = items.csvData.delimiter;

      return initialValues;
    }
    return null;
  };

  getHeaderItems() {
    let items = {
      buttons: [
        {
          id:'save',
          label: headerItemsLabels.SAVE,
          className: 'no-icon',
          disabled: this.props.pristine,
          action: this.props.handleSubmit(this.onSubmit),
        },
      ],
    };

    if (this.props.formType === 'IMPORT') {
      items.buttons.unshift(
        {
          id: 'export',
          icon: 'pl pl-export-icon',
          tooltip: headerItemsLabels.EXPORT_TEMPLATE,
          disabled: !this.props.pristine,
          action: this.onExportCsvTemplate,
        },
      )
    }
    return items;
  }

  onExportCsvTemplate = () => {
    this.props.actions.exportActions.exportCsvTemplate(this.props.entityType, this.props.formData.csvData.startingRowNum , this.getExportMessageDialogBuilder());
  };


  onSubmit = (data) => {
    let newData = {
      startingRowNum: data.startingRowNum,
      settings: this.convertToSettings(data.csvPropertiesList)
    };

    if (this.props.formType === 'BARCODE')
      newData.delimiter = data.delimiter;

    if (this.props.formType === 'IMPORT') {
      newData.mergedPropertiesIndexes = this.getMergeColumnData(data);
    }

    this.props.actions.submit(newData, this.props.entityType, this.props.formType, this.getMessageDialogBuilder())
      .then((response) => {
      if (response && response.success) { //Initialize form values and reset pristine.
        setTimeout(() => {this.initFormValues()} );
      }
    });
  };

  convertToSettings = (csvPropertiesList) => {
    let res = csvPropertiesList.map((item, index) => {
      let matchOption = this.props.formData.csvOptions.find((option) =>{return option.value === item.propertyName});
      return {
          optionId: item.propertyName,
          columnChar: numberToLetters(index+1),
          columnIndex: index+1,
          isAdditionalField: matchOption ? matchOption.data.isAdditionalField : false
        };
    }).filter((item) => {return !!item.optionId});

    return res;
  };

  getMergeColumnData = (data) => {
    if (data && data.mergeColumns && data.columnOne && data.columnTwo) {
      return [data.columnOne, data.columnTwo];
    }
    return null;
  };

  getExportMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {
      let title = this.props.labels.get('export.startingRowNumWarning');
      let className = 'oneBackground';
      return {title, className};
    };
  };

  getMessageDialogBuilder = () => {
    return (response, fnCloseDialog) => {

      let {entityType, labels} = this.props;
      let entityLabel = this.entityLabels.get( LabelKeys[entityType]);
      if (!entityLabel) {
        console.error('getMessageDialogBuilder(): unknown entity type ' + entityType);
      }
      let title = labels.get('confirmation.success' ,'', {entityType: entityLabel?.toLowerCase()});
      let className = 'oneBackground';
      return {title, className};
    };
  };

  onMergeColumnsClick = (value) => {
    this.setState({isMergeColumns: !this.state.isMergeColumns});
  };

  onColumnOneChangeCallback = (newValue, oldValue) => {
    if ( !newValue && oldValue && oldValue.value) {
      //prevent clearing
      this.props.change('columnOne', oldValue.value);
    }
  };
  onColumnTwoChangeCallback = (newValue, oldValue) => {
    if ( !newValue && oldValue && oldValue.value) {
      //prevent clearing
      this.props.change('columnTwo', oldValue.value);
    }
  };

  renderStartingRowSection = (formType) => {
    if (formType === 'BARCODE')
      return null;
    let sectionLabel = (formType === 'IMPORT') ? this.labels.get('startingRow.import') : this.labels.get('startingRow.export');
    let textFieldId = (formType === 'IMPORT') ? 'startingRowNum-import' : 'startingRowNum-export';

    return (
    <InputSection label={sectionLabel} htmlFor="startingRowNum" className="starting-row-section">
      <TextField id={textFieldId}
                 name={'startingRowNum'}
                 validate={Validation.required}
                 normalize={Normalize.number(true, 1, 999)}
                 className="short-textfield"/>
    </InputSection>);
  };

  renderDelimiterSection = (formType) => {
    if (formType === 'BARCODE') {
      return (
        <InputSection label={this.labels.get('delimiter')} htmlFor="startingRowNum" className="starting-row-section">
          <TextField id={'delimiter'}
                     name={'delimiter'}
                     validate={Validation.required}
                     maxLength={2}
                     className="short-textfield"/>
        </InputSection>);
    }
    return null;
  };

  renderMergeColumnsSection = (formType) => {
    if (formType === 'IMPORT') {
      return (
        <InputSection htmlFor="mergeColumns" className="merge-columns-section">
          <Checkbox name="mergeColumns" id="mergeColumns"
                    label={this.labels.get('mergeColumns.createId')}
                    onClick={this.onMergeColumnsClick}
          />

          {this.state.isMergeColumns &&
            <Combobox id="columnOne" name="columnOne"
                      options={this.mergingColumnsOptions}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(this.mergingColumnsOptions)}
                      onChangeCallback={this.onColumnOneChangeCallback}
            />}
          {this.state.isMergeColumns &&
            <Combobox id="columnTwo" name="columnTwo"
                      options={this.mergingColumnsOptions}
                      parse={Parse.comboValueOnly()}
                      format={Format.findOptionByValue(this.mergingColumnsOptions)}
                      onChangeCallback={this.onColumnTwoChangeCallback}
            />}
          {this.state.isMergeColumns &&
            <label className="example-label">{this.labels.get('mergeColumns.example')}</label>
          }
        </InputSection>
      );
    }
    return null;
  };

  render() {

    let {formData, formType} = this.props;
    if (!formData) {
      return null;
    }

    let allOptions = formData.csvOptions;
    let settings = formData.csvData.settings;
    let preSelectedItemsIds = settings ? settings.map((item) => {return item.isAdditionalField ? item.additionalField.id : item.property.id;}) : [];

    let headerItems = this.getHeaderItems();


    return (
      <div className='common-csv-settings-form'>
          <Header {...headerItems} className="csv-settings-form-header"/>

          <div className="config-section">
            {this.renderStartingRowSection(formType)}
            {this.renderDelimiterSection(formType)}
            {this.renderMergeColumnsSection(formType)}
          </div>

          <div className="csv-properties-list-header ">
            <label className="column">{this.labels.get('list.column.column')}</label>
            <label className="column">{this.labels.get('list.column.property')}</label>
          </div>

          <AddRemoveListExt
                            {...this.props}
                            name={'csvPropertiesList'}
                            itemRendererComponent={CsvPropertyListItem}
                            itemRendererOptions={allOptions}
                            preSelectedItemsIds={preSelectedItemsIds}
                            addButtonLabel={this.labels.get('list.button.add')}
                            itemRendererData={{ formType }}
          />

      </div>
    );
  }
}

export default reduxForm({
  //Note: {form: FORM-NAME"} is set by the parent component through the props
  }
)(CommonCsvSettingsForm);

CommonCsvSettingsForm.propTypes = {
  actions : PropTypes.object.isRequired,
  formData : PropTypes.object.isRequired,
  formType : PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
};




