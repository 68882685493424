import {useEffect} from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-bootstrap/Collapse';
import TriggerStartedIcon     from '../../../../assets/svg/trigger-started.svg';
import TriggerCompletedIcon     from '../../../../assets/svg/trigger-completed.svg';
import useRedux from 'infrastructure/js/hooks/useRedux';
import {jsxActions as lastTriggersActions} from '../../../actions/AppTopbar/lastTriggersActions';
import Overlay from 'infrastructure/js/components/Overlay/overlay';
import useLabels from 'infrastructure/js/hooks/useLabels';

import './lastTriggersPanel.scss';


const getState = (state) => {
  return {
    sData: state.appTopbar.get('lastTriggersData') ?? {},
  };
};

const allActions = {
  lastTriggersActions,
};

const LastTriggersPanel = ({open = false}) => {

  const labels = useLabels('mat.header.lastTriggers.panel.');
  const { state, actions } = useRedux(allActions, getState);

  useEffect(() => {
    if (open) {
      actions.lastTriggersActions.init();
    }
    return () => {
      actions.lastTriggersActions.unmount();
    }
  }, [open]);

  const renderLoadingOverlay = () => {
    if (!state?.sData?.get('loading')) {
      return null;
    }
    return <Overlay.Loading />;
  };

  const renderTriggers = () => {
    if (state?.sData?.get('loading')) {
      return null;
    }

    let lastTriggers = state?.sData?.get('triggersData') ?? [];

    if (lastTriggers?.length) {
      return lastTriggers.map((item, index) => {
        const StatusIcon = item.status === 'BUSY' ? TriggerStartedIcon : TriggerCompletedIcon;
        return (
          <div className='trigger-item' key={'triggers-item-' + index}>
            <StatusIcon />
            <div>{item.name}</div>
          </div>
        )
      });
    }
    return (<div className='no-triggers-placeholder'>{labels.get('noTriggers')}</div>)
  }

  return (
    <Collapse in={open} >
      <div className="last-triggers-panel"  >
        <div className='last-triggers-panel__header'>{labels.get('header.title')}</div>
        <div className='last-triggers-panel__body'>
          {renderTriggers()}
          {renderLoadingOverlay()}
        </div>
      </div>
    </Collapse>
  );
}

LastTriggersPanel.propTypes = {
  open: PropTypes.bool,
};

export default LastTriggersPanel;
