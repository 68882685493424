import Network from 'infrastructure/js/modules/network';

export function getShipmentsByDataQuery(query) {
  return Network.post(`shipments/items`, query);
}

export function confirmShipmentStatus(id) {
    return Network.post(`shipments/${id}/confirmshipment`)
}

export function rejectShipment(id) {
  return Network.put(`shipments/${id}/rejection`)
}

export function downloadShipment(id) {
  return Network.get(`shipments/${id}/document`,{}, {blobResponse:true})
}

export function addToShipment(data) {
  return Network.post('shipments/add-assets', data);
}

export function validateAssignment(data) {
  return Network.post('shipments/validate-assignment', data);
}
export function shipmentReadyToShip(shipmentId, locationId) {
  return Network.post(`shipments/${shipmentId}/asn-ready-to-ship`, {locationId});
}

export function markShipmentAsReceived(shipmentId, locationId) {
  return Network.post(`shipments/${shipmentId}/mark-as-received`, {locationId});
}

export function deleteShipment(shipmentId, locationId) {
  return Network.delete(`shipments/${shipmentId}/asn-delete`, {locationId});
}

export function shipToCustomer(data) {
  return Network.post('shipments/ship-to-customer', data);
}
export function uploadTemperatureLogFile(id, file) {
  return Network.postFile(`shipments/${id}/temp-log-file/upload`, file);
}

export function reduceShipmentAssetsEtls({id, ...body}) {
  return Network.put(`shipments/${id}/asset-etls`, body)
}
