// @ts-check

import { MapView } from "@mappedin/mappedin-js";
import { useEffect } from "react";
import MapControl from "../../MapControl/MapControl";

/**
 * @typedef {import("../../../../actions/LiveMap/types/liveMapPageTypes").LiveMapPageRefContext} LiveMapPageRefContext
 * 
 */



/**
 * @param {React.MutableRefObject<LiveMapPageRefContext>} refContext 
 * @param {MapView | undefined | null} mapView
 */
export function useSubscribeTomapReady(mapView, refContext) {

  useEffect(() => {

    if (mapView == null) {
      return;
    }

    // console.log('-- useWhenMapReady => ON MAP READY');
    MapControl.map_OnReady(refContext);


    // Cleanup
    return () => {
      // console.log('-- useWhenMapReady => ON MAP DESTROY');
      MapControl.map_OnDestroy(refContext);
    };

  }, [mapView]);

}

