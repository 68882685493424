import * as externalNotificationService from '../../../services/Notifications/externalNotificationService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import {createLabelHelper} from 'infrastructure/js/utils/labelHelper';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_USER_SETTINGS_EXTERNAL_NOTIFICATION, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query.pageSize = 50;
    return externalNotificationService.fetchExternalNotificationSettings(query);
  }
}function _convertToRowData(rows) {
  let labels = createLabelHelper('mat.activeToolsSchedule.grid.moreInfo.');

  let items = rows.map((obj, index) => {
    let {emailSettings, smsSettings, teamsSettings, slackSettings} = obj;
    obj.methods = [
      {title: 'E-mail:', label: emailSettings?.join(', ') || ''},
      {title: 'SMS:', label: smsSettings?.join(', ') || ''},
      {title: 'Teams:', label: teamsSettings?.map(item => item.webhookName)?.join(', ') || ''},
      {title: 'Slack:', label: slackSettings?.map(item => item.webhookName)?.join(', ') || ''},
      ];

    return obj;
  });

  return items;
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
