import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import Tabs from 'infrastructure/js/components/Tabs/tabs';
import TabTitle from 'infrastructure/js/components/Tabs/tabTitle';
import {
  isAFPStation, isIncomingShipments, isOutgoingShipments,
  isShippingPreparation,
  isTransitArea
} from '../../../utils/locationHelper';
import ImportAssetsViaBarcodeWizard from '../../Wizards/ImportAssetsViaBarcodeWizard/ImportAssetsViaBarcodeWizard';
import PartsViewContainer from '../../../containers/LocationPage/AssetsView/partsViewContainer';
import RollsViewContainer from '../../../containers/LocationPage/AssetsView/rollsViewContainer';
import SpoolsViewContainer from '../../../containers/LocationPage/AssetsView/spoolsViewContainer';
import ToolsViewContainer from '../../../containers/LocationPage/AssetsView/toolsViewContainer';
import KitsViewContainer from '../../../containers/LocationPage/AssetsView/kitsViewContainer';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import GroupsViewContainer from '../../../containers/LocationPage/AssetsView/groupsViewContainer';
import ResinsViewContainer from '../../../containers/LocationPage/AssetsView/resinsViewContainer';

require('./assetsView.scss');

const TabKeys = {
  ROLLS: 'rolls',
  SPOOLS: 'spools',
  RESINS: 'resins',
  KITS:   'kits',
  PARTS: 'parts',
  GROUPS: 'groups',
  TOOLS: 'tools',
};

const TAB_ID = 'assets';

export default class AssetsView extends React.PureComponent {

  constructor (props) {
    super(props);
    this.labels = createLabelHelper('mat.locationpage.view.assets.');

  }

  componentDidUpdate(prevProps) {
    let tabId = this.props.match.params.tabId;
    if (tabId !== TAB_ID) {
      return;
    }
    let newTabId = this.props.match.params.subTabId;

    //Check if tab changed and resize if needed.
    if(prevProps.match.params.subTabId !== newTabId){
      window.dispatchEvent(new Event('resize'));
    }

    if (newTabId && !(Object.values(TabKeys).includes(newTabId))) {
      window.location.hash = '#/PageNotFound';
    }
  }

  getTabTitles = () => {
    let counters = this.props.sData.get('counters');
    let getLabel = this.labels.get;

    let rolls = <TabTitle title={getLabel('tabs.rolls')} count={counters.get('rolls')} className="pl-header-icon-roll" />;
    let spools =  <TabTitle title={getLabel('tabs.spools')} count={counters.get('spools')} className="pl-header-icon-spool" />;
    let resins =  <TabTitle title={getLabel('tabs.resins')} count={counters.get('resins')} className="pl-header-icon-resin" />;
    let kits =  <TabTitle title={getLabel('tabs.kits')} count={counters.get('kits')} className="pl-header-icon-kit" />;
    let parts = <TabTitle title={getLabel('tabs.parts')} count={counters.get('parts')} className="pl-header-icon-part" />;
    let groups = <TabTitle title={getLabel('tabs.groups')} count={counters.get('groups')} className="pl-header-icon-container" />;
    let tools = <TabTitle title={getLabel('tabs.tools')} count={counters.get('tools')} className="pl-menu-active-tool" />;

    return {
      rolls,
      spools,
      resins,
      kits,
      parts,
      groups,
      tools,
    };
  };

  reloadLocationPage = () => {
    if (this.props.actions.locationPageActions && this.props.actions.locationPageActions.reload) {
      this.props.actions.locationPageActions.reload();
    }
  };

  onTabClick = (subTabId) => {
    if (subTabId) {
      let locationId = this.props.match.params.locationId;
      // let tabId = this.props.match.params.tabId;

      let newHash = `#/Location/${locationId}/${TAB_ID}/${subTabId}`;
      if (newHash !== window.location.hash)
        window.location.hash = newHash;
    }
  };

  getSelectedTab = () => {
    let subTabId = this.props.match.params.subTabId;
    return (subTabId && (Object.values(TabKeys).includes(subTabId))) ? subTabId : this.getDefaultTab();
  };

  getDefaultTab = () => {
    return isTransitArea(this.props.sLocationDetails) ? TabKeys.SPOOLS : TabKeys.ROLLS;
  }

  renderRollsTab = () => {
    if (isTransitArea(this.props.sLocationDetails)) {
      return null;
    }
    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.ROLLS} animation={false} title={tabTitles.rolls}>
        <RollsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  }

  renderSpoolsTab = () => {
    const {sLocationDetails} = this.props;
    if (isShippingPreparation(sLocationDetails) ||
        isOutgoingShipments(sLocationDetails) ||
        isIncomingShipments(sLocationDetails)
    ) {
      return null;
    }
    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.SPOOLS} animation={false} title={tabTitles.spools}>
        <SpoolsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  }

  renderResinsTab = () => {
    const {sLocationDetails} = this.props;
    if (!PermissionManager.isResinsEnabled() ||
      isTransitArea(sLocationDetails) ||
      isAFPStation(sLocationDetails) ||
      isShippingPreparation(sLocationDetails) ||
      isOutgoingShipments(sLocationDetails) ||
      isIncomingShipments(sLocationDetails)
    ) {
      return null;
    }

    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.RESINS} animation={false} title={tabTitles.resins}>
        <ResinsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  }

  renderKitsTab = () => {
    const {sLocationDetails} = this.props;
    if (isTransitArea(sLocationDetails) ||
        isAFPStation(sLocationDetails) ||
        isShippingPreparation(sLocationDetails) ||
        isOutgoingShipments(sLocationDetails) ||
        isIncomingShipments(sLocationDetails)
    ) {
      return null;
    }
    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.KITS} animation={false} title={tabTitles.kits}>
        <KitsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  }

  renderPartsTab = () => {
    const {sLocationDetails} = this.props;
    if (!isAFPStation(sLocationDetails)) {
          return null;
    }
    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.PARTS} animation={false} title={tabTitles.parts}>
        <PartsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  };

  renderGroupsTab = () => {
    const {sLocationDetails} = this.props;
    if (!PermissionManager.hasGroupAssetPermissions() || !PermissionManager.getOrgPreferences().groupsEnabled ||
        isShippingPreparation(sLocationDetails) ||
        isOutgoingShipments(sLocationDetails) ||
        isIncomingShipments(sLocationDetails)
    ) {
      return null;
    }
    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.GROUPS} animation={false} title={tabTitles.groups}>
        <GroupsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  };

  renderToolsTab = () => {
    const {sLocationDetails} = this.props;
    if (isTransitArea(sLocationDetails) ||
        isShippingPreparation(sLocationDetails) ||
        isOutgoingShipments(sLocationDetails) ||
        isIncomingShipments(sLocationDetails)
    ) {
      return null;
    }
    let tabTitles = this.getTabTitles();
    return (
      <Tabs.Tab eventKey={TabKeys.TOOLS} animation={false} title={tabTitles.tools}>
        <ToolsViewContainer match={this.props.match} />
      </Tabs.Tab>
    );
  };

  renderImportViaBarcodeWizardDialog = () => {
    let sData = this.props.sImportAssetsViaBarcodeWizardData;

    if (sData && sData.get('show')) {
      return (
        <ImportAssetsViaBarcodeWizard
          show={sData.get('show')}
          sData={sData}
          actions={this.props.actions.importAssetsViaBarcodeWizardActions}
          reloadParentComponent={this.reloadLocationPage}
        />
      );
    }
    return null;
  };

  render() {
    let selectedTab = this.getSelectedTab();
    return (
      <div className="raw-materials-view">

        <div className="top-filters-section">
          <Tabs activeKey={selectedTab} tabType="content" id="content-tabs" onSelect={this.onTabClick}>

            { this.renderRollsTab()}
            { this.renderSpoolsTab()}
            { this.renderResinsTab()}
            { this.renderKitsTab()}
            { this.renderPartsTab()}
            { this.renderGroupsTab()}
            { this.renderToolsTab()}

          </Tabs>
        </div>

        { this.renderImportViaBarcodeWizardDialog() }

      </div>
    );
  }
}

AssetsView.propTypes = {
  actions: PropTypes.object.isRequired,
  sLocationDetails: PropTypes.object.isRequired,
  // sDataCreateRoll: PropTypes.object.isRequired,
  // sDataCreateSpool: PropTypes.object.isRequired,
};
