import React from 'react';
import {reduxForm} from 'redux-form';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper.js';
import Grid from 'infrastructure/js/components/Grid/gridWrapper.js';
import AssetAlertPopoverCell from '../../../Common/CustomGridCells/AssetAlertPopoverCell/assetAlertPopoverCell.js';
import ValueWithStateCell from '../../../Common/CustomGridCells/ValueWithStateCell/valueWithStateCell';
import {navigationStates} from '../../../../enums/navigationStates';
import {filterTypes} from 'infrastructure/js/enums/filterTypes.js';
import {gridsNames} from '../../../../enums/gridsNames';
import {FetchEntitiesFilters} from '../../../../enums/fetchEntitiesFilters';
import {EntityPropertyTypes} from '../../../../enums/entityPropertyTypes';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell';
import EntityIdCell from '../../../Common/CustomGridCells/EntityIdCell/entityIdCell'
import {getAssetEtl} from '../../../../utils/assetHelper';
import {alertStatus, alertType, getAlertColumnFilterSections} from '../../../../utils/alertFilterSectionHelper';
import GridHelper from 'infrastructure/js/components/Grid/Utils/gridHelper';

import'./activeGroupsGrid.scss';

class ActiveGroupsGrid extends React.PureComponent{

  filterConfig = [
    {fieldName: 'availability', filterName: 'availabilityStatus', getOptions: true},
    {fieldName: 'businessId', filterName: 'businessId', getOptions: false},
    {fieldName: 'groupType.businessId', filterName: 'groupType', getOptions: true},
    {fieldName: 'location.name', filterName: 'assetLocationName', getOptions: true},
    {fieldName: 'alertStatus', filterName: 'assetAlert'},
    {fieldName: 'expirationDate', filterName: 'assetExpirationDate', getOptions: false},
    {fieldName: 'exposureTimeLeft', filterName: 'assetCalculatedEtl', getOptions: false},
    {fieldName: 'partType.businessId', filterName: 'groupPart', getOptions: true},
    {fieldName: 'workOrder.businessId', filterName: 'groupWorkOrder', getOptions: true},
    {fieldName: 'project.businessId', filterName: 'groupProject', getOptions: true},
  ]

  constructor (props){
    super(props);
    this.labels = createLabelHelper('mat.grid.');
    this.etlLabels = createLabelHelper('enum.etl.type.');

    this.columnsConfig = this.createColumnsConfig();
  }

  createColumnsConfig = () => {
    return [
      {
        fieldName: 'alertStatus',
        title: this.labels.get('columns.alert.title'),
        filterType: filterTypes.MULTI_SECTIONS,
        filterName: 'assetAlert',
        filterSections: getAlertColumnFilterSections([alertStatus.NO_ALERT, alertStatus.WARNING, alertStatus.ERROR],
          [alertType.ETL_BOND, alertType.ETL_CURE, alertType.EXPIRATION, alertType.DUE_DATE, alertType.MISPLACED]),
        width: 120,
        columnOptions: {
          cellComponent: AssetAlertPopoverCell,
          valueGetter: (params) => {
            return {
              description: '', alerts: params.data.alerts,
              alertStatus: params.data.alertStatus,
              exposureTimeLeftBond: params.data.exposureTimeLeftBond,
              exposureTimeLeftCure: params.data.exposureTimeLeftCure,
              expirationDaysLeft: params.data.m_ExpirationDaysLeft,
              expirationDate: params.data.expirationDate,
              dueDateLeft: params.data.dueDateLeft,
              locationName: params.data.locationName,
            };
          },
          sort: 'desc'
        },
      },
      {
        fieldName: 'availability',
        title: this.labels.get('columns.status.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'availabilityStatus',
        width: 130,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return this.getAvailabilityLabel(params.data.availability)
          }
        }
      },
      {
        fieldName: 'businessId',
        title: this.labels.get('columns.groupId.title'),
        filterType: filterTypes.MULTI_SELECT_ASYNC,
        filterName: 'businessId',
        fetchConfig: {
          entityType: EntityPropertyTypes.GROUP_BUSINESS_ID,
          filter: [FetchEntitiesFilters.ACTIVE],
          action: this.props.actions.fetchEntities
        },
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.id,
              label: params.data.businessId,
              type: navigationStates.GROUP
            };
          }
        }
      },
      {
        fieldName: 'groupType.businessId',
        title: this.labels.get('columns.type.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'groupType',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return (params.data.groupType?.businessId || '');
          },
        }
      },
      {
        fieldName: 'location.name',
        title: this.labels.get('columns.location.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'assetLocationName',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            return {
              id: params.data.locationId,
              type: navigationStates.LOCATION,
              label: params.data.locationName
            };
          }
        }
      },
      {
        fieldName: 'expirationDate',
        title: this.labels.get('columns.expiration.title'),
        filterType: filterTypes.NONE,
        width: 150,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            if (params.data.expirationDate && params.data.expirationDate.value) {
              return {
                value: DateTimeHelper.FormatDateObjectToDayMonth(params.data.expirationDate.value),
                state: params.data.expirationDate.state
              };
            }

            return {value: '', state: ''};
          },
          headerComponentParams: (GridHelper.getDateFormatHeaderIcon())
        }
      },
      {
        fieldName: 'exposureTimeLeft',
        title: this.labels.get('columns.etl.title'),
        filterType: filterTypes.NONE,
        width: 140,
        columnOptions: {
          cellComponent: ValueWithStateCell,
          valueGetter: (params) => {
            return getAssetEtl(params.data, this.etlLabels);
          },
        }
      },
      {
        fieldName: 'partType.businessId',
        title: this.labels.get('columns.partType.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'groupPart',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
      {
        fieldName: 'workOrder.businessId',
        title: this.labels.get('columns.workOrder.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'groupWorkOrder',
        columnOptions: {
          cellComponent: EntityIdCell,
          valueGetter: (params) => {
            if (params.data.workOrder) {
              return {
                id: params.data.workOrder.id,
                label: params.data.workOrder.businessId,
                type: navigationStates.WORKORDER
              };
            }
            return {};
          }
        }
      },
      {
        fieldName: 'project.businessId',
        title: this.labels.get('columns.project.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'groupProject',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        }
      },
    ]
  }

  getAvailabilityLabel(availability) {
    if (availability === 'AVAILABLE') {
      return this.labels.get('columns.status.filter.available');
    }
    else if (availability === 'OCCUPIED') {
      return this.labels.get('columns.status.filter.occupied');
    }
    return '';
  }

  render() {

    return (
      <div className="active-groups-grid">
        <Grid gridName={gridsNames.PREDEFINED_LIST_ACTIVE_GROUPS}
              columnsConfig={this.columnsConfig}
              actions={this.props.actions}
              filterConfig={this.filterConfig}
              gridProps={
                {
                  filterPersistence: true,
                }
              }
        />
      </div>
    );
  }
}

export default reduxForm({
    form: 'activeGroupsGridFrom'
  }
)(ActiveGroupsGrid);
