import { useMemo } from 'react';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { gridsNames } from '../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import useLabels from 'infrastructure/js/hooks/useLabels.js';
import LinkCell from '../../../Common/CustomGridCells/LinkCell/linkCell';

import './schedulingLogsGrid.scss';

const filterConfig = [
  { fieldName: 'schedulerStartExecutionTime', filterName: 'schedulerStartExecutionTime', getOptions: true },
  { fieldName: 'workOrdersCount', filterName: 'workOrdersCount', getOptions: false },
  { fieldName: 'scheduledWorkOrdersCount', filterName: 'scheduledWorkOrdersCount', getOptions: false },
  { fieldName: 'scheduledTasksCount', filterName: 'scheduledTasksCount', getOptions: false },
  { fieldName: 'numberOfCreatedSubTasks', filterName: 'numberOfCreatedSubTasks', getOptions: false },
  { fieldName: 'scheduledQuantity', filterName: 'scheduledQuantity', getOptions: false },
  { fieldName: 'executionDuration', filterName: 'executionDuration', getOptions: false },
  { fieldName: 'solutionsCount', filterName: 'solutionsCount', getOptions: false },
  { fieldName: 'startedBy', filterName: 'startedBy', getOptions: true },
  { fieldName: 'abortedBy', filterName: 'abortedBy', getOptions: true },
  { fieldName: 'status', filterName: 'status', getOptions: true },
  { fieldName: 'schedulerStartDate', filterName: 'schedulerStartDate', getOptions: false },
  { fieldName: 'schedulerFinishDate', filterName: 'schedulerFinishDate', getOptions: false },
];

export default function SchedulingLogsGrid({ actions }) {
  const labels = useLabels('mat.administration.logs.schedulingLogs.grid.');

  const columnsConfig = useMemo(() => {
    return [
      {
        fieldName: 'schedulerStartExecutionTime',
        title: labels.get('executionDate'),
        filterType: filterTypes.HORIZON,
        filterName: 'schedulerStartExecutionTime',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return DateTimeHelper.DateTimeFormat(data?.schedulerStartExecutionTime?.epochDateTime * 1000);
          },
        },
      },
      {
        fieldName: 'workOrdersCount',
        title: labels.get('numOfWorkorders'),
        filterType: filterTypes.NONE,
        filterName: 'workOrdersCount',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'scheduledWorkOrdersCount',
        title: labels.get('numOfScheduledWorkorders'),
        filterType: filterTypes.NONE,
        filterName: 'scheduledWorkOrdersCount',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'scheduledTasksCount',
        title: labels.get('numOfScheduledTasks'),
        filterType: filterTypes.NONE,
        filterName: 'scheduledTasksCount',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'numberOfCreatedSubTasks',
        title: labels.get('numOfScheduledSubTasks'),
        filterType: filterTypes.NONE,
        filterName: 'numberOfCreatedSubTasks',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'scheduledQuantity',
        title: labels.get('scheduledQuantity'),
        filterType: filterTypes.NONE,
        filterName: 'scheduledQuantity',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'executionDuration',
        title: labels.get('duration'),
        filterType: filterTypes.NONE,
        filterName: 'executionDuration',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'solutionsCount',
        title: labels.get('numOfValidSchedules'),
        filterType: filterTypes.NONE,
        filterName: 'solutionsCount',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'startedBy',
        title: labels.get('startedBy'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'startedBy',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'abortedBy',
        title: labels.get('abortedBy'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'abortedBy',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'status',
        title: labels.get('status'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'status',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'schedulerStartDate',
        title: labels.get('horizonStartDate'),
        filterType: filterTypes.HORIZON,
        filterName: 'schedulerStartDate',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return DateTimeHelper.DateTimeFormat(data?.schedulerStartDate?.epochDateTime * 1000);
          },
        },
      },
      {
        fieldName: 'schedulerFinishDate',
        title: labels.get('horizonEndDate'),
        filterType: filterTypes.HORIZON,
        filterName: 'schedulerFinishDate',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return DateTimeHelper.DateTimeFormat(data?.schedulerFinishDate?.epochDateTime * 1000);
          },
        },
      },
      {
        fieldName: 'hasFailureDetails',
        title: labels.get('reasonsForFailure'),
        filterType: filterTypes.NONE,
        columnOptions: {
          cellComponent: LinkCell,
          sortable: false,
          valueGetter: (params) => {
            return params.data.hasFailureDetails ?
            {
              label: labels.get('clickForDetails'),
              onClickHandler: () => {
                const configData = {
                  activityLogId: params.data.id,
                  activityLogTitle: labels.get('scheduleInsights'),
                }
                actions.setConfigData(configData);
                actions.show();
              },
            }
            : null;
          }
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="scheduling-logs-grid">
      <Grid gridName={gridsNames.ADMINISTRATION_SCHEDULING_LOGS} columnsConfig={columnsConfig} actions={actions} filterConfig={filterConfig} />
    </div>
  );
}
