import PropTypes from 'prop-types';
import Tree from 'infrastructure/js/components/Tree/tree';
import Tooltip from 'infrastructure/js/components/tooltip/tooltip.js';
import './digitalTwinViewTree.scss';

const DigitalTwinViewTree = ({ labels,
                               treeData=[],
                               onSelect=()=>{},
                               onDrop=()=>{},
                               selectedItem,
                               defaultExpandAll=true,
                               itemActions=null}) => {

  const getItemActionsRenderer = (item) => {
    return item?.isSection ?
      <>
        <Tooltip placement="top" value={labels.get('tooltip.createSection')}>
          <span className={'pl pl-section-sm'}
                onClick={() => itemActions?.createItem?.({parentId: item.id, isSection: true, isStation: false})} />
        </Tooltip>
        <Tooltip placement="top" value={labels.get('tooltip.createStation')}>
          <span className={'pl pl-station-sm'}
                onClick={() => itemActions?.createItem?.({parentId: item.id, isSection: false, isStation: true})} />
        </Tooltip>
        <Tooltip placement="top" value={labels.get('tooltip.createLocation')}>
          <span className={'pl pl-location-sm'}
                onClick={() => itemActions?.createItem?.({parentId: item.id, isSection: false, isStation: false})} />
        </Tooltip>
      </> : null;
  }

  const getItemIconsRenderer = (item) => {
    if (item.isSection) {
      return item.expanded ? <div className='pl pl-arrow-down branch-icon'/> : <div className='pl pl-arrow-right branch-icon'/>;
    }
    return item.isStation ? <div className='pl pl-station-sm leaf-icon'/> : <div className='pl pl-location-sm leaf-icon'/>
  }

  return (
      <Tree  className="digital-twin-tree"
             treeData={treeData}
             onSelect={onSelect}
             draggable={true}
             onDrop={onDrop}
             selectedItem={selectedItem}
             defaultExpandAll={defaultExpandAll}
             itemIconsRenderer={getItemIconsRenderer}
             itemActionsRenderer={getItemActionsRenderer}
      />
  );
}

DigitalTwinViewTree.propTypes = {
  labels: PropTypes.object.isRequired,
  treeData: PropTypes.array,
  onSelect: PropTypes.func,
  onDrop: PropTypes.func,
  selectedItem: PropTypes.object,
  defaultExpandAll: PropTypes.bool,
  itemActions: PropTypes.object,
}

export default DigitalTwinViewTree;
