import { useEffect, useMemo } from 'react';
import useRedux from 'infrastructure/js/hooks/useRedux';
import LotsGrid from './LotsGrid/lotsGrid';
import { jsxActions as lotsGridActions } from '../../../actions/LotsPage/lotsGridActions';
import { jsxActions as fetchEntitiesActions } from '../../../actions/FetchEntities/fetchEntitiesActions';
import useNavBarUpdate from 'infrastructure/js/hooks/useNavBarUpdate';
import { navigationStates } from '../../../enums/navigationStates';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';

import './lotsPage.scss';

const allActions = {
  lotsGridActions,
  fetchEntitiesActions,
};

export default function LotsPage() {
  const labels = useMemo(() => createLabelHelper('mat.lots.page.'), []);
  const updateNavBar = useNavBarUpdate();
  const { actions } = useRedux(allActions);

  useEffect(() => {
    updateNavBar(navigationStates.LOTS, labels.get('title'));
  }, [updateNavBar, labels]);

  return (
    <div className="lots-page">
      <LotsGrid actions={{ ...actions.lotsGridActions, ...actions.fetchEntitiesActions }} />
    </div>
  );
}
