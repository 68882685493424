import React from 'react';
import PropTypes from 'prop-types';
import { createLabelHelper } from 'infrastructure/js/utils/labelHelper';
import {reduxForm} from 'redux-form';
import Validation from 'infrastructure/js/components/controls/controlsValidations.js';
import Dialog from 'infrastructure/js/components/Dialog/dialog';
import MessageDialog from 'infrastructure/js/components/Dialog/MessageDialog/messageDialog.js';
import {EntityPropertyTypes} from '../../../../../enums/entityPropertyTypes';
import {FetchEntitiesFilters} from '../../../../../enums/fetchEntitiesFilters';
import {enumTypes, getEnumValue} from '../../../../../utils/enumHelper';
import EntitiesMultiSelect from '../../../../Common/Controls/EntitiesMultiSelect/entitiesMultiSelect';
import  * as dialogHelper  from 'infrastructure/js/components/Dialog/dialogHelper';
import Label from 'infrastructure/js/components/Label/label';
import MultiEntitiesMultiSelect from '../../../../Common/Controls/MultiEntitiesMultiSelect/multiEntitiesMultiSelect';
import {getAssetExtraData} from '../../../../../utils/assetHelper';

require('./addToShipmentDialog.scss');

class AddToShipmentDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dialogLabels = createLabelHelper('mat.dialog.');
    this.labels = createLabelHelper('mat.locationpage.view.assets.addtoshipment.dialog.');

    this.entitiesTypes = this.props.sData.get('entitiesTypes').toJS();

    this.shipmentFetchConfig = {
      entityType: EntityPropertyTypes.SHIPMENT_BUSINESS_ID,
      filter: [FetchEntitiesFilters.ON_SUPPLIER_LOCATION],
      action: this.props.actions.fetchEntities,
    };
    this.assetsFetchConfig = {
      filter: [FetchEntitiesFilters.ACTIVE, FetchEntitiesFilters.ON_LOCATION, FetchEntitiesFilters.NOT_CONTAINED, FetchEntitiesFilters.NOT_RELATED, FetchEntitiesFilters.NO_ATTACHED_ASSET],
      action: this.props.actions.fetchEntities,
    };
  }

  getDialogButtons() {
    return {
      left: [{
        id:'cancel',
          text: this.dialogLabels.get('cancel'),
          action: this.onHide
      }],
      right: [
        {
          id:'submit',
          text: this.dialogLabels.get('add'),
          bsStyle: 'primary',
          loading: this.props.sData.get('loading'),
          action: this.props.handleSubmit(this.onSubmit),
          disabled: this.props.sData.get('loading') ||
            this.props.sData.get('hasError') ||
            (this.props.sData.get('showIgnoreValidationCheckbox') && !this.props.sData.get('isIgnoreValidationWarnings'))
        }
      ]
    };
  }

  getComponentAssetsIds = (data) => {
    let allRows = data['add-asset-selection'];
    let assetsIds = [];
    allRows.map((row) => {
      if (row && row.assetsValue) {
        row.assetsValue.map((asset) => {
          assetsIds.push(asset.value);
        });
      }
    });
    return assetsIds;
  };

  getShipment = (data) => {
    let shipment = data?.['to-asset-selection']?.assetsValue || null ;
    return shipment?.value || null;
  }

  onSubmit = (data) => {
    let submitData = {
      assetIds : this.getComponentAssetsIds(data),
      shipmentId:  this.getShipment(data),
      ignoreValidationWarnings: (this.props.sData.get('showIgnoreValidationCheckbox') && data.footerValidationCheckbox) ?
        data.footerValidationCheckbox : false,
    };
    this.props.actions.submit(submitData, this.getMessageDialogBuilder(), this.props.reloadParentComponent);
  };

  getMessageDialogBuilder = () => {
    return (data, fnCloseDialog, callback) => {

      let title = '';
      let buttons = [{id:'close',text: this.dialogLabels.get('close'), action: fnCloseDialog, bsStyle: 'primary'}];
      if (callback) {
        buttons.unshift({id:'printRfidTag', text: this.labels.get('footer.printRfidTag'), action: callback},)
      }
      let children = [];

      if (data && data.assignedAssets) {
        title = this.labels.get('confirmation.message.title', '', { count: data.assignedAssets.length});
        children = (<div  key="operation-confirmation">
          <div className="message-item">
            <div className="message-item-line">
              <MessageDialog.DataRow label={this.labels.get('confirmation.message.shipmentId')}
                                  value={data.shipment?.businessId || ''}/>
            </div>
            <div className="message-item-line">
              <MessageDialog.DataRow label={this.labels.get('confirmation.message.assets')}
                                     value={this.getConfirmationAssetsRows(data.assignedAssets)}/>
            </div>
          </div>
        </div>);
      }

      let className = 'add-to-cont-confirmation-dialog';
      return {title, children, buttons, className};
    };
  };

  getConfirmationAssetsRows = (assets) => {
    if (assets) {
      return (<div>
        {assets.map((asset, index) => {
          let extraData = getAssetExtraData(asset);
          let assetType = asset ? this.labels.get(`confirmation.message.entity.${asset.objectType.toLowerCase()}`) : '';
          return (
            <div className="related-asset-data" key={'related-asset-' + index}>
            <span className="message-item-value">
              <Label className="related-asset-type" text={assetType}/>
              <Label className="related-asset-id" text={asset.businessId}/>
              <Label className="related-asset-metadata" text={(extraData ? '(' + extraData + ')' : '')}/>
            </span>
            </div>
          )
        })}
      </div>);
    }
    return '';
  }

  onHide =() => {
    this.props.actions.hide();
  }

  getFooterValidationCheckBox = () => {
    return this.props.sData.get('showIgnoreValidationCheckbox') ?
      {label: this.dialogLabels.get('ignorewarnings'),onChange: this.onFooterValidationChange} : null;
  };

  onFooterValidationChange = (value) => {
    this.props.actions.toggleIgnoreValidationWarningsCheckbox(value);
  };


  render() {

    let preselectedAssets = this.props.sData.get('preselectedAssets');
    let preselectedShipment = this.props.sData.get('preselectedShipment');

    return (
      <Dialog
        id="add-to-shipment-dialog"
        className="add-to-shipment-dialog"
        show={this.props.sData.get('show')}
        onHide={this.onHide}
        sData={this.props.sData}
        titleText={this.labels.get('header.title')}
        footerValidationCheckbox={this.getFooterValidationCheckBox()}
        footerInformationIcon={dialogHelper.getFooterInformationIcon(this.props.sData, this.dialogLabels)}
        footerButtons={this.getDialogButtons()}
        onEnterKeyPress={this.props.handleSubmit(this.onSubmit)}
      >


        <div className="add-to-asset-container">
          <div className="add-to-asset-section">
            <label className="add-to-asset-label">{this.labels.get('add')}</label>

            <MultiEntitiesMultiSelect
              id="add-asset-selection"
              name='add-asset-selection'
              className="add-to-asset-selection"
              entitiesTypes={this.entitiesTypes}
              validate={Validation.required}
              preSelectedEntities={preselectedAssets}
              fetchConfig={this.assetsFetchConfig}
              autoFocus={true}
              {...this.props}
            />
          </div>

          <label className="add-to-asset-arrow">
            <i className="pl pl-move-arrow-right"/>
          </label>

          <div className="add-to-asset-section">
            <label className="add-to-asset-label">{this.labels.get('to')}</label>
            <EntitiesMultiSelect
              id="to-asset-selection"
              name="to-asset-selection"
              className="assets-to-asset-selection"
              entitiesTypes={[getEnumValue(enumTypes.OBJECT_TYPE)('SHIPMENT')]}
              fetchConfig={this.shipmentFetchConfig}
              validate={Validation.required}
              preSelectedEntities={preselectedShipment}
              isMulti={false}
              change={this.props.change}
            />

          </div>
        </div>
      </Dialog>
    );
  }

}

export default reduxForm({
    form: 'addToShipmentDialog',
    onChange:  (values, dispatch, props, previousValues ) => {
      dialogHelper.onFormChangeHandler(values, props, previousValues);
    },
  }
)(AddToShipmentDialog);


AddToShipmentDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  sData: PropTypes.object.isRequired,
};
