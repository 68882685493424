import * as locationService from '../../services/Locations/locationsService.js';
import {navigationStates} from '../../enums/navigationStates';
import {getEnumValue, enumTypes} from '../../utils/enumHelper';
import PermissionManager from 'infrastructure/js/utils/permissionManager';
import {isFreezerLocation, isOutgoingOrIncomingLocation, isShippingPreparation} from '../../utils/locationHelper';
import { api as navigatorApi } from '../AppTopbar/navigatorActions.js';
import { api as systemApi } from '../System/systemActions.js';
import { api as cuttingViewApi } from './CuttingView/cuttingViewActions.js';
import { api as kittingViewApi } from './KittingView/kittingViewActions.js';
import { api as assetsViewApi } from './AssetsView/assetsViewActions.js';
import { api as shipmentsViewApi } from './ShipmentsView/shipmentsViewActions.js';
import { api as tasksViewApi } from './TasksView/tasksViewActions';
import { api as workOrderViewApi } from './WorkOrderView/workOrderViewActions.js';
import { api as smartSelectionViewApi } from '../SmartSelection/smartSelectionPageActions.js';
import { api as pickListsViewApi } from '../PickListsPage/pickListsPageActions.js';


/////////////////////////////////////////
// ACTION TYPES - PUBLIC, FOR REDUCERS
export const actionTypes = {
  LOCATIONPAGE_LOCATION_CHANGED:           "LOCATIONPAGE_LOCATION_CHANGED",

  LOCATIONPAGE_LOCATION_DETAILS_FETCH_READY:      "LOCATIONPAGE_LOCATION_DETAILS_FETCH_READY",
  LOCATIONPAGE_LOCATION_ALERTS_COUNT_FETCH_READY: "LOCATIONPAGE_LOCATION_ALERTS_COUNT_FETCH_READY",
  LOCATIONPAGE_LOCATION_ASSETS_COUNT_FETCH_READY: "LOCATIONPAGE_LOCATION_ASSETS_COUNT_FETCH_READY",

  LOCATIONPAGE_CURRENT_LOCATION_INITIAL_DATA_FETCH_READY: "LOCATIONPAGE_CURRENT_LOCATION_INITIAL_DATA_FETCH_READY",
  LOCATIONPAGE_LOCATION_SET_LOCATION_ID: "LOCATIONPAGE_LOCATION_SET_LOCATION_ID",
  LOCATIONPAGE_RESET_GRIDS_STATE : "RESET_GRIDS_STATE"

};

////////////////////////////////////////////////////////////////
// PLAIN ACTION CREATORS - PRIVATE, FOR LOCAL DISPATCH ONLY
const actions = {
  locationDetailsFetchReady: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_LOCATION_DETAILS_FETCH_READY, payload: payload };
  },
  setLocationId: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_LOCATION_SET_LOCATION_ID, payload: payload };
  },
  alertsCountFetchReady: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_LOCATION_ALERTS_COUNT_FETCH_READY, payload: payload };
  },
  assetsCountFetchReady: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_LOCATION_ASSETS_COUNT_FETCH_READY, payload: payload };
  },
  currentLocationInitialDataFetchReady: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_CURRENT_LOCATION_INITIAL_DATA_FETCH_READY, payload: payload };
  },
  resetGridState: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_RESET_GRIDS_STATE, payload: payload };
  },
  locationChanged: function(payload) {
    return {type: actionTypes.LOCATIONPAGE_LOCATION_CHANGED, payload: payload };
  }

};

/////////////////////////////////////////////////////
// METHODS FOR JSX PROPS - PUBLIC, ALL THUNK TYPE
export let jsxActions = {};

jsxActions.setLocationId = function(locationId) {
  return function (dispatch, getState) {
    dispatch(actions.setLocationId(locationId));
  }
};
// Called on update when data was changed by some dialog.
jsxActions.load = function(locationId, newLocation) {
  return function(dispatch, getState) {
    systemApi.pageManager(dispatch, getState).setCurrentPageName(navigationStates.LOCATION);
    return api.load(dispatch, getState)(locationId, newLocation);
  };
};

jsxActions.reload = function() {
  return function(dispatch, getState) {
    return api.reload(dispatch, getState)();
  };
};

// Called by this component's ScheduleAction exclusively.
jsxActions.onTimerRefresh = function() {
  return function(dispatch, getState) {
    let locationId = api.getLocationId(dispatch, getState)();

    api.fetchLocationChartsData(dispatch, getState)(locationId)
    .then((combinedLocationInitialData) => {

      // Refresh Top Navigator only.
      let topNavigatorData = utility.convertLocationDataToTopNavigatorData(combinedLocationInitialData);
      navigatorApi.setData(dispatch, getState)(topNavigatorData);

    });

    //temporary commented out (ask Noam)
    //api.reload(dispatch, getState)();
  }
};

jsxActions.ResetGridState = function(tabKey) {
  return function(dispatch, getState) {
    dispatch(actions.locationChanged())
    dispatch(actions.resetGridState());
  }
};

jsxActions.getLocationDetails = function() {
  return function(dispatch, getState) {
    return api.getLocationDetails(dispatch, getState)();
  };
};

/////////////////////////////////////////////////////////////////////////
// API METHODS - PUBLIC, FOR OTHER ACTION MODULES (and internal use)
export let api = {};

api.activateTab = function(dispatch, getState) {
  return function(tabKey) {
    let locationId = api.getLocationId(dispatch, getState)();
    let newHash = `#/Location/${locationId}/${tabKey}`;
    window.location.hash = newHash;
  }
};

api.getLocationDetails = function(dispatch, getState) {
  return function() {
    return getState().locationPage.get('data').get('locationDetails');
  }
};

api.getLocationId = function(dispatch, getState) {
  return function() {
    let locationPageCurrentLocationDetails = getState().locationPage.get('data').get('locationDetails');
    if (!locationPageCurrentLocationDetails) {
      return null;
    }

    return locationPageCurrentLocationDetails.id;
  }
};

api.getLocationType = function(dispatch, getState) {
  return function() {
    let currentLocationDetails = getState().locationPage.get('data').get('locationDetails');
    return currentLocationDetails ? currentLocationDetails.locationType : null;
  }
};

api.fetchLocationChartsData = function(dispatch, getState) {
  return function(locationId) {
    if (!locationId) {
      return;
    }

    // Fetch Assets Counts.
    let locationDetailsPromise = locationService.fetchLocationDetails(locationId)
    .then((response) => {

      let locationDetails = response.data;
      dispatch(actions.locationDetailsFetchReady(locationDetails));

      return locationDetails; // pass value on for next then.
    });


    // Fetch Assets Counts.
    let assetsCountsPromise = locationService.fetchLocationAssetsCounts(locationId)
    .then((response) => {

      let locationAssetsCounts = response.data?.data;
      dispatch(actions.assetsCountFetchReady(locationAssetsCounts));

      return locationAssetsCounts; // pass value on for next then.
    });

    // Fetch Alerts Counts.
    let alertsCountsPromise = locationService.fetchLocationAlertsCounts(locationId)
    .then((response) => {

      let locationAlertsCounts = response.data.data;
      dispatch(actions.alertsCountFetchReady(locationAlertsCounts));

      return locationAlertsCounts; // pass value on for next then.
    });



    // Combined promise for all requests.
    let resultPromise = Promise.all([locationDetailsPromise, assetsCountsPromise, alertsCountsPromise])
    .then((allResults) => {

      let combinedLocationInitialData = {
        locationDetails:  allResults[0],
        assetsCounts:     allResults[1],
        alertsCounts:     allResults[2]
      };

      dispatch(actions.currentLocationInitialDataFetchReady(combinedLocationInitialData));

      return combinedLocationInitialData;
    });

    // Return promise for any consumer of this api function.
    // The consumers will get "combinedLocationInitialData" object in their "then" function.
    return resultPromise;
  };

};


// Force reload all displays for current location.
api.reload = function(dispatch, getState) {
  return function() {
    // Load same location.
    let locationId = api.getLocationId(dispatch, getState)();

    return api.load(dispatch, getState)(locationId, false);
  }
};

// Load all displays for specific location ID.
api.load = function(dispatch, getState) {
  return function(locationId, newLocation = false) {
    let shouldFetchGrid = !newLocation;
    if (newLocation) {
      navigatorApi.setLoading(true)(dispatch, getState);
    }

    if(newLocation){
      dispatch(actions.locationChanged())
    }

    return api.fetchLocationChartsData(dispatch, getState)(locationId)
      .then((combinedLocationInitialData) => {
        // Update top navigator.
        let topNavigatorData = utility.convertLocationDataToTopNavigatorData(combinedLocationInitialData);
        navigatorApi.setData(dispatch, getState)(topNavigatorData);

        let {locationDetails} = combinedLocationInitialData;

        let promiseObjects = {};
        if (locationDetails && locationDetails.operations && utility.hasOperationOfType(getEnumValue(enumTypes.OPERATION_TYPE)('CUT'), locationDetails.operations)) {
          promiseObjects.cuttingView = cuttingViewApi.init(dispatch, getState)(shouldFetchGrid);
        }

        if (locationDetails && locationDetails.operations && utility.hasOperationOfType(getEnumValue(enumTypes.OPERATION_TYPE)('KIT'), locationDetails.operations)) {
          promiseObjects.kittingView = kittingViewApi.init(dispatch, getState)(shouldFetchGrid);
        }

        //Note: the 'Resource' stations do not contain the 'Work Orders' tab
        if (locationDetails && locationDetails.isStation && !locationDetails.isResource) {
          promiseObjects.workOrderView = workOrderViewApi.init(dispatch, getState)(shouldFetchGrid);
        }

        if (isFreezerLocation(locationDetails) && utility.isSmartSelectionEnabled(getState)) {
          if (PermissionManager.hasLocationSmartSelectionTabPermissions()) {
            promiseObjects.smartSelectionView = smartSelectionViewApi.reload(dispatch, getState)();
          }
          if (PermissionManager.hasLocationPickListTabPermissions()) {
            promiseObjects.pickListsView = pickListsViewApi.reload(dispatch, getState)();
          }
        }

        if(PermissionManager.getOrgPreferences().schedulerEnabled){
          promiseObjects.tasksView = tasksViewApi.init(dispatch, getState)(shouldFetchGrid);
        }

        promiseObjects.assetsView = assetsViewApi.init(dispatch, getState)(shouldFetchGrid);

        if (isShippingPreparation(locationDetails) || isOutgoingOrIncomingLocation(locationDetails)) {
          promiseObjects.shipmentsView = shipmentsViewApi.init(dispatch, getState)(shouldFetchGrid);
        }

        //removed activity tab from refreshing to prevent heavy load on the server
        //promiseObjects.activitiesView = activitiesViewApi.init(dispatch, getState)(shouldFetchGrid);

        if (newLocation) {
          navigatorApi.setLoading(false)(dispatch, getState);
        }

        return promiseObjects;

      });
  }
};

////////////////////////////////////////
// Utility
let utility = {};

utility.isSmartSelectionEnabled = (getState) => {
  return PermissionManager.getOrgPreferences().smartSelectionEnabled;
};

utility.hasOperationOfType = ( operationType, operations) => {
  if (operations) {
    let found =  operations.find((op) => {
      return op.operationType.name === operationType;
    });
    return !!found;
  }
  return false;
};

utility.convertLocationDataToTopNavigatorData = function(combinedLocationInitialData) {

  let topNavigatorData = {
    name: combinedLocationInitialData.locationDetails.name,
    type: (combinedLocationInitialData.locationDetails.isStation) ? navigationStates.STATION : navigationStates.LOCATION,
    id: combinedLocationInitialData.locationDetails.id,
    status: combinedLocationInitialData.locationDetails.isActive ? null : 'inactive',
    components: []
  };

  return topNavigatorData;
};


















