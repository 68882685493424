import Network from 'infrastructure/js/modules/network';
import * as toolsService from '../Tools/toolsService';
import * as rollsService from '../Rolls/rollsService';
import * as spoolsService from '../Spools/spoolsService';
import * as resinsService from '../Resins/resinsService';
import * as kitsService from '../Kits/kitsService';
import * as groupsService from '../Groups/groupsService';
import { getEnumValue, enumTypes } from '../../utils/enumHelper';
import { EntityPropertyTypes } from '../../enums/entityPropertyTypes';

export function printMultiAssetsLabels(assetIds) {
  return Network.post('download/label/multiasset', { assetIds }, { blobResponse: true });
}

export function PrintAssetLabel(assetType, assetIds) {
  let data = { assetIds };

  switch (assetType.toUpperCase()) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return rollsService.PrintRollLabel(data);
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return kitsService.PrintKitLabel(data);
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return toolsService.printToolLabel(data);
    default:
      console.error('PrintAssetLabel unhandled asset type: ' + assetType);
      return;
  }
}

export function PrintRfidTagsLabel(assetType, assetIds) {
  let data = { assetIds };

  return rollsService.PrintRollLabel(data);
}

export function getAssetActivityLog(assetId, query = {}) {
  return Network.post(`assets/${assetId}/activities`, query);
}

export function getAssetEtlLog(assetId, query = {}) {
  return Network.post(`assets/${assetId}/etl-log`, query);
}

export function getAssetAttachment(assetType, assetId, hideDeleted, query = {}) {
  let includeDeleted = hideDeleted ? `isDeleted=${!hideDeleted}` : '';

  return Network.post(
    `attachments/info/${assetType.toUpperCase()}/${assetId}/items?${includeDeleted}`,
    query
  );
}

export function editAssetAttributes(data, callback) {
  switch (data.assetsType.toUpperCase()) {
    case getEnumValue(enumTypes.OBJECT_TYPE)('ROLL'):
      return rollsService.editRollAttributes(data.updateData, { callback });
    case getEnumValue(enumTypes.OBJECT_TYPE)('SPOOL'):
      return spoolsService.editSpoolAttributes(data.updateData, { callback });
    case getEnumValue(enumTypes.OBJECT_TYPE)('RESIN'):
      return resinsService.editResinAttributes(data.updateData, { callback });
    case getEnumValue(enumTypes.OBJECT_TYPE)('KIT'):
      return kitsService.editKitAttributes(data.updateData, { callback });
    case getEnumValue(enumTypes.OBJECT_TYPE)('GROUP'):
      return groupsService.editGroupAttributes(data.updateData, { callback });
    case getEnumValue(enumTypes.OBJECT_TYPE)('TOOL'):
      return toolsService.editToolAttributes(data.updateData, { callback });
    default:
      console.error('unhandled asset type: ' + data.assetsType);
  }
}
//used by ShelfLifeUpdateDialog
export function fetchReasons() {
  return Network.get('reason-type');
}

export function getAssetResultingAssets(assetId) {
  return Network.get(`assets/${assetId}/resulting-assets`);
}

export function getAssetComponentAssets(assetId) {
  return Network.get(`assets/${assetId}/component-assets`);
}

export function getAssetRelatedAssets(assetId) {
  return Network.get(`assets/${assetId}/relatedAssets `);
}

export function getAssetEquipmentAssets(assetId) {
  return Network.get(`assets/${assetId}/equipment`);
}

export function fetchEntities(data) {
  let url = _getSearchUrl(data);
  delete data.entityType;

  return Network.post(url, data);
}

export function getAssetByTagId(data) {
  return Network.post('find/asset/bytag', data);
}

export function getAssetDetails(data) {
  return Network.post('assets/details', data);
}

export function getAssetByTid(tid) {
  return Network.get(`assets/tid/${tid}`);
}

export function getAssetByScannedTag(data) {
  return Network.post('assets/getAssetByScannedTag', data);
}

export function confirmAssetLocation(assetId) {
  return Network.post(`assets/${assetId}/location-confirmation`);
}

function _getSearchUrl(data) {
  switch (data.entityType) {
    case EntityPropertyTypes.ROLL_BUSINESS_ID:
      return 'rolls/search/items';
    case EntityPropertyTypes.SPOOL_BUSINESS_ID:
      return 'spools/search/items';
    case EntityPropertyTypes.RESIN_BUSINESS_ID:
      return 'resins/search/items';
    case EntityPropertyTypes.KIT_BUSINESS_ID:
      return 'kits/search/items';
    case EntityPropertyTypes.PART_BUSINESS_ID:
      return 'parts/search/items';
    case EntityPropertyTypes.TOOL_BUSINESS_ID:
      return 'tools/search/items';
    case EntityPropertyTypes.TOOL_TYPE_BUSINESS_ID:
      return 'toolTypes/search/items';
    case EntityPropertyTypes.TOOL_GROUP_BUSINESS_ID:
      return 'toolGroups/search/items';
    case EntityPropertyTypes.WO_BUSINESS_ID:
      return 'workorders/search/items';
    case EntityPropertyTypes.GROUP_BUSINESS_ID:
      return 'groups/search/items';
    case EntityPropertyTypes.ASSETS_BUSINESS_ID:
      return 'assets/search/items';
    case EntityPropertyTypes.LOT_NAME:
      return 'lots/search/items';
    case EntityPropertyTypes.OPERATION_NAME:
      return 'operations/search/items';

    case EntityPropertyTypes.KIT_TYPE_BUSINESS_ID:
      return 'kittypes/search/items';
    case EntityPropertyTypes.PART_TYPE_BUSINESS_ID:
      return 'parttypes/search/items';
    case EntityPropertyTypes.GROUP_TYPE_BUSINESS_ID:
    case EntityPropertyTypes.GROUP_TYPE_NAME:
      return 'groupTypes/search/items';
    case EntityPropertyTypes.MATERIAL_TYPE_BUSINESS_ID:
    case EntityPropertyTypes.MATERIAL_TYPE_NAME:
    case EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID:
      return 'materials/search/items';
    case EntityPropertyTypes.MATERIAL_TYPE_NAME_AND_BUSINESS_ID_PICK_LIST:
      return 'materials/picklist/items';
    case EntityPropertyTypes.TAG_BUSINESS_ID:
      return 'tags/search';
    case EntityPropertyTypes.SCHEDULER_TASK_NAME:
      return 'planner/tasks/search/items';
    case EntityPropertyTypes.SCHEDULER_TASK_WO_BUSINESS_ID:
      return 'planner/tasks/workorders/search/items';
    case EntityPropertyTypes.SCHEDULER_TASK_KIT_TYPE_BUSINESS_ID:
      return 'planner/tasks/kittypes/search/items';
    case EntityPropertyTypes.SHIPMENT_BUSINESS_ID:
    case EntityPropertyTypes.SHIPMENT_STATUS:
    case EntityPropertyTypes.SHIPMENT_CREATED_DATE:
    case EntityPropertyTypes.SHIPMENT_UPDATED_DATE:
      return 'shipments/search/items';
    case EntityPropertyTypes.USER_NAME:
    case EntityPropertyTypes.USER_FIRST_NAME:
    case EntityPropertyTypes.USER_LAST_NAME:
    case EntityPropertyTypes.SHIPMENT_CREATED_BY:
      return 'users/search/items';
    case EntityPropertyTypes.PICK_LIST_BUSINESS_ID:
      return 'picklists/search/items';
    case EntityPropertyTypes.ORGS:
      return 'orgs/search/items';
    case EntityPropertyTypes.REPORTED_CUT:
      return 'reported-cuts/search/items';
  }
  console.error('Unknown entity property type: ' + data.entityType);
  return null;
}

export function markAsReceived(data) {
  return Network.post('assets/markAsReceived', data);
}

export const updateAsset = (assetType, assetId, data) => {
  return Network.put(`${assetType}/${assetId}`, data);
};

export function fetchAllAssets(query = {}) {
  return Network.post('assets/items', query);
}

export function removeAssets(data = {}) {
  return Network.delete('assets/delete', data);
}

