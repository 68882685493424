import PropTypes from 'prop-types';
import CommonKPI from '../../Dashboard/Components/CommonKPI/commonKPI';
import ManagerViewItem from '../ManagerViewItem/managerViewItem';

import './managerView.scss';

const convertToKPIsData = ({errors, warnings}) => {
  return ( [
    {
      title: '',
      leftData: errors?.value || 0,
      leftDataClassName: 'red',
      icon: 'pl pl-error-icon',
      iconClassName: 'red',
      rightData: errors?.label || 'Errors',
      timeFrame: '',
    },
    {
      title: '',
      leftData: warnings?.value || 0,
      leftDataClassName: 'orange',
      icon: 'pl pl-warning-icon',
      iconClassName: 'orange',
      rightData: warnings?.label || 'Warnings',
      timeFrame: '',
    },
  ] );
}

const ManagerView = ({ items=[],
                       wos=[],
                       alerts=[],
                       kpis=[],
                       title=''}) => {

  if(items) {
    let KPIs = convertToKPIsData(kpis)?.map((item, index) => {
      return <CommonKPI key={index} data={item} />;
    }) || [];

    let itemsComp = items?.map((item, index) => {
      return <ManagerViewItem key={'dashborad-item' + index}
                              item={item}
                              wos={wos}
                              alerts={alerts} />;
    }) || [];

    return (
      <div className="manager-view">
        <div className="manager-view__kpis-container">{KPIs}</div>
        <div className="manager-view__title">{title}</div>
        <div className="manager-view__items">{itemsComp}</div>
      </div>
    )
  }
  return null;
}

ManagerView.propTypes = {
  items: PropTypes.array,
  wos: PropTypes.array,
  alerts: PropTypes.array,
  kpis: PropTypes.object,
  title: PropTypes.string,
};

export default ManagerView;
