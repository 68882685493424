import * as workOrdersService from '../../../services/WorkOrders/workOrdersService';
import {gridsNames} from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import WoGridHelper from 'infrastructure/js/utils/woGridHelpers';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_PS_MANAGEMENT_WORK_ORDERS, _fetchDataAction, WoGridHelper._convertServerItemsToGridRowsData);

function _fetchDataAction(query) {
  return function(dispatch, getState) {
    query.pageSize = 50;
    return workOrdersService.fetchAllWorkOrders(query);
  }
}

export let actionTypes = gridActions.actionTypes, api = gridActions.apiActions, jsxActions = gridActions.jsxActions;
