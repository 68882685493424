import { gridsNames } from '../../../enums/gridsNames';
import * as gridActionsHelper from 'infrastructure/js/components/Grid/Utils/gridActionsHelper';
import { fetchSandboxes } from '../../../services/Sandbox/sandboxService';

const gridActions = gridActionsHelper.getGridActions(gridsNames.ADMINISTRATION_SANDBOX, _fetchDataAction, _convertToRowData);

function _fetchDataAction(query) {
  return function (dispatch, getState) {
    return fetchSandboxes(query);
  };
}

function _convertToRowData(rows, serverTimeSeconds) {
  let items = rows.map((obj) => {
    return obj;
  });
  return items;
}

export let actionTypes = gridActions.actionTypes,
  api = gridActions.apiActions,
  jsxActions = gridActions.jsxActions;
