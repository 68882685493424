import { useMemo } from 'react';
import Grid from 'infrastructure/js/components/Grid/gridWrapper';
import LabelWithTooltipCell from '../../../Common/CustomGridCells/LabelWithTooltipCell/labelWithTooltipCell.js';
import { gridsNames } from '../../../../enums/gridsNames';
import { filterTypes } from 'infrastructure/js/enums/filterTypes';
import DateTimeHelper from 'infrastructure/js/utils/dateTimeHelper';
import useLabels from 'infrastructure/js/hooks/useLabels.js';
import {enumTypes, getEnumLabel} from '../../../../utils/enumHelper';

import './notificationLogsGrid.scss';

const filterConfig = [
  { fieldName: 'executionDate', filterName: 'externalNotificationLogsExecutionDate', getOptions: false },
  { fieldName: 'notificationTrigger', filterName: 'externalNotificationLogsTrigger', getOptions: true },
  { fieldName: 'channel', filterName: 'externalNotificationLogsChannel', getOptions: true },
  { fieldName: 'createdByUser', filterName: 'externalNotificationLogsStartedBy', getOptions: true },
];

export default function NotificationLogsGrid({ actions }) {
  const labels = useLabels('mat.administration.logs.notificationLogs.grid.');
  const filterLabels = useLabels('mat.filter.');

  const columnsConfig = useMemo(() => {
    return [
      {
        fieldName: 'executionDate',
        title: labels.get('column.executionDate.title'),
        filterType: filterTypes.DATE_SELECT,
        filterName: 'externalNotificationLogsExecutionDate',
        filterLabel: filterLabels.get('date.startTime'),
        isTime: true,
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: ({ data }) => {
            return data ? DateTimeHelper.DateTimeFormat(data?.executionDate?.epochDateTime * 1000) : '';
          },
        },
      },
      {
        fieldName: 'notificationTrigger',
        title: labels.get('column.trigger.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'externalNotificationLogsTrigger',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
          valueGetter: (params) => {
            return getEnumLabel(enumTypes.NOTIFICATION_TRIGGER_TYPE)(params.data.notificationTrigger);
          }
        },
      },
      {
        fieldName: 'channel',
        title: labels.get('column.method.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'externalNotificationLogsChannel',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
      {
        fieldName: 'createdByUser',
        title: labels.get('column.createdBy.title'),
        filterType: filterTypes.MULTI_SELECT,
        filterName: 'externalNotificationLogsStartedBy',
        columnOptions: {
          cellComponent: LabelWithTooltipCell,
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="notification-logs-grid">
      <Grid gridName={gridsNames.ADMINISTRATION_NOTIFICATION_LOGS} columnsConfig={columnsConfig} actions={actions} filterConfig={filterConfig} />
    </div>
  );
}
