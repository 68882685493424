import {useMemo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TreeItem from './TreeItem/treeItem.js';
import {ElementSelectionProvider} from 'infrastructure/js/contexts/elementSelectionContext';
import {TreeItemDragProvider} from 'infrastructure/js/components/Tree/treeItemDragContext';
import {TreeMap} from 'infrastructure/js/components/Tree/TreeMap/treeMap';

import './tree.scss';

const Tree = ({ className='',
                treeData=[],
                onSelect=() => {},
                draggable=false,
                onDrop=() => {},
                selectedItem,
                defaultExpandAll=true,
                itemIconsRenderer=null,
                itemActionsRenderer=null }) => {

  const treeMap = useMemo(() => new TreeMap(treeData), [treeData]);

  return (
    <TreeItemDragProvider>
    <ElementSelectionProvider>
      <div className={cn('tree', className) }>
        {treeData?.map((item) => <TreeItem key={item.id} level={0} treeMap={treeMap}
                                           item={item}
                                           onSelect={onSelect}
                                           draggable={draggable}
                                           onDrop={onDrop}
                                           selectedItem={selectedItem}
                                           defaultExpandAll={defaultExpandAll}
                                           itemActionsRenderer={itemActionsRenderer}
                                           itemIconsRenderer={itemIconsRenderer}
        />)}
      </div>
    </ElementSelectionProvider>
    </TreeItemDragProvider>
  );
}

Tree.propTypes = {
  className: PropTypes.string,
  treeData: PropTypes.array,
  onSelect: PropTypes.func,
  draggable: PropTypes.bool,
  onDrop: PropTypes.func,
  selectedItem: PropTypes.object,
  defaultExpandAll: PropTypes.bool,
  itemIconsRenderer: PropTypes.func,
  itemActionsRenderer: PropTypes.func,
}

export default Tree;
